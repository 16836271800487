import { Box, HStack } from '@chakra-ui/react'
import { EnquiryIcon, FacebookIcon, TwitterIcon } from 'icons'
import { BoldText, Button } from 'ui'

type SocialItem = 'facebook' | 'twitter' | 'email'

export interface SocialLinksProps {
  title: string | null
  pageUrl: string
  socialItems: SocialItem[]
  pageInfo: string
}

const socialLinksMap = Object.freeze({
  facebook: {
    icon: <FacebookIcon width={'20px'} />,
    href: 'https://www.facebook.com/sharer/sharer.php?u=',
    label: 'Share on Facebook'
  },
  twitter: {
    icon: <TwitterIcon width={'20px'} />,
    href: 'http://twitter.com/share?url=',
    label: 'Share on Twitter'
  },
  email: {
    icon: <EnquiryIcon stroke='currentColor' strokeWidth='0.5px' />,
    label: 'Share via email'
  }
})

const SocialLinks = ({
  title,
  pageUrl = '',
  pageInfo,
  socialItems
}: SocialLinksProps) => {
  return (
    <Box>
      <BoldText>{title}</BoldText>
      <HStack spacing='14px'>
        {socialItems.map((item: SocialItem, index: number) => (
          <Button
            key={index}
            aria-label={socialLinksMap[item].label}
            href={
              item === 'email'
                ? `mailto:?subject=${pageInfo}&body=${pageUrl}`
                : `${socialLinksMap[item].href}${pageUrl}`
            }
            colorScheme='deepWaterBlue'
            variant='outline'
            cursor='pointer'
            borderRadius='full'
            borderWidth='1px'
            boxSize={10}
            icon={socialLinksMap[item].icon}
            _hover={{
              borderColor: 'deepWaterBlue',
              background: 'deepWaterBlue',
              svg: { color: 'white', transition: 'all 0.33s' }
            }}
          />
        ))}
      </HStack>
    </Box>
  )
}

export default SocialLinks
