import { Maybe, TaxonomyObject } from "content-service";
import { getYouTubeVideoId, unique } from "utils";
import createFilterUpdateUtagEventObject, {
  FilterUpdate,
} from "../clicks/filterUpdate";
import createInventorySearchCriteriaUtagEventObject, {
  InventorySearchCriteria,
} from "../clicks/inventorySearchCriteria";
import createLinkClickUtagEventObject, { LinkClick } from "../clicks/linkClick";
import {
  createPopupResponseUtagEventObject,
  createPopupViewUtagEventObject,
  PopupResponse,
  PopupView,
} from "../clicks/popup";
import createProviderReferralUtagEventObject, {
  ProviderReferral,
} from "../clicks/providerReferral";
import createSiteNavUtagEventObject, { SiteNav } from "../clicks/siteNav";
import createStartEnquiryUtagEventObject, {
  StartEnquiry,
} from "../clicks/startEnquiry";
import createVideoUtagEventObject, { Video } from "../clicks/video";
import { ErrorPageViewResult } from "../views/error";
import { InventoryViewResult } from "../views/inventory";
import { PageViewResult } from "../views/page";
import { TEALIUM_EVENT } from "./constants";

type UtagViewData =
  | PageViewResult
  | InventoryViewResult
  | ErrorPageViewResult
  | null;

interface UtagData {
  tealium_visitor_id: string;
  tealium_session_id: string;
}

type Utag = {
  view: (utagDataObject: UtagViewData) => void | undefined;
  link: (utagLink: Record<string, unknown>) => void | undefined;
  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  gdpr: any;
  data: UtagData;
};

interface VideoDataObject {
  name?: string;
  sourceUrl: string;
  duration: number;
}

function createTagList(
  list: Maybe<Array<Maybe<TaxonomyObject>>> | undefined,
): Array<string> | [] {
  const tagList = list?.length ? list.map((item) => item?.tag ?? "") : [];
  return unique(tagList);
}

function createUtagLinkClickEvent(data: LinkClick): void {
  if (typeof window !== "undefined") {
    const customEvent: CustomEvent = new CustomEvent("utagLinkClick", {
      detail: {
        ...createLinkClickUtagEventObject(data),
      },
    });

    document.dispatchEvent(customEvent);
  }
}

function createUtagSiteNavEvent(data: SiteNav): void {
  if (typeof window !== "undefined") {
    const customEvent: CustomEvent = new CustomEvent("utagLinkClick", {
      detail: {
        ...createSiteNavUtagEventObject(data),
      },
    });

    document.dispatchEvent(customEvent);
  }
}

function createUtagFilterUpdateEvent(data: FilterUpdate): void {
  if (typeof window !== "undefined") {
    const customEvent: CustomEvent = new CustomEvent("utagLinkClick", {
      detail: {
        ...createFilterUpdateUtagEventObject(data),
      },
    });

    document.dispatchEvent(customEvent);
  }
}

function createUtagProviderReferralEvent(data: ProviderReferral): void {
  if (typeof window !== "undefined") {
    const customEvent: CustomEvent = new CustomEvent("utagLinkClick", {
      detail: {
        ...createProviderReferralUtagEventObject(data),
      },
    });

    document.dispatchEvent(customEvent);
  }
}

function createUtagInventorySearchCriteriaEvent(
  data: InventorySearchCriteria,
): void {
  if (typeof window !== "undefined") {
    const customEvent: CustomEvent = new CustomEvent("utagLinkClick", {
      detail: {
        ...createInventorySearchCriteriaUtagEventObject(data),
      },
    });

    document.dispatchEvent(customEvent);
  }
}

function createUtagStartEnquiryEvent(data: StartEnquiry): void {
  if (typeof window !== "undefined") {
    const customEvent: CustomEvent = new CustomEvent("utagLinkClick", {
      detail: {
        ...createStartEnquiryUtagEventObject(data),
      },
    });

    document.dispatchEvent(customEvent);
  }
}

function createUtagVideoEvent(data: Video): void {
  if (typeof window !== "undefined") {
    const customEvent: CustomEvent = new CustomEvent("utagLinkClick", {
      detail: {
        ...createVideoUtagEventObject(data),
      },
    });

    document.dispatchEvent(customEvent);
  }
}

function createUtagPopupViewEvent(data: PopupView): void {
  if (typeof window !== "undefined") {
    const customEvent: CustomEvent = new CustomEvent("utagLinkClick", {
      detail: {
        ...createPopupViewUtagEventObject(data),
      },
    });

    document.dispatchEvent(customEvent);
  }
}

function createUtagPopupResponseEvent(data: PopupResponse): void {
  if (typeof window !== "undefined") {
    const customEvent: CustomEvent = new CustomEvent("utagLinkClick", {
      detail: {
        ...createPopupResponseUtagEventObject(data),
      },
    });

    document.dispatchEvent(customEvent);
  }
}

function createSanitisedPageName(documentTitle = "") {
  return documentTitle.includes("|")
    ? documentTitle.split("|")[0].trim()
    : documentTitle;
}

function getPathFromUrl(url: string) {
  return url.split("?")[0];
}

function handleUtagVideoTracking(
  { name = "", sourceUrl, duration }: VideoDataObject,
  tealiumEvent: TEALIUM_EVENT,
): void {
  createUtagVideoEvent({
    tealiumEvent,
    videoId: getYouTubeVideoId(sourceUrl),
    videoLength: duration,
    videoName: name,
    videoPlatform: "YouTube",
  });
}

export {
  getPathFromUrl,
  createSanitisedPageName,
  createUtagLinkClickEvent,
  createUtagFilterUpdateEvent,
  createUtagProviderReferralEvent,
  createUtagSiteNavEvent,
  createUtagInventorySearchCriteriaEvent,
  createUtagStartEnquiryEvent,
  createUtagVideoEvent,
  createTagList,
  handleUtagVideoTracking,
  createUtagPopupViewEvent,
  createUtagPopupResponseEvent,
};
export type { UtagViewData, Utag };
