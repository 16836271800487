import unique from './unique'

type HitItem = string | string[] | Array<{ [key: string]: string }> | undefined

type Hit = {
  [key: string]: HitItem
}

type NestedHitItem =
  | string
  | {
      [key: string]: string
    }

function getUniqueAttributeValuesFromHits(hits: Hit[] | [], attribute: string) {
  const splitAttribute = attribute.split('.')

  const attributeValues = hits.map((hit: Hit) => hit?.[splitAttribute[0]])

  const result = attributeValues.reduce<string[]>((previousHit, currentHit) => {
    if (typeof currentHit === 'string') {
      return [...previousHit, currentHit]
    }

    const value = currentHit?.map((hit: NestedHitItem) =>
      typeof hit === 'string' ? hit : hit?.[splitAttribute[1]]
    )

    return typeof value === 'undefined'
      ? previousHit
      : [...previousHit, ...value]
  }, [])

  return unique(result)
}

export { getUniqueAttributeValuesFromHits }
