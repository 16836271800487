import { Badge, Box } from '@chakra-ui/react'
import React from 'react'

interface IsNewBadge {
  active: boolean
  label: string
}

const IsNewBadge = ({ active, label }: IsNewBadge) => {
  return (
    <Box>
      {active ? (
        <Badge
          fontFamily="enz700"
          fontSize={{ base: '16px', md: '22px' }}
          lineHeight={'36px'}
          background="orange.500"
          color="white"
          border="1px solid"
          borderColor="orange.500"
          borderRadius="6px"
          position="absolute"
          px={{ base: 2, xl: 4 }}
          py={{ base: 0, xl: 2 }}
          left={0}
          top={{ base: '0', xl: '10px' }}
        >
          {label}
        </Badge>
      ) : null}
    </Box>
  )
}

export default IsNewBadge
