import { SearchState } from "react-instantsearch-core";
import {
  getTaxonomyLabelToKey,
  inventorySearchTaxonomyData,
} from "utils/src/helpers/taxonomy";
import { createSanitisedPageName } from "../helpers";
import { TEALIUM_EVENT } from "../helpers/constants";

interface TrackingMap {
  [filterName: string]: string;
}

interface InventorySearchCriteria {
  searchState: SearchState;
  mapTealiumTrackingFields: TrackingMap;
}

interface InventorySearchCriteriaResult {
  page_name: string;
  level_of_study_array: string[];
  area_of_study_array: string[];
  delivery_mode_array: string[];
  locations_of_study_array: string[];
  nz_region_array: string[];
  cities_array: string[];
  provider_subtype: string[];
  provider_name: string;
  search_query: string;
  search_nationality: string[];
  tealium_event: TEALIUM_EVENT;
}

interface MapFields {
  searchState: SearchState;
  mapTealiumTrackingFields: TrackingMap;
}

enum SearchFacetNames {
  DELIVERY_MODE = "deliveryModes",
  LEVEL_OF_STUDY = "levelOfStudy",
  AREA_OF_STUDY = "areasOfStudy",
  COUNTRIES = "countries",
  PROVIDER_SUBTYPE = "providerSubtype",
  SUBTYPE = "subtype",
  REGIONS_WITH_COUNTRIES = "regionsWithCountries",
  REGIONS = "regions",
  PROVIDER_NAME = "providerName",
  CITIES = "cities",
  NATIONALITY = "nationalities",
  CITIES_WITH_COUNTRIES = "citiesWithCountries",
  SECTOR_SPECIALTIES = "sectorSpecialties",
}

interface MapFieldsResult {
  [SearchFacetNames.LEVEL_OF_STUDY]?: string[];
  [SearchFacetNames.AREA_OF_STUDY]?: string[];
  [SearchFacetNames.DELIVERY_MODE]?: string[];
  [SearchFacetNames.COUNTRIES]?: string[];
  [SearchFacetNames.REGIONS_WITH_COUNTRIES]?: string[];
  [SearchFacetNames.REGIONS]?: string[];
  [SearchFacetNames.CITIES]?: string[];
  [SearchFacetNames.PROVIDER_SUBTYPE]?: string[];
  [SearchFacetNames.SUBTYPE]?: string[];
  [SearchFacetNames.PROVIDER_NAME]?: string;
  [SearchFacetNames.NATIONALITY]?: string[];
  [SearchFacetNames.CITIES_WITH_COUNTRIES]?: string[];
  query: string;
}

type FieldMapData = string[] | undefined;
type FieldMapResult = Array<string | undefined> | string | undefined;

interface FieldMap {
  [value: string]: (data: FieldMapData) => FieldMapResult;
}

function convertToRegionOrCity(string: string): string {
  return string.split(" > ")[1];
}

const taxonomy = inventorySearchTaxonomyData();

const fieldMap: FieldMap = Object.freeze({
  [SearchFacetNames.DELIVERY_MODE]: function fetchFieldData(data) {
    return data?.map((value) =>
      getTaxonomyLabelToKey(taxonomy.deliveryModes, value),
    );
  },
  [SearchFacetNames.LEVEL_OF_STUDY]: function fetchFieldData(data) {
    return data?.map((value) =>
      getTaxonomyLabelToKey(taxonomy.levelsOfStudy, value),
    );
  },
  [SearchFacetNames.AREA_OF_STUDY]: function fetchFieldData(data) {
    return data?.map((value) =>
      getTaxonomyLabelToKey(taxonomy.areasOfStudy, value),
    );
  },
  [SearchFacetNames.COUNTRIES]: function fetchFieldData(data) {
    return data?.map((value) =>
      getTaxonomyLabelToKey(taxonomy.locations, value),
    );
  },
  [SearchFacetNames.PROVIDER_SUBTYPE]: function fetchFieldData(data) {
    return data?.map((value) =>
      getTaxonomyLabelToKey(taxonomy.providerSubtype, value),
    );
  },
  [SearchFacetNames.SUBTYPE]: function fetchFieldData(data) {
    return data?.map((value) =>
      getTaxonomyLabelToKey(taxonomy.providerSubtype, value),
    );
  },
  [SearchFacetNames.REGIONS]: function fetchFieldData(data) {
    return data?.map((value) => getTaxonomyLabelToKey(taxonomy.regions, value));
  },
  [SearchFacetNames.REGIONS_WITH_COUNTRIES]: function fetchFieldData(data) {
    return data?.map((value) =>
      getTaxonomyLabelToKey(taxonomy.regions, convertToRegionOrCity(value)),
    );
  },
  [SearchFacetNames.CITIES]: function fetchFieldData(data) {
    return data;
  },
  [SearchFacetNames.PROVIDER_NAME]: function fetchFieldData(data) {
    return data?.[0];
  },
  [SearchFacetNames.NATIONALITY]: function fetchFieldData(data) {
    return data?.map((value) => value);
  },
  [SearchFacetNames.CITIES_WITH_COUNTRIES]: function fetchFieldData(data) {
    return data?.map((value) => convertToRegionOrCity(value));
  },
});

function mapFields({
  mapTealiumTrackingFields,
  searchState,
}: MapFields): MapFieldsResult | undefined {
  if (!searchState) {
    return undefined;
  }

  let resultValue = {};

  Object.entries(searchState).map(([_, values]) => {
    Object.entries<string | string[]>(values).map(
      ([filterName, filterValues]) => {
        const trackingField = mapTealiumTrackingFields[filterName];
        if (
          filterValues !== "" &&
          trackingField &&
          Object.values<string>(SearchFacetNames).includes(trackingField)
        ) {
          const arrayValue = Array.isArray(filterValues)
            ? filterValues
            : [filterValues];
          resultValue = {
            ...resultValue,
            [trackingField]: fieldMap[trackingField](arrayValue),
          };
        }
      },
      {},
    );
  }, {});

  return {
    ...resultValue,
    query: searchState.query ?? "",
  };
}

function getRegions(mappedFields?: MapFieldsResult): string[] {
  return (
    mappedFields?.[SearchFacetNames.REGIONS_WITH_COUNTRIES] ??
    mappedFields?.[SearchFacetNames.REGIONS] ??
    []
  );
}

function getCities(mappedFields?: MapFieldsResult): string[] {
  return (
    mappedFields?.[SearchFacetNames.CITIES] ??
    mappedFields?.[SearchFacetNames.CITIES_WITH_COUNTRIES] ??
    []
  );
}

function getProviderSubtype(mappedFields?: MapFieldsResult): string[] {
  return (
    mappedFields?.[SearchFacetNames.SUBTYPE] ??
    mappedFields?.[SearchFacetNames.PROVIDER_SUBTYPE] ??
    []
  );
}

/**
 * createInventorySearchCriteriaUtagEventObject
 * Build the data for a inventory_search_criteria event
 */
function createInventorySearchCriteriaUtagEventObject({
  searchState,
  mapTealiumTrackingFields,
}: InventorySearchCriteria): InventorySearchCriteriaResult {
  const mappedFields = mapFields({ mapTealiumTrackingFields, searchState });
  return {
    page_name: createSanitisedPageName(document.title),
    level_of_study_array: mappedFields?.[SearchFacetNames.LEVEL_OF_STUDY] ?? [],
    area_of_study_array: mappedFields?.[SearchFacetNames.AREA_OF_STUDY] ?? [],
    delivery_mode_array: mappedFields?.[SearchFacetNames.DELIVERY_MODE] ?? [],
    locations_of_study_array: mappedFields?.[SearchFacetNames.COUNTRIES] ?? [],
    nz_region_array: getRegions(mappedFields),
    cities_array: getCities(mappedFields),
    provider_subtype: getProviderSubtype(mappedFields),
    provider_name: mappedFields?.[SearchFacetNames.PROVIDER_NAME] ?? "",
    search_nationality: mappedFields?.[SearchFacetNames.NATIONALITY] ?? [],
    search_query: mappedFields?.query ?? "",
    tealium_event: TEALIUM_EVENT.INVENTORY_SEARCH_CRITERIA,
  };
}

export default createInventorySearchCriteriaUtagEventObject;
export { mapFields };
export type {
  InventorySearchCriteria,
  InventorySearchCriteriaResult,
  MapFields,
  MapFieldsResult,
  TrackingMap,
};
