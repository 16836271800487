import { CardCarouselGallery, CarouselType } from 'ui'
import { OptimisedImage } from 'ui/src/components/Image/OptimisedImage'

const statisticsCardsConfig = [
  {
    description:
      'Among English-speaking countries, New Zealand is #1 for preparing students for the future',
    url: '/images/stats/stat-card-01.png',
  },
  {
    description:
      'All New Zealand universities are ranked in the top 3% of universities in the world',
    url: '/images/stats/stat-card-02.png',
  },
  {
    description:
      'Out of all countries, New Zealand was the 1st to have a wellbeing strategy for international students',
    url: '/images/stats/stat-card-03.png',
  },
  {
    description:
      'For international work rights, New Zealand is rated #1 compared to 9 other countries',
    url: '/images/stats/stat-card-04.png',
  },
  {
    description: 'Out of 163 nations, New Zealand is ranked 2nd most peaceful place to live',
    url: '/images/stats/stat-card-05.png',
  },
  {
    description:
      'Out of all countries, New Zealand was the 1st to introduce pastoral care requirements to support and protect international students',
    url: '/images/stats/stat-card-06.png',
  },
  {
    description: 'Out of 139 nations, New Zealand is in the top 10',
    url: '/images/stats/stat-card-07.png',
  },
  {
    description: 'Out of 127 nations, New Zealand is ranked 22nd for innovation',
    url: '/images/stats/stat-card-08.png',
  },
]

const statisticsCards = statisticsCardsConfig.map((item) => {
  return {
    image: OptimisedImage({
      src: item.url,
      alt: item.description,
      blurOnLoad: false,
    }),
  }
})

const HomepagePanelStats = () => (
  <CardCarouselGallery
    carouselType={CarouselType.Statistics}
    cards={statisticsCards}
    backgroundColor="surface-blue"
    textColor="white"
    pseudoColor="surface-blue"
  />
)

export default HomepagePanelStats
