import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import { Formik } from 'formik'
import { WarningIcon } from 'icons'
import { useRef } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import type { Utag } from 'tracking'
import { dispatchFormSubmissionUtagLinkEvent } from 'tracking'
import {
  Button,
  CheckboxControl,
  Form,
  FormAgreements,
  FormINZMatchingPermissionOptIn,
  postLeadCaptureFormSubmissionToLeadCaptureAPIEndpoint,
  reformatDateForSubmit,
} from 'ui'
import { formTaxonomyData } from 'utils/src/helpers/taxonomy'
import * as Yup from 'yup'

declare const utag: Utag

interface LeadCapturePopupFormValues {
  firstName: string
  lastName: string
  email: string
  areYouInNZ?: string
  dateOfBirth: string | Date
  nationality: string
  nationalityISOCode: string
  courseStartDate?: string | Date
  privacyPolicyAgreed: boolean
  commsOptin: boolean
  inzMatchingPermissionOptin: boolean
}

export interface LeadCapturePopupFormProps {
  tealium: {
    source: string
    formType: string
    formName: string
  }
  onSuccessCallback?: () => void
  onErrorCallback?: () => void
}

const leadCapturePopupFormSchema: Yup.ObjectSchema<
  Omit<LeadCapturePopupFormValues, 'nationalityISOCode'>
> = Yup.object()
  .shape(
    {
      firstName: Yup.string().required('First name is required'),
      lastName: Yup.string().required('Last name is required'),
      email: Yup.string().email('Please enter a valid email').required('Email address is required'),
      areYouInNZ: Yup.string().oneOf(['Yes', 'I am travelling soon (within two months)', 'No']),
      dateOfBirth: Yup.date()
        .typeError('Please enter a valid date')
        .transform((value) => {
          return value ? new Date(value) : value
        })
        .when('dateOfBirth', {
          is: (val: Date) => val !== undefined,
          then: (rule) => rule.max(new Date(), 'Please enter a date in the past'),
        })
        .required('Date of birth is required'),
      nationality: Yup.string().required('Nationality is required'),
      courseStartDate: Yup.date()
        .typeError('Please enter a valid date')
        .transform((value) => {
          return value ? new Date(value) : value
        })
        .when('courseStartDate', {
          is: (val: Date) => val !== undefined,
          then: (rule) =>
            rule
              .test(
                'year',
                'Please enter a valid date',
                (value: Date | undefined) =>
                  value !== undefined && value.getFullYear() >= 1000 && value.getFullYear() <= 9999
              )
              .min(new Date(), 'Please enter a date in the future'),
        }),
      privacyPolicyAgreed: Yup.boolean().oneOf([true]).defined(),
      commsOptin: Yup.boolean().oneOf([true]).defined(),
      inzMatchingPermissionOptin: Yup.boolean().oneOf([true, false]).defined(),
    },
    [
      ['courseStartDate', 'courseStartDate'],
      ['dateOfBirth', 'dateOfBirth'],
    ]
  )
  .noUnknown(true)
  .required()

const LeadCapturePopupForm = ({
  onSuccessCallback,
  onErrorCallback,
  tealium,
}: LeadCapturePopupFormProps) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const taxonomy = formTaxonomyData()

  const handleFormSubmit = async (values: LeadCapturePopupFormValues) => {
    let tealiumCookie = ''
    let tealiumSessionId = ''
    const utagData: { [key: string]: any } | null = window?.utag?.data ?? null

    if (utagData && utagData?.tealium_session_id?.length > 0) {
      tealiumSessionId = utagData.tealium_session_id
    }

    if (utagData && utagData?.tealium_visitor_id?.length > 0) {
      tealiumCookie = utagData.tealium_visitor_id
    }

    const countryItem =
      taxonomy?.location?.find((country) => country.key == values.nationality) ?? ''
    const isoCode = countryItem ? countryItem.iso : ''

    const formData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      ...(values?.areYouInNZ ? { areYouInNZ: values.areYouInNZ } : {}),
      dateOfBirth: reformatDateForSubmit(values.dateOfBirth),
      nationality: values.nationality,
      nationalityISOCode: isoCode,
      ...(values?.courseStartDate
        ? { courseStartDate: reformatDateForSubmit(values.courseStartDate) }
        : {}),
      privacyPolicyAgreed: values.privacyPolicyAgreed,
      commsOptin: values.commsOptin,
      inzMatchingPermissionOptin: values.inzMatchingPermissionOptin,
      tealiumVisitorId: tealiumCookie,
      tealiumSessionId,
      source: tealium.source,
      formSchemaName: 'leadCapturePopupFormSchema',
    }

    postLeadCaptureFormSubmissionToLeadCaptureAPIEndpoint(formData, {
      success: () => {
        onSuccessCallback?.()
        utagData &&
          dispatchFormSubmissionUtagLinkEvent(
            tealium.formType,
            tealium.formName,
            window.location.href
          )
      },
      error: () => {
        onErrorCallback?.()
      },
    })
  }

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
        dateOfBirth: '',
        nationality: '',
        nationalityISOCode: '',
        privacyPolicyAgreed: true,
        commsOptin: true,
        inzMatchingPermissionOptin: false,
      }}
      validationSchema={leadCapturePopupFormSchema}
      onSubmit={handleFormSubmit}
    >
      {({ isValid, isSubmitting, dirty, initialValues }) => {
        return (
          <Form>
            <Stack spacing={4} alignItems="flex-start">
              {dirty && !isValid && (
                <Flex
                  border="2px solid"
                  borderRadius="base"
                  borderColor="red.400"
                  backgroundColor="red.50"
                  padding={3}
                  lineHeight="1.125"
                  alignItems="center"
                >
                  <Box boxSize={6} fill="red.400">
                    <WarningIcon />
                  </Box>
                  <Text fontFamily="enz400" mb="0" paddingLeft={2} color="grey.500">
                    {`Please complete the highlighted fields below.`}
                  </Text>
                </Flex>
              )}
              <Stack spacing={6} alignItems="flex-start">
                <Stack spacing="10px" width="100%">
                  <Form.FirstNameField />
                  <Form.LastNameField />
                  <Form.EmailAddressField />
                  <Form.AreYouInNZField
                    areYouInNzOptions={[
                      { key: 'yes', label: 'Yes' },
                      {
                        key: 'yes-within-two-months',
                        label: 'I am travelling soon (within two months)',
                      },
                      { key: 'no', label: 'No' },
                    ]}
                  />
                  <Form.DateOfBirthField initialValue={initialValues.dateOfBirth} isRequired />
                  <Form.NationalityField
                    label="Nationality"
                    nationalityOptions={taxonomy.location}
                    isRequired={true}
                  />
                  <Form.StartDateField name="courseStartDate" label="Course start date" />
                </Stack>
                <CheckboxControl
                  name="inzMatchingPermissionOptin"
                  mt={1}
                  mr={2}
                  extendedText={{ label: 'Read' }}
                >
                  <FormINZMatchingPermissionOptIn />
                </CheckboxControl>
                <Box visibility="hidden">
                  {/* @ts-ignore https://github.com/dozoisch/react-google-recaptcha/issues/277 */}
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY ?? ''}
                  />
                </Box>
              </Stack>
              <Box>
                <Button
                  type="submit"
                  isDisabled={!(dirty && isValid) || isSubmitting}
                  mb={3}
                  aria-label="submit"
                >
                  Submit
                </Button>
              </Box>
              <FormAgreements includeAgreePolicy />
            </Stack>
          </Form>
        )
      }}
    </Formik>
  )
}

export default LeadCapturePopupForm
export { leadCapturePopupFormSchema }
export type { LeadCapturePopupFormValues }
