import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Link
} from '@chakra-ui/react'
import { ChatIcon, ExternalLinkIcon } from 'icons'
import { useRouter } from 'next/router'
import React from 'react'
import { SUPPORTED_LANGUAGES } from 'swnz/src/constants'

const LanguageDropdown = () => {
  const { asPath, locale, defaultLocale } = useRouter()

  const currentLanguage = SUPPORTED_LANGUAGES.find((lang) =>
    locale === defaultLocale ? lang.locale === 'en' : lang.locale === locale
  )

  const setCookieOnRouteChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    const { currentTarget } = e
    const nextLanguage = SUPPORTED_LANGUAGES.find(
      (lang) => lang.title === currentTarget.text
    )

    if (nextLanguage && !nextLanguage.externalURL) {
      document.cookie = `NEXT_LOCALE=${nextLanguage.locale}`
      const basePath = `${window.location.origin}/${nextLanguage?.locale}`
      const fullPath = asPath === '/' ? basePath : `${basePath}${asPath}`

      // [NOTE]: we must force a refresh of the page due to https://github.com/vyg/enz-study-with-new-zealand/issues/623
      window.location.href = fullPath
    }
  }

  return (
    <Accordion
      allowToggle
      p={5}
      borderBottom='1px solid rgba(255, 255, 255, 0.1)'
    >
      <AccordionItem border='unset'>
        <AccordionButton
          h={12}
          bg='rgba(4, 15, 32, 0.5)'
          fontFamily='enz500'
          borderRadius={1}
          _expanded={{ bg: 'rgba(255, 255, 255, 0.1)', mb: '6px' }}
        >
          <Box boxSize={4} mr={3}>
            <ChatIcon fill='white' />
          </Box>
          <Box flex='1' textAlign='left' py={4}>
            {currentLanguage?.title}
          </Box>
          <AccordionIcon mr={1} />
        </AccordionButton>
        <AccordionPanel bg='deepblue.600' borderRadius={1}>
          {SUPPORTED_LANGUAGES.map((lang) => (
            <Link
              onClick={setCookieOnRouteChange}
              href={lang.externalURL ? lang.externalURL : undefined}
              aria-label={`link to ${lang.title}`}
              title={`link to ${lang.title} site`}
              display='flex'
              color='white'
              textDecor='none'
              py={2}
              key={lang.title}
              alignItems='center'
              fontFamily='enz400'
              isExternal={!!lang.externalURL}
              _hover={{ color: 'navHover' }}
              _focus={{ outline: 'none' }}
            >
              <Box
                boxSize={4}
                mr={3}
                opacity={lang.locale === locale ? 1 : 0.5}
              >
                <ChatIcon fill='white' />
              </Box>

              {lang.title}
              {!!lang.externalURL && (
                <Box
                  ml={1}
                  boxSize={6}
                  sx={{
                    'html[lang=ar] &': {
                      /* GOTCHA!
                       * This is acutally align 'left'
                       * stylis RTL plugin changes this to 'left' for us */
                      marginLeft: '0',
                      marginRight: '1'
                    }
                  }}
                >
                  <ExternalLinkIcon />
                </Box>
              )}
            </Link>
          ))}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default LanguageDropdown
