import { Link as ChakraLink, LinkProps, Stack } from '@chakra-ui/react'
import React from 'react'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { Link, NavLink } from 'ui'
import { FooterSectionProps } from './types'

const FooterSectionLegalLinks = ({
  links,
  opacity = 1,
  lineHeight = '28px',
  mb = 0
}: FooterSectionProps & LinkProps) => {
  if (!links) {
    return null
  }

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault()
    if (typeof window !== undefined) {
      window?.utag?.gdpr?.showConsentPreferences()
    }
  }

  return (
    <Stack
      spacing={{ base: 0, lg: 7 }}
      direction={{ base: 'column', lg: 'row' }}
    >
      <ChakraLink
        key={4}
        color='white'
        opacity={opacity}
        textDecor='none'
        fontSize='sm'
        fontFamily='enz400'
        lineHeight={lineHeight}
        mb={mb}
        title={'Data consent settings'}
        aria-label={'Data consent settings'}
        onClick={(e) => handleClick(e)}
      >
        Data consent settings
      </ChakraLink>
      {links.map((link: NavLink, index) => (
        <Link
          key={index}
          color='white'
          opacity={opacity}
          textDecor='none'
          fontSize='sm'
          fontFamily='enz400'
          lineHeight={lineHeight}
          mb={mb}
          href={link.slug}
          title={link.title}
          aria-label={link.title}
          onClick={() =>
            createUtagLinkClickEvent({
              linkLabel: link.title.toLowerCase(),
              linkCategory: TEALIUM_LINK_CATEGORY.FOOTER
            })
          }
        >
          {link.title}
        </Link>
      ))}
    </Stack>
  )
}

export default FooterSectionLegalLinks
