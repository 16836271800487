// Checks the pageTheme and returns the correct background colour
const getPageThemeBackgroundColour = (pageTheme?: string) => {
  switch (pageTheme) {
    case 'campaign-garb':
      return 'sand.30'
    default:
      return ''
  }
}

// Checks the pageTheme and returns the correct cta colour
const getPageThemeCtaColour = (pageTheme?: string) => {
  switch (pageTheme) {
    case 'campaign-garb':
      return 'deepblue'
    default:
      return ''
  }
}

export { getPageThemeBackgroundColour, getPageThemeCtaColour }
