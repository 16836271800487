import { Box, Container, GridItem } from '@chakra-ui/react'
import {
  HomepageCardsCollection_Swnz_HomepagePanelFragment,
  HomepageCardsCollection_Swnz_HomepagePanelFragmentDoc,
  HomepagePanel_Swnz_HomepagePanelFragment,
  HomepagePanel_Swnz_HomepagePanelFragmentDoc,
} from 'content-service'
import { ReactElement } from 'react'
import { Connector, GridLayout, RichTextRenderer } from 'ui'
import { createCtaButton } from 'utils/src/helpers'
import useFragment from 'utils/src/hooks/useFragment'
import { HomepagePanel, HomepagePanelCards } from '../../components/Homepage'
import { createPanelCards } from './helpers/createPanelCards'
import HomepagePanelStats from './HomepagePanelStats'

export interface HomepagePanelConnectProps extends Connector {}

const HomepagePanelConnect = ({ id, typename }: HomepagePanelConnectProps): ReactElement | null => {
  const homepagePanel = useFragment<HomepagePanel_Swnz_HomepagePanelFragment>({
    id,
    typename,
    fragment: HomepagePanel_Swnz_HomepagePanelFragmentDoc,
    fragmentName: 'homepagePanel_SWNZ_HomepagePanel',
  })
  const homepageCards = useFragment<HomepageCardsCollection_Swnz_HomepagePanelFragment>({
    id,
    typename,
    fragment: HomepageCardsCollection_Swnz_HomepagePanelFragmentDoc,
    fragmentName: 'homepageCardsCollection_SWNZ_HomepagePanel',
  })

  if (!homepagePanel) {
    return null
  }

  const items = createPanelCards({
    items: homepageCards?.homepageCardsCollection?.items,
  })

  const primaryCtaProps = createCtaButton(homepagePanel?.primaryCta)
  const secondaryCtaProps = createCtaButton(homepagePanel?.secondaryCta)

  return (
    <HomepagePanel
      backgroundColour={homepagePanel?.backgroundColour as string}
      subHeading={homepagePanel?.subHeading as string}
      heading={homepagePanel?.heading as string}
      preHeading={homepagePanel?.preHeading as string}
      primaryCta={primaryCtaProps}
      secondaryCta={secondaryCtaProps}
      data-testid="homepage-cards"
    >
      <Box>
        {homepagePanel.introText && (
          <Container
            textAlign={{ base: 'left', md: 'center' }}
            sx={{
              p: {
                fontSize: { base: '20px', lg: '22px' },
                lineHeight: { base: '30px', lg: 9 },
              },
            }}
          >
            <GridLayout>
              <GridItem
                colSpan={{ base: 4, md: 8 }}
                colStart={{ lg: 3 }}
                sx={{
                  hr: {
                    marginTop: { base: 0, md: 6 },
                  },
                }}
              >
                <RichTextRenderer richText={homepagePanel?.introText} />
              </GridItem>
            </GridLayout>
          </Container>
        )}
        {homepagePanel?.codeId === 'display-stats-cards' && <HomepagePanelStats />}
        {Boolean(items.length) && <HomepagePanelCards items={items} />}
        {homepagePanel?.footerText && (
          <Container
            textAlign="center"
            sx={{
              p: {
                fontSize: { base: '20px', lg: '22px' },
                lineHeight: { base: '30px', lg: 9 },
              },
            }}
          >
            <GridLayout>
              <GridItem colSpan={{ base: 4, md: 8 }} colStart={{ lg: 3 }}>
                <RichTextRenderer richText={homepagePanel?.footerText} />
              </GridItem>
            </GridLayout>
          </Container>
        )}
      </Box>
    </HomepagePanel>
  )
}

export default HomepagePanelConnect
