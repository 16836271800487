import {
  FormControl,
  FormLabel,
  HStack,
  Select,
  SelectProps
} from '@chakra-ui/react'
import React from 'react'

export interface Props extends SelectProps {
  label?: string
}

const SortingButton: React.FC<Props> = ({ label, ...props }) => {
  return (
    <FormControl width='auto' lineHeight='none' display='inline-block'>
      <HStack
        pl={4}
        borderRadius='base'
        transition='all 0.3s'
        _hover={{
          bg: 'deepblue.100'
        }}
      >
        {label && <FormLabel m={0}>{label}</FormLabel>}
        <Select
          variant='unstyled'
          width='auto'
          fontFamily='enz500'
          paddingInlineEnd='1.5rem'
          {...props}
        />
      </HStack>
    </FormControl>
  )
}
export default SortingButton
