import { Statistic_Swnz_StatFragment } from 'content-service'
import { isPresent } from 'ts-is-present'
import { RichTextRenderer } from '../../../components/RichTextRenderer'
import { StatItems } from '../../../components/content-components'

export interface CreateStatistics {
  statistics?: Array<Statistic_Swnz_StatFragment | null>
}

export const createStatistics = ({
  statistics
}: CreateStatistics): Array<StatItems> => {
  if (!statistics) {
    return []
  }

  const statisticArr = statistics.filter(isPresent).map((statistic) => {
    return {
      heroStat: statistic?.statistic ?? 0,
      subtitle: statistic?.title ?? '',
      children: <RichTextRenderer richText={statistic?.bodyText} /> ?? ''
    }
  })

  return statisticArr
}
