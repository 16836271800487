import { AutocompleteApi } from '@algolia/autocomplete-core'
import { Box, Center, Divider, Flex, HStack, Input } from '@chakra-ui/react'
import React, {
  forwardRef,
  ReactElement,
  useImperativeHandle,
  useRef
} from 'react'
import { SEARCH_BAR } from 'utils'
import SearchInputIcon from '../SearchInputIcon/SearchInputIcon'
import SearchTitleCta from '../SearchTitleCta'
import { SearchTitleCtaProps } from '../SearchTitleCta/SearchTitleCta'
import { AutocompleteResults } from './SearchAutocomplete'
interface SearchAutocompleteInput {
  title: string
  autocomplete: AutocompleteApi<AutocompleteResults>
  showLabel?: boolean
  searchTitleCta?: SearchTitleCtaProps
  largeInput?: boolean
}

export interface AutocompleteInputRef {
  form: HTMLFormElement | null
  input: HTMLInputElement | null
}

const SearchAutocompleteInput = forwardRef<
  AutocompleteInputRef,
  SearchAutocompleteInput
>(
  (
    {
      title,
      autocomplete,
      showLabel = false,
      largeInput = false,
      searchTitleCta
    },
    ref
  ): ReactElement => {
    const formRef = useRef<HTMLFormElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    useImperativeHandle(ref, () => ({
      get form() {
        return formRef.current
      },
      get input() {
        return inputRef.current
      }
    }))
    const searchButtonSize = '60px'
    const { getFormProps, getLabelProps, getInputProps } = autocomplete

    return (
      //@ts-ignore
      <form ref={formRef} {...getFormProps({ inputElement: inputRef.current })}>
        <HStack
          spacing={4}
          mb={{ base: 2, lg: 4 }}
          display={{ base: 'none', md: showLabel ? 'flex' : 'none' }}
        >
          <Box fontFamily='enz700' fontSize='20px' {...getLabelProps({})}>
            {title}
          </Box>
          {searchTitleCta && (
            <>
              <Center height={6} display={{ base: 'none', lg: 'unset' }}>
                <Divider
                  orientation='vertical'
                  color='deepblue.200'
                  opacity={1}
                  m='0'
                />
              </Center>

              <SearchTitleCta
                {...searchTitleCta}
                display={{ base: 'none', md: 'inline-block' }}
              />
            </>
          )}
        </HStack>

        <Flex alignItems='center'>
          {
            //@ts-ignore

            <Input
              title={title}
              color={'deepWaterBlue'}
              ref={inputRef}
              paddingInlineEnd={searchButtonSize}
              h={{ base: '60px', lg: largeInput ? '80px' : '60px' }}
              lineHeight={{ lg: largeInput ? '80px' : '60px' }}
              fontSize={{ lg: largeInput ? '22px' : 'md' }}
              fontFamily='enz400'
              sx={{
                'html[lang=ar] &': {
                  px: 4
                },
                ...SEARCH_BAR.HOVER
              }}
              {...getInputProps({ inputElement: null })}
            />
          }
          <SearchInputIcon searchButtonSize={searchButtonSize} />
        </Flex>
      </form>
    )
  }
)

SearchAutocompleteInput.displayName = 'SearchAutocompleteInput'

export default SearchAutocompleteInput
