import {
  MapContainer_Swnz_MapContainerFragment,
  MapContainer_Swnz_MapContainerFragmentDoc
} from 'content-service'
import useFragment from 'utils/src/hooks/useFragment'
import { MapContainer } from '../../components/content-components'
import { toTitleCase } from '../../components/content-components/MapContainer/MapContainer'
import { Connector } from '../types'

export type MapContainerConnectProps = Connector

const regionsToRetainHyphens = {
  'napier-hastings': true
}

const formatRegionNameToLabel = (name?: string | null): string => {
  if (!name) return ''

  const label = regionsToRetainHyphens[name] ? name : name.split('-').join(' ')
  return toTitleCase(label)
}

const MapContainerConnect = ({ id, typename }: MapContainerConnectProps) => {
  const mapContainer = useFragment<MapContainer_Swnz_MapContainerFragment>({
    id,
    typename,
    fragment: MapContainer_Swnz_MapContainerFragmentDoc,
    fragmentName: 'mapContainer_SWNZ_MapContainer'
  })

  if (!mapContainer) {
    return null
  }

  const regions = mapContainer.pagesCollection?.items
    ?.filter(
      (item) =>
        item?.sectionsCollection?.items &&
        item.sectionsCollection.items.length > 0
    )
    ?.map((item) => {
      return {
        label: formatRegionNameToLabel(item?.region),
        name: item?.region ?? '',
        parentPagePath: item?.parentPage ?? undefined
      }
    })
    .sort()

  if (!regions) return null

  return <MapContainer regions={regions} />
}

export default MapContainerConnect
