import {
  JumpAhead_Swnz_JumpAheadFragmentDoc,
  JumpAhead_Swnz_JumpAheadFragment
} from 'content-service'
import { Link } from '@chakra-ui/react'
import React from 'react'
import { slugify } from 'utils/src/helpers/slug'
import useFragment from 'utils/src/hooks/useFragment'
import { Connector } from '../types'

const JumpAheadConnect = ({ id, typename }: Connector) => {
  const jumpAhead: JumpAhead_Swnz_JumpAheadFragment | null | undefined =
    useFragment<JumpAhead_Swnz_JumpAheadFragment>({
      id,
      typename,
      fragment: JumpAhead_Swnz_JumpAheadFragmentDoc,
      fragmentName: 'jumpAhead_SWNZ_JumpAhead'
    })

  const { title, anchorName, displayInMenu } = jumpAhead || {}
  const dataName = anchorName ? anchorName : slugify(title || '')

  return (
    <Link
      data-name={dataName}
      data-title={title}
      data-displayinmenu={Boolean(displayInMenu)}
      data-type='jump-ahead'
      visibility='hidden'
      id={dataName}
    />
  )
}

export default JumpAheadConnect
