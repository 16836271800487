import Input from './input'

type Dict = Record<string, any>

const baseStyle = {
  ...Input.baseStyle.field,
  paddingY: '1rem',
  minHeight: '80px',
  lineHeight: 'short'
}

const variants = {
  filled: (props: Dict) => Input.variants.filled(props).field
}

const sizes = {
  md: Input.sizes.md.field
}

const defaultProps = {
  size: 'md',
  variant: 'filled'
}

export default {
  baseStyle,
  sizes,
  variants,
  defaultProps
}
