import areaOfStudyData from './area-of-study.json'
import deliveryModeData from './delivery-mode.json'
import levelOfStudyBroadData from './level-of-study-broad.json'
import levelOfStudySpecificSchoolData from './level-of-study-specific-school.json'
import locationData from './location.json'
import placesData from './places.json'
import providerSubtypeNzSchoolData from './provider-subtype-nz-school.json'
import providerSubtypeNzTertiaryProviderData from './provider-subtype-nz-tertiary-provider.json'
import providerSubtypeOverseasEducationProviderData from './provider-subtype-overseas-education-provider.json'
import userTypeData from './user-type.json'

export {
  areaOfStudyData,
  deliveryModeData,
  levelOfStudyBroadData,
  levelOfStudySpecificSchoolData,
  locationData,
  placesData,
  providerSubtypeNzSchoolData,
  providerSubtypeNzTertiaryProviderData,
  providerSubtypeOverseasEducationProviderData,
  userTypeData
}
