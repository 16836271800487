import { Flex } from '@chakra-ui/react'
import { useScroll } from 'framer-motion'
import { useCallback, useEffect, useState } from 'react'
import { CallToAction } from 'ui'
import { ZINDEX } from 'utils'
import HomepageHeroCtas from './HomepageHeroCtas'
import HomepageHeroHeading from './HomepageHeroHeading'
import HomepageHeroJumpText from './HomepageHeroJumpText'
import HomepageHeroPreHeading from './HomepageHeroPreHeading'

interface FixedHomepageHeroProps {
  heroPreHeading: string | null | undefined
  heroHeading: string | null | undefined
  heroJumpText: string | null | undefined
  handleIsVisible: (isVisible: boolean) => void
  isVisible: boolean
  pageTheme?: string
  heroCtas: CallToAction[] | []
  leadCaptureFormId?: string
}

interface ElementAttributes {
  top: number
  bottom: number
  height: number
  scrollTop: number
}

const defaultElementAttributes = {
  top: 0,
  bottom: 0,
  height: 0,
  scrollTop: 0,
}

const HomepageHero = ({
  heroPreHeading,
  heroHeading,
  heroJumpText,
  handleIsVisible,
  isVisible,
  heroCtas,
  leadCaptureFormId,
}: FixedHomepageHeroProps) => {
  const TAB_IMAGE_CENTRE_CLASSNAME = '.tab_image_container'
  const TAB_MAIN_ID = '#tab_main'
  const NEW_HEADING_CONTAINER_ID = '#homepage_hero_heading_container'
  const HERO_CTAS_ID = '#homepage_hero_ctas'
  const [tabImageElementCenter, setTabImageElementCenter] = useState<number>(0)
  const [showFixedHeading, setShowFixedHeading] = useState<boolean>(true)
  const [scrollTo, setScrollTo] = useState<number>(0)
  const showHeroCtas = heroCtas.length > 0

  const [tabImageElementAttributes, setTabImageElementAttributes] =
    useState<ElementAttributes>(defaultElementAttributes)

  const [tabMainElementAttributes, setTabMainElementAttributes] =
    useState<ElementAttributes>(defaultElementAttributes)

  const [newHeadingContainerElementAttributes, setNewHeadingContainerElementAttributes] =
    useState<ElementAttributes>(defaultElementAttributes)

  const [heroCtasElementAttributes, setHeroCtasElementAttributes] =
    useState<ElementAttributes>(defaultElementAttributes)

  const getElementAttributes = (selector: string): ElementAttributes => {
    const element = document.querySelector(selector)?.getBoundingClientRect()
    const scrollTop = document.documentElement.scrollTop
    let top = 0
    let bottom = 0
    let height = 0

    if (element !== undefined) {
      top = element.top
      bottom = element.bottom
      height = element.height
    }

    return { top, bottom, height, scrollTop }
  }

  const handleCalculateCentreLine = ({ top, height, scrollTop }: ElementAttributes): number => {
    return top + height / 2 + scrollTop
  }

  const { scrollY } = useScroll()

  const determineHeaderHeight = useCallback((): number => {
    const windowInnerWidth = window.innerWidth
    if (windowInnerWidth < 575) return 60
    if (windowInnerWidth < 1200) return 76

    return 92
  }, [])

  const determineWindowCentre = useCallback((): number => {
    return window.innerHeight / 2
  }, [])

  const handleVisibilityOfFixedHeading = useCallback(
    (scrollYCentreProgress: number) => {
      if (scrollYCentreProgress < tabImageElementCenter) {
        setShowFixedHeading(true)
      } else if (scrollYCentreProgress > tabImageElementCenter) {
        setShowFixedHeading(false)
      }
    },
    [tabImageElementCenter]
  )

  const handleVisibilityOfAdditionalElements = useCallback(
    (scrollYProgress: number) => {
      let scrollProgress = scrollYProgress
      // Handle mobile bug where scrollYProgress is sometimes negative.
      // If scrollYProgress is negative then set it to 0, as it should never be negative.
      if (scrollYProgress < 0) scrollProgress = 0

      let initialDifference =
        tabMainElementAttributes.top - newHeadingContainerElementAttributes.bottom
      // Handle mobile bug where the initial difference is sometimes negative.
      // If the initial difference is negative then convert it to positive, as it should never be negative.
      if (initialDifference < 0) initialDifference *= -1

      if (scrollProgress < initialDifference) {
        handleIsVisible(true)
      } else if (scrollProgress > initialDifference) {
        handleIsVisible(false)
      }
    },
    [tabMainElementAttributes, newHeadingContainerElementAttributes, handleIsVisible]
  )

  useEffect(() => {
    const handleResize = () => {
      setTabImageElementAttributes(getElementAttributes(TAB_IMAGE_CENTRE_CLASSNAME))
      setTabMainElementAttributes(getElementAttributes(TAB_MAIN_ID))
      setNewHeadingContainerElementAttributes(getElementAttributes(NEW_HEADING_CONTAINER_ID))
      setHeroCtasElementAttributes(getElementAttributes(HERO_CTAS_ID))
    }

    if (typeof window !== 'undefined') {
      setTabImageElementAttributes(getElementAttributes(TAB_IMAGE_CENTRE_CLASSNAME))
      setTabMainElementAttributes(getElementAttributes(TAB_MAIN_ID))
      setNewHeadingContainerElementAttributes(getElementAttributes(NEW_HEADING_CONTAINER_ID))
      setHeroCtasElementAttributes(getElementAttributes(HERO_CTAS_ID))
      window.addEventListener('resize', handleResize)
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setTabImageElementCenter(handleCalculateCentreLine(tabImageElementAttributes))
  }, [tabImageElementAttributes, tabImageElementCenter])

  useEffect(() => {
    const windowCentre = determineWindowCentre()
    const headerHeight = determineHeaderHeight()

    scrollY.onChange((scrollProgress) => {
      // prettier-ignore
      const scrollYCentreProgress = scrollProgress.valueOf() + windowCentre + headerHeight
      handleVisibilityOfFixedHeading(scrollYCentreProgress)
      handleVisibilityOfAdditionalElements(scrollProgress.valueOf())
    })

    return () => {
      scrollY.destroy()
    }
  }, [
    scrollY,
    determineHeaderHeight,
    determineWindowCentre,
    handleVisibilityOfFixedHeading,
    handleVisibilityOfAdditionalElements,
  ])

  useEffect(() => {
    setScrollTo(tabImageElementAttributes.top - determineHeaderHeight())
  }, [tabImageElementAttributes, determineHeaderHeight])

  return (
    <Flex
      id={'homepage_hero_heading_container'}
      position={showFixedHeading ? 'fixed' : 'absolute'}
      top={{
        base: showFixedHeading
          ? '50%'
          : `${tabImageElementCenter + heroCtasElementAttributes.height / 3}px`,
        md: showFixedHeading ? '50%' : `${tabImageElementCenter}px`,
      }}
      left="50%"
      transform="translate(-50%, -50%)"
      zIndex={ZINDEX.NEW_TEXT}
      flexDir="column"
      align="center"
      justify="center"
      sx={{
        '@media (max-height: 667px) and (min-width: 576px)': {
          paddingTop: '76px',
        },
      }}
    >
      <HomepageHeroPreHeading heroPreHeading={heroPreHeading} isVisible={isVisible} />
      <HomepageHeroHeading heroHeading={heroHeading} animateIAmNew={!showFixedHeading} />
      {!showHeroCtas && (
        <HomepageHeroJumpText
          heroJumpText={heroJumpText}
          isVisible={isVisible}
          scrollTo={scrollTo}
        />
      )}
      {showHeroCtas && (
        <HomepageHeroCtas
          id={'homepage_hero_ctas'}
          pos="relative"
          zIndex={ZINDEX.NEW_TEXT + 1}
          heroCtas={heroCtas}
          isVisible={isVisible}
          leadCaptureFormId={leadCaptureFormId}
        />
      )}
    </Flex>
  )
}

export default HomepageHero
