import {
  Box,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  useDisclosure,
  Wrap
} from '@chakra-ui/react'
import {
  BlogIcon,
  CloseIcon,
  FacebookIcon,
  InstagramIcon,
  NaverIcon,
  TwitterIcon,
  WeChatIcon,
  WeiboIcon,
  YoutubeIcon
} from 'icons'
import startCase from 'lodash/startCase'
import toLower from 'lodash/toLower'
import { useRouter } from 'next/router'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { Button, Link, NavLink } from 'ui'
import { useMediaQuery } from 'utils/src/helpers'
import { Image } from '../Image'
import { PopoverTrigger } from '../PopoverTrigger'
import FooterSectionTitle from './FooterSectionTitle'
import { FooterSectionProps } from './types'

const everyWordCapitalised = (str: string) => {
  return startCase(toLower(str))
}

const socialLinksIconMap = (title = '') => {
  switch (title.toLocaleLowerCase()) {
    case 'facebook':
      return <FacebookIcon />
    case 'twitter':
      return <TwitterIcon />
    case 'instagram':
      return <InstagramIcon />
    case 'youtube':
      return <YoutubeIcon />
    case 'wechat':
      return <WeChatIcon />
    case 'weibo':
      return <WeiboIcon />
    case 'blog':
      return <BlogIcon />
    case 'naver':
      return <NaverIcon />
    default:
      return null
  }
}

interface SocialLinkProps {
  link: NavLink
  bgColor: string
}

const StandardSocialLink = ({ link, bgColor }: SocialLinkProps) => (
  <Box pr={2} pb={2}>
    <Link
      sx={{
        _hover: {
          fill: 'white'
        },
        svg: {
          width: link.title.toLowerCase() === 'naver' ? '14px' : '16px',
          fill: 'white'
        }
      }}
      boxSize={10}
      background={bgColor}
      borderRadius='full'
      display='flex'
      justifyContent='center'
      alignItems='center'
      href={link.slug}
      title={link.title}
      aria-label={link.title}
      isExternal={true}
      color='white'
      onClick={() =>
        createUtagLinkClickEvent({
          linkLabel: everyWordCapitalised(link.title),
          linkCategory: TEALIUM_LINK_CATEGORY.SOCIAL
        })
      }
    >
      {socialLinksIconMap(link.title)}
    </Link>
  </Box>
)

const WeChatSocialLink = ({ link, bgColor }: SocialLinkProps) => {
  const router = useRouter()
  const { onOpen, onClose, isOpen } = useDisclosure()

  return (
    <Box pr={2} pb={2}>
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        placement={router.locale === 'ar' ? 'left' : 'right'}
      >
        <PopoverTrigger>
          <Button
            sx={{
              svg: {
                width: '16px',
                fill: 'white'
              }
            }}
            p={0}
            boxSize={10}
            background={bgColor}
            border='none'
            display='flex'
            justifyContent='center'
            alignItems='center'
            title={link.title}
            aria-label={link.title}
            cursor='pointer'
            tabIndex={0}
            _hover={{ bg: bgColor }}
            _active={{ bg: bgColor }}
            onClick={() =>
              createUtagLinkClickEvent({
                linkLabel: everyWordCapitalised(link.title),
                linkCategory: TEALIUM_LINK_CATEGORY.SOCIAL
              })
            }
          >
            {socialLinksIconMap(link.title)}
          </Button>
        </PopoverTrigger>
        <PopoverContent width={125} height={125} border='none'>
          <PopoverArrow />
          {isOpen ? (
            <PopoverCloseButton
              position='absolute'
              top={0}
              right={0}
              left='100%'
              backgroundColor='primary'
              borderRadius='none'
            >
              <CloseIcon color='white' boxSize={5} />
            </PopoverCloseButton>
          ) : null}
          <Box>
            <Image
              width={125}
              height={125}
              src='/images/enz-wechat-qr-code.png'
              alt={link.title}
            />
          </Box>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

const FooterSectionSocialLinks = ({
  title = '',
  links,
  socialLinkDark = false
}: FooterSectionProps) => {
  const isDesktop = useMediaQuery('(min-width: 992px)')
  const bgColor = socialLinkDark ? 'deepblue.450' : 'grey.500'

  if (!links) {
    return null
  }

  return (
    <>
      {isDesktop && (
        <FooterSectionTitle aria-label={title}>{title}</FooterSectionTitle>
      )}
      <Wrap maxW={56} mt={5} spacing='0px'>
        {links.map((link: NavLink) =>
          link.title === 'WeChat' ? (
            <WeChatSocialLink key={link.id} link={link} bgColor={bgColor} />
          ) : (
            <StandardSocialLink key={link.id} link={link} bgColor={bgColor} />
          )
        )}
      </Wrap>
    </>
  )
}

export default FooterSectionSocialLinks
