const ZINDEX = {
  NEW_TEXT: 1,
  LETS_GET_STARTED: 2,
  TOHU_CHAT_BUTTON_ICON: 3,
  PHONE_NUMBER_INPUT: 4,
  DATE_PICKER: 5,
  HEADER: 500,
  SEARCH_PANEL: 200,
  LEAD_FORM: 300,
  COOKIE_CONSENT: 499,
  COLUMN_GRID: 9999,
  FORM_POPUP: 300,
  TOOLTIP: 250,
  HERO_FEATURED_CARD: 100
}

export default ZINDEX
