import React from 'react'
import { Text, TextProps } from '@chakra-ui/react'

export default function Blockquote(props: TextProps) {
  return (
    <Text
      fontSize={{ base: '24px', lg: '40px' }}
      lineHeight={{ base: '36px', lg: '56px' }}
      letterSpacing='-0.3px'
      fontFamily='enz500'
      {...props}
    />
  )
}
