import {
  InventoryCourse,
  InventoryProvider,
  InventoryScholarship
} from 'content-service'
import { NextSeo } from 'next-seo'
import { OpenGraph } from 'next-seo/lib/types'
import { ReactElement } from 'react'
import {
  createCourseDetailPageSeo,
  createProviderDetailsPageSeo
} from './helpers'
import { createScholarshipDetailsPageSeo } from './helpers/createScholarshipDetailsPageSeo'

type InputData = InventoryCourse | InventoryProvider | InventoryScholarship

interface DetailPageSeoSeoProps {
  pageName: string
  inputData: InputData
  pageUrl: string
  canonicalUrl: string
}

interface OpenGraphMap {
  [resultsPageType: string]: (data: InputData, pageUrl: string) => OpenGraph
}

enum ResultsPageType {
  CourseDetailsPage = 'inventoryCourse',
  ProviderDetailsPage = 'inventoryProvider',
  ScholarshipDetailsPage = 'inventoryScholarship'
}

const openGraphMap: OpenGraphMap = Object.freeze({
  [ResultsPageType.CourseDetailsPage]: (inputData, pageUrl) =>
    createCourseDetailPageSeo({
      inputData: inputData as InventoryCourse,
      pageUrl
    }),
  [ResultsPageType.ProviderDetailsPage]: (inputData, pageUrl) =>
    createProviderDetailsPageSeo({
      inputData: inputData as InventoryProvider,
      pageUrl
    }),
  [ResultsPageType.ScholarshipDetailsPage]: (inputData, pageUrl) =>
    createScholarshipDetailsPageSeo({
      inputData: inputData as InventoryScholarship,
      pageUrl
    })
})

const DetailPageSeo = ({
  inputData,
  canonicalUrl,
  pageUrl,
  pageName
}: DetailPageSeoSeoProps): ReactElement | null => {
  const { __typename: detailPageTypename } = inputData
  let openGraphMeta: OpenGraph | null = null

  if (!detailPageTypename || !openGraphMap[detailPageTypename]) {
    return null
  }

  openGraphMeta = openGraphMap[detailPageTypename](inputData, pageUrl)

  return (
    <NextSeo
      title={pageName}
      description={openGraphMeta.description}
      canonical={canonicalUrl}
      openGraph={openGraphMeta}
    />
  )
}

export default DetailPageSeo
