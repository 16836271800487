import algoliasearch from 'algoliasearch'
import { useEffect, useState } from 'react'
import { getUniqueAttributeValuesFromHits } from 'utils/src/helpers'

interface UseBrowseObjectsFetch {
  attributes: string[]
  indexName: string
  filters?: string
}
interface ObjectWithObjectID {
  objectID: string
}

type BrowseObjectResultItem<HitResult> = HitResult &
  ObjectWithObjectID &
  Record<string, string>
type BrowseObjectResult<HitResult> = BrowseObjectResultItem<HitResult>[]

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID ?? '',
  process.env.NEXT_PUBLIC_ALGOLIA_BROWSE_INDEX_API_KEY ?? ''
)

export function getFacetAttributeValues<HitResult>(
  items: BrowseObjectResult<HitResult>,
  attribute: string
) {
  const values = getUniqueAttributeValuesFromHits(items, attribute)

  return values.map((item) => ({
    label: item,
    value: item,
    key: item
  }))
}

export default function useBrowseObjectsFetch<HitResult>({
  attributes,
  indexName,
  filters = ''
}: UseBrowseObjectsFetch) {
  const [oneShotFetch, setOneShotFetched] = useState<boolean>(true)
  const [items, setItems] = useState<BrowseObjectResult<HitResult> | []>([])
  const index = searchClient.initIndex(indexName)

  useEffect(() => {
    if (oneShotFetch) {
      const getHits = async () => {
        try {
          let hits: BrowseObjectResult<HitResult> = []

          index
            .browseObjects<HitResult>({
              query: '', // Empty query will match all records
              filters,
              attributesToRetrieve: attributes,
              batch: (batch) => {
                hits = hits.concat(batch)
              }
            })
            .then(() => {
              setItems(hits)
            })
        } catch (error) {
          console.error(
            `Fetching algolia objects with useBrowseObjectsFetch for the attributes ${attributes} has failed`,
            error
          )
        }
      }

      getHits()
      setOneShotFetched(false)
    }
  }, [attributes, oneShotFetch, index, filters])

  return items
}

export type { ObjectWithObjectID, BrowseObjectResult, BrowseObjectResultItem }
