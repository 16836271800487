import {
  AspectRatio,
  Box,
  Divider,
  Flex,
  GridItem,
  Text
} from '@chakra-ui/react'
import { ChevronIcon } from 'icons'
import { ReactElement } from 'react'
import { GridLayout, H3 } from 'ui'
import { CallToAction, CtaButton } from '../../buttons'
export interface TileItemsProps {
  tilesToRenderArray?: TileItem[] | undefined
  columns?: number | undefined
  leadCaptureFormId?: string
}

export interface TileItem {
  title: string | null
  subtitle?: string | null
  bodyText: ReactElement | null
  image?: ReactElement | null
  primaryCta?: CallToAction
  secondaryCta?: CallToAction
}

const TileItems = ({
  tilesToRenderArray,
  columns = 2,
  leadCaptureFormId
}: TileItemsProps): ReactElement => {
  return (
    <Box
      data-testid='tile-container'
      gridTemplateColumns={`repeat(${columns}, 1fr)`}
    >
      <GridLayout
        templateColumns={{
          sm: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: `repeat(${columns}, 1fr)`
        }}
        gap={{ base: 10, md: 6 }}
      >
        {tilesToRenderArray &&
          tilesToRenderArray.map((tileItem: TileItem, index) => {
            const {
              title,
              subtitle,
              bodyText,
              image,
              primaryCta,
              secondaryCta
            } = tileItem

            return (
              <GridItem key={index}>
                {image ? (
                  <AspectRatio ratio={3 / 2} marginBottom={4}>
                    {image}
                  </AspectRatio>
                ) : (
                  <Divider
                    borderColor='deepblue.200'
                    mb={[4, null, 6]}
                    mt={0}
                    orientation='horizontal'
                  />
                )}
                <H3 size='lg' mb={subtitle ? 1 : 4}>
                  {title}
                </H3>
                {subtitle && (
                  <Text
                    fontSize='sm'
                    colorScheme='deepblue'
                    color='deepblue.300'
                    lineHeight='none'
                    fontFamily='enz500'
                    sx={{
                      'html:not([lang=en]) &': {
                        fontWeight: '700'
                      }
                    }}
                  >
                    {subtitle}
                  </Text>
                )}
                {bodyText && <Box mt={2}>{bodyText}</Box>}
                <Flex flexDirection='row' flexWrap='wrap'>
                  {primaryCta?.label && (
                    <Box mt={4}>
                      <CtaButton
                        colorScheme='teal'
                        variant='outline'
                        href={primaryCta.href}
                        openInNewWindow={primaryCta.openInNewWindow}
                        isExternalLink={primaryCta.isExternalLink}
                        externalIconSize={6}
                        aria-label={primaryCta.label}
                        marginRight={4}
                        modalId={primaryCta.modalId}
                        leadCaptureFormId={leadCaptureFormId}
                      >
                        {primaryCta.label}
                      </CtaButton>
                    </Box>
                  )}

                  {secondaryCta?.label && (
                    <Box mt={4}>
                      <CtaButton
                        colorScheme='teal'
                        leftIcon={
                          <Box boxSize={4} fill={'currentColor'}>
                            <ChevronIcon />
                          </Box>
                        }
                        variant='link'
                        href={secondaryCta.href}
                        openInNewWindow={secondaryCta.openInNewWindow}
                        isExternalLink={secondaryCta.isExternalLink}
                        externalIconSize={6}
                        aria-label={secondaryCta.label}
                        modalId={secondaryCta.modalId}
                        leadCaptureFormId={leadCaptureFormId}
                      >
                        {secondaryCta.label}
                      </CtaButton>
                    </Box>
                  )}
                </Flex>
              </GridItem>
            )
          })}
      </GridLayout>
    </Box>
  )
}

export default TileItems
