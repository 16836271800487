import { Text, TextProps } from '@chakra-ui/react'
import React from 'react'

export const LargeText = (props: TextProps) => (
  <Text
    fontSize={{ base: '20px', lg: '22px' }}
    lineHeight={{ base: '30px', lg: 9 }}
    marginBottom={6}
    {...props}
  />
)

export const BoldText = (props: TextProps) => (
  <Text fontFamily='enz700' {...props} />
)

export const SmallText = (props: TextProps) => (
  <Text fontSize='sm' lineHeight={7} marginBottom={3} {...props} />
)

export const SmallBoldText = (props: TextProps) => (
  <SmallText fontFamily='enz500' marginBottom={3} {...props} />
)

export const MediumBoldText = (props: TextProps) => (
  <Text fontFamily='enz500' {...props} />
)
