import {
  CardWithLink_Swnz_CardWithLinkFragment,
  CardWithModal_Swnz_CardWithModalFragment
} from 'content-service'
import { isPresent } from 'ts-is-present'
import { IMAGE_SIZE_CONFIG } from 'utils'
import { createHref } from 'utils/src/helpers'
import { RegionCardProps } from '../../../components/content-components'
import { OptimisedImage } from '../../../components/Image'
import { RegionCardType } from '../RegionCardsCarouselConnect'

export interface CreateRegionCardItems {
  cardLinkItems?: Array<CardWithLink_Swnz_CardWithLinkFragment | null>
  cardModalItems?: Array<CardWithModal_Swnz_CardWithModalFragment | null>
}

export const createRegionCardItems = ({
  cardLinkItems,
  cardModalItems
}: CreateRegionCardItems): Array<RegionCardProps> | [] => {
  if (!cardLinkItems && !cardModalItems) {
    return []
  }

  const linkItems = cardLinkItems
    ? cardLinkItems.filter(isPresent).map((item) => {
        return {
          id: item?.sys?.id,
          type: RegionCardType.Link,
          title: item?.title ?? '',
          description: item?.description ?? '',
          image: OptimisedImage({
            src: item.image?.source?.url ?? '',
            alt: item.image?.source?.description ?? '',
            title: item.image?.source?.title ?? '',
            imageSizeConfig: IMAGE_SIZE_CONFIG.REGION_CARD
          }),
          link: {
            slug: createHref({
              href: item?.customUrl,
              internalLink: item?.link
            }),
            external: Boolean(item?.customUrl) && !item?.link,
            title: item?.link?.title ?? ''
          },
          tag: item?.contentfulMetadata?.tags?.[0]?.name ?? ''
        }
      })
    : []

  const modalItems = cardModalItems
    ? cardModalItems.filter(isPresent).map((item) => {
        return {
          id: item?.sys?.id,
          type: RegionCardType.Modal,
          title: item?.title ?? '',
          description: item?.description ?? '',
          image: OptimisedImage({
            src: item.image?.source?.url ?? '',
            alt: item.image?.source?.description ?? '',
            title: item.image?.source?.title ?? '',
            imageSizeConfig: IMAGE_SIZE_CONFIG.REGION_CARD
          }),
          modalId: item?.modalContent?.sys.id
        }
      })
    : []

  return [...linkItems, ...modalItems]
}
