import { CheckboxGroup, Stack } from '@chakra-ui/react'
import {
  connectRefinementList,
  RefinementListExposed,
  RefinementListProvided
} from 'react-instantsearch-core'
import RefinementCheckbox from './RefinementCheckbox'

// Make very large large to show all attributes
const DEFAULT_FACET_LIMIT = 100

export type RefinementListProps = Pick<
  RefinementListProvided,
  'items' | 'refine'
>

const Refinement = ({ items, refine }: RefinementListProps) => {
  return (
    <CheckboxGroup>
      <Stack spacing={1}>
        {items.map((item) => {
          return (
            <RefinementCheckbox
              label={item.label}
              key={item.label}
              count={item.count}
              isChecked={item.isRefined}
              onChange={(event) => {
                event.preventDefault()
                refine(item.value)
              }}
            />
          )
        })}
      </Stack>
    </CheckboxGroup>
  )
}

export { Refinement }

const ConnectedRefinement = connectRefinementList(Refinement)
const FacetRefinement = ({
  limit = DEFAULT_FACET_LIMIT,
  ...props
}: RefinementListExposed) => <ConnectedRefinement limit={limit} {...props} />
export default FacetRefinement
