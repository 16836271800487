import { AspectRatio, Box, Center } from '@chakra-ui/react'
import {
  MediaVideo_Swnz_MediaVideoFragment,
  MediaVideo_Swnz_MediaVideoFragmentDoc
} from 'content-service'
import { ImageLoadingPreference, IMAGE_SIZE_CONFIG } from 'utils'
import { getPageThemeBackgroundColour } from 'utils/src/helpers'
import useFragment from 'utils/src/hooks/useFragment'
import { ContainerWrapper, OptimisedImage } from '../../components'
import { MediaVideo } from '../../components/content-components'
import { Connector } from '../types'

export interface MediaVideoConnectProps extends Connector {
  imageLoadingPreference?: ImageLoadingPreference
  imagePriority?: boolean
  pageTheme?: string
}

const MediaVideoConnect = ({
  id,
  typename,
  pageTheme,
  ...elementProps
}: MediaVideoConnectProps) => {
  const mediaVideo = useFragment<MediaVideo_Swnz_MediaVideoFragment>({
    id,
    typename,
    fragment: MediaVideo_Swnz_MediaVideoFragmentDoc,
    fragmentName: 'mediaVideo_SWNZ_MediaVideo'
  })

  if (!mediaVideo) {
    return null
  }

  const thumbnailImage = OptimisedImage({
    src: mediaVideo?.thumbnail?.url || '',
    alt: mediaVideo?.thumbnail?.description || '',
    imageSizeConfig: IMAGE_SIZE_CONFIG.MEDIA_VIDEO,
    priority: elementProps.imagePriority
  })

  const setBackgroundColour = getPageThemeBackgroundColour(pageTheme)

  return (
    <ContainerWrapper bgColor={setBackgroundColour}>
      <Center>
        <Box width='full' position='relative'>
          <MediaVideo
            sourceUrl={mediaVideo.sourceUrl || ''}
            thumbnailUrlTitle={mediaVideo.thumbnail?.title || ''}
          >
            <AspectRatio ratio={16 / 9}>{thumbnailImage}</AspectRatio>
          </MediaVideo>
        </Box>
      </Center>
    </ContainerWrapper>
  )
}

export default MediaVideoConnect
