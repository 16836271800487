import EducationFormConnect, { EducationFairFormConnectProps } from './EducationFairForm'
import EnquiryFormConnect, { EnquiryFormConnectProps } from './EnquiryForm'
import HomepageHeroConnect from './HomepageHero'
import HomepagePanelConnect from './HomepagePanel'
import HomepageStoryPanelConnect from './HomepageStoryPanel'

export {
  EducationFormConnect,
  EnquiryFormConnect,
  HomepageStoryPanelConnect,
  HomepageHeroConnect,
  HomepagePanelConnect,
}
export type { EducationFairFormConnectProps, EnquiryFormConnectProps }
