import {
  Cta_Swnz_CallToActionFragment,
  KeepExploring_Swnz_KeepExploringFragment,
  KeepExploring_Swnz_KeepExploringFragmentDoc
} from 'content-service'
import { isPresent } from 'ts-is-present'
import { createCtaButton } from 'utils/src/helpers'
import useFragment from 'utils/src/hooks/useFragment'
import { CallToAction } from '../../components/buttons'
import { KeepExploring } from '../../components/content-components'
import { Connector } from '../types'

export type KeepExploringConnectProps = Connector
export interface CreateLinksProps {
  listItems?: Array<Cta_Swnz_CallToActionFragment>
}

export const createLinks = ({
  listItems = []
}: CreateLinksProps): Array<CallToAction> => {
  const listData: Array<CallToAction> = []

  for (const listItem of listItems) {
    const cta = createCtaButton(listItem)
    if (cta) {
      listData.push(cta)
    }
  }

  return listData
}

const KeepExploringConnect = ({
  id,
  typename,
  leadCaptureFormId
}: KeepExploringConnectProps) => {
  const keepExploring = useFragment<KeepExploring_Swnz_KeepExploringFragment>({
    id,
    typename,
    fragment: KeepExploring_Swnz_KeepExploringFragmentDoc,
    fragmentName: 'keepExploring_SWNZ_KeepExploring'
  })

  if (!keepExploring) {
    return null
  }

  const { title, ctaLinksCollection } = keepExploring || {}
  const { items = [] } = ctaLinksCollection || {}
  const links = createLinks({ listItems: items.filter(isPresent) })

  return (
    <KeepExploring
      title={title || ''}
      links={links}
      leadCaptureFormId={leadCaptureFormId}
    />
  )
}

export default KeepExploringConnect
