import { fontList, fonts } from 'theme/src'

function Font() {
  return (
    <>
      {fontList
        .filter((font) => font.prefetch)
        .map((font, index) => (
          <link
            key={index}
            rel='preload'
            href={font.fileName}
            as='font'
            type={`font/${font.type}`}
            crossOrigin='anonymous'
          />
        ))}
      <style dangerouslySetInnerHTML={{ __html: fonts }} />
    </>
  )
}

export default Font
