import FinderDetailBanner, {
  FinderDetailBannerContactProps,
  FinderDetailBannerCtaProps,
  FinderDetailBannerProps
} from './FinderDetailBanner'
export default FinderDetailBanner
export type {
  FinderDetailBannerContactProps,
  FinderDetailBannerProps,
  FinderDetailBannerCtaProps
}
