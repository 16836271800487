import { BulletPoint_Swnz_BulletPointFragment } from 'content-service'
import { BulletItem } from '../../../components'
import { RichTextRenderer } from '../../../components/RichTextRenderer'

const createBulletItemDataStructureObject = (
  bulletItem: BulletPoint_Swnz_BulletPointFragment
): BulletItem => {
  const { bodyText, icon } = bulletItem || {}

  return {
    bodyText: bodyText ? <RichTextRenderer richText={bodyText} /> : null,
    iconUrl: icon?.source?.url ?? undefined,
    imageName: icon?.source?.title ?? undefined
  }
}

export const createBulletItemsToRenderData = (
  bulletItems: Array<BulletPoint_Swnz_BulletPointFragment | null>
): Array<BulletItem> => {
  const bulletItemsToRenderData: Array<BulletItem> = []

  for (const bulletItem of bulletItems) {
    if (bulletItem !== null) {
      bulletItemsToRenderData.push(
        createBulletItemDataStructureObject(bulletItem)
      )
    }
  }

  return bulletItemsToRenderData
}
