import { useAuth0 } from '@auth0/auth0-react'
import { FormPopup, FORM_CONSTANTS } from 'ui'
import LeadCaptureForm from '../LeadCaptureForm'

const LeadCaptureFormPopup = () => {
  const { isAuthenticated, isLoading } = useAuth0()
  return (
    <FormPopup
      id={'lead-capture-popup'}
      cookieName={FORM_CONSTANTS.LEAD_CAPTURE.HIDE_POPUP_COOKIE}
      heading={{
        initial: 'Take the next step',
        onSuccess: 'Stay in touch',
      }}
      bodyText={{
        initial:
          'Sign up to learn more and stay up to date with the latest information on studying with New Zealand.',
        onSuccess:
          'Learn more and stay up to date with the latest information on studying with New Zealand.',
      }}
      successMessage={FORM_CONSTANTS.LEAD_CAPTURE.SUCCESS_MESSAGE}
      errorMessage={FORM_CONSTANTS.LEAD_CAPTURE.ERROR_MESSAGE}
      isAuthenticated={isAuthenticated}
      isAuthStateLoading={isLoading}
      hideUntilForcedOpen={true}
    >
      {({ handleFormSuccess, handleFormError }) => (
        <LeadCaptureForm
          onSuccessCallback={handleFormSuccess}
          onErrorCallback={handleFormError}
          tealium={{
            source: 'SWNZ Lead Collection Pop Up',
            formName: 'pop-up',
            formType: 'lead',
          }}
        />
      )}
    </FormPopup>
  )
}

export default LeadCaptureFormPopup
