import React from 'react'
import { GridProps, Grid } from '@chakra-ui/react'

const GridLayout = (props: GridProps) => {
  return (
    <Grid
      templateColumns={{
        base: 'repeat(4, 1fr)',
        md: 'repeat(8, 1fr)',
        lg: 'repeat(12, 1fr)'
      }}
      gap={{ base: 4, md: 6 }}
      {...props}
    />
  )
}
export default GridLayout
