import { AspectRatio, Box, GridItem, Text } from '@chakra-ui/react'
import { MotionValue } from 'framer-motion'
import { ChevronIcon } from 'icons'
import useTranslation from 'next-translate/useTranslation'
import { ReactElement, ReactNode } from 'react'
import { BoldText, Button, GridLayout, Headline } from 'ui'
import { createInitStoryModalCustomEvent } from 'utils/src/helpers'
import { MotionBox } from '../helpers'

interface GridProps {
  y?: MotionValue<number>
}

interface MissionProps extends GridProps {
  tab?: {
    quoteText: string
    quoteAuthor: string
    quoteStudyLocation: string
  }
}

export const Mission = ({ y, tab }: MissionProps) => {
  return (
    <GridItem
      colSpan={{ base: 4, lg: 3 }}
      rowStart={1}
      colStart={{
        lg: 3,
      }}
      marginTop={{ base: '-90px', lg: '-50%' }}
      marginRight={-6}
      marginLeft={{ base: 8, lg: 'unset' }}
    >
      <MotionBox
        style={{
          y: y,
        }}
        width="fit-content"
        backgroundColor="surfaceBlue"
        color="white"
        padding={{
          base: '38px 38px 24px',
          lg: '30px 40px',
        }}
        position="relative"
      >
        <Box
          boxSize={16}
          fill="white"
          position="absolute"
          left={-8}
          top={8}
          sx={{
            'html[lang=ar] &': {
              // vertically flips svg
              transform: 'scaleX(-1)',
            },
          }}
        >
          <ChevronIcon />
        </Box>
        <Headline
          fontSize={{ base: 36, lg: 44 }}
          mb={{ base: 6 }}
          sx={{
            'html:not([lang=en]) &': {
              fontSize: { base: '30px', lg: '36px' },
              lineHeight: { base: '36px', lg: '40px' },
            },
          }}
          zIndex={2}
        >
          {tab?.quoteText}
        </Headline>
        <BoldText fontSize={{ base: 16 }} mb={0} zIndex={2}>
          {tab?.quoteAuthor}
        </BoldText>
        <Text fontWeight="hairline" fontSize={{ base: 16 }} mb={0} zIndex={2}>
          {tab?.quoteStudyLocation}
        </Text>
      </MotionBox>
    </GridItem>
  )
}

interface IntroductionProps extends GridProps {
  children: ReactNode
}

export const Introduction = ({ y, children }: IntroductionProps) => {
  return (
    <GridItem
      id="text_introduction"
      fontSize={{ base: '16px' }}
      colSpan={{ base: 4, lg: 4 }}
      colStart={{ lg: 2 }}
      rowStart={{ lg: 2 }}
      mt={{ base: 'none', lg: '80px' }}
    >
      <MotionBox style={{ y }}>{children}</MotionBox>
    </GridItem>
  )
}

interface HeroTabGridImageOneProps extends GridProps {
  gridImageOne?: ReactElement | null
}

export const HeroTabGridImageOne = ({ y, gridImageOne }: HeroTabGridImageOneProps) => {
  return (
    <GridItem
      colSpan={{ base: 4, lg: 5 }}
      colStart={{ lg: 8 }}
      rowStart={{ lg: 2 }}
      mt={{ lg: '-80px' }}
      mb={{ lg: '80px' }}
    >
      <MotionBox style={{ y: y }}>
        <AspectRatio ratio={4 / 3}>{gridImageOne && gridImageOne}</AspectRatio>
      </MotionBox>
    </GridItem>
  )
}

interface HeroTabGridImageTwoProps {
  gridImageTwo: ReactElement | null
}

export const HeroTabGridImageTwo = ({ gridImageTwo }: HeroTabGridImageTwoProps) => {
  return (
    <GridItem
      colSpan={{ base: 4, lg: 7 }}
      colStart={{ lg: 2 }}
      rowStart={{ lg: 3 }}
      ml={{ base: -6, lg: 'unset' }}
      mr={{ base: -6, lg: 'unset' }}
    >
      <AspectRatio ratio={16 / 9}>{gridImageTwo && gridImageTwo}</AspectRatio>
    </GridItem>
  )
}

interface StoryTitleProps extends GridProps {
  storyTitle: string
  children: ReactNode
}

export const StoryTitle = ({ y, storyTitle, children }: StoryTitleProps) => {
  return (
    <GridItem
      id="story_title"
      whiteSpace="pre-wrap"
      colSpan={{ base: 4, lg: 6 }}
      colStart={{ lg: 8, xxl: 7 }}
      rowStart={{ lg: 3 }}
    >
      <MotionBox style={{ y }}>
        <GridLayout
          templateColumns={{
            base: 'repeat(4, 1fr)',
            md: 'repeat(6, 1fr)',
            lg: 'repeat(6, 1fr)',
          }}
          gap={{ base: 0, md: 6 }}
          m={{ base: 0 }}
        >
          <Headline
            gridColumn={{ base: 'span 4', lg: 'span 6' }}
            mb={6}
            zIndex={2}
            sx={{
              'html:not([lang=en]) &': {
                fontSize: { base: '30px', lg: '50px' },
              },
            }}
          >
            {storyTitle}
          </Headline>
          <Box
            gridRow={2}
            gridColumn={{ base: 'span 4', lg: '3 / span 4' }}
            zIndex={2}
            paddingBottom={{ base: 16, md: 0 }}
          >
            {children}
          </Box>
        </GridLayout>
      </MotionBox>
    </GridItem>
  )
}

interface StoryLearnMoreButtonProps {
  modalId: string | undefined
}

export const StoryLearnMoreButton = ({ modalId }: StoryLearnMoreButtonProps) => {
  const { t } = useTranslation('homepage')

  return modalId ? (
    <Button
      colorScheme="white"
      variant="link"
      rightIcon={
        <Box
          fill="white"
          boxSize={4}
          sx={{
            'html[lang=ar] &': {
              // vertically flips svg
              transform: 'scaleX(-1)',
            },
          }}
        >
          <ChevronIcon />
        </Box>
      }
      onClick={() => createInitStoryModalCustomEvent(modalId, 'Learn more')}
      mt={4}
      aria-label="learn more"
    >
      {t('story_learn_more_button')}
    </Button>
  ) : null
}
