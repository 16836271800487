import type { SeoProps } from '../PageSeo'

type PageSeoData = {
  title?: string
  name?: string
  description?: string
  keywords?: string
  noindex?: boolean
  nofollow?: boolean
}

export const validatePageSeoData = (
  pageSeo: SeoProps
): { nextSeoProps: PageSeoData; nextSeoKeywords: string } => {
  const { keywords, ...rest } = pageSeo
  const nextSeoProps: { [key: string]: string | boolean } = {}
  const nextSeoKeywords = Array.isArray(keywords) ? keywords.toString() : ''

  Object.entries(rest).forEach(([key, value]) => {
    if (value === null) return

    nextSeoProps[key] = value
  })

  return { nextSeoKeywords, nextSeoProps }
}
