import { connectRefinementList, SearchState } from 'react-instantsearch-core'
import {
  FinderFacetMenu,
  FinderSearchButton,
  FinderWidget,
  InstantSearchWrapper,
  SearchAutocomplete,
} from 'ui'
import { searchPlugins } from 'ui/src/components/Search/SearchAutocomplete'
import { createConfig } from 'utils/src/helpers/algoliaSearch'
import { ALGOLIA_INDICES, ROUTES } from '../../../constants'

interface ProviderFinderWidgetProps {
  initialSearchState?: SearchState
}

const { searchClient, indexName } = createConfig({
  indexName: ALGOLIA_INDICES.PROVIDER_FINDER.MAIN,
})

const providerDetailPlugin = searchPlugins.createProviderDetailPlugin({
  searchClient,
  indexName,
  filters: '',
})

const VirtualRefinementList = connectRefinementList(() => null)

const ProviderFinderWidget = ({ initialSearchState = {} }: ProviderFinderWidgetProps) => {
  const TYPE_NAME_ATTRIBUTE = 'type.name'
  const SUBTYPE_LIST_NAME_ATTRIBUTE = 'subtypeList.name'

  return (
    <InstantSearchWrapper
      searchState={initialSearchState}
      searchClient={searchClient}
      indexName={indexName}
    >
      <FinderWidget heading="Find an education provider">
        <FinderWidget.Input minWidth="220px" width={{ base: 'full', md: '50%' }}>
          <FinderFacetMenu
            heading="Provider type"
            attribute={SUBTYPE_LIST_NAME_ATTRIBUTE}
            placeholder="Any provider type"
          />
        </FinderWidget.Input>

        <VirtualRefinementList attribute={TYPE_NAME_ATTRIBUTE} />
        <FinderWidget.Input width={{ base: 'full' }}>
          <SearchAutocomplete
            title="Know the name of the education provider?"
            placeholder="Search by name or keyword"
            facet={TYPE_NAME_ATTRIBUTE}
            resultsPath={ROUTES.PROVIDER.RESULTS}
            dropdownTitle="Suggested education providers"
            plugins={[providerDetailPlugin]}
            showLabel={false}
            shouldRedirectOnSubmit={true}
          />
        </FinderWidget.Input>
        <FinderWidget.SearchButton>
          <FinderSearchButton resultsPagePath={ROUTES.PROVIDER.RESULTS} />
        </FinderWidget.SearchButton>
      </FinderWidget>
    </InstantSearchWrapper>
  )
}

export default ProviderFinderWidget
