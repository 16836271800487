import { Box, Center } from '@chakra-ui/react'
import { ChevronIcon } from 'icons'
import { Fragment, ReactElement } from 'react'
import { BoldText, CallToAction, CtaButton, H4 } from 'ui'
import IsNewBadge from '../IsNewBadge'

export interface CardItemProps {
  title: string
  subtitle?: string
  isNew: boolean
  bodyText: ReactElement
  image: ReactElement | null
  primaryCta?: CallToAction
}

const PrimaryCtaButton = ({
  label,
  href,
  openInNewWindow,
  isExternalLink,
  modalId,
}: CallToAction) => (
  <Box>
    <CtaButton
      colorScheme="white"
      mt={4}
      variant="link"
      rightIcon={
        <Box
          boxSize={4}
          fill={'currentColor'}
          sx={{
            // flip chevron icon
            'html[lang=ar] &': {
              transform: 'scaleX(-1) ',
            },
          }}
        >
          <ChevronIcon />
        </Box>
      }
      href={href}
      openInNewWindow={openInNewWindow}
      isExternalLink={isExternalLink}
      aria-label={label}
      modalId={modalId}
    >
      {label}
    </CtaButton>
  </Box>
)

const CardItem = ({ title, isNew, image, subtitle, bodyText, primaryCta }: CardItemProps) => {
  return (
    <Fragment>
      <Center
        backgroundColor="whiteAlpha.200"
        borderRadius="full"
        width={{ base: '258px', xl: '344px' }}
        height={{ base: '258px', xl: '344px' }}
        mx="auto"
        padding={16}
        mb={10}
        position="relative"
      >
        <IsNewBadge active={isNew} label="New" />
        {image && (
          <Box as="span" position="relative" width="100%" height="100%">
            {image}
          </Box>
        )}
      </Center>
      <H4 display={{ base: 'none', md: 'block' }}>{title}</H4>
      {subtitle && <BoldText>{subtitle}</BoldText>}

      {bodyText && (
        <Box
          sx={{
            a: {
              textDecoration: 'none',
              color: 'white',
              fontFamily: 'enz700',
              '&:hover': {
                color: 'deepblue.500',
              },
            },
          }}
        >
          {bodyText}
        </Box>
      )}
      {primaryCta && <PrimaryCtaButton {...primaryCta} />}
    </Fragment>
  )
}

export default CardItem
