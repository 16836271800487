import { ApolloLink } from '@apollo/client'
import isServer from '../isServer'
import auth from './auth'
import clientHttpLink from './client'
import error from './error'
import previewHttpLink from './preview'
import retry from './retry'
import serverHttpLink from './server'

const clientLink = () => ApolloLink.from([retry, error, clientHttpLink])

const serverLink = (preview = false) =>
  ApolloLink.from([
    retry,
    auth,
    error,
    preview ? previewHttpLink : serverHttpLink
  ])

const link = (preview = false) =>
  isServer() ? serverLink(preview) : clientLink()

export default link
