import { TEALIUM_EVENT, TEALIUM_POPUP_RESULT } from "../helpers/constants";

interface PopupView {
  popup_id: string;
  popup_name: string;
  popup_testcell: string;
  tealium_visitor_id: string;
}

interface PopupViewResult extends PopupView {
  tealium_event: TEALIUM_EVENT;
}

/**
 * createPopupViewUtagEventObject
 * Build the data for a popup_view event
 */
function createPopupViewUtagEventObject({
  popup_id = "",
  popup_name = "",
  popup_testcell = "",
  tealium_visitor_id = "",
}: PopupView): PopupViewResult {
  return {
    tealium_event: TEALIUM_EVENT.POPUP_VIEWED,
    popup_id,
    popup_name,
    popup_testcell,
    tealium_visitor_id,
  };
}

interface PopupResponse extends PopupView {
  popup_result: (typeof TEALIUM_POPUP_RESULT)[keyof typeof TEALIUM_POPUP_RESULT];
}

interface PopupResponseResult extends PopupResponse {
  tealium_event: TEALIUM_EVENT;
}

/**
 * createPopupResponseUtagEventObject
 * Build the data for a popup_response event
 */
function createPopupResponseUtagEventObject({
  popup_id = "",
  popup_name = "",
  popup_testcell = "",
  tealium_visitor_id = "",
  popup_result,
}: PopupResponse): PopupResponseResult {
  return {
    tealium_event: TEALIUM_EVENT.POPUP_RESPONSE,
    popup_id,
    popup_name,
    popup_testcell,
    tealium_visitor_id,
    popup_result,
  };
}

export { createPopupViewUtagEventObject, createPopupResponseUtagEventObject };
export type { PopupView, PopupViewResult, PopupResponse, PopupResponseResult };
