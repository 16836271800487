import { useAuth0 } from '@auth0/auth0-react'
import { getStudyOptions, useUser } from '../context'

function useGetStudyOptions() {
  const {
    userState: { profile },
    userDispatch,
  } = useUser()
  const { getAccessTokenSilently } = useAuth0()

  async function handleGetStudyOptions() {
    try {
      const accessToken = await getAccessTokenSilently()
      await getStudyOptions(userDispatch, profile, accessToken)
    } catch (error) {
      console.log(error)
    }
  }

  return handleGetStudyOptions
}

export default useGetStudyOptions
