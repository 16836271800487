import { Box } from '@chakra-ui/react'
import { FilterIcon } from 'icons'
import { CurrentRefinementsProvided } from 'react-instantsearch-core'
import { Button } from '../../buttons'
import { connectCurrentRefinements } from '../Algolia'

interface FilterButton extends Pick<CurrentRefinementsProvided, 'items'> {
  onOpen: () => void
}

const FilterButton = ({ items, onOpen }: FilterButton) => {
  return (
    <Button
      variant='outline'
      colorScheme='deepblue'
      onClick={onOpen}
      flexShrink={0}
      width={{ base: 'full', lg: 'unset' }}
      rightIcon={
        <Box boxSize={6}>
          <FilterIcon />
        </Box>
      }
    >
      Filters {Boolean(items.length) && `(${items.length})`}
    </Button>
  )
}

const FilterButtonComponent = ({
  onOpen,
  ignoreRefinements
}: FilterButton & { ignoreRefinements?: string[] }) => {
  const ComponentWith = connectCurrentRefinements<FilterButton>(
    FilterButton,
    ignoreRefinements
  )

  // @ts-ignore: This needs to be remedied, difficulty in typing a "HOC'd" HOC. See connectCurrentRefinment function.
  return <ComponentWith onOpen={onOpen} />
}

export default FilterButtonComponent
