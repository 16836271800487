import {
  Box,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader
} from '@chakra-ui/react'
import { ReactNode } from 'react'
import { PopoverTrigger } from '../../PopoverTrigger'
import { SmallBoldText, SmallText } from '../../typography'

export interface GlossaryProps {
  term?: string
  definition?: string
  children?: ReactNode
}

const Glossary = ({ term, definition, children }: GlossaryProps) => (
  <Popover placement='top' offset={[0, 16]} flip={false}>
    <PopoverTrigger>
      <Box
        as='span'
        role='button'
        borderBottom='2px'
        borderBottomStyle='dotted'
      >
        {children}
      </Box>
    </PopoverTrigger>
    <PopoverContent
      padding={5}
      maxW={312}
      borderColor='teal.10'
      borderRadius={0}
      color='deepblue.500'
    >
      <PopoverHeader
        border='none'
        padding={0}
        mb={2}
        display='flex'
        justifyContent='space-between'
      >
        <SmallBoldText mb={0}>{term}</SmallBoldText>
        <PopoverCloseButton
          position='relative'
          alignItems='center'
          boxSize='22px'
          fontSize={12}
          padding={0}
        />
      </PopoverHeader>
      <PopoverBody padding={0} sx={{ 'p:last-of-type': { marginBottom: 0 } }}>
        <SmallText fontFamily='enz400'>{definition}</SmallText>
      </PopoverBody>
    </PopoverContent>
  </Popover>
)

export default Glossary
