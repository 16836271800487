import { Link, LinkProps } from '@chakra-ui/next-js'
import { useRouter } from 'next/router'
import { getHrefForLocale } from 'utils'

export type NextLinkProps = LinkProps & {
  dataTestid?: string
  // Shouldn't need isExternal here but we are waiting for https://github.com/chakra-ui/chakra-ui/pull/8028
  // to be merged
  isExternal?: boolean
}

const NextChakraLink = ({
  children,
  dataTestid,
  ...chakraProps
}: NextLinkProps) => {
  const { locale: currentLocale, locales, defaultLocale } = useRouter()
  const { href, isExternal } = chakraProps
  /**
   * If we're in the Default or En locales we want ensure our links are prefixed with
   *  /en so that the middleware doesn't have to do a redirect
   */

  const localeHref =
    getHrefForLocale(href, currentLocale, locales, defaultLocale) ?? ''

  return (
    <Link
      data-testid={dataTestid}
      rel={isExternal ? 'noopener' : undefined}
      {...chakraProps}
      href={localeHref}
    >
      {children}
    </Link>
  )
}

export default NextChakraLink
