import { User as Auth0User } from '@auth0/auth0-react'
import {
  EnquiryAPIEndpointPayload,
  ProfileAPIValues,
  StudyOptionItemType,
  StudyOptionStatus,
} from 'ui'

interface InventoryStatus {
  itemId: string
  itemType: StudyOptionItemType
  status: StudyOptionStatus
}

async function sdFetch<RequestInterface extends RequestInit>(
  url: string,
  accessToken: string,
  fetchOptions?: RequestInterface
) {
  const res = await fetch(url, {
    ...fetchOptions,
    headers: {
      ...fetchOptions?.headers,
      Authorization: `Bearer ${accessToken}`,
    },
  })

  return await res.json()
}

async function getProfile(
  user: Auth0User | undefined,
  accessToken: string,
  fetchOptions?: RequestInit
) {
  const url = `/api/profile/${user?.sub}`
  return await sdFetch(url, accessToken, fetchOptions)
}

async function getStudyOptions(
  profile: ProfileAPIValues | null,
  accessToken: string,
  fetchOptions?: RequestInit
) {
  const url = `/api/study-options/${profile?.id}/items`
  return await sdFetch(url, accessToken, {
    ...fetchOptions,
    headers: {
      'x-auth-id': profile?.studyOptionsToken ?? '',
    },
  })
}

async function updateRecommendations(accessToken: string, fetchOptions?: RequestInit) {
  const url = `/api/profile/update`
  return await sdFetch(url, accessToken, fetchOptions)
}

async function updateInventoryStatus(
  profile: ProfileAPIValues | null,
  accessToken: string,
  body: InventoryStatus
) {
  const url = `/api/study-options/${profile?.id}/item`
  return await sdFetch(url, accessToken, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'x-auth-id': profile?.studyOptionsToken ?? '',
    },
    body: JSON.stringify({
      ...body,
      tealiumVisitorId: window?.utag?.data?.tealium_visitor_id || '',
      tealiumSessionId: window?.utag?.data?.tealium_session_id || '',
    }),
  })
}

async function enquire(
  profile: ProfileAPIValues,
  accessToken: string,
  data: EnquiryAPIEndpointPayload
) {
  const url = `${process.env.NEXT_PUBLIC_URL}/api/study-options/enquire`
  return await sdFetch(url, accessToken, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-auth-id': profile?.studyOptionsToken,
      'x-profile-id': profile?.id,
    },
    body: JSON.stringify(data),
  })
}

export { getProfile, getStudyOptions, updateRecommendations, updateInventoryStatus, enquire }
