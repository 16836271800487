import {
  GenericText_Swnz_GenericTextFragment,
  GenericText_Swnz_GenericTextFragmentDoc
} from 'content-service'
import React, { ReactElement } from 'react'
import { createCtaButton } from 'utils/src/helpers'
import useFragment from 'utils/src/hooks/useFragment'
import { GenericText } from '../../components/content-components'
import { RichTextRenderer } from '../../components/RichTextRenderer'
import { Connector } from '../types'

interface GenericTextConnectProps extends Connector {
  isIntroElement?: boolean
  pageTheme?: string
}

const GenericTextConnect: React.FC<GenericTextConnectProps> = ({
  id,
  typename,
  isIntroElement,
  pageTheme,
  leadCaptureFormId
}): ReactElement | null => {
  const genericText = useFragment<GenericText_Swnz_GenericTextFragment>({
    id,
    typename,
    fragment: GenericText_Swnz_GenericTextFragmentDoc,
    fragmentName: 'genericText_SWNZ_GenericText'
  })

  if (!genericText) {
    return null
  }

  const primaryCta = createCtaButton(genericText?.primaryCta)
  const secondaryCta = createCtaButton(genericText?.secondaryCta)

  return (
    <GenericText
      title={genericText.title ?? ''}
      primaryButton={primaryCta}
      secondaryButton={secondaryCta}
      isIntroGenericText={isIntroElement}
      themeBackgroundPosition={genericText.themeBackgroundPosition ?? ''}
      pageTheme={pageTheme}
      id={pageTheme && isIntroElement ? `${pageTheme}-intro-element` : ''}
      leadCaptureFormId={leadCaptureFormId}
      contentAlignment={genericText.contentAlignment as 'left' | 'center'}
      backgroundColour={genericText.backgroundColour}
    >
      <RichTextRenderer richText={genericText?.bodyText} />
    </GenericText>
  )
}

export default GenericTextConnect
