import { RetryLink } from '@apollo/client/link/retry'

const retryOptions = {
  delay: {
    initial: 50,
    max: 2000,
    jitter: true
  },
  attempts: {
    max: 10
  }
}

const retry = new RetryLink(retryOptions)

export default retry
