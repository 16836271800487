import { Box } from '@chakra-ui/react'
import { Hero_Swnz_HeroFragment } from 'content-service'
import { ReactElement } from 'react'
import CourseFinderWidget from 'swnz/src/components/Search/CourseFinderWidget'
import PathwaysFinderWidget from 'swnz/src/components/Search/PathwaysFinderWidget'
import ProviderFinderWidget from 'swnz/src/components/Search/ProviderFinderWidget'
import ScholarshipFinderWidget from 'swnz/src/components/Search/ScholarshipFinderWidget'
import SchoolFinderWidget from 'swnz/src/components/Search/SchoolFinderWidget'
import { FinderHero, FinderHeroProps, ReducedHero, ReducedHeroProps } from 'ui'
import {
  createCtaButton,
  createHeroImageProps,
  createHeroVideoProps,
  IMAGE_SIZE_CONFIG
} from 'utils'
import { OptimisedImage } from '../../components'
import {
  CampaignHero,
  CampaignHeroProps,
  FullBleedHero,
  FullBleedHeroProps
} from '../../components/content-components'

type Hero = Hero_Swnz_HeroFragment & {
  pageTheme?: string
  leadCaptureFormId?: string
}

interface HeroSelectProps {
  displayType: string
  hero?: Hero
  pageTheme?: string
  leadCaptureFormId?: string
}

export enum DisplayType {
  FullBleed = 'full-bleed',
  Reduced = 'reduced',
  FinderCourse = 'finder-course',
  FinderOnline = 'finder-online',
  FinderPathways = 'finder-pathway',
  FinderPHD = 'finder-phd',
  FinderMasters = 'finder-masters',
  FinderProvider = 'finder-institution',
  FinderUniversity = 'finder-universities',
  Campaign = 'campaign',
  FinderSchool = 'finder-school',
  FinderScholarship = 'finder-scholarships'
}

interface FinderWidgetMap {
  [displayType: string]: ReactElement
}

interface HeroComponentMap {
  [displayType: string]: (
    hero: Hero,
    leadCaptureFormId?: string
  ) => ReactElement
}

const createFullBleedHeroComponentProps = (hero: Hero): FullBleedHeroProps => ({
  heading: hero?.heading || '',
  backgroundColour: hero?.backgroundColour || '',
  heroBackgroundVideoUrl: hero?.heroBackgroundVideo?.url || '',
  heroBackgroundVideoTitle: hero?.heroBackgroundVideo?.title || '',
  heroBackgroundVideoDescription: hero?.heroBackgroundVideo?.description || '',
  primaryCta: createCtaButton(hero?.primaryCta),
  video: createHeroVideoProps(hero?.mediaItem),
  image: OptimisedImage({
    ...createHeroImageProps(hero?.mediaItem),
    imageSizeConfig: IMAGE_SIZE_CONFIG.FULLBLEED_HERO,
    objectPosition: '80%'
  }),
  leadCaptureFormId: hero?.leadCaptureFormId || ''
})

const createCampaignHeroComponentProps = (hero: Hero): CampaignHeroProps => ({
  heading: hero?.heading || '',
  heroBackgroundVideoUrl: hero?.heroBackgroundVideo?.url || '',
  heroBackgroundVideoTitle: hero?.heroBackgroundVideo?.title || '',
  heroBackgroundVideoDescription: hero?.heroBackgroundVideo?.description || '',
  video: createHeroVideoProps(hero?.mediaItem),
  image: OptimisedImage({
    ...createHeroImageProps(hero?.mediaItem),
    imageSizeConfig: IMAGE_SIZE_CONFIG.FULLBLEED_HERO,
    objectPosition: '80%'
  }),
  pageTheme: hero?.pageTheme ?? '',
  leadCaptureFormId: hero?.leadCaptureFormId || ''
})

const createReducedHeroComponentProps = (hero: Hero): ReducedHeroProps => ({
  heading: hero?.heading || '',
  image: OptimisedImage({
    ...createHeroImageProps(hero?.mediaItem),
    imageSizeConfig: IMAGE_SIZE_CONFIG.REDUCDED_HERO
  })
})

const createFinderHeroProps = (
  hero: Hero
): Omit<FinderHeroProps, 'finderWidget'> => ({
  heading: hero?.heading || '',
  backgroundColour: hero?.backgroundColour || '',
  image: OptimisedImage({
    ...createHeroImageProps(hero?.mediaItem),
    imageSizeConfig: IMAGE_SIZE_CONFIG.FULLBLEED_HERO,
    objectPosition: '80%'
  })
})

const finderWidgetMap: FinderWidgetMap = Object.freeze({
  [DisplayType.FinderCourse]: (
    <CourseFinderWidget
      initialSearchState={{
        menu: {
          'deliveryModes.name': 'Study in NZ',
          'countries.name': 'New Zealand'
        }
      }}
    />
  ),
  [DisplayType.FinderOnline]: (
    <CourseFinderWidget
      initialSearchState={{ menu: { 'deliveryModes.name': 'Study online' } }}
    />
  ),
  [DisplayType.FinderPHD]: (
    <CourseFinderWidget
      initialSearchState={{ menu: { 'levelOfStudy.name': 'Doctoral Degree' } }}
    />
  ),
  [DisplayType.FinderMasters]: (
    <CourseFinderWidget
      initialSearchState={{ menu: { 'levelOfStudy.name': "Master's Degree" } }}
    />
  ),
  [DisplayType.FinderProvider]: <ProviderFinderWidget />,
  [DisplayType.FinderUniversity]: (
    <ProviderFinderWidget
      initialSearchState={{ menu: { 'subtypeList.name': 'University' } }}
    />
  ),
  [DisplayType.FinderPathways]: (
    <PathwaysFinderWidget
      initialSearchState={{ menu: { 'deliveryModes.name': 'Global pathways' } }}
    />
  ),
  [DisplayType.FinderSchool]: (
    <SchoolFinderWidget
      initialSearchState={{
        refinementList: {
          subtype: [
            'Primary School',
            'Intermediate School',
            'Secondary School'
          ],
          // using countries was causing it to be set to 'market'
          'countries.name': ['New Zealand']
        }
      }}
    />
  ),
  [DisplayType.FinderScholarship]: <ScholarshipFinderWidget />
})

const heroComponentMap: HeroComponentMap = Object.freeze({
  [DisplayType.FullBleed]: function renderHeroComponent(hero) {
    return <FullBleedHero {...createFullBleedHeroComponentProps(hero)} />
  },
  [DisplayType.Campaign]: function renderHeroComponent(hero) {
    return <CampaignHero {...createCampaignHeroComponentProps(hero)} />
  },

  [DisplayType.Reduced]: function renderHeroComponent(hero) {
    return <ReducedHero {...createReducedHeroComponentProps(hero)} />
  }
})

const HeroSelect = ({
  displayType,
  hero,
  pageTheme,
  leadCaptureFormId
}: HeroSelectProps): ReactElement | null => {
  const isValidHero =
    hero?.__typename === 'SWNZ_Hero' || hero?.__typename === 'SWNZ_HeroFeatured'

  if (!hero || !isValidHero) {
    return null
  }

  if (heroComponentMap[displayType]) {
    return (
      <Box>
        {heroComponentMap[displayType]({
          ...hero,
          pageTheme,
          leadCaptureFormId
        })}
      </Box>
    )
  }

  if (finderWidgetMap[displayType]) {
    return (
      <Box>
        <FinderHero
          {...createFinderHeroProps(hero)}
          finderWidget={finderWidgetMap[displayType]}
        />
      </Box>
    )
  }

  return null
}

export default HeroSelect
