import { mode } from '@chakra-ui/theme-tools'

type Dict = Record<string, any>

const baseStyle = {
  lineHeight: 'none',
  borderRadius: 'full',
  fontFamily: 'enz700',
  fontWeight: 'unset',
  'html:not([lang=en]) &': {
    fontWeight: '700'
  },
  _focus: {
    boxShadow: 'outline'
  },
  _disabled: {
    opacity: 0.5
  }
}

function variantGhost(props: Dict) {
  const { colorScheme: c } = props

  return {
    color: mode(`${c}.600`, `${c}.500`)(props),
    bg: 'transparent',
    textAlign: 'center',
    _hover: {
      color: 'white',
      bg: mode(`${c}.500`, `${c}.400`)(props),
      _disabled: {
        color: mode(`${c}.300`, `${c}.400`)(props),
        bg: 'inherit'
      }
    },
    _active: {
      color: 'white',
      bg: mode(`${c}.600`, `${c}.500`)(props)
    }
  }
}

function variantOutline(props: Dict) {
  const { colorScheme: c } = props
  return {
    ...variantGhost(props),
    borderWidth: '2px',
    _hover: {
      ...variantGhost(props),
      borderColor: mode(`${c}.500`, `${c}.400`)(props)
    },
    _active: {
      ...variantGhost(props)
    }
  }
}

function variantSolid(props: Dict) {
  const { colorScheme: c } = props
  return {
    borderWidth: '2px',
    textAlign: 'center',
    borderColor: mode(`${c}.500`, `${c}.500`),
    _active: { bg: mode(`${c}.600`, `${c}.500`)(props) }
  }
}

function variantLink(props: Dict) {
  const { colorScheme: c } = props
  return {
    color: mode(`${c}.600`, `${c}.500`)(props),
    _hover: {
      color: mode(`${c}.700`, `${c}.200`)(props),
      textDecoration: 'none'
    },
    _active: {
      color: mode(`${c}.700`, `${c}.200`)(props)
    },
    _focus: {
      boxShadow: 0
    }
  }
}

function variantWhite() {
  return {
    color: 'white',
    bg: 'transparent',
    border: '2px solid white',
    textAlign: 'center',
    _hover: {
      bg: 'white',
      color: 'black',
      _disabled: {
        bg: 'white',
        color: 'black'
      }
    },
    _disabled: {
      bg: 'white',
      color: 'black'
    }
  }
}

const variants = {
  ghost: variantGhost,
  outline: variantOutline,
  solid: variantSolid,
  link: variantLink,
  monochrome: variantWhite
}

const sizes = {
  md: {
    fontSize: 'md',
    h: '60px',
    minW: 10,
    py: '20px',
    px: '30px',
    lineHeight: 'none'
  },
  sm: {
    fontSize: 'md',
    h: '48px',
    minW: 10,
    py: '12px',
    px: '30px',
    lineHeight: 'none'
  }
}

const defaultProps = {
  variant: 'solid',
  size: 'md'
}

export default {
  baseStyle,
  variants,
  sizes,
  defaultProps
}
