import { Auth0ContextInterface, User } from "@auth0/auth0-react";
import { useRouter } from "next/router";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { getPathFromUrl, UtagViewData } from "./helpers";

// This component loads a the Tealium utag.js (3rd party tracking script) into the DOM
// https://docs.tealium.com/platforms/javascript/install/#universal-tag-utag-js
// The component needs access to DOM methods in order to work correctly, it must be either dynamically imported with `ssr: false`
// or mounted within a parent component that will ensure that the window has laoded before mounting its children.
// https://nextjs.org/docs/advanced-features/dynamic-import
//
// 'utagLinkClick' event listener:
// The component uses the next/script component to load the Universal Utag script first, if the script is already present then the component will return
// null so that no accidental duplicate Utag data scripts are loaded into the DOM.
// The component also instantiates an event listener that listens for the `utagLinkClick` event, if it detects such an event it uses
// helper methods to format the data from the event and then dispatch the data via a Utag method attached to the DOM (see above).
//
// Route change event listener:
// On route change, the component will call the `window.utag.view` method (used for tracking page changes, helper methods are used to
// create an object of page relevant data to be passed as an argument to the `window.utag.view` method.
// https://docs.tealium.com/platforms/javascript/single-page-applications/#track-views

interface TealiumContext {
  setViewData: (data: UtagViewData) => void;
  setIsPageReady: (isReady: boolean) => void;
}

const TealiumContext = createContext({
  setViewData: (_data: UtagViewData): void => {
    throw new Error();
  },
  setIsPageReady: (_isReady: boolean): void => {
    throw new Error();
  },
});

type UseTealium = () => TealiumContext;

const useTealium: UseTealium = () => useContext<TealiumContext>(TealiumContext);

const TealiumProvider = ({
  children,
  auth0,
}: {
  children: ReactNode;
  auth0?: Auth0ContextInterface<User>;
}) => {
  // A useRef is used here instead of useState as the state is not updated in
  // eventHandler functions such as `handleRouteChange`
  const viewDataRef = useRef<UtagViewData>(null);
  const isPageReadyRef = useRef<boolean>(false);
  const [previousUrlPath, setPreviousUrlPath] = useState<string>("");
  const [triggerUtagView, setTriggerUtagView] = useState<boolean>(false);
  const router = useRouter();
  const UTAG_LINK_CLICK_EVENT = "utagLinkClick";
  const UTAG_LOADED_EVENT = "utagLoaded";
  const ROUTE_CHANGE_EVENT = "routeChangeComplete";

  const setViewData = (data: UtagViewData) => {
    viewDataRef.current = data;
  };
  const setIsPageReady = (isPageReady: boolean) => {
    isPageReadyRef.current = isPageReady;
  };

  const handleUtagLinkClick = useCallback((event): void => {
    if (window?.utag) {
      const { detail = {} } = event;
      window.utag.link({ ...detail });
    }
  }, []);

  const setUtagView = useCallback(async () => {
    if (isPageReadyRef.current) {
      const data = { ...viewDataRef.current, auth0_id: auth0?.user?.sub };
      // We require putting this in a setTimeout to get around the document.title not updating in time
      // See https://github.com/vercel/next.js/issues/6025
      setTimeout(
        () => data && Object.keys(data).length > 0 && window?.utag?.view(data),
        10,
      );
      setIsPageReady(false);
    }
  }, [auth0?.user?.sub]);

  /**
   * On Page Load
   *
   * Listen for the utag to be loaded in the browser and then
   * fire off a view event
   */
  useEffect(() => {
    const utagEventListener = () => {
      setPreviousUrlPath(`/${router.locale}${getPathFromUrl(router.asPath)}`);
      setTriggerUtagView(true);
    };

    document.addEventListener(UTAG_LOADED_EVENT, utagEventListener);
    return () => {
      document.body.removeEventListener(UTAG_LOADED_EVENT, utagEventListener);
    };
  }, [setUtagView, router.asPath, router.locale]);

  /**
   *
   * Set utag.view on route change
   */
  useEffect(() => {
    // When the router changes we want to fire a utag.view event
    const handleRouteChange = (url: string) => {
      // We only want to trigger a utag.view if the path changes and not the
      // params
      const path = getPathFromUrl(url);
      if (previousUrlPath !== "" && previousUrlPath !== path) {
        setTriggerUtagView(true);
      }
      setPreviousUrlPath(path);
    };

    router.events.on(ROUTE_CHANGE_EVENT, handleRouteChange);
    return () => {
      router.events.off(ROUTE_CHANGE_EVENT, handleRouteChange);
    };
  }, [router.events, handleUtagLinkClick, setUtagView, previousUrlPath]);

  /**
   * utag.link track on utag link click event
   */
  useEffect(() => {
    if (typeof window !== "undefined") {
      document.addEventListener(UTAG_LINK_CLICK_EVENT, handleUtagLinkClick);
    }

    return () => {
      document.removeEventListener(UTAG_LINK_CLICK_EVENT, handleUtagLinkClick);
    };
  }, [handleUtagLinkClick]);

  // Wait for Auth0 to finish loading to know if we have user present or not
  useEffect(() => {
    if (triggerUtagView && !auth0?.isLoading) {
      setUtagView();
      setTriggerUtagView(false);
    }
  }, [triggerUtagView, setTriggerUtagView, auth0?.isLoading, setUtagView]);

  return (
    <TealiumContext.Provider value={{ setViewData, setIsPageReady }}>
      {children}
    </TealiumContext.Provider>
  );
};

export { useTealium };
export default TealiumProvider;
