import {
  Box,
  Center,
  Divider,
  GridItem,
  HStack,
  Link,
  LinkProps,
  SimpleGrid,
  Text
} from '@chakra-ui/react'
import { PopularSearchType } from 'swnz/src/constants'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'

interface PopularSearches {
  items: PopularSearchType[]
  popularSearchTitle?: string
}

const StyleLink = ({ ...props }: LinkProps) => (
  <Link
    fontFamily='enz500'
    fontSize='14px'
    textDecoration='none'
    lineHeight='20px'
    {...props}
  />
)

export default function PopularSearches({
  items,
  popularSearchTitle
}: PopularSearches) {
  return (
    <>
      {/* desktop CTAs */}
      <HStack spacing={{ lg: 6 }}>
        <Center height='140px' display={{ base: 'none', lg: 'unset' }}>
          <Divider
            orientation='vertical'
            color='deepblue.200'
            opacity={1}
            m='0'
          />
        </Center>
        <Box>
          <Text fontFamily='enz700' mb={{ base: 2, lg: 4 }} fontSize='20px'>
            {popularSearchTitle}
          </Text>
          {/* desktop list */}
          <SimpleGrid
            columns={2}
            columnGap={4}
            rowGap='10px'
            display={{ base: 'none', lg: 'grid' }}
          >
            {items.map((item: any, key: number) => (
              <GridItem key={key} lineHeight='20px'>
                <StyleLink
                  href={item.value}
                  onClick={() => {
                    createUtagLinkClickEvent({
                      linkLabel: item.label,
                      linkCategory: TEALIUM_LINK_CATEGORY.POPULAR_SEARCH
                    })
                  }}
                >
                  {item.label}
                </StyleLink>
              </GridItem>
            ))}
          </SimpleGrid>
        </Box>
      </HStack>
      {/* mobile horizontal scroll buttons */}
      <Box
        overflow='auto'
        whiteSpace='nowrap'
        mx={-6}
        px={6}
        pb={2}
        display={{ lg: 'none' }}
      >
        <HStack spacing={4}>
          {items.map((item: any, key: number) => (
            <StyleLink key={key} href={item.value}>
              {item.label}
            </StyleLink>
          ))}
        </HStack>
      </Box>
    </>
  )
}
