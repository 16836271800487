const sortAlphabetically = (a: { label: string }, b: { label: string }) => {
  // Use toUpperCase() to ignore character casing
  const labelA = a.label.toUpperCase()
  const labelB = b.label.toUpperCase()

  if (labelA > labelB) return 1
  if (labelA < labelB) return -1

  return 0
}

export { sortAlphabetically }
