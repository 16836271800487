import {
  StaticComponent_Swnz_ComponentStaticFragment,
  StaticComponent_Swnz_ComponentStaticFragmentDoc
} from 'content-service'
import { LeadCaptureForm as NaumaiLeadCaptureForm } from 'naumai/src/components/forms'
import { ReactElement } from 'react'
import { LeadCaptureForm as SwnzLeadCaptureForm } from 'swnz/src/components/forms'
import useFragment from 'utils/src/hooks/useFragment'
import { FormElementWrapper, FORM_CONSTANTS } from '../../forms'
import { Connector } from '../types'

type StaticComponentConnectProps = Connector

interface StaticComponentMap {
  [displayType: string]: ReactElement | null
}

enum StaticComponentType {
  JoinMailingList = 'join-mailing-list',
  FormLeadCapture = 'form-lead-capture'
}

const staticComponentMap: StaticComponentMap = Object.freeze({
  [StaticComponentType.JoinMailingList]: (
    <FormElementWrapper
      successMessage={FORM_CONSTANTS.LEAD_CAPTURE.SUCCESS_MESSAGE}
      errorMessage={FORM_CONSTANTS.LEAD_CAPTURE.ERROR_MESSAGE}
      heading={{
        initial: '',
        onSuccess: 'Stay in touch'
      }}
      bodyText={{
        initial: '',
        onSuccess:
          'Learn more and stay up to date with the latest information on studying with New Zealand.'
      }}
    >
      {({ handleFormSuccess, handleFormError }) => (
        <SwnzLeadCaptureForm
          tealium={{
            source: 'Join Our Mailing List',
            formType: 'general_lead',
            formName: 'component'
          }}
          onSuccessCallback={handleFormSuccess}
          onErrorCallback={handleFormError}
        />
      )}
    </FormElementWrapper>
  ),
  [StaticComponentType.FormLeadCapture]: (
    <FormElementWrapper
      successMessage={FORM_CONSTANTS.LEAD_CAPTURE.SUCCESS_MESSAGE}
      errorMessage={FORM_CONSTANTS.LEAD_CAPTURE.ERROR_MESSAGE}
      heading={{
        initial: 'Ready to join NauMai NZ?',
        onSuccess: 'Stay in touch'
      }}
      bodyText={{
        initial: 'To get started, tell us a bit about yourself',
        onSuccess:
          'Learn more and stay up to date with the latest information on studying with New Zealand.'
      }}
      gridHeaderStyles={{
        colSpan: { base: 4, md: 8, lg: 8 },
        colStart: { base: 1, lg: 3 }
      }}
      gridItemStyles={{
        colSpan: { base: 4, md: 6 }
      }}
    >
      {({ handleFormSuccess, handleFormError }) => (
        <NaumaiLeadCaptureForm
          tealium={{
            source: 'NauMai Join Form',
            formType: 'lead',
            formName: 'naumai_join_form'
          }}
          onSuccessCallback={handleFormSuccess}
          onErrorCallback={handleFormError}
        />
      )}
    </FormElementWrapper>
  )
})

const StaticComponentConnect = ({
  id,
  typename
}: StaticComponentConnectProps) => {
  const data = useFragment<StaticComponent_Swnz_ComponentStaticFragment>({
    id,
    typename,
    fragment: StaticComponent_Swnz_ComponentStaticFragmentDoc,
    fragmentName: 'staticComponent_SWNZ_ComponentStatic'
  })
  // If no data is returned, return null
  if (!data?.codeId) {
    return null
  }

  // If codeId is not join-mailing-list or form-lead-capture return null
  return staticComponentMap[data.codeId] ?? null
}

export default StaticComponentConnect
