import { Box, Flex, ModalBody, Spinner } from '@chakra-ui/react'
import { SavedPrompt, SavedPromptMobile } from 'icons'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { CtaButton, Form, FORM_CONSTANTS, H3, LargeText, Modal, removeLoginDataCookie } from 'ui'
import { LoginState, SignUpType } from '../../components/SignUpModal'
import { COOKIE, ROUTES } from '../../constants'
import useGetStudyOptions from '../../hooks/useGetStudyOptions'

export enum SavedModalStatusType {
  Success = 'success',
  Error = 'error',
  Default = '',
}
interface SuccessModalProps {
  isOpen: boolean
  onClose: () => void
  itemType: string
  status: SavedModalStatusType
}

const SavedSuccessModal = ({
  isOpen,
  onClose,
  loginState,
  itemType,
  status
}: SuccessModalProps & LoginState ) => {
  const handleGetStudyOptions = useGetStudyOptions()
  const [currentSignUpState, setCurrentSignUpState] = useState(loginState)
  const loginDataCookie = Cookies.get(COOKIE.SET_LOGIN_DATA)
  const router = useRouter()
  const cookieData = loginDataCookie && JSON.parse(loginDataCookie)

  const handleModalClose = async (route?: string) => {
    await handleGetStudyOptions()
    removeLoginDataCookie()
    onClose()
    setCurrentSignUpState({ openModal: false })

    if (route) {
      router.push(route)
    }
  }

  useEffect(() => {
    const handleModalData = () => {
      if (cookieData) {
        setCurrentSignUpState({ openModal: true, ...cookieData })
      }
    }
    router.isReady && loginState && handleModalData()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState, router])

  return (
    <>
      {(currentSignUpState?.loginType === SignUpType.Save || isOpen) && (
        <Modal
          onClose={handleModalClose}
          isOpen={currentSignUpState?.openModal ? currentSignUpState?.openModal : isOpen}
        >
          <Modal.Overlay />
          <Modal.FullWidthContent>
            <Modal.CloseButton
              right={{ base: 0, md: 7 }}
              top={{ base: 0, md: 8 }}
            />
            <ModalBody
              flexGrow={{ base: 0, md: 1 }}
              paddingX={{ base: 5, lg: 7 }}
              paddingTop={{ base: 7, lg: 7 }}
              paddingBottom={8}
            >
              {status === '' && (
                <Flex justifyContent="center" mt={2}>
                  <Spinner size={'lg'} />
                </Flex>
              )}
              {status === SavedModalStatusType.Success && (
                <>
                  <H3>{FORM_CONSTANTS.SAVED.heading}</H3>
                  <LargeText>{FORM_CONSTANTS.SAVED.bodyText}</LargeText>
                  <Box bg="teal.100">
                    <Flex w="full" justify={{ base: 'center', md: 'end' }} pt={4}>
                      <Flex
                        display={{ base: 'flex', md: 'none' }}
                        justifyContent="center"
                        alignItems="center"
                        boxSize="100%"
                        overflow="hidden"
                      >
                        <SavedPromptMobile />
                      </Flex>
                      <Box display={{ base: 'none', md: 'block' }} boxSize="100%" overflow="hidden">
                        <SavedPrompt />
                      </Box>
                    </Flex>
                  </Box>
                  <Flex mt={7} flexDir={{ base: 'column', md: 'row' }}>
                    <CtaButton
                      mr={{ base: 0, md: 2 }}
                      mb={{ base: 3, md: 0 }}
                      onClick={() => handleModalClose()}
                    >
                      {FORM_CONSTANTS.SAVED.continueButton} {itemType}
                    </CtaButton>
                    <CtaButton variant="outline" onClick={() => handleModalClose(ROUTES.DASHBOARD)}>
                      {FORM_CONSTANTS.SAVED.dashboardButton}
                    </CtaButton>
                  </Flex>
                </>
              )}
              {status === SavedModalStatusType.Error && (
                <Box pt={3}>
                  <Form.ErrorContent />
                  <Flex mt={10} flexDir={{ base: 'column', md: 'row' }}>
                    <CtaButton
                      mr={{ base: 0, md: 2 }}
                      mb={{ base: 3, md: 0 }}
                      onClick={() => handleModalClose()}
                      variant="solid"
                      bg="deepblue.500"
                      borderColor="deepblue.500"
                    >
                      {FORM_CONSTANTS.ENQUIRY.SUCCESS_MESSAGE.closeButtonLabel}
                    </CtaButton>
                  </Flex>
                </Box>
              )}
            </ModalBody>
          </Modal.FullWidthContent>
        </Modal>
      )}
    </>
  )
}

export default SavedSuccessModal
