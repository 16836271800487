/**
 * ColourMap
 *
 * Handles mapping colour values as set in Contentful
 * and turning these in to colour values in our theme
 *
 * @param color string
 * @returns string
 */
const colourMap = (color: string) => {
  switch (color) {
    case 'white':
      return 'white'
    case 'surface-blue-5':
      return 'teal.100'
    case 'surface-blue':
      return 'teal.500'
    case 'manapou-orange-10':
      return 'orange.10'
    case 'manapou-orange':
      return 'orange.500'
    case 'deep-blue-5':
      return 'deepblue.5'
    case 'deep-water-blue':
      return 'deepblue.500'
    case 'harakeke-green':
      return 'green.300'
    case 'west-coast-sand-10':
      return 'grey.10'
    case 'west-coast-sand':
      return 'grey.600'
    default:
      return color
  }
}

/**
 * ColourMap
 *
 * Handles mapping colour values as set in Contentful
 * and turning these in to colour values in our theme
 *
 * @param color string
 * @returns string
 */
const colourBgMap = (color: string) => {
  switch (color) {
    case 'white':
      return 'white'
    case 'deep-blue':
      return 'deepblue.500'
    case 'deep-water-blue':
      return 'deepblue.5'
    case 'surface-blue':
      return 'teal.100'
    case 'manapou-orange':
      return 'orange.10'
    case 'west-coast-sand':
      return 'grey.10'
    default:
      return color
  }
}

export { colourMap, colourBgMap }
