import { Box, Container } from '@chakra-ui/react'
import React from 'react'
import CardAccordionLayout from './CardsPanel/CardAccordionLayout'
import CardGridLayout from './CardsPanel/CardGridLayout'
import { CardItemProps } from './CardsPanel/CardItem'

export interface HomepagePanelCardsProps {
  items?: CardItemProps[]
}

const HomepagePanelCards = ({ items }: HomepagePanelCardsProps) => {
  return (
    <Box>
      {items !== undefined && items?.length > 1 && (
        <Container>
          <Box display={{ base: 'none', lg: 'block' }}>
            <CardGridLayout items={items} />
          </Box>
          <Box display={{ base: 'block', lg: 'none' }}>
            <CardAccordionLayout items={items} />
          </Box>
        </Container>
      )}
    </Box>
  )
}

export default HomepagePanelCards
