import { Box } from '@chakra-ui/react'
import { ChevronIcon } from 'icons'
import { ReactElement, RefObject, useEffect } from 'react'
import usePagination, { DOTS, range } from 'utils/src/hooks/usePagination'
import { Button } from '../../buttons'
import { LI, OL } from '../../lists'

interface Props {
  numberOfPages: number
  setCurrentPage: (page: number) => void
  currentPage: number
  darkTheme?: boolean
  scrollRef?: RefObject<any>
}

type CreateArrowNavigation = {
  pages: number[]
  type: string
  icon: ReactElement
  currentPage: number
  setCurrentPage: (page: number) => void
  scrollRef?: RefObject<any>
}

type RenderLinkProps = {
  testIdLabel: string
  pageNumber: number
  content: number | ReactElement
  setCurrentPage: (page: number) => void
  currentPage: number
  darkTheme?: boolean
  scrollRef?: RefObject<any>
}

const handleClick = (ref: RefObject<any> | undefined) => {
  if (typeof window !== undefined) {
    ref
      ? ref.current.scrollIntoView({ behavior: 'smooth' })
      : window.scrollTo({ top: 0 })
  }
}

const RenderLink = ({
  testIdLabel,
  pageNumber,
  content,
  setCurrentPage,
  currentPage,
  darkTheme,
  scrollRef
}: RenderLinkProps) => {
  const selected = currentPage === pageNumber

  return (
    <LI
      key={`pagination-${testIdLabel}`}
      my={0}
      mx={1}
      listStyleType='none'
      _notFirst={{ my: 0 }}
      data-testid={`pagination-${testIdLabel}`}
    >
      <Button
        p={0}
        rounded={'full'}
        height={30}
        width={30}
        minW='inherit'
        display='flex'
        alignItems='center'
        justifyContent='center'
        variant={selected ? undefined : 'unstyled'}
        color={selected ? 'white' : darkTheme ? 'white' : 'deepblue.300'}
        borderColor={
          selected ? (darkTheme ? 'deepWaterBlue' : 'surfaceBlue') : 'none'
        }
        background={
          selected ? (darkTheme ? 'deepWaterBlue' : 'surfaceBlue') : 'none'
        }
        fontFamily={selected ? 'enz700' : 'enz400'}
        fontSize={'14px'}
        lineHeight={'20px'}
        onClick={() => {
          setCurrentPage(pageNumber)
          handleClick(scrollRef)
        }}
      >
        {content}
      </Button>
    </LI>
  )
}

const ArrowNavigation = ({
  pages,
  type,
  icon,
  currentPage,
  setCurrentPage,
  scrollRef
}: CreateArrowNavigation) => {
  let pageLink: number = currentPage
  const isFirst = currentPage === pages[0]
  const isLast = currentPage === pages[pages.length - 1]

  // Hide our Arrow nav if we are at the first or last page
  if ((isFirst && type === 'previous') || (isLast && type === 'next'))
    return null

  if (type === 'next' && !isLast) {
    pageLink++
  }

  if (type === 'previous' && !isFirst) {
    pageLink--
  }

  return (
    <RenderLink
      key={type}
      testIdLabel={type}
      pageNumber={pageLink}
      content={icon}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      scrollRef={scrollRef}
    />
  )
}

const Component = ({
  currentPage,
  setCurrentPage,
  numberOfPages,
  darkTheme,
  scrollRef
}: Props) => {
  const numberOfPagesAsArray = range(1, numberOfPages)
  const paginationRange = usePagination({
    currentPage,
    totalPageCount: numberOfPages,
    siblingCount: 2
  })

  useEffect(() => {
    if (numberOfPages !== 0 && numberOfPages < currentPage) {
      setCurrentPage(numberOfPages)
      handleClick(scrollRef)
    }
  }, [setCurrentPage, scrollRef, numberOfPages, currentPage])

  return (
    <OL
      m={0}
      styleType='none'
      display='flex'
      flexDir='row'
      alignItems='center'
      flexWrap='wrap'
    >
      <ArrowNavigation
        pages={numberOfPagesAsArray}
        type={'previous'}
        icon={
          <Box color='teal.500' transform='rotate(180deg)' boxSize='20px'>
            <ChevronIcon />
          </Box>
        }
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        scrollRef={scrollRef}
      />
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS.DOTS) {
          return (
            <LI
              key={`pagination-${index}`}
              p='1em'
              data-testid={`pagination-${index}`}
            >
              <span>...</span>
            </LI>
          )
        }

        // Render our Page Pills
        return (
          <RenderLink
            key={index}
            testIdLabel={index.toString()}
            pageNumber={pageNumber}
            content={pageNumber}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            darkTheme={darkTheme}
            scrollRef={scrollRef}
          />
        )
      })}

      <ArrowNavigation
        pages={numberOfPagesAsArray}
        type={'next'}
        icon={
          <Box color='teal.500' width='20px'>
            <ChevronIcon />
          </Box>
        }
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        scrollRef={scrollRef}
      />
    </OL>
  )
}

export { Component }
export default Component
