import { HttpLink } from '@apollo/client'
import customFetch from './fetch'

const clientHttpLink = new HttpLink({
  uri: `${process.env.NEXT_PUBLIC_URL}${process.env.NEXT_PUBLIC_CLIENT_API}`,
  credentials: 'same-origin',
  fetch: customFetch as typeof fetch
})

export default clientHttpLink
