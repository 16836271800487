import {
  Box,
  Center,
  Container,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  GridItem,
  HStack,
  Stack,
  Text,
  useDisclosure,
  useTheme,
  useToken
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { ContainerWrapper, GridLayout, H2, H3, RichTextRenderer } from 'ui'
import { useMediaQuery } from 'utils/src/helpers'
import { CtaButton } from '../../buttons'
import EmotionCard, {
  EmotionCardProps,
  EmotionCardResponse
} from './EmotionCard'
import EmotionCardCarouselNavigationButtons from './EmotionCardsCarouselButtons'

enum NavigationType {
  Next = 'next',
  Previous = 'previous'
}

interface EmotionCardsCarouselProps {
  heading?: string
  introText?: string
  cards: EmotionCardProps[]
}

export interface CarouselState {
  currentSlide: number
}

export const CAROUSEL_INDEX_CONSTANTS = Object.freeze({
  START_SLIDE: 0,
  DESKTOP: {
    SLIDES_PER_CONTAINER: 6
  },
  MOBILE: {
    SLIDES_PER_CONTAINER: 1
  }
})

const EmotionCardsCarousel = ({
  heading,
  cards,
  introText
}: EmotionCardsCarouselProps) => {
  const theme = useTheme()
  const isLarge = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`)
  const [carouselCardGap] = useToken('space', ['5'])

  const [carouselState, setCarouselState] = useState<CarouselState>({
    currentSlide: CAROUSEL_INDEX_CONSTANTS.START_SLIDE
  })

  const numberOfSlides = cards.length

  useEffect(() => {
    if (isLarge)
      setCarouselState({
        ...carouselState,
        currentSlide: CAROUSEL_INDEX_CONSTANTS.START_SLIDE
      })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLarge])

  const slidesInView: number = isLarge
    ? CAROUSEL_INDEX_CONSTANTS.DESKTOP.SLIDES_PER_CONTAINER
    : CAROUSEL_INDEX_CONSTANTS.MOBILE.SLIDES_PER_CONTAINER

  const updateCurrentSlide = (index: number): void => {
    if (carouselState.currentSlide !== index) {
      setCarouselState({
        ...carouselState,
        currentSlide: index
      })
    }
  }

  const carouselOverrideCss = {
    'html[lang=ar] &': {
      direction: 'rtl'
    },
    '.carousel-root, .carousel-slider, .slider-wrapper': {
      overflow: 'visible'
    }
  }

  const showCarousel = !isLarge

  const { isOpen, onOpen, onClose } = useDisclosure()
  const [drawerCard, setDrawerCard] = useState<EmotionCardResponse | null>(null)

  return (
    <ContainerWrapper
      position='relative'
      overflow='hidden'
      paddingBottom={10}
      bg='#E9F6F6'
      py='100px'
      px={0}
    >
      <Container>
        {heading && (
          <Center>
            <H2
              textAlign='center'
              maxW='container.sm'
              fontSize={{ base: '36px', lg: '48px' }}
              lineHeight={{ base: '44px', lg: '56px' }}
              fontWeight='700'
              letterSpacing={'-1px'}
            >
              {heading}
            </H2>
          </Center>
        )}
        <Flex
          flexDirection={isLarge ? 'row' : 'column'}
          justifyContent='space-between'
          wrap='wrap'
          marginBottom={{
            base: 3,
            lg: 10
          }}
        >
          {introText && (
            <Text
              textAlign='center'
              fontSize={{ base: '20px', lg: '22px' }}
              lineHeight={{ base: '30px', lg: '36px' }}
              px={{ base: 0, lg: 220 }}
            >
              {introText}
            </Text>
          )}
          {showCarousel && (
            <HStack alignSelf='flex-end' dir='ltr'>
              <EmotionCardCarouselNavigationButtons
                slidesInView={slidesInView}
                numberOfSlides={numberOfSlides}
                currentSlide={carouselState.currentSlide}
                updateCurrentSlide={updateCurrentSlide}
              />
            </HStack>
          )}
        </Flex>
      </Container>
      {/* Make sure drawerInfo is loaded first */}
      {drawerCard && (
        <Drawer onClose={onClose} isOpen={isOpen} size='md'>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton
              size={'xl'}
              top={{ base: 4, lg: 6 }}
              right={{ base: 4, lg: 6 }}
              p={4}
              bgColor='deepblue.10'
              borderRadius='full'
            />
            <DrawerBody pt={20}>
              <Stack spacing={8}>
                <H3
                  mb={0}
                  fontWeight={'700px'}
                  fontSize={'32px'}
                  lineHeight={'44px'}
                  letterSpacing={'-1'}
                >
                  {drawerCard?.heading}
                </H3>
                {drawerCard?.bodyText && (
                  <RichTextRenderer richText={drawerCard?.bodyText} />
                )}
                <Box>
                  {drawerCard?.callToAction?.label && (
                    <>
                      <CtaButton
                        variant='solid'
                        aria-label={drawerCard?.callToAction?.label}
                        openInNewWindow={
                          drawerCard?.callToAction?.openInNewWindow
                        }
                        isExternalLink={
                          drawerCard?.callToAction?.isExternalLink
                        }
                        href={drawerCard?.callToAction?.href}
                        modalId={drawerCard?.callToAction?.modalId}
                      >
                        {drawerCard?.callToAction?.label}
                      </CtaButton>
                    </>
                  )}
                </Box>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
      {showCarousel ? (
        <Container maxWidth='container.xl' sx={carouselOverrideCss}>
          <Carousel
            centerMode={true}
            autoPlay={false}
            infiniteLoop={false}
            emulateTouch={true}
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            selectedItem={carouselState.currentSlide}
            centerSlidePercentage={100}
            width={
              isLarge ? `calc(33.33% + 6px)` : `calc(43% + ${carouselCardGap})`
            }
            onChange={(index) => {
              updateCurrentSlide(index)
            }}
          >
            {cards.map((card, index) => {
              if (!card) {
                return ''
              }

              return (
                <EmotionCard
                  onOpen={onOpen}
                  paddingRight={carouselCardGap}
                  setDrawerCard={setDrawerCard}
                  {...card}
                  key={index}
                />
              )
            })}
          </Carousel>
        </Container>
      ) : (
        <Container>
          <GridLayout
            gap={5}
            // Get the number of slides and create a collumn for each slide for desktop.
            templateColumns={{
              lg: `repeat(${numberOfSlides}, 1fr)`
            }}
          >
            {cards.map((card, index) => {
              if (!card) {
                return ''
              }
              return (
                <GridItem key={index}>
                  <EmotionCard
                    {...card}
                    onOpen={onOpen}
                    setDrawerCard={setDrawerCard}
                  />
                </GridItem>
              )
            })}
          </GridLayout>
        </Container>
      )}
    </ContainerWrapper>
  )
}

export default EmotionCardsCarousel
export type { EmotionCard }
export { NavigationType }
