import { DashboardSearchHit } from 'swnz/src/components/Search'
import { ALGOLIA_INDICES } from 'swnz/src/constants'
import { StudyOptionsAPILearnerInventoryItemModel } from 'ui'
import useGetAlgoliaHits from './useGetAlgoliaHits'

type DashboardStudyOptions = {
  recommended: {
    providers: StudyOptionsAPILearnerInventoryItemModel[]
    courses: StudyOptionsAPILearnerInventoryItemModel[]
  }
  saved: {
    providers: StudyOptionsAPILearnerInventoryItemModel[]
    courses: StudyOptionsAPILearnerInventoryItemModel[]
  }
  enquired: {
    providers: StudyOptionsAPILearnerInventoryItemModel[]
    courses: StudyOptionsAPILearnerInventoryItemModel[]
    scholarships: StudyOptionsAPILearnerInventoryItemModel[]
  }
}

const useGetDashboardHits = (dashboardStudyOptions: DashboardStudyOptions) => {
  /* Recommended */
  const { loading: recommendedCourseHitsLoading, hits: recommendedCourseHits } =
    useGetAlgoliaHits<DashboardSearchHit.CourseHit>({
      inventorySearchValues: dashboardStudyOptions.recommended.courses.map(
        (course) => course.itemId
      ),
      indexName: ALGOLIA_INDICES.COURSE_FINDER.MAIN
    })
  const {
    loading: recommendedProviderHitsLoading,
    hits: recommendedProviderHits
  } = useGetAlgoliaHits<DashboardSearchHit.ProviderHit>({
    inventorySearchValues: dashboardStudyOptions.recommended.providers.map(
      (provider) => provider.itemId
    ),
    indexName: ALGOLIA_INDICES.PROVIDER_FINDER.MAIN
  })

  /* Saved */
  const { loading: savedCourseHitsLoading, hits: savedCourseHits } =
    useGetAlgoliaHits<DashboardSearchHit.CourseHit>({
      inventorySearchValues: dashboardStudyOptions.saved.courses.map(
        (course) => course.itemId
      ),
      indexName: ALGOLIA_INDICES.COURSE_FINDER.MAIN
    })
  const { loading: savedProviderHitsLoading, hits: savedProviderHits } =
    useGetAlgoliaHits<DashboardSearchHit.ProviderHit>({
      inventorySearchValues: dashboardStudyOptions.saved.providers.map(
        (provider) => provider.itemId
      ),
      indexName: ALGOLIA_INDICES.PROVIDER_FINDER.MAIN
    })

  /* Enquired */
  const { loading: enquiredCourseHitsLoading, hits: enquiredCourseHits } =
    useGetAlgoliaHits<DashboardSearchHit.CourseHit>({
      inventorySearchValues: dashboardStudyOptions.enquired.courses.map(
        (course) => course.itemId
      ),
      indexName: ALGOLIA_INDICES.COURSE_FINDER.MAIN
    })
  const { loading: enquiredProviderHitsLoading, hits: enquiredProviderHits } =
    useGetAlgoliaHits<DashboardSearchHit.ProviderHit>({
      inventorySearchValues: dashboardStudyOptions.enquired.providers.map(
        (provider) => provider.itemId
      ),
      indexName: ALGOLIA_INDICES.PROVIDER_FINDER.MAIN
    })
  const {
    loading: enquiredScholarshipHitsLoading,
    hits: enquiredScholarshipHits
  } = useGetAlgoliaHits<DashboardSearchHit.ScholarshipHit>({
    inventorySearchValues: dashboardStudyOptions.enquired.scholarships.map(
      (scholarship) => scholarship.itemId
    ),
    indexName: ALGOLIA_INDICES.SCHOLARSHIP_FINDER.MAIN
  })

  return {
    loading:
      recommendedCourseHitsLoading ||
      recommendedProviderHitsLoading ||
      savedCourseHitsLoading ||
      savedProviderHitsLoading ||
      enquiredCourseHitsLoading ||
      enquiredProviderHitsLoading ||
      enquiredScholarshipHitsLoading,
    recommended: {
      itemCount: recommendedCourseHits.length + recommendedProviderHits.length,
      courses: recommendedCourseHits,
      providers: recommendedProviderHits
    },
    saved: {
      itemCount: savedCourseHits.length + savedProviderHits.length,
      courses: savedCourseHits,
      providers: savedProviderHits
    },
    enquired: {
      itemCount:
        enquiredCourseHits.length +
        enquiredProviderHits.length +
        enquiredScholarshipHits.length,
      courses: enquiredCourseHits,
      providers: enquiredProviderHits,
      scholarships: enquiredScholarshipHits
    }
  }
}

export default useGetDashboardHits
