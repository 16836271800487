import { Text, TextProps } from '@chakra-ui/react'

const FooterSectionTitle = (props: TextProps) => {
  return (
    <Text
      as='h6'
      fontSize='sm'
      fontFamily='enz500'
      fontWeight={'500'}
      color='white'
      mb={4}
      {...props}
    />
  )
}

export default FooterSectionTitle
