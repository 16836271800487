import {
  AspectRatio,
  Box,
  Button,
  Container,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Grid,
  GridItem,
  Image,
  Stack,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { CallToAction, CtaButton } from '../../buttons'
import { RichTextRenderer } from '../../RichTextRenderer'
import { BoldText, H3, Headline, LargeText } from '../../typography'

interface ValueImageProps {
  title?: string | undefined
  url: string
}

interface ValueBannerProps {
  title?: string | null
  image?: ValueImageProps
  introduction?: string | null
  ctaText?: string
  drawerDescription?: any | null
  drawerPrimaryCta?: CallToAction
  drawerPronunciation?: string
  leadCaptureFormId?: string
}

const ValueBanner = ({
  title,
  image,
  introduction,
  ctaText,
  drawerDescription,
  drawerPrimaryCta,
  drawerPronunciation,
  leadCaptureFormId,
  ...props
}: ValueBannerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Box
        data-testid='value-banner'
        bgColor='westCoastSand'
        pt={{ base: '60px' }}
        py={{ lg: '120px' }}
        {...props}
      >
        <Container py={0}>
          <Grid
            templateColumns={{
              base: 'repeat(4, 1fr)',
              md: 'repeat(8, 1fr)',
              lg: 'repeat(12, 1fr)'
            }}
            pos='relative'
          >
            <GridItem
              colSpan={{ base: 12, lg: 7 }}
              colStart={1}
              colEnd={{ lg: 7 }}
              gridRow={1}
              alignSelf='center'
              zIndex='1'
            >
              <Stack
                spacing={6}
                py={{ base: '60px', lg: 'unset' }}
                color='white'
              >
                <LargeText maxW={{ lg: '60%' }} mb='0' fontFamily='enz700'>
                  {introduction}
                </LargeText>
                <Headline
                  fontSize={{ base: '46px', lg: '63px', xl: '88px' }}
                  letterSpacing='normal'
                >
                  {title}
                </Headline>
                {ctaText && (
                  <Button
                    variant='link'
                    justifyContent='start'
                    onClick={onOpen}
                    color='white'
                    textDecoration='underline'
                    fontFamily='enz400'
                    _hover={{
                      color: 'rgba(255,255,255,0.7)'
                    }}
                  >
                    {ctaText}
                  </Button>
                )}
              </Stack>
            </GridItem>

            <GridItem
              colSpan={{ base: 12, lg: 8 }}
              colStart={{ base: 1, lg: 5 }}
              colEnd={{ lg: 13 }}
              gridRow={{ lg: 1 }}
              mx={{ base: -6, lg: 'unset' }}
              mr={{ xl: -16 }}
              pl={{ lg: 10 }}
            >
              <AspectRatio ratio={{ base: 1, md: 6 / 4 }}>
                <Image w='full' src={image?.url} alt={image?.title} />
              </AspectRatio>
            </GridItem>
          </Grid>
        </Container>
      </Box>
      {/* value drawer */}
      {ctaText && (
        <Drawer onClose={onClose} isOpen={isOpen} size='md'>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton
              size={'xl'}
              top={{ base: 4, lg: 6 }}
              right={{ base: 4, lg: 6 }}
              p={4}
              bgColor='deepblue.10'
              borderRadius='full'
            />
            <DrawerBody pt={20}>
              <Stack spacing={8}>
                <H3 mb={0}>{title}</H3>
                {drawerDescription && (
                  <RichTextRenderer richText={drawerDescription} />
                )}
                {drawerPronunciation && (
                  <Stack>
                    <BoldText mb={0}>Pronunciation:</BoldText>
                    <Text mb={0}>{drawerPronunciation}</Text>
                  </Stack>
                )}

                <Box>
                  {drawerPrimaryCta?.label && (
                    <>
                      <Divider />
                      <CtaButton
                        variant='outline'
                        aria-label={drawerPrimaryCta.label}
                        openInNewWindow={drawerPrimaryCta.openInNewWindow}
                        isExternalLink={drawerPrimaryCta.isExternalLink}
                        href={drawerPrimaryCta.href}
                        modalId={drawerPrimaryCta.modalId}
                        leadCaptureFormId={leadCaptureFormId}
                      >
                        {drawerPrimaryCta.label}
                      </CtaButton>
                    </>
                  )}
                </Box>
              </Stack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  )
}

export default ValueBanner
export type { ValueBannerProps }
