import { Tile_Swnz_TileFragment } from 'content-service'
import { TileItem } from 'ui'
import { IMAGE_SIZE_CONFIG } from 'utils'
import { createCtaButton } from 'utils/src/helpers'
import { OptimisedImage, OptimisedImageProps } from '../../../components'
import { RichTextRenderer } from '../../../components/RichTextRenderer'

const createTileDataStructureObject = (
  tileItem: Tile_Swnz_TileFragment
): TileItem => {
  const {
    title = '',
    subtitle = '',
    bodyText,
    image,
    primaryCta,
    secondaryCta
  } = tileItem || {}

  const imageComponentProps: OptimisedImageProps = {
    src: image?.source?.url || '',
    alt: image?.source?.description || '',
    title: image?.source?.title || '',
    imageSizeConfig: IMAGE_SIZE_CONFIG.TILE
  }

  const primaryCtaProps = createCtaButton(primaryCta)
  const secondaryCtaProps = createCtaButton(secondaryCta)

  return {
    title: title,
    subtitle: subtitle,
    bodyText: bodyText ? <RichTextRenderer richText={bodyText} /> : null,
    image: OptimisedImage(imageComponentProps),
    primaryCta: primaryCtaProps,
    secondaryCta: secondaryCtaProps
  }
}

export const createTilesToRenderData = (tileItems: any): Array<TileItem> => {
  const tilesToRenderData: Array<TileItem> = []

  for (const tileItem of tileItems) {
    if (tileItem !== null) {
      tilesToRenderData.push(createTileDataStructureObject(tileItem))
    }
  }

  return tilesToRenderData
}
