import { captureException } from '@sentry/nextjs'
import AES from 'crypto-js/aes'
import UTF8 from 'crypto-js/enc-utf8'

const key = process.env.SD_API_CRYPTO_KEY ?? ''

export interface ProfileIds {
  authId: string | undefined
  profileId: string | undefined
}

function encryptProfileId({ authId, profileId }: ProfileIds) {
  return encryptJSON<ProfileIds>({ authId, profileId })
}

function decryptProfileId(data?: string): ProfileIds {
  if (data) {
    try {
      return decryptJSON<ProfileIds>(data)
    } catch (error) {
      captureException(error)
    }
  }

  return {
    authId: undefined,
    profileId: undefined,
  }
}

function encryptJSON<Data>(data: Data) {
  return encryptedData(JSON.stringify(data))
}

function decryptJSON<Data>(data: string): Data {
  return JSON.parse(decryptData(data))
}

function encryptedData(userId: string) {
  return AES.encrypt(userId, key).toString()
}

function decryptData(data: string) {
  return AES.decrypt(data, key).toString(UTF8)
}

export { encryptProfileId, decryptProfileId, encryptedData, decryptData, decryptJSON, encryptJSON }
