import CourseEnquiryForm, { CourseEnquiryFormProps } from './CourseEnquiryForm'
import EducationFairForm, { educationFairFormSchema } from './EducationFairForm'
import LeadCaptureForm, { LeadCaptureFormProps, leadCaptureFormSchema } from './LeadCaptureForm'
import LeadCaptureFormPopup from './LeadCaptureFormPopup'
import PathwayEnquiryForm, { pathwayEnquiryFormSchema } from './PathwayEnquiryForm'
import ProfileForm from './ProfileForm'
import ProviderEnquiryForm from './ProviderEnquiryForm'
import ScholarshipEnquiryForm from './ScholarshipEnquiryForm'
import SchoolEnquiryForm from './SchoolEnquiryForm'
import UserConsentForm from './UserConsentForm'

export {
  CourseEnquiryForm,
  ProviderEnquiryForm,
  SchoolEnquiryForm,
  ScholarshipEnquiryForm,
  PathwayEnquiryForm,
  UserConsentForm,
  ProfileForm,
  EducationFairForm,
  pathwayEnquiryFormSchema,
  educationFairFormSchema,
  LeadCaptureForm,
  leadCaptureFormSchema,
  LeadCaptureFormPopup,
}
export type { CourseEnquiryFormProps, LeadCaptureFormProps }
