import { Box, GridItem } from '@chakra-ui/react'
import { GridLayout } from 'ui'
import CardItem, { CardItemProps } from './CardItem'

export interface CardItemsProps {
  items?: CardItemProps[]
}

const CardGridLayout = ({ items }: CardItemsProps) => {
  return (
    <Box mt={12} mb="60px">
      <GridLayout
        templateColumns={{
          base: 'repeat(3, 1fr)',
        }}
        gridGap={12}
        display="grid"
      >
        {items?.map((item, index) => {
          return (
            <GridItem
              colSpan={{ base: 3, lg: 1 }}
              mt={12}
              key={index}
              data-testid={`carditem-${index}`}
            >
              <CardItem {...item} />
            </GridItem>
          )
        })}
      </GridLayout>
    </Box>
  )
}

export default CardGridLayout
