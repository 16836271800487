import { AspectRatio, Box, Container } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { colourMap } from 'utils/src/helpers'
import { Headline } from '../../typography'

interface FinderHeroProps {
  heading: string
  backgroundColour: string
  image: ReactElement | null
  finderWidget: ReactElement | null
}

const FinderHero = ({
  heading,
  backgroundColour = 'deep-water-blue',
  image,
  finderWidget
}: FinderHeroProps): ReactElement => {
  const sanitisedBackgroundColour = colourMap(backgroundColour)
  const layers = {
    zImage: 1,
    zColour: 2,
    zWidget: 4
  }
  return (
    <Box marginBottom={{ md: '66px', lg: '136px' }} position='relative'>
      <AspectRatio
        ratio={{ base: 4 / 6, lg: 9 / 6 }}
        maxH={{ base: '400px', lg: '900px' }}
        position='relative'
        zIndex={layers.zImage}
        overflow='hidden'
      >
        {image}
      </AspectRatio>
      <Container
        position='relative'
        mt={{ base: '-120px', lg: '-385px' }}
        paddingY={{ base: 6, lg: 16 }}
        pl={{ lg: 16 }}
        zIndex={{ base: layers.zColour }}
        _after={{
          content: "''",
          background: sanitisedBackgroundColour,
          position: 'absolute',
          boxSize: 'full',
          left: { base: -7, lg: '-50%' },
          top: 0,
          zIndex: { base: layers.zColour }
        }}
      >
        <Headline
          as='h1'
          pr={{ lg: '80px' }}
          pb={{ lg: 14 }}
          marginBottom={{ lg: 7 }}
          position='relative'
          color='white'
          width={{ base: '95%', lg: '50%' }}
          zIndex={layers.zColour + 1}
        >
          {heading}
        </Headline>
      </Container>
      <Container
        position='relative'
        mt={{ lg: '-120px' }}
        paddingX={{ base: 'unset', lg: 16 }}
        _after={{
          display: { base: 'none', lg: 'block' },
          content: "''",
          background: sanitisedBackgroundColour,
          position: 'absolute',
          width: { base: '95%', lg: '150%' },
          height: `calc(100% + 4rem)`,
          maxHeight: { base: '448px', xl: '256px' },
          left: { base: 0, lg: '-100%' },
          zIndex: layers.zColour,
          top: 0
        }}
      >
        <Box zIndex={layers.zWidget} position='relative'>
          {finderWidget}
        </Box>
      </Container>
    </Box>
  )
}

export type { FinderHeroProps }
export default FinderHero
