import { CheckboxGroup, Stack } from '@chakra-ui/react'
import {
  connectRefinementList,
  RefinementListExposed,
  RefinementListProvided
} from 'react-instantsearch-core'
import RefinementCheckbox from './RefinementCheckbox'

export interface StaticItemRefinementList {
  label: string
  key: string
  value: string
}

interface SortFunctionProps {
  label: string
  count: number
}

interface StaticRefinementListExposed {
  staticItems: StaticItemRefinementList[]
  sort?: (a: SortFunctionProps, b: SortFunctionProps) => number
}
export type StaticRefinementListProps = Pick<
  RefinementListProvided,
  'items' | 'currentRefinement' | 'refine'
> &
  StaticRefinementListExposed

const DEFAULT_FACET_LIMIT = 100

const StaticRefinement = ({
  staticItems,
  items,
  currentRefinement,
  refine,
  sort = () => 0
}: StaticRefinementListProps) => {
  const staticRefinmentItems = staticItems
    .map((item) => {
      const { label, value } = item

      const { isRefined, count } = items.find(
        (item) => item.label == value
      ) || {
        count: 0,
        isRefined: false
      }

      return {
        label,
        value,
        isRefined,
        count
      }
    })
    .sort(sort)

  return (
    <CheckboxGroup>
      <Stack spacing={1}>
        {staticRefinmentItems.map(({ label, value, isRefined, count }) => {
          return (
            <RefinementCheckbox
              label={label}
              key={value}
              count={count}
              isChecked={isRefined}
              onChange={(event) => {
                event.preventDefault()
                // Toggle the selected element in or out of the currentRefinement
                const nextRefinement = currentRefinement.includes(value)
                  ? currentRefinement.filter((current) => current !== value)
                  : currentRefinement.concat(value)

                refine(nextRefinement)
              }}
            />
          )
        })}
      </Stack>
    </CheckboxGroup>
  )
}

const ConnectedRefinement = connectRefinementList(StaticRefinement)

const StaticFacetRefinement = ({
  limit = DEFAULT_FACET_LIMIT,
  ...props
}: RefinementListExposed & StaticRefinementListExposed) => (
  <ConnectedRefinement limit={limit} {...props} />
)

export { StaticRefinement }
export default StaticFacetRefinement
