import {
  Checkbox,
  CheckboxGroup,
  CheckboxProps,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  Stack
} from '@chakra-ui/react'
import { ErrorMessage, useField } from 'formik'
import React, { FC, ReactNode } from 'react'

export type CheckboxSingleProps = FormControlProps & {
  name: string
  label: string
  checkboxProps?: CheckboxProps
  children?: ReactNode
  checkboxOptions?: { key: string; label: string }[]
}

// See https://github.com/chakra-ui/chakra-ui/discussions/3262

export const MultiCheckboxControl: FC<CheckboxSingleProps> = (
  props: CheckboxSingleProps
) => {
  const { name, label, checkboxOptions, checkboxProps, ...rest } = props
  const [field, meta] = useField(name)
  const { onChange, ...restField } = field

  return (
    <FormControl
      id={name}
      name={name}
      isInvalid={!!(meta.error && meta.touched)}
      isRequired={props.isRequired}
      {...rest}
    >
      <CheckboxGroup {...restField}>
        <FormLabel fontSize='lg' fontFamily='enz500'>
          {label}
        </FormLabel>
        <Stack direction='column'>
          {checkboxOptions &&
            checkboxOptions.map((opt) => (
              <Checkbox
                key={String(opt.key)}
                name={name}
                value={String(opt.key)}
                onChange={onChange}
                sx={{
                  '.chakra-checkbox__label': {
                    fontSize: 'md'
                  }
                }}
                {...checkboxProps}
              >
                {opt.label}
              </Checkbox>
            ))}
        </Stack>
        <ErrorMessage component={FormErrorMessage} name={name} />
      </CheckboxGroup>
    </FormControl>
  )
}

export default MultiCheckboxControl
