import { AutocompleteState } from '@algolia/autocomplete-core'
import { SearchType } from '../types'

interface FindResultSourceResult {
  hasFacet: boolean
  hasQuery: boolean
}

type BaseItem = Record<string, unknown>

type FindResultSource<State extends BaseItem> = Pick<
  AutocompleteState<State>,
  'collections' | 'query'
>

function findResultSource<State extends BaseItem>({
  collections,
  query
}: FindResultSource<State>): FindResultSourceResult {
  const matchingTypes = collections
    .reduce<Array<State>>((matchingSet, collection) => {
      const items = collection.items.filter((item) => item.label === query)
      return items.length ? [...matchingSet, ...items] : matchingSet
    }, [])
    .flat()
    .map((item) => item.type)

  return matchingTypes.length
    ? {
        hasFacet: matchingTypes.includes(SearchType.FACET),
        hasQuery: matchingTypes.includes(SearchType.QUERY)
      }
    : {
        hasFacet: false,
        hasQuery: true
      }
}

export default findResultSource
