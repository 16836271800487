import { Box, Flex, Spacer } from '@chakra-ui/react'
import { VerticalThinkNewNZLogo } from 'icons'
import { ReactElement } from 'react'
import Headroom from 'react-headroom'
import { ZINDEX } from 'utils'
import { Link } from '../Link'

export interface HeaderProps {
  rightHandContent: ReactElement
}

const Header = ({ rightHandContent }: HeaderProps) => {
  return (
    <Headroom style={{ zIndex: ZINDEX.HEADER }}>
      <Box bg='deepblue.500' as='header' dir='ltr'>
        <Flex
          maxW={{ base: 'container.xl', xxl: 'none' }}
          m='auto'
          color='white'
          align='center'
          py={{ base: 2, xl: 4 }}
          px={{ base: 4, xl: 16 }}
        >
          <Link dataTestid='header-logo-link' href='/'>
            <VerticalThinkNewNZLogo
              transition='all 0.3s'
              w='auto'
              h={{ base: '44px', sm: '60px' }}
            />
          </Link>
          <Spacer />
          {rightHandContent}
        </Flex>
      </Box>
    </Headroom>
  )
}

export default Header
