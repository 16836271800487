import { Text, TextProps } from '@chakra-ui/react'
import React from 'react'

export default function StatsNumber(props: TextProps) {
  return (
    <Text
      fontSize={{ base: '8.75rem', lg: '12.5rem' }}
      lineHeight={{ base: '10rem', lg: '12.5rem' }}
      letterSpacing='-6px'
      fontFamily='a23d'
      marginBottom={2}
      sx={{
        'html:not([lang=en]) &': {
          fontWeight: '700',
          fontFamily: 'NotoSans Regular, Helvetica, Arial, sans-serif',
          letterSpacing: '0 !important'
        }
      }}
      {...props}
    />
  )
}
