import { Box, HStack } from '@chakra-ui/react'
import { ReactNode } from 'react'

interface GalleryIndicatorProps {
  slideAmount: number
  currentSlide: number
  handleClick: (index: number) => void
}

const GalleryIndicator = ({
  slideAmount,
  currentSlide,
  handleClick
}: GalleryIndicatorProps) => {
  const markup: ReactNode[] = []

  for (let index = 0; index < slideAmount; index++) {
    const isCurrent = currentSlide === index
    const selectedText = isCurrent ? 'Selected:' : ''

    markup.push(
      <Box
        key={index}
        as='span'
        cursor='pointer'
        borderWidth={1}
        borderColor='deepblue.300'
        transition='0.3s ease-in-out'
        height='6px'
        width={`${isCurrent ? '24px' : '6px'}`}
        borderRadius='full'
        aria-label={`${selectedText} slide item ${index + 1}`}
        title={`${selectedText} slide item ${index + 1}`}
        onClick={() => handleClick(index)}
      />
    )
  }

  return <HStack spacing={{ base: 1, md: 2 }}>{markup}</HStack>
}

export default GalleryIndicator
