import { AspectRatio, Flex, GridItem } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { BoldText, ContainerWrapper, GridLayout } from 'ui'
import { colourMap, getPageThemeBackgroundColour } from 'utils'

enum AlternatingQuoteDisplayType {
  PortraitLeft = 'portraitLeft',
  PortraitRight = 'portraitRight',
  LandscapeLeft = 'landscapeLeft',
  LandscapeRight = 'landscapeRight'
}

type AlternatingQuoteImage = {
  image: ReactElement | null
}

interface AlternatingQuoteProps {
  mediaItemProps: MediaItemProps
  displayType: AlternatingQuoteDisplayType
  pageTheme?: string
  quoteText: string | null
  credit?: string | null
  creditSuffix?: string | null
  identifier?: string | null
  quoteTextColour?: string | null
}

const configureImageWrapperGridItemProps = (
  orientationPlacement: AlternatingQuoteDisplayType
) => {
  switch (orientationPlacement) {
    case AlternatingQuoteDisplayType.PortraitLeft:
      return {
        mx: { base: '0px', lg: '50px' },
        colSpan: { base: 9, lg: 6, md: 4 }
      }
    case AlternatingQuoteDisplayType.PortraitRight:
      return {
        mx: { base: '0px', lg: '50px' },

        colSpan: { base: 6 },
        colStart: { lg: 7 }
      }
    case AlternatingQuoteDisplayType.LandscapeLeft:
      return {
        colSpan: { base: 4, lg: 7 }
      }
    case AlternatingQuoteDisplayType.LandscapeRight:
      return {
        colSpan: { base: 4, lg: 7 },
        colStart: { lg: 6 }
      }
    default:
      return {}
  }
}

const configureTextWrapperGridItemProps = (
  displayType: AlternatingQuoteDisplayType
) => {
  switch (displayType) {
    case AlternatingQuoteDisplayType.PortraitLeft:
      return {
        colSpan: 9,
        colStart: { lg: 8 }
      }
    case AlternatingQuoteDisplayType.PortraitRight:
      return {
        colSpan: 6,
        colStart: { lg: 0 },
        rowStart: { lg: 1 }
      }
    case AlternatingQuoteDisplayType.LandscapeLeft:
      return {
        colSpan: 4,
        colStart: { lg: 9 }
      }
    case AlternatingQuoteDisplayType.LandscapeRight:
      return {
        colSpan: 4,
        colStart: { lg: 1 },
        rowStart: { lg: 1 }
      }
    default:
      return {}
  }
}

const configureImageWrapperGridItemMargin = (
  displayType: AlternatingQuoteDisplayType
) => {
  const marginCSSValues = {
    base: 0, // Centre image on smaller screens
    sm: 'unset'
  }
  if (displayType === AlternatingQuoteDisplayType.PortraitRight) {
    return {
      marginRight: { ...marginCSSValues }
    }
  }
  return {
    marginLeft: { ...marginCSSValues }
  }
}

const configureAspectRatioMargin = (
  displayType: AlternatingQuoteDisplayType
) => {
  return displayType.includes('Right')
    ? { marginLeft: 'auto' }
    : { marginRight: 'auto' }
}

const renderMediaItem = <MediaType extends MediaItemProps>(
  mediaItemProps: MediaType
) => {
  return mediaItemProps.image
}

const AlternatingQuote = ({
  mediaItemProps,
  displayType,
  pageTheme,
  quoteText,
  credit,
  creditSuffix,
  quoteTextColour
}: AlternatingQuoteProps) => {
  const imageWrapperGridItemProps =
    configureImageWrapperGridItemProps(displayType)
  const imageWrapperGridItemMargin =
    configureImageWrapperGridItemMargin(displayType)
  const textWrapperGridItemProps =
    configureTextWrapperGridItemProps(displayType)
  const containerBackgroundColour = getPageThemeBackgroundColour(pageTheme)
  // If the colour is not recognised as a string deepblue.500 will be passed in and returned as a default return in colourMap.
  const sanitisedTextColour = colourMap(
    typeof quoteTextColour === 'string' ? quoteTextColour : 'deepblue.500'
  )

  return (
    <ContainerWrapper bgColor={containerBackgroundColour}>
      <GridLayout marginX='0'>
        <GridItem
          {...imageWrapperGridItemProps}
          {...imageWrapperGridItemMargin}
        >
          <AspectRatio
            ratio={displayType.includes('portrait') ? 3 / 4 : 16 / 9}
            position='relative'
            maxWidth={{ base: '100%', lg: 'unset' }}
            {...configureAspectRatioMargin(displayType)}
          >
            {renderMediaItem(mediaItemProps)}
          </AspectRatio>
        </GridItem>
        <GridItem {...textWrapperGridItemProps}>
          <Flex flexDir='column' justifyContent='center' height='100%'>
            {quoteText && (
              <BoldText
                mb={'24px'}
                fontSize={{ base: '22px', lg: '32px' }}
                lineHeight={{ base: '28px', lg: '44px' }}
                color={sanitisedTextColour}
                letterSpacing={'-1px'}
              >
                {quoteText}
              </BoldText>
            )}
            {credit && (
              <BoldText lineHeight='28px'>
                {credit} — {creditSuffix}
              </BoldText>
            )}
          </Flex>
        </GridItem>
      </GridLayout>
    </ContainerWrapper>
  )
}
export type MediaItemProps = AlternatingQuoteImage

export default AlternatingQuote
export type { AlternatingQuoteProps }
export { AlternatingQuoteDisplayType }
