import { Seo_Swnz_SeoFragment } from 'content-service'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { SITE_CONFIG } from 'swnz/src/constants'
import { createLanguageAlternates } from 'swnz/src/helpers'
import { createCanonicalUrl } from 'utils'
import { validatePageSeoData } from './helpers/validatePageSeoData'

export type SeoProps = Omit<Seo_Swnz_SeoFragment, 'sys'>

interface PageSeoProps {
  pageSeo: SeoProps | null
  metaImgSrc?: string
}

const PageSeo = ({ pageSeo, metaImgSrc }: PageSeoProps) => {
  const {
    asPath = '',
    locale = '',
    defaultLocale = '',
    locales = []
  } = useRouter() || {}
  const baseUrl: string = process.env.NEXT_PUBLIC_URL ?? ''

  if (!pageSeo) {
    return null
  }

  const { nextSeoProps, nextSeoKeywords } = validatePageSeoData(pageSeo)
  const canonicalUrl = createCanonicalUrl(
    asPath,
    locales,
    defaultLocale,
    locale
  )
  const openGraph = {
    url: canonicalUrl,
    title: nextSeoProps.title,
    description: nextSeoProps.description,
    images: [
      {
        url: metaImgSrc ? metaImgSrc : '/images/think-nz-og-image.png',
        width: 600,
        height: 600,
        alt: nextSeoProps.description
      }
    ],
    site_name: SITE_CONFIG.SITE_NAME
  }
  // If there is more than 1 locale in locales, add language alternates. Else, undefined.
  return (
    <NextSeo
      {...nextSeoProps}
      openGraph={openGraph}
      languageAlternates={
        locales.length > 1
          ? createLanguageAlternates(baseUrl, asPath)
          : undefined
      }
      canonical={canonicalUrl}
      additionalMetaTags={[{ property: 'keywords', content: nextSeoKeywords }]}
    />
  )
}

export default PageSeo
