const SEARCH_BAR = {
  HOVER: {
    '&::-webkit-search-cancel-button': {
      WebkitAppearance: 'none',
      appearance: 'none'
    },
    '::-webkit-search-decoration': {
      WebkitAppearance: 'none',
      appearance: 'none'
    },
    '&:placeholder': {
      color: 'deepblue.300'
    },

    ':hover::placeholder': {
      color: 'deepblue.500',
      transition: 'all 0.2s'
    }
  }
}
export default SEARCH_BAR
