import {
  Emotion_Swnz_EmotionContainerFragment,
  Emotion_Swnz_EmotionContainerFragmentDoc
} from 'content-service'
import { useEffect, useState } from 'react'
import useFragment from 'utils/src/hooks/useFragment'
import EmotionCardsCarousel from '../../components/content-components/EmotionCardsCarousel'
import { EmotionCardProps } from '../../components/content-components/EmotionCardsCarousel/EmotionCard'
import { Connector } from '../types'
import { createEmotionCardItems } from './helpers/emotionCardsCarousel'

export type EmotionCardsCarouselConnectProps = Connector

const EmotionCardsCarouselConnect = ({
  id,
  typename
}: EmotionCardsCarouselConnectProps) => {
  const cards = useFragment<Emotion_Swnz_EmotionContainerFragment>({
    id,
    typename,
    fragment: Emotion_Swnz_EmotionContainerFragmentDoc,
    fragmentName: 'emotion_SWNZ_EmotionContainer'
  })

  const [cardItems, setCardItems] = useState<EmotionCardProps[]>([])

  useEffect(() => {
    if (!cards) return

    const items = createEmotionCardItems({
      cardLinkItems: cards?.emotionItemsCollection?.items
    })

    setCardItems(items)
  }, [cards])

  if (!cardItems.length) {
    return null
  }

  const { heading = '', introText = '' } = cards || {}

  return (
    <EmotionCardsCarousel
      heading={heading as string}
      introText={introText as string}
      cards={cardItems}
    />
  )
}

export default EmotionCardsCarouselConnect
