import {
  Accordion_Swnz_AccordionContainerFragment,
  Accordion_Swnz_AccordionContainerFragmentDoc
} from 'content-service'
import { AccordionComponent } from 'ui'
import useFragment from 'utils/src/hooks/useFragment'
import { Connector } from '../types'
import { createAccordionItemsToRenderData } from './helpers'

export type AccordionConnectProps = Connector

const AccordionConnect = ({ id, typename }: AccordionConnectProps) => {
  const accordionContainer =
    useFragment<Accordion_Swnz_AccordionContainerFragment>({
      id,
      typename,
      fragment: Accordion_Swnz_AccordionContainerFragmentDoc,
      fragmentName: 'accordion_SWNZ_AccordionContainer'
    })

  if (!accordionContainer) {
    return null
  }

  const {
    title = '',
    introText = '',
    accordionItemsCollection
  } = accordionContainer || {}
  const { items = [] } = accordionItemsCollection || {}

  const accordionItemsToRenderArray = createAccordionItemsToRenderData(items)

  return (
    <AccordionComponent
      isLoaded={Boolean(accordionItemsToRenderArray.length)}
      title={title}
      introText={introText}
      accordionItemsToRenderArray={accordionItemsToRenderArray}
    />
  )
}

export default AccordionConnect
