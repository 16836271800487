import { mode } from '@chakra-ui/theme-tools'

const parts = ['container', 'track', 'thumb']
type Dict = Record<string, any>

function baseStyleTrack(props: Dict) {
  const { colorScheme: c } = props

  return {
    borderRadius: 'full',
    p: '0',
    width: '32px',
    height: '12px',
    bg: mode('deepblue.200', 'whiteAlpha.400')(props),
    pos: 'relative',
    _disabled: {
      opacity: 0.38
    },
    _checked: {
      bg: mode(`${c}.100`, `${c}.200`)(props)
    }
  }
}

function baseStyleThumb(props: Dict) {
  const { colorScheme: c } = props

  return {
    bg: 'white',
    width: '18px',
    height: '18px',
    pos: 'absolute',
    top: '-3px',
    boxShadow: '0px 5px 20px rgba(7, 25, 55, 0.1)',
    _checked: {
      transform: 'translateX(var(--slider-thumb-x))',
      bg: mode(`${c}.500`, `${c}.400`)(props)
    }
  }
}

const baseStyle = (props: Dict) => ({
  track: baseStyleTrack(props),
  thumb: baseStyleThumb(props)
})

const defaultProps = {
  size: 'md'
}

export default {
  parts,
  baseStyle,
  defaultProps
}
