import { Category } from 'taxonomy'

interface LevelOfStudyFacet {
  label: string
  value: string
  key: string
}

const createLevelOfStudyFacets = (
  data: Category,
  levelOfStudyFacets: LevelOfStudyFacet[] = []
) => {
  const { tags = [], type, key } = data || {}
  const isCategory = type === 'category'

  if (!isCategory) {
    return []
  }

  const isSpecificLevelOfStudy = key.indexOf('level-of-study-specific') >= 0
  for (const tag of tags) {
    const tagSubcategories = tag.subcategories[0] || undefined
    const isValidTag = tag.label && tag.key
    const isSchoolTag = tag.key === 'school'

    if (isSpecificLevelOfStudy) {
      levelOfStudyFacets.push({
        label: tag.label,
        key: tag.key,
        value: tag.label
      })
    }

    if (isValidTag && !isSchoolTag) {
      createLevelOfStudyFacets(tagSubcategories, levelOfStudyFacets)
    }
  }

  return levelOfStudyFacets
}

export type { LevelOfStudyFacet }
export { createLevelOfStudyFacets }
