import { StaticItemRefinementList } from 'ui'

const sortLevelOfSchools = (subtypeList: StaticItemRefinementList[]) => {
  const schoolSubtypesReferenceOrder = [
    'Primary School',
    'Intermediate School',
    'Secondary School'
  ]
  const orderedSubtypes: StaticItemRefinementList[] =
    schoolSubtypesReferenceOrder.flatMap((reference) =>
      subtypeList.filter((subtype) => subtype.key === reference)
    )
  return orderedSubtypes
}

export default sortLevelOfSchools
