import { AspectRatio, Box, GridItem } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { ContainerWrapper, GridLayout } from '../../layout'
import { H1 } from '../../typography'

interface ReducedHeroProps {
  heading: string
  image: ReactElement | null
}
const ReducedHero = ({ heading, image }: ReducedHeroProps): ReactElement => {
  return (
    <ContainerWrapper
      sx={{
        pt: 0,
        mt: { base: 12, md: 0, lg: 20 },
        mb: { base: 0, lg: '20px' }
      }}
      pos='relative'
    >
      <GridLayout>
        <GridItem
          colStart={1}
          colEnd={{ base: 5, md: 8, lg: 6 }}
          gridRow={{ base: 1, md: 2, lg: 1 }}
          ml={{ base: 0, md: -6, lg: 0 }}
          mt={{ base: 0, md: -16, lg: 0 }}
          zIndex='1'
        >
          <Box
            paddingLeft={{ base: 0, md: 6, lg: 0 }}
            paddingRight={{ base: 0, lg: 6 }}
            paddingTop={{ base: 0, md: 4, lg: 0 }}
            paddingBottom={{ base: 0, lg: 6 }}
            backgroundColor='white'
          >
            <H1 zIndex='1' position='relative' mb={{ base: 2, md: 0 }}>
              {heading && heading}
            </H1>
          </Box>
        </GridItem>
        {image && (
          <GridItem
            colStart={{ base: 1, lg: 4 }}
            colEnd={{ base: 5, md: 9, lg: 13 }}
            gridRow={{ md: 1 }}
            mx={{ base: -6, lg: 0 }}
          >
            <AspectRatio ratio={16 / 9}>{image}</AspectRatio>
          </GridItem>
        )}
      </GridLayout>
    </ContainerWrapper>
  )
}

export type { ReducedHeroProps }
export default ReducedHero
