import React from 'react'
import {
  UnorderedList,
  ListItem,
  OrderedList,
  ListProps,
  ListItemProps
} from '@chakra-ui/react'

export const UL = (props: ListProps) => (
  <UnorderedList spacing={2} mb={4} marginStart={8} {...props} />
)

export const OL = (props: ListProps) => (
  <OrderedList spacing={2} mb={4} marginStart={8} {...props} />
)

export const LI = (props: ListItemProps) => <ListItem mb={2} {...props} />
