import {
  HomepageStoryPanel_Swnz_HomepageStoryPanelFragment,
  HomepageStoryPanel_Swnz_HomepageStoryPanelFragmentDoc,
} from 'content-service'
import React, { ReactElement, useEffect, useState } from 'react'
import { CardCarouselGallery, CarouselType, Connector, OptimisedImage } from 'ui'
import { IMAGE_SIZE_CONFIG } from 'utils'
import useFragment from 'utils/src/hooks/useFragment'

interface HomepageStoryPanelConnectProps extends Connector {}

const HomepageStoryPanelConnect = ({
  id,
  typename,
}: HomepageStoryPanelConnectProps): ReactElement | null => {
  const homepageStoryPanel = useFragment<HomepageStoryPanel_Swnz_HomepageStoryPanelFragment>({
    id,
    typename,
    fragment: HomepageStoryPanel_Swnz_HomepageStoryPanelFragmentDoc,
    fragmentName: 'homepageStoryPanel_SWNZ_HomepageStoryPanel',
  })

  const [storyCards, setStoryCards] = useState<
    | {
        name: string
        areaOfStudy: string
        location: string
        image: React.ReactElement<any, string | React.JSXElementConstructor<any>> | null
        tags: string[]
        modalId: string | undefined
      }[]
    | null
  >(null)

  useEffect(() => {
    if (homepageStoryPanel) {
      const storyItems = homepageStoryPanel.storyCardCarouselCollection?.items
      const storyCards = storyItems
        ? storyItems.map((item) => {
            return {
              name: item?.name || '',
              areaOfStudy: item?.areaOfStudy || '',
              location: item?.location || '',
              image: OptimisedImage({
                src: item?.image?.source?.url || '',
                alt: item?.image?.source?.description || '',
                title: item?.image?.source?.title || '',
                imageSizeConfig: IMAGE_SIZE_CONFIG.CARD_CAROUSEL_GALLERY_CARD,
                blurOnLoad: false,
              }),
              tags: item?.tags?.length ? (item.tags as string[]) : [],
              modalId: item?.modalContent?.sys.id,
              modalHasHeroVideo: item?.modalContent?.hero?.__typename === 'SWNZ_MediaVideo',
            }
          })
        : []

      setStoryCards(storyCards)
    }
  }, [homepageStoryPanel])

  if (!storyCards || !homepageStoryPanel) {
    return null
  }

  return (
    <CardCarouselGallery
      carouselType={CarouselType.Story}
      title={homepageStoryPanel.title || ''}
      backgroundColor={homepageStoryPanel.backgroundColour || ''}
      textColor={'white'}
      pseudoColor={homepageStoryPanel.backgroundColour || ''}
      tagHeading={homepageStoryPanel.tagHeading || ''}
      cards={storyCards}
    />
  )
}

export default HomepageStoryPanelConnect
