import { InventoryCourse } from 'content-service'
import { OpenGraph } from 'next-seo/lib/types'
import {
  createOpenGraphConfig,
  stripTagsFromHtmlStringAndTruncate
} from './index'

const createCourseDetailPageSeo = ({
  inputData,
  pageUrl
}: {
  inputData: InventoryCourse
  pageUrl: string
}): OpenGraph => {
  const title =
    `${inputData.name} | ${inputData?.provider?.name} | Study with New Zealand` ||
    ''
  // If we have both course name and provider name we want to provide a default description string
  // otherwise return the course description
  const description =
    inputData.name && inputData.provider?.name
      ? `Study ${inputData.name} at ${inputData.provider.name}. Enrol Now!`
      : inputData.details || ''
  const formattedDescription = stripTagsFromHtmlStringAndTruncate(description)

  return createOpenGraphConfig({
    title,
    url: pageUrl,
    description: formattedDescription
  })
}

export { createCourseDetailPageSeo }
