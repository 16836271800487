import {
  ProfileAPISuggestionInventoryItemModel,
  StudyOptionsAPILearnerInventoryItemModel,
  StudyOptionStatus,
} from 'ui'
import { ProfileInventory } from 'ui/src/forms/helpers/profileForm'

export const currentDate = new Date()

export const transformSuggestions = (
  suggestions: ProfileAPISuggestionInventoryItemModel[]
): StudyOptionsAPILearnerInventoryItemModel[] => {
  let transformedSuggestions: StudyOptionsAPILearnerInventoryItemModel[] = []

  suggestions.length > 0 &&
    suggestions.reverse().forEach((suggestion) => {
      if (!suggestion.provider && !suggestion.course) return

      if (
        suggestion.provider &&
        transformedSuggestions.findIndex(
          (transformedSuggestion) => transformedSuggestion.itemId === suggestion.provider
        ) === -1
      ) {
        const transformedProvider: StudyOptionsAPILearnerInventoryItemModel = {
          itemType: ProfileInventory.Provider,
          itemId: suggestion.provider,
          status: StudyOptionStatus.Suggested,
          matchScore: suggestion.matchScore,
          updated: currentDate,
        }

        transformedSuggestions = [...transformedSuggestions, transformedProvider]
      }

      if (suggestion.course) {
        const transformedCourse: StudyOptionsAPILearnerInventoryItemModel = {
          itemType: ProfileInventory.Course,
          itemId: suggestion.course,
          status: StudyOptionStatus.Suggested,
          matchScore: suggestion.matchScore,
          updated: currentDate,
        }

        transformedSuggestions = [...transformedSuggestions, transformedCourse]
      }
    })

  return transformedSuggestions
}
