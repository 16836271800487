// Workout what video platform the video is hosted on
// based on the url
export function getVideoType(url: string) {
  if (url.includes('youtube') || url.includes('youtu.be')) {
    return 'youtube'
  }

  if (url.includes('bilibili')) {
    return 'bilibili'
  }

  return ''
}
