import { ListItem, UnorderedList } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'ui'
import { NavLink } from '../Nav/NavItem'

interface BreadcrumbNavLinksProps {
  links: NavLink[] | []
  currentPath: string
}

const BreadcrumbNavLinks = React.forwardRef<
  HTMLUListElement,
  BreadcrumbNavLinksProps
>(({ links, currentPath }, ref) => {
  return (
    <UnorderedList
      ref={ref}
      listStyleType='none'
      height='100%'
      margin='0'
      overflowX='auto'
      overflowY='hidden'
      whiteSpace='nowrap'
      boxSizing='content-box'
      dir='ltr'
    >
      {links.map((link: NavLink, index: number) => {
        const { slug, title } = link
        const currentPathMatchesSlug = currentPath === slug

        return (
          <ListItem
            height='full'
            key={index}
            _first={{
              marginLeft: { base: 4, md: 6, xl: 8 },
              'html[lang=ar] &': {
                marginLeft: 0
              }
            }}
            _notLast={{
              marginRight: { base: 4, md: 6, xl: 8 }
            }}
            _last={{
              'html[lang=ar] &': {
                marginRight: { base: 4, md: 6, xl: 8 }
              }
            }}
            display='inline-block'
          >
            <Link
              href={slug}
              color='white'
              textDecoration='none'
              fontSize='14px'
              position='relative'
              height='full'
              display='flex'
              alignItems='center'
              fontFamily='enz500'
              _hover={{
                opacity: 0.7
              }}
              _after={{
                content: `''`,
                height: currentPathMatchesSlug ? '4px' : 0,
                width: 'full',
                backgroundColor: 'teal.500',
                position: 'absolute',
                bottom: 0,
                left: 0,
                transition: 'height 0.1s ease-in-out'
              }}
            >
              {title}
            </Link>
          </ListItem>
        )
      })}
    </UnorderedList>
  )
})

BreadcrumbNavLinks.displayName = 'BreadcrumbNavLinks'
export default BreadcrumbNavLinks
export type { BreadcrumbNavLinksProps }
