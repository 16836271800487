import { Box, Fade, Stack, StackProps } from '@chakra-ui/react'
import { SearchIcon } from 'icons'
import { TEALIUM_LINK_CATEGORY } from 'tracking'
import { CallToAction, CtaButton } from 'ui'

type HomepageHeroCtasProps = StackProps & {
  heroCtas: CallToAction[]
  pageTheme?: string
  isVisible?: boolean
  id?: string
  leadCaptureFormId?: string
}

export default function HomepageHeroCtas({
  heroCtas,
  isVisible,
  id,
  leadCaptureFormId,
  ...props
}: HomepageHeroCtasProps) {
  return (
    <Fade
      id={id}
      in={isVisible}
      animate={{ opacity: isVisible ? 1 : 0 }}
      transition={{ enter : { duration: 1 } }}
    >
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        mt={6}
        w="full"
        justifyContent="center"
        spacing={{ base: 2, lg: 4 }}
        {...props}
      >
        {heroCtas.map((cta: CallToAction, index: number) => (
          <CtaButton
            colorScheme={index === 0 ? 'teal' : 'white'}
            variant={index === 0 ? undefined : 'monochrome'}
            key={index}
            href={cta.href}
            openInNewWindow={Boolean(cta.openInNewWindow)}
            isExternalLink={Boolean(cta.isExternalLink)}
            rightIcon={
              index === 0 ? (
                <Box boxSize={7}>
                  <SearchIcon />
                </Box>
              ) : undefined
            }
            aria-label={'label'}
            modalId={cta.modalId}
            leadCaptureFormId={leadCaptureFormId}
            width={{ base: '325px', lg: 'unset' }}
            linkCategory={
              Boolean(cta.isExternalLink)
                ? TEALIUM_LINK_CATEGORY.OUTBOUND
                : TEALIUM_LINK_CATEGORY.CTA
            }
          >
            {cta.label}
          </CtaButton>
        ))}
      </Stack>
    </Fade>
  )
}
