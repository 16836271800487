import { EmotionItem_Swnz_EmotionItemFragment } from 'content-service'
import { isPresent } from 'ts-is-present'
import { createCtaButton } from 'utils/src/helpers'
import { EmotionCardProps } from '../../../components/content-components'
export interface CreateEmotionCardItems {
  cardLinkItems?: Array<EmotionItem_Swnz_EmotionItemFragment | null>
}

export const createEmotionCardItems = ({
  cardLinkItems
}: CreateEmotionCardItems): Array<EmotionCardProps> | [] => {
  if (!cardLinkItems) {
    return []
  }

  const linkItems = cardLinkItems
    ? cardLinkItems.filter(isPresent).map((item) => {
        return {
          id: item?.sys?.id,
          label: item?.label ?? '',
          icon: item?.icon?.source?.url ?? '',
          response: item?.responsesCollection?.items?.map((response) => {
            return {
              heading: response?.heading ?? '',
              bodyText: response?.bodyText ?? '',
              callToAction: createCtaButton(response?.callToAction) ?? undefined
            }
          })
        }
      })
    : []
  return [...linkItems]
}
