const createBreadcrumbNavBasePath = (
  pathArray: string | string[] | undefined
): string => {
  let sectionCodeId = ''
  let sanitisedSectionCodeId = ''

  if (!pathArray?.length) {
    return ''
  }

  sectionCodeId = pathArray[pathArray.length - 2]
  sanitisedSectionCodeId =
    pathArray.length <= 2 ? sectionCodeId + '-section' : sectionCodeId

  return sanitisedSectionCodeId
}

export { createBreadcrumbNavBasePath }
