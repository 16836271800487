import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  useDisclosure
} from '@chakra-ui/react'
import { CloseIcon, SearchIcon } from 'icons'
import React, { ReactNode } from 'react'
import { Modal } from 'ui/src/components/modal'
import { Button } from '../../buttons'
import SearchTitleCta from '../SearchTitleCta'
import { SearchTitleCtaProps } from '../SearchTitleCta/SearchTitleCta'

/**
 * In order for the <SearchAutocompleteItem> to be able to invoke an onClose action for this modal
 * we need to wrap the <SearchAutoCompleteModal/> in Context. We add the relevant modal actions to
 * the Context object, so that they may be consumed as callbacks.
 *
 * Below are the types, context and provider declarations necessary for this to work.
 */
interface SearchAutocompleteModalContextInterface {
  isOpen: boolean
  onOpen(): void
  onClose(): void
}
interface SearchAutocompleteModalProviderProps {
  children: React.ReactNode | React.ReactNodeArray
}

export const SearchAutocompleteModalContext =
  React.createContext<SearchAutocompleteModalContextInterface>({
    isOpen: false,
    onOpen: () => {},
    onClose: () => {}
  })

export function SearchAutocompleteModalProvider({
  children
}: SearchAutocompleteModalProviderProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure({ defaultIsOpen: false })

  return (
    <SearchAutocompleteModalContext.Provider
      value={{ onClose, onOpen, isOpen }}
    >
      {children}
    </SearchAutocompleteModalContext.Provider>
  )
}

interface SearchAutocompleteModal {
  placeholder: string
  title: string
  currentRefinement: string
  children: ReactNode
  showLabel?: boolean
  searchTitleCta?: SearchTitleCtaProps
}

const SearchAutocompleteModal = ({
  currentRefinement,
  placeholder,
  title,
  children,
  showLabel,
  searchTitleCta
}: SearchAutocompleteModal) => {
  const closeButtonSize = 8
  const inputContainsQuery = Boolean(currentRefinement)

  return (
    <SearchAutocompleteModalProvider>
      <SearchAutocompleteModalContext.Consumer>
        {(context) => {
          const { isOpen, onOpen, onClose } = context
          return (
            <Box position='relative' dir='ltr'>
              <Stack spacing={2}>
                {showLabel && (
                  <Box fontSize='20px' fontFamily='enz700' lineHeight='30px'>
                    {title}
                  </Box>
                )}
                <InputGroup>
                  <Input
                    type='button'
                    width='100%'
                    textAlign='left'
                    cursor='pointer'
                    onClick={onOpen}
                    color={inputContainsQuery ? 'deepWaterBlue' : 'grey.500'}
                    value={inputContainsQuery ? currentRefinement : placeholder}
                    sx={{
                      '&:placeholder': {
                        color: 'deepblue.300'
                      },
                      wordWrap: 'normal',
                      'html[lang=ar] &': {
                        textAlign: 'right'
                      }
                    }}
                  />
                  <InputRightAddon backgroundColor='teal.100'>
                    <Box boxSize={7}>
                      <SearchIcon color='deepblue.500' />
                    </Box>
                  </InputRightAddon>
                </InputGroup>
                {searchTitleCta && <SearchTitleCta {...searchTitleCta} />}
              </Stack>
              <Modal size='full' isOpen={isOpen} onClose={onClose}>
                <Modal.Overlay />
                <Modal.FullWidthContent my={0} borderRadius={0}>
                  <Flex padding={6}>
                    <Modal.Body padding={0}>{children}</Modal.Body>
                    <Box
                      width={closeButtonSize}
                      height={closeButtonSize}
                      marginLeft={4}
                      sx={{
                        'html[lang=ar] &': {
                          marginLeft: 'unset',
                          marginRight: 4
                        }
                      }}
                    >
                      <Flex
                        width={closeButtonSize}
                        height='60px'
                        alignItems='center'
                      >
                        <Button
                          variant='unstyled'
                          width={closeButtonSize}
                          height={closeButtonSize}
                          minWidth={closeButtonSize}
                          display='flex'
                          justifyContent='center'
                          alignItems='center'
                          aria-label='Close search'
                          icon={
                            <CloseIcon
                              boxSize={closeButtonSize}
                              color='grey.500'
                            />
                          }
                          onClick={onClose}
                        />
                      </Flex>
                    </Box>
                  </Flex>
                </Modal.FullWidthContent>
              </Modal>
            </Box>
          )
        }}
      </SearchAutocompleteModalContext.Consumer>
    </SearchAutocompleteModalProvider>
  )
}

export default SearchAutocompleteModal
