import { Box, Fade } from '@chakra-ui/react'
import { HomepageHero_Swnz_PageHomepageFragment } from 'content-service'
import type { PropsWithChildren, ReactElement } from 'react'
import { Image, RichTextRenderer } from 'ui'

interface HomepageHeroWelcomeMessage {
  heroWelcomeMessage: HomepageHero_Swnz_PageHomepageFragment['heroWelcomeMessage']
}

interface HomepageHeroWelcomeMessageProps {
  children: ReactElement | ReactElement[]
}

export default function HomepageHeroWelcomeMessage({
  children,
}: HomepageHeroWelcomeMessageProps): PropsWithChildren<ReactElement> {
  return (
    <Fade
      in={true}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ enter: { duration: 1, delay: 3.5 } }}
    >
      {children}
    </Fade>
  )
}

HomepageHeroWelcomeMessage.Fixed = function HomepageHeroWelcomeMessageFixed({
  heroWelcomeMessage,
}: HomepageHeroWelcomeMessage) {
  return (
    <Box
      display="flex"
      position="fixed"
      marginX="auto"
      left="0"
      right="0"
      bottom="0"
      bgColor="white"
      borderTop="4px solid"
      borderTopColor="surfaceBlue"
      maxWidth={'container.lg'}
      padding={6}
    >
      <Box boxSize={20} marginRight={6} position="relative" sx={{ minWidth: 20 }}>
        <Image
          src="/govt-logo.png"
          layout="fill"
          objectFit="cover"
          alt="New Zealand Government"
          blurOnLoad={false}
        />
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        flexFlow="wrap"
        sx={{
          '*': {
            display: 'flex',
          },
          'p:first-of-type': {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 0,
            width: 'calc(100% - 80px)',
          },
        }}
      >
        <RichTextRenderer richText={heroWelcomeMessage} />
      </Box>
    </Box>
  )
}

HomepageHeroWelcomeMessage.Static = function HomepageHeroWelcomeMessageFixed({
  heroWelcomeMessage,
}: HomepageHeroWelcomeMessage) {
  return (
    <Box borderTop="4px solid" borderTopColor="surfaceBlue" padding={6} background="white">
      <Box
        display="flex"
        flexDirection="column"
        flexFlow="wrap"
        sx={{
          '*': {
            display: 'flex',
            transition: '3s opacity ease',
          },
          'p:first-of-type': {
            display: 'flex',
            alignItems: 'center',
            marginBottom: 0,
            width: 'calc(100% - 80px)',
            paddingLeft: 1,
          },
          'p:last-of-type': {
            paddingTop: 4,
          },
        }}
      >
        <Box
          display="inline-flex"
          justifySelf="center"
          alignSelf="center"
          boxSize={10}
          marginRight={4}
          position="relative"
          sx={{ minWidth: 10 }}
        >
          <Image
            src="/govt-logo.png"
            layout="fill"
            objectFit="cover"
            alt="New Zealand Government"
            blurOnLoad={false}
          />
        </Box>
        <RichTextRenderer richText={heroWelcomeMessage} />
      </Box>
    </Box>
  )
}
