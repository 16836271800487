import {
  Box,
  IconButton,
  IconButtonProps,
  Text,
  useOutsideClick
} from '@chakra-ui/react'
import { InfoIcon } from 'icons'
import React, { useRef, useState } from 'react'
import { ZINDEX } from 'utils'

interface TooltipProps extends IconButtonProps {
  children: React.ReactNode
  boxPosition?: 'top' | 'bottom'
}

const Tooltip = ({ children, boxPosition, ...props }: TooltipProps) => {
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const ref = useRef<HTMLDivElement>(null)
  useOutsideClick({
    ref: ref,
    handler: () => {
      setTooltipOpen(false)
    }
  })

  return (
    <Box ref={ref} pos={{ md: 'relative' }} as='span' display='inline' ml={1}>
      <IconButton
        minW='unset'
        verticalAlign='sub'
        p={0}
        h='auto'
        bgColor='transparent'
        variant='unstyled'
        icon={
          <Box
            boxSize={5}
            sx={{
              circle: {
                fill: 'deepblue.200'
              }
            }}
          >
            <InfoIcon />
          </Box>
        }
        {...props}
        onClick={() => setTooltipOpen(!tooltipOpen)}
      />
      {tooltipOpen && (
        <Text
          bgColor='deepblue.50'
          fontFamily='enz400'
          fontSize='sm'
          lineHeight='20px'
          w={{ base: 'calc(100vw - 48px)', md: '317px' }}
          h='auto'
          position='absolute'
          p={4}
          zIndex={ZINDEX.TOOLTIP}
          bottom={boxPosition === 'top' ? 4 : 'unset'}
          top={boxPosition === 'bottom' ? 4 : 'unset'}
          left={{ base: 0, md: '50%' }}
          ml={{ md: '-158.5px' }}
          borderRadius='10px'
          boxShadow='0px 10px 20px rgba(0, 0, 0, 0.1)'
        >
          {children}
        </Text>
      )}
    </Box>
  )
}

export default Tooltip
