import { useAuth0 } from '@auth0/auth0-react'
import { useRouter } from 'next/router'
import { ROUTES } from '../constants'

export const redirectUri = `${process.env.NEXT_PUBLIC_URL}${ROUTES.AUTH.REDIRECT}`

export type LoginScreenHint = 'signup' | 'login'

export default function useLogin() {
  const { loginWithRedirect } = useAuth0()
  const router = useRouter()

  function login(signUp: LoginScreenHint = 'login', loginType?: string, returnTo?: string) {
    loginWithRedirect({
      authorizationParams: {
        screen_hint: signUp,
        redirect_uri: redirectUri,
      },
      appState: {
        returnTo: returnTo ?? router.asPath, // This url is required in case we login with social
        locale: router.locale,
        loginType: loginType,
      },
    })
  }

  return login
}
