import { datadogLogs, Logger } from '@datadog/browser-logs'

type APILogger = Logger & {
  emitError: (api: string, httpStatus: number, error?: unknown) => void
}

type FormLogger = APILogger & {
  emitSuccess: (form: string, details?: unknown) => void
}

/**
 * Used to report on form status, particularly around submission status.
 * [EXAMPLE USAGE]: CourseEnquiryForm and LeadCaptureForm.
 */
const formLogger = datadogLogs.getLogger('formLogger') as FormLogger

if (formLogger) {
  formLogger.emitError = (form: string, httpStatus: number, error: unknown) =>
    formLogger?.error(
      `*** [FORM_LOGGER] *** :: Uh oh. The ${form} submission has failed. HTTP status code: ${httpStatus}`,
      {
        error
      }
    )

  formLogger.emitSuccess = (form: string, details: unknown) =>
    formLogger?.info(
      `*** [FORM_LOGGER] *** :: Great! The ${form} form was successfuly submitted`,
      {
        details
      }
    )
}

/**
 * Used to report on API request status.
 * [EXAMPLE USAGE]: Enquiry API and leadcaptureAPI.
 */
const apiLogger = datadogLogs.getLogger('apiLogger') as APILogger

if (apiLogger) {
  apiLogger.emitError = (api: string, httpStatus: number, error: unknown) =>
    apiLogger?.error(
      `*** [API_LOGGER] *** :: Uh oh. A request to the ${api} API has failed. HTTP status code: ${httpStatus}`,
      { error }
    )
}

export { apiLogger, formLogger }
