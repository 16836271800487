import { Box } from '@chakra-ui/react'
import { ChevronIcon } from 'icons'
import { ReactElement, RefObject, useEffect } from 'react'
import { connectPagination } from 'react-instantsearch-dom'
import usePagination, { DOTS, range } from 'utils/src/hooks/usePagination'
import { Button } from '../../buttons'
import { LI, OL } from '../../lists'

type Refine = (refinement: string | number) => void
interface Props {
  currentRefinement: number
  nbPages: number
  refine: Refine
  darkTheme?: boolean
  scrollRef?: RefObject<any>
}

type CreateArrowNavigation = {
  pages: number[]
  type: string
  icon: ReactElement
  currentRefinement: number
  refine: Refine
  scrollRef?: RefObject<any>
}

type RenderLink = {
  testIdLabel: string
  refinement: number | string
  content: number | ReactElement
  refine: Refine
  currentRefinement: number
  darkTheme?: boolean
  scrollRef?: RefObject<any>
}

const handleClick = (ref: RefObject<any> | undefined) => {
  if (typeof window !== undefined) {
    ref
      ? ref.current.scrollIntoView({ behavior: 'smooth' })
      : window.scrollTo({ top: 0 })
  }
}

const RenderLink = ({
  testIdLabel,
  refinement,
  content,
  refine,
  currentRefinement,
  darkTheme,
  scrollRef
}: RenderLink) => {
  const selected = currentRefinement === refinement

  return (
    <LI
      key={`pagination-${testIdLabel}`}
      my={0}
      mx={1}
      listStyleType='none'
      _notFirst={{ my: 0 }}
      data-testid={`pagination-${testIdLabel}`}
    >
      <Button
        p={0}
        rounded={'full'}
        height={30}
        width={30}
        minW='inherit'
        display='flex'
        alignItems='center'
        justifyContent='center'
        variant={selected ? undefined : 'unstyled'}
        color={selected ? 'white' : darkTheme ? 'white' : 'deepblue.300'}
        borderColor={
          selected ? (darkTheme ? 'deepWaterBlue' : 'surfaceBlue') : 'none'
        }
        background={
          selected ? (darkTheme ? 'deepWaterBlue' : 'surfaceBlue') : 'none'
        }
        fontFamily={selected ? 'enz700' : 'enz400'}
        fontSize={'14px'}
        lineHeight={'20px'}
        onClick={() => {
          refine(refinement)
          handleClick(scrollRef)
        }}
      >
        {content}
      </Button>
    </LI>
  )
}

const ArrowNavigation = ({
  pages,
  type,
  icon,
  currentRefinement,
  refine,
  scrollRef
}: CreateArrowNavigation) => {
  let pageLink: number = currentRefinement
  const isFirst = currentRefinement === pages[0]
  const isLast = currentRefinement === pages[pages.length - 1]

  // Hide our Arrow nav if we are at the first or last page
  if ((isFirst && type === 'previous') || (isLast && type === 'next'))
    return null

  if (type === 'next' && !isLast) {
    pageLink++
  }

  if (type === 'previous' && !isFirst) {
    pageLink--
  }

  return (
    <RenderLink
      key={type}
      testIdLabel={type}
      refinement={pageLink}
      content={icon}
      refine={refine}
      currentRefinement={currentRefinement}
      scrollRef={scrollRef}
    />
  )
}

const Component = ({
  currentRefinement: currentPage,
  nbPages,
  refine,
  darkTheme,
  scrollRef
}: Props) => {
  const numberOfPagesAsArray = range(1, nbPages)
  const paginationRange = usePagination({
    currentPage,
    totalPageCount: nbPages,
    siblingCount: 2
  })

  useEffect(() => {
    if (nbPages < currentPage) {
      refine(nbPages)
      handleClick(scrollRef)
    }
  }, [refine, scrollRef, nbPages, currentPage])

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  return (
    <OL
      m={0}
      styleType='none'
      display='flex'
      flexDir='row'
      alignItems='center'
      flexWrap='wrap'
    >
      <ArrowNavigation
        pages={numberOfPagesAsArray}
        type={'previous'}
        icon={
          <Box color='teal.500' transform='rotate(180deg)' boxSize='20px'>
            <ChevronIcon />
          </Box>
        }
        currentRefinement={currentPage}
        refine={refine}
        scrollRef={scrollRef}
      />
      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS.DOTS) {
          return (
            <LI
              key={`pagination-${index}`}
              p='1em'
              data-testid={`pagination-${index}`}
            >
              <span>...</span>
            </LI>
          )
        }

        // Render our Page Pills
        return (
          <RenderLink
            key={index}
            testIdLabel={index.toString()}
            refinement={pageNumber}
            content={pageNumber}
            refine={refine}
            currentRefinement={currentPage}
            darkTheme={darkTheme}
            scrollRef={scrollRef}
          />
        )
      })}

      <ArrowNavigation
        pages={numberOfPagesAsArray}
        type={'next'}
        icon={
          <Box color='teal.500' width='20px'>
            <ChevronIcon />
          </Box>
        }
        currentRefinement={currentPage}
        refine={refine}
        scrollRef={scrollRef}
      />
    </OL>
  )
}

export { Component }
export default connectPagination(Component)
