import { createConnector } from 'react-instantsearch-dom'
import { ResultFilters } from '../ResultFilters'

const connectResultFilter: any = createConnector<
  Record<string, unknown>,
  ResultFilters
>({
  displayName: 'ResultsFilterConnector',
  getProvidedProps(props, searchState, searchResults) {
    return { ...props, searchState, searchResults }
  },
  refine(_, searchState, nextSearchState) {
    return {
      ...searchState,
      ...nextSearchState
    }
  },
  getSearchParameters(searchParameters, _, searchState) {
    const { query = '' } = searchState
    return searchParameters.setQuery(query)
  },
  cleanUp(_, searchState) {
    return searchState
  }
})

export default connectResultFilter
