import { useAuth0 } from '@auth0/auth0-react'
import { User as Auth0User } from '@auth0/auth0-spa-js'
import React, { createContext, useEffect } from 'react'
import {
  ProfileAPIValues,
  StudyOptionsAPILearnerInventoryItemModel,
  StudyOptionsAPIValues,
  StudyOptionStatus,
} from 'ui'
import { Actions, AUTH, Statuses } from '../constants'
import * as client from '../helpers/sdClient'

type Action =
  | { type: Actions.INITIALISING }
  | {
      type: Actions.INITIALISED
      profile?: ProfileAPIValues | null
      studyOptions?: StudyOptionsAPIValues | null
    }
  | { type: Actions.FAILED; error: Error | null }
  | { type: Actions.RESET }
  | {
      type: Actions.ADD_RECOMMENDATIONS
      recommendations?: StudyOptionsAPIValues | null
      studyOptions?: StudyOptionsAPIValues | null
    }
export type Dispatch = (action: Action) => void
type Status = Statuses.PENDING | Statuses.RESOLVED | Statuses.REJECTED
type State = {
  profile: ProfileAPIValues | null
  studyOptions: StudyOptionsAPIValues | null
  error: Error | null
  status: Status | null
}
type UserProviderProps = { children: React.ReactNode }

const UserStateContext = createContext<{ userState: State; userDispatch: Dispatch } | undefined>(
  undefined
)

function userReducer(state: State, action: Action): State {
  switch (action.type) {
    case Actions.INITIALISING: {
      return {
        ...state,
        status: Statuses.PENDING,
        error: null,
      }
    }
    case Actions.INITIALISED: {
      return {
        ...state,
        status: Statuses.RESOLVED,
        error: null,
        profile: action?.profile ?? state.profile,
        studyOptions: action?.studyOptions ?? state.studyOptions,
      }
    }
    case Actions.ADD_RECOMMENDATIONS: {
      const options: StudyOptionsAPIValues = {
        items: state?.studyOptions?.items
          ? state.studyOptions.items?.filter((item) => item.status !== StudyOptionStatus.Suggested)
          : [],
        totalItems: state?.studyOptions?.items ? state.studyOptions.items.length : 0,
      }

      const optionsArray: StudyOptionsAPILearnerInventoryItemModel[] =
        action?.recommendations?.items || []

      return {
        ...state,
        status: Statuses.RESOLVED,
        studyOptions: {
          items: [...optionsArray, ...options.items],
          totalItems: optionsArray?.length || 0,
        },
      }
    }
    case Actions.FAILED: {
      return {
        ...state,
        status: Statuses.REJECTED,
        error: action.error,
      }
    }
    case Actions.RESET: {
      return {
        status: null,
        error: null,
        profile: null,
        studyOptions: null,
      }
    }
  }
}

function UserProvider({ children }: UserProviderProps) {
  const [userState, userDispatch] = React.useReducer(userReducer, {
    status: null,
    error: null,
    profile: null,
    studyOptions: null,
  })
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0()

  useEffect(() => {
    const abortController = new AbortController()

    ;(async () => {
      if (isAuthenticated) {
        const audience = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE ?? ''
        const scope = AUTH.READ_USER_SCOPE
        const accessToken = await getAccessTokenSilently({
          authorizationParams: { audience, scope },
        })

        const requestInfo = {
          signal: abortController.signal,
        }
        await getUser(userDispatch, user, accessToken, requestInfo)
      }
    })()

    return () => abortController.abort()

    // `getAccessTokenSilently` causes an infinite loop in tests.
    // If we are omitting this value then we will also omit `options`
    // save needing to use a deep compare on the useEffect.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, getAccessTokenSilently, isAuthenticated])

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { userState, userDispatch }
  return <UserStateContext.Provider value={value}>{children}</UserStateContext.Provider>
}

async function getUser(
  dispatch: Dispatch,
  auth0User: Auth0User | undefined,
  accessToken: string,
  requestInfo?: RequestInit
) {
  dispatch({ type: Actions.INITIALISING })
  try {
    const profile = await client.getProfile(auth0User, accessToken, requestInfo)
    if (profile && profile.error) {
      dispatch({ type: Actions.FAILED, error: profile.error })
      return profile.error
    }

    const studyOptions = await client.getStudyOptions(profile, accessToken, requestInfo)
    if (studyOptions && studyOptions.error) {
      dispatch({ type: Actions.FAILED, error: studyOptions.error })
      return studyOptions.error
    }
    dispatch({ type: Actions.INITIALISED, profile, studyOptions })
  } catch (err) {
    const error = err instanceof Error ? err : null
    dispatch({ type: Actions.FAILED, error })
    return Promise.reject(error)
  }
}

async function getProfile(
  dispatch: Dispatch,
  user: Auth0User | undefined,
  accessToken: string,
  requestInfo?: RequestInit
) {
  try {
    const profile = await client.getProfile(user, accessToken, requestInfo)
    if (profile && profile.error) {
      dispatch({ type: Actions.FAILED, error: profile.error })
      return profile.error
    }
    dispatch({ type: Actions.INITIALISED, profile })

    return profile
  } catch (err) {
    const error = err instanceof Error ? err : null
    dispatch({ type: Actions.FAILED, error })
    return Promise.reject(error)
  }
}

async function updateStudyOptions(
  dispatch: Dispatch,
  accessToken: string,
  requestInfo?: RequestInit
) {
  try {
    const recommendations = await client.updateRecommendations(accessToken, requestInfo)
    if (recommendations && recommendations.error) {
      dispatch({ type: Actions.FAILED, error: recommendations.error })
      return recommendations.error
    }
    if (recommendations?.body?.length > 0) {
      dispatch({
        type: Actions.ADD_RECOMMENDATIONS,
        recommendations: { items: recommendations.body, totalItems: recommendations.body.length },
      })
    }
  } catch (err) {
    const error = err instanceof Error ? err : null
    dispatch({ type: Actions.FAILED, error })
    return Promise.reject(error)
  }
}

async function getStudyOptions(
  dispatch: Dispatch,
  profile: ProfileAPIValues | null,
  accessToken: string,
  requestInfo?: RequestInit
) {
  try {
    const studyOptions = await client.getStudyOptions(profile, accessToken, requestInfo)
    if (studyOptions && studyOptions.error) {
      dispatch({ type: Actions.FAILED, error: studyOptions.error })
      return studyOptions.error
    }

    dispatch({ type: Actions.INITIALISED, studyOptions })
  } catch (err) {
    const error = err instanceof Error ? err : null
    dispatch({ type: Actions.FAILED, error })
    return Promise.reject(error)
  }
}

function useUser() {
  const context = React.useContext(UserStateContext)
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider')
  }
  return context
}

export { UserProvider, useUser, getUser, getProfile, updateStudyOptions, getStudyOptions }
