import {
  AlternatingContent_Swnz_AlternatingContentFragment,
  AlternatingContent_Swnz_AlternatingContentFragmentDoc
} from 'content-service'
import { createCtaButton } from 'utils/src/helpers'
import useFragment from 'utils/src/hooks/useFragment'
import { RichTextRenderer } from '../../components/RichTextRenderer'
import { AlternatingComponent } from '../../components/content-components'
import { Connector } from '../types'
import {
  createMediaImageProps,
  createMediaVideoProps,
  validateDisplayType
} from './helpers'

export interface AlternatingComponentConnectProps extends Connector {
  pageTheme?: string
}

const AlternatingComponentConnect = ({
  id,
  typename,
  pageTheme,
  leadCaptureFormId
}: AlternatingComponentConnectProps) => {
  const alternatingContent =
    useFragment<AlternatingContent_Swnz_AlternatingContentFragment>({
      id,
      typename,
      fragment: AlternatingContent_Swnz_AlternatingContentFragmentDoc,
      fragmentName: 'alternatingContent_SWNZ_AlternatingContent'
    })

  if (!alternatingContent) {
    return null
  }

  const {
    title = null,
    mediaPlacement = null,
    mediaOrientation = null,
    mediaItem = null,
    primaryCta,
    secondaryCta
  } = alternatingContent || {}
  const primaryCtaButton = createCtaButton(primaryCta)
  const secondaryCtaButton = createCtaButton(secondaryCta)

  const hasVideo = mediaItem?.__typename === 'SWNZ_MediaVideo'
  const displayType = validateDisplayType(mediaOrientation, mediaPlacement)

  const mediaItemProps = hasVideo
    ? createMediaVideoProps(mediaItem)
    : createMediaImageProps(mediaItem)

  return (
    <AlternatingComponent
      primaryCta={primaryCtaButton}
      secondaryCta={secondaryCtaButton}
      hasVideo={hasVideo}
      mediaItemProps={mediaItemProps}
      displayType={displayType}
      heading={title}
      pageTheme={pageTheme}
      leadCaptureFormId={leadCaptureFormId}
    >
      <RichTextRenderer richText={alternatingContent?.bodyText} />
    </AlternatingComponent>
  )
}

export default AlternatingComponentConnect
