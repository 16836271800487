import { useEffect, useState } from 'react'
import { StaticItemRefinementList } from 'ui'
import { createConfig } from 'utils/src/helpers/algoliaSearch'

interface UseFacetValueFetch {
  attribute: string
  indexName: string
  filters?: string
  maxFacetLimit?: number
}
export default function useFacetValueFetch({
  attribute,
  indexName,
  filters = '',
  maxFacetLimit = 100
}: UseFacetValueFetch): StaticItemRefinementList[] | [] {
  const [oneShotFetch, setOneShotFetched] = useState<boolean>(true)
  const [items, setItems] = useState<StaticItemRefinementList[] | []>([])
  const { searchClient } = createConfig({
    indexName
  })
  useEffect(() => {
    if (oneShotFetch) {
      const getFacetValues = async () => {
        try {
          const [{ facetHits = [] }] = await searchClient.searchForFacetValues([
            {
              indexName,
              params: {
                facetName: attribute,
                facetQuery: '',
                filters,
                maxFacetHits: maxFacetLimit
              }
            }
          ])

          setItems(
            facetHits.map((facet) => ({
              ...facet,
              label: facet.value,
              value: facet.value,
              key: facet.value
            }))
          )
        } catch (error) {
          console.error(
            `Fetching algolia facet values with useFacetValueFetch for the attribute ${attribute} has failed`,
            error
          )
        }
      }

      getFacetValues()
      setOneShotFetched(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attribute, oneShotFetch, indexName, searchClient, filters])

  return items
}
