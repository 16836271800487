enum Actions {
  INITIALISING = 'INITIALISING',
  INITIALISED = 'INITIALISED',
  FAILED = 'FAILED',
  RESET = 'RESET',
  ADD_RECOMMENDATIONS = 'ADD_RECOMMENDATIONS',
}

enum Statuses {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  REJECTED = 'REJECTED',
}

export { Actions, Statuses }
