import { useAuth0 } from '@auth0/auth0-react'
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Stack,
  useDisclosure,
} from '@chakra-ui/react'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { CtaButton, H3, LargeText } from 'ui'
import { CLAIM, COOKIE, ROUTES } from '../../constants'

const FirstTimeLoginModal = () => {
  const { user } = useAuth0()
  const router = useRouter()
  const { onClose, isOpen } = useDisclosure()
  const firstTimeLogin = user && user[CLAIM.FIRST_LOGIN_CLAIM]
  const showModal = firstTimeLogin
  const loginTypeCookie = Cookies.get(COOKIE.SET_LOGIN_DATA)
  const [modalOpen, setModalOpen] = useState(showModal)

  const handleModalClose = () => {
    onClose()
    setModalOpen(false)
  }

  const handleButtonClick = (href: string) => {
    onClose()
    setModalOpen(false)
    router.push(href)
  }

  useEffect(() => {
    loginTypeCookie ? setModalOpen(false) : setModalOpen(showModal)
  }, [loginTypeCookie, showModal])

  return (
    <>
      <Modal
        onClose={handleModalClose}
        isOpen={modalOpen ? modalOpen : isOpen}
        isCentered
        scrollBehavior="inside"
      >
        <ModalOverlay background="rgba(4, 15, 32, 0.85)" />
        <ModalContent
          position="relative"
          maxWidth={{ md: '550px', lg: '650px' }}
          borderRadius={{ base: 0, md: '20px' }}
          my="unset"
          dir="ltr"
        >
          <ModalCloseButton
            position="absolute"
            right={{ base: 2, lg: 7 }}
            top={{ base: 2, lg: 12 }}
            zIndex={9999}
            aria-label="Close modal"
            backgroundColor={'rgba(230, 232, 235, 0.7)'}
            borderRadius="full"
            boxSize={{ base: '40px', lg: '50px' }}
            _hover={{
              backgroundColor: { md: 'deepblue.200' },
            }}
            sx={{
              'html[lang=ar] &': {
                right: 'unset',
                left: { base: 2, lg: 7 },
              },
              svg: {
                boxSize: { base: '14px', md: '18px' },
                color: { base: 'deepblue.300', md: 'deepblue.500' },
              },
            }}
          />
          <ModalBody
            flexGrow={{ base: 0, md: 1 }}
            paddingX={{ base: 5, lg: 7 }}
            paddingTop={{ base: 5, lg: 7 }}
            paddingBottom={12}
          >
            <H3>
              Nau mai, haere mai! <br />
              Welcome!
            </H3>
            <LargeText>Congratulations, you now have a Study With New Zealand account.</LargeText>
            <LargeText>
              You can now access your personal dashboard to see your recommendations, save your
              favourite items and send enquiries.
            </LargeText>
            <Box mt={7}>
              <Stack direction={{ base: 'column', sm: 'row' }} spacing={{ base: 2, md: 4 }}>
                <CtaButton onClick={() => handleButtonClick(ROUTES.DASHBOARD)}>
                  Go to dashboard
                </CtaButton>
                <CtaButton variant="outline" onClick={() => handleButtonClick(ROUTES.HOME)} ml={4}>
                  Back to home
                </CtaButton>
              </Stack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default FirstTimeLoginModal
