import { GridItem, Skeleton, Text } from '@chakra-ui/react'
import { ContainerWrapper, GridLayout, H2 } from 'ui'
import TileItems, { TileItem } from './TileItems'

export interface TilesProps {
  columns?: number
  title?: string | null
  introText?: string | null
  isLoaded: boolean
  tilesToRenderArray?: TileItem[]
  leadCaptureFormId?: string
}

const Tiles = ({
  tilesToRenderArray,
  columns,
  title,
  introText,
  isLoaded,
  leadCaptureFormId
}: TilesProps) => {
  return (
    <ContainerWrapper>
      <Skeleton isLoaded={isLoaded}>
        {title && (
          <GridLayout
            templateColumns={{
              base: '1fr',
              md: 'repeat(2, 1fr)'
            }}
            gap={{ base: 0, md: 6 }}
          >
            <GridItem>
              <H2 as='h2' size='2xl'>
                {title}
              </H2>
            </GridItem>
            {introText && (
              <GridItem>
                <Text
                  fontSize={{ base: '20px', lg: '22px' }}
                  lineHeight={{ base: '30px', lg: '36px' }}
                >
                  {introText}
                </Text>
              </GridItem>
            )}
          </GridLayout>
        )}
        <TileItems
          columns={columns}
          tilesToRenderArray={tilesToRenderArray}
          leadCaptureFormId={leadCaptureFormId}
        />
      </Skeleton>
    </ContainerWrapper>
  )
}

export default Tiles
