import { createSanitisedPageName } from "../helpers";
import { TEALIUM_EVENT } from "../helpers/constants";

interface LinkClick {
  pageName?: string;
  linkLabel: string;
  linkCategory: string;
  destinationUrl?: string;
  modalName?: string;
}

interface LinkClickResult {
  page_name: string;
  link_label: string;
  link_category: string;
  destination_url?: string;
  tealium_event: TEALIUM_EVENT;
  modal_name?: string;
}

/**
 * createLinkClickUtagEventObject
 * Build the data for a link_click event
 */
function createLinkClickUtagEventObject({
  pageName,
  linkLabel,
  linkCategory,
  destinationUrl,
  modalName,
}: LinkClick): LinkClickResult {
  return {
    page_name: pageName ? pageName : createSanitisedPageName(document.title),
    tealium_event: TEALIUM_EVENT.LINK_CLICK,
    link_label: linkLabel,
    link_category: linkCategory,
    ...(destinationUrl ? { destination_url: destinationUrl } : {}),
    ...(modalName ? { modal_name: modalName } : {}),
  };
}

export default createLinkClickUtagEventObject;
export type { LinkClick, LinkClickResult };
