import { SearchState } from 'react-instantsearch-core'

export interface BuildState {
  searchState: SearchState
  query?: string
  facet?: string
  facetValue?: string
}
export interface CreateFacetState {
  searchState: SearchState
  facet: string
  facetValue: string
  query?: string
}

function createFacetState({
  searchState,
  facet,
  facetValue,
  query
}: CreateFacetState) {
  const hasQuery = query || query === ''
  return {
    query: hasQuery ? query : facetValue,
    refinementList: {
      ...searchState?.refinementList,
      [facet]: [facetValue] ?? ''
    }
  }
}

function createQueryOnlyState({ query }: { query?: string }) {
  const hasQuery = query || query === ''
  return hasQuery
    ? {
        query
      }
    : {}
}

/**
 * Build the query state given the current search state, a query param, and a facet and its facetValue
 *
 * Pass the current search state through
 * Add a facet to the query
 * Update or remove the query value from the search
 */
export const buildState = ({
  searchState,
  facet,
  facetValue,
  query
}: BuildState): SearchState => {
  const newState =
    facet && facetValue
      ? createFacetState({ searchState, facet, facetValue, query })
      : createQueryOnlyState({ query })

  return {
    ...searchState,
    ...newState
  }
}
