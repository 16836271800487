type LinkTag = {
  tag: string
  key: string
  rel: string
  type?: string
  color?: string
  sizes?: string
  href: string
}

type MetaTag = {
  tag: string
  key: string
  name: string
  content: string
}

const GenerateSiteHeadHtml = ({
  items
}: {
  items: Array<LinkTag | MetaTag>
}) => {
  const generatedHtml = items.map((item) => {
    const { tag, key, ...rest } = item
    const HtmlTag = `${item.tag}`

    return <HtmlTag key={key} {...rest} />
  })

  return <>{generatedHtml}</>
}

export default GenerateSiteHeadHtml
