import { Select } from '@chakra-ui/react'
import { useRef } from 'react'
import { MenuProvided } from 'react-instantsearch-core'
import { connectMenu } from 'react-instantsearch-dom'

interface Props extends MenuProvided {
  type: 'primary' | 'secondary'
  attribute: string
  placeholder: string
}

type Items = Array<{
  count: number
  isRefined: boolean
  label: string
  value: string
}>

const createFacetArray = (items: Items): string[] =>
  items.map((item: { label: string }) => item.label)

const createPrimaryFacetArray = (items: Items) => {
  // Ignoring as I'm not sure this component will stick around
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ref = useRef(items)

  return createFacetArray(ref.current)
}

const Component = ({
  items,
  currentRefinement,
  refine,
  placeholder,
  type
}: Props) => {
  let allFacets: string[] = []

  if (type === 'primary' && items.length) {
    allFacets = createPrimaryFacetArray(items)
  } else if (type === 'secondary') {
    allFacets = createFacetArray(items)
  }

  return (
    <Select
      value={currentRefinement || ''}
      onChange={(event) => refine(event.target.value)}
    >
      <option defaultValue={placeholder} disabled hidden>
        {placeholder}
      </option>
      <option value=''>Any {placeholder.toLowerCase()}</option>
      {allFacets.map((item, index) => (
        <option key={index} value={item}>
          {item}
        </option>
      ))}
    </Select>
  )
}

const PrimaryFacetSelect = connectMenu(Component)

export default PrimaryFacetSelect
