import { Box } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { DisplayType } from 'ui'
import { colourBgMap } from 'utils'

export const getHeroIntroWrapperBgColor = (
  heroType,
  displayType,
  backgroundColor,
  textColor
) => {
  if (textColor === 'white') {
    return 'deep-blue'
  }

  if (heroType === 'SWNZ_HeroFeatured') {
    return 'surface-blue'
  }

  if (backgroundColor && displayType === DisplayType.FullBleed.toString()) {
    return backgroundColor
  }

  return undefined
}

export default function HeroIntroWrapper({
  children,
  backgroundColor
}: {
  children: ReactElement | ReactElement[]
  backgroundColor?: string
}) {
  const bgColor = backgroundColor ? colourBgMap(backgroundColor) : undefined
  return <Box backgroundColor={bgColor}>{children}</Box>
}
