import { Link } from '@chakra-ui/react'

interface JumpAheadMenuLinkProps {
  name: string
  title: string
  textColor?: string | null
}

const JumpAheadMenuLink = ({
  name,
  title,
  textColor
}: JumpAheadMenuLinkProps) => {
  return (
    <Link
      pr={4}
      textDecoration='none'
      display='inline-block'
      color={textColor === 'white' ? textColor : 'teal.500'}
      aria-label={name}
      _hover={{ textDecoration: 'none', color: 'teal.700' }}
      href={`#${name}`}
    >
      {title}
    </Link>
  )
}

export default JumpAheadMenuLink
export type { JumpAheadMenuLinkProps }
