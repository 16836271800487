import {
  IntroTextWithJumpMenu_Swnz_IntroTextWithJumpMenuFragment,
  IntroTextWithJumpMenu_Swnz_IntroTextWithJumpMenuFragmentDoc
} from 'content-service'
import { createCtaButton } from 'utils/src/helpers'
import useFragment from 'utils/src/hooks/useFragment'
import { IntroTextWithJumpMenu } from '../../components/content-components'
import { RichTextRenderer } from '../../components/RichTextRenderer'
import { Connector } from '../types'

const IntroTextWithJumpMenuConnect = ({
  id,
  typename,
  textColor,
  leadCaptureFormId
}: Connector & { textColor?: string | null }) => {
  const introTextWithJumpMenu:
    | IntroTextWithJumpMenu_Swnz_IntroTextWithJumpMenuFragment
    | null
    | undefined =
    useFragment<IntroTextWithJumpMenu_Swnz_IntroTextWithJumpMenuFragment>({
      id,
      typename,
      fragment: IntroTextWithJumpMenu_Swnz_IntroTextWithJumpMenuFragmentDoc,
      fragmentName: 'introTextWithJumpMenu_SWNZ_IntroTextWithJumpMenu'
    })

  const primaryCtaButton = createCtaButton(introTextWithJumpMenu?.primaryCta)

  return (
    <IntroTextWithJumpMenu
      primaryCta={primaryCtaButton}
      textColor={textColor}
      leadCaptureFormId={leadCaptureFormId}
    >
      <RichTextRenderer richText={introTextWithJumpMenu?.bodyText} />
    </IntroTextWithJumpMenu>
  )
}

export default IntroTextWithJumpMenuConnect
