import {
  Box,
  Center,
  Container,
  Flex,
  GridItem,
  Stack,
  StackProps,
  Text
} from '@chakra-ui/react'
import { ScrollIcon, SearchIcon } from 'icons'
import { PropsWithChildren, ReactElement, ReactNode } from 'react'
import {
  connectStateResults,
  StateResultsProvided
} from 'react-instantsearch-core'

import { Pagination } from '..'
import { BackButton, Button } from '../../buttons'
import { GridLayout } from '../../layout'
import { H1, H3 } from '../../typography'
import InstantSearchLoader from '../InstantSearchLoader'

interface FinderResultsBodyProps extends StateResultsProvided {
  children?: (options: {
    searchResults: StateResultsProvided['searchResults']
    searchState: StateResultsProvided['searchState']
  }) => ReactElement | ReactElement[] | null
}

const FinderResults = function FinderResults({
  children
}: PropsWithChildren<Record<string, unknown>>): ReactElement {
  return (
    <Container dir='ltr'>
      <InstantSearchLoader>{children}</InstantSearchLoader>
    </Container>
  )
}

FinderResults.Header = function FinderResultsHeader({
  title,
  children
}: PropsWithChildren<{ title: string }>): ReactElement {
  return (
    <>
      <Box pt={{ base: 4, lg: 10 }} pb={{ base: 0, lg: 46 }}>
        <BackButton hideTextOnMobile />
        <H1 mt={{ base: 7, lg: 16 }}>{title}</H1>
        <Stack spacing={4}>{children}</Stack>
      </Box>
    </>
  )
}

FinderResults.FilterContainer = function FinderResultsFilterContainer({
  children
}: PropsWithChildren<Record<string, unknown>>): ReactElement {
  return (
    <Flex
      alignItems={{ lg: 'center' }}
      flexDir={{ base: 'column', lg: 'row' }}
      position='relative'
      py={{ base: 6, lg: 2 }}
      _before={{
        content: '""',
        display: { base: 'block', lg: 'none' },
        position: 'absolute',
        bg: 'deepblue.100',
        top: 0,
        left: -6,
        h: '1px',
        w: 'calc(100% + 48px)'
      }}
    >
      {children}
    </Flex>
  )
}

const FinderResultsBody = ({
  searchResults,
  searchState,
  children
}: FinderResultsBodyProps) =>
  children ? <>{children({ searchResults, searchState })}</> : null

FinderResults.Body = connectStateResults(FinderResultsBody)

FinderResults.Results = function FinderResultsResults({
  children
}: PropsWithChildren<Record<string, unknown>>): ReactElement {
  return <Box>{children}</Box>
}

FinderResults.NoResults = function FinderResultsResults({
  query
}: {
  query: string
}): ReactElement {
  return (
    <Container>
      <GridLayout
        templateColumns={{
          base: 'repeat(4, 1fr)',
          md: 'repeat(8, 1fr)',
          lg: 'repeat(10, 1fr)'
        }}
      >
        <GridItem
          colSpan={{ base: 4, md: 6, lg: 8 }}
          colStart={{ base: 1, md: 2, lg: 2 }}
          textAlign={'center'}
          mb={24}
        >
          <Box
            display={'inline-block'}
            borderRadius={'full'}
            p={4}
            backgroundColor={'rgba(238, 238, 238, 0.5)'}
            mt={12}
            mb={8}
            boxSize={12}
          >
            <SearchIcon fill='deepblue.300' />
          </Box>
          <H3>{`Sorry we couldn't find anything for "${query}"`}</H3>
          <Text>
            Please check spelling or try with a different word or removing some
            filters before seaching again.
          </Text>
        </GridItem>
      </GridLayout>
    </Container>
  )
}

FinderResults.ResultsBar = function FinderResultsResultsBar({
  children,
  ...props
}: {
  children: ReactNode
} & StackProps): ReactElement {
  return (
    <Stack
      borderBottomWidth='1px'
      spacing={2}
      borderBottomColor='deepblue.500'
      marginBottom={7}
      pb={{ base: 12, lg: 0 }}
      justifyContent='space-between'
      flexDirection={{ base: 'column', lg: 'row' }}
      alignItems={{ lg: 'baseline' }}
      {...props}
    >
      {children}
    </Stack>
  )
}

FinderResults.ResultsContainer = function FinderResultsResultsContainer({
  sideBarHeading,
  children
}: PropsWithChildren<{ sideBarHeading: string }>): ReactElement {
  return (
    <GridLayout position='relative'>
      <GridItem colStart={1} colSpan={{ base: 4, md: 2, lg: 3 }}>
        <Text color='deepblue.300' fontFamily='enz700' fontSize='16px' mb={0}>
          {sideBarHeading}
        </Text>
      </GridItem>
      <GridItem
        colStart={{ base: 1, md: 3, lg: 4 }}
        colSpan={{ base: 4, md: 6, lg: 9 }}
      >
        {children}
      </GridItem>
    </GridLayout>
  )
}

FinderResults.SiteSearchResultsContainer =
  function FinderResultsSiteSearchResultsContainer({
    children
  }: {
    children?: ReactNode | undefined
  }): ReactElement {
    return (
      <GridLayout position='relative'>
        <GridItem
          colStart={{ base: 1, md: 2, lg: 3 }}
          colSpan={{ base: 4, md: 6, lg: 8 }}
        >
          {children}
        </GridItem>
      </GridLayout>
    )
  }

FinderResults.Footer = function FinderResultsFooter(): ReactElement {
  const handleClick = () => {
    if (typeof window !== undefined) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  return (
    <GridLayout mt='64px' mb={{ base: '60px', lg: '120px' }}>
      <GridItem
        colStart={1}
        colSpan={{ base: 4, md: 3 }}
        display='flex'
        alignItems='center'
        justifyContent={{ base: 'center', md: 'flex-start' }}
        gridRowStart={{ base: 2, md: 1 }}
        marginTop={{ base: 4, md: 0 }}
      >
        <Button
          variant='unstyled'
          color='teal.500'
          display='flex'
          padding='0'
          aria-label='Back to top'
          onClick={handleClick}
          leftIcon={
            <Box boxSize='28px' transform='rotate(180deg)' marginRight='0'>
              <ScrollIcon />
            </Box>
          }
        >
          Back to top
        </Button>
      </GridItem>
      <GridItem colStart={{ base: 1, md: 4 }} colSpan={{ base: 4, md: 9 }}>
        <Center>
          <Pagination />
        </Center>
      </GridItem>
    </GridLayout>
  )
}

export default FinderResults
