import { AccordionItem_Swnz_AccordionItemFragment } from 'content-service'
import { RichTextRenderer } from '../../../components/RichTextRenderer'
import { AccordionElement } from '../../../components/content-components'

const createAccordionItemDataStructureObject = (
  item: AccordionItem_Swnz_AccordionItemFragment
): AccordionElement => {
  const { bodyText, title } = item || {}

  return {
    title: title ?? '',
    bodyText: bodyText ? <RichTextRenderer richText={bodyText} /> : null
  }
}

export const createAccordionItemsToRenderData = (
  // @ts-ignore
  accordionItems: any
): Array<AccordionElement> => {
  const accordionItemsToRenderData: Array<AccordionElement> = []

  for (const accordionItem of accordionItems) {
    if (accordionItem !== null) {
      accordionItemsToRenderData.push(
        createAccordionItemDataStructureObject(accordionItem)
      )
    }
  }

  return accordionItemsToRenderData
}
