import { Box, Fade, Text, VStack } from '@chakra-ui/react'
import { ChevronIcon } from 'icons'
import { ZINDEX } from 'utils'

interface HomepageHeroJumpTextProps {
  heroJumpText: string | null | undefined
  isVisible: boolean
  scrollTo: number
}

export default function HomepageHeroJumpText({
  heroJumpText,
  isVisible,
  scrollTo,
}: HomepageHeroJumpTextProps) {
  const handleScrollBy = (): void => {
    window.scrollBy({
      top: scrollTo,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Fade in={isVisible} animate={{ opacity: isVisible ? 1 : 0 }} transition={{ enter: { duration: 1 } }}>
      <VStack
        zIndex={ZINDEX.LETS_GET_STARTED}
        position="relative"
        onClick={handleScrollBy}
        cursor="pointer"
        opacity={isVisible ? 1 : 0}
        transition="1s ease-in"
      >
        <Text fontSize={18} fontFamily="enz700" marginBottom={0} color="white">
          {heroJumpText}
        </Text>
        <Box
          transform="rotate(90deg)"
          boxSize={12}
          transition="all 1s ease"
          fill="surfaceBlue"
          sx={{
            _hover: {
              transform: 'rotate(90deg) translateX(5px)',
            },
          }}
        >
          <ChevronIcon />
        </Box>
      </VStack>
    </Fade>
  )
}
