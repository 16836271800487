import { Utag } from ".";
import { TEALIUM_EVENT } from "./constants";

declare const utag: Utag;

export const dispatchFormSubmissionUtagLinkEvent = (
  formType: string,
  formName: string,
  formPageLocation: string,
  levelOfStudy?: string,
  nationality?: string,
) => {
  return utag.link({
    tealium_event: TEALIUM_EVENT.FORM_SUBMISSION,
    form_type: formType,
    form_name: formName,
    form_page_location: formPageLocation,
    ...(levelOfStudy ? { level_of_study: levelOfStudy } : {}),
    ...(levelOfStudy ? { nationality } : {}),
  });
};
