/**
 * indexUtils
 * https://github.com/algolia/react-instantsearch/blob/master/packages/react-instantsearch-core/src/core/indexUtils.js
 */
import {
  ConnectorSearchResults,
  Refinement,
  SearchState
} from 'react-instantsearch-core'

export type InstantSearchState = SearchState & {
  indices?:
    | {
        [index: string]: {
          [key: string]: Record<string, unknown>
        }
      }
    | undefined
}

export type IndexContext =
  | {
      targetedIndex: string
    }
  | undefined

type Listener = () => void

export type Store = {
  getState: () => InstantSearchState
  setState: (nextState: InstantSearchState) => InstantSearchState
  subscribe: (listener: Listener) => () => Listener[]
}

export type InstantSearchContext = {
  onInternalStateUpdate: (searchState: InstantSearchState) => void
  createHrefForState: (searchState: string) => void
  onSearchForFacetValues: (searchState: string) => void
  onSearchStateChange: (searchState: InstantSearchState) => void
  onSearchParameters: (
    getSearchParameters: (...args: any[]) => any,
    context: { ais: InstantSearchContext; multiIndexContext: IndexContext },
    props: Record<string, unknown>,
    searchState: InstantSearchState
  ) => void
  store: Store
  widgetsManager: any
  mainTargetedIndex: string
}

export type InstantSearchIndexContext = {
  ais?: InstantSearchContext
  multiIndexContext: IndexContext
}

type ConnectedFields = {
  multiIndex: { targetedIndex: string }
  searchState: InstantSearchState
  indexId: string
  id: string
  namespace: string
  attribute: string
  context: InstantSearchContext
}

export function getIndexId(context: InstantSearchIndexContext) {
  return hasMultipleIndices(context)
    ? // @ts-ignore
      context.multiIndexContext.targetedIndex
    : // @ts-ignore
      context.ais.mainTargetedIndex
}

/**
 * @returns {import('algoliasearch-helper').SearchResults} results
 */
export function getResults(
  searchResults: ConnectorSearchResults,
  context: InstantSearchIndexContext
) {
  if (searchResults.results) {
    if (searchResults.results.hits) {
      return searchResults.results
    }

    const indexId = getIndexId(context)
    if (searchResults.results[indexId]) {
      return searchResults.results[indexId]
    }
  }

  return null
}

export function hasMultipleIndices(context: InstantSearchIndexContext) {
  return context && context.multiIndexContext
}

// eslint-disable-next-line max-params
export function refineValue(
  searchState: InstantSearchState,
  nextRefinement: Refinement,
  context: InstantSearchIndexContext,
  resetPage: boolean,
  namespace: string
) {
  if (hasMultipleIndices(context)) {
    const indexId = getIndexId(context)
    return namespace
      ? refineMultiIndexWithNamespace(
          searchState,
          nextRefinement,
          indexId,
          resetPage,
          namespace
        )
      : refineMultiIndex(searchState, nextRefinement, indexId, resetPage)
  } else {
    // When we have a multi index page with shared widgets we should also
    // reset their page to 1 if the resetPage is provided. Otherwise the
    // indices will always be reset
    // see: https://github.com/algolia/react-instantsearch/issues/310
    // see: https://github.com/algolia/react-instantsearch/issues/637
    if (searchState.indices && resetPage) {
      Object.keys(searchState.indices).forEach((targetedIndex) => {
        //@ts-ignore
        searchState = refineValue(
          searchState,
          //@ts-ignore
          { page: 1 },
          { multiIndexContext: { targetedIndex } },
          true,
          namespace
        )
      })
    }
    return namespace
      ? refineSingleIndexWithNamespace(
          searchState,
          nextRefinement,
          resetPage,
          namespace
        )
      : refineSingleIndex(searchState, nextRefinement, resetPage)
  }
}

function refineMultiIndex(
  searchState: SearchState,
  nextRefinement: SearchState,
  indexId: string,
  resetPage: boolean
) {
  const page = resetPage ? { page: 1 } : undefined
  const state =
    searchState.indices && searchState.indices[indexId]
      ? {
          ...searchState.indices,
          [indexId]: {
            ...searchState.indices[indexId],
            ...nextRefinement,
            ...page
          }
        }
      : {
          ...searchState.indices,
          [indexId]: {
            ...nextRefinement,
            ...page
          }
        }

  return {
    ...searchState,
    indices: state
  }
}

function refineSingleIndex(
  searchState: SearchState,
  nextRefinement: Refinement,
  resetPage: boolean
) {
  const page = resetPage ? { page: 1 } : undefined
  return { ...searchState, ...nextRefinement, ...page }
}

// eslint-disable-next-line max-params
function refineMultiIndexWithNamespace(
  searchState: InstantSearchState,
  nextRefinement: Refinement,
  indexId: string,
  resetPage: boolean,
  namespace: string
) {
  const page = resetPage ? { page: 1 } : undefined
  const state =
    searchState.indices && searchState.indices[indexId]
      ? {
          ...searchState.indices,
          [indexId]: {
            ...searchState.indices[indexId],
            [namespace]: {
              ...searchState.indices[indexId][namespace],
              ...nextRefinement
            },
            page: 1
          }
        }
      : {
          ...searchState.indices,
          [indexId]: {
            [namespace]: nextRefinement,
            ...page
          }
        }

  return {
    ...searchState,
    indices: state
  }
}

function refineSingleIndexWithNamespace(
  searchState: SearchState,
  nextRefinement: Refinement,
  resetPage: boolean,
  namespace: string
) {
  const page = resetPage ? { page: 1 } : undefined
  return {
    ...searchState,
    [namespace]: { ...searchState[namespace], ...nextRefinement },
    ...page
  }
}

function getNamespaceAndAttributeName(id: string) {
  // eslint-disable-next-line
  const parts = id.match(/^([^.]*)\.(.*)/)
  const namespace = parts && parts[1]
  const attributeName = parts && parts[2]

  return { namespace, attributeName }
}

function hasRefinements({
  multiIndex,
  indexId,
  namespace,
  attributeName,
  id,
  searchState
}: {
  multiIndex: IndexContext
  indexId: string
  namespace: string
  attributeName: string
  id: string
  searchState: InstantSearchState
}) {
  if (multiIndex && namespace) {
    return (
      searchState.indices &&
      searchState.indices[indexId] &&
      searchState.indices[indexId][namespace] &&
      Object.hasOwnProperty.call(
        searchState.indices[indexId][namespace],
        attributeName
      )
    )
  }

  if (multiIndex) {
    return (
      searchState.indices &&
      searchState.indices[indexId] &&
      Object.hasOwnProperty.call(searchState.indices[indexId], id)
    )
  }

  if (namespace) {
    return (
      searchState[namespace] &&
      Object.hasOwnProperty.call(searchState[namespace], attributeName)
    )
  }

  return Object.hasOwnProperty.call(searchState, id)
}

function getRefinements({
  multiIndex,
  indexId,
  namespace,
  attributeName,
  id,
  searchState
}: {
  multiIndex: IndexContext
  indexId: string
  namespace: string
  attributeName: string
  id: string
  searchState: InstantSearchState
}): string[] {
  if (multiIndex && namespace) {
    // @ts-ignore
    return searchState.indices[indexId][namespace][attributeName]
  }
  if (multiIndex) {
    // @ts-ignore
    return searchState.indices[indexId][id]
  }
  if (namespace) {
    return searchState[namespace][attributeName]
  }

  return searchState[id]
}

export function getCurrentRefinementValue(
  props: {
    defaultRefinement: string[]
  },
  searchState: InstantSearchState,
  context: InstantSearchIndexContext,
  id: string,
  defaultValue: string[]
) {
  const indexId = getIndexId(context)
  const { namespace, attributeName } = getNamespaceAndAttributeName(id)
  const multiIndex = hasMultipleIndices(context)
  const args = {
    multiIndex,
    indexId,
    namespace,
    attributeName,
    id,
    searchState
  }
  // @ts-ignore
  const hasRefinementsValue = hasRefinements(args)

  if (hasRefinementsValue) {
    // @ts-ignore
    return getRefinements(args)
  }

  if (props.defaultRefinement) {
    return props.defaultRefinement
  }

  return defaultValue
}

export function cleanUpValue(
  searchState: InstantSearchState,
  context: InstantSearchIndexContext,
  id: string
) {
  const indexId = getIndexId(context)
  const { namespace, attributeName } = getNamespaceAndAttributeName(id)

  if (hasMultipleIndices(context) && Boolean(searchState.indices)) {
    return cleanUpValueWithMultiIndex({
      attribute: attributeName ?? '',
      searchState,
      indexId,
      id,
      //@ts-ignore
      namespace
    })
  }

  return cleanUpValueWithSingleIndex({
    attribute: attributeName ?? '',
    searchState,
    id,
    //@ts-ignore
    namespace
  })
}

function cleanUpValueWithSingleIndex({
  searchState,
  id,
  namespace,
  attribute
}: Pick<ConnectedFields, 'searchState' | 'id' | 'namespace' | 'attribute'>) {
  if (namespace) {
    return {
      ...searchState,
      [namespace]: omit(searchState[namespace], [attribute])
    }
  }

  return omit(searchState, [id])
}

function cleanUpValueWithMultiIndex({
  searchState,
  indexId,
  id,
  namespace,
  attribute
}: Pick<
  ConnectedFields,
  'searchState' | 'indexId' | 'id' | 'namespace' | 'attribute'
>) {
  // @ts-ignore
  const indexSearchState = searchState.indices[indexId]

  if (namespace && indexSearchState) {
    return {
      ...searchState,
      indices: {
        ...searchState.indices,
        [indexId]: {
          ...indexSearchState,
          [namespace]: omit(indexSearchState[namespace], [attribute])
        }
      }
    }
  }

  if (indexSearchState) {
    return {
      ...searchState,
      indices: {
        ...searchState.indices,
        [indexId]: omit(indexSearchState, [id])
      }
    }
  }

  return searchState
}

// https://github.com/algolia/react-instantsearch/blob/master/packages/react-instantsearch-core/src/core/utils.ts
export function omit(source: { [key: string]: any }, excluded: string[]) {
  if (source === null || source === undefined) {
    return {}
  }
  const target = {}
  const sourceKeys = Object.keys(source)
  for (let i = 0; i < sourceKeys.length; i++) {
    const key = sourceKeys[i]
    if (excluded.indexOf(key) >= 0) {
      // eslint-disable-next-line no-continue
      continue
    }
    // @ts-ignore
    target[key] = source[key]
  }
  return target
}
