import { UrlObject } from 'url'

type UrlProp = string | UrlObject | undefined
type LocaleProp = string | undefined

const getQueryLocale = (
  locale: LocaleProp,
  defaultLocale: string | undefined
) => (locale && locale !== defaultLocale ? locale : 'en')

const getLocalisedPath = (
  baseUrl: string,
  asPath: string,
  locale: LocaleProp,
  defaultLocale: string | undefined
) => {
  const basePath =
    locale?.length && locale !== defaultLocale
      ? `${baseUrl}/${locale}`
      : `${baseUrl}/en`
  const fullPath = asPath === '/' ? basePath : `${basePath}${asPath}`

  return fullPath
}

const checkIfHrefIsExternal = (href: string) => {
  if (!href?.length) return false

  return (
    href.search(/^tel:/g) > -1 ||
    href.search(/^mailto:/g) > -1 ||
    href.search(/http/g) > -1
  )
}

const isHrefExternal = (href: UrlProp) => {
  let isExternal = false

  if (typeof href === 'string') {
    isExternal = checkIfHrefIsExternal(href)
  } else if (href?.pathname) {
    isExternal = checkIfHrefIsExternal(href.pathname)
  }

  return isExternal
}

/**
 * If we're in the Default or En locales we want ensure our links are prefixed with
 *  /en so that the middleware doesn't have to do a redirect
 */
const getHrefForLocale = (
  href: UrlProp,
  locale: LocaleProp,
  locales: string[] | undefined,
  defaultLocale: string | undefined
) => {
  // check if the href starts with # or ?, ends in /en or includes /en/
  const regex = new RegExp(/^#|^\?|^\/en($|\/)/g)

  const isExternal = isHrefExternal(href)

  let url = href
  if (
    !isExternal &&
    (locale === defaultLocale || locale === 'en' || !locale) &&
    locales &&
    locales?.length > 1
  ) {
    if (typeof href === 'string') {
      url = href.match(regex) ? href : `/en${href}`
    } else if (href?.pathname) {
      url = {
        ...href,
        pathname: href.pathname.match(regex)
          ? href.pathname
          : `/en${href.pathname}`
      }
    }
  }

  return url
}

export { getQueryLocale, getHrefForLocale, isHrefExternal, getLocalisedPath }
