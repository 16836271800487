import { Box, Flex, Stack, Text, VStack } from '@chakra-ui/react'
import { GetEnquiryFormFieldsQuery, Swnz_FormDropdownOption } from 'content-service'
import { Formik } from 'formik'
import { useRouter } from 'next/router'
import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import type { Utag } from 'tracking'
import {
  Button,
  CtaButton,
  enquiryFormSchema,
  EnquiryFormType,
  EnquiryFormValues,
  Form,
  FormAgreements,
  FORM_CONSTANTS,
  H3,
  H5,
  MediumBoldText,
  reformatDateForSubmit,
  StackField,
  useEnquiryFormSubmission,
  useHandleModalCloseResetData,
} from 'ui'
import { mapInitialEnquiryFormValues } from 'ui/src/forms/helpers/profileForm'
import { formTaxonomyData } from 'utils/src/helpers/taxonomy'
import { ROUTES } from '../../../constants'
import { useUser } from '../../../context'
import useGetStudyOptions from '../../../hooks/useGetStudyOptions'
import { useTranslation } from '../../../hooks/useTranslation'

declare const utag: Utag

type CourseEnquiryFormProps = {
  formId: string
  courseName?: string
  providerName?: string
  providerSubtype?: string[]
  deliveryMode?: string
  heading?: string
  bodyText?: string
  contentful: GetEnquiryFormFieldsQuery
  successMessage?: {
    heading: string
    bodyText: string
  }
  errorMessage?: {
    heading: string
    bodyText: string
  }
  onClose: () => void
  onSuccess?: () => void
}

const CourseEnquiryForm = ({
  formId,
  heading,
  bodyText,
  providerName,
  providerSubtype,
  courseName,
  contentful,
  deliveryMode,
  successMessage,
  errorMessage,
  onClose,
  onSuccess,
}: CourseEnquiryFormProps) => {
  const { t, lang } = useTranslation('forms')
  const handleGetStudyOptions = useGetStudyOptions()
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [successfulSubmission, setSuccessfulSubmission] = useState(false)
  const [submissionError, setSubmissionError] = useState(false)

  const {
    userState: { profile },
  } = useUser()

  const postEnquiryFormSubmissionToAPIEndpoint = useEnquiryFormSubmission()
  const handleModalCloseResetData = useHandleModalCloseResetData()
  const router = useRouter()
  const showErrorState = router.query.submissionError

  const initialValues = mapInitialEnquiryFormValues(profile, t)

  const taxonomy = formTaxonomyData()

  // [FIX]:
  //       * TS ERROR: figure nesting type issue for contentful query object(s)
  const {
    languageField: {
      // @ts-ignore
      items: [
        {
          dropdownOptionsCollection: { items: languageOptions },
        },
      ],
    },
    agentUseField: {
      // @ts-ignore
      items: [
        {
          dropdownOptionsCollection: { items: agentUseOptions },
        },
      ],
    },
    levelOfEnglishField: {
      // @ts-ignore
      items: [
        {
          dropdownOptionsCollection: { items: levelOfEnglishOptions },
        },
      ],
    },
    // @ts-ignore
    moreInformationFieldOnCourses: {
      // @ts-ignore
      items: [
        {
          dropdownOptionsCollection: { items: moreInformationOptions },
        },
      ],
    },
  } = contentful

  const handleFormSubmit = (values: EnquiryFormValues) => {
    const nationalityIso =
      taxonomy.location.find((country) => country.key == values.nationality)?.iso ?? ''

    const enquiryQuestionId = moreInformationOptions.find(
      (n: Swnz_FormDropdownOption) => n.label === values.sourceInformation
    ).value!

    const dateOfBirth = reformatDateForSubmit(values.dateOfBirth)
    const preferredStartDate = values.prefStartDate
      ? reformatDateForSubmit(values.prefStartDate)
      : ''

    let tealiumCookie = ''
    const utagData = window?.utag?.data
    if (utagData && utagData?.tealium_visitor_id?.length > 0) {
      tealiumCookie = utagData.tealium_visitor_id
    }

    postEnquiryFormSubmissionToAPIEndpoint(
      profile,
      {
        ...values,
        dateOfBirth: dateOfBirth,
        prefStartDate: preferredStartDate,
        authId: profile?.authId ?? '',
        itemId: formId,
        itemType: `${EnquiryFormType.Course}`,
        sourceInformation: values.sourceInformationOther || values.sourceInformation,
        enquiryQuestionId,
        nationalityIso,
        tealiumVisitorId: tealiumCookie,
        lang,
      },
      {
        success: () => {
          // used for testing error responses
          if (showErrorState) {
            setSubmissionError(true), setSuccessfulSubmission(false)
          } else {
            setSuccessfulSubmission(true)
            onSuccess?.()
            if (utagData && Object.keys(utagData).length > 0) {
              utag.link({
                tealium_event: 'form_submission',
                form_type: 'finder_enquiry',
                form_name: 'contact_provider',
                enquiry_id: 'provider_enquiries',
                enquiry_group: deliveryMode ? `${deliveryMode}` : '',
                enquiry_name: `${providerName}`,
                enquiry_type: `${EnquiryFormType.Course}`,
                level_of_study:
                  `${values.levelOfStudy}` || `${profile?.studyInterests?.levelOfStudy}` || '',
                nationality:
                  `${values.nationality}` || `${profile?.demographics?.nationality}` || '',
                course_name: courseName,
                provider_name: providerName,
                provider_subtype: providerSubtype,
              })
            }
          }
        },
        error: () => setSubmissionError(true),
      }
    )
  }

  return (
    <>
      {!successfulSubmission && !submissionError && (
        <>
          {(heading || providerName || courseName || bodyText) && (
            <>
              <VStack alignItems="flex-start">
                {heading && <H3 mb={0}>{heading}</H3>}
                {(providerName || courseName) && (
                  <Form.LeadText>
                    {providerName && (
                      <>
                        {`Send an enquiry to `}
                        <MediumBoldText as="strong" mb={0} display="inline">
                          {providerName}
                        </MediumBoldText>
                        {`.`}
                      </>
                    )}
                    <br />
                    {courseName && (
                      <>
                        {`Enquiring about, `}
                        <MediumBoldText as="strong" mb={0} display="inline">
                          {courseName}
                        </MediumBoldText>
                        {`.`}
                      </>
                    )}
                  </Form.LeadText>
                )}

                {bodyText && <Text>{bodyText}</Text>}
              </VStack>

              <Form.Divider mb={6} />
            </>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={enquiryFormSchema(lang)}
            onSubmit={handleFormSubmit}
          >
            {({ dirty, isValid, isSubmitting, values }) => {
              return (
                <Form>
                  <H5>{`Your Details:`}</H5>
                  <Stack spacing={4} mb={8} alignItems="flex-start" dir="ltr">
                    <StackField>
                      <Form.FirstNameField />
                      <Form.LastNameField />
                    </StackField>
                    <Form.EmailAddressField isDisabled={Boolean(values.email)} />
                    <StackField>
                      <Form.PhoneNumberField country={t('mobile_number.country')} />
                      <Form.DateOfBirthField initialValue={initialValues.dateOfBirth} isRequired />
                    </StackField>
                    <StackField>
                      <Form.PersonTypeField
                        personTypeOptions={taxonomy.userType.filter(
                          (el) => !['enz', 'education-provider', 'other'].includes(el.key)
                        )}
                        isRequired
                      />
                    </StackField>
                    <StackField>
                      <Form.NationalityField
                        nationalityOptions={taxonomy.location}
                        isRequired={lang !== 'ko'}
                      />
                      <Form.LanguageField languageOptions={languageOptions} />
                    </StackField>
                    <StackField>
                      <Form.LevelOfStudyField
                        levelOfStudyOptions={taxonomy.levelOfStudy}
                        isRequired
                      />
                      <Form.AreaOfStudyField areaOfStudyOptions={taxonomy.areaOfStudy} isRequired />
                    </StackField>
                    <Form.Divider mb={4} />
                    <H5 mb={6}>{`Questions about this course:`}</H5>
                    <Form.StartDateControl
                      prefStartDate={initialValues?.prefStartDate?.toString()}
                    />
                    <Form.AgentUseField agentUseOptions={agentUseOptions} />
                    <Form.LevelOfEnglishField levelOfEnglishOptions={levelOfEnglishOptions} />
                    <Form.MoreInformationField moreInformationOptions={moreInformationOptions} />
                    <Box visibility="hidden">
                      {/* @ts-ignore https://github.com/dozoisch/react-google-recaptcha/issues/277 */}
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY ?? ''}
                      />
                    </Box>
                    <Button
                      colorScheme="teal"
                      type="submit"
                      isDisabled={!(dirty && isValid) || isSubmitting}
                    >
                      Send enquiry
                    </Button>
                    <FormAgreements mt={6} />
                  </Stack>
                </Form>
              )
            }}
          </Formik>
        </>
      )}
      {successfulSubmission && successMessage && (
        <Box px={4}>
          <H3>{successMessage.heading}</H3>
          <Text mb={3}>
            Thanks, {profile?.firstName}! {successMessage.bodyText}
          </Text>
          <Flex mt={7} flexDir={{ base: 'column', md: 'row' }} py={2}>
            <CtaButton
              mr={{ base: 0, md: 2 }}
              mb={{ base: 3, md: 0 }}
              onClick={() => {
                handleModalCloseResetData(onClose)
                handleGetStudyOptions()
              }}
            >
              {FORM_CONSTANTS.ENQUIRY.SUCCESS_MESSAGE.closeButtonLabel}
            </CtaButton>
            <CtaButton
              variant="outline"
              onClick={() => {
                handleModalCloseResetData(onClose, ROUTES.ENQUIRIES)
                handleGetStudyOptions()
              }}
            >
              {FORM_CONSTANTS.ENQUIRY.SUCCESS_MESSAGE.viewButtonLabel}
            </CtaButton>
          </Flex>
        </Box>
      )}
      {submissionError && errorMessage && (
        <>
          <Form.ErrorContent />
          <Flex mt={12} flexDir={{ base: 'column', md: 'row' }}>
            <CtaButton
              mr={{ base: 0, md: 2 }}
              mb={{ base: 3, md: 0 }}
              onClick={() => handleModalCloseResetData(onClose)}
              bg="deepblue.500"
              borderColor="deepblue.500"
            >
              {FORM_CONSTANTS.ENQUIRY.SUCCESS_MESSAGE.closeButtonLabel}
            </CtaButton>
          </Flex>
        </>
      )}
    </>
  )
}
export default CourseEnquiryForm
export type { CourseEnquiryFormProps }
