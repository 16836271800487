import { AutocompletePlugin } from '@algolia/autocomplete-js'
import { ROUTES } from 'swnz/src/constants'
import { searchStateToURL } from 'utils/src/helpers/algoliaSearch'
import Highlight from '../../Highlight'
import { pluginOnSelect } from '../helpers'
import { addItemType } from '../helpers/addItemType'
import { buildState } from '../helpers/buildState'
import { SearchAutocompleteContext } from '../SearchAutocomplete'
import { BaseResult, SearchType } from '../types'

export function popularAreasOfStudyPlugin(
  popularAreasOfStudyArray: BaseResult[]
): AutocompletePlugin<BaseResult, BaseResult> {
  return {
    getSources({ query }) {
      if (query) {
        return []
      }

      return [
        {
          sourceId: 'default',
          getItems() {
            return addItemType({
              type: SearchType.FACET,
              items: popularAreasOfStudyArray
            })
          },
          getItemUrl({ item, state }) {
            const { instantSearchState } =
              state.context as SearchAutocompleteContext
            const url = searchStateToURL(
              ROUTES.COURSE.RESULTS,
              buildState({
                searchState: instantSearchState ?? {},
                facet: 'areasOfStudy',
                facetValue: item.label
              })
            )
            return url
          },
          getItemInputValue({ item }) {
            return item?.label ?? ''
          },
          onSelect(params) {
            pluginOnSelect(params)
          },
          templates: {
            item({ item }) {
              return <Highlight attribute='label' hit={item} />
            }
          }
        }
      ]
    }
  }
}
