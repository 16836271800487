import { Box, Fade, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { HomepageHero_Swnz_PageHomepageFragment } from 'content-service'
import { useMotionValue, useScroll, useSpring, useTransform } from 'framer-motion'
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'
import { ContainerWrapper, GridLayout, OptimisedImage, RichTextRenderer } from 'ui'
import { IMAGE_SIZE_CONFIG } from 'utils'
import { HomepageHeroTabs } from '../../components/Homepage'

export interface TabsProps {
  homepageHero:
    | Pick<HomepageHero_Swnz_PageHomepageFragment, 'heroTabsCollection'>
    | null
    | undefined
}

const FRAMER_MOTION_CONSTANTS = Object.freeze({
  INPUTS: {
    TABS: {
      YPOS_STORY_TITLE: [1700, 2000],
      YPOS_IMAGE_ONE: [1400, 2000],
      YPOS_MISSION_INTRODUCTION: [1300, 2000],
    },
  },
  OUTPUTS: {
    TABS: {
      YPOS_STORY_TITLE: [0, -100],
      YPOS_IMAGE_ONE: [0, -100],
      YPOS_MISSION_INTRODUCTION: [0, -100],
    },
  },
})

export const HomepageHeroTabsConnect = ({ homepageHero }: TabsProps): ReactElement | null => {
  const [tabIndex, setTabIndex] = useState(0)
  const { scrollY } = useScroll()
  const tabPanelsRef = useRef<HTMLDivElement | null>(null)
  const scrollProgress = useMotionValue(0)

  function createRandomMassValue(min = 0, max = 100) {
    return Math.floor(Math.random() * (+max - +min)) + +min
  }

  const springConfig = { damping: 100, stiffness: 100, mass: createRandomMassValue() }

  const missionAndIntroductionYPos = useSpring(
    useTransform(
      scrollProgress,
      FRAMER_MOTION_CONSTANTS.INPUTS.TABS.YPOS_MISSION_INTRODUCTION,
      FRAMER_MOTION_CONSTANTS.OUTPUTS.TABS.YPOS_MISSION_INTRODUCTION
    ),
    springConfig
  )

  const heroTabImageOneYPos = useSpring(
    useTransform(
      scrollProgress,
      FRAMER_MOTION_CONSTANTS.INPUTS.TABS.YPOS_IMAGE_ONE,
      FRAMER_MOTION_CONSTANTS.OUTPUTS.TABS.YPOS_IMAGE_ONE
    ),
    springConfig
  )

  const storyTitleYPos = useSpring(
    useTransform(
      scrollProgress,
      FRAMER_MOTION_CONSTANTS.INPUTS.TABS.YPOS_STORY_TITLE,
      FRAMER_MOTION_CONSTANTS.OUTPUTS.TABS.YPOS_STORY_TITLE
    ),
    springConfig
  )

  /**
   * Intersection observer updates scrollProgress value when this component
   * is scrolled into view.
   */
  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          scrollY.onChange((progress) => scrollProgress.set(progress))
        }
      })
    },
    [scrollY, scrollProgress]
  )

  useEffect(() => {
    /**
     * https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
     */
    const options: IntersectionObserverInit = { root: null, rootMargin: '0px', threshold: 0.5 }
    const observer: IntersectionObserver = new IntersectionObserver(handleObserver, options)
    const tabPanelsElement = tabPanelsRef.current

    if (tabPanelsElement) {
      observer.observe(tabPanelsElement)
    }

    return () => {
      scrollY.stop()
      scrollProgress.destroy()
      tabPanelsElement && observer.unobserve(tabPanelsElement)
    }
  }, [tabPanelsRef, handleObserver, scrollY, scrollProgress])

  if (!homepageHero) {
    return null
  }

  return (
    <Box position="relative">
      <Tabs
        id="tab_main"
        isFitted
        position="relative"
        bgColor="deepblue.500"
        pb={{ base: 0, lg: 24 }}
        onChange={(index) => setTabIndex(index)}
      >
        <TabList id="tab_list">
          {homepageHero.heroTabsCollection?.items.map((tab: any, index: number) => (
            <HomepageHeroTabs.TabHeader key={index} tab={tab} />
          ))}
        </TabList>
        <TabPanels ref={tabPanelsRef}>
          {homepageHero.heroTabsCollection?.items.map((tab: any, index: number) => {
            const backgroundImage = OptimisedImage({
              alt: tab?.backgroundImage?.source?.description || '',
              src: tab?.backgroundImage?.source?.url || '',
              priority: index === 0, // only preload the first tab backgroundImage
              imageSizeConfig: IMAGE_SIZE_CONFIG.HOMEPAGE_HERO_TABS.BACKGROUND_IMAGE,
              blurOnLoad: false,
            })
            const forgroundImage = OptimisedImage({
              alt: tab?.foregroundImage?.source?.description || '',
              src: tab?.foregroundImage?.source?.url || '',
              priority: index === 0, // only preload the first tab foregroundImage
              imageSizeConfig: IMAGE_SIZE_CONFIG.HOMEPAGE_HERO_TABS.FOREGROUND_IMAGE,
              blurOnLoad: false,
            })
            const gridImageOne = OptimisedImage({
              alt: tab?.storyImageOne?.source?.description || '',
              src: tab?.storyImageOne?.source?.url || '',
              imageSizeConfig: IMAGE_SIZE_CONFIG.HOMEPAGE_HERO_TABS.STORY_IMAGE_ONE,
              blurOnLoad: false,
            })
            const gridImageTwo = OptimisedImage({
              alt: tab?.storyImageTwo?.source?.description || '',
              src: tab?.storyImageTwo?.source?.url || '',
              imageSizeConfig: IMAGE_SIZE_CONFIG.HOMEPAGE_HERO_TABS.STORY_IMAGE_TWO,
              blurOnLoad: false,
            })

            return (
              <TabPanel key={index} padding={0} backgroundColor="deepblue.500" color="white">
                <Fade in={index === tabIndex}>
                  <HomepageHeroTabs.TabContent
                    forgroundImage={forgroundImage}
                    backgroundImage={backgroundImage}
                  />

                  <Box id="hero_story_grid" zIndex={3}>
                    <ContainerWrapper sx={{ py: 0 }}>
                      <GridLayout
                        templateColumns={{
                          base: 'repeat(4, 1fr)',
                          // override the medium size as tablet breakpoint is nasty
                          md: 'repeat(4, 1fr)',
                          lg: 'repeat(12, 1fr)',
                        }}
                        rowGap={{ base: 16, md: 6 }}
                        zIndex={2}
                        pos="relative"
                        m="unset"
                      >
                        {/* Parallax elements 👇 */}
                        {/* Mission and Introduction */}
                        <HomepageHeroTabs.Mission y={missionAndIntroductionYPos} tab={tab} />
                        <HomepageHeroTabs.Introduction y={missionAndIntroductionYPos}>
                          <RichTextRenderer richText={tab?.storySummaryOne} />
                          <HomepageHeroTabs.StoryLearnMoreButton
                            modalId={tab?.storyLearnMoreOne?.content?.sys?.id}
                          />
                        </HomepageHeroTabs.Introduction>

                        {/* Image One */}
                        <HomepageHeroTabs.HeroTabGridImageOne
                          y={heroTabImageOneYPos}
                          gridImageOne={gridImageOne}
                        />
                        <HomepageHeroTabs.HeroTabGridImageTwo gridImageTwo={gridImageTwo} />

                        {/* Story title  */}
                        <HomepageHeroTabs.StoryTitle
                          y={storyTitleYPos}
                          storyTitle={tab?.storyTitle}
                        >
                          <RichTextRenderer richText={tab?.storySummaryTwo} />
                          <HomepageHeroTabs.StoryLearnMoreButton
                            modalId={tab?.storyLearnMoreTwo?.content?.sys?.id}
                          />
                        </HomepageHeroTabs.StoryTitle>
                        {/* Parallax elements 👆 */}
                      </GridLayout>
                    </ContainerWrapper>
                  </Box>
                </Fade>
              </TabPanel>
            )
          })}
        </TabPanels>
      </Tabs>
    </Box>
  )
}
