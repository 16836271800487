import { OpenGraph } from 'next-seo/lib/types'

interface CreateOpenGraphConfig {
  title: string
  url: string
  description: string
}

const createOpenGraphConfig = ({
  title,
  url,
  description
}: CreateOpenGraphConfig): OpenGraph => {
  return {
    type: 'website',
    url,
    title,
    description,
    images: [
      {
        url: '/images/think-nz-og-image.png',
        width: 600,
        height: 600,
        alt: 'Study with New Zealand'
      }
    ]
  }
}

export { createOpenGraphConfig }
