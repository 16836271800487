import { Box, useDisclosure } from '@chakra-ui/react'
import { PlayIcon } from 'icons'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { Button, CallToAction, CtaButton } from 'ui'
import { colourMap, ZINDEX } from 'utils'
import { VideoModal } from '../../modal'
import { Headline, MediumBoldText } from '../../typography'
import HeroFeaturedFloatingCard, {
  HeroFeaturedFloatingCardProps
} from './HeroFeaturedFloatingCard'

type Video = {
  url: string
  buttonLabel: string
}

interface HeroFeaturedProps {
  heading: string
  subHeading: string
  introText?: string
  primaryCta?: CallToAction
  secondaryCta?: CallToAction
  video?: Video
  image: ReactElement | null
  heroFloatingCard?: HeroFeaturedFloatingCardProps | null
  leadCaptureFormId?: string
  isFocusedOnFaces?: boolean
  textThemeColour?: string | null
}

const HeroFeatured = ({
  heading,
  subHeading,
  introText,
  primaryCta,
  secondaryCta,
  video,
  image,
  heroFloatingCard,
  leadCaptureFormId,
  textThemeColour
}: HeroFeaturedProps): ReactElement => {
  const headingRef = useRef<HTMLDivElement>(null)
  const [headingHeight, setHeadingHeight] = useState<number>(0)

  useEffect(() => {
    const handleResize = () => {
      setHeadingHeight(headingRef?.current?.clientHeight ?? 0)
    }

    if (typeof window !== 'undefined') {
      handleResize()
      window.addEventListener('resize', handleResize)
    }

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const {
    isOpen: isVideoModalOpen,
    onOpen: openVideoModal,
    onClose: closeVideoModal
  } = useDisclosure()

  const [videoModalUrl, setVideoModalUrl] = useState<string>(
    video?.url || heroFloatingCard?.video?.url || ''
  )

  const playVideoInModal = (url?: string) => {
    if (!url) return

    setVideoModalUrl(url)
    openVideoModal()
  }

  const textColour = colourMap(textThemeColour ?? 'deepblue.500')

  return (
    <>
      <Box position='relative'>
        <Box
          position={{ base: 'relative', md: 'absolute' }}
          w='100%'
          h={{ base: '352px', md: '753px' }}
        >
          {image}
        </Box>
        <Box
          display='flex'
          flexFlow='wrap'
          minH={{ md: '753px' }}
          height='100%'
          px={{ base: 6, md: 0 }}
          pb={!heroFloatingCard ? { base: '30px', md: 0 } : 0}
          transform={{
            base: `translateY(-${headingHeight / 2}px)`,
            md: 'unset'
          }}
          marginBottom={{ base: `-${headingHeight / 2}px`, md: 'unset' }}
          maxW='container.xl'
          w='100%'
          margin='0 auto'
        >
          <Box
            flexGrow={1}
            maxW={{
              md: heroFloatingCard
                ? 'calc(100% - 4rem - 260px - 16px)' // 100% - container padding - heroFloatingCard width - gap
                : 'unset',
              lg: 'unset'
            }}
            w={{ md: heroFloatingCard ? '100%' : 'auto', lg: 'auto' }}
          >
            <Box
              position={{ base: 'relative', md: 'unset' }}
              maxW='614px'
              w='100%'
              h='100%'
              pt={{ base: 0, md: 100 }}
              overflow='visible'
              paddingInlineStart={{ md: 16 }}
            >
              <Box
                position='absolute'
                zIndex={1}
                left={{ base: '-25%', md: 0 }}
                top={{ base: '-50%', md: 0 }}
                bottom={{ md: 0 }}
                width={{ base: '516px', md: '100%' }}
                height={{ base: '516px', md: '100%' }}
                transform={{ base: 'translateX(-25%)', md: 'unset' }}
                borderRadius={{ base: '50%', md: 'none' }}
                sx={{
                  background: {
                    base: `radial-gradient(50% 50% at 50% 50%, ${
                      textColour === 'white' ? ' rgba(0, 0, 0, 0.7)' : '#EDF8F8'
                    } 0%, rgba(237, 248, 248, 0) 100%)`,
                    md: `linear-gradient(90deg, rgba(${
                      textColour === 'white' ? '0, 0, 0' : '253, 245, 242'
                    }, 0.9) 0%, rgba(${
                      textColour === 'white' ? '0, 0, 0' : '253, 245, 242'
                    }, 0) 66%)`
                  },
                  'html[lang=ar] &': {
                    background: {
                      md: `linear-gradient(-90deg, rgba(${
                        textColour === 'white' ? '0, 0, 0' : '253, 245, 242'
                      }, 0.9) 0%, rgba(${
                        textColour === 'white' ? '0, 0, 0' : '253, 245, 242'
                      }, 0) 66%)`
                    }
                  }
                }}
              />
              <Box
                pos='relative'
                zIndex={3}
                maxW='496px'
                w='100%'
                color={textColour}
              >
                <Box ref={headingRef}>
                  <Headline
                    as='h1'
                    marginBottom={0}
                    fontSize={{ base: '60px', md: '90px' }}
                    letterSpacing={{ base: '-1px', md: '-2px' }}
                  >
                    {heading}
                  </Headline>
                </Box>
                <MediumBoldText
                  fontSize={{ base: '24px', md: '36px' }}
                  lineHeight={1.3}
                  marginTop={4}
                  marginBottom={
                    heroFloatingCard ||
                    introText ||
                    primaryCta?.label ||
                    secondaryCta?.label ||
                    (video && video?.url)
                      ? 6
                      : 0
                  }
                >
                  {subHeading}
                </MediumBoldText>
                {introText && (
                  <MediumBoldText
                    fontSize={{ base: '20px', md: '22px' }}
                    lineHeight={{ base: '28px', md: '32px' }}
                    letterSpacing='-1px'
                    marginBottom={
                      heroFloatingCard ||
                      primaryCta?.label ||
                      secondaryCta?.label ||
                      (video && video?.url)
                        ? 6
                        : 0
                    }
                  >
                    {introText}
                  </MediumBoldText>
                )}
                <Box
                  display='flex'
                  flexFlow='wrap'
                  sx={{
                    '> *:not(:last-child)': {
                      marginBottom: { base: 4, md: 6 }
                    }
                  }}
                >
                  {primaryCta?.label && (
                    <CtaButton
                      width={{ base: '100%', md: 'auto' }}
                      marginRight={{ base: 0, md: 4 }}
                      colorScheme='teal'
                      href={primaryCta.href}
                      modalId={primaryCta.modalId}
                      leadCaptureFormId={leadCaptureFormId}
                    >
                      {primaryCta.label}
                    </CtaButton>
                  )}
                  {secondaryCta?.label && (
                    <CtaButton
                      width={{ base: '100%', md: 'auto' }}
                      marginRight={{ base: 0, md: 4 }}
                      color={textColour}
                      borderColor={textColour}
                      colorScheme={''}
                      href={secondaryCta.href}
                      modalId={secondaryCta.modalId}
                      leadCaptureFormId={leadCaptureFormId}
                      {...(textColour === 'deepblue.500' && {
                        _hover: {
                          backgroundColor: 'deepblue.500',
                          color: 'white'
                        }
                      })}
                    >
                      {secondaryCta.label}
                    </CtaButton>
                  )}
                  {video && video?.url && (
                    <Button
                      className='play-icon-container'
                      width={{ base: '100%', md: 'auto' }}
                      borderColor={textColour}
                      color={textColour}
                      colorScheme={''}
                      onClick={() => playVideoInModal(video.url)}
                      openInNewWindow={false}
                      sx={{
                        transition: '0.25s ease-in-out',
                        '> .chakra-button__icon': {
                          transition: '0.25s ease-in-out',
                          borderRadius: '50%',
                          marginLeft: '16px',
                          '> .play-icon': {
                            transition: '0.25s ease-in-out',
                            borderRadius: '50%',
                            '.outline': {
                              transition: '0.25s ease-in-out'
                            },
                            '.triangle': {
                              transition: '0.25s ease-in-out'
                            }
                          }
                        }
                      }}
                      {...(textColour === 'deepblue.500' && {
                        _hover: {
                          backgroundColor: 'deepblue.500',
                          color: 'white',
                          '> .chakra-button__icon': {
                            background: 'deepblue.500',
                            '> .play-icon': {
                              borderColor: 'white',
                              '.outline': {
                                fill: 'white'
                              },
                              '.triangle': {
                                fill: 'white'
                              }
                            }
                          }
                        }
                      })}
                      rightIcon={
                        <Box
                          className='play-icon'
                          boxSize={{ base: '24px', md: '24px' }}
                          fill={textColour}
                          overflow='hidden'
                        >
                          <PlayIcon />
                        </Box>
                      }
                    >
                      Watch video
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
          {heroFloatingCard && (
            <Box
              zIndex={ZINDEX.HERO_FEATURED_CARD}
              display='flex'
              alignItems='flex-end'
              justifyContent={{ base: 'center', md: 'flex-end' }}
              w={{ base: '100%', md: 'auto' }}
              paddingInlineEnd={{ md: 16 }}
              pb={{ base: '30px', md: '60px' }}
              flexGrow={1}
            >
              <HeroFeaturedFloatingCard
                {...heroFloatingCard}
                onClick={() => playVideoInModal(heroFloatingCard?.video?.url)}
              />
            </Box>
          )}
        </Box>
      </Box>
      {videoModalUrl ? (
        <VideoModal
          url={videoModalUrl}
          isOpen={isVideoModalOpen}
          onClose={closeVideoModal}
        />
      ) : null}
    </>
  )
}

export type { HeroFeaturedProps }
export default HeroFeatured
