import { Box, HStack, Link, Text, VStack } from '@chakra-ui/react'
import { ContactIcon, NZMapIcon } from 'icons'
import { PropsWithChildren, ReactElement, ReactNode } from 'react'
import { createUtagProviderReferralEvent } from 'tracking'
import { CtaButton, CtaButtonProps } from '../../buttons'
import { BoldText, H4 } from '../../typography'
interface FinderDetailBannerProps {
  type: 'contact' | 'info'
  title: string
  children: ReactElement | ReactElement[]
}

interface FinderDetailBannerContactProps {
  name: string
  city?: string
  address?: string
  mapUrl?: string
  phone?: string
  campuses?: string
  siteUrl?: string
  type: string
  subtypeList: string[]
}

interface FinderDetailBannerCtaProps {
  type: 'primary' | 'secondary'
  href: string
  label: string
  openInNewWindow: boolean
  isExternalLink?: boolean | undefined
  modalId?: string
  leadCaptureFormId?: string
}

const GOOGLE_MAPS_URL = 'https://www.google.com/maps/search/'
const GOOGLE_MAPS_QUERY_PARAM = '?api=1&query='

function FinderDetailBanner({
  title,
  type,
  children
}: FinderDetailBannerProps) {
  return (
    <Box
      bgColor={type === 'contact' ? 'teal.100' : 'orange.50'}
      borderRadius='20px'
      px={{ base: 5, lg: 8 }}
      pt={8}
      pb={12}
      mb={6}
    >
      <HStack justifyContent='space-between' mb={{ base: 4, lg: 7 }}>
        <H4
          color='deepWaterBlue'
          fontSize={{ base: '22px', lg: '26px' }}
          mb={0}
        >
          {title}
        </H4>

        {type === 'contact' ? (
          <Box boxSize={{ base: '50px', lg: '60px' }} color='surfaceBlue'>
            <ContactIcon />
          </Box>
        ) : (
          <Box boxSize={{ base: '50px', lg: '60px' }} color='orange.500'>
            <NZMapIcon />
          </Box>
        )}
      </HStack>
      <Box>{children}</Box>
    </Box>
  )
}

FinderDetailBanner.Contact = function FinderDetailBannerContact({
  name,
  city,
  address,
  phone,
  campuses,
  siteUrl,
  type,
  subtypeList
}: PropsWithChildren<FinderDetailBannerContactProps>): ReactElement {
  const isNZSchool = type === 'nz-school'
  return (
    <Box>
      <VStack alignItems='baseline' spacing={4}>
        {address && (
          <VStack alignItems='baseline' spacing={{ base: 0, lg: 1 }}>
            <BoldText mb={0}>Address</BoldText>
            <Box as='address' fontStyle='normal'>
              {name}
              <br />
              {city}
              <br />
              {address}
            </Box>
            <Link
              href={encodeURI(
                `${GOOGLE_MAPS_URL}${GOOGLE_MAPS_QUERY_PARAM}${address}`
              )}
              target='_blank'
            >
              View on Google Maps
            </Link>
          </VStack>
        )}
        {phone && (
          <VStack alignItems='baseline' spacing={{ base: 0, lg: 1 }}>
            <BoldText mb={0}>Phone number</BoldText>
            <Link href={`tel:${phone}`}>{phone}</Link>
          </VStack>
        )}
        {campuses && !isNZSchool && (
          <VStack alignItems='baseline' spacing={{ base: 0, lg: 1 }}>
            <BoldText mb={0}>Campuses</BoldText>
            <Text>{campuses}</Text>
          </VStack>
        )}
      </VStack>
      {siteUrl && (
        <Box mt={4}>
          <CtaButton
            variant='link'
            whiteSpace='normal'
            display='flex'
            justifyContent='flex-start'
            href={siteUrl}
            onClick={() =>
              createUtagProviderReferralEvent({
                linkLabel: 'View education provider website',
                providerName: name,
                providerType: type,
                providerSubtype: subtypeList,
                destinationUrl: siteUrl
              })
            }
            isExternalLink
            openInNewWindow
          >
            {isNZSchool
              ? 'View school website'
              : 'View education provider website'}
          </CtaButton>
        </Box>
      )}
    </Box>
  )
}

FinderDetailBanner.CtaButtonContainer =
  function FinderDetailBannerCtaButtonContainer({
    children
  }: {
    children: ReactNode
  }): ReactElement | null {
    const spacingTop = { base: 8, lg: 10 }

    return (
      <VStack
        spacing={4}
        borderTop='1px solid'
        borderColor='deepblue.200'
        marginTop={spacingTop}
        paddingTop={spacingTop}
      >
        {children}
      </VStack>
    )
  }

FinderDetailBanner.CtaButton = function FinderDetailBannerCtaButton({
  type,
  href,
  label,
  openInNewWindow = false,
  isExternalLink,
  modalId,
  leadCaptureFormId
}: PropsWithChildren<FinderDetailBannerCtaProps>): ReactElement | null {
  const secondaryStyleProps: CtaButtonProps = {
    backgroundColor: 'white',
    variant: 'monochrome',
    color: 'deepblue.500'
  }

  return (
    <CtaButton
      href={href}
      aria-label={label}
      openInNewWindow={openInNewWindow}
      isExternalLink={isExternalLink}
      colorScheme={type === 'primary' ? 'orange' : 'deepblue'}
      {...(type === 'secondary' && secondaryStyleProps)}
      width='full'
      modalId={modalId}
      leadCaptureFormId={leadCaptureFormId}
    >
      {label}
    </CtaButton>
  )
}

export default FinderDetailBanner
export type {
  FinderDetailBannerContactProps,
  FinderDetailBannerProps,
  FinderDetailBannerCtaProps
}
