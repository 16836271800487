const fontList = [
  {
    fileName: '/fonts/A23D_Solid-RegularWEB.woff',
    type: 'font/woff',
    prefetch: true
  },
  {
    fileName: '/fonts/NotoSans-Regular.woff2',
    type: 'woff2',
    prefetch: false
  },
  {
    fileName: '/fonts/untitled-sans-web-black.woff',
    type: 'woff',
    prefetch: false
  },
  {
    fileName: '/fonts/untitled-sans-web-bold.woff',
    type: 'woff',
    prefetch: true
  },
  {
    fileName: '/fonts/untitled-sans-web-medium.woff',
    type: 'woff',
    prefetch: false
  },
  {
    fileName: '/fonts/untitled-sans-web-regular.woff',
    type: 'woff',
    prefetch: true
  }
]

const fontFace = `
    @font-face {
      font-family: 'A23D Solid';
      font-style: normal;
      font-weight: normal;
      font-display: swap;
      src: url(/fonts/A23D_Solid-RegularWEB.woff) format('woff');
      unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    @font-face {
      font-family: 'Untitled Sans';
      font-style: normal;
      font-weight: normal;
      font-display: swap;
      src: url(/fonts/untitled-sans-web-regular.woff) format('woff');
    }
    @font-face {
      font-family: 'Untitled Sans Medium';
      font-style: normal;
      font-weight: normal;
      font-display: swap;
      src: url(/fonts/untitled-sans-web-medium.woff) format('woff');
    }
    @font-face {
      font-family: 'Untitled Sans Bold';
      font-style: normal;
      font-weight: normal;
      font-display: swap;
      src: url(/fonts/untitled-sans-web-bold.woff) format('woff');
    }
    @font-face {
      font-family: 'Untitled Sans Black';
      font-style: normal;
      font-weight: nomal;
      font-display: swap;
      src: url(/fonts/untitled-sans-web-black.woff) format('woff');
    }
    @font-face {
      font-family: 'NotoSans Regular';
      font-style: normal;
      font-weight: nomal;
      font-display: swap;
      src: url(/fonts/NotoSans-Regular.woff2) format('woff2');
    }
  `

export { fontList }
export default fontFace
