import {
  BackgroundProps,
  Box,
  Container,
  ContainerProps
} from '@chakra-ui/react'
import React from 'react'

type ChakraBackground =
  | BackgroundProps['bg']
  | BackgroundProps['bgColor']
  | BackgroundProps['backgroundColor']

// Add padding if there is a background colour
// passed to this component that is not white
const addPadding = (fields: Array<ChakraBackground>) =>
  fields.reduce(
    (pastValue, currentField) =>
      pastValue ||
      (currentField !== undefined &&
        currentField !== '' &&
        currentField !== 'white' &&
        currentField !== '#FFF' &&
        currentField !== 'sand.30'),
    false
  )

const ContainerWrapper = (
  props: ContainerProps & {
    'data-testid'?: string
    noDefaultPadding?: boolean
  }
) => {
  const {
    textColor,
    bg,
    bgColor,
    backgroundColor,
    children,
    'data-testid': dataTestId,
    noDefaultPadding = false,
    sx,
    ...rest
  } = props

  return (
    <Box
      data-testid={dataTestId}
      textColor={textColor}
      bgColor={bgColor}
      bg={bg}
      backgroundColor={backgroundColor}
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      <Container
        sx={{
          ...(noDefaultPadding
            ? {
                py: 0,
                px: 0
              }
            : addPadding([bg, bgColor, backgroundColor])
              ? {
                  pt: { base: '60px', lg: '120px' },
                  pb: { base: '60px', lg: '120px' }
                }
              : {
                  pt: { base: '30px', lg: '60px' },
                  pb: { base: '30px', lg: '60px' }
                }),
          ...sx
        }}
        {...rest}
      >
        {children}
      </Container>
    </Box>
  )
}
export default ContainerWrapper
