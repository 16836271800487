import {
  areaOfStudyData,
  deliveryModeData,
  levelOfStudyBroadData,
  levelOfStudySpecificSchoolData,
  locationData,
  placesData,
  providerSubtypeNzSchoolData,
  providerSubtypeNzTertiaryProviderData,
  providerSubtypeOverseasEducationProviderData,
  userTypeData
} from 'taxonomy'

/**
 * Dynamically fetch data from server. Intended to only be used server side.
 * E.g. in getServerSideProps or getStaticProps
 */

export interface TaxonomyObject {
  key: string
  label: string
}
export interface TaxonomyLocation extends TaxonomyObject {
  iso: string
}
export interface TaxonomyRegion extends TaxonomyObject {
  places: Array<TaxonomyObject>
}

export interface TaxonomyAreaOfStudy extends TaxonomyObject {
  subjects: Array<TaxonomyObject>
}
export interface TaxonomyForm {
  location: Array<TaxonomyLocation>
  regions: Array<TaxonomyRegion>
  levelOfStudy: Array<TaxonomyObject>
  levelOfStudySchools: Array<TaxonomyObject>
  areaOfStudy: Array<TaxonomyAreaOfStudy>
  userType: Array<TaxonomyObject>
}
export interface TaxonomyInventorySearch {
  locations: Array<TaxonomyLocation>
  regions: Array<TaxonomyObject>
  levelsOfStudy: Array<TaxonomyObject>
  areasOfStudy: Array<TaxonomyAreaOfStudy>
  deliveryModes: Array<TaxonomyObject>
  providerSubtype: Array<TaxonomyObject>
  subtype: Array<TaxonomyObject>
}

function isActive<T extends { active: number }>(item: T) {
  return Boolean(item.active)
}

export const location = ({ locale = 'en' }: { locale?: string } = {}) =>
  locationData.tags.filter(isActive).map((location) => {
    return {
      key: location.key,
      label: location.data.locale[locale] || location.label,
      iso: location.data.iso
    }
  })

export const regions = ({ locale = 'en' }: { locale?: string } = {}) =>
  placesData.subcategories.map((location) => {
    return {
      key: location.key,
      label: location.label,
      places: location.tags.filter(isActive).map((tag) => ({
        key: tag.key,
        label: tag.data.locale[locale] || tag.label
      }))
    }
  })

export const levelOfStudy = ({ locale = 'en' }: { locale?: string } = {}) =>
  levelOfStudyBroadData.tags.filter(isActive).flatMap((item) =>
    item.subcategories.flatMap((item) =>
      item.tags.filter(isActive).flatMap((tag) => ({
        key: tag.key,
        label: tag.data.locale[locale] || tag.label
      }))
    )
  )

export const levelOfStudySchools = ({
  locale = 'en'
}: { locale?: string } = {}) =>
  levelOfStudySpecificSchoolData.tags.filter(isActive).flatMap((item) => {
    return {
      key: item.key,
      label: item.data.locale[locale] || item.label
    }
  })

export const areaOfStudy = ({ locale = 'en' }: { locale?: string } = {}) =>
  areaOfStudyData.tags.filter(isActive).flatMap((item) => {
    return {
      key: item.key,
      label: item.data.locale[locale] || item.label,
      subjects: item.subcategories.flatMap((subcategory) =>
        subcategory.tags.filter(isActive).map((tag) => ({
          key: tag.key,
          label: tag.data.locale[locale] || tag.label
        }))
      )
    }
  })
export const providerSubtypeNZSchool = () =>
  providerSubtypeNzSchoolData.tags.filter(isActive).flatMap((item) => {
    return {
      key: item.key,
      label: item.label
    }
  })
export const providerSubtypeTertiaryProvider = () =>
  providerSubtypeNzTertiaryProviderData.tags
    .filter(isActive)
    .flatMap((item) => {
      return {
        key: item.key,
        label: item.label
      }
    })
export const providerSubtypeOverseasEducationProvider = () =>
  providerSubtypeOverseasEducationProviderData.tags
    .filter(isActive)
    .flatMap((item) => {
      return {
        key: item.key,
        label: item.label
      }
    })

export const userType = ({ locale = 'en' }: { locale?: string } = {}) =>
  userTypeData.tags.filter(isActive).flatMap((item) => {
    return {
      key: item.key,
      label: item.data.locale[locale] || item.label
    }
  })

export const deliveryMode = ({ locale = 'en' }: { locale?: string } = {}) =>
  deliveryModeData.tags.filter(isActive).flatMap((item) => {
    return {
      key: item.key,
      label: item.data.locale[locale] || item.label
    }
  })

export const providerSubtype = [
  ...providerSubtypeNZSchool(),
  ...providerSubtypeTertiaryProvider(),
  ...providerSubtypeOverseasEducationProvider()
]

// Collection of taxonomy data for the lead form
export const inventorySearchTaxonomyData = (): TaxonomyInventorySearch => ({
  deliveryModes: deliveryMode(),
  levelsOfStudy: levelOfStudy(),
  areasOfStudy: areaOfStudy(),
  locations: location(),
  providerSubtype,
  subtype: providerSubtype,
  regions: regions().flatMap((item) => item.places)
})

export function formTaxonomyData(locale?: string): TaxonomyForm {
  return {
    location: location({ locale: locale }),
    regions: regions({ locale: locale }),
    levelOfStudy: levelOfStudy({ locale: locale }),
    levelOfStudySchools: levelOfStudySchools({ locale: locale }),
    areaOfStudy: areaOfStudy({ locale: locale }),
    userType: userType({ locale: locale })
  }
}

export function getTaxonomyLabelToKey(
  data: Array<TaxonomyObject>,
  label: string
): string | undefined {
  const item = data.find((item) => {
    // Remove accentuated characters from the label to match taxonomy label with
    // label in algolia
    const labelWithoutAccents = item.label
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
    return label === labelWithoutAccents
  })
  return item?.key
}
