import { AutocompletePlugin, getAlgoliaResults } from '@algolia/autocomplete-js'
import { SearchClient } from 'algoliasearch/lite'
import Highlight from '../../Highlight'
import { addItemType, pluginOnSelect } from '../helpers'
import { SearchType, SiteSearchQueryResult } from '../types'

interface CreateSearchQuery {
  searchClient: SearchClient
  indexName: string
  defaultLabel: string
  filters?: string
}

export function createSiteSearchQuery({
  searchClient,
  indexName,
  defaultLabel,
  filters = ''
}: CreateSearchQuery): AutocompletePlugin<
  SiteSearchQueryResult,
  SiteSearchQueryResult
> {
  return {
    getSources({ query }) {
      if (!query) {
        return []
      }

      return [
        {
          sourceId: 'search',
          getItems({ query }) {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName,
                  query,
                  params: {
                    filters
                  }
                }
              ],
              transformResponse({ hits }) {
                // Set label on response
                const results = hits.flat().map((hit) => ({
                  ...hit,
                  label:
                    hit.url === '/'
                      ? defaultLabel
                      : hit.hierarchy.l1
                        ? hit.hierarchy.l1
                        : hit.hierarchy.l0
                          ? hit.hierarchy.l0
                          : defaultLabel,
                  _highlightResult: {
                    ...hit._highlightResult,
                    label: {
                      value: hit?._highlightResult?.hierarchy?.l1?.value
                        ? hit._highlightResult.hierarchy.l1.value
                        : hit?._highlightResult?.hierarchy?.l0?.value
                          ? hit._highlightResult.hierarchy.l0.value
                          : defaultLabel
                    }
                  }
                }))

                return [
                  addItemType<SiteSearchQueryResult>({
                    type: SearchType.QUERY,
                    items: results
                  })
                ]
              }
            })
          },
          getItemUrl({ item }) {
            return item.url
          },
          getItemInputValue({ item }) {
            return item.hierarchy.l1 ? item.hierarchy.l1 : item.hierarchy.l0
          },
          onSelect(params) {
            pluginOnSelect(params)
          },
          templates: {
            header() {
              return 'Suggested Searches'
            },
            item({ item }) {
              return (
                <Highlight
                  attribute={
                    item.hierarchy.l1 ? item.hierarchy.l1 : item.hierarchy.l0
                  }
                  hit={item}
                />
              )
            }
          }
        }
      ]
    }
  }
}
