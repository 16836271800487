import { CtaButton, CtaButtonProps } from '../../buttons'

export interface SearchTitleCtaProps extends CtaButtonProps {
  topic: string
}

const SearchTitleCta = ({ href, topic, ...props }: SearchTitleCtaProps) => (
  <CtaButton
    fontSize='sm'
    lineHeight='24px'
    height='auto'
    href={href}
    aria-label={`Looking for ${topic}?`}
    variant='link'
    justifyContent='unset'
    fontFamily={'enz400'}
    sx={{
      textDecoration: 'underline',
      ':hover': {
        textDecoration: 'underline'
      }
    }}
    {...props}
  >
    {`Looking for ${topic}?`}
  </CtaButton>
)

export default SearchTitleCta
