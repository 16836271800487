import { Box } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { NavLink } from '../NavItem'
import { Header } from './Header'
import LanguageDropdown from './LanguageDropdown'
import { NavCollapsible } from './NavCollapsible'

interface MainNavProps {
  links: NavLink[]
  callback: (id?: string) => void
}

export const MainNav = ({ links, callback }: MainNavProps) => {
  const { locales = [] } = useRouter()
  return (
    <Box borderTop='2px solid rgba(57, 71, 95)'>
      {locales.length > 1 ? <LanguageDropdown /> : undefined}
      <Header />
      <NavCollapsible links={links} callback={callback} />
    </Box>
  )
}
