import { LazyQueryHookOptions, QueryResult } from '@apollo/client'
import {
  Box,
  Center,
  keyframes,
  ModalCloseButton,
  Spinner
} from '@chakra-ui/react'
import {
  GenericText_Swnz_GenericTextFragment,
  GetPageModalByIdQuery,
  GetPageModalByIdQueryVariables,
  GetPageModalByIdTwoQuery,
  GetPageModalByIdTwoQueryVariables,
  Swnz_PageModal,
  useGetPageModalByIdLazyQuery,
  useGetPageModalByIdTwoLazyQuery
} from 'content-service'
import { CloseIcon } from 'icons'
import merge from 'lodash/merge'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { Modal } from '../modal'

type StoryModalQuery = GetPageModalByIdQuery & GetPageModalByIdTwoQuery
type StoryModalQueryVariables = GetPageModalByIdQueryVariables &
  GetPageModalByIdTwoQueryVariables
type StoryModalQueryQueryResult = Pick<
  QueryResult<StoryModalQuery, StoryModalQueryVariables>,
  'loading' | 'data'
>
type StoryModalQueryExecFunctionOptions = Partial<
  LazyQueryHookOptions<StoryModalQuery, StoryModalQueryVariables>
>
type StoryModalQueryResult = [
  (options?: StoryModalQueryExecFunctionOptions) => void,
  StoryModalQueryQueryResult
]

export function useStoryModalQuery(): StoryModalQueryResult {
  const [loadPageModalData, { loading: loadingStatus, data: contentData }] =
    useGetPageModalByIdLazyQuery()
  const [
    loadPageModalTwoData,
    { loading: loadingStatusTwo, data: contentDataTwo }
  ] = useGetPageModalByIdTwoLazyQuery()

  function loadPageModal(options?: StoryModalQueryExecFunctionOptions) {
    loadPageModalData(options)
    loadPageModalTwoData(options)
  }

  const loading = loadingStatus || loadingStatusTwo
  const data = merge({}, contentData, contentDataTwo)
  return [loadPageModal, { loading, data }]
}

const PageModal = dynamic(() => import('swnz/src/templates/PageModal'))

const StoryModal = () => {
  const [storyModalOpenState, setStoryModalOpenState] = useState(false)
  const [modalButtonName, setModalButtonName] = useState<string>('')
  const [currentModalId, setCurrentModalId] = useState<string>('')
  const [loadPageModal, { loading, data: content }] = useStoryModalQuery()
  const { locale, isPreview, query } = useRouter()

  useEffect(() => {
    // if the story modal is open when the router query gets triggered then close it
    if (storyModalOpenState) {
      setStoryModalOpenState(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])

  const updateStoryModalState = useCallback(
    (event) => {
      const {
        detail: { modalId = {}, name = '' }
      } = event || {}

      if (modalId)
        loadPageModal({
          variables: { id: modalId, locale, preview: isPreview }
        })

      setStoryModalOpenState(!storyModalOpenState)
      setModalButtonName(name)
      setCurrentModalId(modalId)
    },
    [loadPageModal, storyModalOpenState, isPreview, locale]
  )

  useEffect(() => {
    if (
      !loading &&
      storyModalOpenState &&
      content?.swnz_pageModal?.sys.id === currentModalId
    ) {
      // Find the first generic text item and assume the title on that is the data we need.
      const genericText =
        content.swnz_pageModal?.sectionsCollection?.items.find(
          (item) => item?.__typename === 'SWNZ_GenericText'
        ) as GenericText_Swnz_GenericTextFragment

      createUtagLinkClickEvent({
        linkLabel: modalButtonName,
        linkCategory: TEALIUM_LINK_CATEGORY.STORY_MODAL,
        modalName: genericText?.title ?? ''
      })
    }
  }, [loading, storyModalOpenState, modalButtonName, currentModalId, content])

  useEffect(() => {
    if (typeof document !== undefined) {
      document.addEventListener('initStoryModal', updateStoryModalState)
    }

    return () => {
      document.removeEventListener('initStoryModal', updateStoryModalState)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fadeInAnimation = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `

  const modalProps: { isOpen: boolean; onClose: () => void } = {
    isOpen: storyModalOpenState,
    onClose: () => setStoryModalOpenState(!storyModalOpenState)
  }

  return (
    <Modal
      {...modalProps}
      size='sm'
      isCentered={false}
      scrollBehavior='outside'
    >
      <Modal.Overlay />
      <Modal.Content
        maxWidth={{ md: '620px', lg: '1015px' }}
        borderRadius={{ base: 'none', sm: '20px' }}
        backgroundColor={loading ? 'transparent' : 'white'}
        paddingTop={{ base: 16, sm: 0 }}
        marginTop={{ base: 0, sm: 24 }}
        marginX={{ base: 0, sm: 16 }}
        marginBottom={{ base: '0', sm: 16 }}
      >
        {!loading && (
          <Box
            position='fixed'
            width='full'
            height='50px'
            left='50%'
            transform='translate(-50%, 0)'
            maxWidth={{ md: '620px', lg: '1015px' }}
            zIndex='1'
            marginTop={{ base: '-50px', xs: '-55px', sm: 0 }}
          >
            <Box position='relative' width='full' height='100%'>
              <ModalCloseButton
                opacity={loading ? 0 : 1}
                backgroundColor={{
                  base: 'rgba(230,232,235, 0.8)',
                  sm: 'rgba(255,255,255, 0.1)'
                }}
                boxSize={{ base: '44px', md: '50px' }}
                borderRadius='full'
                position='absolute'
                right={{ base: '1em', md: '-65px' }}
                top='0'
              >
                <CloseIcon
                  boxSize={{ base: '6', md: '8' }}
                  color={{ base: 'deepblue.500', sm: 'white' }}
                />
              </ModalCloseButton>
            </Box>
          </Box>
        )}

        <Modal.Body padding={0} borderRadius={{ base: 0, sm: '20px' }}>
          {loading && (
            <Center
              height='100vh'
              width='full'
              position='fixed'
              top='0'
              left='0'
            >
              <Spinner
                thickness='6px'
                speed='0.65s'
                emptyColor='teal.100'
                color='deepblue.500'
                size='xl'
                left='50%'
                transform='translate(-50%, 0)'
                display='block'
              />
            </Center>
          )}

          {!loading && content && (
            <Box
              animation={`${fadeInAnimation} 0.25s ease-in`}
              borderRadius={{ base: 'none', sm: '20px' }}
              overflow='hidden'
              paddingBottom={{ base: '30px', md: '40px' }}
            >
              <PageModal
                content={content.swnz_pageModal as Swnz_PageModal}
                isPage={false}
              />
            </Box>
          )}
        </Modal.Body>
      </Modal.Content>
    </Modal>
  )
}

export default StoryModal
