import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { ChevronCircle, ExternalLinkIcon } from 'icons'
import { ReactElement } from 'react'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { isExternalUrl } from 'utils'
import { Link } from '../Link'
import { useNavigation } from './NavWrapper'

export interface NavLink {
  id: string
  slug: string
  title: string
  children?: Array<NavLink>
  isExternalLink?: boolean | undefined
  icon?: ReactElement
  action?: () => void
}

export interface NavItemProps {
  link: NavLink | any
  callback?: (id?: string) => void
}

const NavItem = ({ link, callback }: NavItemProps): ReactElement => {
  const { onClose } = useNavigation()

  const handleLinkClick = () => {
    createUtagLinkClickEvent({
      linkLabel: link.title,
      linkCategory: TEALIUM_LINK_CATEGORY.SITE_NAV
    })
    onClose()
  }

  const linkProps = {
    href: link.slug,
    textDecoration: 'none',
    color: 'white',
    fontFamily: 'enz400',
    ...(link.openLinkInNewWindow && { target: '_blank' }),
    _hover: {
      color: 'navHover'
    }
  }

  const action = (event: { preventDefault: () => void }) => {
    event.preventDefault()
    link.action()
  }

  return (
    <>
      {link.id === 'logout' && <Divider my={4} />}
      <Flex
        width='100%'
        align='center'
        justify={
          link.isExternalLink && isExternalUrl(link.slug)
            ? 'flex-start'
            : 'space-between'
        }
        pt={4}
        pb={4}
      >
        {link?.slug.length !== 0 ? (
          <Link
            {...linkProps}
            onClick={(event: React.SyntheticEvent) => {
              link.action ? action(event) : handleLinkClick()
            }}
            display={link.icon ? 'inline-flex' : 'inline-block'}
            alignItems='center'
          >
            {link.icon && (
              <Box display={'inline-block'} mr={4}>
                {link.icon}
              </Box>
            )}

            {link.title}
          </Link>
        ) : (
          <Text>{link.title}</Text>
        )}

        {link.children && link.children.length !== 0 ? (
          <Box {...(callback && { onClick: () => callback(link.id) })}>
            <Box
              w={7}
              h={7}
              _hover={{
                backgroundColor: 'white',
                color: 'deepblue.600',
                cursor: 'pointer',
                borderRadius: '50%',
                transition: 'background-color, color 0.15s ease-out'
              }}
              sx={{
                'html[lang=ar] &': {
                  transform: 'scaleX(-1)'
                },
                '&:hover': {
                  path: {
                    _first: {
                      fill: 'white'
                    },
                    _last: {
                      fill: 'deepblue.600'
                    }
                  }
                }
              }}
            >
              <ChevronCircle />
            </Box>
          </Box>
        ) : null}

        {link.isExternalLink && isExternalUrl(link.slug) ? (
          <Link {...linkProps} isExternal onClick={handleLinkClick}>
            <Box ml={1} boxSize={6}>
              <ExternalLinkIcon />
            </Box>
          </Link>
        ) : null}
      </Flex>
    </>
  )
}

export default NavItem
