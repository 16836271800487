import { Box, Text } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import JumpAheadMenuLink, {
  JumpAheadMenuLinkProps as LinkAttributes
} from './JumpAheadMenuLink'

const JumpAheadMenu = ({ textColor }: { textColor?: string | null }) => {
  const [linkAttributes, setLinkAttributes] = useState<LinkAttributes[]>([])
  const router = useRouter()

  const validateJumpAheadLinks = useCallback(
    (linksNodeList: HTMLAnchorElement[]) => {
      const validLinkAttributes: LinkAttributes[] = []

      for (const node of linksNodeList) {
        const { displayinmenu, name = '', title = '' } = node.dataset || {}

        if (displayinmenu === 'true') {
          validLinkAttributes.push({ name, title })
        }
      }

      return validLinkAttributes
    },
    []
  )

  useEffect(() => {
    const handleRouteChange = () => {
      let nodeList: HTMLAnchorElement[] | [] = []
      let validLinkAttributes: LinkAttributes[] = []

      if (!nodeList.length) {
        nodeList = Array.from(
          document.querySelectorAll(`[data-type*="jump-ahead"]`)
        )
      }

      if (nodeList.length) {
        validLinkAttributes = validateJumpAheadLinks(nodeList)
        setLinkAttributes(validLinkAttributes)
      }
    }

    handleRouteChange() // Call once on initial load

    if (router.events)
      router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      if (router.events)
        router.events.off('routeChangeComplete', handleRouteChange)
      setLinkAttributes([])
    }
  }, [router.events, validateJumpAheadLinks])

  const { t } = useTranslation('navigation')

  return (
    <Box fontFamily='enz500'>
      <Text mb={0} color='deepblue.300'>
        {`${t('jump_menu')}:`}
      </Text>
      <Box pb={1}>
        {linkAttributes.map(({ name, title }, index: number) => (
          <JumpAheadMenuLink
            name={name}
            title={title}
            key={index}
            textColor={textColor}
          />
        ))}
      </Box>
    </Box>
  )
}

export default JumpAheadMenu
