import { MouseEvent } from 'react'
// Detect when an event is modified with a special key to let the browser trigger its default behavior.
export default function isModifierEvent(event: MouseEvent) {
  const isMiddleClick = event.button === 1

  return (
    isMiddleClick ||
    event.altKey ||
    event.ctrlKey ||
    event.metaKey ||
    event.shiftKey
  )
}
