import { Box } from '@chakra-ui/react'
import { HomepageHero_Swnz_PageHomepageFragment } from 'content-service'
import { useState } from 'react'
import { CallToAction } from 'ui'
import { HomepageHero, HomepageHeroVideo } from '../../components/HomepageHero'

interface HomepageHeroLandingPageProps {
  pageTheme?: string
  homepageHero:
    | Pick<
        HomepageHero_Swnz_PageHomepageFragment,
        | 'heroHeading'
        | 'heroBackgroundVideo'
        | 'heroImage'
        | 'heroJumpText'
        | 'heroPreHeading'
        | 'heroTabsCollection'
        | 'heroWelcomeMessage'
      >
    | null
    | undefined
  homepageHeroCtas: CallToAction[] | []
  leadCaptureFormId?: string
}

export const HomepageHeroLandingPageConnect = ({
  homepageHero,
  homepageHeroCtas,
  pageTheme,
  leadCaptureFormId,
}: HomepageHeroLandingPageProps) => {
  const [isVisible, setIsVisible] = useState<boolean>(true)

  /**
   * Handles state variable used to hide/show elements in this component
   */
  const handleIsVisible = (isVisible: boolean): void => setIsVisible(isVisible)

  if (!homepageHero || homepageHero === null) {
    return null
  }

  return (
    <>
      {/* ⚠️ NEGATIVE MARGIN TO ACCOUNT FOR HEADER HEIGHT 👇 */}
      <Box as="section" marginTop={{ base: '-60px', md: '-76px', xl: '-92px' }}>
        {/* VIDEO 🎞️ */}
        <HomepageHeroVideo
          heroImage={homepageHero.heroImage}
          heroBackgroundVideo={homepageHero.heroBackgroundVideo}
        />

        {/* HERO 🦸‍♂️ */}
        <HomepageHero
          heroPreHeading={homepageHero.heroPreHeading}
          heroHeading={homepageHero.heroHeading}
          heroJumpText={homepageHero.heroJumpText}
          handleIsVisible={handleIsVisible}
          isVisible={isVisible}
          heroCtas={homepageHeroCtas}
          pageTheme={pageTheme}
          leadCaptureFormId={leadCaptureFormId}
        />
      </Box>
    </>
  )
}
