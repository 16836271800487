const LINK_TAGS = [
  {
    tag: 'link',
    key: 'apple-touch-icon',
    rel: 'apple-touch-icon',
    type: 'image/png',
    sizes: '180x180',
    href: '/favicons/apple-touch-icon.png'
  },
  {
    tag: 'link',
    key: 'favicons32',
    rel: 'icon',
    type: 'image/png',
    sizes: '32x32',
    href: '/favicons/favicon-32x32.png'
  },
  {
    tag: 'link',
    key: 'favicons16',
    rel: 'icon',
    type: 'image/png',
    sizes: '16x16',
    href: '/favicons/favicon-16x16.png'
  },
  {
    tag: 'link',
    key: 'manifest',
    rel: 'manifest',
    href: '/site.webmanifest',
    crossOrigin: 'use-credentials'
  },
  {
    tag: 'link',
    key: 'mask-icon',
    rel: 'mask-icon',
    type: 'image/png',
    color: '#22a2a2',
    href: '/favicons/safari-pinned-tab.svg'
  }
]

const META_TAGS = [
  {
    tag: 'meta',
    key: 'google-site-verification',
    name: 'google-site-verification',
    content: 'FnkslTaADXTLPBIEtwVwr8ZlPaD8ByaJkDv6lAwv3Vc'
  },
  {
    tag: 'meta',
    key: 'msapplication-TileColor',
    name: 'msapplication-TileColor',
    content: '#ffffff'
  },
  {
    tag: 'meta',
    key: 'theme-color',
    name: 'theme-color',
    content: '#ffffff'
  }
]

const SITE_HEAD_CONFIG = [...LINK_TAGS, ...META_TAGS]

export default SITE_HEAD_CONFIG
