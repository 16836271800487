import { AspectRatio, Box } from '@chakra-ui/react'
import { PropsWithChildren } from 'react'
import { ContainerWrapper } from 'ui'
import { getPageThemeBackgroundColour } from 'utils/src/helpers'

interface MediaImageProps {
  caption?: string
  aspectRatio?: number
  pageTheme?: string
  noDefaultPadding?: boolean
}

const MediaImage = ({
  caption,
  aspectRatio = 3 / 2,
  children,
  pageTheme,
  noDefaultPadding = false
}: PropsWithChildren<MediaImageProps>) => {
  const setBackgroundColour = getPageThemeBackgroundColour(pageTheme)
  return (
    <ContainerWrapper
      bgColor={setBackgroundColour}
      noDefaultPadding={noDefaultPadding}
    >
      <AspectRatio ratio={aspectRatio}>{children}</AspectRatio>
      {caption && (
        <Box
          as='figcaption'
          display='inline-block'
          marginTop={3}
          color='deepblue.300'
        >
          {caption}
        </Box>
      )}
    </ContainerWrapper>
  )
}

export default MediaImage
