import { Box } from '@chakra-ui/react'
import React from 'react'

const FooterDivider = () => {
  return (
    <Box
      borderWidth='1px'
      borderColor={'white'}
      opacity='.2'
      my={{ base: 6, lg: 0 }}
    />
  )
}

export default FooterDivider
