import {
  CourseDetail_InventoryCourseFragment,
  ProviderDetail_InventoryProviderFragment,
  ScholarshipDetail_InventoryScholarshipFragment,
} from "content-service";
import useDeepCompareEffect from "use-deep-compare-effect";
import { unique } from "utils";
import { createTagList } from "../helpers";
import { INVENTORY_LABELS, TEALIUM_EVENT } from "../helpers/constants";
import { useTealium } from "../TealiumTracking";

type InventoryCourseView = CourseDetail_InventoryCourseFragment;
type InventoryProviderView = ProviderDetail_InventoryProviderFragment;
type InventoryScholarshipView = ScholarshipDetail_InventoryScholarshipFragment;

type InventoryViewResult =
  | {
      page_name: string;
      inventory_object_type: string;
      inventory_id: string;
      level_of_study?: string;
      area_of_study_array?: string[];
      subject_of_study_array?: string[];
      delivery_mode_array?: string[];
      course_name?: string;
      provider_name: string;
      provider_type: string;
      provider_subtype: string[];
      campus_region_array: string[];
      tealium_event: TEALIUM_EVENT;
    }
  | Record<string, unknown>;

//eslint-disable-next-line @typescript-eslint/no-explicit-any
function useTrackInventoryViews(data: any) {
  const { setViewData, setIsPageReady } = useTealium();
  useDeepCompareEffect(() => {
    setViewData(data);
    setIsPageReady(true);
  }, [setViewData, setIsPageReady, data]);
}

function createInventoryCourseUtagView(
  course: InventoryCourseView,
): InventoryViewResult {
  return {
    page_name: INVENTORY_LABELS.COURSE.PAGE_NAME,
    inventory_object_type: INVENTORY_LABELS.COURSE.INVENTORY_OBJECT_TYPE,
    inventory_id: course?.id ?? "",
    level_of_study: course?.levelOfStudy?.tag ?? "",
    area_of_study_array: createTagList(course?.areasOfStudy),
    subject_of_study_array: createTagList(course?.subjectsOfStudy),
    delivery_mode_array: unique(
      course?.intakes?.map((intake) => intake?.deliveryMode?.tag ?? ""),
    ),
    course_name: course?.name ?? "",
    provider_name: course?.provider?.name ?? "",
    provider_type: course?.provider?.type?.tag ?? "",
    provider_subtype: createTagList(course?.provider?.subtypeList),
    campus_region_array: unique(
      course?.provider?.campuses?.map((campus) => campus?.region?.tag ?? ""),
    ),
    tealium_event: TEALIUM_EVENT.VIEW_INVENTORY_ITEM,
  };
}

function createInventoryProviderUtagView(
  provider: InventoryProviderView,
): InventoryViewResult {
  return {
    page_name: INVENTORY_LABELS.PROVIDER.PAGE_NAME,
    inventory_object_type: INVENTORY_LABELS.PROVIDER.INVENTORY_OBJECT_TYPE,
    inventory_id: provider?.id ?? "",
    provider_name: provider?.name ?? "",
    provider_type: provider?.type?.tag ?? "",
    provider_subtype: createTagList(provider?.subtypeList),
    campus_region_array: unique(
      provider?.campuses?.map((campus) => campus?.region?.tag ?? ""),
    ),
    tealium_event: TEALIUM_EVENT.VIEW_INVENTORY_ITEM,
  };
}

function createInventoryScholarshipUtagView(
  scholarship: InventoryScholarshipView,
): InventoryViewResult {
  return {
    page_name: INVENTORY_LABELS.SCHOLARSHIP.PAGE_NAME,
    inventory_object_type: INVENTORY_LABELS.SCHOLARSHIP.INVENTORY_OBJECT_TYPE,
    inventory_id: scholarship?.id ?? "",
    provider_name: scholarship?.providerName ?? "",
    provider_type: scholarship?.providerType?.tag ?? "",
    provider_subtype: createTagList(scholarship?.providerSubtypeList),
    scholarship_name: scholarship?.title ?? "",
    tealium_event: TEALIUM_EVENT.VIEW_INVENTORY_ITEM,
  };
}

export default useTrackInventoryViews;
export {
  createInventoryProviderUtagView,
  createInventoryCourseUtagView,
  createInventoryScholarshipUtagView,
};
export type {
  InventoryCourseView,
  InventoryProviderView,
  InventoryViewResult,
  InventoryScholarshipView,
};
