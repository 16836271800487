import { Box, HStack } from '@chakra-ui/react'
import { ChevronIcon } from 'icons'
import React, { MouseEvent } from 'react'
import { Button, ButtonProps } from '../buttons'

interface BreadcrumbNavButtonsProps extends ButtonProps {
  show: boolean
  alignment: { left: boolean; right: boolean }
  onClick: (e: MouseEvent<HTMLElement>) => void
}

const BreadcrumbNavButtons = ({
  show,
  alignment,
  onClick,
  ...props
}: BreadcrumbNavButtonsProps) => (
  <HStack display={show ? 'flex' : 'none'}>
    <Button
      variant='unstyled'
      minWidth='15px'
      height='full'
      aria-label='Scroll breadcrumb links to the left'
      title='scroll-breadcrumb-links-left'
      onClick={onClick}
      isDisabled={!alignment.left}
      {...props}
    >
      <Box
        boxSize='15px'
        color='white'
        transition='opacity 0.2s ease'
        opacity={alignment.left ? 1 : 0.2}
        transform='rotate(180deg)'
        sx={{
          'html[lang=ar] &': {
            transform: 'scaleX(-1)'
          }
        }}
      >
        <ChevronIcon />
      </Box>
    </Button>
    <Button
      variant='unstyled'
      minWidth='15px'
      height='full'
      aria-label='Scroll breadcrumb links to the right'
      title='scroll-breadcrumb-links-right'
      onClick={onClick}
      isDisabled={!alignment.right}
      {...props}
    >
      <Box
        boxSize='15px'
        color='white'
        transition='opacity 0.2s ease'
        opacity={alignment.right ? 1 : 0.2}
        sx={{
          'html[lang=ar] &': {
            transform: 'scaleX(-1)'
          }
        }}
      >
        <ChevronIcon />
      </Box>
    </Button>
  </HStack>
)

export default BreadcrumbNavButtons
