import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList,
  MenuOptionGroup
} from '@chakra-ui/react'
import { ChevronIcon, TickIcon } from 'icons'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { connectSortBy } from 'react-instantsearch-core'
import { SortByProps } from 'react-instantsearch-dom'
interface SortByComponentProps extends SortByProps {
  currentRefinement: string
  refine: Dispatch<SetStateAction<string>>
}

const Component = ({
  items,
  currentRefinement,
  refine
}: SortByComponentProps) => {
  const DEFAULT_REFINEMENT_LABEL = 'Relevancy'
  const [buttonLabel, setButtonLabel] = useState<string>(
    DEFAULT_REFINEMENT_LABEL
  )

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const value = event.currentTarget.value
    const ariaLabel =
      event.currentTarget.getAttribute('aria-label') || DEFAULT_REFINEMENT_LABEL

    setButtonLabel(ariaLabel)
    refine(value)
  }

  const menuItemStyleProps: MenuItemProps = {
    paddingY: 3,
    paddingRight: 4,
    display: 'flex',
    justifyContent: 'space-between',
    _hover: { background: 'teal.100' }
  }

  const tickIcon = (
    <Box as='span' fill='deepWaterBlue' boxSize='12px'>
      <TickIcon />
    </Box>
  )

  return (
    <Box overflow='hidden'>
      <Menu
        matchWidth={false}
        flip={false}
        preventOverflow
        placement='bottom-end'
      >
        {({ isOpen }) => (
          <>
            <MenuButton
              as={Button}
              width='full'
              isActive={isOpen}
              variant='unstyled'
              display='flex'
              justifyContent='space-between'
              overflow='hidden'
              position='relative'
              rightIcon={
                <Box
                  transform={isOpen ? 'rotate(-90deg)' : 'rotate(90deg)'}
                  transition='0.2s ease-in-out'
                  boxSize='30px'
                  color='deepblue.500'
                  backgroundColor={{ base: 'deepblue.50', lg: 'white' }}
                >
                  <ChevronIcon />
                </Box>
              }
              color='deepblue.500'
              fontFamily='enz400'
              fontSize='16px'
              height='60px'
              borderRadius='base'
              backgroundColor={{ base: 'deepblue.50', lg: 'white' }}
              paddingLeft={{ base: 4, lg: 0 }}
              paddingRight={{ base: 4, lg: 0 }}
            >
              <HStack
                as='span'
                alignItems='center'
                justifyContent='flex-start'
                aria-label={buttonLabel}
              >
                <Box as='span' fontFamily='enz500' color='deepblue.500'>
                  Sort by
                </Box>
                <Box
                  as='span'
                  fontFamily={{ md: 'enz500' }}
                  color={{ base: 'deepblue.500', md: 'deepblue.300' }}
                  overflow='hidden'
                  whiteSpace='nowrap'
                  textOverflow='ellipsis'
                  data-testid='menu-button-label'
                >
                  {' '}
                  {buttonLabel}
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              border='none'
              boxShadow='0px 5px 20px rgba(7, 25, 55, 0.1)'
              paddingBottom='12px'
              maxHeight='350px'
              maxWidth='320px'
              overflowY='scroll'
              marginTop='-5px'
            >
              <MenuOptionGroup>
                {items.map((item, index: number) => (
                  <MenuItem
                    key={index}
                    value={item.value}
                    data-label={item.label}
                    onClick={handleClick}
                    aria-label={item.label}
                    {...menuItemStyleProps}
                  >
                    <Box as='span'>{item.label}</Box>
                    {currentRefinement === item.value && tickIcon}
                  </MenuItem>
                ))}
              </MenuOptionGroup>
            </MenuList>
          </>
        )}
      </Menu>
    </Box>
  )
}

export { Component }
const SortBy = connectSortBy(Component)
export default SortBy
