const DETECT_HTML_TAG_REGEX = /(<([^>]+)>)/gi
const META_DESCRIPTION_TAG_CHAR_LIMIT = 200

const stripTagsFromHtmlStringAndTruncate = (htmlString: string): string => {
  const formattedString = htmlString.replace(DETECT_HTML_TAG_REGEX, '')

  if (formattedString.length > META_DESCRIPTION_TAG_CHAR_LIMIT) {
    return `${formattedString.substring(0, META_DESCRIPTION_TAG_CHAR_LIMIT)}...`
  }

  return formattedString
}

export { stripTagsFromHtmlStringAndTruncate }
