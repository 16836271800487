import { useAuth0 } from '@auth0/auth0-react'
import { Box, Stack, Text } from '@chakra-ui/react'
import { GetProfileFormFieldsQuery } from 'content-service'
import { intervalToDuration, isValid } from 'date-fns'
import { Formik, FormikErrors } from 'formik'
import { ReactNode, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  BoldText,
  Button,
  ExpandableButton,
  Form,
  H3,
  H5,
  ProfileAPIUpdateModel,
  reformatDateForSubmit,
  StackField,
} from 'ui'
import {
  areaOfStudy,
  deliveryMode,
  levelOfStudy,
  location,
  providerSubtypeOverseasEducationProvider,
  providerSubtypeTertiaryProvider,
  regions,
  TaxonomyAreaOfStudy,
  TaxonomyObject,
  TaxonomyRegion,
} from 'utils/src/helpers/taxonomy'
import * as Yup from 'yup'
import { AUTH } from '../../../constants'
import { getProfile, updateStudyOptions, useUser } from '../../../context'

interface BaseProfileFormValues {
  firstName: string
  lastName: string
  email: string
  dateOfBirth: Date | string
}

interface Over18ProfileFormValues extends BaseProfileFormValues {
  deliveryMode?: string
  providerType?: string
  levelOfStudy?: string
  areaOfStudy?: string
  subjectOfStudy?: string
  nzRegion?: string
  maxTuitionBudget?: number
  prefStartDate?: Date | string
  nationality?: string
  nationalityISO?: string
  region?: string
}

const getSubjectOfStudyOptions = (currentAreaOfStudy: string | undefined): TaxonomyObject[] => {
  const areasOfstudy: TaxonomyAreaOfStudy[] = taxonomy.areaOfStudy ?? []
  let subjects: TaxonomyObject[] = []
  if (currentAreaOfStudy && areasOfstudy.length > 0) {
    const [matchingAreaOfStudy] = areasOfstudy.filter((area) => area.key === currentAreaOfStudy)
    subjects = matchingAreaOfStudy?.subjects ?? []
  }

  return subjects
}

const over18ProfileFormSchema: Yup.ObjectSchema<Over18ProfileFormValues> = Yup.object().shape(
  {
    firstName: Yup.string()
      .min(2, 'Too Short!')
      .max(33, 'Too Long!')
      .required('First name is required'),
    lastName: Yup.string()
      .min(2, 'Too Short!')
      .max(33, 'Too Long!')
      .required('Last name is required'),
    email: Yup.string().email('Please enter a valid email').required('Email address is required'),
    dateOfBirth: Yup.date()
      .typeError('Please enter a valid date')
      .transform((value: string) => {
        return value ? new Date(value) : value
      })
      .max(new Date(), 'Please enter a valid date')
      .required('Please enter your date of birth')
      .default(undefined),
    deliveryMode: Yup.string(),
    providerType: Yup.string(),
    levelOfStudy: Yup.string(),
    areaOfStudy: Yup.string(),
    subjectOfStudy: Yup.string().when('areaOfStudy', {
      is: (val) => getSubjectOfStudyOptions(val).length > 0,
      then: (schema) => schema.required('Subject of study is required'),
    }),
    nzRegion: Yup.string(),
    maxTuitionBudget: Yup.number(),
    prefStartDate: Yup.lazy((value) =>
      value === ''
        ? Yup.string().defined()
        : Yup.date().when('prefStartDate', {
            is: (val: Date) => val !== undefined && val !== null,
            then: (rule) =>
              rule
                .transform((value: Date) => {
                  return value ? new Date(value) : value
                })
                .min(new Date(), 'Please enter a date in the future')
                .typeError('Please enter a valid date'),
          })
    ),
    nationality: Yup.string(),
    nationalityISO: Yup.string(),
    region: Yup.string(),
  },
  [['prefStartDate', 'prefStartDate']]
)

interface Under18ProfileFormValues extends BaseProfileFormValues {
  schoolOrientation?: string
  boardingFacilities?: string
  schoolAuthority?: string
  maxTuitionBudget?: number
  nzRegion?: string
}

type ProfileFormValues = Under18ProfileFormValues | Over18ProfileFormValues

const under18ProfileFormSchema: Yup.ObjectSchema<Under18ProfileFormValues> = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(33, 'Too Long!')
    .required('First name is required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(33, 'Too Long!')
    .required('Last name is required'),
  email: Yup.string().email('Please enter a valid email').required('Email address is required'),
  dateOfBirth: Yup.date()
    .typeError('Please enter a valid date')
    .transform((value) => {
      return value ? new Date(value) : value
    })
    .max(new Date(), 'Please enter a valid date')
    .required('Please enter your date of birth'),
  schoolOrientation: Yup.string(),
  boardingFacilities: Yup.string(),
  schoolAuthority: Yup.string(),
  maxTuitionBudget: Yup.number(),
  nzRegion: Yup.string(),
})

const ProfileFormStep = ({ children }: { children: ReactNode | ReactNode[] }) => <>{children}</>

const taxonomy = {
  deliveryMode: deliveryMode(),
  location: location(),
  regions: regions(),
  levelOfStudy: levelOfStudy(),
  areaOfStudy: areaOfStudy(),
  providerType: [
    ...providerSubtypeTertiaryProvider(),
    ...providerSubtypeOverseasEducationProvider(),
  ],
}

interface ProfileFormProps {
  heading: string
  bodyText: string
  contentful: GetProfileFormFieldsQuery
  onProfileFormCompleteCallback: () => void
}

const ProfileForm = ({
  heading,
  bodyText,
  contentful,
  onProfileFormCompleteCallback,
}: ProfileFormProps) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0()
  const audience = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE ?? ''
  const scope = AUTH.READ_USER_SCOPE

  const [showUnder18Fields, setShowUnder18Fields] = useState(false)
  const [showOver18Fields, setShowOver18Fields] = useState(false)

  const { userState, userDispatch } = useUser()

  const handleDisplayAgeDependentFields = (
    dateOfBirth: Date | string,
    errors?: FormikErrors<Date>
  ) => {
    try {
      if (errors) {
        setShowOver18Fields(false)
        setShowUnder18Fields(false)
        return
      }

      if (typeof dateOfBirth !== 'string' && isValid(dateOfBirth)) {
        // prevent time of day from being considered when calculating age
        const currentDate = new Date()
        currentDate.setHours(0, 0, 0, 0)
        dateOfBirth.setHours(0, 0, 0, 0)

        const userAge = intervalToDuration({
          start: dateOfBirth,
          end: currentDate,
        }).years

        if (userAge === undefined) {
          setShowOver18Fields(false)
          setShowUnder18Fields(false)
          return
        }

        if (userAge >= 18) {
          setShowOver18Fields(true)
          setShowUnder18Fields(false)
          return
        }

        setShowOver18Fields(false)
        setShowUnder18Fields(true)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const initialFormValues: ProfileFormValues = {
    firstName: userState.profile?.firstName ?? '',
    lastName: userState.profile?.lastName ?? '',
    email: userState.profile?.email ?? '',
    dateOfBirth: userState.profile?.demographics?.dateOfBirth
      ? new Date(userState.profile.demographics.dateOfBirth)
      : '',
    deliveryMode: userState?.profile?.studyInterests?.deliveryMode ?? '',
    providerType: userState?.profile?.studyInterests?.instType ?? '',
    levelOfStudy: userState.profile?.studyInterests?.levelOfStudy ?? '',
    areaOfStudy: userState.profile?.studyInterests?.areaOfStudy ?? '',
    subjectOfStudy: userState.profile?.studyInterests?.subjectOfStudy ?? '',
    nzRegion: userState.profile?.studyInterests?.nzRegion ?? '',
    maxTuitionBudget: userState?.profile?.studyInterests?.maxTuitionBudget ?? undefined,
    prefStartDate:
      userState.profile?.studyInterests?.prefStartDate &&
      typeof userState.profile.studyInterests.prefStartDate === 'string' &&
      new Date(userState.profile.studyInterests.prefStartDate) > new Date()
        ? new Date(userState.profile?.studyInterests?.prefStartDate)
        : '',
    nationality: userState.profile?.demographics?.nationality ?? '',
    nationalityISO: userState.profile?.demographics?.nationalityIso ?? '',
    region: userState.profile?.region ?? '',
    schoolOrientation: userState.profile?.studyInterests?.schoolOrientation ?? '',
    boardingFacilities: userState.profile?.studyInterests?.boardingFacilities
      ? 'yes'
      : 'no' ?? undefined,
    schoolAuthority: userState.profile?.studyInterests?.schoolAuthority ?? '',
  }

  // [FIX]:
  //       * TS ERROR: figure nesting type issue for contentful query object(s)
  const {
    schoolOrientationField: {
      // @ts-ignore
      items: [
        {
          dropdownOptionsCollection: { items: schoolOrientationOptions },
        },
      ],
    },
    schoolAuthorityField: {
      // @ts-ignore
      items: [
        {
          dropdownOptionsCollection: { items: schoolAuthorityOptions },
        },
      ],
    },
    maxTuitionBudgetField: {
      // @ts-ignore
      items: [
        {
          dropdownOptionsCollection: { items: maxTuitionBudgetOptions },
        },
      ],
    },
  } = contentful

  const regionMap: { [country: string]: string } = Object.freeze({
    'new-zealand': 'nz-region',
    china: 'china-region',
    india: 'india-region',
  })

  const getRegionOptions = (currentRegion: string | null) => {
    const regions: TaxonomyRegion[] = taxonomy?.regions ?? []
    let places: TaxonomyObject[] = []

    if (currentRegion?.length) {
      for (const item of regions) {
        if (item.key === currentRegion) {
          places = item.places
        }
      }
    }

    return places
  }

  const handleSubmit = async (values: ProfileFormValues) => {
    const payload: ProfileAPIUpdateModel = {
      authId: userState?.profile?.authId ?? '',
      email: values.email,
      tealiumVisitorId: window?.utag?.data?.tealium_visitor_id || '',
      personType: userState?.profile?.contactDetails?.personType ?? '',
      dateOfBirth: reformatDateForSubmit(values.dateOfBirth),
      firstName: values.firstName,
      lastName: values.lastName,
      areaOfStudy:
        'areaOfStudy' in values
          ? values.areaOfStudy
          : userState?.profile?.studyInterests?.areaOfStudy
            ? userState.profile.studyInterests.areaOfStudy
            : '',
      deliveryMode:
        'deliveryMode' in values
          ? values.deliveryMode
          : userState?.profile?.studyInterests?.deliveryMode
            ? userState.profile.studyInterests.deliveryMode
            : '',
      subjectOfStudy:
        'subjectOfStudy' in values
          ? values.subjectOfStudy
          : userState?.profile?.studyInterests?.subjectOfStudy
            ? userState.profile.studyInterests.subjectOfStudy
            : '',
      instType:
        'providerType' in values
          ? values.providerType
          : userState?.profile?.studyInterests?.instType ?? '',
      schoolAuthority: 'schoolAuthority' in values ? values.schoolAuthority : '',
      boardingFacilities:
        'boardingFacilities' in values && values.boardingFacilities === 'yes' ? true : false,
      studentFirstName: userState?.profile?.contactDetails?.studentFirstName ?? '',
      studentLastName: userState?.profile?.contactDetails?.studentLastName ?? '',
      sex: userState?.profile?.demographics?.gender ?? '',
      nationality:
        'nationality' in values
          ? values.nationality
          : userState?.profile?.demographics?.nationality
            ? userState.profile.demographics.nationality
            : '',
      nationalityIso:
        'nationalityISO' in values
          ? values.nationalityISO
          : userState?.profile?.demographics?.nationalityIso
            ? userState.profile.demographics.nationalityIso
            : '',
      levelOfStudy:
        'levelOfStudy' in values
          ? values.levelOfStudy
          : userState?.profile?.studyInterests?.levelOfStudy
            ? userState.profile.studyInterests.levelOfStudy
            : '',
      schoolOrientation:
        'schoolOrientation' in values
          ? values.schoolOrientation
          : userState?.profile?.studyInterests?.schoolOrientation
            ? userState.profile.studyInterests.schoolOrientation
            : '',
      prefStartDate:
        'prefStartDate' in values && values.prefStartDate
          ? reformatDateForSubmit(values.prefStartDate)
          : '',
      nzLocalityType: userState?.profile?.studyInterests?.nzLocalityType ?? '',
      nzRegion:
        'nzRegion' in values
          ? values.nzRegion
          : userState?.profile?.studyInterests?.nzRegion
            ? userState.profile.studyInterests.nzRegion
            : '',
      studyLevelAchieved: userState?.profile?.studyInterests?.studyLevelAchieved ?? '',
      useOfAgent: userState?.profile?.studyInterests?.useOfAgent ?? '',
      // Check that the tuition budget is not an empty string which is the default value, otherwise we want to send it as a number
      maxTuitionBudget:
        'maxTuitionBudget' in values
          ? values.maxTuitionBudget
            ? Number(values.maxTuitionBudget)
            : 0
          : userState?.profile?.studyInterests?.maxTuitionBudget
            ? userState.profile.studyInterests.maxTuitionBudget
            : 0,
      region:
        'region' in values
          ? values.region
          : userState?.profile?.region
            ? userState.profile.region
            : '',
      city: userState?.profile?.city ?? '',
      englishProfLevel: userState?.profile?.studyInterests?.englishProfLevel ?? '',
      mobilePhone: userState?.profile?.mobilePhone ?? '',
      nzWorkAfterStudy: userState?.profile?.studyInterests?.nzWorkAfterStudy ?? false,
      scholarshipSeeker: userState?.profile?.studyInterests?.scholarshipSeeker ?? false,
      prefLanguage: userState?.profile?.demographics.prefLanguage ?? '',
    }

    const accessToken = await getAccessTokenSilently({ authorizationParams: { audience, scope } })

    await updateStudyOptions(userDispatch, accessToken, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(payload),
    })
      .then(onProfileFormCompleteCallback)
      .then(async () => {
        if (isAuthenticated) {
          await getProfile(userDispatch, user, accessToken)
        }
      })
  }

  return (
    <>
      <H3 mb={3}>{heading}</H3>
      <Form.LeadText pt={0}>{bodyText}</Form.LeadText>
      <Form.Divider />

      <Formik
        initialValues={initialFormValues}
        onSubmit={handleSubmit}
        validationSchema={showOver18Fields ? over18ProfileFormSchema : under18ProfileFormSchema}
        validateOnMount
        initialTouched={{ prefStartDate: true }}
      >
        {({ dirty, isValid, isSubmitting, values, errors, handleChange, setFieldValue }) => {
          const dob = values.dateOfBirth !== initialFormValues.dateOfBirth && values.dateOfBirth
          handleDisplayAgeDependentFields(dob || values.dateOfBirth, errors.dateOfBirth)
          return (
            <Form>
              <ProfileFormStep>
                <H5 mb={3}>Your details</H5>
                <Stack spacing={4} mb={6} alignItems="flex-start" dir="ltr">
                  <StackField>
                    <Form.FirstNameField />
                    <Form.LastNameField />
                  </StackField>
                  <Form.EmailAddressField isDisabled={Boolean(values.email)} />
                </Stack>
                <H5 mb={3}>When were you born?</H5>
                <Text>
                  This will help us recommend the right providers for your age (if you are a parent
                  looking for a school for your child, please use your child's date of birth).
                </Text>
                <Box w="100%" maxW={{ lg: '289px' }}>
                  <Form.DateOfBirthField initialValue={initialFormValues.dateOfBirth} isRequired />
                </Box>
              </ProfileFormStep>

              {showUnder18Fields && (
                <ProfileFormStep>
                  <Form.Divider mt={8} mb={8} />
                  <H5 mb={3}>School details for children aged 17 years and under</H5>
                  <Box mb={6}>
                    <Text mb={3}>Do you want a single sex or co-educational school?</Text>
                    <Form.SelectField
                      label="Please choose"
                      name="schoolOrientation"
                      required={false}
                      options={schoolOrientationOptions}
                    />
                  </Box>
                  <Box mb={6}>
                    <Text mb={3}>
                      What school type are you looking for? E.g state, private, religious
                    </Text>
                    <Form.SelectField
                      label="Please choose"
                      name="schoolAuthority"
                      required={false}
                      options={schoolAuthorityOptions}
                    />
                  </Box>
                  <Box mb={6}>
                    <Form.RadioField
                      name="boardingFacilities"
                      label="Is living at the school required?"
                      options={[
                        {
                          value: 'yes',
                          label: 'Yes, I need a school with boarding facilities.',
                        },
                        {
                          value: 'no',
                          label: "No, I'm ok with off campus accommodation.",
                        },
                      ]}
                    />
                  </Box>
                  <Box mb={6}>
                    <Form.SelectField
                      label="Max annual tuition budget $NZD"
                      name="maxTuitionBudget"
                      required={false}
                      options={maxTuitionBudgetOptions}
                    />
                  </Box>
                  <Text mb={3}>Which NZ region would you like to be in?</Text>
                  <Form.SelectField
                    label="Please choose"
                    name="nzRegion"
                    required={false}
                    options={getRegionOptions(regionMap['new-zealand'])}
                  />
                </ProfileFormStep>
              )}

              {showOver18Fields && (
                <>
                  <ProfileFormStep>
                    <Form.Divider mt={8} mb={8} />
                    <Box paddingBottom={3}>
                      <H5 mb={3}>Additional details based on your age of 18 years and over</H5>
                      <Text mb={3}>Will you be studying in New Zealand or Online?</Text>
                      <ExpandableButton
                        label="Learn more about these different study options."
                        whiteSpace="normal"
                        style={{ textAlign: 'start' }}
                      >
                        <>
                          <Text>
                            <BoldText as="span">Studying in New Zealand: </BoldText>When you
                            complete your studies here in New Zealand.
                          </Text>
                          <Text>
                            <BoldText as="span">Studying online: </BoldText>Complete your studies
                            with a New Zealand education provider online.
                          </Text>
                        </>
                      </ExpandableButton>
                    </Box>
                    <Form.SelectField
                      label="Please choose"
                      name="deliveryMode"
                      required={false}
                      options={taxonomy.deliveryMode.filter((mode) => mode.key !== 'pathway')}
                    />
                  </ProfileFormStep>

                  {'deliveryMode' in values && values.deliveryMode && (
                    <ProfileFormStep>
                      <Form.Divider mt={8} />
                      <H5 mb={3}>
                        We just need a few more details to help provide the best matches for you.
                      </H5>
                      <Stack spacing={4} mb={8} alignItems="flex-start" dir="ltr">
                        {values.deliveryMode === 'in-new-zealand' && (
                          <>
                            <StackField>
                              <Form.SelectField
                                label="Provider type"
                                name="providerType"
                                required={false}
                                options={taxonomy.providerType}
                              />
                              <Form.SelectField
                                label="Level of study"
                                name="levelOfStudy"
                                required={false}
                                options={taxonomy.levelOfStudy}
                              />
                            </StackField>
                            <Form.SelectField
                              label="Area of study"
                              name="areaOfStudy"
                              required={false}
                              options={taxonomy.areaOfStudy}
                              onInput={(e) => {
                                handleChange(e)
                                setFieldValue('subjectOfStudy', undefined)
                              }}
                            />
                            {values?.areaOfStudy &&
                              values.areaOfStudy.length > 0 &&
                              getSubjectOfStudyOptions(values.areaOfStudy).length > 0 && (
                                <Form.SelectField
                                  label="Subject of study"
                                  name="subjectOfStudy"
                                  required={true}
                                  options={getSubjectOfStudyOptions(values.areaOfStudy)}
                                />
                              )}
                            <StackField>
                              <Form.SelectField
                                label="Region of NZ"
                                name="nzRegion"
                                required={false}
                                options={getRegionOptions(regionMap['new-zealand'])}
                              />
                              <Form.SelectField
                                label="Max annual tuition budget"
                                name="maxTuitionBudget"
                                required={false}
                                options={maxTuitionBudgetOptions}
                              />
                            </StackField>
                            <Box w={'100%'} maxW={{ lg: '289px' }}>
                              <Form.StartDateField label="Preferred start date" />
                            </Box>
                          </>
                        )}
                        {values.deliveryMode === 'online' && (
                          <>
                            <StackField>
                              <Form.SelectField
                                label="Provider type"
                                name="providerType"
                                required={false}
                                options={taxonomy.providerType}
                              />
                              <Form.SelectField
                                label="Level of study"
                                name="levelOfStudy"
                                required={false}
                                options={taxonomy.levelOfStudy}
                              />
                            </StackField>
                            <Form.SelectField
                              label="Area of study"
                              name="areaOfStudy"
                              required={false}
                              options={taxonomy.areaOfStudy}
                            />
                            {values?.areaOfStudy &&
                              values.areaOfStudy.length > 0 &&
                              getSubjectOfStudyOptions(values.areaOfStudy).length > 0 && (
                                <Form.SelectField
                                  label="Subject of study"
                                  name="subjectOfStudy"
                                  required={true}
                                  options={getSubjectOfStudyOptions(values.areaOfStudy)}
                                />
                              )}
                            <StackField>
                              <Form.StartDateField label="Preferred start date" />
                              <Form.SelectField
                                label="Max annual tuition budget"
                                name="maxTuitionBudget"
                                required={false}
                                options={maxTuitionBudgetOptions}
                              />
                            </StackField>
                          </>
                        )}
                      </Stack>
                    </ProfileFormStep>
                  )}
                </>
              )}
              <Box visibility="hidden">
                {/* @ts-ignore https://github.com/dozoisch/react-google-recaptcha/issues/277 */}
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY ?? ''}
                />
              </Box>
              <Button
                mt={8}
                colorScheme="teal"
                type="submit"
                //If form is dirty and is invalid or submitting, then disable the button
                isDisabled={(dirty && !isValid) || isSubmitting}
              >
                Get new recommendations
              </Button>
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default ProfileForm
