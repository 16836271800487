import Checkbox from './checkbox'
import { mode } from '@chakra-ui/theme-tools'

const parts = ['container', 'control', 'label']
type Dict = Record<string, any>

function baseStyleControl(props: Dict) {
  const { control } = Checkbox.baseStyle()
  const { colorScheme: c } = props
  return {
    ...control,
    borderRadius: 'full',
    pos: 'relative',
    display: 'flex',
    _checked: {
      _before: {
        content: `""`,
        pos: 'absolute',
        w: '14px',
        h: '14px',
        borderRadius: 'inherit',
        bg: 'white'
      },
      _after: {
        content: `""`,
        pos: 'absolute',
        w: '10px',
        h: '10px',
        borderRadius: 'inherit',
        bg: mode(`${c}.500`, `${c}.400`)(props)
      }
    }
  }
}

const baseStyle = (props: Dict) => ({
  label: Checkbox.baseStyle().label,
  control: baseStyleControl(props)
})

const defaultProps = {
  size: 'lg'
}

export default {
  parts,
  baseStyle,
  defaultProps
}
