import { Box, Flex, Stack } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { CallToAction, ContainerWrapper, CtaButton, Headline } from 'ui'
import { colourMap } from 'utils/src/helpers'

export interface PanelProps {
  backgroundColour?: string
  preHeading?: string
  heading?: string
  subHeading?: string
  primaryCta?: CallToAction
  secondaryCta?: CallToAction
  children: ReactNode
}

const HomepagePanel = ({
  backgroundColour = 'white',
  preHeading,
  heading,
  subHeading,
  primaryCta,
  secondaryCta,
  children,
}: PanelProps) => {
  return (
    <Box
      backgroundColor={colourMap(backgroundColour)}
      color="white"
      py={{ base: '60px', lg: '120px' }}
      data-testid="homepage-cards"
      sx={{
        hr: {
          '&.chakra-divider:not(.accordion-divider)': {
            borderColor: '#fff',
            maxWidth: { base: 40, md: 80 },
            mx: 'auto',
            mb: { base: 8, md: 10 },
            mt: 0,
          },
        },
      }}
    >
      <ContainerWrapper sx={{ pt: 0, pb: { base: '35px', md: '40px' } }}>
        <Headline
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          mb={0}
        >
          <Box
            as="span"
            sx={{
              'html:not([lang=en]) &': {
                fontSize: { base: '30px', lg: '50px' },
                marginBottom: { base: '16px', lg: '32px' },
                lineHeight: { base: '32px', lg: '64px' },
              },
            }}
          >
            {preHeading}
          </Box>
          <Box
            as="span"
            fontSize={{ base: '8rem', lg: '20rem', xl: '22.5rem' }}
            sx={{
              'html:not([lang=en]) &': {
                fontSize: { base: '84px', lg: '200px' },
              },
              'html:lang(vi) &': {
                lineHeight: { base: '84px', lg: '250px' },
              },
            }}
          >
            {heading}
          </Box>
          <Box
            as="span"
            sx={{
              'html:not([lang=en]) &': {
                fontSize: { base: '30px', lg: '50px' },
                marginTop: { base: '16px', lg: '32px' },
                lineHeight: { base: '32px', lg: '64px' },
              },
            }}
          >
            {subHeading}
          </Box>
        </Headline>
      </ContainerWrapper>
      {children}
      {primaryCta || secondaryCta ? (
        <ContainerWrapper sx={{ py: 0 }}>
          <Flex alignItems="center" justifyContent="center">
            <Stack direction={{ base: 'column', lg: 'row' }}>
              {primaryCta?.label && (
                <CtaButton
                  colorScheme="deepblue"
                  href={primaryCta.href}
                  openInNewWindow={primaryCta.openInNewWindow}
                  isExternalLink={primaryCta.isExternalLink}
                  externalIconSize={6}
                  aria-label={primaryCta.label}
                  onClick={() =>
                    createUtagLinkClickEvent({
                      linkLabel: primaryCta.label.toLowerCase(),
                      linkCategory: TEALIUM_LINK_CATEGORY.CTA,
                    })
                  }
                  modalId={primaryCta.modalId}
                >
                  {primaryCta.label}
                </CtaButton>
              )}
              {secondaryCta?.label && (
                <CtaButton
                  openInNewWindow={secondaryCta.openInNewWindow}
                  isExternalLink={secondaryCta.isExternalLink}
                  variant="outline"
                  colorScheme="white"
                  href={secondaryCta.href}
                  externalIconSize={6}
                  aria-label={secondaryCta.label}
                  onClick={() =>
                    createUtagLinkClickEvent({
                      linkLabel: secondaryCta.label.toLowerCase(),
                      linkCategory: TEALIUM_LINK_CATEGORY.CTA,
                    })
                  }
                  modalId={secondaryCta.modalId}
                >
                  {secondaryCta.label}
                </CtaButton>
              )}
            </Stack>
          </Flex>
        </ContainerWrapper>
      ) : null}
    </Box>
  )
}

export default HomepagePanel
