import { TrackingMap } from "./tealium/clicks/inventorySearchCriteria";
import {
  createTagList,
  createUtagFilterUpdateEvent,
  createUtagInventorySearchCriteriaEvent,
  createUtagLinkClickEvent,
  createUtagProviderReferralEvent,
  createUtagSiteNavEvent,
  createUtagStartEnquiryEvent,
  handleUtagVideoTracking,
  Utag,
} from "./tealium/helpers";
import {
  INVENTORY_LABELS,
  TEALIUM_EVENT,
  TEALIUM_LINK_CATEGORY,
  TEALIUM_SEARCH_FILTER_MAPS,
} from "./tealium/helpers/constants";
import { dispatchFormSubmissionUtagLinkEvent } from "./tealium/helpers/formSubmissionUtagLinkEvent";
import useTrackVideo from "./tealium/hooks/useTrackVideo";
import {
  default as TealiumProvider,
  default as TealiumTracking,
  useTealium,
} from "./tealium/TealiumTracking";
import useTrackInventoryViews, {
  createInventoryCourseUtagView,
  createInventoryProviderUtagView,
  createInventoryScholarshipUtagView,
} from "./tealium/views/inventory";
import { useTrackPageViews } from "./tealium/views/page";

export {
  TealiumTracking,
  createUtagLinkClickEvent,
  TEALIUM_LINK_CATEGORY,
  createUtagFilterUpdateEvent,
  createUtagSiteNavEvent,
  useTrackVideo,
  TEALIUM_SEARCH_FILTER_MAPS,
  createTagList,
  createUtagStartEnquiryEvent,
  INVENTORY_LABELS,
  createUtagProviderReferralEvent,
  createUtagInventorySearchCriteriaEvent,
  dispatchFormSubmissionUtagLinkEvent,
  TealiumProvider,
  useTrackPageViews,
  useTrackInventoryViews,
  createInventoryProviderUtagView,
  createInventoryScholarshipUtagView,
  handleUtagVideoTracking,
  TEALIUM_EVENT,
  useTealium,
  createInventoryCourseUtagView,
};
export type { Utag, TrackingMap };
