import React from 'react'
import {
  connectCurrentRefinements as connectCurrentRefinementsOriginal,
  CurrentRefinementsExposed,
  CurrentRefinementsProvided,
  Refinement
} from 'react-instantsearch-core'

/**
 * A re-export of "connectCurrentRefinements" HOC which allows us to ignore specified refinement results.
 * This ensures that any Components wrapped in this HOC have a little more control over (auditing)
 * the props being injected to them.
 */

const connectCurrentRefinements = <ComponentProps,>(
  StatelessComponent: React.FunctionComponent<
    CurrentRefinementsProvided & ComponentProps
  >,
  ignoreRefinements?: string[]
): React.ComponentClass<CurrentRefinementsExposed, any> => {
  return connectCurrentRefinementsOriginal((connectProps) => {
    const { items } = connectProps

    const itemWhitelist = ignoreRefinements
      ? items.filter(
          (item: Refinement) => !ignoreRefinements.includes(item.attribute)
        )
      : items

    // @ts-ignore: This needs to be remedied, difficulty in typing a "HOC'd" HOC.
    return <StatelessComponent {...{ ...connectProps, items: itemWhitelist }} />
  })
}

export { connectCurrentRefinements }
