import {
  Box,
  ButtonGroup,
  HStack,
  ModalCloseButton,
  Text
} from '@chakra-ui/react'
import { MouseEvent } from 'react'
import { BoldText, H3, Link, Modal } from 'ui'
import { Button } from '../../buttons'

const COMPONENT_CONFIG = {
  HEADING: 'Choose your study mode',
  BODY_TEXT:
    'How do you intend to study? You can show all courses or filter by how you intend to study.',
  SHOW_ALL_BUTTON_LABEL: 'Show all courses'
}

interface ResultsFilterModalUIProps {
  items: { label: string; count: number }[]
  totalHits: number
  isOpen: boolean
  handleButtonClick: (event: MouseEvent<HTMLButtonElement>) => void
  handleOnClose: () => void
}

const ResultsFilterModalUI = ({
  items,
  totalHits,
  isOpen,
  handleButtonClick,
  handleOnClose
}: ResultsFilterModalUIProps) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={handleOnClose}
      motionPreset='slideInBottom'
      size='full'
    >
      <Modal.Overlay />
      <Modal.FullWidthContent
        data-testid='results-filter-modal-content'
        minWidth={{ base: 'full', md: '550px', lg: '650px' }}
        minHeight={{ base: 'full', md: 'auto' }}
        borderRadius={{ base: 0, md: '20px' }}
        position='fixed'
        sx={{
          marginTop: 0,
          marginBottom: 0,
          dir: 'ltr'
        }}
      >
        <Modal.Body
          paddingX={7}
          paddingTop={10}
          paddingBottom={16}
          flexGrow={{ base: 0, md: 1 }}
        >
          <HStack justifyContent='space-between' alignItems='center'>
            <H3 margin={0}>{COMPONENT_CONFIG.HEADING}</H3>
            <ModalCloseButton
              position='initial'
              aria-label='Close modal'
              backgroundColor={{
                base: 'white',
                md: 'rgba(230, 232, 235, 0.8)'
              }}
              borderRadius='full'
              boxSize={{ base: '20px', md: '50px' }}
              _hover={{
                backgroundColor: { md: 'deepblue.200' }
              }}
              transform={{ base: 'translateY(-16px)', md: 'unset' }}
              sx={{
                svg: {
                  fill: 'red',
                  boxSize: { base: '16px', md: '18px' },
                  color: { base: 'deepblue.300', md: 'deepblue.500' }
                }
              }}
            />
          </HStack>
          <Box
            width='full'
            maxWidth={{ base: '90%', md: 'calc(100% - 115px)' }}
            marginTop={6}
          >
            <Text>
              We found{' '}
              <BoldText as='span' data-testid='results-filter-modal-total-hits'>
                {totalHits}
              </BoldText>{' '}
              different courses {COMPONENT_CONFIG.BODY_TEXT}
            </Text>
          </Box>
          <ButtonGroup
            marginTop={8}
            display='flex'
            flexDirection={{ base: 'column', lg: 'row' }}
            colorScheme='deepblue'
            variant='outline'
            flexFlow={{ lg: 'wrap' }}
            __css={{
              button: {
                marginLeft: 0,
                marginRight: { base: 0, lg: 4 },
                marginBottom: 4,
                'html[lang=ar] &': {
                  marginRight: 'unset',
                  marginLeft: { base: 0, lg: 4 }
                }
              },
              'button:last-of-type': {
                marginBottom: { base: 0, lg: 4 }
              }
            }}
          >
            <Button
              onClick={handleOnClose}
              value=''
              aria-label={COMPONENT_CONFIG.SHOW_ALL_BUTTON_LABEL}
            >
              {COMPONENT_CONFIG.SHOW_ALL_BUTTON_LABEL} ({totalHits})
            </Button>
            {items.map((item, index) => (
              <Button
                key={index}
                onClick={handleButtonClick}
                value={item.label}
                aria-label={item.label}
              >
                {item.label} ({item.count})
              </Button>
            ))}
          </ButtonGroup>
        </Modal.Body>
      </Modal.FullWidthContent>
    </Modal>
  )
}

export default ResultsFilterModalUI
