// https://github.com/vercel/next.js/blob/canary/examples/with-apollo/lib/apolloClient.js

import {
  ApolloClient,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'
import { useMemo } from 'react'
import isServer from './isServer'
import link from './link'
import generatedIntrospection from './possibleTypes'
import typePolicies from './typePolicies'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject>

type InitialState = NormalizedCacheObject & {
  preview?: boolean
}

export function createApolloClient(initialState: InitialState) {
  const preview = initialState.preview || false

  return new ApolloClient({
    ssrMode: isServer(),
    link: link(preview),
    cache: new InMemoryCache({
      typePolicies,
      possibleTypes: generatedIntrospection.possibleTypes
    })
  })
}

export function initializeApollo(initialState = {}) {
  const _apolloClient =
    apolloClient === null || apolloClient === undefined
      ? createApolloClient(initialState)
      : apolloClient

  if (initialState) {
    const existingCache = _apolloClient.extract()

    const data = merge(initialState, existingCache, {
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        )
      ]
    })

    _apolloClient.cache.restore(data)
  }
  if (isServer()) return _apolloClient
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  pageProps: Record<string, unknown> & {
    props: Record<string, unknown>
  }
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }
  return pageProps
}

export function getApolloState(client: ApolloClient<NormalizedCacheObject>) {
  return {
    [APOLLO_STATE_PROP_NAME]: client.cache.extract()
  }
}

export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(state), [state])
  return store
}
