import { useTheme } from '@chakra-ui/react'
import {
  HeroFeatured_Swnz_HeroFeaturedFragment,
  HeroFeatured_Swnz_HeroFeaturedFragmentDoc,
  HomepagePage_Swnz_PageHomepageFragment
} from 'content-service'
import { ReactElement } from 'react'
import { HeroFeaturedProps, OptimisedImage } from 'ui'
import {
  createCtaButton,
  createHeroFloatingCardProps,
  createHeroImageProps,
  createHeroVideoProps,
  IMAGE_SIZE_CONFIG
} from 'utils'
import { useMediaQuery } from 'utils/src/helpers'
import useFragment from 'utils/src/hooks/useFragment'
import { HeroFeatured, HeroIntroWrapper } from '../../components'
import { Connector } from '../types'

type HeroFeatured = HeroFeatured_Swnz_HeroFeaturedFragment
type Hero = Pick<HomepagePage_Swnz_PageHomepageFragment, 'hero'>

type HeroFeaturedComponent = HeroFeaturedProps & {
  leadCaptureFormId?: string
}

type HeroProps = Hero & {
  displayType: string
  pageTheme?: string
  leadCaptureFormId?: string
}

type HeroFeaturedConnectProps = Connector

const createHeroFeaturedComponentProps = (
  hero: HeroFeatured,
  isFocusedOnFaces?: boolean,
  leadCaptureFormId?: string
): HeroFeaturedComponent => {
  const { key, ...heroImageProps } = createHeroImageProps(hero?.mediaItem)

  return {
    heading: hero?.heading || '',
    subHeading: hero?.subHeading || '',
    introText: hero?.introText || '',
    primaryCta: createCtaButton(hero?.primaryCta),
    secondaryCta: createCtaButton(hero?.secondaryCta),
    video: createHeroVideoProps(hero?.mediaItem),
    image: OptimisedImage({
      key,
      ...heroImageProps,
      imageSizeConfig: IMAGE_SIZE_CONFIG.FEATURED_HERO,
      objectPosition: '80%',
      loading: 'eager',
      blurOnLoad: false,
      onFocusBreakpoint: isFocusedOnFaces
    }),
    heroFloatingCard:
      hero?.heroFloatingCard &&
      createHeroFloatingCardProps(hero?.heroFloatingCard),
    leadCaptureFormId: leadCaptureFormId,
    textThemeColour: hero?.textThemeColour
  }
}

const HeroFeaturedConnect = ({
  id,
  typename
}: HeroFeaturedConnectProps): ReactElement | null => {
  const theme = useTheme()
  const isFocusedOnFaces = useMediaQuery(`(max-width: ${theme.breakpoints.md})`)

  const data = useFragment<HeroFeatured_Swnz_HeroFeaturedFragment>({
    id,
    typename,
    fragment: HeroFeatured_Swnz_HeroFeaturedFragmentDoc,
    fragmentName: 'heroFeatured_SWNZ_HeroFeatured'
  })

  if (!data) {
    return null
  }

  return (
    <HeroIntroWrapper
      backgroundColor={
        data.textThemeColour === 'white' ? 'deep-blue' : 'surface-blue'
      }
    >
      <HeroFeatured
        {...createHeroFeaturedComponentProps(data, isFocusedOnFaces)}
      />
    </HeroIntroWrapper>
  )
}

export default HeroFeaturedConnect
