import { useEffect, useState } from 'react'

function calculateNumberOfPages(numberOfHits: number, hitsPerPage: number) {
  return Math.ceil(numberOfHits / hitsPerPage)
}

const calculateMin = (currentPage: number, hitsPerPage: number) =>
  hitsPerPage * currentPage - hitsPerPage
const calculateMax = (currentPage: number, hitsPerPage: number) =>
  hitsPerPage * currentPage

/**
 * Convert results into a paginated set
 */
function usePaginateResults<Hit>({
  hitLoading,
  hits,
  hitsPerPage
}: {
  hitLoading: boolean
  hits: Hit[]
  hitsPerPage: number
}) {
  const [currentPage, setCurrentPage] = useState(1)
  const [max, setMax] = useState<number>(calculateMax(currentPage, hitsPerPage))
  const [min, setMin] = useState<number>(calculateMin(currentPage, hitsPerPage))

  useEffect(() => {
    if (!hitLoading) {
      setMin(calculateMin(currentPage, hitsPerPage))
      setMax(calculateMax(currentPage, hitsPerPage))
    }
  }, [hitLoading, currentPage, hitsPerPage])

  return {
    currentPage,
    setCurrentPage,
    paginatedHits: hits.slice(min, max) ?? [],
    totalItems: hits.length,
    numberOfPages: calculateNumberOfPages(hits.length, hitsPerPage)
  }
}

export default usePaginateResults
