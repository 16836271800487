import FooterDivider from './FooterDivider'
import FooterSectionLegalLinks from './FooterSectionLegalLinks'
import FooterSectionLinks from './FooterSectionLinks'
import FooterSectionSocialLinks from './FooterSectionSocialLinks'
import FooterSectionTitle from './FooterSectionTitle'

export {
  FooterSectionLegalLinks,
  FooterSectionLinks,
  FooterSectionTitle,
  FooterSectionSocialLinks,
  FooterDivider
}
