// Revaldation constants (seconds)
const REVALIDATE = {
  DEFAULT: 86400,
  FINDER: {
    DETAILS: 86400,
    RESULTS: 86400
  },
  ERROR_PAGES: 3600
}

export default REVALIDATE
