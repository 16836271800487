import { SearchType } from '../types'

interface AddItemType<Item> {
  type: SearchType
  items: Item[]
}

export function addItemType<Item>({ type, items }: AddItemType<Item>): Item[] {
  return items.map((item) => ({ ...item, type }))
}
