import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'

/* Global style overrides */
import colors from './colors'
import styles from './styles'
/* Foundational style overrides */
import breakpoints from './foundations/breakpoints'
import fontSizes from './foundations/fontSizes'

/* Component style overrides */
import Button from './components/button'
import Checkbox from './components/checkbox'
import Container from './components/container'
import Input from './components/input'
import Link from './components/link'
import Radio from './components/radio'
import Select from './components/select'
import Switch from './components/switch'
import Textarea from './components/textarea'
import fonts from './components/Typography/fonts'
import Heading from './components/Typography/heading'
import lineHeights from './components/Typography/lineheight'
import Text from './components/Typography/text'
import sizes from './foundations/sizes'

const theme = extendTheme(
  {
    styles,
    colors,
    fonts,
    fontSizes,
    lineHeights,
    breakpoints,
    sizes,
    /* Other foundational style overrides go here */
    components: {
      Text,
      Heading,
      Button,
      Checkbox,
      Radio,
      Switch,
      Input,
      Select,
      Link,
      Textarea,
      Container
    }
  },
  withDefaultColorScheme({ colorScheme: 'teal' })
)

type Theme = typeof theme

export type { Theme }
export default theme
