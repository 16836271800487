import { Box } from '@chakra-ui/react'
import { ArrowIcon } from 'icons'
import { MouseEventHandler } from 'react'
import { Button } from '../../buttons'
import {
  CAROUSEL_INDEX_CONSTANTS,
  NavigationType
} from './EmotionCardsCarousel'

type UpdateCurrentSlide = (index: number) => void
interface NextNavigationDisabled {
  numberOfSlides: number
  currentSlide: number
  slidesInView: number
}
interface EmotionCardCarouselNavigationButtons {
  numberOfSlides: number
  currentSlide: number
  slidesInView: number
  updateCurrentSlide: UpdateCurrentSlide
}
interface EmotionCardCarouselButton {
  navigationType: NavigationType
  disabled: boolean
  onClickHandler?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
}

interface HandleNavigationNextClick {
  currentSlide: number
  numberOfSlides: number
  slidesInView: number
  updateCurrentSlide: UpdateCurrentSlide
}
interface HandleNavigationPreviousClick {
  currentSlide: number
  updateCurrentSlide: UpdateCurrentSlide
}

export const hasNextSlide = ({
  numberOfSlides,
  currentSlide,
  slidesInView
}: NextNavigationDisabled) =>
  Boolean(numberOfSlides - currentSlide - slidesInView)

const handleNavigationNextClick = ({
  currentSlide,
  numberOfSlides,
  slidesInView,
  updateCurrentSlide
}: HandleNavigationNextClick) => {
  if (
    !hasNextSlide({
      numberOfSlides,
      currentSlide,
      slidesInView
    })
  ) {
    return
  }

  updateCurrentSlide(currentSlide + 1)
}

const handleNavigationPreviousClick = ({
  currentSlide,
  updateCurrentSlide
}: HandleNavigationPreviousClick) => {
  if (currentSlide === CAROUSEL_INDEX_CONSTANTS.START_SLIDE) {
    return
  }

  updateCurrentSlide(currentSlide - 1)
}

const EmotionCardCarouselButton = ({
  navigationType,
  disabled,
  onClickHandler
}: EmotionCardCarouselButton) => {
  const buttonAccessibilityIdentifier = `Slider navigation: ${navigationType} item button`
  const reducedOpacityValue = 0.4

  return (
    <Button
      variant='unstyled'
      display='flex'
      justifyContent='center'
      alignItems='center'
      width={10}
      height={10}
      title={buttonAccessibilityIdentifier}
      aria-label={buttonAccessibilityIdentifier}
      cursor='pointer'
      onClick={onClickHandler}
      icon={
        <Box
          boxSize={10}
          opacity={disabled ? reducedOpacityValue : 1}
          color='deepblue.500'
          transform={
            navigationType === NavigationType.Previous ? 'rotate(-180deg)' : ''
          }
        >
          <ArrowIcon />
        </Box>
      }
    />
  )
}

const EmotionCardCarouselNavigationButtons = ({
  slidesInView,
  numberOfSlides,
  currentSlide,
  updateCurrentSlide
}: EmotionCardCarouselNavigationButtons) => {
  return (
    <>
      <EmotionCardCarouselButton
        navigationType={NavigationType.Previous}
        disabled={currentSlide === CAROUSEL_INDEX_CONSTANTS.START_SLIDE}
        onClickHandler={() =>
          handleNavigationPreviousClick({
            currentSlide,
            updateCurrentSlide
          })
        }
      />
      <EmotionCardCarouselButton
        navigationType={NavigationType.Next}
        disabled={
          !hasNextSlide({
            numberOfSlides,
            currentSlide,
            slidesInView
          })
        }
        onClickHandler={() =>
          handleNavigationNextClick({
            currentSlide,
            numberOfSlides,
            slidesInView,
            updateCurrentSlide
          })
        }
      />
    </>
  )
}

export default EmotionCardCarouselNavigationButtons
