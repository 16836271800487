import { User as Auth0User } from '@auth0/auth0-react'
import { TranslationKeys } from 'swnz/src/hooks/useTranslation'
import * as Yup from 'yup'

enum ProfileInventory {
  Course = 'Course',
  Provider = 'Provider'
}

enum ProfileType {
  Learner = 'Learner',
  Industry = 'Industry'
}

type ProfileAPIValues = {
  id: string
  authId: string
  studyOptionsToken: string
  email: string
  firstName: string
  lastName: string
  mobilePhone: string
  profileType: string
  source: string
  country: string
  countryTag: string
  countryIsoCode: string
  region: string
  regionTag: string
  city: string
  identifiers: {
    tealiumVisitorId?: string
  }
  contactDetails: {
    personType: string
    studentFirstName: string
    studentLastName: string
  }
  demographics: {
    dateOfBirth: string
    gender: string
    nationality: string
    nationalityIso: string
    prefLanguage: string
  }
  engagementJourney: {
    engagementLevel: string
    journeyStageDetail: string
    journeyStage: string
    journeyStageHigh: string
    nextBestActionId: string
    nextBestActionText: string
    sourceInformation?: string
    sourceInformationOther?: string
  }
  studyInterests: {
    areaOfStudy: string
    courseStartDate: string
    deliveryMode: string
    englishProfLevel: string
    instType: string
    levelOfStudy: string
    maxTuitionBudget: number
    nzLocalityType: string
    nzRegion: string
    nzWorkAfterStudy: boolean
    prefStartDate: string
    scholarshipSeeker: boolean
    schoolOrientation: string
    studyLevelAchieved: string
    subjectOfStudy: string
    useOfAgent?: string
    schoolAuthority?: string
    boardingFacilities?: boolean
  }
  consents: {
    inzConsent: boolean
    optIn: boolean
    privacyPolicy: boolean
  }
  industryParticipation: {
    jobTitle: string
    liaInzId: string
    orgAdmin: string
    orgLevel: string
    orgRole: string
    organizationId: string
  }
}

type ProfileAPIRegistrationModel = {
  authId: string
  email: string
  facebookId?: string
  googleId?: string
  firstName?: string
  lastName?: string
  profileType?: string
  privacyPolicy?: boolean
  inzConsent?: boolean
  optIn?: boolean
  tealiumVisitorId?: string
  tealiumSessionId?: string
}

type SWNZRegistrationAPIModel = {
  privacyPolicyAndInzConsent: boolean
  commsOptin: boolean
  sessionToken: string
  facebookId?: string
  googleId?: string
  firstName?: string
  lastName?: string
  profileType?: string
  tealiumVisitorId?: string
  tealiumSessionId?: string
}

type ProfileAPIUpdateModel = {
  authId: string
  email: string
  tealiumVisitorId?: string
  personType?: string
  areaOfStudy?: string
  dateOfBirth?: string
  deliveryMode?: string
  englishProfLevel?: string
  firstName?: string
  instType?: string
  lastName?: string
  levelOfStudy?: string
  city?: string
  region?: string
  maxTuitionBudget?: number
  mobilePhone?: string
  nationality?: string
  nationalityIso?: string
  nzLocalityType?: string
  nzRegion?: string
  nzWorkAfterStudy?: boolean
  prefLanguage?: string
  prefStartDate?: string
  scholarshipSeeker?: boolean
  schoolOrientation?: string
  sex?: string
  studentFirstName?: string
  studentLastName?: string
  studyLevelAchieved?: string
  subjectOfStudy?: string
  useOfAgent?: string
  sourceInformation?: string
  sourceInformationOther?: string
  schoolAuthority?: string
  boardingFacilities?: boolean
}

const swnzRegistrationEndpointSchema: Yup.ObjectSchema<
  Omit<SWNZRegistrationAPIModel, 'facebookId' | 'googleId'>
> = Yup.object()
  .shape({
    privacyPolicyAndInzConsent: Yup.boolean().oneOf([true]).required(),
    commsOptin: Yup.boolean().oneOf([true, false]).required(),
    sessionToken: Yup.string().required(),
    firstName: Yup.string().min(1, 'First name is too short'),
    lastName: Yup.string().min(1, 'Last name is too short'),
    inzConsent: Yup.boolean().oneOf([true, false]),
    optIn: Yup.boolean().oneOf([true, false]),
    privacyPolicy: Yup.boolean().oneOf([true, false]),
    profileType: Yup.string().oneOf(Object.values(ProfileType)),
    tealiumVisitorId: Yup.string(),
    tealiumSessionId: Yup.string()
  })
  .noUnknown(true)
  .required()

const profileRegistrationEndpointSchema: Yup.ObjectSchema<ProfileAPIRegistrationModel> =
  Yup.object()
    .shape({
      authId: Yup.string().required(),
      facebookId: Yup.string(),
      googleId: Yup.string(),
      email: Yup.string().email('Please enter a valid email').required(),
      firstName: Yup.string().min(1, 'First name is too short'),
      inzConsent: Yup.boolean().oneOf([true, false]),
      lastName: Yup.string().min(1, 'Last name is too short'),
      optIn: Yup.boolean().oneOf([true, false]),
      privacyPolicy: Yup.boolean().oneOf([true, false]),
      profileType: Yup.string().oneOf(Object.values(ProfileType)),
      tealiumVisitorId: Yup.string(),
      tealiumSessionId: Yup.string(),
      sourceInformation: Yup.string(),
      sourceInformationOther: Yup.string()
    })
    .noUnknown(true)
    .required()

const profileUpdateEndpointSchema: Yup.ObjectSchema<
  Omit<ProfileAPIUpdateModel, 'sourceInformation' | 'sourceInformationOther'>
> = Yup.object()
  .shape({
    authId: Yup.string().required(),
    email: Yup.string().required(),
    tealiumVisitorId: Yup.string(),
    personType: Yup.string(),
    areaOfStudy: Yup.string(),
    dateOfBirth: Yup.string().required(),
    deliveryMode: Yup.string(),
    englishProfLevel: Yup.string(),
    firstName: Yup.string().min(1, 'First name is too short'),
    instType: Yup.string(),
    lastName: Yup.string().min(1, 'Last name is too short'),
    levelOfStudy: Yup.string(),
    city: Yup.string(),
    region: Yup.string(),
    maxTuitionBudget: Yup.number(),
    mobilePhone: Yup.string(),
    nationality: Yup.string(),
    nationalityIso: Yup.string(),
    nzLocalityType: Yup.string(),
    nzRegion: Yup.string(),
    nzWorkAfterStudy: Yup.boolean().oneOf([true, false]),
    prefLanguage: Yup.string(),
    prefStartDate: Yup.string(),
    scholarshipSeeker: Yup.boolean().oneOf([true, false]),
    schoolOrientation: Yup.string(),
    sex: Yup.string(),
    studentFirstName: Yup.string(),
    studentLastName: Yup.string(),
    studyLevelAchieved: Yup.string(),
    subjectOfStudy: Yup.string(),
    useOfAgent: Yup.string(),
    schoolAuthority: Yup.string(),
    boardingFacilities: Yup.boolean().oneOf([true, false])
  })
  .noUnknown(true)
  .required()

export {
  ProfileType,
  swnzRegistrationEndpointSchema,
  profileRegistrationEndpointSchema,
  profileUpdateEndpointSchema,
  ProfileInventory
}
export type {
  ProfileAPIRegistrationModel,
  ProfileAPIValues,
  SWNZRegistrationAPIModel,
  ProfileAPIUpdateModel
}

export const mapInitialEnquiryFormValues = (
  user: Auth0User | null,
  t: (value: TranslationKeys['forms']) => string | undefined
) => {
  const personType = user?.profileType?.toLowerCase() ?? ''
  const dateOfBirth =
    user?.demographics?.dateOfBirth && new Date(user.demographics.dateOfBirth)

  const prefStartDate = user?.studyInterests?.prefStartDate
    ? new Date(user.studyInterests.prefStartDate)
    : ''

  return {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    email: user?.email ?? '',
    mobilePhone: user?.mobilePhone ?? '',
    nationality:
      user?.demographics?.nationality ?? t('nationality.defaultValue') ?? '',
    personType: personType,
    sourceInformation: user?.sourceInformation ?? '',
    sourceInformationOther:
      user?.engagementJourney?.sourceInformationOther ?? '',
    areaOfStudy: user?.studyInterests?.areaOfStudy ?? '',
    levelOfStudy: user?.studyInterests?.levelOfStudy ?? '',
    dateOfBirth: dateOfBirth,
    prefLanguage: user?.demographics?.prefLanguage ?? '',
    prefStartDate: prefStartDate,
    useOfAgent: ''
  }
}
