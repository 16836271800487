import { IMAGE_SIZE_CONFIG } from 'utils'
import { OptimisedImage, OptimisedImageProps } from '../../../components'
import { AlternatingQuoteDisplayType } from '../../../components/content-components'

const validateDisplayType = (
  mediaOrientation: string | null,
  mediaPlacement: string | null
): AlternatingQuoteDisplayType => {
  const orientation = mediaOrientation
    ? mediaOrientation.toUpperCase()
    : 'PORTRAIT'
  const placement = mediaPlacement ? mediaPlacement.toUpperCase() : 'LEFT'
  const displayType = `${orientation}_${placement}`

  const orientationPlacement: {
    [key: string]: AlternatingQuoteDisplayType
  } = {
    PORTRAIT_LEFT: AlternatingQuoteDisplayType.PortraitLeft,
    PORTRAIT_RIGHT: AlternatingQuoteDisplayType.PortraitRight,
    LANDSCAPE_LEFT: AlternatingQuoteDisplayType.LandscapeLeft,
    LANDSCAPE_RIGHT: AlternatingQuoteDisplayType.LandscapeRight
  }

  return orientationPlacement[displayType]
}

const createMediaImageProps = (mediaItem: any) => {
  const imageComponentProps: OptimisedImageProps = {
    src: mediaItem?.source?.url || '',
    alt: mediaItem?.source?.description || '',
    title: mediaItem?.source?.title || '',
    imageSizeConfig: IMAGE_SIZE_CONFIG.ALTERNATING_CONTENT
  }

  return { image: OptimisedImage(imageComponentProps) }
}

export { validateDisplayType, createMediaImageProps }
