import {
  Box,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Spacer,
  Text
} from '@chakra-ui/react'
import { ChevronCircle } from 'icons'
import React, { useState } from 'react'
import { Breadcrumb } from '.'
import { Link } from '../../Link'
import NavItem from '../NavItem'
import { useNavigation } from '../NavWrapper'

interface SecondaryNavContentProps {
  navigateToCrumb: (id: string) => void
  navigateToMainNav: () => void
  current: Breadcrumb
  getCrumb: (id: string) => Breadcrumb | undefined
}

export const SecondaryNavContent: React.FC<SecondaryNavContentProps> = ({
  getCrumb,
  current,
  navigateToCrumb,
  navigateToMainNav
}) => {
  const parent = current?.parent
    ? getCrumb(current.parent)
    : { parent: null, childNodes: [] }
  const linkProps = {
    href: current.slug,
    textDecoration: 'none',
    color: 'white',
    _hover: {
      color: 'navHover'
    }
  }

  const { onClose } = useNavigation()

  const [secondaryNavSate, setSecondaryNavSate] = useState<boolean>(true)

  const navigateToMain = () => {
    setSecondaryNavSate(false)
    setTimeout(() => {
      navigateToMainNav()
    }, 150)
  }

  return (
    <>
      <Drawer
        isOpen={secondaryNavSate}
        onClose={onClose}
        placement='right'
        onOverlayClick={onClose}
      >
        <DrawerOverlay bg='transparent' />
        <DrawerContent
          bg='deepblue.500'
          color='white'
          top={{ base: '60px !important', xl: '92px !important' }}
        >
          <DrawerBody p='unset'>
            <Flex
              align='center'
              p={1}
              pb={6}
              borderBottom='1px solid rgba(255, 255, 255, 0.1)'
            >
              <Flex
                data-testid='backwards-navigation'
                align='center'
                onClick={() =>
                  parent?.parent ? navigateToCrumb(parent.id) : navigateToMain()
                }
              >
                <Box
                  w={7}
                  h={7}
                  ml={5}
                  transform='rotate(180deg)'
                  _hover={{
                    background: 'white',
                    color: 'deepblue.600',
                    cursor: 'pointer',
                    borderRadius: '50%',
                    transition: 'background-color, color 0.15s ease-out'
                  }}
                  sx={{
                    '&:hover': {
                      path: {
                        _first: {
                          fill: 'white'
                        },
                        _last: {
                          fill: 'deepblue.600'
                        }
                      }
                    },
                    'html[lang=ar] &': {
                      /* GOTCHA!
                       * This is acutally align 'left'
                       * stylis RTL plugin changes this to 'left' for us */
                      marginRight: 5,
                      marginLeft: 5,
                      svg: {
                        transform: 'scaleX(-1)'
                      }
                    }
                  }}
                >
                  <ChevronCircle />
                </Box>
                <Flex
                  flexDir='column'
                  ml={4}
                  sx={{
                    'html[lang=ar] &': {
                      /* GOTCHA!
                       * This is acutally align 'left'
                       * stylis RTL plugin changes this to 'left' for us */
                      marginLeft: '0',
                      marginRight: '4'
                    }
                  }}
                >
                  <Text lineHeight='1.25' fontSize='14px' mb={0}>
                    Back to
                  </Text>
                  <Text lineHeight='1.25' mb={0}>
                    {parent?.parent ? parent.title : 'Main menu'}
                  </Text>
                </Flex>
              </Flex>
              <Spacer />
            </Flex>

            <Flex flexDir='column' p={8}>
              <Text fontFamily='enz700'>
                <Link {...linkProps}>{current.title}</Link>
              </Text>
              {current.childNodes.map((child) => (
                <Flex data-testid='forwards-navigation' key={child}>
                  <NavItem
                    link={{ ...getCrumb(child) }}
                    callback={() => navigateToCrumb(child)}
                  />
                </Flex>
              ))}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}
