import { useAuth0 } from '@auth0/auth0-react'
import { StudyOptionItemType, StudyOptionStatus } from 'ui'
import { getStudyOptions, useUser } from '../context'
import { updateInventoryStatus } from '../helpers/sdClient'

function useUpdateInventoryItem(onSaveModalOpen?: () => void) {
  const {
    userState: { profile },
    userDispatch,
  } = useUser()
  const { getAccessTokenSilently } = useAuth0()

  async function handleUpdateItem(itemId: string, itemType: StudyOptionItemType) {
    try {
      onSaveModalOpen && onSaveModalOpen()
      const accessToken = await getAccessTokenSilently()

      // Update the item
      await updateInventoryStatus(profile, accessToken, {
        itemId,
        itemType,
        status: StudyOptionStatus.Saved,
      })
      await getStudyOptions(userDispatch, profile, accessToken)
    } catch (error) {
      console.log(error)
    }
  }

  return handleUpdateItem
}

export default useUpdateInventoryItem
