import { Box, FormErrorMessage, Text, VStack } from '@chakra-ui/react'
import { captureException } from '@sentry/nextjs'
import { Formik } from 'formik'
import { WarningRoundedIcon } from 'icons'
import { useRouter } from 'next/router'
import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  AccountCommsOptIn,
  AccountPrivacyAndINZConsent,
  Button,
  CheckboxControl,
  Form,
  FormAgreements,
} from 'ui'
import * as Yup from 'yup'

interface UserConsentFormValues {
  privacyPolicyAndInzConsent: boolean
  commsOptin: boolean
}

const REQUIRED_CHECKBOX_MESSAGE =
  'To finish creating your Study with New Zealand account and to get your personalised recommendations you must check this box.'

const userConsentFormSchema: Yup.ObjectSchema<UserConsentFormValues> = Yup.object()
  .shape({
    privacyPolicyAndInzConsent: Yup.boolean().oneOf([true]).defined(),
    commsOptin: Yup.boolean().defined(),
  })
  .noUnknown(true)
  .required()

export default function UserConsentForm() {
  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const router = useRouter()
  const [submissionError, setSubmissionError] = useState(false)
  const { state, session_token, access_token } = router.query

  function getAdditionalFields() {
    if (typeof window === 'undefined') {
      return {}
    }

    return {
      sessionToken: session_token,
      tealiumVisitorId: window?.utag?.data?.tealium_visitor_id || '',
      tealiumSessionId: window?.utag?.data?.tealium_session_id || '',
    }
  }

  async function handleFormSubmit(values: UserConsentFormValues) {
    const authContinueUrl = `https://${
      process.env.NEXT_PUBLIC_AUTH0_DOMAIN ?? ''
    }/continue?state=${state}`

    const additionalFields = getAdditionalFields()

    try {
      const res = await fetch(`${process.env.NEXT_PUBLIC_URL}/api/profile/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${access_token}`,
        },
        body: JSON.stringify({ ...additionalFields, ...values }),
      })

      const response = await res.json()

      if (response.status !== 200) {
        setSubmissionError(true)
        captureException(new Error(`API Submission Error`), {
          extra: {
            'Form Name': 'User Consent Form',
            'API Response': response,
          },
        })

        return
      }

      window.location.href = authContinueUrl
    } catch (error) {
      setSubmissionError(true)
      if (error instanceof Error) {
        captureException(error, {
          extra: {
            'Form Name': 'User Consent Form',
          },
        })
      }
    }
  }

  return (
    <>
      {!submissionError && (
        <Formik
          initialValues={{
            privacyPolicyAndInzConsent: false,
            commsOptin: false,
          }}
          validationSchema={userConsentFormSchema}
          onSubmit={handleFormSubmit}
        >
          {({ isValid, isSubmitting, dirty }) => (
            <Form>
              <VStack spacing={6} alignItems="flex-start">
                <CheckboxControl
                  mt={1.5}
                  mr={2}
                  name="privacyPolicyAndInzConsent"
                  data-testid="user-consent-privacy"
                  extendedText={{
                    height: 90,
                    label: 'See',
                  }}
                  errorMessage={
                    <FormErrorMessage color="red.600" mt={4} alignItems="flex-start">
                      <Box>
                        <Box boxSize={6} color="red.600" mr={4} mt={1}>
                          <WarningRoundedIcon />
                        </Box>
                      </Box>
                      <Text mb={0} lineHeight={1.75}>
                        {REQUIRED_CHECKBOX_MESSAGE}
                      </Text>
                    </FormErrorMessage>
                  }
                >
                  <AccountPrivacyAndINZConsent />
                </CheckboxControl>
                <CheckboxControl
                  data-testid="user-consent-comms-optin"
                  mt={1.5}
                  mr={2}
                  name="commsOptin"
                >
                  <AccountCommsOptIn />
                </CheckboxControl>
                <Box visibility="hidden">
                  {/* @ts-ignore https://github.com/dozoisch/react-google-recaptcha/issues/277 */}
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY ?? ''}
                  />
                </Box>
                <Button
                  size="sm"
                  mt={0}
                  borderRadius={0}
                  colorScheme="teal"
                  type="submit"
                  isDisabled={!(dirty && isValid) || isSubmitting}
                >
                  Activate account
                </Button>
                <FormAgreements mt={6} />
              </VStack>
            </Form>
          )}
        </Formik>
      )}
      {submissionError && <Form.ErrorContent />}
    </>
  )
}
