import { Box, Stack, Text, VStack } from '@chakra-ui/react'
import { Formik } from 'formik'
import { TickRoundedIcon } from 'icons'
import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import type { Utag } from 'tracking'
import {
  Button,
  CheckboxControl,
  Form,
  FormAgreements,
  FormINZMatchingPermissionOptIn,
  H3,
  H5,
  postLeadCaptureFormSubmissionToLeadCaptureAPIEndpoint,
  reformatDateForSubmit,
  SmallText,
  StackField,
} from 'ui'
import { formTaxonomyData } from 'utils/src/helpers/taxonomy'
import * as Yup from 'yup'
import { useUser } from '../../../context'
import { useTranslation } from '../../../hooks/useTranslation'

declare const utag: Utag

interface PathwayEnquiryFormValues {
  firstName: string
  lastName: string
  email: string
  mobile: string
  nationality?: string
  intendedLevelOfStudy: string
  desiredStartDate: Date | string
  inzMatchingPermissionOptin: boolean
}

interface PathwayEnquiryFormProps {
  heading?: string
  bodyText?: string
  successMessage?: {
    heading: string
    bodyText: string
  }
  errorMessage?: {
    heading: string
    bodyText: string
  }
}

const pathwayEnquiryFormSchema = (lang?: string): Yup.ObjectSchema<PathwayEnquiryFormValues> =>
  Yup.object()
    .shape(
      {
        firstName: Yup.string()
          .min(2, 'Too Short!')
          .max(33, 'Too Long!')
          .required('First name is required'),
        lastName: Yup.string()
          .min(2, 'Too Short!')
          .max(33, 'Too Long!')
          .required('Last name is required'),
        email: Yup.string()
          .email('Please enter a valid email')
          .required('Email address is required'),
        mobile: Yup.string()
          .min(7, 'Too Short!')
          .max(16, 'Too Long!')
          .required('Please enter a valid mobile number'),
        nationality:
          lang === 'ko' ? Yup.string() : Yup.string().required('Please choose a location'),
        desiredStartDate: Yup.lazy((value) =>
          value === ''
            ? Yup.string().defined()
            : Yup.date()
                .when('desiredStartDate', {
                  is: (val: Date) => val !== undefined && val !== null,
                  then: (rule) =>
                    rule
                      .transform((value: Date) => {
                        return value ? new Date(value) : value
                      })
                      .min(new Date(), 'Please enter a date in the future')
                      .typeError('Please enter a valid date'),
                })
                .defined()
        ),
        intendedLevelOfStudy: Yup.string().required('Please enter an intended level of study'),
        inzMatchingPermissionOptin: Yup.boolean().oneOf([true, false]).defined(),
      },
      [['desiredStartDate', 'desiredStartDate']]
    )
    .noUnknown(true)
    .required()

const PathwayEnquiryForm = ({
  heading,
  bodyText,
  successMessage,
  errorMessage,
}: PathwayEnquiryFormProps) => {
  const { t, lang } = useTranslation('forms')

  const {
    userState: { profile },
  } = useUser()

  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const [successfulSubmission, setSuccessfulSubmission] = useState(false)
  const [submissionError, setSubmissionError] = useState(false)

  const taxonomy = formTaxonomyData()

  const handleFormSubmit = async (values: PathwayEnquiryFormValues) => {
    let tealiumCookie = ''
    let tealiumSessionId = ''
    const utagData = window?.utag?.data

    if (utagData && utagData?.tealium_visitor_id?.length > 0) {
      tealiumCookie = utagData.tealium_visitor_id
    }

    if (utagData && utagData?.tealium_session_id?.length > 0) {
      tealiumSessionId = utagData.tealium_session_id
    }

    const nationality = values.nationality
    const countryItem = taxonomy?.location.find((country) => country.key == nationality)
    const isoCode = countryItem ? countryItem.iso : ''

    postLeadCaptureFormSubmissionToLeadCaptureAPIEndpoint(
      {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        mobile: values.mobile,
        nationality: values.nationality,
        intendedLevelOfStudy: values.intendedLevelOfStudy,
        privacyPolicyAgreed: true,
        inzMatchingPermissionOptin: values.inzMatchingPermissionOptin,
        desiredStartDate: values.desiredStartDate
          ? reformatDateForSubmit(values.desiredStartDate)
          : '',
        nationalityISOCode: isoCode,
        tealiumVisitorId: tealiumCookie,
        tealiumSessionId,
        commsOptin: true,
        source: 'Pathways Keep Updated Form',
        formSchemaName: 'pathwayEnquiryFormSchema',
      },
      {
        success: () => {
          setSuccessfulSubmission(true)
          if (utagData && Object.keys(utagData).length > 0) {
            utag.link({
              tealium_event: 'form_submission',
              form_type: 'lead',
              form_name: 'pop-up',
              level_of_study:
                `${values.intendedLevelOfStudy}` ||
                `${profile?.studyInterests?.levelOfStudy}` ||
                '',
              nationality: `${values.nationality}` || `${profile?.demographics?.nationality}` || '',
            })
          }
        },
        error: () => {
          setSubmissionError(true)
        },
      }
    )
  }

  return (
    <>
      {!successfulSubmission && (
        <>
          {(heading || bodyText) && (
            <VStack maxW="500px" spacing={5} mb={6} alignItems="flex-start">
              {heading && <H3 mb={0}>{heading}</H3>}
              {bodyText && <Text>{bodyText}</Text>}
            </VStack>
          )}
          <H5 mb={6}>{`Your Details:`}</H5>
          <Formik
            initialValues={{
              firstName: '',
              lastName: '',
              email: '',
              nationality: t('nationality.defaultValue') ?? '',
              mobile: '',
              intendedLevelOfStudy: '',
              desiredStartDate: '',
              privacyPolicyAgreed: true,
              inzMatchingPermissionOptin: false,
            }}
            validationSchema={pathwayEnquiryFormSchema}
            onSubmit={handleFormSubmit}
          >
            {({ dirty, isValid, isSubmitting }) => (
              <Form>
                <Stack spacing={4} mb={8} alignItems="flex-start">
                  <StackField>
                    <Form.FirstNameField />
                    <Form.LastNameField />
                  </StackField>
                  <Form.EmailAddressField />
                  <StackField>
                    <Form.PhoneNumberField
                      name="mobile"
                      isRequired
                      country={t('mobile_number.country')}
                    />
                    <Form.LevelOfStudyField
                      name="intendedLevelOfStudy"
                      levelOfStudyOptions={taxonomy.levelOfStudy}
                      isRequired
                    />
                  </StackField>
                  <StackField>
                    <Form.NationalityField
                      nationalityOptions={taxonomy.location}
                      isRequired={lang !== 'ko'}
                    />
                    <Form.StartDateField name="desiredStartDate" label="Desired start date" />
                  </StackField>
                  <CheckboxControl
                    mt={1}
                    mr={2}
                    name="inzMatchingPermissionOptin"
                    extendedText={{ label: 'Read' }}
                  >
                    <FormINZMatchingPermissionOptIn />
                  </CheckboxControl>
                  <Box visibility="hidden">
                    {/* @ts-ignore https://github.com/dozoisch/react-google-recaptcha/issues/277 */}
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY ?? ''}
                    />
                  </Box>
                  <Button
                    colorScheme="teal"
                    type="submit"
                    isDisabled={!(dirty && isValid) || isSubmitting}
                  >
                    Submit
                  </Button>
                  <FormAgreements includeAgreePolicy mt={6} />
                </Stack>
              </Form>
            )}
          </Formik>
        </>
      )}
      {successfulSubmission && successMessage && (
        <Box px={4} py={16} textAlign="center">
          <Box boxSize={16} color="success" marginBottom={4} mx="auto">
            <TickRoundedIcon />
          </Box>
          <H3>{successMessage.heading}</H3>
          <SmallText mb={3}>{successMessage.bodyText}</SmallText>
        </Box>
      )}
      {submissionError && errorMessage && (
        <Box px={4} py={16} textAlign="center">
          <H3 color="error">{errorMessage.heading}</H3>
          <SmallText color="error">{errorMessage.bodyText}</SmallText>
        </Box>
      )}
    </>
  )
}

export default PathwayEnquiryForm
export { pathwayEnquiryFormSchema }
export type { PathwayEnquiryFormValues }
