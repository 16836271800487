import ALGOLIA_INDICES from './algolia'
import ALGOLIA_FILTER_MAPS from './algoliaFilterMaps'
import { Actions, Statuses } from './apiReducer'
import AUTH from './auth'
import CLAIM from './claims'
import CONTENT_SERVICE from './contentService'
import COOKIE from './cookie'
import DEFAULT_SEO from './defaultSeo'
import { PopularSearchType } from './popularSearchItems'
import ROUTES from './routes'
import SD_SERVICES from './sdServices'
import SITE_CONFIG from './siteConfig'
import SLUG, { SEARCH_RESULTS_PAGE_SLUG_MAP, SUPRESS_POPUP_SLUGS } from './slug'
import SUPPORTED_LANGUAGES from './supportedLanguages'

export {
  ALGOLIA_INDICES,
  ALGOLIA_FILTER_MAPS,
  AUTH,
  ROUTES,
  SITE_CONFIG,
  SLUG,
  SUPPORTED_LANGUAGES,
  DEFAULT_SEO,
  SD_SERVICES,
  COOKIE,
  SEARCH_RESULTS_PAGE_SLUG_MAP,
  CLAIM,
  Actions,
  Statuses,
  CONTENT_SERVICE,
  SUPRESS_POPUP_SLUGS,
}
export type { PopularSearchType }
