import { mode } from '@chakra-ui/theme-tools'

type Dict = Record<string, any>

function baseStyle(props: Dict) {
  const { colorScheme: c } = props
  return {
    textDecoration: 'underline',
    fontFamily: 'enz500',
    color: mode(`${c}.600`, `${c}.500`)(props),
    _hover: {
      color: mode(`${c}.700`, `${c}.600`)(props)
    }
  }
}

export default {
  baseStyle
}
