import { getColor, mode } from '@chakra-ui/theme-tools'

const parts = ['field', 'addon']

const baseStyle = {
  field: {
    bg: 'teal.200',
    width: '100%',
    minWidth: 0,
    boxShadow: 'none',
    outline: 0,
    position: 'relative',
    appearance: 'none',
    transition: 'all 0.2s'
  }
}

const size = {
  md: {
    fontSize: 'md',
    px: 4,
    h: '60px',
    borderRadius: '4px'
  }
}

const sizes = {
  md: {
    field: size.md,
    addon: size.md
  }
}

function getDefaults(props: Record<string, any>) {
  const { focusBorderColor: fc, errorBorderColor: ec } = props
  return {
    focusBorderColor: fc || mode('teal.500', 'teal.300')(props),
    errorBorderColor: ec || mode('red.400', 'red.500')(props)
  }
}

function variantFilled(props: Record<string, any>) {
  const { theme } = props
  const { focusBorderColor: fc, errorBorderColor: ec } = getDefaults(props)

  return {
    field: {
      border: '2px solid',
      borderColor: 'transparent',
      color: 'deepblue.300',
      bg: mode('teal.100', 'whiteAlpha.50')(props),
      _hover: {
        bg: mode('teal.200', 'whiteAlpha.100')(props),
        color: 'deepblue.500'
      },
      _readOnly: {
        boxShadow: 'none !important',
        userSelect: 'all'
      },
      _disabled: {
        background: 'deepblue.100',
        cursor: 'not-allowed'
      },
      _invalid: {
        borderColor: getColor(theme, ec),
        color: getColor(theme, ec)
      },
      _focus: {
        background: 'teal.100',
        borderColor: getColor(theme, fc),
        _placeholder: {
          opacity: '0'
        }
      }
    },
    addon: {
      border: '2px solid',
      borderColor: 'transparent',
      bg: mode('gray.100', 'whiteAlpha.50')(props)
    }
  }
}

const variants = { filled: variantFilled }

const defaultProps = {
  size: 'md',
  variant: 'filled'
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parts,
  baseStyle,
  sizes,
  variants,
  defaultProps
}
