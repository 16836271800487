// https://egghead.io/lessons/react-detect-user-activity-with-a-custom-useidle-react-hook
import type {
  ActivityDetectorOptions,
  ActivityDetectorResult
} from 'activity-detector'
import { useEffect, useState } from 'react'

function useIdle(options: ActivityDetectorOptions) {
  const [running, setRunning] = useState(false)
  const [isIdle, setIsIdle] = useState(false)

  useEffect(() => {
    let activityDetector: ActivityDetectorResult | undefined
    async function init() {
      if (typeof window !== 'undefined' && running) {
        const { default: createActivityDetector } = await import(
          'activity-detector'
        )

        activityDetector = createActivityDetector(options)

        if (running) {
          activityDetector.on('idle', () => setIsIdle(true))
          activityDetector.on('active', () => setIsIdle(false))
        } else {
          activityDetector.stop()
        }
      }
    }

    init()

    return () => activityDetector?.stop()
  }, [options, running])

  return { isIdle, running, setRunning }
}

export default useIdle
