import { Box, Skeleton } from '@chakra-ui/react'

interface BilibiliPlayerProps {
  videoUrl: string
  autoplay?: boolean
}

export function getBilibiliVideoDetails(url: string) {
  // Match the bvid parameter
  let matches = url.match(/[?&]bvid=([a-zA-Z0-9]+)/)
  const bvid = matches ? matches[1] : null

  // Match the aid parameter
  matches = url.match(/[?&]aid=(\d+)/)
  const aid = matches ? matches[1] : null

  matches = url.match(/[?&]cid=(\d+)/)
  const cid = matches ? matches[1] : null

  // Return an object with bvid or aid and cid
  return { bvid, aid, cid }
}

function BilibiliPlayer({ videoUrl }: BilibiliPlayerProps) {
  const { bvid, aid, cid } = getBilibiliVideoDetails(videoUrl)

  if (!bvid && !aid) return null

  //If they want bilbili.com
  const url = `https://player.bilibili.com/player.html?isOutside=true&${
    bvid ? `bvid=${bvid}` : `aid=${aid}`
  }${cid ? `&cid=${cid}` : ''}`

  return (
    <Box position='relative' width='100%' height='100%'>
      <Skeleton
        isLoaded={false}
        position='absolute'
        top='0'
        left='0'
        width='100%'
        height='100%'
      />
      <Box
        as='iframe'
        src={url}
        allowFullScreen={true}
        allow='autoplay'
        position='absolute'
        top='0'
        left='0'
        width='100%'
        height='100%'
        border='none'
      />
    </Box>
  )
}

export default BilibiliPlayer
