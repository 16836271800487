/**
 * connectDependentRefinementList
 *
 * A modified version of connectRefinementList to create a facet that depends on another facet.
 * The items returned will be a filtered list dependent on the fields in the independent facet.
 * This component expects format of the facet attribute to be as follows: attribute: "independentFacetValue > dependantFacetValue"
 *
 * e.g.
 * ## Input
 * const independentFacetValues = ["New Zealand"]
 * const dependantFacetValues = ["New Zealand > Christchurch", "Australia > New South Wales"]
 *
 * ## Output
 * items = [{
 *  label: Christchurch
 * }]
 *
 * https://github.com/algolia/react-instantsearch/blob/master/packages/react-instantsearch-core/src/connectors/connectRefinementList.js
 *
 * Unfortunately this isn't already typed so I had a go. I found that some types didn't seem to line up with other functions so you will
 * see a few @ts-ignores sprinkled around the place.
 *
 * Changes from RefinementList list are:
 *  - Attempt at typing
 *  - Add function `filterByIndependentAttribute`
 *  - Pull functionality out which gets items into it's own function `getItems`
 */

import { SearchResults } from 'algoliasearch-helper'
import {
  BasicDoc,
  ConnectorSearchResults,
  createConnector,
  RefinementListExposed,
  RefinementListProvided,
  SearchState as InstantSearchState
} from 'react-instantsearch-core'
import { isPresent } from 'ts-is-present'
import {
  cleanUpValue,
  getCurrentRefinementValue,
  getIndexId,
  getResults,
  InstantSearchContext,
  InstantSearchIndexContext,
  refineValue
} from '../helpers/indexUtils'

export interface DependentRefinementListExposed extends RefinementListExposed {
  independentAttribute: string
  defaultRefinement?: string[]
  facetOrdering?: boolean
  contextValue?: InstantSearchContext
  indexContextValue?: { targetedIndex: string }
}

export interface DependentRefinementListProvided
  extends Pick<
    RefinementListProvided,
    'currentRefinement' | 'canRefine' | 'isFromSearch' | 'refine' | 'items'
  > {
  independentCurrentRefinement: string[]
  searchable?: boolean | undefined
}

export type Refinement = string | string[]

const namespace = 'refinementList'

function getId(props: DependentRefinementListExposed) {
  return props.attribute
}

function getCurrentRefinement(
  props: DependentRefinementListExposed,
  searchState: InstantSearchState,
  context: InstantSearchIndexContext
): string[] {
  const currentRefinement = getCurrentRefinementValue(
    //@ts-ignore
    props,
    searchState,
    context,
    `${namespace}.${getId(props)}`,
    []
  )

  if (typeof currentRefinement !== 'string') {
    return currentRefinement
  }

  if (currentRefinement) {
    return [currentRefinement]
  }

  return []
}

function getValue(
  name: string,
  props: DependentRefinementListExposed,
  searchState: InstantSearchState,
  context: InstantSearchIndexContext
) {
  const currentRefinement = getCurrentRefinement(props, searchState, context)
  const isAnewValue = currentRefinement.indexOf(name) === -1

  const nextRefinement = isAnewValue
    ? currentRefinement.concat([name]) // cannot use .push(), it mutates
    : currentRefinement.filter((selectedValue) => selectedValue !== name) // cannot use .splice(), it mutates

  return nextRefinement
}

function getLimit({
  showMore,
  limit,
  showMoreLimit
}: Pick<RefinementListExposed, 'showMore' | 'limit' | 'showMoreLimit'>) {
  return showMore ? showMoreLimit : limit
}

function refine(
  props: DependentRefinementListExposed,
  searchState: InstantSearchState,
  nextRefinement: Refinement,
  context: InstantSearchIndexContext
) {
  const id = getId(props)
  // Setting the value to an empty string ensures that it is persisted in
  // the URL as an empty value.
  // This is necessary in the case where `defaultRefinement` contains one
  // item and we try to deselect it. `nextSelected` would be an empty array,
  // which would not be persisted to the URL.
  // {foo: ['bar']} => "foo[0]=bar"
  // {foo: []} => ""

  // @ts-ignore
  const nextValue = {
    [id]: nextRefinement && nextRefinement.length > 0 ? nextRefinement : ''
  }
  const resetPage = true

  // @ts-ignore
  return refineValue(searchState, nextValue, context, resetPage, namespace)
}

function cleanUp(
  props: DependentRefinementListExposed,
  searchState: InstantSearchState,
  context: InstantSearchIndexContext
) {
  return cleanUpValue(searchState, context, `${namespace}.${getId(props)}`)
}

interface Item {
  label: string
  value: string[]
  count: number
  isRefined: boolean
  _highlightResult: { label: { value: string } }
}
function getItems({
  isFromSearch,
  attribute,
  searchForFacetValuesResults,
  props,
  searchState,
  results,
  facetOrdering
}: {
  isFromSearch: boolean
  attribute: string
  searchForFacetValuesResults: any
  props: any
  searchState: InstantSearchState
  results: Partial<ConnectorSearchResults | SearchResults<BasicDoc>> | null
  facetOrdering?: boolean
}): Item[] {
  return isFromSearch
    ? searchForFacetValuesResults[attribute].map((v) => ({
        label: v.value,
        value: getValue(v.value, props, searchState, {
          ais: props.contextValue,
          multiIndexContext: props.indexContextValue
        }),
        _highlightResult: { label: { value: v.highlighted } },
        count: v.count,
        isRefined: v.isRefined
      }))
    : (results &&
        results
          //@ts-ignore
          .getFacetValues(attribute, { sortBy, facetOrdering })
          ?.map((v) => ({
            label: v.name,
            value: getValue(v.name, props, searchState, {
              ais: props.contextValue,
              multiIndexContext: props.indexContextValue
            }),
            count: v.count,
            isRefined: v.isRefined
          }))) ??
        []
}

function filterByIndependentAttribute(
  independentCurrentRefinement: string[],
  items: Item[]
): Item[] {
  const filteredItems = items
    .map((item) => {
      const [independentAttributeValue, label] = item.label.split(' > ')
      const selectedIndependentAttributeValue =
        independentCurrentRefinement.includes(independentAttributeValue)
      return selectedIndependentAttributeValue
        ? { ...item, label: label }
        : undefined
    })
    .filter(isPresent)

  return filteredItems
}
/**
 * connectRefinementList connector provides the logic to build a widget that will
 * give the user the ability to choose multiple values for a specific facet.
 * @name connectDependentRefinementList
 * @kind connector
 * @requirements The attribute passed to the `attribute` prop must be present in "attributes for faceting"
 * on the Algolia dashboard or configured as `attributesForFaceting` via a set settings call to the Algolia API.
 * @propType {string} attribute - the name of the attribute in the record
 * @propType {boolean} [searchable=false] - allow search inside values
 * @propType {string} [operator=or] - How to apply the refinements. Possible values: 'or' or 'and'.
 * @propType {boolean} [showMore=false] - true if the component should display a button that will expand the number of items
 * @propType {number} [limit=10] - the minimum number of displayed items
 * @propType {number} [showMoreLimit=20] - the maximun number of displayed items. Only used when showMore is set to `true`
 * @propType {string[]} defaultRefinement - the values of the items selected by default. The searchState of this widget takes the form of a list of `string`s, which correspond to the values of all selected refinements. However, when there are no refinements selected, the value of the searchState is an empty string.
 * @propType {function} [transformItems] - Function to modify the items being displayed, e.g. for filtering or sorting them. Takes an items as parameter and expects it back in return.
 * @providedPropType {function} refine - a function to toggle a refinement
 * @providedPropType {function} createURL - a function to generate a URL for the corresponding search state
 * @providedPropType {string[]} currentRefinement - the refinement currently applied
 * @providedPropType {array.<{count: number, isRefined: boolean, label: string, value: string}>} items - the list of items the RefinementList can display.
 * @providedPropType {function} searchForItems - a function to toggle a search inside items values
 * @providedPropType {boolean} isFromSearch - a boolean that says if the `items` props contains facet values from the global search or from the search inside items.
 * @providedPropType {boolean} canRefine - a boolean that says whether you can refine
 */

const sortBy = ['isRefined', 'count:desc', 'name:asc']
const connectDependentRefinementList: any = createConnector<
  DependentRefinementListProvided,
  DependentRefinementListExposed
>({
  displayName: 'DependentRefinementList',

  defaultProps: {
    operator: 'or',
    showMore: false,
    limit: 10,
    showMoreLimit: 20,
    facetOrdering: true
  },
  //@ts-ignore
  getProvidedProps(
    props,
    searchState,
    searchResults,
    _,
    searchForFacetValuesResults
  ) {
    const {
      independentAttribute,
      attribute,
      searchable,
      indexContextValue,
      facetOrdering
    } = props

    const results = getResults(searchResults, {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    })

    const canRefine =
      //@ts-ignore
      Boolean(results) && Boolean(results.getFacetByName(attribute))

    const isFromSearch = Boolean(
      searchForFacetValuesResults &&
        searchForFacetValuesResults[attribute] &&
        searchForFacetValuesResults.query !== ''
    )

    // Search For Facet Values is not available with derived helper (used for multi index search)
    if (searchable && indexContextValue) {
      throw new Error(
        'react-instantsearch: searching in *List is not available when used inside a' +
          ' multi index context'
      )
    }

    if (!canRefine) {
      return {
        independentCurrentRefinement: [],
        items: [],
        currentRefinement: getCurrentRefinement(props, searchState, {
          ais: props.contextValue,
          multiIndexContext: props.indexContextValue
        }),
        canRefine,
        isFromSearch,
        searchable
      }
    }

    const independentCurrentRefinement =
      searchState?.refinementList?.[independentAttribute] ?? []

    const items = filterByIndependentAttribute(
      independentCurrentRefinement,
      getItems({
        isFromSearch,
        attribute,
        searchForFacetValuesResults,
        props,
        searchState,
        results,
        facetOrdering
      })
    )

    const transformedItems = props.transformItems
      ? props.transformItems(items)
      : items

    return {
      searchState,
      independentCurrentRefinement,
      items: transformedItems.slice(0, getLimit(props)),
      currentRefinement: getCurrentRefinement(props, searchState, {
        ais: props.contextValue,
        multiIndexContext: props.indexContextValue
      }),
      isFromSearch,
      searchable,
      canRefine: transformedItems.length > 0
    }
  },
  //@ts-ignore
  refine(props, searchState, nextRefinement) {
    return refine(props, searchState, nextRefinement, {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    })
  },
  //@ts-ignore
  searchForFacetValues(props, _, nextRefinement) {
    return {
      facetName: props.attribute,
      query: nextRefinement,
      maxFacetHits: getLimit(props)
    }
  },

  cleanUp(props, searchState) {
    return cleanUp(props, searchState, {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    })
  },

  getSearchParameters(searchParameters, props, searchState) {
    const { attribute, operator } = props

    const addKey = operator === 'and' ? 'addFacet' : 'addDisjunctiveFacet'
    const addRefinementKey = `${addKey}Refinement`

    searchParameters = searchParameters.setQueryParameters({
      maxValuesPerFacet: Math.max(
        searchParameters.maxValuesPerFacet || 0,
        getLimit(props) || 0
      )
    })

    searchParameters = searchParameters[addKey](attribute)

    return getCurrentRefinement(props, searchState, {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
      //@ts-ignore
    }).reduce(
      (res, val) => res[addRefinementKey](attribute, val),
      searchParameters
    )
  },

  getMetadata(props, searchState) {
    const id = getId(props)
    const context = {
      ais: props.contextValue,
      multiIndexContext: props.indexContextValue
    }

    return {
      id,
      index: getIndexId(context),
      items:
        getCurrentRefinement(props, searchState, context).length > 0
          ? [
              {
                attribute: props.attribute,
                label: `${props.attribute}: `,
                currentRefinement: getCurrentRefinement(
                  props,
                  searchState,
                  context
                ),
                value: (nextState: InstantSearchState) =>
                  refine(props, nextState, [], context),
                items: getCurrentRefinement(props, searchState, context).map(
                  (item) => ({
                    label: `${item}`,
                    value: (nextState: InstantSearchState) => {
                      const nextSelectedItems = getCurrentRefinement(
                        props,
                        nextState,
                        context
                      ).filter((other) => other !== item)
                      //@ts-ignore
                      return refine(
                        props,
                        searchState,
                        nextSelectedItems,
                        context
                      )
                    }
                  })
                )
              }
            ]
          : []
    }
  }
})

export default connectDependentRefinementList
