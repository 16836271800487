const FORM_CONSTANTS = {
  LEAD_CAPTURE: {
    HIDE_POPUP_COOKIE: 'hideLeadPopup',
    SUCCESS_MESSAGE: {
      heading: "You're in the loop!",
      bodyText:
        "You'll receive the latest information on studying with New Zealand."
    },
    ERROR_MESSAGE: {
      heading: 'Error',
      bodyText: 'There was an issue with the submission.'
    }
  },
  FAIR_FORM: {
    SUCCESS_MESSAGE: {
      heading: 'Thanks for registering, we will be in touch soon.',
      bodyText: ''
    },
    ERROR_MESSAGE: {
      heading: 'Error',
      bodyText: 'There was an issue with the submission.'
    }
  },
  ENQUIRY: {
    HEADING: 'Want to find out more?',
    BODY_TEXT:
      'We just need a few more details to help get the right answers for you.',
    SUCCESS_MESSAGE: {
      heading: 'Enquiry submitted!',
      bodyText:
        'Your enquiry has been submitted successfully. A copy of your enquiry has also been sent to your email and you can now find this item listed in your enquired list.',
      closeButtonLabel: 'Okay, got it',
      viewButtonLabel: 'View enquiries'
    },
    ERROR_MESSAGE: {
      heading: 'Error',
      bodyText: 'There was an issue with the submission.'
    }
  },
  SAVED: {
    heading: 'This item has been added to your favourites!',
    bodyText:
      'Visit your dashboard to view all your favourites and update your profile to view your personal study recommendations.',
    dashboardButton: 'Go to dashboard',
    continueButton: 'Continue browsing'
  },
  SIGN_UP: {
    heading: 'Sign in or register today',
    bodyText:
      'With your Study with New Zealand account, you can easily find the right study option for you.',
    secondaryText:
      'View recommended study options personally matched to you, save your favourites and keep track of all your enquiries.',
    registerButton: 'Register',
    signInButton: 'Sign in'
  },
  PROFILE: {
    HEADING: 'Profile',
    BODY_TEXT:
      'Tell us a bit more about yourself to get personalised recommendations just for you.'
  }
}

const FORM_COMPONENTS = {
  LEAD_CAPTURE: 'LeadCaptureForm',
  COURSE_ENQUIRY: 'CourseEnquiryForm',
  SCHOLARSHIP_ENQUIRY: 'ScholarshipEnquiryForm',
  SCHOOL_ENQUIRY: 'SchoolEnquiryForm',
  PROVIDER_ENQUIRY: 'ProviderEnquiryForm'
}

export { FORM_CONSTANTS, FORM_COMPONENTS }
