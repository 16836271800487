import {
  TileContainer_Swnz_TileContainerFragment,
  TileContainer_Swnz_TileContainerFragmentDoc
} from 'content-service'
import { ReactElement } from 'react'
import { Tiles } from 'ui'
import useFragment from 'utils/src/hooks/useFragment'
import { Connector } from '../types'
import { createTilesToRenderData } from './helpers'

export type TilesConnectProps = Connector

const TilesConnect = ({
  id,
  typename,
  leadCaptureFormId
}: TilesConnectProps): ReactElement | null => {
  const tileItems = useFragment<TileContainer_Swnz_TileContainerFragment>({
    id,
    typename,
    fragment: TileContainer_Swnz_TileContainerFragmentDoc,
    fragmentName: 'tileContainer_SWNZ_TileContainer'
  })

  if (!tileItems) {
    return null
  }

  const {
    title = null,
    columns,
    introText = null,
    tilesCollection
  } = tileItems || {}
  const { items = [] } = tilesCollection || {}

  const tilesToRenderArray = createTilesToRenderData(items)

  return (
    <Tiles
      isLoaded={Boolean(tilesToRenderArray.length)}
      title={title}
      columns={parseInt(`${columns}`)}
      tilesToRenderArray={tilesToRenderArray}
      introText={introText}
      leadCaptureFormId={leadCaptureFormId}
    />
  )
}

export default TilesConnect
