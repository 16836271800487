import { Container } from '@chakra-ui/react'
import { ReactElement, ReactNode } from 'react'

interface EntryBlockquoteProps {
  children: ReactNode
}

const EntryBlockquote = ({ children }: EntryBlockquoteProps): ReactElement => {
  return (
    <Container
      borderLeftWidth={'3px'}
      borderColor='teal.500'
      paddingLeft={{ base: 5, md: 5, lg: 5 }}
      paddingRight={{ base: 0, md: 0, lg: 0 }}
    >
      {children}
    </Container>
  )
}

export default EntryBlockquote
export type { EntryBlockquoteProps }
