import { Box, HStack } from '@chakra-ui/react'
import {
  Swnz_NavigationElement,
  useGetNavigationElementsForBreadcrumbsQuery
} from 'content-service'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import { MouseEvent, useEffect, useRef, useState } from 'react'
import { cnNavFilter } from 'utils/src/helpers/cnNavFilter'
import { createLinks } from 'utils/src/helpers/nav'
import { NavLink } from '../Nav/NavItem'
import BreadcrumbNavBackButton from './BreadcrumbNavBackButton'
import BreadcrumbNavButtons from './BreadcrumbNavButtons'
import BreadcrumbNavLinks from './BreadcrumbNavLinks'
import {
  createBreadcrumbBackButtonProps,
  createBreadcrumbNavBasePath,
  createBreadcrumbNavLinksProps
} from './helpers'

interface BreadcrumbNavProps {
  preview?: boolean
  pathArray?: string[]
}

interface BreadcrumbScrollButtonAlignment {
  left: boolean
  right: boolean
}

const BreadcrumbNav = ({ pathArray = [], preview }: BreadcrumbNavProps) => {
  const sectionCodeId = createBreadcrumbNavBasePath(pathArray)
  const breadcrumbNavLinksRef = useRef<HTMLUListElement | null>(null)

  // State
  const [breadcrumbScrollButtonAlignment, setBreadcrumbScrollButtonAlignment] =
    useState<BreadcrumbScrollButtonAlignment>({ left: false, right: true })
  const [showBreadcrumbScrollButton, setShowBreadcrumbScrollButton] =
    useState<boolean>(false)

  const handleBreadcrumbScrollButtonAlignment = (scrollPosition: number) => {
    if (scrollPosition === 0) {
      setBreadcrumbScrollButtonAlignment({ left: true, right: false })
    } else {
      setBreadcrumbScrollButtonAlignment({ left: false, right: true })
    }
  }

  // Handlers
  const handleBreadcrumbScrollButtonClick = (e: MouseEvent<HTMLElement>) => {
    const { title = '' } = e.currentTarget
    const { scrollLeft = 0, scrollWidth = 0 } =
      breadcrumbNavLinksRef.current || {}

    if (title === 'scroll-breadcrumb-links-left') {
      breadcrumbNavLinksRef.current?.scrollTo({ left: 0, behavior: 'smooth' })
      setTimeout(() => handleBreadcrumbScrollButtonAlignment(scrollLeft), 500)
    }

    // 'scroll-breadcrumb-links-right'
    breadcrumbNavLinksRef.current?.scrollTo({
      left: scrollWidth,
      behavior: 'smooth'
    })
    setTimeout(() => handleBreadcrumbScrollButtonAlignment(scrollLeft), 500)
  }

  const handleShowBreadcrumbScrollButtons = () => {
    const { scrollWidth = 0, offsetWidth = 0 } =
      breadcrumbNavLinksRef.current || {}
    const elWidthGreaterThanScrollWidth: boolean = offsetWidth >= scrollWidth

    if (!elWidthGreaterThanScrollWidth) {
      setShowBreadcrumbScrollButton(true)
    }

    if (elWidthGreaterThanScrollWidth) {
      setShowBreadcrumbScrollButton(false)
    }
  }

  useEffect(() => {
    if (typeof window !== undefined) {
      handleShowBreadcrumbScrollButtons()
      window.addEventListener('resize', handleShowBreadcrumbScrollButtons)
    }

    return () => {
      window.removeEventListener('resize', handleShowBreadcrumbScrollButtons)
    }
  }, [breadcrumbNavLinksRef])

  const { locale } = useRouter()

  const { loading, data } = useGetNavigationElementsForBreadcrumbsQuery({
    variables: { codeId: sectionCodeId, preview, locale }
  })

  let navigationElements = data?.swnz_navigationElementCollection

  if (locale === 'cn' && data?.swnz_navigationElementCollection?.items) {
    navigationElements = {
      ...data.swnz_navigationElementCollection,
      items: cnNavFilter(
        data.swnz_navigationElementCollection
          .items as Array<Swnz_NavigationElement>
      )
    }
  }

  const links: NavLink[] = createLinks({
    linkItems: navigationElements
  })

  const hasParentPageLink = Boolean(
    data?.swnz_navigationElementCollection?.items[0]?.internalPageLink
  )
  const [breadcrumbNavLinks] = links

  const { t } = useTranslation('navigation')
  const destination = t('breadcrumb_nav_return_destination')

  const breadcrumbBackButtonProps = createBreadcrumbBackButtonProps(
    hasParentPageLink,
    breadcrumbNavLinks,
    destination
  )
  const breadcrumbNavLinksProps = createBreadcrumbNavLinksProps(
    pathArray,
    breadcrumbNavLinks
  )

  return (
    <Box background='deepblue.500' px={{ xl: 16 }} dir='ltr'>
      <Box height={{ base: '1px', xl: '2px' }} background='#39475F'></Box>
      <Box
        as='nav'
        role='breadcrumb-navigation'
        maxW={{ base: 'container.xl', xxl: 'none' }}
        height='60px'
        position='relative'
        marginX={{ base: 4, xl: 0 }}
      >
        {!loading && (
          <HStack
            height='100%'
            position='relative'
            width='100%'
            justifyContent='space-between'
            opacity={loading ? 0 : 1}
            transition='opacity 0.2s ease'
          >
            <BreadcrumbNavBackButton {...breadcrumbBackButtonProps} />
            <HStack
              overflowX='hidden'
              overflowY='hidden'
              whiteSpace='nowrap'
              boxSizing='content-box'
              height='full'
            >
              <BreadcrumbNavLinks
                {...breadcrumbNavLinksProps}
                ref={breadcrumbNavLinksRef}
              />
              <BreadcrumbNavButtons
                onClick={handleBreadcrumbScrollButtonClick}
                alignment={breadcrumbScrollButtonAlignment}
                show={showBreadcrumbScrollButton}
              />
            </HStack>
          </HStack>
        )}
      </Box>
    </Box>
  )
}

export default BreadcrumbNav
