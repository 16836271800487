import { Box } from '@chakra-ui/react'
import { useState } from 'react'
import { Button, ButtonProps } from '..'

export interface ExpandableButtonProps extends ButtonProps {
  label: string
}

const ExpandableButton = ({
  label,
  children,
  onClick,
  ...props
}: ExpandableButtonProps) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false)

  const handleOnClick = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <>
      {isExpanded && <Box>{children}</Box>}
      <Button
        variant='link'
        colorScheme='deepblue'
        textDecoration='underline'
        fontFamily='enz400'
        onClick={handleOnClick}
        {...props}
      >
        {isExpanded ? 'Show less' : label}
      </Button>
    </>
  )
}

export default ExpandableButton
