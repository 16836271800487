import { useAuth0 } from '@auth0/auth0-react'
import Cookies from 'js-cookie'
import router, { useRouter } from 'next/router'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { SavedModalStatusType } from 'swnz/src/components/SavedSuccessModal'
import { SignUpType } from 'swnz/src/components/SignUpModal'
import { EnquiryFormConnectProps } from 'swnz/src/connectors'
import { COOKIE, Statuses } from 'swnz/src/constants'
import { useUser } from 'swnz/src/context/user'
import { enquire } from 'swnz/src/helpers/sdClient'
import useUpdateInventoryItem from 'swnz/src/hooks/useUpdateInventoryItem'
import { formLogger } from 'utils'
import * as Yup from 'yup'
import { ProfileAPIValues } from './profileForm'

type EnquiryFormValues = {
  firstName: string
  lastName: string
  email: string
  nationality?: string
  mobilePhone?: string
  personType: string
  sourceInformation?: string
  sourceInformationOther?: string | null
  englishProfLevel?: string
  useOfAgent: string
  areaOfStudy: string
  levelOfStudy: string
  prefStartDate?: Date | string
  dateOfBirth: Date | string
  prefLanguage: string
  picked?: string
}

enum EnquiryFormType {
  Course = 'Course',
  Provider = 'Provider',
  Scholarship = 'Scholarship',
  Agent = 'Agent',
  School = 'School'
}

type FormSubmissionCallbacks = {
  success: () => void
  error: () => void
}

type EnquiryFormSubmissionData = {
  authId: string
  firstName?: string
  lastName?: string
  email: string
  mobilePhone?: string
  mobilePhoneCountry?: string
  dateOfBirth?: string
  prefLanguage?: string
  nationality?: string
  levelOfStudy?: string
  prefStartDate?: string
  englishProfLevel?: string
  useOfAgent?: string
  sourceInformation?: string
  sourceInformationOther?: string | null
  nationalityIso?: string
  enquiryQuestionId: string
  itemId: string
  itemType: string
  tealiumVisitorId?: string
  lang?: string
}

/**
 * SpeakData Profile API payload
 */
type EnquiryAPILearnerProfileModel = {
  authId: string
  email: string
  tealiumVisitorId?: string
  tealiumSessionId?: string
  firstName?: string
  lastName?: string
  levelOfStudy?: string
  areaOfStudy?: string
  prefStartDate?: string
  dateOfBirth?: string
  englishProfLevel?: string
  personType?: string
  city?: string
  region?: string
  mobilePhone?: string
  nationality?: string
  nationalityIso?: string
  prefLanguage?: string
  sex?: string
  studentFirstName?: string
  studentLastName?: string
  useOfAgent?: string
  picked?: string
}

/**
 * SpeakData Enquiry API payload
 */
type EnquiryAPIEnquiryModel = {
  enquiryQuestionId: string
  enquiryQuestionText: string
  itemId: string
  itemType: string
  tealiumVisitorId?: string
  tealiumSessionId?: string
}

/**
 * Payload for the internal Enquiry API endpoint
 */
type EnquiryAPIEndpointPayload = {
  /**
   * The learners profile data to send to SpeakData
   */
  learner: EnquiryAPILearnerProfileModel
  /**
   * The learners enquiry data to send to SpeakData
   */
  enquiry: EnquiryAPIEnquiryModel
  lang?: string
}

type Learner = Omit<
  EnquiryFormValues,
  'sourceInformation' | 'sourceInformationOther'
> &
  Pick<EnquiryAPILearnerProfileModel, 'nationalityIso'> &
  Pick<EnquiryAPIEnquiryModel, 'tealiumVisitorId'>

type Enquiry = Omit<
  EnquiryAPIEnquiryModel,
  'tealiumVisitorId' | 'tealiumSessionId'
>

type EnquiryEndpointValues = { learner: Learner; enquiry: Enquiry }

const enquiryEndpointSchema = (
  lang?: string
): Yup.ObjectSchema<EnquiryEndpointValues> =>
  Yup.object()
    .shape({
      learner: Yup.object().shape(
        {
          authId: Yup.string(),
          tealiumVisitorId: Yup.string(),
          firstName: Yup.string()
            .min(2, 'First name is too short')
            .max(33, 'First name is too long')
            .required('First name is required'),
          lastName: Yup.string()
            .min(2, 'Last name is too short')
            .max(33, 'Last name is too long')
            .required('Last name is required'),
          email: Yup.string()
            .email('Please enter a valid email')
            .required('Email address is required'),
          mobilePhone: Yup.string().when('mobilePhone', {
            is: (val: string) => val !== '',
            then: (rule) => rule.min(7, 'Too Short!').max(16, 'Too Long!')
          }),
          dateOfBirth: Yup.string().required('Date of birth is required'),
          personType: Yup.string().required('Person type is required'),
          nationality:
            lang === 'ko'
              ? Yup.string()
              : Yup.string().required('Nationality is required'),
          nationalityIso:
            lang === 'ko'
              ? Yup.string()
              : Yup.string().required('Nationality ISO is required'),
          prefLanguage: Yup.string().required('Language is required'),
          areaOfStudy: Yup.string().required(
            'Intended area of study is required'
          ),
          levelOfStudy: Yup.string().required(
            'Intended level of study is required'
          ),
          picked: Yup.string(),
          prefStartDate: Yup.string(),
          englishProfLevel: Yup.string(),
          useOfAgent: Yup.string().required('Use of agent is required')
        },
        [
          ['mobilePhone', 'mobilePhone'],
          ['prefStartDate', 'prefStartDate']
        ]
      ),
      enquiry: Yup.object().shape({
        enquiryQuestionId: Yup.string()
          .min(2, 'Enquiry question ID is too short')
          .required('Enquiry question ID is required'),
        enquiryQuestionText: Yup.string().required(
          'Enquiry question text is required'
        ),
        itemId: Yup.string().required('Item ID is required'),
        itemType: Yup.string().required('Item type is required')
      })
    })
    .noUnknown(true)
    .required()

const enquiryFormSchema = (
  lang?: string
): Yup.ObjectSchema<EnquiryFormValues> =>
  Yup.object().shape(
    {
      firstName: Yup.string()
        .min(2, 'Too Short!')
        .max(33, 'Too Long!')
        .required('First name is required'),
      lastName: Yup.string()
        .min(2, 'Too Short!')
        .max(33, 'Too Long!')
        .required('Last name is required'),
      email: Yup.string()
        .email('Please enter a valid email')
        .required('Email address is required'),
      nationality:
        lang === 'ko'
          ? Yup.string()
          : Yup.string().required('Please choose a location'),
      mobilePhone: Yup.string().when('mobilePhone', {
        is: (val: string) => val?.length,
        then: (rule) => rule.min(7, 'Too Short!').max(16, 'Too Long!')
      }),
      personType: Yup.string().required('Please choose your role'),
      sourceInformation: Yup.string().required('Please choose an option'),
      sourceInformationOther: Yup.string().min(2, 'Too Short!').nullable(),
      englishProfLevel: Yup.string(),
      useOfAgent: Yup.string().required('Please choose an option'),
      areaOfStudy: Yup.string().required(
        'Please enter an intended area of study'
      ),
      levelOfStudy: Yup.string().required(
        'Please enter an intended level of study'
      ),
      picked: Yup.string(),
      prefStartDate: Yup.lazy((value) =>
        value === ''
          ? Yup.string()
          : Yup.date().when('picked', {
              is: (picked: string) => picked === 'yes',
              then: (rule) =>
                rule
                  .transform((value: Date) => (value ? new Date(value) : value))
                  .test(
                    'year',
                    'Please enter a valid date',
                    (value: Date | undefined) =>
                      value !== undefined &&
                      value.getFullYear() >= 1000 &&
                      value.getFullYear() <= 9999
                  )
                  .min(new Date(), 'Please enter a date in the future')
                  .required('Please enter a valid date')
                  .typeError('Please enter a valid date'),
              otherwise: (rule) => rule.notRequired().nullable()
            })
      ),
      dateOfBirth: Yup.date()
        .typeError('Please enter a valid date')
        .transform((value) => {
          return value ? new Date(value) : value
        })
        .max(new Date(), 'Please enter a valid date')
        .required('Please enter your date of birth'),
      prefLanguage: Yup.string().required('Please choose a language')
    },
    [
      ['mobilePhone', 'mobilePhone'],
      ['prefStartDate', 'prefStartDate']
    ]
  )

const reshapeEnquiryFormDataAsAPIPayload = (
  data: EnquiryFormSubmissionData
): EnquiryAPIEndpointPayload => {
  formLogger?.info(
    '*** [FORM_LOGGER] *** :: Reshaping submissionData from an enquiry form',
    {
      data
    }
  )

  const {
    itemId,
    itemType,
    enquiryQuestionId,
    sourceInformation,
    sourceInformationOther,
    mobilePhoneCountry,
    lang,
    ...learner
  } = data

  const enquiry = (({
    sourceInformation,
    enquiryQuestionId,
    itemId,
    itemType
  }) => ({
    itemId,
    itemType,
    enquiryQuestionId,
    enquiryQuestionText: sourceInformation ?? '',
    tealiumVisitorId: window?.utag?.data?.tealium_visitor_id || '',
    tealiumSessionId: window?.utag?.data?.tealium_session_id || ''
  }))(data)

  return {
    learner: {
      ...learner,
      tealiumVisitorId: window?.utag?.data?.tealium_visitor_id || '',
      tealiumSessionId: window?.utag?.data?.tealium_session_id || ''
    },
    enquiry,
    lang
  }
}

function useEnquiryFormSubmission() {
  const { getAccessTokenSilently } = useAuth0()

  const postEnquiryFormSubmissionToAPIEndpoint = async (
    profile: ProfileAPIValues | null,
    data: EnquiryFormSubmissionData,
    callbacks: FormSubmissionCallbacks
  ): Promise<any> => {
    const payload = reshapeEnquiryFormDataAsAPIPayload(data)
    const accessToken = await getAccessTokenSilently()

    formLogger?.info(
      '*** [FORM_LOGGER] *** :: Initiating API request for an enquiry form',
      {
        payload
      }
    )

    if (!profile) {
      return callbacks.error()
    }

    enquire(profile, accessToken, payload)
      .then(async (response) => {
        if (
          response.hasOwnProperty('message') &&
          response.message === 'success'
        ) {
          formLogger?.emitSuccess('Enquiry form', { response, payload })
          callbacks.success()
        }

        if (
          response.hasOwnProperty('error') ||
          (response.hasOwnProperty('message') && response.status !== 200)
        ) {
          callbacks.error()
          formLogger?.emitError('Enquiry form', response.status, {
            response,
            payload
          })
        }
      })
      .catch((error) => {
        formLogger?.emitError('Enquiry form', 500, { error, payload })
      })
  }

  return postEnquiryFormSubmissionToAPIEndpoint
}

const removeLoginDataCookie = () => {
  Cookies.get(COOKIE.SET_LOGIN_DATA) && Cookies.remove(COOKIE.SET_LOGIN_DATA)
}

function useHandleModalCloseResetData() {
  const handleModalCloseResetData = async (
    onClose: () => void,
    url?: string
  ) => {
    onClose()
    removeLoginDataCookie()
    url && router.push(url)
  }

  return handleModalCloseResetData
}

function useEnquiryModalOnSignIn(
  onModalOpen: () => void,
  setActionData: Dispatch<SetStateAction<EnquiryFormConnectProps | null>>
) {
  const {
    userState: { status }
  } = useUser()
  const router = useRouter()

  useEffect(() => {
    const loginDataCookie = Cookies.get(COOKIE.SET_LOGIN_DATA)
    const formData = loginDataCookie && JSON.parse(loginDataCookie)
    const data = loginDataCookie && formData.loginType === SignUpType.Enquiry

    if (data && router.isReady && status === Statuses.RESOLVED) {
      onModalOpen()
      setActionData(formData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, router.isReady])
}

function useSavedModalOnSignIn(
  onSignUpModalOpen: () => void,
  setSavedStatus: Dispatch<SetStateAction<SavedModalStatusType>>
) {
  const {
    userState: { status }
  } = useUser()
  const router = useRouter()
  const loginDataCookie = Cookies.get(COOKIE.SET_LOGIN_DATA)
  const formData = loginDataCookie && JSON.parse(loginDataCookie)
  const handleSaveItem = useUpdateInventoryItem()

  useEffect(() => {
    async function handleSaving() {
      if (
        formData &&
        formData.loginType &&
        formData.loginType === SignUpType.Save
      ) {
        handleSaveItem(formData.formId, formData.itemType)
          .then(() => {
            setSavedStatus(SavedModalStatusType.Success)
          })
          .catch((err) => {
            setSavedStatus(SavedModalStatusType.Error)
          })
      }
    }

    if (formData && router.isReady && status === Statuses.RESOLVED) {
      onSignUpModalOpen()
      handleSaving()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status, router.isReady])
}

export {
  useEnquiryFormSubmission,
  EnquiryFormType,
  enquiryEndpointSchema,
  enquiryFormSchema,
  removeLoginDataCookie,
  useHandleModalCloseResetData,
  useEnquiryModalOnSignIn,
  useSavedModalOnSignIn
}
export type {
  FormSubmissionCallbacks,
  EnquiryFormValues,
  EnquiryFormSubmissionData,
  EnquiryAPIEnquiryModel,
  EnquiryAPILearnerProfileModel,
  EnquiryAPIEndpointPayload,
  EnquiryEndpointValues
}
