import { AutocompleteApi } from '@algolia/autocomplete-core'
import { SearchClient } from 'algoliasearch/lite'
import { ReactElement, ReactNode } from 'react'
import { UL } from '../../lists'

import { AutocompleteResults } from './SearchAutocomplete'

export interface SearchAutocompleteProps {
  title: string
  placeholder: string
  indexName: string
  facet: string
  searchClient: SearchClient
}

interface SearchAutocompleteList {
  autocomplete: AutocompleteApi<AutocompleteResults>
  children: ReactNode
}

const SearchAutocompleteList = ({
  autocomplete,
  children
}: SearchAutocompleteList): ReactElement => {
  return (
    <UL listStyleType='none' margin={0} {...autocomplete.getListProps()}>
      {children}
    </UL>
  )
}

export default SearchAutocompleteList
