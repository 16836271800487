import {
  AspectRatio,
  Box,
  Flex,
  LinkBox,
  LinkOverlay,
  Text
} from '@chakra-ui/react'
import {
  ParentNaumaiPagePath_Swnz_NaumaiPageStandardFragment,
  ParentPagePath_Swnz_PageFragment
} from 'content-service'
import { PlayIconSolid } from 'icons'
import { H6 } from 'ui'
import { createHref } from 'utils'
import { OptimisedImage } from '../../Image'

type Video = {
  url: string
  caption: string
  thumbnail: string
}

type PageLink = {
  slug: string
  parentPage:
    | ParentNaumaiPagePath_Swnz_NaumaiPageStandardFragment
    | ParentPagePath_Swnz_PageFragment
    | undefined
  title: string
  thumbnail: string
}

interface HeroFeaturedFloatingCardProps {
  cardHeading?: string
  title?: string
  video?: Video | null
  pageLink?: PageLink | null
  onClick: () => void
}

const LinkWrapper = ({ cardHref, children }) => {
  return cardHref ? (
    <LinkOverlay href={cardHref}>{children}</LinkOverlay>
  ) : (
    children
  )
}

const HeroFeaturedFloatingCard = ({
  cardHeading,
  title,
  video,
  pageLink,
  onClick
}: HeroFeaturedFloatingCardProps) => {
  const thumbnailImage = OptimisedImage({
    src: pageLink?.thumbnail || video?.thumbnail || ''
  })

  const parentPath =
    pageLink?.parentPage && pageLink?.parentPage.slug !== '/'
      ? `${createHref({ internalLink: pageLink?.parentPage })}/`
      : '/'
  const cardHref = pageLink ? `${parentPath}${pageLink?.slug}` : ''

  return (
    <LinkBox
      width={{ base: '260px', lg: '308px' }}
      padding={{ base: '10px', lg: '16px' }}
      background='white'
      boxShadow='0px 5px 20px rgba(0, 0, 0, 0.25)'
      onMouseDown={onClick}
      cursor='pointer'
      data-testid='floating-card'
      mt={{ base: 8, lg: 0 }}
    >
      <LinkWrapper cardHref={cardHref}>
        <Flex maxW={{ base: '260px', lg: '308px' }} w='100%'>
          <Box maxW={{ base: '164px', lg: '194px' }} w='100%'>
            <Flex flexDir='column'>
              <Text
                fontFamily='enz500'
                fontSize='14px'
                fontWeight='500'
                lineHeight='20px'
                color='surfaceBlue'
                marginBottom={0}
              >
                {cardHeading}
              </Text>
              <H6
                fontFamily='enz500'
                fontSize={{ base: '14px', md: '18px' }}
                fontWeight='500'
                lineHeight={{ base: '20px', md: '24px' }}
                letterSpacing='-1px !important'
                color='deepWaterBlue'
                marginBottom={0}
              >
                {title}
              </H6>
            </Flex>
          </Box>
          <Box
            width='66px'
            height='66px'
            marginInlineStart='10px'
            position='relative'
          >
            {video?.url && (
              <Box
                data-testid='card-play-icon'
                className='play-icon'
                position='absolute'
                top='50%'
                left='50%'
                transform='translate(-50%,-50%)'
                zIndex={1}
                sx={{
                  '.outline': {
                    display: 'none'
                  },
                  '.triangle': {
                    fill: 'white'
                  }
                }}
              >
                <PlayIconSolid />
              </Box>
            )}
            {thumbnailImage && (
              <AspectRatio ratio={1}>{thumbnailImage}</AspectRatio>
            )}
          </Box>
        </Flex>
      </LinkWrapper>
    </LinkBox>
  )
}

export type { HeroFeaturedFloatingCardProps }
export default HeroFeaturedFloatingCard
