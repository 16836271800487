import { InventoryProvider } from 'content-service'
import {
  createOpenGraphConfig,
  stripTagsFromHtmlStringAndTruncate
} from './index'

const createProviderDetailsPageSeo = ({
  inputData,
  pageUrl
}: {
  inputData: InventoryProvider
  pageUrl: string
}) => {
  const title = `${inputData.name} | Study with New Zealand` || ''

  const description = inputData.name
    ? `Explore all courses offered at the ${inputData.name}. Start your New Zealand education journey now.`
    : inputData.description || ''
  const formattedDescription = stripTagsFromHtmlStringAndTruncate(description)

  return createOpenGraphConfig({
    title,
    url: pageUrl,
    description: formattedDescription
  })
}

export { createProviderDetailsPageSeo }
