// Yarn build issue for compress: https://github.com/rse/graphql-query-compress/issues/10
// Reason for not using webpack alias https://github.com/vercel/next.js/issues/877
// @ts-ignore
import compress from 'graphql-query-compress/lib/graphql-query-compress.browser.js'

const customFetch = (uri: string, options: RequestInit) => {
  const body = JSON.parse(options.body as string)

  // Compress the query to avoid contentful query limits
  const newOptions = {
    ...options,
    body: JSON.stringify({
      ...body,
      query: compress(body.query)
    })
  }

  return fetch(uri, newOptions)
}

// helper to debug the customFetch
function getSize(obj: object) {
  return new TextEncoder().encode(JSON.stringify(obj)).length
}

export default customFetch
