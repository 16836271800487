import { Box, Flex } from '@chakra-ui/react'
import { LightBulbIcon } from 'icons'
import { ReactNode } from 'react'

type InfoBoxProps = { id?: string; children: ReactNode }

const InfoBox = ({ children, ...props }: InfoBoxProps) => {
  return (
    <Flex
      backgroundColor='sand.20'
      p={{ base: 5, lg: 8 }}
      mb={8}
      maxW='648px'
      {...props}
    >
      <Box
        fontSize='32px'
        mr={{ base: 3, lg: 6 }}
        sx={{
          svg: {
            width: '1em',
            height: '1em'
          }
        }}
      >
        <LightBulbIcon />
      </Box>
      <Box
        fontFamily='enz500'
        fontSize={{ base: '14px', lg: '18px' }}
        lineHeight={{ base: '20px', lg: '24px' }}
        letterSpacing={{ base: -0.5, lg: -1 }}
      >
        {children}
      </Box>
    </Flex>
  )
}

export default InfoBox
