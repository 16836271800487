import {
  Value_Swnz_ValueFragment,
  Value_Swnz_ValueFragmentDoc
} from 'content-service'
import { ReactElement } from 'react'
import { createCtaButton } from 'utils/src/helpers'
import useFragment from 'utils/src/hooks/useFragment'
import { ValueBanner } from '../../components/content-components'
import { Connector } from '../types'

export type ValueComponentConnectProps = Connector

const ValueComponentConnect = ({
  id,
  typename,
  leadCaptureFormId
}: ValueComponentConnectProps): ReactElement | null => {
  const valueComponent = useFragment<Value_Swnz_ValueFragment>({
    id,
    typename,
    fragment: Value_Swnz_ValueFragmentDoc,
    fragmentName: 'value_SWNZ_Value'
  })

  if (!valueComponent) {
    return null
  }

  const title = valueComponent?.title ?? ''
  const introduction = valueComponent?.introduction ?? ''
  const ctaText = valueComponent?.ctaText ?? ''
  const drawerPronunciation = valueComponent?.drawerPronunciation ?? ''
  const image = {
    title: valueComponent?.image?.title ?? '',
    url: valueComponent?.image?.url ?? ''
  }
  const drawerPrimaryCta = createCtaButton(valueComponent?.drawerPrimaryCta)
  const drawerDescription = valueComponent?.drawerDescription ?? null

  return (
    <ValueBanner
      title={title}
      introduction={introduction}
      ctaText={ctaText}
      drawerPronunciation={drawerPronunciation}
      drawerPrimaryCta={drawerPrimaryCta}
      drawerDescription={drawerDescription}
      image={image}
      leadCaptureFormId={leadCaptureFormId}
    />
  )
}

export default ValueComponentConnect
