import { useEffect, useState } from 'react'
import { SearchState } from 'react-instantsearch-core'
import {
  FinderFacetMenu,
  FinderFacetMenuItem,
  FinderSearchButton,
  FinderWidget,
  InstantSearchWrapper,
  SearchAutocomplete,
} from 'ui'
import { searchPlugins } from 'ui/src/components/Search/SearchAutocomplete'
import { createConfig } from 'utils/src/helpers/algoliaSearch'
import { createLevelOfStudyFacets } from 'utils/src/helpers/createLevelOfStudyFacets'
import { ALGOLIA_INDICES, ROUTES } from '../../../constants'

const { searchClient, indexName } = createConfig({
  indexName: ALGOLIA_INDICES.COURSE_FINDER.MAIN,
})

const facetQuery = searchPlugins.createFacetQuery({
  searchClient,
  indexName,
  resultsPagePath: ROUTES.COURSE.RESULTS,
  facet: 'areasOfStudy.name',
})

const searchQuery = searchPlugins.createSearchQuery({
  searchClient,
  indexName,
  resultsPagePath: ROUTES.COURSE.RESULTS,
})

interface CourseFinderWidget {
  initialSearchState: SearchState
}

const CourseFinderWidget = ({ initialSearchState }: CourseFinderWidget) => {
  const [levelOfStudyFacets, setLevelOfStudyFacets] = useState<FinderFacetMenuItem[] | []>([])

  async function getLevelOfStudyJSON() {
    const data = await import('taxonomy/src/data/level-of-study-broad.json')

    if (data) {
      setLevelOfStudyFacets(createLevelOfStudyFacets(data))
    }
  }

  useEffect(() => {
    getLevelOfStudyJSON()
  }, [])

  return (
    <InstantSearchWrapper
      searchState={initialSearchState}
      searchClient={searchClient}
      indexName={indexName}
    >
      <FinderWidget heading="Search for a course">
        <FinderWidget.Input minWidth="220px" width={{ base: 'full', md: '25%' }}>
          <FinderFacetMenu
            heading="Levels of Study"
            placeholder="All levels of study"
            attribute="levelOfStudy.name"
            facets={levelOfStudyFacets}
          />
        </FinderWidget.Input>
        <FinderWidget.Input width={{ base: 'full', md: '75%' }}>
          <SearchAutocomplete
            title="Search for a course"
            placeholder="Search your area of study"
            facet="areasOfStudy.name"
            resultsPath={ROUTES.COURSE.RESULTS}
            dropdownTitle="Suggested courses"
            plugins={[facetQuery, searchQuery, searchPlugins.popularCourseCategories]}
            showLabel={false}
            shouldRedirectOnSubmit={true}
          />
        </FinderWidget.Input>
        <FinderWidget.SearchButton w={{ base: 'full', md: 'auto' }}>
          <FinderSearchButton resultsPagePath={ROUTES.COURSE.RESULTS} />
        </FinderWidget.SearchButton>
      </FinderWidget>
    </InstantSearchWrapper>
  )
}

export default CourseFinderWidget
