import { AspectRatio, Box, useDisclosure } from '@chakra-ui/react'
import { ZoomIcon } from 'icons'
import { ReactElement } from 'react'
import { getPageThemeBackgroundColour } from 'utils/src/helpers'
import { ContainerWrapper } from '../layout'
import ImageZoomModal from './ImageZoomModal'

interface ImageZoomProps {
  imageComponent: ReactElement | null
  imageCaption?: string
  zoomImageComponent: ReactElement | null
  maxModalWidth: number
  aspectRatio: number
  pageTheme?: string
}

const ImageZoom = ({
  imageComponent,
  imageCaption,
  zoomImageComponent,
  maxModalWidth,
  aspectRatio,
  pageTheme
}: ImageZoomProps) => {
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose
  } = useDisclosure()
  const containerBackgroundColour = getPageThemeBackgroundColour(pageTheme)

  return (
    <>
      <ContainerWrapper bgColor={containerBackgroundColour}>
        <button
          onClick={onModalOpen}
          aria-label='Open image in modal'
          aria-expanded={isModalOpen}
          style={{
            position: 'relative',
            display: 'block',
            width: '100%',
            cursor: 'zoom-in'
          }}
        >
          <AspectRatio ratio={aspectRatio}>{imageComponent}</AspectRatio>
          <Box
            position='absolute'
            bottom={{ base: '10px', lg: '40px' }}
            right={{ base: '10px', lg: '40px' }}
            boxSize={{ base: '40px', md: '70px', lg: '100px' }}
          >
            <ZoomIcon />
          </Box>
        </button>
        {imageCaption && (
          <Box
            as='figcaption'
            display='inline-block'
            marginTop={3}
            color='deepblue.300'
            fontFamily='enz500'
          >
            {imageCaption}
          </Box>
        )}
      </ContainerWrapper>

      {isModalOpen && (
        <ImageZoomModal
          isOpen={isModalOpen}
          onClose={onModalClose}
          maxWidth={maxModalWidth}
        >
          <AspectRatio
            data-testid='image-zoom-modal'
            ratio={aspectRatio}
            maxHeight={'100vh'}
            display='flex'
            justifyContent='center'
          >
            {zoomImageComponent}
          </AspectRatio>
        </ImageZoomModal>
      )}
    </>
  )
}

export default ImageZoom
export type { ImageZoomProps }
