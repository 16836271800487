import {
  ButtonProps,
  ChakraProps,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps
} from '@chakra-ui/react'
import React, { ReactNode } from 'react'

const Modal = ({
  children,
  ...chakraProps
}: { children: ReactNode } & ModalProps) => {
  return (
    <ChakraModal isCentered scrollBehavior='inside' {...chakraProps}>
      {children}
    </ChakraModal>
  )
}

Modal.Overlay = function Overlay({ ...chakraProps }) {
  return <ModalOverlay background='rgba(4, 15, 32, 0.85)' {...chakraProps} />
}

Modal.Content = function Content({
  children,
  ...chakraProps
}: {
  children: React.ReactNode | React.ReactNode[]
} & ChakraProps) {
  return (
    <ModalContent
      position='relative'
      maxWidth={{ md: '550px', lg: '650px' }}
      borderRadius={{ base: 0, md: '20px' }}
      my='unset'
      dir='ltr'
      mx={4}
      {...chakraProps}
    >
      {children}
    </ModalContent>
  )
}

Modal.FullWidthContent = function FullWidthContent({
  children,
  ...chakraProps
}: {
  children: React.ReactNode | React.ReactNode[]
} & ChakraProps) {
  return (
    <ModalContent
      position='relative'
      maxWidth={{ md: '550px', lg: '650px' }}
      borderRadius={{ base: 0, md: '20px' }}
      my='unset'
      dir='ltr'
      marginX={0}
      overflowY='scroll'
      {...chakraProps}
    >
      {children}
    </ModalContent>
  )
}

Modal.Body = function Body({
  children,
  onClick,
  ...chakraProps
}: {
  children: React.ReactNode | React.ReactNode[]
  onClick?: () => void
} & ChakraProps) {
  return (
    <ModalBody {...chakraProps} onClick={onClick}>
      {children}
    </ModalBody>
  )
}

Modal.CloseButton = function CloseBtn({ ...chakraProps }: ButtonProps) {
  return (
    <ModalCloseButton
      position='absolute'
      right={{ base: 2, lg: 7 }}
      top={{ base: 2, lg: 6 }}
      zIndex={9999}
      aria-label='Close modal'
      backgroundColor={'rgba(230, 232, 235, 0.7)'}
      borderRadius='full'
      boxSize={{ base: '40px', lg: '50px' }}
      _hover={{
        backgroundColor: { md: 'deepblue.200' }
      }}
      sx={{
        'html[lang=ar] &': {
          right: 'unset',
          left: { base: 2, lg: 7 }
        },
        svg: {
          boxSize: { base: '14px', lg: '18px' },
          color: { base: 'deepblue.300', md: 'deepblue.500' }
        }
      }}
      {...chakraProps}
    />
  )
}
export default Modal
