import { Box, HStack } from '@chakra-ui/react'
import { ArrowIcon } from 'icons'
import React from 'react'
import { Button } from '../../buttons'
import { NavigationType } from './CardCarouselGallery'
import { setArrowIconOpacity } from './helpers'

interface CardCarouselNavigationButtonsProps {
  numberOfSlides: number
  currentSlide: number
  isMobile: boolean
  onClickHandler: (navigationType: string) => void
  color?: string
}

type RenderNavigationIcon = CardCarouselNavigationButtonsProps & {
  navigationType: NavigationType
}

const renderNavigationIcon = ({
  navigationType,
  numberOfSlides,
  currentSlide,
  isMobile,
  onClickHandler,
  color
}: RenderNavigationIcon) => {
  const buttonAccessibilityIdentifier = `Slider navigation: ${navigationType} item button`

  return (
    <Button
      variant='unstyled'
      display='flex'
      justifyContent='center'
      alignItems='center'
      width={10}
      height={10}
      title={buttonAccessibilityIdentifier}
      aria-label={buttonAccessibilityIdentifier}
      cursor='pointer'
      onClick={() => onClickHandler(navigationType)}
      icon={
        <Box
          fill={color}
          boxSize={10}
          transform={
            navigationType === NavigationType.Previous ? 'rotate(-180deg)' : ''
          }
          opacity={setArrowIconOpacity({
            navigationType,
            numberOfSlides,
            currentSlide,
            isMobile
          })}
        >
          <ArrowIcon />
        </Box>
      }
    />
  )
}

const CardCarouselNavigationButtons = ({
  numberOfSlides,
  currentSlide,
  isMobile,
  onClickHandler,
  color
}: CardCarouselNavigationButtonsProps) => {
  return (
    <HStack width={24} dir='ltr'>
      {renderNavigationIcon({
        navigationType: NavigationType.Previous,
        numberOfSlides,
        currentSlide,
        isMobile,
        onClickHandler,
        color
      })}
      {renderNavigationIcon({
        navigationType: NavigationType.Next,
        numberOfSlides,
        currentSlide,
        isMobile,
        onClickHandler,
        color
      })}
    </HStack>
  )
}

export default CardCarouselNavigationButtons
