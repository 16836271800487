import { onError } from '@apollo/client/link/error'

const errorLink = onError(({ graphQLErrors, response }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ extensions }) => {
      const { contentful } = extensions || {}
      // In contentful if published content links to a draft object an UNRESOLVABLE_LINK error is returned
      // This is ignored as all other content should be able to be published
      // @ts-ignore
      if (response?.errors && contentful?.code === 'UNRESOLVABLE_LINK') {
        response.errors = undefined
      }
    })
})

export default errorLink
