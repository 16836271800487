import { Flex } from '@chakra-ui/react'
import {
  Swnz_NavigationElement,
  useGetNavigationElementsByCodeIdQuery
} from 'content-service'
import { useRouter } from 'next/router'
import { ReactElement, useState } from 'react'
import { cnNavFilter } from 'utils/src/helpers/cnNavFilter'
import { createLinks } from 'utils/src/helpers/nav'
import { MainNav } from './main'
import { NavLink } from './NavItem'
import { SecondaryNav } from './secondary'
interface NavProps {
  codeId: string
  preview?: boolean
}

const Nav = ({ codeId, preview = false }: NavProps): ReactElement => {
  const { locale } = useRouter()

  const [selectedItem, setSelection] = useState<string | null>(null)

  const { loading, error, data } = useGetNavigationElementsByCodeIdQuery({
    variables: { codeId, preview, locale }
  })

  if (error) return <p>Error</p>
  if (loading) return <p>Loading</p>
  if (!data || data?.swnz_navigationCollection == null) return <p>No Data</p>

  let navigationElements =
    data?.swnz_navigationCollection?.items[0]?.navigationElementsCollection

  if (locale === 'cn' && navigationElements) {
    navigationElements = {
      ...navigationElements,
      items: cnNavFilter(
        navigationElements.items as Array<Swnz_NavigationElement>
      )
    }
  }

  const links: Array<NavLink> = createLinks({
    linkItems: navigationElements
  })

  const callback = (id?: string) => {
    if (!id) return

    setSelection(id)
  }

  return (
    <Flex as='nav' flexDir='column'>
      {selectedItem
        ? links && (
            <SecondaryNav
              links={links}
              selected={selectedItem}
              navigateToMainNav={() => setSelection(null)}
            />
          )
        : links && <MainNav links={links} callback={callback} />}
    </Flex>
  )
}

export default Nav
