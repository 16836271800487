import { Heading, HeadingProps } from '@chakra-ui/react'
import React from 'react'

export const H1 = (props: HeadingProps) => (
  <Heading
    as='h1'
    fontSize={{ base: '48', lg: '64' }}
    lineHeight='1.12'
    letterSpacing='-2px'
    marginBottom={10}
    {...props}
  />
)

export const H2 = (props: HeadingProps) => (
  <Heading
    as='h2'
    fontSize={{ base: '36', lg: '48' }}
    lineHeight='1.15'
    marginBottom={8}
    {...props}
  />
)

export const H3 = (props: HeadingProps) => (
  <Heading
    as='h3'
    fontSize={{ base: '26', lg: '32' }}
    lineHeight='1.375'
    marginBottom={6}
    {...props}
  />
)

export const H4 = (props: HeadingProps) => (
  <Heading
    as='h4'
    fontSize={{ base: '20', lg: '26' }}
    lineHeight='1.3'
    marginBottom={5}
    {...props}
  />
)
export const H5 = (props: HeadingProps) => (
  <Heading
    as='h5'
    fontSize={{ base: '20', lg: '22' }}
    lineHeight='1.4'
    marginBottom={4}
    fontFamily='enz500'
    fontWeight='medium'
    {...props}
  />
)
export const H6 = (props: HeadingProps) => (
  <Heading
    as='h6'
    fontSize={{ base: '14', lg: '16' }}
    marginBottom={3}
    {...props}
  />
)

export const Headline = (props: HeadingProps) => (
  <Heading
    fontSize={{ base: '44', lg: '64' }}
    fontWeight='normal'
    lineHeight='1'
    fontFamily='a23d'
    textTransform='uppercase'
    marginBottom={10}
    {...props}
  />
)
