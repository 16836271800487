import { Box, Flex, GridItem, Text } from '@chakra-ui/react'
import {
  NavigationElements_Swnz_NavigationNavigationElementsCollectionFragment,
  useGetNavigationElementsForFooterQuery,
} from 'content-service'
import { VerticalThinkNewNZLogo } from 'icons'
import { useRouter } from 'next/router'
import { ReactElement } from 'react'
import {
  ContainerWrapper,
  FooterSectionLegalLinks,
  FooterSectionLinks,
  FooterSectionSocialLinks,
  GridLayout,
  Image,
  Link,
} from 'ui'
import { createLinks } from 'utils/src/helpers/nav'
import { slugify } from 'utils/src/helpers/slug'

interface CreateFooterSection {
  title: string | undefined
  customUrl?: string
  links: Pick<
    NavigationElements_Swnz_NavigationNavigationElementsCollectionFragment,
    'items'
  > | null
  useTitle?: boolean
}

interface FooterProps {
  preview?: boolean
}

const createFooterSection = ({
  title = '',
  customUrl,
  links,
  useTitle = false,
}: CreateFooterSection) => {
  return {
    title,
    links: createLinks({
      linkItems: links,
      ...(useTitle && { parentLink: customUrl ?? `/${slugify(title ?? '')}` }),
    }),
  }
}

const Footer = ({ preview = false }: FooterProps): ReactElement | null => {
  const { locale } = useRouter()

  const { data } = useGetNavigationElementsForFooterQuery({
    variables: {
      preview,
      locale,
    },
  })
  const { footer, socialLinks, legal } = data || {}

  const footerSectionLinks =
    footer?.items[0]?.navigationElementsCollection?.items.map((item) =>
      createFooterSection({
        title: item?.title ?? '',
        customUrl: item?.customUrl ?? undefined,
        links: item?.navigationElementsCollection ?? null,
        useTitle: true,
      })
    ) ?? []

  const socialLinksSection = createFooterSection({
    title: socialLinks?.items[0]?.navigationElementsCollection?.items[0]?.title || '',
    customUrl: footer?.items[0]?.navigationElementsCollection?.items[0]?.customUrl || undefined,
    links:
      socialLinks?.items[0]?.navigationElementsCollection?.items[0]?.navigationElementsCollection ||
      null,
  })

  const legalSection = createFooterSection({
    title: '',
    links: legal?.items[0]?.navigationElementsCollection || null,
  })

  const currentYear = `${new Date().getFullYear()}`

  return (
    <Box
      as="footer"
      bg="deepblue.500"
      paddingTop={{ base: 14, lg: 16 }}
      paddingBottom={{ base: 32, lg: 16 }}
      dir="ltr"
    >
      <ContainerWrapper sx={{ py: 0 }}>
        <GridLayout m={0} templateColumns={{ base: 'repeat(1fr)', lg: 'repeat(12, 1fr)' }}>
          <GridItem colSpan={{ base: 12, lg: 3, xl: 2 }}>
            <Link href="/" title="Link to homepage" aria-label="Link to homepage" display="block">
              <VerticalThinkNewNZLogo w={{ base: 28, xs: 164 }} h={{ base: 10, xs: 16 }} mb={8} />
            </Link>
          </GridItem>

          {footerSectionLinks.map((section, i) => (
            <GridItem key={i} colSpan={{ base: 12, lg: 3, xl: 2 }}>
              <FooterSectionLinks title={section.title} links={section.links} />
            </GridItem>
          ))}

          <GridItem
            colSpan={{ base: 12, lg: 3, xl: 2 }}
            colStart={{ xl: 11 }}
            borderTop={{ base: '1px solid rgba(255,255,255,0.1)', lg: 'none' }}
            sx={{
              // actually rtl
              'html[lang=ar] &': {
                direction: 'ltr',
              },
            }}
          >
            <FooterSectionSocialLinks
              title={socialLinksSection.title}
              links={socialLinksSection.links}
            />
          </GridItem>
        </GridLayout>
        <Flex justifyContent={{ lg: 'flex-end' }} my={{ base: 8, lg: 12 }}>
          <Link
            href="https://www.govt.nz/"
            title="Link to New Zealand Government"
            aria-label="Link to New Zealand Government"
            display="block"
            isExternal
          >
            <Image
              src="/nzgovt-logo.png"
              alt="New Zealand Government"
              width="250"
              height="56"
              blurOnLoad={false}
            />
          </Link>
        </Flex>
        <Flex
          flexDirection={{ base: 'column', lg: 'row' }}
          justifyContent={{ lg: 'space-between' }}
        >
          <Text color="white" fontSize="sm" mb={{ base: 2, lg: 0 }}>
            All content &copy; Education New Zealand {currentYear}
          </Text>

          <FooterSectionLegalLinks links={legalSection.links} />
        </Flex>
      </ContainerWrapper>
    </Box>
  )
}

export default Footer
