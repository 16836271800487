import {
  Box,
  Input,
  InputGroup,
  InputProps,
  InputRightElement
} from '@chakra-ui/react'
import { HidePasswordIcon, ShowPasswordIcon } from 'icons'
import React from 'react'

const PasswordInput = ({ ...props }: InputProps) => {
  const [show, setShow] = React.useState(false)
  const handleClick = () => setShow(!show)

  return (
    <InputGroup>
      <Input
        type={show ? 'text' : 'password'}
        placeholder='Enter password'
        {...props}
      />
      <InputRightElement onClick={handleClick} cursor='pointer'>
        {show ? (
          <Box boxSize={7}>
            <HidePasswordIcon />
          </Box>
        ) : (
          <Box boxSize={7}>
            <ShowPasswordIcon />
          </Box>
        )}
      </InputRightElement>
    </InputGroup>
  )
}
export default PasswordInput
