import { formLogger } from 'utils'
import * as Yup from 'yup'
import { FormSubmissionCallbacks } from './enquiryForm'

type NauMaiLeadCaptureFormSubmissionData = {
  firstName: string
  lastName: string
  email: string
  mobile?: string
  areYouInNZ?: string
  dateOfBirth: string | Date
  intendedLevelOfStudy?: string
  nzRegion?: string
  nationality: string
  nationalityISOCode: string
  courseStartDate?: string | Date
  expectedStudyCompletionDate?: string | Date
  privacyPolicyAgreed: boolean
  commsOptin: boolean
  inzMatchingPermissionOptin: boolean
  tealiumVisitorId?: string
  tealiumSessionId?: string
}

type SWNZLeadCaptureFormSubmissionData = {
  firstName: string
  lastName: string
  email: string
  mobile: string
  nationality?: string
  intendedLevelOfStudy: string
  privacyPolicyAgreed: boolean
  inzMatchingPermissionOptin: boolean
  desiredStartDate?: Date | string | null
  nationalityISOCode?: string
  tealiumVisitorId?: string
  tealiumSessionId?: string
  commsOptin: boolean
  source: string
  formSchemaName: string
  fairLocation?: string
  dateOfBirth?: Date | string | null
  hearAboutFair?: string[]
  areaOfStudy?: string
  lineId?: string
  personType?: string
  t?: any
  lang?: string
}

type LeadCaptureFormSubmissionData =
  | SWNZLeadCaptureFormSubmissionData
  | NauMaiLeadCaptureFormSubmissionData

type AdditionalLeadCaptureEndpointValues = Pick<
  SWNZLeadCaptureFormSubmissionData,
  | 'privacyPolicyAgreed'
  | 'nationalityISOCode'
  | 'tealiumVisitorId'
  | 'tealiumSessionId'
  | 'commsOptin'
  | 'source'
>

// Contains the fields that are required for lead capture api endpoint validation,
// but not required for front-end lead capture form validation
const additionalLeadCaptureEndpointSchema = (
  lang?: string
): Yup.ObjectSchema<AdditionalLeadCaptureEndpointValues> =>
  Yup.object()
    .shape({
      privacyPolicyAgreed: Yup.boolean()
        .oneOf([true], 'You must accept the privacy policy')
        .required('Required'),
      nationalityISOCode:
        lang === 'ko'
          ? Yup.string()
          : Yup.string().required('Nationality ISO code missing'),
      tealiumVisitorId: Yup.string(),
      tealiumSessionId: Yup.string(),
      commsOptin: Yup.boolean().oneOf([true, false]).defined(),
      source: Yup.string().required('Source is missing')
    })
    .noUnknown(true)
    .required()

const postLeadCaptureFormSubmissionToLeadCaptureAPIEndpoint = async (
  data: LeadCaptureFormSubmissionData,
  callbacks: FormSubmissionCallbacks
): Promise<any> => {
  const payload = data

  formLogger?.info(
    '*** [FORM_LOGGER] *** :: Initiating API request for LeadCaptureForm',
    {
      payload
    }
  )

  await fetch(`${process.env.NEXT_PUBLIC_URL}/api/leadcapture`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(payload)
  })
    .then((res) => {
      return res.json()
    })
    .then((response) => {
      if (
        response.hasOwnProperty('message') &&
        response.message === 'success'
      ) {
        formLogger?.emitSuccess('LeadCaptureForm', { response, payload })
        callbacks.success()
      }

      if (
        response.hasOwnProperty('error') ||
        (response.hasOwnProperty('message') && response.status !== 200)
      ) {
        callbacks.error()
        formLogger?.emitError('LeadCaptureForm', response.status, {
          response,
          payload
        })
      }
    })
    .catch((error) => {
      formLogger?.emitError('LeadCaptureForm', 500, { error, payload })
    })
}

export {
  postLeadCaptureFormSubmissionToLeadCaptureAPIEndpoint,
  additionalLeadCaptureEndpointSchema
}
