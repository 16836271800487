import { Center, Spinner } from '@chakra-ui/react'
import { useGetEnquiryFormFieldsQuery } from 'content-service'
import { useRouter } from 'next/router'
import { FORM_COMPONENTS, FORM_CONSTANTS } from 'ui'
import {
  CourseEnquiryForm,
  ProviderEnquiryForm,
  ScholarshipEnquiryForm,
  SchoolEnquiryForm,
} from '../../components/forms'
import { SignUpType } from '../../components/SignUpModal'

type EnquiryFormConnectProps = {
  formId: string
  formComponent: string
  courseName?: string
  providerName?: string
  providerSubtype?: string[]
  scholarshipName?: string
  deliveryMode?: string
  loginType?: SignUpType
  onClose: () => void
  onSuccess?: () => void
}

const EnquiryFormConnect = ({ formComponent, ...props }: EnquiryFormConnectProps) => {
  const { locale } = useRouter()

  const { loading, data } = useGetEnquiryFormFieldsQuery({ variables: { locale } })

  if (loading) {
    return (
      <Center height={32} width="full">
        <Spinner
          position="absolute"
          thickness="6px"
          speed="0.65s"
          emptyColor="teal.100"
          color="deepblue.500"
          size="xl"
          top="50%"
          transform="translate(-50%, 0)"
          display="block"
        />
      </Center>
    )
  }

  if (!data) return null

  const formComponentProps = {
    ...props,
    contentful: data,
    heading: FORM_CONSTANTS.ENQUIRY.HEADING,
    bodyText: FORM_CONSTANTS.ENQUIRY.BODY_TEXT,
    successMessage: FORM_CONSTANTS.ENQUIRY.SUCCESS_MESSAGE,
    errorMessage: FORM_CONSTANTS.ENQUIRY.ERROR_MESSAGE,
    onClose: props.onClose,
  }

  // return the correct form component based on the form component prop
  const formComponentMap = Object.freeze({
    [FORM_COMPONENTS.COURSE_ENQUIRY]: function renderCourseEnquiryForm() {
      return <CourseEnquiryForm {...formComponentProps} />
    },
    [FORM_COMPONENTS.PROVIDER_ENQUIRY]: function renderProviderEnquiryForm() {
      return <ProviderEnquiryForm {...formComponentProps} />
    },
    [FORM_COMPONENTS.SCHOOL_ENQUIRY]: function renderSchoolEnquiryForm() {
      return <SchoolEnquiryForm {...formComponentProps} />
    },
    [FORM_COMPONENTS.SCHOLARSHIP_ENQUIRY]: function renderScholarshipEnquiryForm() {
      return <ScholarshipEnquiryForm {...formComponentProps} />
    },
  })

  return formComponentMap[formComponent]()
}

export default EnquiryFormConnect
export type { EnquiryFormConnectProps }
