import { ListItem, UnorderedList } from '@chakra-ui/react'
import { Fragment } from 'react'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { Link } from '../Link'

interface SiteMapLinksProps {
  siteMapData: Record<string, any>
}

export default function SiteMapLinks({ siteMapData }: SiteMapLinksProps) {
  return (
    <>
      {Object.values(siteMapData).map((items) => {
        const children = Object.keys(items).reduce(
          (object: Record<string, any>, key: string) => {
            if (key !== 'slug' && key !== 'title') {
              object[key] = items[key]
            }
            return object
          },
          {}
        )

        const hasChildren =
          children &&
          Object.keys(children).length !== 0 &&
          children.constructor === Object
        const childlength = Object.keys(children).length

        return (
          items && (
            <Fragment key={items.slug}>
              <ListItem>
                <Link
                  href={items.slug}
                  prefetch={false}
                  onClick={() =>
                    createUtagLinkClickEvent({
                      linkLabel: 'sitemap',
                      linkCategory: TEALIUM_LINK_CATEGORY.FOOTER
                    })
                  }
                >
                  {items.title}
                </Link>
              </ListItem>
              {hasChildren && (
                <UnorderedList key={`${items.slug}-${childlength}`}>
                  <SiteMapLinks
                    siteMapData={children}
                    key={`listcomponent-${childlength}`}
                  />
                </UnorderedList>
              )}
            </Fragment>
          )
        )
      })}
    </>
  )
}
