import { mode } from '@chakra-ui/theme-tools'
import { mergeWith as merge } from '@chakra-ui/utils'
import Input from './input'

const parts = ['field', 'icon']

function baseStyleField(props: Record<string, any>) {
  return {
    ...Input.baseStyle.field,
    '> option, > optgroup': {
      bg: mode('white', 'gray.700')(props)
    }
  }
}

const baseStyleIcon = {
  width: '3rem',
  height: '100%',
  insetEnd: '0.4rem',
  position: 'relative',
  color: 'currentColor',
  fontSize: '30px',
  _disabled: {
    opacity: 0.38
  }
}

const baseStyle = (props: Record<string, any>) => ({
  field: baseStyleField(props),
  icon: baseStyleIcon
})

const sizes = merge({}, Input.sizes, {
  xs: {
    icon: {
      insetEnd: '0.25rem'
    }
  }
})

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  parts,
  baseStyle,
  sizes,
  variants: Input.variants,
  defaultProps: Input.defaultProps
}
