import SITE_CONFIG from './siteConfig'

const DEFAULT_SEO = {
  titleTemplate: '%s | Study with New Zealand',
  openGraph: {
    type: 'website',
    url: SITE_CONFIG.SITE_PROD_BASE_URL,
    site_name: SITE_CONFIG.SITE_NAME,
  },
  twitter: {
    handle: '@studyinnz',
    site: '@studyinnz',
    cardType: 'summary_large_image',
  },
}

export default DEFAULT_SEO
