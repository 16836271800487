import {
  Box,
  Button,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemProps,
  MenuList
} from '@chakra-ui/react'
import { ChevronIcon, TickIcon } from 'icons'
import React from 'react'
import { connectMenu, MenuProvided } from 'react-instantsearch-core'
import { Virtuoso } from 'react-virtuoso'
interface MenuItem {
  label: string
  value: string
  count: number
}

interface ResultsFacetMenuProps extends MenuProvided {
  heading: string
}

const menuItemStyleProps: MenuItemProps = {
  paddingY: 3,
  paddingRight: 4,
  display: 'flex',
  justifyContent: 'space-between',
  _hover: { background: 'teal.100' }
}

const tickIcon = (
  <Box as='span' data-testid='tick-icon' color='deepWaterBlue' boxSize='12px'>
    <TickIcon />
  </Box>
)

const ResultsFacetMenu = connectMenu(
  ({
    heading,
    items = [],
    currentRefinement,
    refine
  }: ResultsFacetMenuProps) => {
    const buttonLabel = currentRefinement ?? `${heading} (${items.length})`

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) =>
      refine(event.currentTarget.value)

    return (
      <Box overflow='hidden'>
        <Menu
          matchWidth={false}
          flip={false}
          preventOverflow
          placement='bottom-start'
        >
          {({ isOpen }) => (
            <>
              <MenuButton
                as={Button}
                width='full'
                isActive={isOpen}
                variant='unstyled'
                display='flex'
                justifyContent='space-between'
                overflow='hidden'
                position='relative'
                rightIcon={
                  <Box
                    transform={isOpen ? 'rotate(-90deg)' : 'rotate(90deg)'}
                    transition='0.2s ease-in-out'
                    boxSize='30px'
                    color='deepblue.500'
                    backgroundColor={{ base: 'deepblue.50', lg: 'white' }}
                  >
                    <ChevronIcon />
                  </Box>
                }
                color='deepblue.500'
                fontFamily='enz400'
                fontSize='16px'
                height='60px'
                borderRadius='base'
                backgroundColor={{ base: 'deepblue.50', lg: 'white' }}
                paddingLeft={{ base: 4, lg: 0 }}
                paddingRight={{ base: 4, lg: 0 }}
              >
                <HStack
                  as='span'
                  alignItems='center'
                  justifyContent='flex-start'
                  aria-label={buttonLabel}
                >
                  <Box as='span' fontFamily='enz500' color='deepblue.500'>
                    Showing
                  </Box>
                  <Box
                    as='span'
                    fontFamily={{ md: 'enz500' }}
                    color={{ base: 'deepblue.500', md: 'deepblue.300' }}
                    overflow='hidden'
                    whiteSpace='nowrap'
                    textOverflow='ellipsis'
                  >
                    {' '}
                    {buttonLabel}
                  </Box>
                </HStack>
              </MenuButton>
              <MenuList
                border='none'
                boxShadow='0px 5px 20px rgba(7, 25, 55, 0.1)'
                paddingBottom='12px'
                maxHeight='350px'
                maxWidth='320px'
                overflowY='scroll'
                marginTop='-5px'
              >
                <Virtuoso
                  style={{ height: 350, width: 320 }}
                  data={[{ label: heading, value: '' }, ...items]}
                  totalCount={items.length}
                  itemContent={(index, item) => {
                    return (
                      <MenuItem
                        key={index}
                        value={item.value}
                        onClick={handleClick}
                        aria-label={item.label}
                        {...menuItemStyleProps}
                      >
                        <Box as='span'>{item.label}</Box>
                        {currentRefinement === item.label && tickIcon}
                      </MenuItem>
                    )
                  }}
                />
              </MenuList>
            </>
          )}
        </Menu>
      </Box>
    )
  }
)

export default ResultsFacetMenu
