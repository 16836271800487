import { TEALIUM_EVENT } from "../helpers/constants";

interface StartEnquiry {
  pageName: string;
  inventoryId: string;
  inventoryObjectType: string;
  levelOfStudy: string;
  areaOfStudyArray: string[];
  subjectOfStudyArray: string[];
  deliveryModeArray: string[];
  courseName: string;
  providerName: string;
  providerType: string;
  providerSubtype: string[];
  scholarshipName?: string;
  campusRegionArray: string[];
  linkLabel: string;
  country?: string;
  city?: string;
}

interface StartEnquiryResult {
  page_name: string;
  inventory_id: string;
  inventory_object_type: string;
  level_of_study: string;
  area_of_study_array: string[];
  subject_of_study_array: string[];
  delivery_mode_array: string[];
  course_name: string;
  provider_name: string;
  provider_type: string;
  provider_subtype: string[];
  scholarship_name?: string;
  campus_region_array: string[];
  link_label: string;
  country?: string;
  city?: string;
  tealium_event: TEALIUM_EVENT;
}

/**
 * createStartEnquiryUtagEventObject
 * Build the data for a start_enquiry event
 */
function createStartEnquiryUtagEventObject({
  pageName,
  inventoryId,
  inventoryObjectType,
  levelOfStudy,
  areaOfStudyArray,
  subjectOfStudyArray,
  deliveryModeArray,
  courseName,
  providerName,
  providerType,
  providerSubtype,
  scholarshipName = "",
  campusRegionArray,
  linkLabel,
  country = "",
  city = "",
}: StartEnquiry): StartEnquiryResult {
  return {
    page_name: pageName,
    inventory_id: inventoryId,
    inventory_object_type: inventoryObjectType,
    level_of_study: levelOfStudy,
    area_of_study_array: areaOfStudyArray,
    subject_of_study_array: subjectOfStudyArray,
    delivery_mode_array: deliveryModeArray,
    course_name: courseName,
    provider_name: providerName,
    provider_type: providerType,
    provider_subtype: providerSubtype,
    scholarship_name: scholarshipName,
    campus_region_array: campusRegionArray,
    link_label: linkLabel,
    country,
    city,
    tealium_event: TEALIUM_EVENT.START_ENQUIRY,
  };
}

export default createStartEnquiryUtagEventObject;
export type { StartEnquiry, StartEnquiryResult };
