import { Box, Flex, ModalBody, ModalProps } from '@chakra-ui/react'
import { LoginPrompt, LoginPromptMobile } from 'icons'
import Cookies from 'js-cookie'
import { useEffect, useState } from 'react'
import { CtaButton, FORM_CONSTANTS, removeLoginDataCookie } from 'ui'
import { Modal } from 'ui/src/components/modal'
import { H3 } from 'ui/src/components/typography/Headings'
import { LargeText } from 'ui/src/components/typography/Paragraph'
import { EnquiryFormConnectProps } from '../../connectors'
import { COOKIE, ROUTES } from '../../constants'
import useLogin, { LoginScreenHint } from '../../hooks/useLogin'

interface SignInModalProps {
  isOpen: boolean
  onClose: () => void
}

export interface LoginConnectProps {
  loginType?: SignUpType
  inventory?: Inventory
  openModal?: boolean
}

export interface LoginState {
  loginState?: LoginConnectProps
}

export enum InventoryType {
  Course = 'course',
  Provider = 'provider',
  School = 'school',
  Scholarship = 'scholarship',
}

export type Inventory = EnquiryFormConnectProps

export enum SignUpType {
  Default = '',
  Save = 'save',
  Enquiry = 'enquiry',
}

export function setEmailVerificationCookie() {
  if (typeof window === 'undefined') {
    return
  }

  // Don't allow users to be redirected to the email unverified page after they have
  // been redirected. Default to the homepage
  const redirectLocation =
    window?.location?.pathname === ROUTES.AUTH.EMAIL_UNVERIFIED
      ? ROUTES.HOME
      : window.location.pathname

  Cookies.set(COOKIE.LOGIN_REDIRECT, redirectLocation, { sameSite: 'lax' })
}

export function setLoginDataCookie({ inventory }: LoginConnectProps) {
  inventory
    ? Cookies.set(COOKIE.SET_LOGIN_DATA, JSON.stringify(inventory), { sameSite: 'lax' })
    : Cookies.remove(COOKIE.SET_LOGIN_DATA)
}

const SignUpModal = ({
  isOpen,
  onClose,
  loginState,
  ...modalProps
}: SignInModalProps & LoginState & Omit<ModalProps, 'children'>) => {
  const [modalOpen, setModalOpen] = useState(loginState?.openModal)
  const login = useLogin()

  const handleModalClose = () => {
    removeLoginDataCookie()
    onClose()
    setModalOpen(false)
  }

  const handleClick = (signUp: LoginScreenHint, { loginState }: LoginState) => {
    setEmailVerificationCookie()
    loginState?.inventory && setLoginDataCookie(loginState)
    const loginType = loginState?.loginType

    login(signUp, loginType)
  }

  useEffect(() => {
    setModalOpen(loginState?.openModal)
  }, [loginState?.openModal])

  return (
    <>
      <Modal
        size="full"
        onClose={handleModalClose}
        isOpen={modalOpen ? modalOpen : isOpen}
        isCentered={false}
        {...modalProps}
      >
        <Modal.Overlay />
        <Modal.Content
          minHeight="auto"
          mt={{ base: '2.5rem', md: '3.75rem' }}
          borderRadius={{ base: '10px', md: '20px' }}
        >
          <ModalBody
            paddingX={{ base: 5, lg: 7 }}
            paddingTop={{ base: 5, lg: 7 }}
            paddingBottom={12}
            textAlign="left"
          >
            <Flex justifyContent="space-between">
              <H3>{FORM_CONSTANTS.SIGN_UP.heading}</H3>
              <Modal.CloseButton
                position="relative"
                top="initial"
                bottom="initial"
                right="initial"
                left="initial"
              />
            </Flex>

            <LargeText>{FORM_CONSTANTS.SIGN_UP.bodyText}</LargeText>
            <LargeText>{FORM_CONSTANTS.SIGN_UP.secondaryText}</LargeText>
            <Box bg="teal.100">
              <Flex w="full" justify={{ base: 'center', md: 'end' }} pt={4}>
                <Flex
                  display={{ base: 'flex', md: 'none' }}
                  justifyContent="center"
                  alignItems="center"
                  boxSize={'283px, 303px'}
                >
                  <LoginPromptMobile />
                </Flex>
                <Box display={{ base: 'none', md: 'block' }} width="584px" height="335px">
                  <LoginPrompt />
                </Box>
              </Flex>
            </Box>
            <Flex mt={7} flexDir={{ base: 'column', md: 'row' }}>
              <CtaButton
                marginEnd={{ base: 0, md: 2 }}
                mb={{ base: 3, md: 0 }}
                onClick={() =>
                  handleClick('login', {
                    loginState: {
                      inventory: loginState?.inventory,
                      loginType: loginState?.loginType,
                      openModal: loginState?.openModal,
                    },
                  })
                }
              >
                {FORM_CONSTANTS.SIGN_UP.signInButton}
              </CtaButton>
              <CtaButton
                variant="outline"
                onClick={() =>
                  handleClick('signup', {
                    loginState: {
                      inventory: loginState?.inventory,
                      loginType: loginState?.loginType,
                      openModal: loginState?.openModal,
                    },
                  })
                }
              >
                {FORM_CONSTANTS.SIGN_UP.registerButton}
              </CtaButton>
            </Flex>
          </ModalBody>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default SignUpModal
