import { ReactElement } from 'react'
import {
  EducationFairFormConnectProps,
  EducationFormConnect
} from 'swnz/src/connectors'
import { ImageLoadingPreference } from 'utils'
import {
  AccordionConnect,
  AccordionConnectProps,
  AlternatingComponentConnect,
  AlternatingComponentConnectProps,
  AlternatingQuoteConnect,
  AlternatingQuoteConnectProps,
  BulletPointsConnect,
  BulletPointsConnectProps,
  Connector,
  EmotionCardsCarouselConnect,
  EmotionCardsCarouselConnectProps,
  GalleryCarouselConnect,
  GalleryCarouselConnectProps,
  GenericTextConnect,
  ImpactTextConnect,
  ImpactTextConnectProps,
  IntroTextWithJumpMenuConnect,
  JumpAheadConnect,
  KeepExploringConnect,
  KeepExploringConnectProps,
  MapContainerConnect,
  MapContainerConnectProps,
  MediaImageConnect,
  MediaImageConnectProps,
  MediaVideoConnect,
  MediaVideoConnectProps,
  NextStepsConnect,
  NextStepsConnectProps,
  RegionCardsCarouselConnect,
  RegionCardsCarouselConnectProps,
  StaticComponentConnect,
  StatisticsConnect,
  StatisticsConnectProps,
  StoryCardContainerConnect,
  StoryCardContainerConnectProps,
  TilesConnect,
  TilesConnectProps,
  ValueComponentConnect,
  ValueComponentConnectProps
} from '../../connectors'

export interface ElementSelectProps extends Connector {
  imageLoadingPreference?: ImageLoadingPreference
  imagePriority?: boolean
  isIntroElement?: boolean
  pageTheme?: string
  textColor?: string | null
}

interface ElementProps {
  elementProps?:
    | AlternatingComponentConnectProps
    | AlternatingQuoteConnectProps
    | GalleryCarouselConnectProps
    | NextStepsConnectProps
    | ImpactTextConnectProps
    | KeepExploringConnectProps
    | StatisticsConnectProps
    | TilesConnectProps
    | BulletPointsConnectProps
    | MediaVideoConnectProps
    | MediaImageConnectProps
    | RegionCardsCarouselConnectProps
    | AccordionConnectProps
    | EmotionCardsCarouselConnectProps
    | ValueComponentConnectProps
    | StoryCardContainerConnectProps
    | EducationFairFormConnectProps
    | MapContainerConnectProps
}

const ElementSelect = ({
  typename,
  id,
  pageTheme,
  textColor,
  ...elementProps
}: ElementSelectProps & ElementProps): ReactElement | null => {
  switch (typename) {
    case 'SWNZ_Value':
      return (
        <ValueComponentConnect id={id} typename={typename} {...elementProps} />
      )
    case 'SWNZ_AlternatingContent':
      return (
        <AlternatingComponentConnect
          id={id}
          typename={typename}
          pageTheme={pageTheme}
          {...elementProps}
        />
      )
    case 'SWNZ_AlternatingQuote':
      return (
        <AlternatingQuoteConnect
          id={id}
          typename={typename}
          pageTheme={pageTheme}
          {...elementProps}
        />
      )
    case 'SWNZ_GalleryCarousel':
      return (
        <GalleryCarouselConnect id={id} typename={typename} {...elementProps} />
      )
    case 'SWNZ_NextSteps':
      return <NextStepsConnect id={id} typename={typename} {...elementProps} />
    case 'SWNZ_ImpactText':
      return (
        <ImpactTextConnect
          id={id}
          typename={typename}
          pageTheme={pageTheme}
          {...elementProps}
        />
      )
    case 'SWNZ_KeepExploring':
      return (
        <KeepExploringConnect id={id} typename={typename} {...elementProps} />
      )
    case 'SWNZ_StoryCardContainer':
      return (
        <StoryCardContainerConnect
          id={id}
          typename={typename}
          pageTheme={pageTheme}
          {...elementProps}
        />
      )
    case 'SWNZ_StatContainer':
      return <StatisticsConnect id={id} typename={typename} {...elementProps} />
    case 'SWNZ_TileContainer':
      return <TilesConnect id={id} typename={typename} {...elementProps} />
    case 'SWNZ_GenericText':
      return (
        <GenericTextConnect
          id={id}
          typename={typename}
          pageTheme={pageTheme}
          {...elementProps}
        />
      )
    case 'SWNZ_MediaVideo':
      return (
        <MediaVideoConnect
          id={id}
          typename={typename}
          pageTheme={pageTheme}
          {...elementProps}
        />
      )
    case 'SWNZ_MediaImage':
      return (
        <MediaImageConnect
          id={id}
          typename={typename}
          pageTheme={pageTheme}
          {...elementProps}
        />
      )
    case 'SWNZ_BulletPointContainer':
      return (
        <BulletPointsConnect id={id} typename={typename} {...elementProps} />
      )
    case 'SWNZ_CardContainer':
      return (
        <RegionCardsCarouselConnect
          id={id}
          typename={typename}
          {...elementProps}
        />
      )
    case 'SWNZ_AccordionContainer':
      return <AccordionConnect id={id} typename={typename} {...elementProps} />
    case 'SWNZ_EmotionContainer':
      return (
        <EmotionCardsCarouselConnect
          id={id}
          typename={typename}
          {...elementProps}
        />
      )
    case 'SWNZ_JumpAhead':
      return <JumpAheadConnect id={id} typename={typename} {...elementProps} />
    case 'SWNZ_IntroTextWithJumpMenu': {
      return (
        <IntroTextWithJumpMenuConnect
          id={id}
          typename={typename}
          textColor={textColor}
          {...elementProps}
        />
      )
    }
    case 'SWNZ_ComponentStatic':
      return <StaticComponentConnect id={id} typename={typename} />
    case 'SWNZ_FormLeadCapture':
      return (
        <EducationFormConnect id={id} typename={typename} {...elementProps} />
      )
    case 'SWNZ_MapContainer': {
      return (
        <MapContainerConnect id={id} typename={typename} {...elementProps} />
      )
    }
    default:
      return null
  }
}

export default ElementSelect
