import { Box } from '@chakra-ui/react'
import { SearchIcon } from 'icons'
import React from 'react'

interface SearchInputIconProps {
  searchButtonSize?: string
}

const SearchInputIcon = ({ searchButtonSize }: SearchInputIconProps) => {
  return (
    <Box
      display='flex'
      position='absolute'
      right={0}
      width={searchButtonSize}
      height={searchButtonSize}
      minWidth={searchButtonSize}
      justifyContent='center'
      alignItems='center'
      sx={{
        'html[lang=ar] &': {
          left: 0,
          right: 'unset'
        }
      }}
    >
      <Box boxSize={{ base: 8, lg: 9 }}>
        <SearchIcon />
      </Box>
    </Box>
  )
}

export default SearchInputIcon
