import { TEALIUM_EVENT } from "../helpers/constants";

interface Video {
  tealiumEvent: TEALIUM_EVENT;
  videoId: string;
  videoLength: number;
  videoName: string;
  videoPlatform: string;
}

interface VideoResult {
  video_id: string;
  video_length: number;
  video_name: string;
  video_platform: string;
  tealium_event: TEALIUM_EVENT;
}

/**
 * createVideoUtagEventObject
 * Build the data for a video_start or video_complete event
 */
function createVideoUtagEventObject({
  tealiumEvent,
  videoId,
  videoLength,
  videoName,
  videoPlatform,
}: Video): VideoResult {
  return {
    video_id: videoId,
    video_length: videoLength,
    video_name: videoName,
    video_platform: videoPlatform,
    tealium_event: tealiumEvent,
  };
}

export default createVideoUtagEventObject;
export type { Video, VideoResult };
