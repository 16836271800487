import { Box, GridItem } from '@chakra-ui/react'
import { SearchIcon } from 'icons'
import { ReactNode, useEffect, useState } from 'react'
import { CallToAction, CtaButton } from '../../buttons'
import ContainerWrapper from '../../layout/ContainerWrapper'
import GridLayout from '../../layout/GridLayout'
import { LargeText } from '../../typography'
import JumpAheadMenu from '../JumpAheadMenu'

interface IntroTextWithJumpMenuProps {
  primaryCta?: CallToAction | null
  children?: ReactNode | null
  leadCaptureFormId?: string
  textColor?: string | null
}

const IntroTextWithJumpMenu = ({
  primaryCta,
  children,
  leadCaptureFormId,
  textColor
}: IntroTextWithJumpMenuProps) => {
  const [isMounted, setIsMounted] = useState<boolean>(false)

  useEffect(() => {
    if (typeof window !== undefined) {
      setIsMounted(true)
    }

    return () => {
      setIsMounted(false)
    }
  }, [])

  return (
    <ContainerWrapper
      position='relative'
      {...(textColor === 'white'
        ? {
            sx: { color: textColor, '.chakra-link': { color: textColor } }
          }
        : {})}
    >
      <GridLayout>
        <GridItem colSpan={{ base: 4, lg: 5 }}>
          <Box mb={{ base: 8, lg: 4 }}>
            {isMounted && <JumpAheadMenu textColor={textColor} />}
          </Box>
        </GridItem>
        {children && (
          <GridItem colSpan={{ base: 4, lg: 6 }} colEnd={{ lg: 13 }}>
            <LargeText fontSize={{ base: '20px', md: '22px' }}>
              {children}
            </LargeText>
            {primaryCta?.label && (
              <CtaButton
                href={primaryCta.href}
                openInNewWindow={primaryCta.openInNewWindow}
                leadCaptureFormId={leadCaptureFormId}
                aria-label={primaryCta.label}
                modalId={primaryCta.modalId}
              >
                {primaryCta.label}
              </CtaButton>
            )}
          </GridItem>
        )}
      </GridLayout>
    </ContainerWrapper>
  )
}

export default IntroTextWithJumpMenu
export type { IntroTextWithJumpMenuProps }
