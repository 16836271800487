import {
  CardContainer_Swnz_CardContainerFragment,
  CardContainer_Swnz_CardContainerFragmentDoc
} from 'content-service'
import { colourMap } from 'utils/src/helpers'
import useFragment from 'utils/src/hooks/useFragment'
import {
  RegionCardLayoutType,
  RegionCardsCarousel
} from '../../components/content-components'
import { Connector } from '../types'
import { createRegionCardItems } from './helpers/regionCardsCarousel'

export type RegionCardsCarouselConnectProps = Connector

export enum RegionCardType {
  Link = 'link',
  Modal = 'modal'
}

const RegionCardsCarouselConnect = ({
  id,
  typename
}: RegionCardsCarouselConnectProps) => {
  const cards = useFragment<CardContainer_Swnz_CardContainerFragment>({
    id,
    typename,
    fragment: CardContainer_Swnz_CardContainerFragmentDoc,
    fragmentName: 'cardContainer_SWNZ_CardContainer'
  })

  const cardType =
    cards?.display === 'stacked'
      ? RegionCardLayoutType.Grid
      : RegionCardLayoutType.Carousel

  const cardItems = createRegionCardItems({
    cardModalItems: cards?.cardsWithModalCollection?.items,
    cardLinkItems: cards?.cardsWithLinkCollection?.items
  })

  if (!cardItems.length || !cardType) {
    return null
  }

  const { title = '' } = cards || {}

  const backgroundColor = cards?.backgroundColour
    ? colourMap(cards?.backgroundColour)
    : 'white'
  const applyCardBackgroundColor = cards?.applyCardBackgroundColour ?? false

  return (
    <RegionCardsCarousel
      title={title ?? ''}
      cards={cardItems}
      cardType={cardType}
      backgroundColor={backgroundColor}
      applyCardBackgroundColor={applyCardBackgroundColor}
    />
  )
}

export default RegionCardsCarouselConnect
