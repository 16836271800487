import {
  NavigationElements_Swnz_NavigationNavigationElementsCollectionFragment,
  ParentNaumaiPagePath_Swnz_NaumaiPageStandardFragment,
  ParentPagePath_Swnz_PageFragment
} from 'content-service'
import { isPresent } from 'ts-is-present'
import { NavLink } from 'ui'
import { isExternalUrl } from './isExternalUrl'
import { buildPath, orderSlugs, slugify } from './slug'
export interface CreateLinks {
  linkItems?: Pick<
    NavigationElements_Swnz_NavigationNavigationElementsCollectionFragment,
    'items'
  > | null
  parentLink?: string | null
}

export interface CreateInternalLinks {
  linkItems:
    | Pick<
        NavigationElements_Swnz_NavigationNavigationElementsCollectionFragment,
        'items'
      >
    | undefined
    | null
  parentSlug?: Array<string>
}
export interface InternalLink {
  slugs: Array<string>
  children: Array<InternalLink>
}

export type InternalLinks = Array<InternalLink>

export interface FlattenedLinks {
  links?: Array<InternalLink> | null
}
export type FlattenedLinksResult = string[][]

export interface CreateInternalLinkSlugListProps {
  navElements?: Pick<
    NavigationElements_Swnz_NavigationNavigationElementsCollectionFragment,
    'items'
  > | null
}

export type CreateInternalLinkSlugListResult = string[][]

interface GetSlug {
  internalPageLink?:
    | ParentNaumaiPagePath_Swnz_NaumaiPageStandardFragment
    | ParentPagePath_Swnz_PageFragment
    | null
  customUrl?: string | null
  title?: string | null
}

export const createLinks = ({
  linkItems,
  parentLink
}: CreateLinks): Array<NavLink> => {
  if (!linkItems) {
    return []
  }
  return linkItems.items.filter(isPresent).map((item) => {
    const {
      internalPageLink,
      title,
      sys: { id },
      customUrl,
      openLinkInNewWindow,
      navigationElementsCollection
    } = item

    let slug = getSlug({ internalPageLink, customUrl, title })

    if (parentLink) {
      slug = `${parentLink}${slug}`
    }

    const children = navigationElementsCollection
      ? createLinks({
          linkItems: navigationElementsCollection,
          parentLink: sanitiseURL(slug)
        })
      : []

    return {
      id,
      ...(openLinkInNewWindow && { openLinkInNewWindow }),
      ...(customUrl && { isExternalLink: isExternalUrl(customUrl) }),
      title: title ?? '',
      slug: sanitiseURL(customUrl ?? slug),
      children
    }
  })
}

/**
 * Build out nested list of slug arrays
 *
 * This is useful for places like getStaticPaths to help with creating a flat list of all internal paths
 *
 * This function assumes that linkItems with internalPageLinks or has navigationElementsCollection items should be used.
 * Internal and external links in customUrl's are ignored as they could have pages that have already been built out in their own pages
 *
 * e.g. [
      {
        slugs: [],
        children: [
          {
            slugs: [ 'schools' ],
            children: [
              {
                slugs: [ 'schools', 'the-nz-school-system' ],
                children: []
              },
              { slugs: [ 'schools', 'find-a-school' ], children: [] }
            ]
          }
        ]
      },
      {
        slugs: [],
        children: [ { slugs: [ 'covid-and-borders' ], children: [] } ]
      },
      { slugs: [ '/' ], children: [] },
      { slugs: [], children: [] }
    ]
 * @param param0
 * @returns
 */
const internalLinks = ({
  linkItems,
  parentSlug
}: CreateInternalLinks): InternalLinks => {
  if (!linkItems) {
    return []
  }

  const list = linkItems.items
    .filter(isPresent)
    .filter(
      (item) =>
        item.internalPageLink !== null ||
        item.navigationElementsCollection?.items.length
    )
    .map((item) => {
      const { internalPageLink, navigationElementsCollection, title } = item

      let slugs = internalPageLink
        ? orderSlugs({ pageResult: internalPageLink })
        : [`${slugify(title ?? '')}`]

      slugs = parentSlug ? [...parentSlug, ...slugs] : [...slugs]

      const children = navigationElementsCollection
        ? internalLinks({
            linkItems: navigationElementsCollection,
            parentSlug: slugs
          })
        : []

      return {
        slugs,
        children
      }
    })

  return list
}

/**
 * Flatten the result from internalLinks()
 * e.g.
    [
      ['schools'],
      ['schools', 'the-nz-school-system'],
      ['schools', 'find-a-school'],
      ['covid-and-borders'],
    ]
 * @param param0
 * @returns
 */
const flattenSlugs = ({ links }: FlattenedLinks): FlattenedLinksResult => {
  if (!links) {
    return []
  }

  const slugArray = links
    .reduce<FlattenedLinksResult>((acc, link) => {
      const { slugs, children } = link
      if (!children.length) {
        return [...acc, slugs]
      }

      return [...acc, slugs, ...flattenSlugs({ links: children })]
    }, [])
    .filter((item) => !!item.length && !item.includes('/'))

  return slugArray
}

export const createInternalLinkSlugList = ({
  navElements
}: CreateInternalLinkSlugListProps): CreateInternalLinkSlugListResult => {
  return flattenSlugs({
    links: internalLinks({ linkItems: navElements })
  })
}

function sanitiseURL(url: string) {
  if (url.startsWith('http')) {
    return url
  }

  return url.startsWith('/') ? url : `/${url}`
}

function getSlug({ internalPageLink, customUrl, title }: GetSlug): string {
  if (internalPageLink) {
    return buildPath({
      slugs: orderSlugs({ pageResult: internalPageLink })
    })
  }

  if (customUrl) {
    return customUrl
  }

  return `/${slugify(title ?? '')}`
}
