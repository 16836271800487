import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getYouTubeVideoId } from "utils/src/helpers";
import { handleUtagVideoTracking } from "../helpers";
import { TEALIUM_EVENT } from "../helpers/constants";

interface VideoItem {
  snippet: { title: string };
}

interface VideoResult {
  items: Array<VideoItem>;
}

type UseYouTubeDataResult = [VideoResult | null, boolean];
type UseYouTubeTitleResult = [
  Dispatch<SetStateAction<boolean>>,
  Dispatch<SetStateAction<boolean>>,
];

const YOUTUBE_DATA_API_URL = "https://youtube.googleapis.com/youtube/v3/";

function useTrackVideo(
  sourceUrl: string,
  duration: number,
): UseYouTubeTitleResult {
  // Load title on mount of component loading this hook
  const [data, isLoading] = useYouTubeData(sourceUrl);
  const [startVideoTracking, setStartVideoTracking] = useState(false);
  const [endVideoTracking, setEndVideoTracking] = useState(false);
  const title = data?.items?.[0]?.snippet?.title ?? "";

  // When we have the title and the user has started the video trigger the
  // video start event
  useEffect(() => {
    if (startVideoTracking && !isLoading) {
      handleUtagVideoTracking(
        { sourceUrl, duration, name: title },
        TEALIUM_EVENT.VIDEO_START,
      );
      setStartVideoTracking(false);
    }
  }, [startVideoTracking, title, isLoading, duration, sourceUrl]);

  // When we have the title and the user has completed the video trigger the
  // video end event
  useEffect(() => {
    if (endVideoTracking && !isLoading) {
      handleUtagVideoTracking(
        { sourceUrl, duration, name: title },
        TEALIUM_EVENT.VIDEO_COMPLETE,
      );
      setEndVideoTracking(false);
    }
  }, [endVideoTracking, title, isLoading, duration, sourceUrl]);

  return [setStartVideoTracking, setEndVideoTracking];
}

export function useYouTubeData(sourceUrl: string): UseYouTubeDataResult {
  const [data, setData] = useState<VideoResult | null>(null);
  const [isLoading, setLoading] = useState(false);

  const videoId = getYouTubeVideoId(sourceUrl);
  const getParams = new URLSearchParams({
    key: process.env.NEXT_PUBLIC_GOOGLE_YOUTUBE_API_KEY ?? "",
    part: "snippet",
    id: videoId,
  });
  const url = `${YOUTUBE_DATA_API_URL}videos?${getParams}`;

  useEffect(() => {
    setLoading(true);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData(data);
        setLoading(false);
      });
  }, [url]);

  return [data, isLoading];
}

export default useTrackVideo;
