import { HeroMediaItem_Swnz_HeroMediaItemFragment } from 'content-service'

const blankProps = {
  src: '',
  title: '',
  alt: '',
  priority: true,
  key: ''
}

export const createHeroImageProps = (
  mediaItem: HeroMediaItem_Swnz_HeroMediaItemFragment | undefined | null
) => {
  if (mediaItem?.__typename === 'SWNZ_MediaImage') {
    return {
      src: mediaItem.source?.url || '',
      title: mediaItem.source?.title || '',
      alt: mediaItem.source?.description || '',
      priority: true,
      key: mediaItem.source?.url || ''
    }
  }

  if (mediaItem?.__typename === 'SWNZ_MediaVideo') {
    return {
      src: mediaItem.thumbnail?.url || '',
      title: mediaItem.thumbnail?.url || '',
      alt: mediaItem.thumbnail?.url || '',
      priority: true,
      key: mediaItem.thumbnail?.url || ''
    }
  }

  return blankProps
}
