import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box
} from '@chakra-ui/react'
import { CloseIcon } from 'icons'
import { Button } from '../buttons'

interface PreviewBannerProps {
  preview?: boolean
}

const PreviewBanner = ({ preview = false }: PreviewBannerProps) => {
  return preview ? (
    <Alert status='warning'>
      <AlertIcon />
      <AlertTitle mr={2}>Preview Mode is turned on</AlertTitle>
      <AlertDescription>
        This enables viewing unpublished changes.
      </AlertDescription>
      <Box ml='auto'>
        <Button
          href='/api/exit-preview'
          variant='unstyled'
          aria-label='Click to exit preview mode'
          p={2}
          leftIcon={<CloseIcon top={6} />}
        >
          Click to exit preview mode
        </Button>
      </Box>
    </Alert>
  ) : null
}

export default PreviewBanner
