import { Flex } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { Link } from '../../Link'

export const homeLink = {
  id: '0',
  title: 'Homepage',
  slug: '/'
}

export const Header = () => {
  const { t } = useTranslation('navigation')

  return (
    <Flex p={6} pl={9} borderBottom='1px solid rgba(255, 255, 255, 0.1)'>
      <Link
        href={homeLink.slug}
        textDecoration='none'
        fontFamily='enz400'
        color='white'
        _hover={{ color: 'navHover' }}
        _focus={{ outline: 'none' }}
      >
        {t('header')}
      </Link>
    </Flex>
  )
}
