import { Cta_Swnz_CallToActionFragment } from 'content-service'
import { CallToAction } from 'ui'
import { createHref } from './createHref'
import { isExternalUrl } from './isExternalUrl'

// Create CTA Button to be used globally
// Function takes in a CallToAction object and transforms
// to return an object that can be used in the ui-components
const createCtaButton = (
  cta?: Cta_Swnz_CallToActionFragment | null
): CallToAction | undefined => {
  if (!cta) {
    return
  }

  return {
    label: cta.label ?? '',
    href: createHref(cta),
    openInNewWindow: cta.openInNewWindow ?? false,
    isExternalLink: cta?.href
      ? isExternalUrl(cta?.href) && !cta.internalLink
      : false,
    modalId: cta.modalId ?? ''
  }
}

export { createCtaButton }
