import { AspectRatio, Box, Flex } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { useMediaQuery } from 'utils/src/helpers'
import { SmallText } from '../../typography'
import GalleryIndicator from './GalleryIndicator'
import GalleryNavigationButton from './GalleryNavigationButton'

export interface GalleryItems {
  image: ReactElement | null
  caption?: string
  video?: string
}

export interface GalleryItemsProps {
  galleryItems: GalleryItems[]
  centerSlidePercentage?: number
}

const galleryGap = { base: '5px', lg: 3 }

const GalleryVideoModal = dynamic(() => import('./GalleryVideoModal'))

const Gallery = ({
  galleryItems,
  centerSlidePercentage
}: GalleryItemsProps) => {
  const isDesktop = useMediaQuery('(min-width: 992px)')
  const imageRef = useRef<HTMLImageElement>(null)
  const [imageWidth, setImageWidth] = useState<number>(0)
  const [state, setState] = useState<{
    numberOfSlides: number
    currentSlide: number
  }>({
    numberOfSlides: galleryItems.length,
    currentSlide: 0
  })

  const updateCurrentSlide = (index: number) => {
    if (state.currentSlide !== index) {
      setState({
        ...state,
        ...{ currentSlide: index }
      })
    }
  }

  const handleResize = () => {
    setImageWidth(imageRef?.current?.getBoundingClientRect().width || 0)
  }

  useEffect(() => {
    setImageWidth(imageRef?.current?.getBoundingClientRect().width || 0)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [imageRef])

  const carouselProps = {
    centerMode: true,
    autoPlay: false,
    infiniteLoop: false,
    swipeable: true,
    showThumbs: false,
    showStatus: false,
    showIndicators: false
  }

  if (!galleryItems) {
    return null
  }

  const carouselOverrideCss = {
    'li.slide': {
      '.gallery-image-container': {
        px: galleryGap
      },
      _first: {
        '.gallery-image-container': {
          pl: 0
        }
      },
      _last: {
        '.gallery-image-container': {
          pr: 0
        }
      }
    },
    'html[lang=ar] &': {
      //actually ltr
      direction: 'rtl'
    }
  }
  return (
    <Box position='relative'>
      <Carousel
        {...carouselProps}
        //if show more is true, shows more of the next and previous slide
        centerSlidePercentage={
          centerSlidePercentage ? centerSlidePercentage : !isDesktop ? 90 : 80
        }
        onChange={updateCurrentSlide}
        selectedItem={state.currentSlide}
        renderArrowPrev={(
          onClickHandler: () => void,
          hasPrev: boolean,
          label: string
        ) => (
          <GalleryNavigationButton
            onClickHandler={onClickHandler}
            hasNextPrev={hasPrev}
            label={label}
            type='previous'
          />
        )}
        renderArrowNext={(
          onClickHandler: () => void,
          hasNext: boolean,
          label: string
        ) => (
          <GalleryNavigationButton
            onClickHandler={onClickHandler}
            hasNextPrev={hasNext}
            label={label}
            type='next'
          />
        )}
      >
        {galleryItems.map((galleryItem: GalleryItems, index) => {
          const { image, video } = galleryItem

          return (
            <Box key={index}>
              <AspectRatio ratio={isDesktop ? 16 / 9 : 3 / 2}>
                <Box
                  className='gallery-image-container'
                  ref={index === state.currentSlide ? imageRef : null}
                  pos='relative'
                >
                  <Box
                    position='relative'
                    overflow='hidden'
                    boxSize='full'
                    _after={{
                      content: '""',
                      position: 'absolute',
                      display: index !== state.currentSlide ? 'block' : 'none',
                      boxSize: 'full',
                      transition: '0.2s ease-in',
                      background: 'rgba(7, 25, 55, 0.4)',
                      top: 0,
                      right: 0,
                      left: 0,
                      'html[lang=ar] &': {
                        right: 0,
                        left: galleryGap
                      }
                    }}
                  >
                    {image}
                  </Box>
                </Box>
              </AspectRatio>
              {video && <GalleryVideoModal video={video} />}
            </Box>
          )
        })}
      </Carousel>
      <Flex
        width='full'
        mt={{ base: 4, lg: 5 }}
        alignItems='center'
        justifyContent='space-between'
        minHeight={{ base: 2, md: 5 }}
      >
        <SmallText color='deepblue.300' lineHeight={5} mb={0} mr={2}>
          {galleryItems[state.currentSlide]?.caption || ''}
        </SmallText>
        <GalleryIndicator
          slideAmount={state.numberOfSlides}
          currentSlide={state.currentSlide}
          handleClick={updateCurrentSlide}
        />
      </Flex>
    </Box>
  )
}

export default Gallery
