import { AutocompleteReshapeSource, BaseItem } from '@algolia/autocomplete-core'

// We filter out falsy values because dynamic sources may not exist at every render.
// We flatten to support pipe operators from functional libraries like Ramda.
export function normalizeReshapeSources<TItem extends BaseItem>(
  sources: Array<AutocompleteReshapeSource<TItem>>
) {
  return sources.flat().filter(Boolean)
}

export type AutocompleteReshapeFunction<TParams = any> = <
  TItem extends BaseItem
>(
  ...params: TParams[]
) => (
  ...expressions: Array<AutocompleteReshapeSource<TItem>>
) => Array<AutocompleteReshapeSource<TItem>>

type UniqByPredicate<TItem extends BaseItem> = (params: {
  source: AutocompleteReshapeSource<TItem>
  item: TItem
}) => TItem

export const uniqBy: AutocompleteReshapeFunction<UniqByPredicate<any>> = <
  TItem extends BaseItem
>(
  // @ts-ignore
  predicate
) => {
  return function runUniqBy(...rawSources) {
    const sources = normalizeReshapeSources(rawSources)
    const seen = new Set<TItem>()

    return sources.map((source) => {
      const items = source.getItems().filter((item) => {
        const appliedItem = predicate({ source, item })
        const hasSeen = seen.has(appliedItem)

        seen.add(appliedItem)

        return !hasSeen
      })

      return {
        ...source,
        getItems() {
          return items
        }
      }
    })
  }
}
