import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement
} from '@chakra-ui/react'
import { isValid } from 'date-fns'
import { ErrorMessage, useField } from 'formik'
import { CalendarIcon } from 'icons'
import React, { FocusEvent, useEffect, useState } from 'react'

export type InputControlProps = FormControlProps & InputProps

export interface StyleInputProps extends InputControlProps {
  name: string
  label?: string
  helpText?: string
  hasIcon?: React.ReactNode
  isDate?: boolean
}

const StyleInput = ({
  label,
  helpText,
  hasIcon,
  onBlur,
  isDate,
  ...props
}: StyleInputProps) => {
  const [field, meta, { setValue }] = useField(props.name)
  const { value } = field

  const [isFocused, setFocused] = React.useState(false)
  const [date, setDate] = useState<Date | null>(isDate ? value : null)
  const onFocus = () => setFocused(!isFocused)

  const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
    setFocused(false)
    onBlur?.(e)
  }

  const errorTouched = meta.error && meta.touched
  const addErrorClass = errorTouched ? 'error ' : ''
  const toggleClass =
    isFocused || value || props.type === 'date' ? addErrorClass + 'active' : ''

  //If input field is date, set value to date
  useEffect(() => {
    if (isDate && isValid(date)) {
      setValue(date)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  return (
    <FormControl
      id={props.name}
      isInvalid={errorTouched ? true : false}
      isRequired={props.isRequired}
      position='relative'
      lineHeight='none'
      overflow='hidden'
      _hover={{
        'label:not(.active)': {
          color: 'deepblue.500'
        }
      }}
      {...field}
    >
      <InputGroup>
        <Input
          height='3.75rem'
          px='1rem'
          pt='1rem'
          color='deepblue.500'
          onFocus={onFocus}
          onBlur={handleBlur}
          onChange={(e) => isDate && setDate(new Date(e.target.value))}
          defaultValue={value}
          className={toggleClass}
          {...props}
          sx={{
            '&.active': {
              color: 'deepblue.500',
              '&.error': {
                color: 'red.400'
              }
            },
            '&::-webkit-calendar-picker-indicator': {
              display: 'none'
            },
            '&::-webkit-date-and-time-value': {
              textAlign: 'left'
            }
          }}
        />
        {hasIcon && (
          <InputRightElement height='full' width='3.75rem'>
            {props.type === 'date' ? (
              <Box boxSize={6}>
                <CalendarIcon />
              </Box>
            ) : (
              hasIcon
            )}
          </InputRightElement>
        )}
      </InputGroup>
      <FormLabel
        color='deepblue.300'
        position='absolute'
        top='22px'
        left='1rem'
        m={0}
        cursor='text'
        transition='all 0.3s'
        lineHeight='none'
        className={toggleClass}
        sx={{
          pointerEvents: 'none',
          '&.active': {
            top: '12px',
            left: '18px',
            fontSize: '0.75rem',
            color: 'teal.500',
            '&.error': {
              color: 'red.400'
            }
          },
          'html[lang=ar] &': {
            /* GOTCHA!
             * This is acutally align 'left'
             * stylis RTL plugin changes this to 'left' for us */
            right: '1rem',
            left: 0
          }
        }}
      >
        {label}
      </FormLabel>
      <FormErrorMessage mb={2} pl={4}>
        <ErrorMessage name={props.name} />
      </FormErrorMessage>
      {!value && !errorTouched && helpText && (
        <FormHelperText
          as='span'
          fontSize='sm'
          mt={1}
          pl={4}
          display='inline-block'
        >
          {helpText}
        </FormHelperText>
      )}
    </FormControl>
  )
}
export default StyleInput
