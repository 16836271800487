import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Flex, Text } from '@chakra-ui/react'
import { MapLocationIcon } from 'icons'
import NextChakraLink, { NextLinkProps } from '../../Link/Link'

interface MapContainerLinkProps extends NextLinkProps {
  label: string
}

const MapContainerLink = ({ label, href, ...props }: MapContainerLinkProps) => {
  return (
    <NextChakraLink
      display='flex'
      justifyContent='space-between'
      alignItems='center'
      borderTop='1px'
      borderTopColor='deepblue.500'
      color='deepblue.500'
      _hover={{ color: 'teal.500' }}
      paddingY={3}
      mb={3}
      href={href}
      textDecoration='none'
      {...props}
    >
      <Flex alignItems='center'>
        <Box boxSize={{ base: 6, lg: 8 }} mr={2}>
          <MapLocationIcon />
        </Box>
        <Text
          fontSize={{ base: 'sm', lg: 'md' }}
          lineHeight={{ base: '20px', lg: '28px' }}
          fontFamily={{ base: 'enz500', lg: 'enz700' }}
          mb={0}
        >
          {label}
        </Text>
      </Flex>
      <ChevronRightIcon />
    </NextChakraLink>
  )
}

export default MapContainerLink
