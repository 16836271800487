import { Box, Img } from '@chakra-ui/react'

// CSS replicates NextChakraImage (used in /apps/app) component CSS output
const MockChildImage = ({
  src,
  objectFit = 'cover',
  ...rest
}: {
  src: string
  alt?: string
  title?: string
  objectFit?: 'cover' | 'contain'
}) => {
  return (
    <Box
      display='block'
      overflow='hidden'
      position='absolute'
      inset='0'
      boxSizing='border-box'
      margin='0'
    >
      <Img
        src={src}
        {...rest}
        position='absolute'
        inset='0'
        boxSizing='border-box'
        padding='0'
        border='none'
        margin='auto'
        display='block'
        width='0'
        height='0'
        minWidth='100%'
        maxWidth='100%'
        minHeight='100%'
        maxHeight='100%'
        objectFit={objectFit}
      />
    </Box>
  )
}

export default MockChildImage
