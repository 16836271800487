import { SearchState } from 'react-instantsearch-core'

{
  /** Function to set the location to New Zealand when a condition is met, else set to default searchState*/
}
function setNZLocation(
  searchState: SearchState,
  refinementCondition?: boolean
) {
  if (refinementCondition)
    return (searchState = {
      ...searchState,
      refinementList: {
        ...(searchState?.refinementList ?? {}),
        'countries.name': [
          ...(searchState?.refinementList?.['countries.name'] ?? []),
          'New Zealand'
        ]
      }
    })
  else return searchState
}

export default setNZLocation
