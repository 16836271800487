import { getApolloContext } from '@apollo/client'
import { TypedDocumentNode } from '@graphql-typed-document-node/core'
import { useContext } from 'react'
import { cacheId } from 'utils'

interface FragmentOrQueryProps<T> {
  id: string
  typename: string
  fragment: TypedDocumentNode<T, unknown>
  fragmentName: string
}

type FragmentResult<T> = T | null

const useFragment = <T,>({
  id,
  typename,
  fragment,
  fragmentName
}: FragmentOrQueryProps<T>): FragmentResult<T> => {
  const { client } = useContext(getApolloContext())

  if (!client) {
    return null
  }

  const data = client.readFragment({
    id: cacheId(typename, id),
    fragment,
    fragmentName
  })

  return data
}

export default useFragment
