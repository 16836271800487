import { Box, ButtonGroup } from '@chakra-ui/react'
import { CloseIcon } from 'icons'
import { MouseEvent } from 'react'
import {
  CurrentRefinementsProvided,
  Refinement,
  RefinementValue
} from 'react-instantsearch-core'
import { Pill } from 'ui'
import { connectCurrentRefinements } from '..'
import getLabel from '../DependantRefinementList/helpers/getLabel'
interface FacetItem {
  label: string
  value: RefinementValue
}
interface FilterPillProps {
  facetItems: FacetItem[] | undefined
  refine: () => void
}

type FinderFilterPillProps = CurrentRefinementsProvided

const FilterPill = ({ facetItems = [], refine }: FilterPillProps) => {
  const [item] = facetItems

  const label = getLabel(item?.label)
  return (
    <>
      {label && (
        <Pill
          flexShrink={0}
          onClick={(event: MouseEvent<HTMLButtonElement>) => {
            event.preventDefault()
            refine()
          }}
          rightIcon={<CloseIcon boxSize={3.5} />}
        >
          {label}
          {facetItems.length > 1 && ` +${facetItems.length - 1}`}
        </Pill>
      )}
    </>
  )
}

const FinderFilterPill = ({ items, refine }: FinderFilterPillProps) => {
  const handleRefine = (facetItems: Refinement[]) => {
    refine(facetItems)
  }

  return (
    <>
      {items.length > 0 && (
        <Box
          overflow='auto'
          whiteSpace='nowrap'
          mt={{ base: 4, lg: 0 }}
          mx={{ base: -6, lg: 4 }}
          px={{ base: 6, lg: 'unset' }}
          pb={{ base: 3, lg: 'unset' }}
        >
          <ButtonGroup spacing='10px'>
            {items.map((facetItems, index) => (
              <FilterPill
                key={`${facetItems.attribute}-${index}`}
                facetItems={facetItems.items}
                refine={() => handleRefine([facetItems])}
              />
            ))}
          </ButtonGroup>
        </Box>
      )}
    </>
  )
}

const FinderFilterPillComponent = ({
  ignoreRefinements
}: FinderFilterPillProps & { ignoreRefinements?: string[] }) => {
  const ComponentWith = connectCurrentRefinements<FinderFilterPillProps>(
    FinderFilterPill,
    ignoreRefinements
  )
  return <ComponentWith />
}

export default FinderFilterPillComponent
