import {
  Button as ChakraButton,
  ButtonProps as ChakraButtonProps,
  IconButton as ChakraIconButton,
  IconButtonProps as ChakraIconButtonProps
} from '@chakra-ui/react'
import { LinkProps as NextLinkProps } from 'next/dist/client/link'
import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { forwardRef, MouseEventHandler, ReactNode } from 'react'

import { getHrefForLocale, isHrefExternal, Optional } from 'utils'

type NextLinkType = Omit<Optional<NextLinkProps, 'href'>, 'as' | 'onClick'>

type ChakraIconButtonType = Omit<
  Optional<ChakraIconButtonProps, 'aria-label'>,
  'onClick'
>

type ButtonProps = NextLinkType &
  ChakraIconButtonType &
  ChakraButtonProps & {
    target?: string
    openInNewWindow?: boolean
    children?: ReactNode | undefined
    refreshPageOnRouteChange?: boolean
    onClick?: MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>
  }

/**
 * Pick between a Button or IconButton
 */
const ButtonSelect = forwardRef<any, ButtonProps>((props: ButtonProps, ref) => {
  const { icon, 'aria-label': ariaLabel } = props
  if (icon && ariaLabel !== undefined) {
    return <ChakraIconButton ref={ref} {...props} aria-label={ariaLabel} />
  } else {
    return <ChakraButton ref={ref} {...props} />
  }
})

ButtonSelect.displayName = 'ButtonSelect'

// Has to be a new component because both chakra and next share the `as` keyword
const Button = forwardRef<any, ButtonProps>(
  (
    {
      href,
      as,
      replace,
      scroll,
      shallow,
      prefetch,
      passHref,
      openInNewWindow,
      refreshPageOnRouteChange = false,
      ...chakraProps
    }: ButtonProps,
    ref
  ) => {
    const { locale, locales, defaultLocale } = useRouter()

    const hasExternalHref: boolean = isHrefExternal(href)

    if (href === undefined || href === '') {
      // If no href is passed to Button we assume it should be treated as a button and shouldn't be wrapped by NextLink
      return <ButtonSelect ref={ref} {...chakraProps} />
    } else if (refreshPageOnRouteChange) {
      // If refreshPageOnRouteChange is passed to Button we assume it should be treated as a link but shouldn't be wrapped by NextLink,
      // so that the page undergoes a full refresh on route change
      return <ButtonSelect ref={ref} href={href} as='a' {...chakraProps} />
    } else if (hasExternalHref) {
      return (
        <ButtonSelect
          ref={ref}
          href={href}
          as={NextLink}
          target='_blank'
          rel='noopener noreferrer'
          {...chakraProps}
        />
      )
    } else {
      const localeHref =
        getHrefForLocale(href, locale, locales, defaultLocale) ?? ''
      return (
        <ButtonSelect
          as={NextLink}
          href={localeHref}
          target={openInNewWindow ? '_blank' : undefined}
          rel={openInNewWindow ? 'noopener noreferrer' : undefined}
          replace={replace}
          scroll={scroll}
          shallow={shallow}
          prefetch={prefetch}
          {...chakraProps}
        />
      )
    }
  }
)

Button.displayName = 'Button'

export default Button
export type { ButtonProps }
