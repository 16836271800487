import {
  AspectRatio,
  Box,
  BoxProps,
  chakra,
  Flex,
  Text
} from '@chakra-ui/react'
import { ChevronCircle, ExpandRoundedIcon } from 'icons'
import { ReactElement, ReactNode } from 'react'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { createInitStoryModalCustomEvent } from 'utils/src/helpers'
import { Button } from '../../buttons'
import { H4, SmallBoldText } from '../../typography'

export interface RegionCardProps {
  id: string
  type: RegionCardType
  title?: string
  description?: string
  image: ReactElement | null
  link?: {
    slug: string
    title: string
    external: boolean
  }
  modalId?: string
  backgroundColor?: string
  color?: string
  addContentBorder?: boolean
  tag?: string
}

export type RegionWrapperProps = Pick<
  RegionCardProps,
  'type' | 'link' | 'modalId' | 'title'
> & {
  children: ReactNode
}

enum RegionCardType {
  Link = 'link',
  Modal = 'modal'
}

const RegionTypeSelect = chakra(
  ({ type, children, link, modalId, title, ...props }: RegionWrapperProps) => {
    if (link && type === RegionCardType.Link) {
      const newWindow = link.external
        ? { target: '_blank', rel: 'noopener noreferrer' }
        : {}

      return (
        <a
          href={link?.slug ?? ''}
          onClick={() => {
            createUtagLinkClickEvent({
              linkLabel: link?.title
                ? link?.title
                : link.external
                  ? title ?? 'Link to external site'
                  : 'Link to Study With New Zealand page',
              destinationUrl: link.slug ?? '',
              linkCategory: TEALIUM_LINK_CATEGORY.CTA
            })
          }}
          {...newWindow}
          {...props}
        >
          {children}
        </a>
      )
    }

    return (
      <Box
        role='button'
        {...props}
        onClick={() =>
          modalId ? createInitStoryModalCustomEvent(modalId, title) : false
        }
      >
        {children}
      </Box>
    )
  }
)

const RegionCard = ({
  id,
  type,
  title,
  description,
  image,
  link,
  modalId,
  backgroundColor,
  color,
  tag,
  addContentBorder,
  ...props
}: RegionCardProps & BoxProps) => {
  return (
    <RegionTypeSelect
      h='100%'
      key={id}
      type={type}
      link={link}
      modalId={modalId}
      title={title}
    >
      <Box
        pt={{ lg: 2 }}
        display='flex'
        flexDirection='column'
        h='100%'
        className='card-container'
        {...props}
      >
        <Box
          w='100%'
          position='relative'
          sx={{
            '.card-container:hover &': {
              opacity: '0.8'
            }
          }}
        >
          <AspectRatio ratio={3 / 2}>{image}</AspectRatio>
        </Box>
        <Flex
          flexGrow={{ base: 1 }}
          flexDirection='column'
          backgroundColor={backgroundColor}
          p={{ base: 5, lg: 7 }}
          textAlign='left'
          color={color}
          {...(addContentBorder
            ? {
                borderColor: 'teal.200',
                borderLeftWidth: '1px',
                borderRightWidth: '1px',
                borderBottomWidth: '1px'
              }
            : { border: 'none' })}
        >
          {title && <H4 mb={2}>{title}</H4>}

          {description && (
            <Text
              mb={1}
              whiteSpace='break-spaces'
              overflowX='hidden'
              overflow='hidden'
              textOverflow='ellipsis'
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '4'
              }}
            >
              {description}
            </Text>
          )}
          <Flex
            justifyContent={tag ? 'space-between' : 'flex-end'}
            alignItems='flex-end'
            flexGrow={{ base: 1 }}
            paddingTop={4}
          >
            {tag ? (
              <Box
                paddingX={4}
                paddingY={2}
                borderRadius='22px'
                as='span'
                border='2px'
                borderColor='deepblue.300'
              >
                <SmallBoldText lineHeight='22px' color='deepblue.300' mb={0}>
                  {tag}
                </SmallBoldText>
              </Box>
            ) : null}
            <Button
              variant='unstyled'
              display='flex'
              justifyContent='center'
              alignItems='center'
              h='auto'
              aria-label={link?.title ?? 'Link to Study With New Zealand page'}
              cursor='pointer'
              icon={
                type === RegionCardType.Modal ? (
                  <Box
                    boxSize={10}
                    sx={{
                      '.card-container:hover &': {
                        '.expand-rounded-icon--up-chevron': {
                          fill: backgroundColor === 'white' ? 'white' : 'black'
                        },
                        '.expand-rounded-icon--down-chevron': {
                          fill: backgroundColor === 'white' ? 'white' : 'black'
                        }
                      }
                    }}
                  >
                    <ExpandRoundedIcon />
                  </Box>
                ) : (
                  <Box
                    boxSize={10}
                    sx={{
                      '.card-container:hover &': {
                        '.chevron-circle--chevron': {
                          fill: backgroundColor === 'white' ? 'white' : 'black'
                        }
                      },
                      'html[lang=ar] &': {
                        transform: 'scaleX(-1)'
                      }
                    }}
                  >
                    <ChevronCircle />
                  </Box>
                )
              }
              sx={{
                '.card-container:hover &': {
                  backgroundColor:
                    backgroundColor === 'white' ? 'deepblue.500' : 'white',
                  borderRadius: 'full'
                }
              }}
            />
          </Flex>
        </Flex>
      </Box>
    </RegionTypeSelect>
  )
}

export default RegionCard
