import { TrackingMap } from "../clicks/inventorySearchCriteria";

enum TEALIUM_EVENT {
  VIEW_INVENTORY_ITEM = "view_inventory_item",
  PAGE_VIEW = "page_view",
  PROVIDER_REFERRAL = "provider_referral",
  LINK_CLICK = "link_click",
  FILTER_UPDATE = "filter_update",
  SITE_NAV = "site_nav",
  INVENTORY_SEARCH_CRITERIA = "inventory_search_criteria",
  START_ENQUIRY = "start_enquiry",
  VIDEO_START = "video_start",
  VIDEO_COMPLETE = "video_complete",
  FORM_SUBMISSION = "form_submission",
  SHOW_POPUP = "show_popup",
  POPUP_VIEWED = "popup_viewed",
  POPUP_RESPONSE = "popup_response",
  ERROR_PAGE_VIEW = "404_error",
}

const TEALIUM_POPUP_RESULT = {
  MINIMISED: "minimised",
  MAXIMISED: "maximised",
  DISMISSED: "dismissed",
  COMPLETED: "completed",
  CLICKED: "clicked",
} as const;

enum TEALIUM_LINK_CATEGORY {
  HOMEPAGE = "homepage",
  FOOTER = "footer",
  SOCIAL = "social",
  SITE_NAV = "site nav",
  STORY_MODAL = "story_modal",
  CTA = "CTA",
  SELECTION = "selection",
  CONTENT = "content",
  POPULAR_SEARCH = "popular search",
  OUTBOUND = "outbound",
  MOOD_SELECTION = "mood selector",
}

const mapTealiumTrackingProviderResultFields: TrackingMap = {
  "subtypeList.name": "subtype",
  "countries.name": "countries",
  "regions.name": "regions",
  cities: "cities",
  name: "providerName",
};

const mapTealiumTrackingCourseResultFields: TrackingMap = {
  "deliveryModes.name": "deliveryModes",
  "levelOfStudy.name": "levelOfStudy",
  "areasOfStudy.name": "areasOfStudy",
  "countries.name": "countries",
  "providerSubtype.name": "providerSubtype",
  regionsWithCountries: "regionsWithCountries",
  providerName: "providerName",
};

const mapTealiumTrackingScholarshipResultFields: TrackingMap = {
  nationalities: "nationalities",
  providerName: "providerName",
};

const TEALIUM_SEARCH_FILTER_MAPS = {
  COURSE_FINDER_RESULTS: mapTealiumTrackingCourseResultFields,
  PROVIDER_FINDER_RESULTS: mapTealiumTrackingProviderResultFields,
  SCHOLARSHIP_FINDER_RESULTS: mapTealiumTrackingScholarshipResultFields,
};

const INVENTORY_LABELS = {
  COURSE: {
    PAGE_NAME: "Course View",
    INVENTORY_OBJECT_TYPE: "Course",
  },
  PROVIDER: {
    PAGE_NAME: "Institution View",
    INVENTORY_OBJECT_TYPE: "Institution",
  },
  SCHOLARSHIP: {
    PAGE_NAME: "Scholarship View",
    INVENTORY_OBJECT_TYPE: "Scholarship",
  },
};

export {
  INVENTORY_LABELS,
  TEALIUM_EVENT,
  TEALIUM_LINK_CATEGORY,
  TEALIUM_SEARCH_FILTER_MAPS,
  TEALIUM_POPUP_RESULT,
};
