import { ReactElement } from 'react'
import { connectStateResults, SearchState } from 'react-instantsearch-core'

export interface FilterDisplayLogicData {
  searchState: SearchState
  attribute: string
  values?: string[]
}

type FinderLogic = ({
  attribute,
  values,
  searchState
}: FilterDisplayLogicData) => boolean
interface FinderConditionalWrapper extends FilterDisplayLogicData {
  children: ReactElement | ReactElement[]
  handleShowFilter: FinderLogic
}

const FilterDisplayLogicComponent = ({
  searchState,
  attribute,
  values = [],
  children,
  handleShowFilter
}: FinderConditionalWrapper) => {
  const hasValue = handleShowFilter({ attribute, values, searchState })
  return hasValue ? <>{children}</> : null
}

export { FilterDisplayLogicComponent }

export default connectStateResults(FilterDisplayLogicComponent)
