import { parseAlgoliaHitHighlight } from '@algolia/autocomplete-preset-algolia'
import { Box, Text, TextProps } from '@chakra-ui/react'
import { AutocompleteResults } from '../SearchAutocomplete/SearchAutocomplete'

interface Highlight extends TextProps {
  hit: AutocompleteResults
  attribute: string
}

const Highlight = ({ hit, attribute, ...props }: Highlight) => {
  const results = parseAlgoliaHitHighlight({
    //@ts-ignore
    hit,
    //@ts-ignore
    attribute
  })

  return (
    <Text as='span' display='inline-block' my={2} {...props}>
      {results.map((result, index) =>
        result.isHighlighted ? (
          <Box key={index} as='span' fontFamily='enz700'>
            {result.value}
          </Box>
        ) : (
          <Box key={index} as='span'>
            {result.value}
          </Box>
        )
      )}
    </Text>
  )
}

export default Highlight
