import {
  ParentNaumaiPagePath_Swnz_NaumaiPageStandardFragment,
  ParentPagePath_Swnz_PageFragment
} from 'content-service'
import { buildPath, orderSlugs } from './slug'

type createHref = {
  href?: string | null
  internalLink?:
    | ParentNaumaiPagePath_Swnz_NaumaiPageStandardFragment
    | ParentPagePath_Swnz_PageFragment
    | null
}

function createHref(primaryCta?: createHref | null): string {
  const { href, internalLink } = primaryCta || {}

  if (internalLink) {
    return buildPath({ slugs: orderSlugs({ pageResult: internalLink }) })
  } else if (href) {
    return href
  }

  return ''
}

export { createHref }
