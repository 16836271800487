import { Accordion as ChakraAccordion, Skeleton, Text } from '@chakra-ui/react'
import { ContainerWrapper } from '../../layout'
import { H2 } from '../../typography'
import AccordionItems, { AccordionElement } from './AccordionItems'

interface Props {
  title?: string | null
  introText?: string | null
  accordionItemsToRenderArray: AccordionElement[]
  isLoaded: boolean
}

const AccordionComponent = ({
  title,
  introText,
  accordionItemsToRenderArray,
  isLoaded
}: Props) => {
  return (
    <ContainerWrapper>
      <Skeleton isLoaded={isLoaded}>
        {title && <H2 mb={{ base: 8, lg: 12 }}>{title}</H2>}
        {introText && (
          <Text
            fontSize={{ base: '20px', lg: '22px' }}
            lineHeight={{ base: '30px', lg: '36px' }}
          >
            {introText}
          </Text>
        )}
        <ChakraAccordion allowToggle maxW='1096px' mx='auto'>
          <AccordionItems
            accordionItemsToRenderArray={accordionItemsToRenderArray}
          />
        </ChakraAccordion>
      </Skeleton>
    </ContainerWrapper>
  )
}

export default AccordionComponent
