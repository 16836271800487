import { Box, Heading, VStack } from '@chakra-ui/react'
import { LargeText } from 'ui'

interface CardCarouselHeading {
  title?: string
  tagHeading?: string
}

const CardCarouselHeading = ({ title, tagHeading }: CardCarouselHeading) => {
  return (
    <Box>
      <VStack marginBottom={6} marginX={4} textAlign='center'>
        <Heading
          color='white'
          marginBottom={{ base: 8, lg: 10 }}
          fontSize={{ base: '36px', lg: 'xxx-large' }}
        >
          {title}
        </Heading>
        <LargeText as='h5' color='white'>
          {tagHeading}
        </LargeText>
      </VStack>
    </Box>
  )
}

export default CardCarouselHeading
