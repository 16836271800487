import { ReactElement } from 'react'
import Image, { ChakraNextImageProps } from './Image'

interface OptimisedImageProps extends ChakraNextImageProps {
  imageSizeConfig?: {
    width: number
    height: number
  }
  onFocusBreakpoint?: boolean
}

const OptimisedImage = (props: OptimisedImageProps): ReactElement | null => {
  const { src, alt, objectFit = 'cover', imageSizeConfig, key, ...rest } = props
  const optimisedSrcParams = imageSizeConfig
    ? `?w=${imageSizeConfig.width}&h=${imageSizeConfig.height}`
    : ''

  if (!src) {
    return null
  }
  // This will focus on the faces of the image if the breakpoint is met, if it is undefined it will not focus on the faces
  return (
    <Image
      src={`${src}${optimisedSrcParams}${
        props.onFocusBreakpoint ? '&f=faces&fit=crop' : ''
      }`}
      alt={alt}
      objectFit={objectFit}
      layout='fill'
      key={key}
      onError={(event) => {
        event.currentTarget.style.display = 'none'
      }}
      {...rest}
    />
  )
}

export { OptimisedImage }
export type { OptimisedImageProps }
