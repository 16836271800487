import React, { useReducer } from 'react'
import { SecondaryNavContent } from '.'
import { NavLink } from '../NavItem'
import { ACTION_TYPES, stateInitialiser } from './helper/normalizeLinks'

export interface Breadcrumb extends NavLink {
  parent: string | null
  childNodes: Array<string>
}

interface BreadcrumbState {
  breadcrumbs: Map<string, Breadcrumb>
  current: Breadcrumb
}

export interface SecondaryNavProps {
  links: Array<NavLink>
  selected: string
  navigateToMainNav: () => void
}

const initialState: BreadcrumbState = {
  breadcrumbs: new Map(),
  current: {
    id: '',
    title: '',
    slug: '',
    parent: null,
    childNodes: []
  }
}

function reducer(state: any, action: any) {
  switch (action.type) {
    case ACTION_TYPES.SET_BREADCRUMB:
      return { ...state, current: action.payload }
    default:
      return { ...state }
  }
}

export const SecondaryNav: React.FC<SecondaryNavProps> = ({
  links,
  selected,
  navigateToMainNav
}) => {
  const [{ current, breadcrumbs }, dispatch] = useReducer(
    reducer,
    initialState,
    stateInitialiser(links, selected)
  )

  const navigate = (id: string) => {
    const navigateTo = breadcrumbs.get(id)

    if (navigateTo)
      dispatch({ type: ACTION_TYPES.SET_BREADCRUMB, payload: navigateTo })
    else throw new Error()
  }

  const getCrumb = (id: string): Breadcrumb | undefined => {
    const crumb = breadcrumbs.get(id)
    if (crumb) return crumb
  }

  if (current === initialState.current) return null

  return (
    <SecondaryNavContent
      current={current}
      navigateToCrumb={navigate}
      navigateToMainNav={navigateToMainNav}
      getCrumb={getCrumb}
    />
  )
}
