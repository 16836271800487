import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
} from '@chakra-ui/react'
import { MinusIcon, PlusIcon } from 'icons'
import { ReactElement } from 'react'
import { H4 } from 'ui'
import CardItem, { CardItemProps } from './CardItem'

export interface CardItemsProps {
  items?: CardItemProps[]
}

const CardAccordionLayout = ({ items }: CardItemsProps): ReactElement => {
  return (
    <Accordion border="none" color="white" mt={10} mb="30px" allowToggle>
      {items?.map((item, index) => {
        const { title } = item
        return (
          <AccordionItem
            border="none"
            borderRadius="sm"
            key={index}
            data-testid={`accordionitem-${index}`}
          >
            {({ isExpanded }) => (
              <Box
                sx={{
                  hr: {
                    maxWidth: '100%',
                    marginBottom: 0,
                  },
                }}
              >
                <Divider className="accordion-divider" />
                <AccordionButton
                  p={0}
                  my={5}
                  _hover={{
                    bgColor: 'none',
                  }}
                >
                  <Box display="flex" flex="1" justifyContent="space-between" textAlign="left">
                    <H4 mb="0">{title}</H4>
                    {isExpanded ? (
                      <MinusIcon boxSize={5} />
                    ) : (
                      <Box boxSize={5}>
                        <PlusIcon />
                      </Box>
                    )}
                  </Box>
                </AccordionButton>
                <AccordionPanel pb={{ base: 4, md: 10 }} px={0}>
                  <CardItem {...item} />
                </AccordionPanel>
              </Box>
            )}
          </AccordionItem>
        )
      })}
    </Accordion>
  )
}

export default CardAccordionLayout
