import {
  Box,
  Input,
  InputGroup,
  InputProps,
  InputRightElement
} from '@chakra-ui/react'
import { SearchIcon } from 'icons'
import React from 'react'

const SearchInput = ({ ...props }: InputProps) => {
  return (
    <InputGroup>
      <Input placeholder='Search' {...props} />
      <InputRightElement>
        <Box boxSize={8}>
          <SearchIcon />
        </Box>
      </InputRightElement>
    </InputGroup>
  )
}
export default SearchInput
