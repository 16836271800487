const createInitStoryModalCustomEvent = (
  modalId: string | undefined,
  name?: string | undefined,
  linkLabel?: string | undefined
) => {
  const initStoryModalEvent = new CustomEvent('initStoryModal', {
    detail: { modalId, name, linkLabel }
  })
  if (typeof window === undefined) {
    return
  }

  return document.dispatchEvent(initStoryModalEvent)
}

export { createInitStoryModalCustomEvent }
