import { Swnz_NavigationElement } from 'content-service'

{
  /** Filters out study online from the navigation elements */
}
export const cnNavFilter = (
  items: Array<Swnz_NavigationElement>
): Array<Swnz_NavigationElement> => {
  return items
    .map((item) => {
      if (
        item.navigationElementsCollection &&
        item.navigationElementsCollection.items
      ) {
        return {
          ...item,
          navigationElementsCollection: {
            ...item.navigationElementsCollection,
            items: cnNavFilter(
              item.navigationElementsCollection
                .items as Array<Swnz_NavigationElement>
            )
          }
        }
      }
      return item
    })
    .filter((item) => item.codeId !== 'online') // Filter out items with codeId 'online' at the current level
}
