import { HighlightHitParams } from '@algolia/autocomplete-js'
import { SearchAutocompleteContext } from './SearchAutocomplete'

export interface BaseSearchItem {
  type: SearchType
}

export enum SearchType {
  QUERY = 'query',
  FACET = 'facet'
}

export type FacetHit = {
  label: string
  count: number
  _highlightResult: {
    label: {
      value: string
    }
  }
}

export interface BaseResult extends SearchAutocompleteContext {
  [key: string]: string | unknown
  label: string
  objectID: string
  count?: number
  type?: SearchType
  url?: string
}

export interface SearchQueryResult extends CourseQueryResult {
  _highlightResult: HighlightHitParams<CourseQueryResult>
}
export interface SiteSearchQueryResult extends SitepageQueryResult {
  _highlightResult: HighlightHitParams<SitepageQueryResult>
}

export interface SchoolsQueryResult extends BaseResult {
  id: string
  duration: string
  nzqaLevel: string | null
  details: string
  institutionId: string
  levelOfStudy: string
  institutionType: string
  institutionName: string
  region: string
  modesOfStudy: string[]
  __autocomplete_indexName: string
  __autocomplete_id: number
}

export interface ScholarshipQueryResult extends BaseResult {
  id: string
  subjectsOfStudy: string[]
  levelsOfStudy: string[]
  nationalities: string[]
  providerName: string
  providerSubtype: string
  title: string
  __autocomplete_indexName: string
  __autocomplete_id: number
}

export interface ScholarshipSearchQueryResult extends ScholarshipQueryResult {
  _highlightResult: HighlightHitParams<ScholarshipQueryResult>
}

export interface CourseQueryResult extends BaseResult {
  id: string
  name: string
  details: string
  areasOfStudy: string[]
  countries: string[]
  providerName: string
  providerType: string
  levelOfStudy: string
  modesOfStudy: string[]
  regions: string[]
  nzqaLevel: number
  subjectsOfStudy: string[]
  providerId: string
  intakeDates: string[]
  __autocomplete_indexName: string
  __autocomplete_id: number
}

export interface SitepageQueryResult extends BaseResult {
  id: string
  title: string
  url: string
  content: string
  hierarchy: {
    l0: string
    l1: string
    l2: string
    l3: string
    l4: string
    content: string
  }
  __autocomplete_indexName: string
  __autocomplete_id: number
}
