import {
  AlternatingQuote_Swnz_AlternatingQuoteFragment,
  AlternatingQuote_Swnz_AlternatingQuoteFragmentDoc
} from 'content-service/src'
import useFragment from 'utils/src/hooks/useFragment'
import { AlternatingQuote } from '../../components/content-components'
import { Connector } from '../types'
import { createMediaImageProps, validateDisplayType } from './helpers'

export interface AlternatingQuoteConnectProps extends Connector {
  pageTheme?: string
}

const AlternatingQuoteConnect = ({
  id,
  typename,
  pageTheme
}: AlternatingQuoteConnectProps) => {
  const alternatingQuote =
    useFragment<AlternatingQuote_Swnz_AlternatingQuoteFragment>({
      id,
      typename,
      fragment: AlternatingQuote_Swnz_AlternatingQuoteFragmentDoc,
      fragmentName: 'alternatingQuote_SWNZ_AlternatingQuote'
    })

  if (!alternatingQuote) {
    return null
  }

  const {
    quoteText = null,
    mediaPlacement = null,
    mediaOrientation = null,
    credit = null,
    creditSuffix = null,
    identifier = null,
    mediaItem = null,
    quoteTextColour = null
  } = alternatingQuote || {}

  const displayType = validateDisplayType(mediaOrientation, mediaPlacement)
  const mediaItemProps = createMediaImageProps(mediaItem)

  return (
    <AlternatingQuote
      mediaItemProps={mediaItemProps}
      displayType={displayType}
      quoteText={quoteText}
      pageTheme={pageTheme}
      credit={credit}
      creditSuffix={creditSuffix}
      identifier={identifier}
      quoteTextColour={quoteTextColour}
    />
  )
}

export default AlternatingQuoteConnect
