import { format, isMatch, parse } from 'date-fns'

export const reformatDate = (dateToReformat: string): string => {
  if (dateToReformat !== '' && !isMatch(dateToReformat, 'yyyy-MM-dd')) {
    const toDate = parse(dateToReformat, 'dd/MM/yyyy', new Date())
    dateToReformat = format(toDate, 'yyyy-MM-dd')
  }

  return dateToReformat
}

//Format date back into string for API, needs to take a string also for leadcapture
export const reformatDateForSubmit = (date: Date | string) => {
  if (typeof date === 'string') return ''
  else return format(date, 'yyyy-MM-dd').toString()
}

export default reformatDate
