import { Box } from '@chakra-ui/react'
import { ArrowIcon } from 'icons'
import { Button } from '../../buttons'

interface GalleryNavigationButtonProps {
  onClickHandler: () => void
  hasNextPrev: boolean
  label: string
  type: string
}

const GalleryNavigationButton = ({
  onClickHandler,
  hasNextPrev,
  label,
  type
}: GalleryNavigationButtonProps) => {
  if (!hasNextPrev) {
    return null
  }

  return (
    <Button
      data-testid='gallery_navigation_button'
      as='span'
      boxSize={50}
      variant='unstyled'
      justifyContent='center'
      alignItems='center'
      onClick={onClickHandler}
      cursor='pointer'
      position='absolute'
      zIndex='1'
      top='calc(50% - 24px)'
      sx={{
        'html:not([lang=ar]) &': {
          right: type === 'next' ? { base: '0.5em', lg: '2em' } : '',
          left: type === 'previous' ? { base: '0.5em', lg: '2em' } : ''
        },
        'html[lang=ar] &': {
          left: type === 'next' ? { base: '0.5em', lg: '2em' } : '',
          right: type === 'previous' ? { base: '0.5em', lg: '2em' } : ''
        }
      }}
      aria-label={label}
      title={label}
      opacity={{ base: 0, lg: 1 }}
      fill='white'
      _hover={{
        opacity: '0.7'
      }}
      icon={
        <Box
          boxSize={50}
          fill='white'
          transform={type === 'previous' ? 'rotate(-180deg)' : ''}
        >
          <ArrowIcon />
        </Box>
      }
    />
  )
}

export default GalleryNavigationButton
