import { useEffect, useState } from 'react'
import { createConfig } from 'utils/src/helpers/algoliaSearch'

function buildFilterString(attribute: string, Ids: string[]) {
  const idsWithAttribute = Ids.map((item) => `${attribute}: ${item}`)
  return idsWithAttribute.join(' OR ')
}

/**
 * Fetch algolia results by a single attribute and an array of attribute values
 * This should only be used if you need all the results in one go. Using
 * the react instantsearch package is probably the best place to start.
 */
function useGetAlgoliaHits<HitType>({
  inventorySearchValues,
  indexName,
  searchAttribute = 'objectID'
}: {
  inventorySearchValues: string[]
  indexName: string
  searchAttribute?: string
}) {
  const [hits, setHits] = useState<HitType[] | []>([])
  const [loading, setLoading] = useState<boolean>(false)

  const filterString = buildFilterString(searchAttribute, inventorySearchValues)

  useEffect(() => {
    if (inventorySearchValues && inventorySearchValues.length > 0) {
      const fetchData = async () => {
        const { searchClient } = createConfig({
          indexName
        })
        const index = searchClient.initIndex(indexName)

        setLoading(true)
        try {
          const hits = await index.search<HitType>('', {
            filters: filterString
          })

          setLoading(false)
          setHits(
            hits.hits.sort(
              (a, b) =>
                inventorySearchValues.indexOf(a.objectID) -
                inventorySearchValues.indexOf(b.objectID)
            )
          )
        } catch (error) {
          console.error(
            `Fetching algolia objects with useGetAlgoliaHits for the attribute ${searchAttribute} has failed`,
            error
          )
        }
      }
      // Search can only return up to 1000 records at a time
      // this should be fine for the number of records we expect a student
      // add to their profile
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterString, indexName, searchAttribute])

  return {
    loading,
    hits
  }
}

export default useGetAlgoliaHits
