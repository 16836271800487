import { HttpLink } from '@apollo/client'
import customFetch from './fetch'

const serverHttpLink = new HttpLink({
  uri: process.env.CONTENT_API,
  credentials: 'same-origin',
  fetch: customFetch as typeof fetch
})

export default serverHttpLink
