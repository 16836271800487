import { TypedTypePolicies } from './apolloHelpers'
import possibleTypes from './possibleTypes'

const SWNZEntityFields = () => {
  const fields: TypedTypePolicies = {}

  for (const entryItem of possibleTypes.possibleTypes.SWNZ_Entry) {
    fields[entryItem] = {
      keyFields: ['sys', ['id'] as any]
    }
  }

  return fields
}

const typePolicies = (): TypedTypePolicies => ({
  ...SWNZEntityFields(),
  SWNZ_Asset: {
    keyFields: ['sys', ['id'] as any]
  }
})

export default typePolicies()
