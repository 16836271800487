import { Checkbox } from '@chakra-ui/react'
import React, { ChangeEvent } from 'react'
interface RefinementCheckBox {
  label: string
  count: number
  isChecked: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const RefinementCheckbox = ({
  label,
  count,
  isChecked,
  onChange
}: RefinementCheckBox) => (
  <Checkbox
    w='100%'
    isDisabled={!count && !isChecked}
    size='md'
    isChecked={isChecked}
    onChange={onChange}
    sx={{
      '.chakra-checkbox__label': {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between'
      }
    }}
  >
    <span>{label}</span>
    <span>{count}</span>
  </Checkbox>
)
export default RefinementCheckbox
