import { CheckCircleIcon } from '@chakra-ui/icons'
import { Box } from '@chakra-ui/react'
import { ReactElement } from 'react'
import UncheckedCircleIcon from './UncheckedCircleIcon'

interface AccordionTickBox {
  isChecked: boolean
}

const AccordionTickBox = ({ isChecked }: AccordionTickBox): ReactElement => {
  return (
    <Box display='flex'>
      {isChecked ? (
        <CheckCircleIcon
          color={'teal.500'}
          boxSize={{ base: '30px', lg: '50px' }}
        />
      ) : (
        <UncheckedCircleIcon />
      )}
    </Box>
  )
}

export default AccordionTickBox
