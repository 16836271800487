export default {
  // setup basic/global style for the heading
  baseStyle: {
    fontFamily: 'enz700',
    letterSpacing: '-1px'
  },
  defaultProps: {
    size: 'xl'
  }
}
