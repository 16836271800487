import { NextStepsItem_Swnz_NextStepsItemFragment } from 'content-service'
import { isPresent } from 'ts-is-present'
import { createCtaButton } from 'utils/src/helpers'
import { NextStepsStep } from '../../../components/content-components'

export interface CreateSteps {
  steps?: Array<NextStepsItem_Swnz_NextStepsItemFragment | null>
}

export const createSteps = ({ steps }: CreateSteps): Array<NextStepsStep> => {
  if (!steps) {
    return []
  }

  const stepArr = steps.filter(isPresent).map((nextStep) => {
    const primaryCta = createCtaButton(nextStep?.primaryCta)
    const secondaryCta = createCtaButton(nextStep?.secondaryCta)

    return {
      title: nextStep?.title ?? '',
      bodyText: nextStep?.description ?? '',
      primaryButton: primaryCta,
      secondaryButton: secondaryCta
    }
  })

  return stepArr
}
