import { Box, BoxProps, Flex } from '@chakra-ui/react'
import { PropsWithChildren, ReactElement, ReactNode } from 'react'
import { LargeText } from 'ui/src/components/typography/Paragraph'
interface FinderWidgetProps extends BoxProps {
  heading: string
  children?: ReactNode
}

function FinderWidget({ heading, children, ...props }: FinderWidgetProps) {
  return (
    <Box
      boxSize='full'
      backgroundColor='white'
      paddingX={7}
      paddingTop={{ base: 6, md: 9 }}
      paddingBottom={{ base: 7, md: 12 }}
      borderTopRadius={{ base: 0, lg: 20 }}
      borderBottomRadius={20}
      boxShadow='0px 10px 50px -12px rgba(0,0,0, 0.1)'
      {...props}
    >
      <LargeText fontFamily='enz500' mb={3}>
        {heading}
      </LargeText>
      <Flex flexDirection={{ base: 'column', md: 'row' }} width='full'>
        {children}
      </Flex>
    </Box>
  )
}

FinderWidget.Input = function FinderWidgetInput({
  children,
  ...restProps
}: PropsWithChildren<BoxProps>): ReactElement {
  return (
    <Box
      {...restProps}
      marginRight={{ base: 0, md: 4 }}
      marginBottom={{ base: 2.5, md: 0 }}
    >
      {children}
    </Box>
  )
}

FinderWidget.SearchButton = function FinderWidgetSearchButton({
  children,
  ...restProps
}: PropsWithChildren<BoxProps>): ReactElement {
  return (
    <Flex
      marginLeft={{ base: 0, md: 1 }}
      marginTop={{ base: 2.5, md: 0 }}
      {...restProps}
    >
      {children}
    </Flex>
  )
}

export default FinderWidget
