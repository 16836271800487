import { defaultLocale, locales } from '../../i18n'

/**
 * Creates html link tags to be added to the document head
 * referecing alternate versions of the same page in a different language
 * https://developers.google.com/search/docs/advanced/crawling/localized-versions
 */

const createLanguageAlternates = (baseUrl: string, asPath: string) => {
  const languageAlternates: Array<{ href: string; hrefLang: string }> = []

  locales.forEach((locale) => {
    if (locale === defaultLocale) return

    const basePath = `${baseUrl}/${locale}`
    const fullPath = asPath === '/' ? `${basePath}` : `${basePath}${asPath}`

    if (locale === 'en') {
      languageAlternates.push(
        { href: fullPath, hrefLang: 'x-default' },
        { href: fullPath, hrefLang: locale }
      )
    }

    languageAlternates.push({ href: fullPath, hrefLang: locale })
  })

  return languageAlternates
}

export { createLanguageAlternates }
