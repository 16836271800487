import React from 'react'
import { Button, ButtonProps } from '../buttons'

const Pill = ({ ...props }: ButtonProps) => (
  <Button
    colorScheme='deepblue'
    fontSize='sm'
    fontFamily='enz400'
    lineHeight='34px'
    height='34px'
    py={0}
    pl={4}
    pr={3}
    {...props}
  />
)

export default Pill
