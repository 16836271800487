import { Box, ModalCloseButton } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { Modal } from 'ui'

interface ImageZoomModalProps {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
  maxWidth: number
}

const ImageZoomModal = ({
  children,
  isOpen,
  onClose,
  maxWidth
}: ImageZoomModalProps) => {
  return (
    <Modal
      size='full'
      onClose={onClose}
      isOpen={isOpen}
      allowPinchZoom
      scrollBehavior='outside'
    >
      <Box
        sx={{
          '.chakra-modal__content-container': {
            overflow: 'hidden',
            height: '100%'
          }
        }}
      >
        <Modal.Overlay background='rgba(4, 15, 32, 0.95)' />
        <Modal.FullWidthContent
          maxWidth={`${maxWidth}px`}
          my={0}
          background='transparent'
          justifyContent='center'
          minHeight='fit-content'
        >
          <Modal.Body
            flexGrow={0}
            padding={0}
            sx={{
              position: 'relative',
              '@media screen and (min-width: 666px)': {
                marginTop: 0
              }
            }}
            onClick={onClose}
          >
            <ModalCloseButton
              zIndex={9999}
              aria-label='Close image zoom modal'
              backgroundColor={'rgba(255, 255, 255, 0.1)'}
              borderRadius='full'
              boxSize={{ base: '40px', lg: '50px' }}
              _hover={{
                backgroundColor: { md: 'deepblue.200' }
              }}
              sx={{
                position: 'initial',
                marginTop: '3px',
                marginRight: '10px',
                marginBottom: '13px',
                marginLeft: 'auto',
                top: '-56px',
                '@media screen and (min-width: 666px)': {
                  position: 'absolute',
                  margin: 0,
                  top: '12px',
                  right: '20px'
                },
                svg: {
                  boxSize: { base: '14px', md: '18px' },
                  color: { base: '#fff' }
                }
              }}
            />
            <Box cursor='zoom-out'>{children}</Box>
          </Modal.Body>
        </Modal.FullWidthContent>
      </Box>
    </Modal>
  )
}

export default ImageZoomModal
