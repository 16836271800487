import { Text, useDisclosure } from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import { LeadCaptureForm } from 'swnz/src/components/forms'
import EducationFairFormConnect from 'swnz/src/connectors/EducationFairForm/EducationFairFormConnect'
import { createUtagLinkClickEvent } from 'tracking'
import { FormElementWrapper, FORM_CONSTANTS } from '../../../forms'
import Button from '../Button'
import { CtaButtonProps } from '../CtaButton'

const MailingList = () => {
  return (
    <FormElementWrapper
      inModal
      successMessage={FORM_CONSTANTS.LEAD_CAPTURE.SUCCESS_MESSAGE}
      errorMessage={FORM_CONSTANTS.LEAD_CAPTURE.ERROR_MESSAGE}
      heading={{
        initial: 'Join our mailing list',
        onSuccess: 'Stay in touch'
      }}
      bodyText={{
        initial:
          'Sign up to learn more and stay up to date with the latest information on studying with New Zealand.',
        onSuccess:
          'Learn more and stay up to date with the latest information on studying with New Zealand.'
      }}
    >
      {({ handleFormSuccess, handleFormError }) => (
        <LeadCaptureForm
          tealium={{
            source: 'Join Our Mailing List',
            formType: 'general_lead',
            formName: 'component'
          }}
          onSuccessCallback={handleFormSuccess}
          onErrorCallback={handleFormError}
        />
      )}
    </FormElementWrapper>
  )
}

const FormModal = dynamic(() => import('ui/src/forms/FormModal'))

const ModalButton = ({
  modalId,
  children,
  linkCategory = '',
  isTruncated,
  leadCaptureFormId,
  ...buttonProps
}: CtaButtonProps) => {
  const {
    isOpen: isModalOpen,
    onOpen: onModalOpen,
    onClose: onModalClose
  } = useDisclosure()
  if (!modalId) {
    return null
  }
  const handleEnquiryFormModalOpen = () => {
    onModalOpen()
    createUtagLinkClickEvent({
      linkLabel: `${children}`,
      linkCategory
    })
  }

  return (
    <>
      <Button
        onClick={handleEnquiryFormModalOpen}
        data-testid='test-modal-button'
        {...buttonProps}
      >
        {isTruncated ? (
          <Text noOfLines={1} wordBreak='break-all' mb={0}>
            {children}
          </Text>
        ) : (
          <>{children}</>
        )}
      </Button>
      {isModalOpen && (
        <FormModal isOpen={isModalOpen} onClose={onModalClose}>
          {modalId === 'join-mailing-list-form' ? (
            <MailingList />
          ) : (
            modalId === 'form-lead-capture' && (
              <EducationFairFormConnect
                id={leadCaptureFormId || ''}
                inModal
                typename='SWNZ_FormLeadCapture'
              />
            )
          )}
        </FormModal>
      )}
    </>
  )
}

export default ModalButton
