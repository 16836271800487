import { Box, Fade } from '@chakra-ui/react'
import { Headline } from 'ui'
import { ZINDEX } from 'utils'

interface HomepageHeroPreHeadingProps {
  heroPreHeading: string | null | undefined
  isVisible: boolean
}

export default function HomepageHeroPreHeading({
  heroPreHeading,
  isVisible,
}: HomepageHeroPreHeadingProps) {
  return (
    <Fade in={isVisible} animate={{ opacity: isVisible ? 1 : 0 }} transition={{ enter : { duration: 1 } }}>
      <Box color="white" zIndex={ZINDEX.NEW_TEXT}>
        <Headline
          as="span"
          display="block"
          fontSize={{
            base: '32px',
            lg: '64px',
          }}
          sx={{
            'html:not([lang=en]) &': {
              fontFamily: 'a23d',
              fontWeight: '500',
            },
          }}
          marginBottom={0}
        >
          {heroPreHeading}
        </Headline>
      </Box>
    </Fade>
  )
}
