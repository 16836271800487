import { FormPopup, FORM_CONSTANTS } from 'ui'
import LeadCapturePopupForm from '../LeadCapturePopupForm'

const LeadCaptureFormPopup = () => {
  return (
    <FormPopup
      id={'lead-capture-popup'}
      cookieName={FORM_CONSTANTS.LEAD_CAPTURE.HIDE_POPUP_COOKIE}
      heading={{
        initial: 'Join NauMai NZ',
        onSuccess: 'Stay in touch',
      }}
      bodyText={{
        initial:
          'Get trusted information, help and advice to have a great experience while studying with New Zealand!',
        onSuccess:
          'Learn more and stay up to date with the latest information on studying with New Zealand.',
      }}
      successMessage={FORM_CONSTANTS.LEAD_CAPTURE.SUCCESS_MESSAGE}
      errorMessage={FORM_CONSTANTS.LEAD_CAPTURE.ERROR_MESSAGE}
      hideUntilForcedOpen={false}
    >
      {({ handleFormSuccess, handleFormError }) => (
        <LeadCapturePopupForm
          onSuccessCallback={handleFormSuccess}
          onErrorCallback={handleFormError}
          tealium={{
            source: 'NauMai Pop Up Form',
            formName: 'naumai_pop-up',
            formType: 'lead',
          }}
        />
      )}
    </FormPopup>
  )
}

export default LeadCaptureFormPopup
