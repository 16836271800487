import { Box, Flex, Input, Select } from '@chakra-ui/react'
import { Button, LargeText } from 'ui'

const MockFinderWidget = ({
  heading = 'Finder widget heading'
}: {
  heading?: string
}) => (
  <Box
    width='full'
    height='full'
    backgroundColor='white'
    paddingX={7}
    paddingTop={8}
    paddingBottom={12}
    borderTopRadius={{ base: 0, md: '20px' }}
    borderBottomRadius='20px'
    boxShadow='0px 5px 20px rgba(7, 25, 55, 0.1)'
  >
    <LargeText fontFamily='enz500'>{heading}</LargeText>
    <Flex flexDirection={{ base: 'column', md: 'row' }}>
      <Select></Select>
      <Input />
      <Button>Search</Button>
    </Flex>
  </Box>
)

export default MockFinderWidget
