import { ChakraProvider } from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import { theme } from 'theme/src'
import 'utils/src/helpers/wdyr'
import { RtlProvider } from '../../components'

interface StyleProviderWrapperProps {
  children: ReactElement
}

export default function StyleProviderWrapper({
  children
}: StyleProviderWrapperProps) {
  return (
    <ChakraProvider theme={theme}>
      <RtlProvider>{children}</RtlProvider>
    </ChakraProvider>
  )
}
