import { Box, Flex, GridItem, Text } from '@chakra-ui/react'
import { QuoteIcon } from 'icons'
import { ReactElement } from 'react'
import { ContainerWrapper, GridLayout } from 'ui'
import {
  colourMap,
  getPageThemeBackgroundColour,
  getPageThemeCtaColour
} from 'utils/src/helpers'
import { CallToAction, CtaButton } from '../../buttons'
interface ImpactTextProps {
  textAlignment?: 'left' | 'center'
  textColour?: string | undefined
  displayAsQuote?: boolean
  inModal?: boolean
  backgroundColour?: string
  primaryCta?: CallToAction
  secondaryCta?: CallToAction
  description?: string
  children?: ReactElement
  pageTheme?: string
  leadCaptureFormId?: string
}
const ImpactText = ({
  backgroundColour = 'white',
  textAlignment,
  textColour,
  displayAsQuote = false,
  inModal = false,
  children,
  description,
  primaryCta,
  secondaryCta,
  pageTheme,
  leadCaptureFormId
}: ImpactTextProps): ReactElement => {
  const hasButtons = primaryCta?.label || secondaryCta?.label
  const renderQuote = (displayAsQuote: boolean, orientation: string) => {
    if (!displayAsQuote) {
      return null
    }

    return orientation === 'up' ? (
      <Box
        as='span'
        alignSelf={{ base: 'flex-end', lg: 'flex-start' }}
        data-testid='QuoteIcon'
        sx={{
          'html[lang=ar] &': {
            transform: 'rotate(180deg)'
          }
        }}
      >
        <Box boxSize={16}>
          <QuoteIcon />
        </Box>
      </Box>
    ) : (
      <Box as='span' data-testid='quoteDownIcon'>
        <Box
          boxSize={16}
          transform='rotate(180deg)'
          sx={{
            'html[lang=ar] &': {
              transform: 'unset'
            }
          }}
        >
          <QuoteIcon />
        </Box>
      </Box>
    )
  }

  const setLargeColStart = (displayAsQuote: boolean, inModal: boolean) =>
    inModal ? 0 : displayAsQuote ? 2 : 3

  const setLargeColSpan = (displayAsQuote: boolean, inModal: boolean) =>
    inModal ? (displayAsQuote ? 12 : 10) : displayAsQuote ? 10 : 8

  const containerBackgroundColour = getPageThemeBackgroundColour(pageTheme)

  const impactTextColor = textColour ? colourMap(textColour) : 'deepblue.500'

  const impactBackgroundColor =
    containerBackgroundColour !== ''
      ? containerBackgroundColour
      : displayAsQuote
        ? 'white'
        : colourMap(backgroundColour)

  const ctaColour = getPageThemeCtaColour(pageTheme)

  return (
    <ContainerWrapper
      backgroundColor={impactBackgroundColor}
      data-testid='impactText'
      textColor={impactTextColor}
    >
      <GridLayout>
        <GridItem
          colSpan={{
            base: 4,
            md: 8,
            lg: setLargeColSpan(displayAsQuote, inModal)
          }}
          colStart={{
            base: 0,
            md: 0,
            lg: setLargeColStart(displayAsQuote, inModal)
          }}
        >
          <Flex flexDirection={{ base: 'column', lg: 'row' }}>
            {renderQuote(displayAsQuote, 'down')}
            <Box
              textAlign={textAlignment}
              fontSize={{ base: '24px', md: '40px' }}
              fontFamily='enz500'
              lineHeight={{ base: '36px', md: '56px' }}
              px={{
                md: 0,
                lg: displayAsQuote ? 6 : 0,
                xl: displayAsQuote ? 10 : 0,
                xxl: displayAsQuote ? 12 : 0
              }}
              //   pr={{ md: displayAsQuote ? 0 : 0 }}
              paddingTop={{ base: displayAsQuote ? 4 : 0, md: 0 }}
              data-testid='impactTextContent'
              sx={{
                a: {
                  textDecoration: 'none'
                },
                'a&:hover': {
                  textDecoration: 'underline'
                },
                'html:lang(vi) &': {
                  fontWeight: '700'
                }
              }}
            >
              {children}
              {description && (
                <Text
                  marginBottom={0}
                  marginTop={{ base: 4, md: 7 }}
                  textColor='deepblue.500'
                  display='flex'
                  flexWrap='wrap'
                  fontSize={{ base: 'md', lg: '22px' }}
                  lineHeight={{ base: '28px', lg: '36px' }}
                >
                  {description}
                </Text>
              )}
            </Box>
            {renderQuote(displayAsQuote, 'up')}
          </Flex>

          {hasButtons && (
            <Flex
              marginTop={8}
              flexWrap='wrap'
              marginX={{
                base: 0,
                lg: displayAsQuote ? 20 : 0,
                xl: displayAsQuote ? 28 : 0
              }}
            >
              {primaryCta?.label && (
                <Box pb={{ base: secondaryCta?.label ? 4 : 0 }}>
                  <CtaButton
                    colorScheme={
                      pageTheme && pageTheme !== 'default' ? ctaColour : 'teal'
                    }
                    variant='outline'
                    href={primaryCta.href}
                    openInNewWindow={primaryCta.openInNewWindow}
                    isExternalLink={primaryCta.isExternalLink}
                    externalIconSize={6}
                    aria-label={primaryCta.label}
                    marginRight={4}
                    modalId={primaryCta.modalId}
                    leadCaptureFormId={leadCaptureFormId}
                  >
                    {primaryCta.label}
                  </CtaButton>
                </Box>
              )}

              {secondaryCta?.label && (
                <CtaButton
                  colorScheme={
                    pageTheme && pageTheme !== 'default' ? ctaColour : 'teal'
                  }
                  variant='outline'
                  href={secondaryCta.href}
                  openInNewWindow={secondaryCta.openInNewWindow}
                  isExternalLink={secondaryCta.isExternalLink}
                  externalIconSize={6}
                  aria-label={secondaryCta.label}
                  modalId={secondaryCta.modalId}
                  leadCaptureFormId={leadCaptureFormId}
                >
                  {secondaryCta.label}
                </CtaButton>
              )}
            </Flex>
          )}
        </GridItem>
      </GridLayout>
    </ContainerWrapper>
  )
}

export default ImpactText
export type { ImpactTextProps }
