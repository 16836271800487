import { Box, Button } from '@chakra-ui/react'
import { useState } from 'react'

interface DebugJSONData {
  data: any
  show?: boolean
}

function DebugJSONData({ data, show = true }: DebugJSONData) {
  const [showData, setShowData] = useState(false)
  const isDebug = show && process.env.NODE_ENV === 'development'

  return isDebug ? (
    <Box
      zIndex={100000}
      position='fixed'
      visibility='visible'
      left={4}
      top={4}
      width='50vw'
      display={show ? 'block' : 'hidden '}
    >
      <Box display='flex' mb={5}>
        <Button onClick={() => setShowData(!showData)}>
          Data Debug Toggle
        </Button>
      </Box>
      <Box
        display={showData ? 'block' : 'none'}
        bgColor='white'
        overflowY='scroll'
        overflowX='scroll'
        height='80vh'
        border='1px'
        padding={2}
      >
        <pre>{JSON.stringify(data, null, 4)}</pre>
      </Box>
    </Box>
  ) : null
}
export default DebugJSONData
