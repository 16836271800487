import { AspectRatio, Box } from '@chakra-ui/react'
import { PropsWithChildren, useState } from 'react'
import ReactPlayer from 'react-player/youtube'
import { useTrackVideo } from 'tracking'
import { VideoButton } from 'ui'
import BilibiliPlayer from './BilibiliPlayer'
import { getVideoType } from 'utils'

interface MediaVideoProps {
  sourceUrl: string
  thumbnailUrlTitle: string
}

const MediaVideo = ({
  sourceUrl,
  thumbnailUrlTitle,
  children
}: PropsWithChildren<MediaVideoProps>) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [setStartVideoTracking, setEndVideoTracking] = useTrackVideo(
    sourceUrl,
    duration
  )

  const videoType = getVideoType(sourceUrl)

  return (
    <Box>
      <VideoButton
        compact
        position='absolute'
        margin='0 auto'
        onClick={() => setIsPlaying(!isPlaying)}
        zIndex='1'
        visibility={!isPlaying ? 'visible' : 'hidden'}
      />
      <Box
        sx={{
          '.react-player': {
            visibility: isPlaying ? 'visible' : 'hidden'
          }
        }}
        position='relative'
        width='100%'
        height='100%'
      >
        {!isPlaying ? (
          children
        ) : (
          <>
            {videoType == 'bilibili' && (
              <AspectRatio ratio={16 / 9}>
                <BilibiliPlayer videoUrl={sourceUrl} autoplay={isPlaying} />
              </AspectRatio>
            )}
            {videoType == 'youtube' && (
              <AspectRatio ratio={16 / 9}>
                <ReactPlayer
                  className='react-player'
                  url={sourceUrl}
                  src={sourceUrl}
                  width='100%'
                  height='100%'
                  playing={isPlaying}
                  controls={true}
                  aria-label={thumbnailUrlTitle}
                  onDuration={(duration) => setDuration(duration)}
                  onStart={() => setStartVideoTracking(true)}
                  onEnded={() => setEndVideoTracking(true)}
                />
              </AspectRatio>
            )}
          </>
        )}
      </Box>
    </Box>
  )
}

export default MediaVideo
