import { IMAGE_SIZE_CONFIG } from 'utils'
import { OptimisedImage, OptimisedImageProps } from '../../../components'
import { AlternatingComponentDisplayType } from '../../../components/content-components'

const validateDisplayType = (
  mediaOrientation: string | null,
  mediaPlacement: string | null
): AlternatingComponentDisplayType => {
  const orientation = mediaOrientation
    ? mediaOrientation.toUpperCase()
    : 'PORTRAIT'
  const placement = mediaPlacement ? mediaPlacement.toUpperCase() : 'LEFT'
  const displayType = `${orientation}_${placement}`

  const orientationPlacement: {
    [key: string]: AlternatingComponentDisplayType
  } = {
    PORTRAIT_LEFT: AlternatingComponentDisplayType.PortraitLeft,
    PORTRAIT_RIGHT: AlternatingComponentDisplayType.PortraitRight,
    LANDSCAPE_LEFT: AlternatingComponentDisplayType.LandscapeLeft,
    LANDSCAPE_RIGHT: AlternatingComponentDisplayType.LandscapeRight
  }

  return orientationPlacement[displayType]
}

const createMediaVideoProps = (mediaItem: any) => {
  const imageComponentProps: OptimisedImageProps = {
    src: mediaItem.thumbnail.url || '',
    alt: mediaItem?.thumbnailUrl?.title || '',
    title: mediaItem?.source?.title || '',
    imageSizeConfig: IMAGE_SIZE_CONFIG.ALTERNATING_CONTENT
  }

  return {
    mediaVideoSourceUrl: mediaItem?.sourceUrl,
    thumbnailUrlTitle: mediaItem?.thumbnailUrl?.title,
    image: OptimisedImage(imageComponentProps)
  }
}

const createMediaImageProps = (mediaItem: any) => {
  const imageComponentProps: OptimisedImageProps = {
    src: mediaItem?.source?.url || '',
    alt: mediaItem?.source?.description || '',
    title: mediaItem?.source?.title || '',
    imageSizeConfig: IMAGE_SIZE_CONFIG.ALTERNATING_CONTENT
  }

  return { image: OptimisedImage(imageComponentProps) }
}

export { validateDisplayType, createMediaVideoProps, createMediaImageProps }
