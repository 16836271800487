import {
  NextSteps_Swnz_NextStepsFragment,
  NextSteps_Swnz_NextStepsFragmentDoc
} from 'content-service'
import { NextSteps } from 'ui'
import useFragment from 'utils/src/hooks/useFragment'
import { Connector } from '../types'
import { createSteps } from './helpers/nextSteps'

export type NextStepsConnectProps = Connector

const NextStepsConnect = ({
  id,
  typename,
  leadCaptureFormId
}: NextStepsConnectProps) => {
  const nextSteps = useFragment<NextSteps_Swnz_NextStepsFragment>({
    id,
    typename,
    fragment: NextSteps_Swnz_NextStepsFragmentDoc,
    fragmentName: 'nextSteps_SWNZ_NextSteps'
  })

  if (!nextSteps) {
    return null
  }

  const stepItems = nextSteps?.nextStepsItemsCollection?.items ?? []
  const steps = createSteps({ steps: stepItems })
  const title = nextSteps?.title ?? ''

  return (
    <NextSteps
      isLoaded={!!nextSteps}
      title={title}
      steps={steps}
      leadCaptureFormId={leadCaptureFormId}
    />
  )
}

export default NextStepsConnect
