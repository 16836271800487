import {
  MediaImage_Swnz_MediaImageFragment,
  MediaImage_Swnz_MediaImageFragmentDoc
} from 'content-service'
import { ImageLoadingPreference, IMAGE_SIZE_CONFIG } from 'utils'
import useFragment from 'utils/src/hooks/useFragment'
import { OptimisedImage } from '../../components'
import { MediaImage } from '../../components/content-components'
import { ImageZoom } from '../../components/ImageZoom'
import { Connector } from '../types'

export interface MediaImageConnectProps extends Connector {
  aspectRatio?: number
  imageLoadingPreference?: ImageLoadingPreference
  imagePriority?: boolean
  pageTheme?: string
  noDefaultPadding?: boolean
}

const MediaImageConnect = ({
  id,
  typename,
  pageTheme,
  ...elementProps
}: MediaImageConnectProps) => {
  const mediaImage = useFragment<MediaImage_Swnz_MediaImageFragment>({
    id,
    typename,
    fragment: MediaImage_Swnz_MediaImageFragmentDoc,
    fragmentName: 'mediaImage_SWNZ_MediaImage'
  })

  if (!mediaImage) {
    return null
  }

  const createImageConfig = {
    src: mediaImage?.source?.url || '',
    alt: mediaImage.source?.description || '',
    title: mediaImage?.source?.title || '',
    priority: elementProps?.imagePriority
  }

  const image = OptimisedImage({
    ...createImageConfig,
    imageSizeConfig: IMAGE_SIZE_CONFIG.MEDIA_IMAGE.IMAGE
  })

  // Stand-alone media image designs have a different aspect ratio to those used elsewhere
  const aspectRatio = elementProps.aspectRatio
    ? elementProps.aspectRatio
    : 16 / 9

  if (mediaImage.isZoomable) {
    const zoomImage = OptimisedImage({
      ...createImageConfig,
      imageSizeConfig: IMAGE_SIZE_CONFIG.MEDIA_IMAGE.ZOOM_IMAGE,
      quality: 100,
      objectFit: 'contain',
      layout: 'intrinsic',
      width: IMAGE_SIZE_CONFIG.MEDIA_IMAGE.ZOOM_IMAGE.width,
      height: IMAGE_SIZE_CONFIG.MEDIA_IMAGE.ZOOM_IMAGE.height
    })

    return (
      <ImageZoom
        imageComponent={image}
        imageCaption={mediaImage?.caption || ''}
        zoomImageComponent={zoomImage}
        maxModalWidth={IMAGE_SIZE_CONFIG.MEDIA_IMAGE.ZOOM_IMAGE.width}
        aspectRatio={aspectRatio}
        pageTheme={pageTheme}
      />
    )
  }

  return (
    <MediaImage
      caption={mediaImage?.caption || ''}
      aspectRatio={aspectRatio}
      pageTheme={pageTheme}
      noDefaultPadding={elementProps?.noDefaultPadding}
    >
      {image}
    </MediaImage>
  )
}

export default MediaImageConnect
