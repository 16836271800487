import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay
} from '@chakra-ui/react'
import { CloseIcon } from 'icons'
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react'
import {
  connectSearchBox,
  InstantSearch,
  InstantSearchProps,
  SearchState
} from 'react-instantsearch-core'
import { createURL } from 'utils/src/helpers/algoliaSearch'
import { Button } from '../../buttons'
import { H3 } from '../../typography'
import ClearButton from './ClearButton'
import connectResultFilter from './Connector/connectResultFilter'
import ShowResultsButton from './ShowResultsButton'

export interface ResultFilters extends InstantSearchProps {
  isOpen: boolean
  onClose: () => void
  children: ReactNode
  editStateOnChange?: (searchState: SearchState) => SearchState
}

export interface ResultsFilterConnectorProps {
  searchState?: SearchState
  refine: Dispatch<
    SetStateAction<{
      query?: string
      facetValue?: string
    }>
  >
}

const VirtualSearchBox = connectSearchBox(() => null)

export const Component = ({
  searchClient,
  indexName,
  searchState,
  resultsState,
  isOpen,
  onClose,
  refine,
  editStateOnChange,
  children
}: ResultFilters & ResultsFilterConnectorProps) => {
  const [currentSearchState, setCurrentSearchState] =
    useState<SearchState>(searchState)
  const globalPadding = 8

  const onSearchStateChange = (searchState: SearchState, isOpen: boolean) => {
    // If the state is set when the modal is closed it resets to a blank state
    if (isOpen) {
      if (editStateOnChange) {
        searchState = editStateOnChange(searchState)
      }
      setCurrentSearchState(searchState)
    }
  }

  const handleDrawerClose = () => {
    onClose()
    refine(currentSearchState)
  }

  useEffect(() => {
    onSearchStateChange(searchState, isOpen)
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState, isOpen])

  return (
    <>
      <InstantSearch
        indexName={indexName}
        searchClient={searchClient}
        searchState={currentSearchState}
        resultsState={resultsState}
        onSearchStateChange={(searchState) => {
          onSearchStateChange(searchState, isOpen)
        }}
        createURL={createURL}
      >
        <Drawer
          isOpen={isOpen}
          placement='right'
          onClose={handleDrawerClose}
          size='md'
        >
          <DrawerOverlay
            background='rgba(7, 25, 55, 0.85)'
            sx={{ backdropFilter: 'blur(4px)' }}
          />
          <DrawerContent px={globalPadding} dir='ltr'>
            <DrawerHeader
              p={0}
              mb={globalPadding}
              mt={globalPadding + 2}
              position='relative'
            >
              <H3 mb={0}>Filters</H3>
              <Button
                data-testid='test-close-filter-drawer'
                colorScheme='gray'
                borderRadius='full'
                border='none'
                height={10}
                aria-label='Close filter drawer'
                position='absolute'
                right={0}
                top={{ base: 0, lg: 1 }}
                icon={<CloseIcon boxSize={5} />}
                onClick={handleDrawerClose}
                sx={{
                  'html[lang=ar] &': {
                    left: 0,
                    right: 'unset'
                  }
                }}
              />
            </DrawerHeader>

            <DrawerBody
              p={0}
              mb={8}
              overflowX='hidden'
              overflowY='auto'
              dir='ltr'
            >
              <VirtualSearchBox />
              {children}
            </DrawerBody>

            <DrawerFooter
              mx={-globalPadding}
              px={globalPadding}
              py={{ base: 2, lg: 5 }}
              backgroundColor='teal.100'
              justifyContent='space-between'
            >
              <ShowResultsButton onClick={handleDrawerClose} />
              <ClearButton />
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      </InstantSearch>
    </>
  )
}

export default connectResultFilter(Component)
