import { ReactNode } from 'react'
import { Modal } from '../../components/modal'

interface FormModalProps {
  children: ReactNode
  isOpen: boolean
  onClose: () => void
}

const FormModal = ({ children, isOpen, onClose }: FormModalProps) => {
  return (
    <>
      <Modal size='full' onClose={onClose} isOpen={isOpen} isCentered={false}>
        <Modal.Overlay />
        <Modal.Content
          mx='unset'
          minHeight={{ base: 'full', md: 'auto' }}
          mt={{ md: '3.75rem' }}
        >
          <Modal.CloseButton
            position='absolute'
            right={{ base: 2, lg: 7 }}
            top={{ base: 2, lg: 8 }}
            zIndex={9999}
            aria-label='Close modal'
            backgroundColor={'rgba(230, 232, 235, 0.7)'}
            borderRadius='full'
            boxSize={{ base: '40px', lg: '50px' }}
            _hover={{
              backgroundColor: { md: 'deepblue.200' }
            }}
            sx={{
              'html[lang=ar] &': {
                right: 'unset',
                left: { base: 2, lg: 7 }
              },
              svg: {
                boxSize: { base: '14px', md: '18px' },
                color: { base: 'deepblue.300', md: 'deepblue.500' }
              }
            }}
          />
          <Modal.Body
            flexGrow={{ base: 0, md: 1 }}
            paddingX={{ base: 5, lg: 7 }}
            paddingTop={{ base: 8, lg: 10 }}
            paddingBottom={8}
            textAlign='left'
          >
            {children}
          </Modal.Body>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default FormModal
