import { Box, Text } from '@chakra-ui/react'
import { ChevronIcon, ExternalLinkIcon } from 'icons'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import Button, { ButtonProps } from '../Button'
import ModalButton from '../ModalButton'

export interface CallToAction {
  label: string
  href?: string
  openInNewWindow?: boolean
  isExternalLink?: boolean
  modalId?: string
  leadCaptureFormId?: string
}

export type CtaButtonProps = ButtonProps & {
  openInNewWindow?: boolean
  isTruncated?: boolean
  modalId?: string
  chevron?: boolean
  Icon?: React.FunctionComponent<React.SVGAttributes<SVGElement>>
  isExternalLink?: boolean
  externalIconSize?: string | number
  callback?: () => void
  linkCategory?:
    | ''
    | TEALIUM_LINK_CATEGORY.CTA
    | TEALIUM_LINK_CATEGORY.OUTBOUND
    | TEALIUM_LINK_CATEGORY.SITE_NAV
  leadCaptureFormId?: string
  modalName?: string
}

const CtaButton = ({
  href,
  children,
  openInNewWindow = false,
  chevron = false,
  isExternalLink = false,
  modalId,
  leadCaptureFormId,
  isTruncated = false,
  Icon,
  externalIconSize,
  callback,
  linkCategory = TEALIUM_LINK_CATEGORY.CTA,
  modalName,
  ...props
}: CtaButtonProps) => {
  if (!children && !href && !callback) {
    return null
  }

  const componentProps = {
    href,
    openInNewWindow,
    leftIcon: chevron ? (
      <Box
        width={'1em'}
        fill={'currentColor'}
        sx={{
          'html[lang=ar] &': {
            // vertically flips svg
            transform: 'scaleX(-1)'
          }
        }}
      >
        <ChevronIcon />
      </Box>
    ) : undefined,
    rightIcon: isExternalLink ? (
      <Box boxSize={externalIconSize ? externalIconSize : 8}>
        <ExternalLinkIcon />
      </Box>
    ) : Icon ? (
      <Icon />
    ) : undefined,
    ...props
  }

  if (modalId) {
    return (
      <ModalButton
        modalId={modalId}
        linkCategory={linkCategory}
        isTruncated={isTruncated}
        leadCaptureFormId={leadCaptureFormId}
        {...componentProps}
      >
        {children}
      </ModalButton>
    )
  } else
    return (
      <Button
        whiteSpace='normal'
        onClick={() => {
          createUtagLinkClickEvent({
            linkLabel: `${children}`,
            linkCategory,
            destinationUrl: (href as string) ?? '',
            modalName
          })
          callback?.()
        }}
        {...componentProps}
      >
        {isTruncated ? (
          <Text noOfLines={1} wordBreak='break-all' mb={0}>
            {children}
          </Text>
        ) : (
          <>{children}</>
        )}
      </Button>
    )
}

export default CtaButton
