import { createSanitisedPageName } from "../helpers";
import { TEALIUM_EVENT } from "../helpers/constants";

interface SiteNav {
  linkLabel: string;
  linkCategory: string;
}

interface SiteNavResult {
  page_name: string;
  link_label: string;
  link_category: string;
  tealium_event: TEALIUM_EVENT;
}

/**
 * createSiteNavUtagEventObject
 * Build the data for a site_nav event
 */
function createSiteNavUtagEventObject({
  linkLabel,
  linkCategory,
}: SiteNav): SiteNavResult {
  return {
    page_name: createSanitisedPageName(document.title),
    tealium_event: TEALIUM_EVENT.SITE_NAV,
    link_label: linkLabel,
    link_category: linkCategory,
  };
}

export default createSiteNavUtagEventObject;
export type { SiteNav, SiteNavResult };
