import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import { Formik } from 'formik'
import { TickRoundedIcon, WarningIcon } from 'icons'
import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { dispatchFormSubmissionUtagLinkEvent } from 'tracking'
import {
  Button,
  CheckboxControl,
  Form,
  FormAgreements,
  FormINZMatchingPermissionOptIn,
  H3,
  LargeText,
  postLeadCaptureFormSubmissionToLeadCaptureAPIEndpoint,
  reformatDateForSubmit,
  SmallText,
} from 'ui'
import { getRegionOptions, regionMap } from 'utils/src/helpers/leadCapture'
import { formTaxonomyData } from 'utils/src/helpers/taxonomy'
import * as Yup from 'yup'
import { useUser } from '../../../context/user'
import { useTranslation } from '../../../hooks/useTranslation'

interface LeadCaptureFormValues {
  email: string
  firstName: string
  lastName: string
  mobile: string
  nationality?: string
  intendedLevelOfStudy: string
  desiredStartDate: Date | string
  inzMatchingPermissionOptin: boolean
  stateRegion?: string
}

export interface LeadCaptureFormProps {
  tealium: {
    source: string
    formType: string
    formName: string
  }
  successMessage?: {
    heading: string
    bodyText: string
  }
  errorMessage?: {
    heading: string
    bodyText: string
  }
  onSuccessCallback?: () => void
  onErrorCallback?: () => void
}

const leadCaptureFormSchema = (lang?: string): Yup.ObjectSchema<LeadCaptureFormValues> =>
  Yup.object()
    .shape(
      {
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string()
          .email('Please enter a valid email')
          .required('Email address is required'),
        mobile: Yup.string()
          .min(8, 'Mobile number is too short')
          .max(15, 'Mobile number is too long')
          .required('Please enter a valid mobile number'),
        nationality:
          lang === 'ko' ? Yup.string() : Yup.string().required('Nationality is required'),
        stateRegion: Yup.string().when('nationality', {
          is: (nationality: string) =>
            nationality == 'china' || nationality == 'new-zealand' || nationality == 'india',
          then: (schema) => schema.required('State or region is required'),
        }),
        intendedLevelOfStudy: Yup.string().required('Please enter an intended level of study'),
        desiredStartDate: Yup.date()
          .typeError('Please enter a valid date')
          .transform((value) => {
            return value ? new Date(value) : value
          })
          .when('desiredStartDate', {
            is: (val: Date) => val !== undefined,
            then: (rule) =>
              rule
                .test(
                  'year',
                  'Please enter a valid date',
                  (value: Date | undefined) =>
                    value !== undefined &&
                    value.getFullYear() >= 1000 &&
                    value.getFullYear() <= 9999
                )
                .min(new Date(), 'Please enter a date in the future'),
          })
          .required('Desired start date is required'),
        inzMatchingPermissionOptin: Yup.boolean().oneOf([true, false]).defined(),
      },
      [['desiredStartDate', 'desiredStartDate']]
    )
    .noUnknown(true)
    .required()

const LeadCaptureForm = ({
  onSuccessCallback,
  onErrorCallback,
  tealium,
  successMessage,
  errorMessage,
}: LeadCaptureFormProps) => {
  const { t, lang } = useTranslation('forms')
  const defaultNationality = t('nationality.defaultValue') ?? ''
  const {
    userState: { profile },
  } = useUser()

  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const [successfulSubmission, setSuccessfulSubmission] = useState(false)
  const [submissionError, setSubmissionError] = useState(false)
  const [country, setCountry] = useState<string>(defaultNationality)
  const [region, setRegion] = useState<string | null>(regionMap[defaultNationality] ?? null)

  const taxonomy = formTaxonomyData()

  const handleCountrySelect = (e: any) => {
    setCountry(e.target.value)
    setRegion(regionMap[e.target.value])
  }

  const handleFormSubmit = async (values: LeadCaptureFormValues) => {
    let tealiumCookie = ''
    let tealiumSessionId = ''
    const utagData: { [key: string]: any } | null = window?.utag?.data ?? null

    if (utagData && utagData?.tealium_visitor_id?.length > 0) {
      tealiumCookie = utagData.tealium_visitor_id
    }

    if (utagData && utagData?.tealium_session_id?.length > 0) {
      tealiumSessionId = utagData.tealium_session_id
    }

    const nationality = values.nationality
    const countryItem = taxonomy?.location?.find((country) => country.key == nationality) ?? ''
    const isoCode = countryItem ? countryItem.iso : ''
    const formattedStartDate = reformatDateForSubmit(values.desiredStartDate)

    let formData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      mobile: values.mobile,
      nationality: values.nationality,
      intendedLevelOfStudy: values.intendedLevelOfStudy,
      privacyPolicyAgreed: true,
      inzMatchingPermissionOptin: values.inzMatchingPermissionOptin,
      desiredStartDate: formattedStartDate,
      nationalityISOCode: isoCode,
      tealiumVisitorId: tealiumCookie,
      tealiumSessionId,
      commsOptin: true,
      source: tealium.source,
      formSchemaName: 'leadCaptureFormSchema',
      lang,
      t,
    }

    // Check if the region has been added
    // If so we want to push this in to our formData object
    formData = {
      ...formData,
      ...(values.stateRegion && { stateRegion: values.stateRegion }),
    }

    postLeadCaptureFormSubmissionToLeadCaptureAPIEndpoint(formData, {
      success: () => {
        setSuccessfulSubmission(true)
        onSuccessCallback?.()
        utagData &&
          dispatchFormSubmissionUtagLinkEvent(
            tealium.formType,
            tealium.formName,
            window.location.href,
            `${values.intendedLevelOfStudy}` || `${profile?.studyInterests?.levelOfStudy}` || '',
            `${values.nationality}` || `${profile?.demographics?.nationality}` || ''
          )
      },
      error: () => {
        setSubmissionError(true)
        onErrorCallback?.()
      },
    })
  }

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          mobile: '',
          nationality: defaultNationality,
          intendedLevelOfStudy: '',
          desiredStartDate: '',
          privacyPolicyAgreed: true,
          inzMatchingPermissionOptin: false,
        }}
        validationSchema={leadCaptureFormSchema(lang)}
        onSubmit={handleFormSubmit}
      >
        {({ isValid, isSubmitting, dirty }) => (
          <Form>
            <Stack spacing={4} alignItems="flex-start">
              {!isValid && (
                <Flex
                  border="2px solid"
                  borderRadius="base"
                  borderColor="red.400"
                  backgroundColor="red.50"
                  padding={3}
                  lineHeight="1.125"
                  alignItems="center"
                >
                  <Box boxSize={6} fill="red.400">
                    <WarningIcon />
                  </Box>
                  <Text fontFamily="enz400" mb="0" paddingLeft={2} color="grey.500">
                    {`Please complete the highlighted fields below.`}
                  </Text>
                </Flex>
              )}
              <Stack spacing={6} alignItems="flex-start">
                <Stack spacing="10px" width="100%">
                  <SmallText color="grey.500" lineHeight="none" m={0}>
                    {`All fields are required`}
                  </SmallText>
                  <Form.FirstNameField />
                  <Form.LastNameField />
                  <Form.EmailAddressField />
                  <Form.PhoneNumberField
                    name="mobile"
                    isRequired
                    country={t('mobile_number.country')}
                  />
                  <Form.LevelOfStudyField
                    name="intendedLevelOfStudy"
                    levelOfStudyOptions={taxonomy.levelOfStudy}
                    isRequired
                  />
                  <Form.NationalityField
                    nationalityOptions={taxonomy.location}
                    onInput={(e: any) => handleCountrySelect(e)}
                    isRequired={lang !== 'ko'}
                  />
                  {country && Object.keys(regionMap).includes(country) && (
                    <Form.RegionField
                      isRequired
                      regionOptions={getRegionOptions(region, taxonomy)}
                    />
                  )}
                  <Form.StartDateField
                    isRequired
                    name="desiredStartDate"
                    label="Desired start date"
                  />
                </Stack>
                <CheckboxControl
                  mt={1}
                  mr={2}
                  name="inzMatchingPermissionOptin"
                  extendedText={{ label: 'Read' }}
                >
                  <FormINZMatchingPermissionOptIn />
                </CheckboxControl>
                FormINZMatchingPermissionOptIn
                <Box visibility="hidden">
                  {/* @ts-ignore https://github.com/dozoisch/react-google-recaptcha/issues/277 */}
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY ?? ''}
                  />
                </Box>
              </Stack>
              <Box>
                <Button
                  type="submit"
                  isDisabled={!(dirty && isValid) || isSubmitting}
                  mb={3}
                  aria-label="submit"
                >
                  Submit
                </Button>
              </Box>
              <FormAgreements includeAgreePolicy />
            </Stack>
          </Form>
        )}
      </Formik>
      {successfulSubmission && successMessage && (
        <Box px={4} py={16} textAlign="center">
          <Box boxSize={16} color="success" marginBottom={4} mx="auto">
            <TickRoundedIcon />
          </Box>
          <H3>{successMessage.heading}</H3>
          <LargeText mb={3}>{successMessage.bodyText}</LargeText>
        </Box>
      )}
      {submissionError && errorMessage && (
        <Box px={4} py={16} textAlign="center">
          <H3 color="error">{errorMessage.heading}</H3>
          <SmallText color="error">{errorMessage.bodyText}</SmallText>
        </Box>
      )}
    </>
  )
}

export default LeadCaptureForm
export { leadCaptureFormSchema }
export type { LeadCaptureFormValues }
