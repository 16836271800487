import { CurrentRefinementsProvided } from 'react-instantsearch-core'
import { Button } from '../../buttons'
import { connectCurrentRefinements } from '../Algolia'

export const Component = ({ items, refine }: CurrentRefinementsProvided) => {
  return (
    <Button
      aria-label='Clear filters'
      onClick={() => refine(items)}
      isDisabled={!items.length}
      variant='link'
    >
      Clear filters
    </Button>
  )
}

export default connectCurrentRefinements(Component)
