import * as fs from 'fs'

const OUTPUT_FOLDER = __dirname
const fileName = `${OUTPUT_FOLDER}/cache.json`

const multiplyTime = {
  minutes: 60,
  seconds: 60,
  milliseconds: 1000
}

const millisecondsToExpiry =
  multiplyTime.milliseconds * multiplyTime.seconds * multiplyTime.minutes * 24 // Hours

function readCache() {
  const cache = fs.readFileSync(fileName)
  return JSON.parse(cache.toString())
}

/**
 * Check the cache to see if it is still valid
 * i.e. is the current time past the expiry
 */
function expired() {
  try {
    const { expiry } = readCache()
    const now = new Date().getTime()
    return now > expiry
  } catch (error) {
    if (isNodeError(error) && error?.code === 'ENOENT') {
      // File not found or show us the error
      return true
    }

    return console.error(error)
  }
}

/**
 * @param error the error object.
 * @returns if given error object is a NodeJS error.
 */
const isNodeError = (error: any): error is NodeJS.ErrnoException =>
  error instanceof Error

function update() {
  try {
    const expiry = new Date().getTime() + millisecondsToExpiry
    fs.writeFileSync(fileName, JSON.stringify({ expiry }, null, 2))
  } catch (error) {
    // File not found or show us the error
    console.error('Taxonomy cache write failed')
    console.error(error)
  }
}

export function expiredCache() {
  if (expired()) {
    update()
    return true
  }

  return false
}
