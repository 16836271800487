import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputProps,
  Text
} from '@chakra-ui/react'
import { ErrorMessage, useField } from 'formik'
import React from 'react'
import PhoneInput from 'react-phone-input-2'
import { ZINDEX } from 'utils'

export type InputControlProps = FormControlProps & InputProps

export interface Props extends InputControlProps {
  name: string
  label?: string
  isRequired?: boolean
  countryCodeEditable?: boolean
  disableDropdown?: boolean
  country?: string
}

const PhoneNumberInput = ({
  label,
  isRequired,
  name,
  countryCodeEditable,
  disableDropdown,
  country,
  ...props
}: Props) => {
  const [field, meta, { setValue }] = useField(name)
  const { value } = field
  const [isFocused, setFocused] = React.useState(false)
  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)
  const errorTouched = meta.error && meta.touched
  const toggleClass = isFocused || value ? 'active' : ''

  return (
    <FormControl
      id={name}
      isInvalid={errorTouched ? true : false}
      position='relative'
      zIndex={ZINDEX.PHONE_NUMBER_INPUT}
      lineHeight='none'
      overflow='visible'
      _hover={{
        'label:not(.active)': {
          color: 'deepblue.500'
        }
      }}
      sx={{
        '.react-tel-input': {
          display: 'flex',
          flexDir: 'row-reverse',
          width: '100%',
          height: '3.75rem',
          pl: 4,
          backgroundColor: 'teal.100',
          borderRadius: 'base',
          border: errorTouched || isFocused ? '2px solid' : 'none',
          borderColor: errorTouched
            ? 'red.400'
            : isFocused
              ? 'teal.500'
              : 'transparent',
          color: 'deepblue.500',
          '> .form-control': {
            marginLeft: 'unset',
            paddingLeft: 'unset',
            lineHeight: 'unset',
            height: '100%',
            width: '100%',
            fontSize: 'inherit',
            letterSpacing: 'unset',
            pt: '1rem',
            background: 'transparent',
            borderRadius: 'base',
            borderColor: 'transparent',
            '&:focus-visible, &:focus': {
              border: 'none',
              borderImageWidth: 0,
              outline: 0
            }
          },
          '.flag-dropdown': {
            display: disableDropdown ? 'none' : 'block',
            pos: 'unset',
            top: 'unset',
            bottom: 'unset',
            border: 'none',
            background: 'transparent',
            '&.open .selected-flag': {
              background: 'transparent'
            }
          },
          '.selected-flag': {
            display: 'flex',
            flexDir: 'column',
            paddingTop: '1rem',
            paddingLeft: 0,
            background: 'transparent',
            '.flag': {
              pos: 'unset',
              top: 'unset',
              margin: 'auto 0'
            }
          },
          '.country-list': {
            width: '100%',
            top: 'calc(100% + 2px)',
            left: 0,
            right: 0,
            margin: 0,
            borderRadius: 'base',
            boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.1)'
          }
        },
        'html[lang=ar] &': {
          /* GOTCHA!
           * This is acutally align 'left'
           * stylis RTL plugin changes this to 'left' for us */
          '.react-tel-input': {
            pr: 4,
            pl: 'unset'
          }
        }
      }}
      {...field}
    >
      <PhoneInput
        value={value}
        onChange={(value: string) => {
          setValue(value)
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        containerClass={toggleClass}
        inputProps={{ required: isRequired, id: name }}
        country={country}
        countryCodeEditable={countryCodeEditable}
        disableDropdown={disableDropdown}
        autoFormat={false}
        placeholder=''
      />

      <FormLabel
        color='deepblue.300'
        position='absolute'
        top='12px'
        left='1rem'
        m={0}
        cursor='text'
        transition='all 0.3s'
        lineHeight='none'
        fontSize='0.75rem'
        className={toggleClass}
        sx={{
          pointerEvents: 'none',
          '&.active': {
            color: 'surfaceBlue'
          },
          '&[data-invalid]': {
            color: 'red.400'
          },
          'html[lang=ar] &': {
            /* GOTCHA!
             * This is acutally align 'left'
             * stylis RTL plugin changes this to 'left' for us */
            right: '64px',
            left: 0
          }
        }}
      >
        {label}
        {isRequired && (
          <Text as='span' color='red.400'>
            &nbsp;*
          </Text>
        )}
      </FormLabel>
      <FormErrorMessage mb={2} pl={4}>
        <ErrorMessage name={name} />
      </FormErrorMessage>
      {props.isInvalid && (
        <FormHelperText
          as='span'
          fontSize='sm'
          color='red.400'
          ml={4}
          mt={1}
          display='inline-block'
        >
          Help text
        </FormHelperText>
      )}
    </FormControl>
  )
}
export default PhoneNumberInput
