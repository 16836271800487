/// <reference types="@welldone-software/why-did-you-render" />
import React from 'react'

const showWDYR = process.env.NEXT_PUBLIC_USE_WDYR

if (showWDYR === 'true' && process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require('@welldone-software/why-did-you-render')
  whyDidYouRender(React, {
    trackAllPureComponents: true
  })
}
