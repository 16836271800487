import algoliasearch, { SearchClient } from 'algoliasearch'
import qs from 'qs'
import { SearchState } from 'react-instantsearch-core'

interface CreateConfig {
  appId?: string
  searchApiKey?: string
  indexName: string
}
interface CreateConfigResult {
  searchClient: SearchClient
  indexName: string
}

export enum SearchStateFilter {
  REFINEMENT_LIST = 'refinementList',
  MENU = 'menu'
}
export interface FinderFieldMap {
  attribute: string
  filterType: SearchStateFilter
}

export type FinderFieldMaps = FinderFieldMap[]

const algoliaSearchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID ?? '',
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY ?? ''
)

const urlKeyToInstantSearchKey: Record<string, string> = {
  levelOfStudy: 'levelOfStudy.name',
  levelsOfStudy: 'levelsOfStudy.name',
  areasOfStudy: 'areasOfStudy.name',
  subjectsOfStudy: 'subjectsOfStudy.name',
  regions: 'regions.name',
  countries: 'countries.name',
  nationalities: 'nationalities',
  deliveryModes: 'deliveryModes.name',
  subtype: 'subtypeList.name',
  providerSubtype: 'providerSubtype.name',
  providerSubtypeList: 'providerSubtypeList.name',
  market: 'countries',
  city: 'citiesWithCountries'
}

// Swap key and values from urlKeyToInstantSearchKey
const instantSearchKeyToUrlKey = Object.entries(
  urlKeyToInstantSearchKey
).reduce<Record<string, string>>((ret, [key, value]) => {
  ret[value] = key
  return ret
}, {})

const filterFieldValueTypes: Record<SearchStateFilter, 'array' | 'scalar'> = {
  [SearchStateFilter.REFINEMENT_LIST]: 'array',
  [SearchStateFilter.MENU]: 'scalar'
}

export const createConfig = ({
  indexName,
  appId = '',
  searchApiKey = ''
}: CreateConfig): CreateConfigResult => {
  // Normally we want to use algoliaSearchClient so we can use the same
  // algoliasearch instance across the site.
  const searchClient =
    Boolean(appId) && Boolean(searchApiKey)
      ? algoliasearch(appId, searchApiKey)
      : algoliaSearchClient

  return {
    searchClient,
    indexName
  }
}

export const createURL = (
  searchState: SearchState,
  addQueryPrefix = true
): string => {
  return `${qs.stringify(createURLSearchState(searchState), {
    arrayFormat: 'repeat',
    format: 'RFC1738',
    addQueryPrefix
  })}`
}

export const pathToSearchState = (
  path: string | undefined,
  filterMaps: FinderFieldMaps
): SearchState | {} => {
  if (!path || !path.includes('?')) {
    return {}
  }

  const params = path.substring(path.indexOf('?') + 1)
  const urlState = qs.parse(params, {
    decoder: paramsDecoder,
    ignoreQueryPrefix: true
  })

  /**
   * If a given url query clause is empty of a value we do not include it in eventual SearchState
   * e.g. given: "http://localhost:3000/study-options/course/results?levelOfStudy=&", "levelOfStudy" is an empty query
   */
  for (const next in urlState) {
    if (urlState.hasOwnProperty(next) && urlState[next] == '') {
      delete urlState[next]
    }
  }

  return convertFriendlyURLSToSearchStateLabels(urlState, filterMaps)
}

export function createURLSearchState(searchState: SearchState) {
  const {
    refinementList = {},
    menu = {},
    query,
    page,
    configure,
    ...rest
  } = searchState
  const newQuery = query ? { query: query ?? '' } : {}
  const newPage = Boolean(page) && page !== 1 ? { page: page } : {}

  // Note: configure has been destructured from searchState but excluded from newState to exclude it from the url
  const newState = {
    ...newQuery,
    ...newPage,
    ...convertKeysToFriendlyURLS(refinementList),
    ...convertKeysToFriendlyURLS(menu),
    ...rest
  }

  return newState
}

export const searchStateToURL = (
  pathname: string,
  searchState: SearchState
): string => {
  const params = createURL(searchState)
  const url = params ? `${pathname}${params}` : ''
  return url
}

/**
 * Takes urlParams object and distributes the fields under
 * their correct widget type.
 *
 * It expects a filterMap to be set for each InstantSearchWidget expecting to pull the
 * state from the URL.
 *
 * e.g. { levelOfStudy.name: "Bachelors" } => {refinementList: { levelOfStudy.name: "Bachelors" }}
 */
function convertFriendlyURLSToSearchStateLabels(
  urlState: Record<string, unknown>,
  filterMaps: FinderFieldMaps
) {
  const newSearchState = Object.entries(urlState).reduce<SearchState>(function (
    latestState,
    currentItem
  ) {
    const [key, value] = currentItem

    const matchingEntry = filterMaps.find((entry) => entry.attribute === key)

    if (!matchingEntry) {
      return {
        ...latestState,
        [key]: value
      }
    }

    // A refinementList expects an array but if there is just one item for the attribute then
    // it comes through as a scalar
    const values =
      filterFieldValueTypes[matchingEntry.filterType] === 'array' &&
      !Array.isArray(value)
        ? [value]
        : value

    return {
      ...latestState,
      [matchingEntry.filterType]: {
        ...latestState[matchingEntry.filterType],
        [matchingEntry.attribute]: values
      }
    }
  }, {})

  return newSearchState
}

/**
 * Converts incoming params to algolia attributes
 * e.g levelOfStudy => levelOfStudy.name
 */
function paramsDecoder(
  str: string,
  defaultDecoder: qs.defaultDecoder,
  _charset: string,
  type: 'key' | 'value'
) {
  return type === 'key' && str in urlKeyToInstantSearchKey
    ? urlKeyToInstantSearchKey[str]
    : defaultDecoder(str)
}

/**
 * Converts InstantSearch fields to easy to ready params
 *
 * It requires fields in urlKeyToInstantSearchKey to be set
 */
function convertKeysToFriendlyURLS(
  items: Record<string, string | string[] | number>
) {
  return Object.entries(items).reduce((latestState, currentItem) => {
    const [key, value] = currentItem

    return {
      ...latestState,
      ...(key in instantSearchKeyToUrlKey
        ? { [instantSearchKeyToUrlKey[key]]: value }
        : { [key]: [value] })
    }
  }, {})
}
