import { Box, BoxProps, Stack, useDisclosure } from '@chakra-ui/react'
import { ReactElement, useRef, useState } from 'react'
import { TEALIUM_LINK_CATEGORY } from 'tracking'
import { CtaButton, Headline, VideoButton, VideoModal } from 'ui'

type Video = {
  url: string
  buttonLabel: string
}

interface CampaignHeroProps {
  heading?: string
  heroBackgroundVideoUrl: string
  heroBackgroundVideoTitle?: string
  heroBackgroundVideoDescription?: string
  video: Video
  image: ReactElement | null
  pageTheme?: string
  leadCaptureFormId?: string
}

const CampaignHero = ({
  heading,
  heroBackgroundVideoUrl,
  heroBackgroundVideoTitle,
  heroBackgroundVideoDescription,
  image,
  video,
  pageTheme,
  leadCaptureFormId
}: CampaignHeroProps): ReactElement => {
  const headingContainerRef = useRef<HTMLDivElement>(null)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const { isOpen, onClose, onOpen } = useDisclosure()

  //Displays fallback image if background video is faulty or not supplied
  const renderImage =
    (heroBackgroundVideoUrl !== '' && !isPlaying) ||
    heroBackgroundVideoUrl === ''

  const heroImageWrapperStyleProps: BoxProps = {
    position: renderImage ? 'absolute' : 'relative',
    minH: '90vh',
    height: 'full',
    width: 'full'
  }

  return (
    <Box
      position='static'
      alignItems='center'
      justifyContent='center'
      marginTop={0}
    >
      <Box position='relative' overflow='hidden'>
        {/* Fallback image */}
        {renderImage && <Box {...heroImageWrapperStyleProps}>{image}</Box>}
        <Box>
          {/* Background looping video if one is supplied */}
          {heroBackgroundVideoUrl !== '' && (
            <Box
              as='video'
              playsInline={true}
              autoPlay={true}
              muted={true}
              loop={true}
              position='relative'
              minH='90vh'
              height='auto'
              width='full'
              objectFit='cover'
              onPlay={() => setIsPlaying(!isPlaying)}
            >
              <Box
                as='source'
                aria-label={heroBackgroundVideoDescription}
                src={heroBackgroundVideoUrl}
                title={heroBackgroundVideoTitle}
              />
            </Box>
          )}
        </Box>
        <Box
          position='absolute'
          top='50%'
          left='50%'
          transform='translate(-50%, -50%)'
          flexDir='column'
          zIndex={1}
          width={{ base: 'unset', lg: 'fit-content' }}
        >
          <Box
            ref={headingContainerRef}
            color='white'
            maxWidth='876px'
            display='flex'
            textAlign='center'
            position='relative'
          >
            {heading && (
              <Headline
                as='h1'
                position='relative'
                marginBottom={0}
                fontSize={{ base: '140px', lg: '200px' }}
                letterSpacing='-6px'
                lineHeight={{ base: 0.85, lg: 1 }}
              >
                {heading}
              </Headline>
            )}
          </Box>
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            pt='10px'
            w='full'
            justifyContent='center'
            alignItems='center'
            spacing={{ base: 2, lg: 4 }}
          >
            {video?.url && (
              <VideoButton
                onClick={onOpen}
                minWidth='fit-content'
                width='200px'
                height='60px'
                sx={{
                  '.chakra-button__icon': {
                    width: '24px'
                  }
                }}
                _hover={{
                  background: 'white',
                  span: {
                    color: 'deepblue.500',
                    svg: {
                      fill: 'deepblue.500'
                    }
                  }
                }}
              >
                <Box
                  as='span'
                  display='block'
                  maxW='230px'
                  lineHeight='1.2'
                  textAlign='left'
                  fontSize='md'
                  noOfLines={1}
                  wordBreak='break-all'
                  color='white'
                  margin='0px 16px'
                >
                  Watch video
                </Box>
              </VideoButton>
            )}
            {pageTheme && (
              <CtaButton
                aria-label='Explore More'
                linkCategory={TEALIUM_LINK_CATEGORY.CTA}
                leadCaptureFormId={leadCaptureFormId}
                href={`#${pageTheme}-intro-element`}
                width='200px'
                height='60px'
              >
                Explore more
              </CtaButton>
            )}
          </Stack>
        </Box>
        {video?.url && (
          <VideoModal url={video.url} isOpen={isOpen} onClose={onClose} />
        )}
      </Box>
    </Box>
  )
}

export type { CampaignHeroProps }
export default CampaignHero
