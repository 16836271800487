import { Box } from '@chakra-ui/react'
import { ChevronCircle } from 'icons'
import { useRouter } from 'next/router'
import React from 'react'
import Button, { ButtonProps } from '../Button'

interface BackButtonProps extends ButtonProps {
  label?: string
  hideTextOnMobile?: boolean
  darkTheme?: boolean
}

const BackButton = ({
  label,
  hideTextOnMobile,
  darkTheme,
  ...props
}: BackButtonProps) => {
  const router = useRouter()

  return (
    <Button
      fontFamily='enz500'
      color={darkTheme ? 'white' : 'deepblue.500'}
      variant='link'
      aria-label={label}
      onClick={() => router.back()}
      sx={{
        '&:hover': {
          path: {
            fill: darkTheme ? 'white' : 'deepWaterBlue',
            transition: 'all 250ms ease 0s'
          }
        },
        '.chakra-button__icon': {
          mr: '10px',
          'html[lang=ar] &': {
            mr: 'unset',
            ml: '10px'
          }
        }
      }}
      _hover={{
        '.chevron-circle--chevron': {
          fill: darkTheme ? 'deepWaterBlue' : 'white'
        }
      }}
      _active={{
        fill: darkTheme ? 'white' : 'deepWaterBlue'
      }}
      leftIcon={
        <Box borderRadius='full' boxSize={8} transform='rotate(180deg)'>
          <ChevronCircle />
        </Box>
      }
      {...props}
    >
      <Box
        as='span'
        display={{
          base: hideTextOnMobile ? 'none' : 'inline-block',
          lg: 'inline-block'
        }}
      >
        {label ? label : 'Back'}
      </Box>
    </Button>
  )
}

export default BackButton
