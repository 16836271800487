import {
  Container,
  Flex,
  GridItem,
  HStack,
  useTheme,
  useToken
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { Carousel } from 'react-responsive-carousel'
import { useMediaQuery } from 'utils/src/helpers'
import { ContainerWrapper, GridLayout } from '../../layout'
import { H4 } from '../../typography'
import RegionCard, { RegionCardProps } from './RegionCard'
import RegionCardCarouselNavigationButtons from './RegionCardsCarouselButtons'

enum NavigationType {
  Next = 'next',
  Previous = 'previous'
}

export enum RegionCardLayoutType {
  Grid = 'grid',
  Carousel = 'carousel'
}

interface RegionCardsCarouselProps {
  title?: string
  cardType: RegionCardLayoutType
  cards: Array<RegionCardProps | null>
  backgroundColor?: string
  applyCardBackgroundColor: boolean
}

export interface CarouselState {
  currentSlide: number
}

export const CAROUSEL_INDEX_CONSTANTS = Object.freeze({
  START_SLIDE: 0,
  DESKTOP: {
    SLIDES_PER_CONTAINER: 3
  },
  MOBILE: {
    SLIDES_PER_CONTAINER: 1
  }
})

const RegionCardsCarousel = ({
  title,
  cards,
  cardType,
  backgroundColor,
  applyCardBackgroundColor
}: RegionCardsCarouselProps) => {
  const theme = useTheme()
  const isLarge = useMediaQuery(`(min-width: ${theme.breakpoints.lg})`)
  const isMedium = useMediaQuery(`(min-width: ${theme.breakpoints.md})`)
  const [carouselCardGap] = useToken('space', ['5'])

  const [carouselState, setCarouselState] = useState<CarouselState>({
    currentSlide: CAROUSEL_INDEX_CONSTANTS.START_SLIDE
  })

  const numberOfSlides = cards.length

  useEffect(() => {
    if (isLarge)
      setCarouselState({
        ...carouselState,
        currentSlide: CAROUSEL_INDEX_CONSTANTS.START_SLIDE
      })
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLarge])

  // Number of slides to show within the container
  const slidesInView: number = isLarge
    ? CAROUSEL_INDEX_CONSTANTS.DESKTOP.SLIDES_PER_CONTAINER
    : CAROUSEL_INDEX_CONSTANTS.MOBILE.SLIDES_PER_CONTAINER

  const updateCurrentSlide = (index: number): void => {
    if (carouselState.currentSlide !== index) {
      setCarouselState({
        ...carouselState,
        currentSlide: index
      })
    }
  }

  const carouselOverrideCss = {
    'html[lang=ar] &': {
      //actually left to right
      direction: 'rtl'
    },
    '.carousel-root, .carousel-slider, .slider-wrapper': {
      overflow: 'visible'
    }
  }

  // Grid Layout is only for desktop. Grid displays as carousel on mobile.
  const showCarousel =
    (!isLarge && cardType === RegionCardLayoutType.Grid) ||
    cardType === RegionCardLayoutType.Carousel

  const cardBackgroundColor = applyCardBackgroundColor
    ? 'deepblue.500'
    : 'white'
  const cardColor = applyCardBackgroundColor ? 'white' : 'deepblue.600'
  const addCardContentBorder =
    backgroundColor === 'white' && cardBackgroundColor === 'white'

  return (
    <ContainerWrapper
      position='relative'
      overflow='hidden'
      backgroundColor={backgroundColor ?? 'white'}
      px={0}
    >
      <Container>
        <Flex
          flexDirection={isMedium ? 'row' : 'column'}
          justifyContent='space-between'
          wrap='wrap'
          marginBottom={{
            base: 3,
            lg: 10
          }}
        >
          <H4
            maxW='container.sm'
            fontSize={{ base: '36px', lg: '48px' }}
            mb={2}
          >
            {title as string}
          </H4>
          {showCarousel && (
            <HStack alignSelf='flex-end' dir='ltr'>
              <RegionCardCarouselNavigationButtons
                slidesInView={slidesInView}
                numberOfSlides={numberOfSlides}
                currentSlide={carouselState.currentSlide}
                updateCurrentSlide={updateCurrentSlide}
              />
            </HStack>
          )}
        </Flex>
      </Container>

      {showCarousel ? (
        <Container maxWidth='container.xl' sx={carouselOverrideCss}>
          <Carousel
            centerMode={true}
            autoPlay={false}
            infiniteLoop={false}
            emulateTouch={true}
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            showIndicators={false}
            selectedItem={carouselState.currentSlide}
            centerSlidePercentage={100}
            width={
              isLarge ? `calc(33.33% + 6px)` : `calc(100% + ${carouselCardGap})`
            }
            onChange={(index) => {
              updateCurrentSlide(index)
            }}
          >
            {cards.map((card, index) => {
              if (!card) {
                return ''
              }

              return (
                <RegionCard
                  {...card}
                  addContentBorder={addCardContentBorder}
                  backgroundColor={cardBackgroundColor}
                  color={cardColor}
                  paddingRight={carouselCardGap}
                  key={index}
                />
              )
            })}
          </Carousel>
        </Container>
      ) : (
        <Container>
          <GridLayout
            gap={5}
            templateColumns={{
              lg: 'repeat(3, 1fr)'
            }}
          >
            {cards.map((card, index) => {
              if (!card) {
                return ''
              }
              return (
                <GridItem key={index}>
                  <RegionCard
                    {...card}
                    addContentBorder={addCardContentBorder}
                    backgroundColor={cardBackgroundColor}
                    color={cardColor}
                  />
                </GridItem>
              )
            })}
          </GridLayout>
        </Container>
      )}
    </ContainerWrapper>
  )
}

export default RegionCardsCarousel
export type { RegionCard }
export { NavigationType }
