import {
  StudyOptionItemType,
  StudyOptionsAPILearnerInventoryItemModel,
  StudyOptionsAPIValues,
  StudyOptionStatus,
} from 'ui'

export const initialFilterState = (
  options: {
    providers: StudyOptionsAPILearnerInventoryItemModel[]
    courses: StudyOptionsAPILearnerInventoryItemModel[]
    scholarships?: StudyOptionsAPILearnerInventoryItemModel[]
  },
  loading: boolean
) => {
  if (loading || options.providers.length > 0) return StudyOptionItemType.Provider
  if (options.courses.length > 0) return StudyOptionItemType.Course
  if (options.scholarships && options.scholarships.length > 0)
    return StudyOptionItemType.Scholarship

  return StudyOptionItemType.Provider
}

export const mapToItemIds = (studyOptionItems: StudyOptionsAPILearnerInventoryItemModel[]) => {
  return studyOptionItems?.map((studyOptionItem) => studyOptionItem['itemId']) ?? []
}

export const filterByStatus = (studyOptions: StudyOptionsAPIValues, status: StudyOptionStatus) => {
  const { items } = studyOptions
  return items?.filter((item) => item['status'] == status) ?? []
}

export const filterByItemType = (
  studyOptionItems: StudyOptionsAPILearnerInventoryItemModel[],
  itemType: StudyOptionItemType
) => {
  return studyOptionItems?.filter((item) => item['itemType'] == itemType) ?? []
}
