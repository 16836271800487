import {
  Checkbox,
  CheckboxGroup,
  CheckboxProps,
  Collapse,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Link,
  Text
} from '@chakra-ui/react'
import { ErrorMessage, useField } from 'formik'
import React, { ReactNode, useEffect, useState } from 'react'

interface ExtendedText {
  /**
   * The label for the extended text
   * @deprecated use showMoreLabel/showLessLabel instead to allow translations
   */
  label?: string
  showMoreLabel?: string
  showLessLabel?: string
  height?: number
}

export type CheckboxControlProps = Omit<FormControlProps, 'label'> &
  CheckboxProps & {
    name: string
    helpText?: string
    children?: ReactNode
    extendedText?: ExtendedText
    errorMessage?: ReactNode
  }

const CheckboxControl = ({
  name,
  helpText,
  children,
  extendedText,
  errorMessage,
  ...props
}: CheckboxControlProps) => {
  const [field, meta] = useField(name)
  const { value } = field
  const [isFocused, setFocused] = React.useState(false)
  const onFocus = () => setFocused(!isFocused)
  const onBlur = () => setFocused(false)
  const errorTouched = meta.error && !value
  const addErrorClass = errorTouched ? 'error ' : ''
  const toggleClass = isFocused || value ? addErrorClass + 'active' : ''

  // Keep track of the checkbox state
  const [isChecked, setIsChecked] = useState(false)
  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked)
  }

  return (
    <FormControl
      id={name}
      isInvalid={errorTouched ? true : false}
      isRequired={props.isRequired}
      position='relative'
      lineHeight='none'
      overflow='hidden'
      mt={4}
      _hover={{
        'label:not(.active)': {
          color: 'deepblue.500'
        }
      }}
      {...field}
    >
      <HStack alignItems='flex-start'>
        <CheckboxGroup>
          <Checkbox
            color='deepblue.500'
            onFocus={onFocus}
            onBlur={onBlur}
            isChecked={isChecked}
            onChange={handleCheckboxChange}
            className={toggleClass}
            name={name}
            {...props}
            sx={{
              '&.active': {
                color: 'deepblue.500',
                '&.error': {
                  color: 'red.400',
                  borderColor: 'red.400'
                }
              },
              '&.error': {
                color: 'red.400',
                borderColor: 'red.400'
              }
            }}
          />
          <FormLabel
            color='deepblue.300'
            left={4}
            m={0}
            transition='all 0.3s'
            className={toggleClass}
            htmlFor={name}
            sx={{
              '&.active': {
                '&.error': {
                  color: 'red.400'
                }
              },
              '&.error': {
                color: 'red.400'
              }
            }}
          >
            <CheckboxControl.Label
              label={extendedText?.label}
              showMoreLabel={extendedText?.showMoreLabel}
              showLessLabel={extendedText?.showLessLabel}
              height={extendedText?.height}
            >
              {children}
            </CheckboxControl.Label>
          </FormLabel>
        </CheckboxGroup>
      </HStack>
      <CheckboxControl.ErrorMessage
        errorMessage={errorMessage}
        name={name}
        isChecked={isChecked}
      />

      {!errorTouched && helpText && (
        <FormHelperText as='span' fontSize='sm' pl={7} display='inline-block'>
          *{helpText}
        </FormHelperText>
      )}
    </FormControl>
  )
}

CheckboxControl.ErrorMessage = function CheckboxControlErrorMessage({
  errorMessage,
  name,
  isChecked
}: {
  name: string
  errorMessage?: ReactNode
  isChecked?: boolean
}) {
  // Keep track if the user has clicked the checkbox for the second time.
  const [isSecondClick, setIsSecondClick] = useState(false)

  useEffect(() => {
    if (isChecked) {
      setIsSecondClick(true)
    }
  }, [isChecked])

  return errorMessage && isSecondClick ? (
    <Text
      pl={'35px'}
      mt={'8px'}
      fontSize={14}
      lineHeight={'14px'}
      textColor='red.500'
    >
      {errorMessage}
    </Text>
  ) : (
    <FormErrorMessage mt={4} pl={9}>
      <ErrorMessage name={name} />
    </FormErrorMessage>
  )
}

/**
 * TODO: remove the label prop and replace with individual showMoreLabel/showLessLabel props
 * this allows these to be individually translated
 *
 * Currently the component allows all three props to be passed
 * and will use the label prop if it exists, otherwise it will use
 * the showMoreLabel/showLessLabel props
 */
CheckboxControl.Label = function CheckboxControlLabel({
  label,
  showMoreLabel,
  showLessLabel,
  height,
  children
}: {
  // @deprecated use showMoreLabel/showLessLabel instead
  label?: string
  // Allows translation of the show more label
  showMoreLabel?: string
  // Allows translation of the show less label
  showLessLabel?: string
  height?: number
  children: ReactNode
}) {
  const [show, setShow] = useState(false)
  const handleToggle = () => setShow(!show)

  return label || showMoreLabel || showLessLabel ? (
    <>
      <Collapse startingHeight={height ?? 48} in={show}>
        {children}
      </Collapse>
      <Link fontSize='sm' display='block' mt={2} onClick={handleToggle}>
        {label && label}
        {show
          ? showLessLabel
            ? showLessLabel
            : ' less'
          : showMoreLabel
            ? showMoreLabel
            : ' more'}
      </Link>
    </>
  ) : (
    <>{children}</>
  )
}

export default CheckboxControl
