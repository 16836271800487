import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Center
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { MinusIcon } from 'icons'
import { ReactElement } from 'react'
import { H5 } from '../../typography'

export interface AccordionItemsProps {
  accordionItemsToRenderArray?: AccordionElement[] | undefined
}

export interface AccordionElement {
  title: string
  bodyText?: ReactElement | null
}

const AccordionItems = ({
  accordionItemsToRenderArray
}: AccordionItemsProps): ReactElement => {
  const padLeadingZeros = (num: number) => {
    let itemNumber = `${String(num + 1)}` + ''
    while (itemNumber.length < 2) itemNumber = '0' + itemNumber
    return itemNumber
  }

  const AnimatedPlus = motion(MinusIcon)

  return (
    <Box>
      {accordionItemsToRenderArray &&
        accordionItemsToRenderArray.map((item: AccordionElement, index) => {
          return (
            <AccordionItem key={index} _first={{ borderTop: 0 }}>
              {({ isExpanded }) => (
                <Box>
                  <Box>
                    <AccordionButton
                      minH={{ base: 12, lg: 16 }}
                      _hover={{
                        bgColor: 'none',
                        color: 'teal.500',
                        transition: 'color 0.15s ease-out'
                      }}
                      transition='color 0.15s ease-in'
                      pl={0}
                      pt={{ base: 4, lg: 6 }}
                      pb={isExpanded ? 0 : { base: 4, lg: 6 }}
                    >
                      <Center
                        boxSize={{ base: '50px', lg: '60px' }}
                        color='white'
                        fontSize={{ base: '20px', lg: '22px' }}
                        bgColor='teal.500'
                        fontFamily='enz700'
                        borderRadius='full'
                      >
                        {padLeadingZeros(index)}
                      </Center>
                      <Box
                        flex='1'
                        textAlign='left'
                        ml={{ base: 4, lg: '50px' }}
                      >
                        <H5 mb={0} mr={3}>
                          {item.title ? item.title : `FAQ ${index + 1}`}
                        </H5>
                      </Box>
                      <Box position='relative' h='32px' w='32px'>
                        <MinusIcon
                          boxSize={{ base: 5, lg: 8 }}
                          position='absolute'
                          top={0}
                          left={0}
                        />
                        <AnimatedPlus
                          boxSize={{ base: 5, lg: 8 }}
                          position='absolute'
                          top={0}
                          left={0}
                          transition={{ duration: 0.35, type: 'spring' }}
                          animate={{ rotate: isExpanded ? 0 : 90 }}
                        />
                      </Box>
                    </AccordionButton>
                  </Box>
                  <AccordionPanel
                    pb={{ base: '8px', lg: '32px' }}
                    pl={{ base: 0, lg: '110px' }}
                  >
                    <Box py={{ base: 4, md: 0 }}>{item.bodyText}</Box>
                  </AccordionPanel>
                </Box>
              )}
            </AccordionItem>
          )
        })}
    </Box>
  )
}

export default AccordionItems
