export default {
  enz400: "'Untitled Sans', Helvetica, Arial, sans-serif",
  enz500: "'Untitled Sans Medium', Helvetica, Arial, sans-serif",
  enz700: "'Untitled Sans Bold', Helvetica, Arial, sans-serif",
  enz900: "'Untitled Sans Black', Helvetica, Arial, sans-serif",
  a23d: "'A23D Solid', Helvetica,Arial, sans-serif",
  notosans: 'NotoSans Regular, Helvetica,Arial, sans-serif',
  mono: "Menlo, Monaco, Consolas, 'Courier New', monospace"
}

// how to protect what GridItem is
