import { GridItem, Skeleton } from '@chakra-ui/react'
import { ContainerWrapper, GridLayout, H2, LargeText } from 'ui'
import BulletItems, { BulletItem } from './BulletItems'

export interface BulletPointsProps {
  title?: string | null
  introText?: string | null
  bulletItemsToRenderArray?: BulletItem[]
  isLoaded: boolean
}
const BulletPoints = ({
  title,
  introText,
  bulletItemsToRenderArray,
  isLoaded
}: BulletPointsProps) => {
  return (
    <ContainerWrapper>
      <Skeleton isLoaded={isLoaded}>
        <GridLayout templateColumns={{ lg: 'repeat(2, 1fr)' }} mb={12}>
          {title && (
            <GridItem>
              <H2 mb={{ base: 3, lg: 0 }}>{title}</H2>
            </GridItem>
          )}
          {introText && (
            <GridItem>
              <LargeText mb={0}>{introText}</LargeText>
            </GridItem>
          )}
        </GridLayout>
        <BulletItems bulletItemsToRenderArray={bulletItemsToRenderArray} />
      </Skeleton>
    </ContainerWrapper>
  )
}

export default BulletPoints
