import { OnSelectParams } from '@algolia/autocomplete-core'
import { isModifierEvent } from '.'
import { FacetQueryResult } from '../Plugins/createFacetQuery'
import {
  BaseResult,
  ScholarshipSearchQueryResult,
  SearchQueryResult,
  SiteSearchQueryResult
} from '../types'

type PluginOnSelect =
  | OnSelectParams<BaseResult>
  | OnSelectParams<SearchQueryResult>
  | OnSelectParams<FacetQueryResult>
  | OnSelectParams<ScholarshipSearchQueryResult>
  | OnSelectParams<SiteSearchQueryResult>
export default function pluginOnSelect({
  item,
  setQuery,
  setContext,
  setIsOpen,
  refresh,
  event
}: PluginOnSelect) {
  if (isModifierEvent(event)) {
    return
  }

  const query = item?.label ?? ''
  setContext({ itemSelected: true })
  setQuery(query)
  setIsOpen(false)
  refresh()
}
