import Trans from 'next-translate/Trans'

import { Box, BoxProps, Link } from '@chakra-ui/react'

import { SmallText } from 'ui'

interface FormAgreementsProps extends BoxProps {
  includeAgreePolicy?: boolean
}
export const TransAgreements = (props) => (
  <Box>
    <Trans
      i18nKey="forms:form_agreements"
      components={[
        <SmallText key={0} lineHeight={6} color={props.color} />,
        <Link key={1} href="https://policies.google.com/privacy" isExternal />,
        <Link key={2} href="https://policies.google.com/terms" isExternal />,
        <SmallText key={3} lineHeight={6} color={props.color} />,
        <Link key={4} href="/privacy-policy" />,
      ]}
    />
  </Box>
)

export const TransFormINZMatchingPermissionOptIn = (props) => (
  <Trans
    i18nKey="forms:inz_matching_optin"
    components={[
      <SmallText key={0} lineHeight={6} mb={0} color={props.color} />,
      <Link key={1} href="/privacy-policy#immigration-new-zealand-data-agreement" />,
      <Link key={2} href="/privacy-policy" />,
    ]}
  />
)
