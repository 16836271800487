import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  LinkProps,
  ListItem,
  Stack,
  UnorderedList
} from '@chakra-ui/react'
import { ExternalLinkIcon } from 'icons'
import React from 'react'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { NavLink } from 'ui'
import { useMediaQuery } from 'utils/src/helpers'
import { Link } from '../Link'
import FooterSectionTitle from './FooterSectionTitle'
import { FooterSectionProps } from './types'

const FooterSectionLinks = ({
  title = '',
  links,
  fontSize = '16px', // the font size on mobile for section heading
  lineHeight = '1.5' // custom line height of links
}: FooterSectionProps & LinkProps) => {
  const isDesktop = useMediaQuery('(min-width: 992px)')

  if (!links.length) {
    return null
  }

  return (
    <Box
      sx={{
        // Actually rtl
        'html[lang=ar] &': {
          direction: 'ltr'
        }
      }}
    >
      {isDesktop ? (
        <>
          <FooterSectionTitle aria-label={title}>{title}</FooterSectionTitle>
          <UnorderedList
            listStyleType='none'
            marginLeft={0}
            spacing={{ base: 1, lg: 4 }}
          >
            {links.map((link: NavLink) => (
              <ListItem key={link.id} lineHeight={lineHeight}>
                <Link
                  color='white'
                  textDecor='none'
                  fontSize='sm'
                  fontFamily='enz400'
                  lineHeight={lineHeight}
                  href={link.slug}
                  title={link.title}
                  aria-label={link.title}
                  isExternal={link.isExternalLink}
                  onClick={() =>
                    createUtagLinkClickEvent({
                      linkLabel: link.title,
                      linkCategory: TEALIUM_LINK_CATEGORY.FOOTER
                    })
                  }
                >
                  {link.title}
                  {link.isExternalLink === true && (
                    <Box
                      ml={1}
                      boxSize={6}
                      sx={{
                        'html[lang=ar] &': {
                          mr: 1,
                          ml: 'unset'
                        }
                      }}
                    >
                      <ExternalLinkIcon />
                    </Box>
                  )}
                </Link>
              </ListItem>
            ))}
          </UnorderedList>
        </>
      ) : (
        <Accordion border='none' allowMultiple color='white'>
          <AccordionItem border='none' bgColor='deepblue.600' borderRadius='sm'>
            <AccordionButton
              h={12}
              _expanded={{ bg: 'rgba(255, 255, 255, 0.1)', mb: 2 }}
            >
              <Box
                flex='1'
                textAlign='left'
                pt={4}
                pb={4}
                fontSize={fontSize}
                fontFamily='enz400'
                fontWeight='400'
                opacity='1'
              >
                {title}
              </Box>
              <AccordionIcon boxSize={6} />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Stack spacing={4} py={3}>
                {links.map((link: NavLink) => (
                  <Link
                    key={link.id}
                    color='white'
                    textDecor='none'
                    fontSize='sm'
                    fontFamily='enz400'
                    lineHeight={lineHeight}
                    href={link.slug}
                    title={link.title}
                    aria-label={link.title}
                    isExternal={link.isExternalLink}
                    onClick={() =>
                      createUtagLinkClickEvent({
                        linkLabel: link.title,
                        linkCategory: TEALIUM_LINK_CATEGORY.SITE_NAV
                      })
                    }
                  >
                    {link.title}
                    {link.isExternalLink === true && (
                      <Box
                        ml={1}
                        boxSize={6}
                        sx={{
                          'html[lang=ar] &': {
                            mr: 1,
                            ml: 'unset'
                          }
                        }}
                      >
                        <ExternalLinkIcon />
                      </Box>
                    )}
                  </Link>
                ))}
              </Stack>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </Box>
  )
}

export default FooterSectionLinks
