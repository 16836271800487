import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box
} from '@chakra-ui/react'
import React, { ReactElement } from 'react'
import NavItem, { NavLink } from '../NavItem'

export interface NavCollapsibleProps {
  links: Array<NavLink>
  callback: (id?: string) => void
}

function buildOptions(
  children: Array<NavLink>,
  cb: (id?: string) => void
): ReactElement | null {
  if (!children || children.length === 0) return null
  return (
    <>
      {children.map((item) => {
        return (
          <NavItem key={item.id} link={item} callback={() => cb(item.id)} />
        )
      })}
    </>
  )
}

export const NavCollapsible: React.FC<NavCollapsibleProps> = ({
  links,
  callback
}) => (
  <Accordion allowMultiple p={5} pt={5}>
    {links.map((link, index) => {
      return link.children && link.children.length > 0 ? (
        <AccordionItem
          key={index}
          mb={4}
          border='unset'
          _last={{ mb: { xs: '100px', sm: '0px' } }}
        >
          <AccordionButton
            h={12}
            bg='rgba(4, 15, 32, 0.5)'
            fontFamily='enz500'
            borderRadius={1}
            _expanded={{ bg: 'rgba(255, 255, 255, 0.1)', mb: '6px' }}
          >
            <Box flex='1' textAlign='left' pt={4} pb={4} opacity='1'>
              {link?.title}
            </Box>
            <AccordionIcon
              mr={1}
              sx={{
                'html[lang=ar] &': {
                  /* GOTCHA!
                   * This is acutally align 'left'
                   * stylis RTL plugin changes this to 'left' for us */
                  marginLeft: '1',
                  marginRight: '0'
                }
              }}
            />
          </AccordionButton>
          <AccordionPanel
            bg='deepblue.600'
            borderRadius={1}
            sx={{
              'html[lang=ar] &': {
                // actually rtl
                direction: 'ltr'
              }
            }}
          >
            {buildOptions(link.children, callback)}
          </AccordionPanel>
        </AccordionItem>
      ) : (
        <Box pl={4} key={index}>
          <NavItem
            link={link}
            callback={() => {
              callback(link.id)
            }}
          />
        </Box>
      )
    })}
  </Accordion>
)
