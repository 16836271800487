import { Grid, GridItem } from '@chakra-ui/react'
import { ParentNaumaiPagePath_Swnz_NaumaiPageStandardFragment } from 'content-service'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { createHref } from 'utils'
import { ContainerWrapper, GridLayout } from '../../layout'
import { H2 } from '../../typography'
import MapContainerLink from './MapContainerLink'
import MapContainerMap from './MapContainerMap'

const hasWindow = typeof window !== 'undefined'

export const toTitleCase = (str: string): string => {
  const splitStr = str.split(/ |-/)

  for (let i = 0; i < splitStr.length; i++) {
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].slice(1)
  }

  if (str.includes('-')) return splitStr.join('-')

  return splitStr.join(' ')
}

const scaleRegion = (region: string, scaleUp: boolean) => {
  const selectedRegion =
    hasWindow && document.querySelector<SVGClipPathElement>(`#${region}`)
  if (!selectedRegion) return

  if (scaleUp) {
    selectedRegion.style.transform = 'scale(2)'
  } else {
    selectedRegion.style.transform = 'scale(1)'
  }
}

export interface MapRegion {
  label: string
  name: string
  parentPagePath?:
    | ParentNaumaiPagePath_Swnz_NaumaiPageStandardFragment
    | undefined
}

export interface MapContainerProps {
  regions: MapRegion[]
}

const MapContainer = ({ regions }: MapContainerProps) => (
  <ContainerWrapper bgColor='orange.10'>
    <GridLayout>
      <GridItem
        display={{ base: 'none', lg: 'block' }}
        colSpan={{ base: 0, lg: 5 }}
      >
        <MapContainerMap regions={regions} />
      </GridItem>
      <GridItem colSpan={{ base: 4, md: 8, lg: 6 }} colStart={{ lg: 7 }}>
        <H2 mb={{ base: 6, lg: 10 }}>Explore a region</H2>
        {regions && regions?.length > 0 ? (
          <Grid
            gridTemplateColumns='repeat(2, 1fr)'
            gridColumnGap={{ base: '16px', lg: '24px' }}
          >
            {regions.map(({ label, name, parentPagePath }, i) => {
              const midPoint = Math.floor(regions.length / 2)

              const regionUrl = `${
                createHref({ internalLink: parentPagePath }) || ''
              }/${name}`

              return (
                <GridItem
                  onMouseEnter={() => scaleRegion(name, true)}
                  onMouseLeave={() => scaleRegion(name, false)}
                  key={name}
                  gridRowStart={i > midPoint ? i - midPoint : 'initial'}
                  gridColumn={i > midPoint ? '2/3' : '1/2'}
                >
                  <MapContainerLink
                    href={regionUrl}
                    label={label}
                    onClick={() => {
                      createUtagLinkClickEvent({
                        linkLabel: name,
                        linkCategory: TEALIUM_LINK_CATEGORY.CONTENT,
                        destinationUrl: regionUrl
                      })
                    }}
                  />
                </GridItem>
              )
            })}
          </Grid>
        ) : null}
      </GridItem>
    </GridLayout>
  </ContainerWrapper>
)

export default MapContainer
