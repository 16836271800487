import { Box, Fade } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { Headline } from 'ui'
import { ZINDEX } from 'utils'

interface HomepageHeroHeadingProps {
  heroHeading: string | null | undefined
  animateIAmNew: boolean
}

export default function HomepageHeroHeading({
  heroHeading,
  animateIAmNew,
}: HomepageHeroHeadingProps) {
  const { t } = useTranslation('homepage')
  const declaration = t('hero_declaration')

  return (
    <Fade
      in={true}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ enter: { duration: 1, delay: 1.5 } }}
    >
      <Box as="span" zIndex={ZINDEX.NEW_TEXT} display="block" position="relative">
        <Headline
          as="h1"
          color="white"
          lineHeight="0.85"
          zIndex={ZINDEX.NEW_TEXT}
          marginBottom={0}
          fontSize={{
            base: '10rem',
            lg: '22.5rem',
          }}
          sx={{
            'html:not([lang=en]) &': {
              fontFamily: 'a23d',
              fontWeight: '500',
            },
          }}
          _before={{
            content: `'${declaration} '`,
            position: 'absolute',
            zIndex: ZINDEX.NEW_TEXT,
            opacity: animateIAmNew ? 1 : 0,
            transition: 'opacity 1s ease-in-out',
            left: { base: '0.5rem', md: '-7rem', lg: '2.25rem', xl: '-12rem' },
            top: { base: '-3.5rem', md: '2.5rem', lg: '-5.5rem', xl: '0.8em' },
            fontSize: {
              base: '64px',
              lg: '112px',
            },
          }}
        >
          {heroHeading}
        </Headline>
      </Box>
    </Fade>
  )
}
