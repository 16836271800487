import {
  BulletPoints_Swnz_BulletPointContainerFragment,
  BulletPoints_Swnz_BulletPointContainerFragmentDoc
} from 'content-service'
import useFragment from 'utils/src/hooks/useFragment'
import BulletPoints from '../../components/content-components/BulletPoints'
import { Connector } from '../types'
import { createBulletItemsToRenderData } from './helpers'

export type BulletPointsConnectProps = Connector

const BulletPointsConnect = ({ id, typename }: BulletPointsConnectProps) => {
  const bulletPoints =
    useFragment<BulletPoints_Swnz_BulletPointContainerFragment>({
      id,
      typename,
      fragment: BulletPoints_Swnz_BulletPointContainerFragmentDoc,
      fragmentName: 'bulletPoints_SWNZ_BulletPointContainer'
    })

  if (!bulletPoints) {
    return null
  }

  const {
    title = '',
    introText = '',
    bulletPointsCollection
  } = bulletPoints || {}
  const { items = [] } = bulletPointsCollection || {}
  const bulletItemsToRenderArray = createBulletItemsToRenderData(items)

  return (
    <BulletPoints
      isLoaded={Boolean(createBulletItemsToRenderData.length)}
      title={title}
      introText={introText}
      bulletItemsToRenderArray={bulletItemsToRenderArray}
    />
  )
}

export default BulletPointsConnect
