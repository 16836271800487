import { Box, BoxProps, Container, useDisclosure } from '@chakra-ui/react'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { CallToAction, CtaButton, VideoButton } from 'ui'
import { colourMap } from 'utils/src/helpers'
import { VideoModal } from '../../modal'
import { Headline } from '../../typography'

type Video = {
  url: string
  buttonLabel: string
}

interface FullBleedHeroProps {
  heading: string
  backgroundColour: string
  heroBackgroundVideoUrl?: string
  heroBackgroundVideoTitle?: string
  heroBackgroundVideoDescription?: string
  primaryCta?: CallToAction
  video?: Video
  image: ReactElement | null
  leadCaptureFormId?: string
}

const FullBleedHero = ({
  backgroundColour = 'surface-blue',
  heading,
  heroBackgroundVideoUrl,
  heroBackgroundVideoTitle,
  heroBackgroundVideoDescription,
  primaryCta,
  image,
  video,
  leadCaptureFormId
}: FullBleedHeroProps): ReactElement => {
  const headingContainerRef = useRef<HTMLDivElement>(null)
  const [isPlaying, setIsPlaying] = useState<boolean>(false)
  const [containerPaddingBottom, setContainerPaddingBottom] =
    useState<number>(0)
  const { isOpen, onClose, onOpen } = useDisclosure()
  const sanitisedBackgroundColour = colourMap(backgroundColour)

  const renderVideoButton = (
    videoUrl: string | undefined,
    videoButtonLabel: string | undefined,
    isSmallScreen: boolean
  ): ReactElement | null => {
    const smallScreenStyleProps = {
      display: { base: 'block', md: 'none' },
      top: -6,
      left: 8
    }

    const largeScreenStyleProps = {
      bottom: 16,
      right: 16,
      display: { base: 'none', md: 'block' }
    }

    if (!videoUrl) {
      return null
    }

    return (
      <Box
        position='absolute'
        {...(isSmallScreen ? smallScreenStyleProps : largeScreenStyleProps)}
      >
        <VideoButton onClick={onOpen} compact={isSmallScreen}>
          <Box
            as='span'
            display={{ base: 'none', lg: 'block' }}
            maxW='230px'
            lineHeight='1.2'
            textAlign='left'
            fontSize='sm'
            noOfLines={1}
            wordBreak='break-all'
          >
            {videoButtonLabel}
          </Box>
        </VideoButton>
      </Box>
    )
  }

  const videoModal = video?.buttonLabel ? (
    <VideoModal url={video.url} isOpen={isOpen} onClose={onClose} />
  ) : null

  useEffect(() => {
    const handleResize = () =>
      setContainerPaddingBottom(headingContainerRef.current?.clientHeight || 0)

    setContainerPaddingBottom(headingContainerRef.current?.clientHeight || 0)

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const heroImageWrapperStyleProps: BoxProps = {
    overflow: 'hidden',
    position: 'absolute',
    width: 'full',
    height: 'full'
  }

  return (
    <Box position='relative' marginBottom={{ base: 0, md: '20px' }}>
      <Box
        position='relative'
        overflow='hidden'
        height={{ base: '400px', md: '600px', lg: '70vh' }}
      >
        {/* Hero image when no looping video supplied  */}
        {!heroBackgroundVideoUrl && image && (
          <Box {...heroImageWrapperStyleProps}>{image}</Box>
        )}

        {/* Fallback image when video is loading or invalid  */}
        {heroBackgroundVideoUrl && !isPlaying && (
          <Box {...heroImageWrapperStyleProps}>{image}</Box>
        )}

        {/* Looping video */}
        {heroBackgroundVideoUrl && (
          <Box
            as='video'
            playsInline={true}
            autoPlay={true}
            muted={true}
            loop={true}
            position='relative'
            height='full'
            width='full'
            objectFit='cover'
            onPlay={() => setIsPlaying(!isPlaying)}
          >
            <Box
              as='source'
              aria-label={heroBackgroundVideoDescription}
              src={heroBackgroundVideoUrl}
              title={heroBackgroundVideoTitle}
            />
          </Box>
        )}

        {renderVideoButton(video?.url, video?.buttonLabel, false)}
      </Box>
      <Container
        position='relative'
        transform='translateY(-60%)'
        marginBottom={-containerPaddingBottom * 0.6}
      >
        {renderVideoButton(video?.url, video?.buttonLabel, true)}
        <Box
          ref={headingContainerRef}
          color='white'
          display='inline-block'
          position='relative'
          backgroundColor={sanitisedBackgroundColour}
          paddingY={{ base: 8, md: 16 }}
          paddingRight={{ base: 6, md: 16 }}
          maxWidth='550px'
          _before={{
            content: '""',
            position: 'absolute',
            width: '150%',
            height: '100%',
            background: sanitisedBackgroundColour,
            left: '-150%',
            top: 0,
            right: 0
          }}
        >
          <Headline as='h1' position='relative' zIndex={1} marginBottom={0}>
            {heading}
          </Headline>
          {primaryCta?.label && (
            <CtaButton
              marginTop={6}
              colorScheme='white'
              href={primaryCta.href}
              zIndex={1}
              modalId={primaryCta.modalId}
              leadCaptureFormId={leadCaptureFormId}
            >
              {primaryCta.label}
            </CtaButton>
          )}
        </Box>
      </Container>

      {videoModal}
    </Box>
  )
}

export type { FullBleedHeroProps }
export default FullBleedHero
