import { TaxonomyLocation } from 'utils/src/helpers/taxonomy'
import {
  AutocompletePlugin,
  AutocompleteSource
} from '@algolia/autocomplete-js'
import { BaseResult } from '../types'

interface PathwaysCountriesPluginOptions {
  /**
   * List of countries from the Taxonomy API
   */
  countries: Array<TaxonomyLocation>
  /**
   * A function to transform the provided source.
   *
   * @link https://www.algolia.com/doc/ui-libraries/autocomplete/api-reference/autocomplete-plugin-recent-searches/createRecentSearchesPlugin/#param-transformsource
   */
  transformSource?(params: {
    source: AutocompleteSource<BaseResult>
  }): AutocompleteSource<BaseResult>
  onSelect?(params: any): void
}

export function pathwaysCountriesPlugin({
  countries,
  onSelect: onSelectEvent,
  transformSource = ({ source }) => source
}: PathwaysCountriesPluginOptions): AutocompletePlugin<BaseResult, BaseResult> {
  return {
    subscribe({ onSelect }) {
      onSelect(({ item, state, event }) => {
        // @ts-ignore
        onSelectEvent({
          item,
          state,
          event
        })
      })
    },
    getSources({ query }) {
      // If no query return an empty list
      if (!query) {
        return []
      }

      return [
        transformSource({
          source: {
            sourceId: 'pathwaysCountries',
            // @ts-ignore
            getItems({ query }) {
              // Filter our countries list by the query
              return countries.filter((country) => {
                return country.label
                  .toLowerCase()
                  .startsWith(query.toLowerCase())
              })
            },
            getItemInputValue({ item }) {
              return item?.label ?? ''
            },
            getItemUrl({ item }) {
              return '#'
            },
            templates: {
              item({ item }) {
                return `${item.label}`
              },
              header() {
                return 'Searches'
              },
              noResults() {
                return 'No results.'
              }
            }
          }
        })
      ]
    }
  }
}
