import { TaxonomyForm, TaxonomyObject, TaxonomyRegion } from './taxonomy'

export const regionMap: { [country: string]: string } = Object.freeze({
  'new-zealand': 'nz-region',
  china: 'china-region',
  india: 'india-region'
})

export const getRegionOptions = (
  currentRegion: string | null,
  taxonomy: TaxonomyForm
) => {
  const regions: TaxonomyRegion[] = taxonomy?.regions ?? []
  let places: TaxonomyObject[] = []

  if (currentRegion?.length) {
    for (const item of regions) {
      if (item.key === currentRegion) places = item.places
    }
  }

  return places
}

export const getFormTextColour = (theme?: string, inModal = false) => {
  if (inModal) return 'deepblue.500'

  switch (theme) {
    case 'deep-water-blue':
      return 'white'
    case 'deep-blue-5':
      return 'deepblue.500'
    default:
      return 'deepblue.500'
  }
}

export const setFormBackgroundColor = (theme?: string, inModal = false) => {
  if (inModal) return ''

  switch (theme) {
    case 'deep-water-blue':
      return 'deepblue.500'
    case 'deep-blue-5':
      return 'white'
    default:
      return ''
  }
}
