import { Box } from '@chakra-ui/react'
import { PlayIcon } from 'icons'
import Button, { ButtonProps } from '../Button'

interface VideoButtonProps extends ButtonProps {
  compact?: boolean
}

const VideoButton = ({ compact, children, ...props }: VideoButtonProps) => {
  if (compact) {
    return (
      <Button
        variant='unstyled'
        boxSize={{ base: '40px', md: '60px' }}
        borderRadius='full'
        display='flex'
        justifyContent='center'
        alignItems='center'
        aria-label={'Play video'}
        title={'Play video'}
        position='absolute'
        top='calc(50% - 30px)'
        left=' 50%'
        transform='translate(-50%, 0)'
        style={{ backdropFilter: 'blur(5px)' }}
        backgroundColor='rgba(grey.200, 0.25)'
        overflow='hidden'
        icon={
          <Box
            boxSize={{ base: '40px', md: '60px' }}
            fill='white'
            overflow='hidden'
            sx={{
              transition: '0.25s ease-in-out',
              '.outline': {
                background: 'white'
              }
            }}
            _hover={{
              background: 'white',
              borderRadius: '50%',
              '.triangle': {
                fill: 'deepblue.500',
                transition: '0.25s ease-in-out'
              }
            }}
          >
            <PlayIcon />
          </Box>
        }
        {...props}
      />
    )
  }

  return (
    <Button
      variant='unstyled'
      borderRadius='40px'
      display='flex'
      justifyContent='center'
      alignItems='center'
      color='white'
      style={{ backdropFilter: 'blur(5px)' }}
      padding={{ base: 0, lg: '15px 25px' }}
      py={{ base: 0, lg: '15px' }}
      px={{ base: 0, lg: '25px' }}
      iconSpacing={{ base: 0, lg: '16px' }}
      backgroundColor='rgba(255, 255, 255, 0.25)'
      rightIcon={
        <Box
          width={'60px'}
          fill='white'
          overflow='hidden'
          borderRadius='full'
          sx={{
            transition: '0.25s ease-in-out',
            '.outline': {
              background: 'white'
            }
          }}
          _hover={{
            background: 'white',
            borderRadius: '50%',
            '.triangle': {
              fill: 'deepblue.500'
            }
          }}
        >
          <PlayIcon />
        </Box>
      }
      {...props}
    >
      {children}
    </Button>
  )
}

export default VideoButton
