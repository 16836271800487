import { chakra, ImageProps as ChakraImageProps } from '@chakra-ui/react'
import NextImage, { ImageProps } from 'next/legacy/image'

type WidthHeight = {
  width?: NonNullable<ChakraImageProps['width']> | number
  height?: NonNullable<ChakraImageProps['height']> | number
}

type NextImageProps = Omit<ImageProps, 'width' | 'height'> & WidthHeight

interface ChakraNextImageProps extends NextImageProps {
  blurOnLoad?: boolean
}

const shimmer = () => `
  <svg width="100%" height="100%" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient id="g">
        <stop stop-color="#F6F7F8" offset="0%"  />
        <stop stop-color="#EDEEF1" offset="40%" />
        <stop stop-color="#F6F7F8" offset="100%" />
      </linearGradient>
    </defs>
    <rect width="100%" height="100%" fill="#F6F7F8"  />
    <rect id="r" width="100%" height="100%" fill="url(#g)" />
    <animate xlink:href="#r" attributeName="x" from="-100%" to="100%" dur="1s" repeatCount="indefinite"  />
  </svg>
`

const toBase64 = (input: string) =>
  typeof window === 'undefined'
    ? Buffer.from(input).toString('base64')
    : window.btoa(input)

const ChakraNextUnwrappedImage = chakra(NextImage, {
  shouldForwardProp: (prop) => {
    const nextImageForwardedProps = [
      'width',
      'height',
      'src',
      'alt',
      'layout',
      'objectFit',
      'loading',
      'priority',
      'quality',
      'placeholder',
      'blurDataURL',
      'key'
    ]

    return nextImageForwardedProps.includes(prop)
  }
})

const ChakraNextImage = ({
  src,
  width,
  height,
  blurOnLoad = true,
  ...restProps
}: ChakraNextImageProps) => {
  return (
    <ChakraNextUnwrappedImage
      src={src}
      width={width}
      height={height}
      {...restProps}
      {...(blurOnLoad && {
        placeholder: 'blur',
        blurDataURL: `data:image/svg+xml;base64,${toBase64(shimmer())}`
      })}
      w='full'
      h='full'
    />
  )
}

export default ChakraNextImage
export type { ChakraNextImageProps }
