const container = {
  sm: '640px',
  md: '870px',
  lg: '1024px',
  xl: '1440px'
}

const sizes = {
  container
}

export default sizes
