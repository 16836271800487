import { createSanitisedPageName } from "../helpers";
import { TEALIUM_EVENT } from "../helpers/constants";

interface FilterUpdate {
  storyFilter: string;
}

interface FilterUpdateResult {
  page_name: string;
  story_filter: string;
  tealium_event: TEALIUM_EVENT;
}

/**
 * createFilterUpdateUtagEventObject
 * Build the data for a filter_update event
 */
function createFilterUpdateUtagEventObject({
  storyFilter,
}: FilterUpdate): FilterUpdateResult {
  return {
    page_name: createSanitisedPageName(document.title),
    tealium_event: TEALIUM_EVENT.FILTER_UPDATE,
    story_filter: storyFilter,
  };
}

export default createFilterUpdateUtagEventObject;
export type { FilterUpdate, FilterUpdateResult };
