import {
  AutocompleteState,
  createAutocomplete,
  Reshape
} from '@algolia/autocomplete-core'
import { AutocompleteOptions } from '@algolia/autocomplete-js'
import {
  Box,
  BoxProps,
  LinkProps,
  ListItemProps,
  TextProps
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react'
import { SearchState } from 'react-instantsearch-core'
import { useDebouncedCallback } from 'use-debounce'
import { useMediaQuery } from 'utils/src/helpers'
import { searchStateToURL } from 'utils/src/helpers/algoliaSearch'
import { SearchTitleCtaProps } from '../SearchTitleCta'
import connectAutocomplete, {
  CurrentRefinement
} from './Connector/connectAutocomplete'
import { findResultSource } from './helpers'
import { BuildState } from './helpers/buildState'
import { FacetQueryResult } from './Plugins/createFacetQuery'
import SearchAutocompleteInput, {
  AutocompleteInputRef
} from './SearchAutocompleteInput'
import SearchAutocompleteItem from './SearchAutocompleteItem'
import SearchAutocompleteList from './SearchAutocompleteList'
import SearchAutocompleteModal from './SearchAutocompleteModal'
import SearchAutocompletePanel from './SearchAutocompletePanel'
import { BaseResult, SearchQueryResult } from './types'

export type SearchAutocompleteContext = { instantSearchState?: SearchState }
export type AutocompleteResults =
  | FacetQueryResult
  | SearchQueryResult
  | BaseResult
export type AutocompletePlugins = Pick<
  AutocompleteOptions<AutocompleteResults>,
  'plugins'
>['plugins']

export interface SearchAutocompleteProps {
  title: string
  placeholder: string
  facet: string
  resultsPath: string
  plugins: AutocompletePlugins
  reshape?: Reshape<AutocompleteResults>
  emptyQueryDropdownTitle?: string
  dropdownTitle?: string
  showLabel?: boolean
  largeInput?: boolean
  shouldRedirectOnSubmit?: boolean
  searchProviderCtaProps?: SearchTitleCtaProps
  displayInModal?: boolean
  autocompletePanelProps?: {
    titleProps: TextProps
    contentWrapperProps: BoxProps
  }
  autocompleteItem?: {
    itemProps: ListItemProps
    linkProps: Omit<LinkProps, 'as' | 'href'>
    highlightProps: TextProps
  }
}

export interface AutocompleteConnectorPropsHOC extends CurrentRefinement {
  searchState?: SearchState
  refine: Dispatch<
    SetStateAction<{
      query?: string
      facetValue?: string
    }>
  >
}

type SetInstantSearchState = Partial<Pick<BuildState, 'query' | 'facetValue'>>

const SearchAutocomplete = ({
  title,
  placeholder,
  resultsPath,
  plugins,
  reshape,
  emptyQueryDropdownTitle = 'Popular Searches',
  dropdownTitle = 'Suggested Searches',
  searchState,
  currentRefinement,
  refine,
  showLabel,
  largeInput,
  shouldRedirectOnSubmit = true,
  searchProviderCtaProps,
  displayInModal,
  autocompletePanelProps,
  autocompleteItem
}: SearchAutocompleteProps & AutocompleteConnectorPropsHOC) => {
  const router = useRouter()
  const [autocompleteState, setAutocompleteState] = React.useState<
    AutocompleteState<AutocompleteResults>
  >({
    collections: [],
    completion: null,
    context: { instantSearchState: searchState, itemSelected: false },
    isOpen: false,
    query: currentRefinement,
    activeItemId: null,
    status: 'idle' as const
  })

  const inputFormRef = useRef<AutocompleteInputRef>(null)
  const dropdownPanelRef = useRef<HTMLDivElement | null>(null)

  const setInstantSearchQuery = (
    state: AutocompleteState<AutocompleteResults>,
    setIsOpen: (isOpen: boolean) => void
  ) => {
    if (state.context.itemSelected && state.status === 'idle') {
      setContext({ itemSelected: false })
      setIsOpen(false)
    }
    onSubmit({ state, shouldRedirectOnSubmit: false })
  }

  const debouncedSetQuery = useDebouncedCallback(setInstantSearchQuery, 400)

  const onSubmit = ({
    state,
    shouldRedirectOnSubmit = true
  }: {
    state: AutocompleteState<AutocompleteResults>
    shouldRedirectOnSubmit?: boolean
  }) => {
    const submitQuery = state?.completion || state.query || ''

    const { hasFacet, hasQuery } = findResultSource<AutocompleteResults>(state)
    const instantSearchState: SetInstantSearchState = {
      ...(hasQuery ? { query: submitQuery, facetValue: undefined } : {}),
      ...(hasFacet ? { query: '', facetValue: submitQuery } : {})
    }

    // Merge instant search state
    setQuery(submitQuery)
    refine(instantSearchState)

    const url = searchStateToURL(resultsPath, instantSearchState ?? {})

    if (Boolean(url) && shouldRedirectOnSubmit) {
      router.push(url)
    }
  }

  /**
   * Please be mindful of following args in 'createAutocomplete' function:
   * 1. "debug": true --> will result in the <SearchAutocompletePanel/> persisting and not closing in response to and out-of-bounds click
   */
  const autocomplete = React.useMemo(
    () =>
      createAutocomplete<AutocompleteResults>({
        navigator: {
          navigate({ itemUrl }) {
            router.push(itemUrl)
          }
        },
        initialState: autocompleteState,
        onStateChange({ state, prevState, setIsOpen }) {
          setAutocompleteState(state)
          if (prevState.query !== state.query) {
            debouncedSetQuery(state, setIsOpen)
          }
        },
        onSubmit: ({ state }) => {
          onSubmit({ state, shouldRedirectOnSubmit })
        },
        openOnFocus: true,
        placeholder,
        plugins,
        ...(reshape ? { reshape } : {})
      }),

    // Adding router to the useMemo dependencies causes an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      currentRefinement,
      debouncedSetQuery,
      placeholder,
      plugins,
      reshape,
      refine
    ]
  )

  const { setQuery, setContext, getEnvironmentProps, getRootProps, refresh } =
    autocomplete

  useEffect(() => {
    setContext({ instantSearchState: searchState })
    refresh()
  }, [refresh, searchState, setContext])

  ///https://www.algolia.com/doc/ui-libraries/autocomplete/guides/creating-a-renderer/#mirroring-a-native-mobile-experience
  useEffect(() => {
    if (
      !(
        inputFormRef?.current?.form &&
        dropdownPanelRef.current &&
        inputFormRef?.current?.input
      )
    ) {
      return
    }

    const { onTouchStart, onTouchMove } = getEnvironmentProps({
      formElement: inputFormRef?.current?.form,
      panelElement: dropdownPanelRef.current,
      inputElement: inputFormRef?.current?.input
    })

    window.addEventListener('touchstart', onTouchStart)
    window.addEventListener('touchmove', onTouchMove)

    return () => {
      window.removeEventListener('touchstart', onTouchStart)
      window.removeEventListener('touchmove', onTouchMove)
    }
  }, [
    getEnvironmentProps,
    dropdownPanelRef,
    inputFormRef?.current?.form,
    inputFormRef?.current?.input
  ])

  const isMedium = useMediaQuery('(min-width: 768px)')

  const renderDropdownTitle = (query: string) =>
    query ? dropdownTitle : emptyQueryDropdownTitle

  const autocompleteUI = (
    <Box position='relative' height='auto'>
      <Box
        height='full'
        flexDirection={{ base: 'column', md: 'row' }}
        borderRadius='base'
        {...getRootProps({})}
      >
        <SearchAutocompleteInput
          title={title}
          autocomplete={autocomplete}
          ref={inputFormRef}
          showLabel={showLabel}
          largeInput={largeInput}
          searchTitleCta={searchProviderCtaProps}
        />
        {
          <SearchAutocompletePanel
            title={renderDropdownTitle(autocompleteState.query)}
            autocomplete={autocomplete}
            autocompleteState={autocompleteState}
            ref={dropdownPanelRef}
            titleProps={{
              ...(autocompletePanelProps?.titleProps
                ? autocompletePanelProps.titleProps
                : {})
            }}
            contentWrapperProps={{
              ...(autocompletePanelProps?.contentWrapperProps
                ? autocompletePanelProps.contentWrapperProps
                : {})
            }}
          >
            {autocompleteState?.collections.map(({ source, items }) => {
              const collectionItems = items.map((item) => (
                <SearchAutocompleteItem
                  key={`${item.__autocomplete_id}`}
                  item={item}
                  source={source}
                  autocomplete={autocomplete}
                  autocompleteState={autocompleteState}
                  itemProps={{
                    ...(autocompleteItem?.itemProps
                      ? autocompleteItem.itemProps
                      : {})
                  }}
                  linkProps={{
                    ...(autocompleteItem?.linkProps
                      ? autocompleteItem.linkProps
                      : {})
                  }}
                  highlightProps={{
                    ...(autocompleteItem?.highlightProps
                      ? autocompleteItem.highlightProps
                      : {})
                  }}
                />
              ))

              return (
                <SearchAutocompleteList
                  key={source.sourceId}
                  autocomplete={autocomplete}
                >
                  {collectionItems}
                </SearchAutocompleteList>
              )
            })}
          </SearchAutocompletePanel>
        }
      </Box>
    </Box>
  )

  return isMedium || displayInModal === false ? (
    autocompleteUI
  ) : (
    <SearchAutocompleteModal
      placeholder={placeholder}
      title={title}
      currentRefinement={currentRefinement}
      showLabel={showLabel}
      searchTitleCta={searchProviderCtaProps}
    >
      {autocompleteUI}
    </SearchAutocompleteModal>
  )
}

export default connectAutocomplete(SearchAutocomplete)
