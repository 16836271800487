import { RefObject, useEffect, useState } from 'react'

function useIntersectionObserver(
  elementRef: RefObject<Element>,
  { threshold = 0, root = null, rootMargin = '0px' }: IntersectionObserverInit
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = useState<IntersectionObserverEntry>()

  const frozen = entry?.isIntersecting

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry)
  }

  useEffect(
    () => {
      const node = elementRef?.current
      const hasIOSupport = !!window.IntersectionObserver

      if (!hasIOSupport || frozen || !node) return

      const observer = new IntersectionObserver(updateEntry, {
        threshold,
        root,
        rootMargin
      })

      observer.observe(node)

      return () => {
        observer.unobserve(node)
        observer.disconnect()
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elementRef?.current, JSON.stringify(threshold), root, rootMargin, frozen]
  )

  return entry
}

export default useIntersectionObserver
