import { StudyOptionsAPILearnerInventoryItemModel } from 'ui'

interface SearchHitAPIItem {
  matchScore: string
  status: string
  updated?: Date | undefined
}

export const getAPIItem = (
  apiSearchHitItems: StudyOptionsAPILearnerInventoryItemModel[],
  hitId: string
): SearchHitAPIItem => {
  const matchingItem = apiSearchHitItems.find((apiItem) => apiItem.itemId === hitId)
  return {
    matchScore: matchingItem && matchingItem.matchScore ? `${matchingItem.matchScore}%` : '0%',
    status: matchingItem && matchingItem.status ? matchingItem.status : '',
    updated: matchingItem && matchingItem.updated && matchingItem.updated,
  }
}
