import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  Select,
  SelectProps
} from '@chakra-ui/react'
import { ErrorMessage, useField } from 'formik'
import { ChevronIcon } from 'icons'
import React, { ReactNode } from 'react'

export type SelectControlProps = FormControlProps & SelectProps

export interface Props extends SelectControlProps {
  name: string
  label?: string
  helpText?: string
  children: ReactNode
}

const SelectControl = ({ label, helpText, children, ...props }: Props) => {
  const [field, meta] = useField(props.name)
  const { value } = field
  const [isFocused, setFocused] = React.useState(false)
  const onFocus = () => setFocused(!isFocused)
  const onBlur = () => setFocused(false)
  const errorTouched = meta.error && meta.touched
  const addErrorClass = errorTouched ? 'error ' : ''
  const toggleClass = isFocused || value ? addErrorClass + 'active' : ''

  return (
    <FormControl
      id={props.name}
      isInvalid={errorTouched ? true : false}
      isRequired={props.isRequired}
      position='relative'
      lineHeight='none'
      overflow='hidden'
      _hover={{
        'label:not(.active)': {
          color: 'deepblue.500'
        }
      }}
      {...field}
    >
      <InputGroup
        sx={{
          'html[lang=ar] &': {
            '.chakra-select__icon-wrapper': {
              left: '0.4rem',
              right: 'unset'
            }
          }
        }}
      >
        <Select
          height='3.75rem'
          color='deepblue.500'
          onFocus={onFocus}
          onBlur={onBlur}
          className={toggleClass}
          icon={
            <Box transform='rotate(90deg)' fill='deepWaterBlue' fontSize='30px'>
              <ChevronIcon />
            </Box>
          }
          {...props}
          defaultValue={value}
          sx={{
            '&.active': {
              color: 'deepblue.500',
              pt: '1rem',
              '&.error': {
                color: 'red.400'
              }
            }
          }}
        >
          {children}
        </Select>
      </InputGroup>

      <FormLabel
        color='deepblue.300'
        position='absolute'
        top='22px'
        left='1rem'
        m={0}
        transition='all 0.3s'
        className={toggleClass}
        sx={{
          pointerEvents: 'none',
          '&.active': {
            top: '12px',
            left: '18px',
            fontSize: '0.75rem',
            color: 'teal.500',
            '&.error': {
              color: 'red.400'
            }
          },
          'html[lang=ar] &': {
            /* GOTCHA!
             * This is acutally align 'left'
             * stylis RTL plugin changes this to 'left' for us */
            right: '1rem',
            left: 0
          }
        }}
      >
        {label}
      </FormLabel>
      <FormErrorMessage mb={2} pl={4}>
        <ErrorMessage name={props.name} />
      </FormErrorMessage>
      {!value && !errorTouched && helpText && (
        <FormHelperText
          as='span'
          fontSize='sm'
          mt={1}
          pl={4}
          display='inline-block'
        >
          {helpText}
        </FormHelperText>
      )}
    </FormControl>
  )
}
export default SelectControl
