import { CheckboxGroup, Stack } from '@chakra-ui/react'
import RefinementCheckbox from '../FacetRefinement/RefinementCheckbox'
import connectDependentRefinementList, {
  DependentRefinementListExposed,
  DependentRefinementListProvided
} from './Connector/connectDependentRefinementList'

const DEFAULT_FACET_LIMIT = 100

const Refinement = ({ refine, items }: DependentRefinementListProvided) => {
  return (
    <CheckboxGroup>
      <Stack spacing={1}>
        {items.map((item) => {
          return (
            <RefinementCheckbox
              label={item.label}
              key={item.label}
              count={item.count}
              isChecked={item.isRefined}
              onChange={(event) => {
                event.preventDefault()
                refine(item.value)
              }}
            />
          )
        })}
      </Stack>
    </CheckboxGroup>
  )
}

const ConnectedRefinement = connectDependentRefinementList(Refinement)
const DependentRefinementList = ({
  limit = DEFAULT_FACET_LIMIT,
  ...props
}: DependentRefinementListExposed) => (
  <ConnectedRefinement limit={limit} {...props} />
)
export default DependentRefinementList
