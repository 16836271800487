import { FinderFieldMaps, SearchStateFilter } from 'utils/src/helpers/algoliaSearch'

const courseFinderResultsMap: FinderFieldMaps = [
  {
    attribute: 'levelOfStudy.name',
    filterType: SearchStateFilter.REFINEMENT_LIST,
  },
  {
    attribute: 'deliveryModes.name',
    filterType: SearchStateFilter.REFINEMENT_LIST,
  },
  {
    attribute: 'countries.name',
    filterType: SearchStateFilter.REFINEMENT_LIST,
  },
  {
    attribute: 'regions.name',
    filterType: SearchStateFilter.REFINEMENT_LIST,
  },
  {
    attribute: 'providerName',
    filterType: SearchStateFilter.MENU,
  },
  {
    attribute: 'providerType.name',
    filterType: SearchStateFilter.REFINEMENT_LIST,
  },
  {
    attribute: 'providerSubtype.name',
    filterType: SearchStateFilter.REFINEMENT_LIST,
  },
  {
    attribute: 'areasOfStudy.name',
    filterType: SearchStateFilter.REFINEMENT_LIST,
  },
]

const providerFinderResultsMap: FinderFieldMaps = [
  {
    attribute: 'type.name',
    filterType: SearchStateFilter.REFINEMENT_LIST,
  },
  {
    attribute: 'subtypeList.name',
    filterType: SearchStateFilter.REFINEMENT_LIST,
  },
  {
    attribute: 'countries.name',
    filterType: SearchStateFilter.REFINEMENT_LIST,
  },
  {
    attribute: 'regions.name',
    filterType: SearchStateFilter.REFINEMENT_LIST,
  },
  {
    attribute: 'cities',
    filterType: SearchStateFilter.REFINEMENT_LIST,
  },
  {
    attribute: 'name',
    filterType: SearchStateFilter.MENU,
  },
]

const scholarshipFinderResultsMap: FinderFieldMaps = [
  {
    attribute: 'levelsOfStudy.name',
    filterType: SearchStateFilter.REFINEMENT_LIST,
  },
  {
    attribute: 'nationalities',
    filterType: SearchStateFilter.REFINEMENT_LIST,
  },
  {
    attribute: 'providerSubtypeList.name',
    filterType: SearchStateFilter.REFINEMENT_LIST,
  },
  {
    attribute: 'subjectsOfStudy.name',
    filterType: SearchStateFilter.REFINEMENT_LIST,
  },
  {
    attribute: 'providerName',
    filterType: SearchStateFilter.MENU,
  },
]

export const ALGOLIA_FILTER_MAPS = {
  COURSE_FINDER_RESULTS: courseFinderResultsMap,
  PROVIDER_FINDER_RESULTS: providerFinderResultsMap,
  SCHOLARSHIP_FINDER_RESULTS: scholarshipFinderResultsMap,
}

export default ALGOLIA_FILTER_MAPS
