import {
  FormLeadCapture_Swnz_FormLeadCaptureFragment,
  FormLeadCapture_Swnz_FormLeadCaptureFragmentDoc,
} from 'content-service'
import { EducationFairForm } from 'swnz/src/components/forms'
import { useTranslation } from 'swnz/src/hooks/useTranslation'
import { Connector, FormElementWrapper } from 'ui'
import useFragment from 'utils/src/hooks/useFragment'

export interface EducationFairFormConnectProps extends Connector {
  inModal?: boolean
}

const EducationFairFormConnect = ({ id, typename, inModal }: EducationFairFormConnectProps) => {
  const { t } = useTranslation('forms')

  const data = useFragment<FormLeadCapture_Swnz_FormLeadCaptureFragment>({
    id,
    typename,
    fragment: FormLeadCapture_Swnz_FormLeadCaptureFragmentDoc,
    fragmentName: 'formLeadCapture_Swnz_FormLeadCapture',
  })

  if (!data) {
    return null
  }

  const { heading, subHeading, identifier, sourceId, theme } = data || {}

  return (
    <FormElementWrapper
      isEducationFairForm
      inModal={inModal}
      theme={theme || ''}
      successMessage={{ heading: t('success_heading'), bodyText: '' }}
      errorMessage={{ heading: t('error_heading'), bodyText: t('error_body') }}
      heading={{
        initial: heading || '',
        onSuccess: '',
      }}
      bodyText={{
        initial: subHeading || '',
        onSuccess: '',
      }}
    >
      {({ handleFormSuccess, handleFormError }) => (
        <EducationFairForm
          tealium={{
            source: sourceId || '',
            formType: 'form_module',
            formName: identifier || '',
          }}
          inModal={inModal}
          contentful={data}
          onSuccessCallback={handleFormSuccess}
          onErrorCallback={handleFormError}
        />
      )}
    </FormElementWrapper>
  )
}

export default EducationFairFormConnect
