import {
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { MinusIcon } from 'icons'
import { ReactElement } from 'react'
import {
  connectRefinementList,
  RefinementListExposed,
  RefinementListProvided
} from 'react-instantsearch-core'
import { H4 } from '../../typography'

interface FilterAccordionItem {
  title: string
  children: ReactElement
}
const DEFAULT_FACET_LIMIT = 500
const FilterAccordion = ({
  items,
  title,
  children
}: FilterAccordionItem & Pick<RefinementListProvided, 'items'>) => {
  if (!items?.length) {
    return null
  }

  const refinedItemCount = items.filter((item) => item.isRefined)
  const AnimatedPlus = motion(MinusIcon)
  return (
    <AccordionItem borderColor='deepblue.200'>
      {({ isExpanded }) => (
        <>
          <AccordionButton
            py={4}
            px={0}
            _hover={{
              bgColor: 'none',
              color: 'teal.500',
              transitionProperty: 'color',
              transitionTimingFunction: 'ease-out',
              transitionDuration: 'slow',
              '.chakra-icon': {
                transitionProperty: 'color',
                transitionTimingFunction: 'ease-out',
                transitionDuration: 'slow',
                color: 'teal.500'
              }
            }}
          >
            <H4
              letterSpacing='unset'
              fontFamily='enz700'
              fontSize='md'
              lineHeight={5}
              mb={0}
              flex='1'
              textAlign='left'
              sx={{
                'html[lang=ar] &': {
                  textAlign: 'right'
                }
              }}
            >{`${title}  (${refinedItemCount?.length ?? 0})`}</H4>
            <Box
              borderColor='deepblue.500'
              position='relative'
              boxSize={{ base: 4 }}
            >
              <MinusIcon
                color='deepWaterBlue'
                boxSize={{ base: 4 }}
                position='absolute'
                top={0}
                left={0}
              />
              <AnimatedPlus
                boxSize={{ base: 4 }}
                position='absolute'
                color='deepWaterBlue'
                top={0}
                left={0}
                transition={{ duration: 0.35, type: 'spring' }}
                animate={{ rotate: isExpanded ? 0 : 90 }}
              />
            </Box>
          </AccordionButton>
          <AccordionPanel pt='unset' pb={6} px={0}>
            {children}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}

const ConnectedRefinement = connectRefinementList(FilterAccordion)
const FilterAccordionItem = ({
  limit = DEFAULT_FACET_LIMIT,
  ...props
}: FilterAccordionItem & RefinementListExposed) => (
  <ConnectedRefinement limit={limit} {...props} />
)
export default FilterAccordionItem
