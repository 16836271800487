import { CONTENT_SERVICE } from 'src/constants'
import {
  Header as CommonHeader,
  NavButton,
  NavDrawer,
  NavigationProvider,
  NavWrapper,
  SignInSignOutButton,
  useHeaderDrawer,
} from 'ui'

function RightHandContent({ preview }) {
  const { handleProfileNavToggle, profileNav, handleCloseNav, size, isOpen, handleOpenMainNav } =
    useHeaderDrawer()

  return (
    <>
      <SignInSignOutButton
        handleProfileNavToggle={handleProfileNavToggle}
        profileNav={profileNav}
        handleCloseNav={handleCloseNav}
        size={size}
      />
      <NavButton
        isOpen={isOpen}
        handleCloseNav={handleCloseNav}
        handleOpenMainNav={handleOpenMainNav}
      />
      <NavDrawer
        handleCloseNav={handleCloseNav}
        isOpen={isOpen}
        size={size}
        preview={preview}
        codeId={CONTENT_SERVICE.HEADER_CODE_ID}
      />
    </>
  )
}

function Header({ preview }: { preview: boolean }) {
  return (
    <CommonHeader
      rightHandContent={
        <NavWrapper>
          <NavigationProvider>
            <RightHandContent preview={preview} />
          </NavigationProvider>
        </NavWrapper>
      }
    />
  )
}

export default Header
