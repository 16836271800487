import { AspectRatio, Box, Flex, Image, Tab, Text } from '@chakra-ui/react'
import { Fragment, ReactElement } from 'react'
import { BoldText } from 'ui'

interface TabContentProps {
  backgroundImage?: ReactElement | null
  forgroundImage?: ReactElement | null
}

export const TabContent = ({ backgroundImage, forgroundImage }: TabContentProps) => {
  return (
    <Fragment>
      <AspectRatio
        ratio={72 / 31}
        minH={{ base: 310, md: 620 }}
        position="relative"
        className="tab_image_container"
      >
        <Box data-testid="background_image">
          {backgroundImage && backgroundImage}
          <Box
            id="foreground_image"
            pos="absolute"
            boxSize="full"
            top="0"
            left="0"
            right="0"
            bottom="0"
          >
            <AspectRatio
              ratio={72 / 31}
              position="relative"
              height="full"
              data-testid="portrait_image"
              zIndex={2}
            >
              {forgroundImage && forgroundImage}
            </AspectRatio>
          </Box>
        </Box>
      </AspectRatio>
    </Fragment>
  )
}

interface TabHeaderProps {
  tab: {
    tabBackgroundImage: {
      source: { url: string }
    }
    tabThumbnailImage: {
      source: {
        url: string
        description: string
      }
    }
    tabHeading: string
    tabSubHeading: string
  }
}

export const TabHeader = ({ tab }: TabHeaderProps) => {
  return (
    <Tab
      borderBottom="none"
      position="relative"
      _selected={{
        borderTop: '6px solid',
        borderTopColor: 'surfaceBlue',
      }}
      _active={{
        backgroundImage: `url(${tab?.tabBackgroundImage?.source?.url})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
      }}
      _focus={{
        zIndex: 2,
      }}
      _hover={{
        cursor: 'pointer',
      }}
      _before={{
        content: `""`,
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        background: 'rgba(7, 25, 55, 0.5)',
      }}
      backgroundImage={`url(${tab?.tabBackgroundImage?.source?.url})`}
      backgroundSize={'cover'}
      backgroundPosition={'center center'}
      color="white"
      sx={{
        zIndex: 2,
      }}
    >
      <Flex zIndex={1} alignItems="center">
        <Box
          textAlign="right"
          marginRight={4}
          display={{ base: 'none', xl: 'block' }}
          lineHeight={1.125}
        >
          <BoldText mb={0} whiteSpace={{ xl: 'nowrap' }}>
            {tab?.tabHeading}
          </BoldText>
          <Text mb={0}>{tab?.tabSubHeading}</Text>
        </Box>
        <Image
          style={{ width: '56px', height: '56px' }}
          src={tab?.tabThumbnailImage?.source?.url}
          alt={tab?.tabThumbnailImage?.source?.description || ''}
          borderRadius="full"
          objectFit={'cover'}
        />
      </Flex>
    </Tab>
  )
}
