/* eslint-disable no-useless-escape */
const getYouTubeVideoId = (sourceUrl: string): string => {
  if (sourceUrl.includes('youtube') || sourceUrl.includes('youtu.be')) {
    let videoId = ''
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
    const match = regExp.exec(sourceUrl)

    if (match && match[7].length == 11) {
      videoId = match[7]
    } else {
      console.error(`🚨 Unable to extract video ID from ${sourceUrl}`)
    }

    return videoId
  }
  return ''
}

export { getYouTubeVideoId }
