import { NextRouter, useRouter } from 'next/router'
import React, { ReactElement, ReactNode, useEffect, useState } from 'react'
import { Configure, SearchState } from 'react-instantsearch-core'
import { InstantSearch, InstantSearchProps } from 'react-instantsearch-dom'
import { createUtagInventorySearchCriteriaEvent, TrackingMap } from 'tracking'
import { useDebouncedCallback } from 'use-debounce'
import {
  createURL,
  FinderFieldMaps,
  pathToSearchState,
  searchStateToURL
} from 'utils/src/helpers/algoliaSearch'
type CurrentSearchState = SearchState | Record<string, unknown>
interface InstantSearchWrapper extends InstantSearchProps {
  children: ReactNode
  mapTealiumTrackingFields?: TrackingMap
  // If urlFilterMap is present we assume that we should be converting the url into state
  urlFilterMap?: FinderFieldMaps
  editStateOnChange?: (searchState: SearchState) => void
}

const InstantSearchWrapper = ({
  children,
  indexName,
  searchClient,
  searchState,
  resultsState,
  urlFilterMap,
  editStateOnChange,
  mapTealiumTrackingFields,
  ...restProps
}: InstantSearchWrapper): ReactElement => {
  const URL_DEBOUNCE_DELAY = 700
  const TRACKING_DEBOUNCE_DELAY = 5000
  const router = useRouter()
  const [initialStateLoadedFromURL, setInitialStateLoadedFromURL] =
    useState<boolean>(false)
  const [currentSearchState, setCurrentSearchState] =
    useState<CurrentSearchState>(searchState)

  const updateURLState = (router: NextRouter, searchState: SearchState) => {
    const href = searchStateToURL(router.pathname, searchState)

    if (router?.isReady) {
      router.push(href, href, {
        shallow: true
      })
    }
  }

  const updateTracking = (
    routerIsReady: boolean,
    mapTealiumTrackingFields: TrackingMap,
    searchState: SearchState
  ) => {
    if (routerIsReady) {
      createUtagInventorySearchCriteriaEvent({
        mapTealiumTrackingFields,
        searchState
      })
    }
  }

  const debouncedUpdateURLState = useDebouncedCallback(
    updateURLState,
    URL_DEBOUNCE_DELAY
  )
  const debouncedUpdateTracking = useDebouncedCallback(
    updateTracking,
    TRACKING_DEBOUNCE_DELAY
  )

  const onSearchStateChange = (searchState: SearchState): void => {
    if (urlFilterMap) {
      debouncedUpdateURLState(router, searchState)
    }

    if (editStateOnChange) {
      editStateOnChange(searchState)
    }

    setCurrentSearchState(searchState)
  }

  useEffect(() => {
    if (!initialStateLoadedFromURL && router.isReady) {
      setCurrentSearchState(
        pathToSearchState(router?.asPath, urlFilterMap ?? [])
      )
      setInitialStateLoadedFromURL(true)
    }
  }, [router?.asPath, router.isReady, initialStateLoadedFromURL, urlFilterMap])

  useEffect(() => {
    if (mapTealiumTrackingFields && currentSearchState && router.isReady) {
      debouncedUpdateTracking(
        router.isReady,
        mapTealiumTrackingFields,
        currentSearchState
      )
    }
  }, [
    currentSearchState,
    debouncedUpdateTracking,
    mapTealiumTrackingFields,
    router.isReady
  ])

  return (
    <InstantSearch
      indexName={indexName}
      searchClient={searchClient}
      searchState={currentSearchState}
      resultsState={resultsState}
      onSearchStateChange={onSearchStateChange}
      createURL={createURL}
      {...restProps}
    >
      <Configure />
      {children}
    </InstantSearch>
  )
}

export default InstantSearchWrapper
