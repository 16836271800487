import { Box } from '@chakra-ui/react'
import Script from 'next/script'
import { ZINDEX } from 'utils'
import { TOHU_CHATBOT_CONFIG } from '../../constants'

const Tohu = () => {
  return (
    <>
      <Script src={TOHU_CHATBOT_CONFIG.src} strategy='afterInteractive' />
      <Box
        id={TOHU_CHATBOT_CONFIG.id}
        dir='ltr'
        sx={{
          '#myChatButton': {
            boxSize: { base: '128px', lg: '144px' },
            right: 'unset',
            left: 5,
            bottom: 2,
            zIndex: ZINDEX.TOHU_CHAT_BUTTON_ICON,
            _hover: {
              opacity: 0.87,
              bottom: 4
            }
          },
          /* control chat button */
          '.the-bot-face': {
            bgImage: 'url(/images/tohu-logo.png) !important'
          },
          /* Message container */
          '#chatBoxWrapper': {
            right: 'unset',
            left: { base: 0, md: 1 },
            bottom: 1,
            '.chatBoxHeader': {
              bg: 'deepWaterBlue',
              '.chatHeaderIcon span.icon': {
                w: 16,
                bgImage: 'url(/images/tohu-logo.png) !important'
              }
            },
            '.wc-message-from-bot .wc-message-content, .chatBoxContent .wc_narrow .wc-chatview-panel .wc-message-pane .wc-suggested-actions .wc-hscroll ul li button':
              {
                bg: '#22a2a2 !important'
              },
            '.wc-message-from-me .wc-message-content, .chatBoxContent .wc_narrow .wc-chatview-panel .wc-console, .chatBoxHeader #chatHeaderAction div.action-layer .action-list-container, .chatBoxHeader #chatHeaderAction div.action-layer .action-list':
              {
                bg: '#071937 !important'
              },
            '.wc-card button': {
              color: '#22a2a2 !important'
            },
            '.chatBoxContent .wc_narrow .wc-chatview-panel .wc-message-pane .wc-message-groups::-webkit-scrollbar-thumb':
              {
                bg: 'grey.100',
                boxShadow: 'none'
              }
          }
        }}
      />
    </>
  )
}

export default Tohu
