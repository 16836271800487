import { Box } from '@chakra-ui/react'

const UncheckedCircleIcon = () => {
  return (
    <Box
      backgroundColor='white'
      boxSize={{ base: '30px', lg: '50px' }}
      display='inline-block'
      borderRadius='full'
      borderStyle='solid'
      borderColor='teal.500'
      borderWidth={{ base: '2px', lg: '4px' }}
    />
  )
}

export default UncheckedCircleIcon
