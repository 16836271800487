import { createSanitisedPageName } from "../helpers";
import { TEALIUM_EVENT } from "../helpers/constants";

interface ProviderReferral {
  pageName?: string;
  providerName?: string;
  providerType: string;
  providerSubtype: string[];
  scholarshipName?: string;
  destinationUrl: string;
  linkLabel: string;
}

interface ProviderReferralResult {
  page_name: string;
  provider_name?: string;
  provider_type: string;
  provider_subtype: string[];
  scholarship_name?: string;
  destination_url: string;
  link_label: string;
  tealium_event: TEALIUM_EVENT;
}

/**
 * createProviderReferralUtagEventObject
 * Build the data for a provider_referral event
 */
function createProviderReferralUtagEventObject({
  pageName,
  providerName = "",
  providerType,
  providerSubtype,
  scholarshipName = "",
  destinationUrl,
  linkLabel,
}: ProviderReferral): ProviderReferralResult {
  return {
    page_name: pageName ? pageName : createSanitisedPageName(document.title),
    provider_name: providerName,
    provider_type: providerType,
    provider_subtype: providerSubtype,
    scholarship_name: scholarshipName,
    destination_url: destinationUrl,
    link_label: linkLabel,
    tealium_event: TEALIUM_EVENT.PROVIDER_REFERRAL,
  };
}

export default createProviderReferralUtagEventObject;
export type { ProviderReferral, ProviderReferralResult };
