import { AspectRatio, Box, Flex, GridItem } from '@chakra-ui/react'
import { ReactElement, ReactNode } from 'react'
import { CallToAction, ContainerWrapper, CtaButton, GridLayout, H3 } from 'ui'
import { getPageThemeBackgroundColour, getPageThemeCtaColour } from 'utils'
import MediaVideo from '../MediaVideo'

enum AlternatingComponentDisplayType {
  PortraitLeft = 'portraitLeft',
  PortraitRight = 'portraitRight',
  LandscapeLeft = 'landscapeLeft',
  LandscapeRight = 'landscapeRight'
}

type AlternatingComponentVideo = {
  mediaVideoSourceUrl?: string
  thumbnailUrlTitle?: string
  image: ReactNode
}

type AlternatingComponentImage = {
  image: ReactElement | null
}

export type MediaItemProps = AlternatingComponentVideo &
  AlternatingComponentImage

interface AlternatingComponentProps {
  primaryCta?: CallToAction
  secondaryCta?: CallToAction
  hasVideo: boolean
  mediaItemProps: MediaItemProps
  displayType: AlternatingComponentDisplayType
  heading: string | null
  children: ReactNode
  pageTheme?: string
  leadCaptureFormId?: string
}

const configureImageWrapperGridItemProps = (
  orientationPlacement: AlternatingComponentDisplayType
) => {
  switch (orientationPlacement) {
    case AlternatingComponentDisplayType.PortraitLeft:
      return {
        colSpan: { base: 4, lg: 6 }
      }
    case AlternatingComponentDisplayType.PortraitRight:
      return {
        colSpan: { base: 4, lg: 6 },
        colStart: { lg: 7 }
      }
    case AlternatingComponentDisplayType.LandscapeLeft:
      return {
        colSpan: { base: 4, lg: 7 }
      }
    case AlternatingComponentDisplayType.LandscapeRight:
      return {
        colSpan: { base: 4, lg: 7 },
        colStart: { lg: 6 }
      }
    default:
      return {}
  }
}

const configureTextWrapperGridItemProps = (
  displayType: AlternatingComponentDisplayType
) => {
  switch (displayType) {
    case AlternatingComponentDisplayType.PortraitLeft:
      return {
        colSpan: 4,
        colStart: { lg: 8 }
      }
    case AlternatingComponentDisplayType.PortraitRight:
      return {
        colSpan: 4,
        colStart: { lg: 2 },
        rowStart: { lg: 1 }
      }
    case AlternatingComponentDisplayType.LandscapeLeft:
      return {
        colSpan: 4,
        colStart: { lg: 9 }
      }
    case AlternatingComponentDisplayType.LandscapeRight:
      return {
        colSpan: 4,
        colStart: { lg: 1 },
        rowStart: { lg: 1 }
      }
    default:
      return {}
  }
}

const configureImageWrapperGridItemMargin = (
  displayType: AlternatingComponentDisplayType
) => {
  const marginCSSValues = {
    base: -6, // negative margin to force image to edge of screen on small screens
    sm: 'unset'
  }

  if (displayType.includes('Right')) {
    return {
      marginRight: { ...marginCSSValues }
    }
  }

  return {
    marginLeft: { ...marginCSSValues }
  }
}

const configureAspectRatioMargin = (
  displayType: AlternatingComponentDisplayType
) => {
  return displayType.includes('Right')
    ? { marginLeft: 'auto' }
    : { marginRight: 'auto' }
}

const renderMediaItem = <MediaType extends MediaItemProps>(
  hasVideo: boolean,
  mediaItemProps: MediaType
) => {
  if (!hasVideo) {
    return mediaItemProps.image
  }

  return (
    <MediaVideo
      sourceUrl={mediaItemProps.mediaVideoSourceUrl ?? ''}
      thumbnailUrlTitle={mediaItemProps.thumbnailUrlTitle ?? ''}
    >
      {mediaItemProps.image}
    </MediaVideo>
  )
}

const AlternatingComponent = ({
  primaryCta,
  secondaryCta,
  hasVideo,
  mediaItemProps,
  displayType,
  heading,
  pageTheme,
  children,
  leadCaptureFormId
}: AlternatingComponentProps) => {
  const imageWrapperGridItemProps =
    configureImageWrapperGridItemProps(displayType)
  const imageWrapperGridItemMargin =
    configureImageWrapperGridItemMargin(displayType)
  const textWrapperGridItemProps =
    configureTextWrapperGridItemProps(displayType)
  const containerBackgroundColour = getPageThemeBackgroundColour(pageTheme)
  const ctaColour = getPageThemeCtaColour(pageTheme)

  return (
    <ContainerWrapper bgColor={containerBackgroundColour}>
      <GridLayout marginX='0'>
        <GridItem
          {...imageWrapperGridItemProps}
          {...imageWrapperGridItemMargin}
        >
          <AspectRatio
            ratio={displayType.includes('portrait') ? 4 / 5 : 3 / 2}
            position='relative'
            maxWidth={{ base: '500px', sm: 'unset' }}
            {...configureAspectRatioMargin(displayType)}
          >
            {renderMediaItem(hasVideo, mediaItemProps)}
          </AspectRatio>
        </GridItem>
        <GridItem {...textWrapperGridItemProps}>
          <Flex flexDir='column' justifyContent='center' height='100%'>
            {heading && <H3 mb={4}>{heading}</H3>}
            <Box mb={10}>{children}</Box>
            {primaryCta?.label && (
              <Box>
                <CtaButton
                  colorScheme={
                    pageTheme && pageTheme !== 'default' ? ctaColour : 'teal'
                  }
                  variant='outline'
                  href={primaryCta.href}
                  openInNewWindow={primaryCta.openInNewWindow}
                  isExternalLink={primaryCta.isExternalLink}
                  externalIconSize={6}
                  aria-label={primaryCta.label}
                  modalId={primaryCta.modalId}
                  leadCaptureFormId={leadCaptureFormId}
                >
                  {primaryCta.label}
                </CtaButton>
              </Box>
            )}
            {secondaryCta?.label && (
              <Box mt={2}>
                <CtaButton
                  colorScheme={
                    pageTheme && pageTheme !== 'default' ? ctaColour : 'teal'
                  }
                  variant='outline'
                  href={secondaryCta.href}
                  openInNewWindow={secondaryCta.openInNewWindow}
                  isExternalLink={secondaryCta.isExternalLink}
                  externalIconSize={6}
                  aria-label={secondaryCta.label}
                  modalId={secondaryCta.modalId}
                  leadCaptureFormId={leadCaptureFormId}
                >
                  {secondaryCta.label}
                </CtaButton>
              </Box>
            )}
          </Flex>
        </GridItem>
      </GridLayout>
    </ContainerWrapper>
  )
}

export default AlternatingComponent
export type { AlternatingComponentProps }
export { AlternatingComponentDisplayType }
