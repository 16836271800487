import React from 'react'

type MockNextImageProps = JSX.IntrinsicElements['img']

const MockNextImage = ({
  src,
  alt,
  width,
  height,
  style
}: MockNextImageProps) => (
  <img src={src} alt={alt} width={width} height={height} style={style} />
)
export default MockNextImage
