import { Box, Code, Divider, Link, Text } from '@chakra-ui/react'
import {
  CommonNode,
  documentToReactComponents,
  Options,
  RenderNode
} from '@contentful/rich-text-react-renderer'
import { BLOCKS, Document, INLINES, MARKS } from '@contentful/rich-text-types'
import {
  Blockquote_Swnz_BlockquoteFragment,
  Glossary_Swnz_GlossaryFragment,
  InfoBox_Swnz_InfoBoxFragment,
  Maybe,
  MediaImage_Swnz_MediaImageFragment,
  ParentPagePath_Swnz_PageFragment,
  Swnz_Asset,
  Swnz_Entry
} from 'content-service'
import { ExternalLinkIcon } from 'icons'
import { ReactNode, useEffect, useState } from 'react'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { isExternalUrl } from 'utils'
import { createHref } from 'utils/src/helpers'
import {
  EntryBlockquote,
  Glossary,
  InfoBox
} from '../../components/content-components'
import { Blockquote, H1, H2, H3, H4, H5, H6 } from '../../components/typography'
import { ElementSelect } from '../../selectors/ElementSelect'
import { Link as NextLink } from '../Link'
import { LI, OL, UL } from '../lists'

interface RichTextEntries {
  inline: Array<Maybe<Swnz_Entry>>
  hyperlink: Array<Maybe<Swnz_Entry>>
  block: Array<Maybe<Swnz_Entry>>
}
interface RichTextAssets {
  hyperlink: Array<Maybe<Swnz_Asset>>
  block: Array<Maybe<Swnz_Asset>>
}
interface Links {
  entries?: RichTextEntries
  assets?: RichTextAssets
}
export interface RichText<RichTextLinkType> {
  json: Document
  links?: RichTextLinkType
}
export interface RichTextRendererProps<RichTextLinkType> {
  richText?: RichText<RichTextLinkType> | null
}

enum ENTRY_TYPES {
  HYPERLINK = 'hyperlink',
  BLOCK = 'block'
}

interface GetLinkProps {
  id: string
  type: ENTRY_TYPES
  links: Links
}

function selectLinksData({
  id,
  type,
  links
}: GetLinkProps):
  | Swnz_Entry
  | ParentPagePath_Swnz_PageFragment
  | Glossary_Swnz_GlossaryFragment
  | InfoBox_Swnz_InfoBoxFragment
  | MediaImage_Swnz_MediaImageFragment
  | Blockquote_Swnz_BlockquoteFragment
  | undefined
  | null {
  if (type === ENTRY_TYPES.HYPERLINK) {
    const data = links?.entries?.hyperlink
      ? links?.entries?.hyperlink.find((link) => link?.sys.id === id)
      : undefined
    return data
  }

  if (type === ENTRY_TYPES.BLOCK) {
    const data = links?.entries?.block
      ? links?.entries?.block.find((link) => link?.sys.id === id)
      : undefined
    return data
  }
}

const createOptions = <RichTextLinkType,>(
  links?: RichTextLinkType
): Options => {
  /**
   *  Return document with no text wrapped in p tags. Contentful wraps all text with
   *  <p> tags even if the text is nested with other elements.
   *  Current issue see: https://github.com/contentful/rich-text/issues/126#issuecomment-636926522
   *
   *  Assumption is that divider and embedded items shouldn't be handled within a <li> tag
   *
   * @param node
   * @returns Node
   */
  const processListChildren = (node: CommonNode) =>
    documentToReactComponents(node as Document, {
      renderNode: {
        [BLOCKS.PARAGRAPH]: function Paragraph(_, children) {
          return children
        },
        [BLOCKS.LIST_ITEM]: function ListItem(_, children) {
          return children
        },
        ...hyperLinks
      }
    })

  const typography: RenderNode = {
    [BLOCKS.PARAGRAPH]: function Paragraph(_, children) {
      return <Text _last={{ marginBottom: 0 }}>{children}</Text>
    },
    [BLOCKS.QUOTE]: function Quote(_, children) {
      return <Blockquote>{children}</Blockquote>
    },
    [BLOCKS.HEADING_1]: function Heading1(_, children) {
      return <H1>{children}</H1>
    },
    [BLOCKS.HEADING_2]: function Heading2(_, children) {
      return <H2>{children}</H2>
    },
    [BLOCKS.HEADING_3]: function Heading3(_, children) {
      return <H3>{children}</H3>
    },
    [BLOCKS.HEADING_4]: function Heading4(_, children) {
      return <H4>{children}</H4>
    },
    [BLOCKS.HEADING_5]: function Heading5(_, children) {
      return <H5>{children}</H5>
    },
    [BLOCKS.HEADING_6]: function Heading6(_, children) {
      return <H6>{children}</H6>
    }
  }

  const list: RenderNode = {
    [BLOCKS.LIST_ITEM]: function ListItem(node, _) {
      return <LI>{processListChildren(node)}</LI>
    },
    [BLOCKS.OL_LIST]: function OLList(_, children) {
      return <OL>{children}</OL>
    },
    [BLOCKS.UL_LIST]: function ULList(_, children) {
      return <UL>{children}</UL>
    }
  }

  const hyperLinks: RenderNode = {
    [INLINES.ENTRY_HYPERLINK]: function EntryHyperlink(node, children) {
      if (!links) return children

      const linkData = selectLinksData({
        id: node.data.target.sys.id,
        type: ENTRY_TYPES.HYPERLINK,
        links
      })

      if (
        linkData &&
        '__typename' in linkData &&
        linkData.__typename === 'SWNZ_Glossary'
      ) {
        return (
          <Glossary
            term={linkData?.glossaryTerm ?? ''}
            definition={linkData?.glossaryDefinition ?? ''}
          >
            {children}
          </Glossary>
        )
      }

      const href = createHref({ internalLink: linkData as Swnz_Entry })
      return (
        linkData && (
          <NextLink
            href={href}
            onClick={() => {
              createUtagLinkClickEvent({
                linkLabel: `${children}`,
                linkCategory: TEALIUM_LINK_CATEGORY.CONTENT,
                destinationUrl: href
              })
            }}
          >
            {children}
          </NextLink>
        )
      )
    },
    [INLINES.HYPERLINK]: function Hyperlink(node, children) {
      return (
        <Link
          href={node.data.uri}
          isExternal={isExternalUrl(node.data.uri)}
          sx={{ svg: { display: 'inline', width: '1em', height: '1em' } }}
          onClick={() => {
            createUtagLinkClickEvent({
              linkLabel: `${children}`,
              linkCategory: !isExternalUrl(node.data.uri)
                ? TEALIUM_LINK_CATEGORY.CONTENT
                : TEALIUM_LINK_CATEGORY.OUTBOUND,
              destinationUrl: node.data.uri
            })
          }}
        >
          {children}
          {isExternalUrl(node.data.uri) ? <ExternalLinkIcon /> : null}
        </Link>
      )
    }
  }

  const embedded: RenderNode = {
    [BLOCKS.EMBEDDED_ENTRY]: function EmbeddedEntry(node, children) {
      if (!links) return <>{children}</>

      const linkData = selectLinksData({
        id: node.data.target.sys.id,
        type: ENTRY_TYPES.BLOCK,
        links
      })

      if (linkData && '__typename' in linkData && linkData.__typename) {
        let elementProps = {}
        if (linkData.__typename === 'SWNZ_InfoBox') {
          return (
            <InfoBox>
              <RichTextRenderer richText={linkData?.infoText} />
            </InfoBox>
          )
        }
        if (linkData.__typename === 'SWNZ_Blockquote') {
          return (
            <EntryBlockquote>
              <RichTextRenderer richText={linkData?.bodyText} />
            </EntryBlockquote>
          )
        }
        if (linkData.__typename === 'SWNZ_MediaImage') {
          elementProps = {
            noDefaultPadding: true,
            aspectRatio: 1096 / 539
          }
        }
        return (
          <ElementSelect
            id={linkData.sys.id}
            typename={linkData.__typename}
            {...elementProps}
          />
        )
      }

      return <>{children}</>
    }
  }

  const divider: RenderNode = {
    [BLOCKS.HR]: function Hr() {
      return <Divider />
    }
  }

  return {
    renderNode: {
      ...typography,
      ...list,
      ...hyperLinks,
      ...embedded,
      ...divider
    },
    renderMark: {
      [MARKS.BOLD]: function Bold(text) {
        return (
          <Box as='b' fontWeight='unset' fontFamily='enz700'>
            {text}
          </Box>
        )
      },
      [MARKS.ITALIC]: function Italic(text) {
        return (
          <Box as='i' fontStyle='italic'>
            {text}
          </Box>
        )
      },
      [MARKS.UNDERLINE]: function Underline(text) {
        return <Box as='u'>{text}</Box>
      },
      [MARKS.CODE]: function MarksCode(text) {
        return <Code>{text}</Code>
      },
      ['subscript']: function Subscript(text) {
        return (
          <Box fontSize={{ base: '14px' }} lineHeight={{ base: '20px' }}>
            {text}
          </Box>
        )
      },
      ['superscript']: function Superscript(text) {
        return (
          <Box
            fontSize={{ base: '20px', lg: '22px' }}
            lineHeight={{ base: '30px', lg: '36px' }}
          >
            {text}
          </Box>
        )
      }
    }
  }
}

function RichTextRenderer<RichTextLinkType>({
  richText
}: RichTextRendererProps<RichTextLinkType>) {
  const [content, setContent] = useState<ReactNode | null>()

  useEffect(() => {
    if (richText) {
      const options = createOptions(richText?.links)
      const document = richText?.json
      const generatedComponents = documentToReactComponents(document, options)

      setContent(generatedComponents)
    }
  }, [richText])

  if (!richText) {
    return null
  }

  return <>{content ? content : null}</>
}

export default RichTextRenderer
