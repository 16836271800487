import { FORM_COMPONENTS, FORM_CONSTANTS } from './constants'
import { DashboardUpdatedItemProps } from './dashboard'
import {
  EnquiryAPIEndpointPayload,
  EnquiryAPIEnquiryModel,
  EnquiryAPILearnerProfileModel,
  enquiryEndpointSchema,
  enquiryFormSchema,
  EnquiryFormType,
  EnquiryFormValues,
  FormSubmissionCallbacks,
  removeLoginDataCookie,
  useEnquiryFormSubmission,
  useHandleModalCloseResetData
} from './enquiryForm'
import { postLeadCaptureFormSubmissionToLeadCaptureAPIEndpoint } from './leadCaptureForm'
import { ProfileAPISuggestionInventoryItemModel } from './profileEndpoint'
import {
  ProfileAPIRegistrationModel,
  ProfileAPIUpdateModel,
  ProfileAPIValues,
  profileRegistrationEndpointSchema,
  ProfileType,
  profileUpdateEndpointSchema,
  SWNZRegistrationAPIModel,
  swnzRegistrationEndpointSchema
} from './profileForm'
import reformatDate, { reformatDateForSubmit } from './reformatDate'
import {
  learnerInventoryItemUpdateModelEndpointSchema,
  StudyOptionItemType,
  StudyOptionsAPILearnerInventoryItemModel,
  StudyOptionsAPILearnerInventoryItemUpdateModel,
  StudyOptionsAPIValues,
  StudyOptionStatus
} from './studyOptionsEndpoint'
import validateFormData, { FormSchemaObj } from './validateFormData'
export {
  swnzRegistrationEndpointSchema,
  learnerInventoryItemUpdateModelEndpointSchema,
  reformatDateForSubmit,
  useEnquiryFormSubmission,
  enquiryFormSchema,
  FORM_CONSTANTS,
  postLeadCaptureFormSubmissionToLeadCaptureAPIEndpoint,
  reformatDate,
  FORM_COMPONENTS,
  EnquiryFormType,
  validateFormData,
  enquiryEndpointSchema,
  ProfileType,
  profileRegistrationEndpointSchema,
  profileUpdateEndpointSchema,
  StudyOptionStatus,
  StudyOptionItemType,
  removeLoginDataCookie,
  useHandleModalCloseResetData
}
export type {
  SWNZRegistrationAPIModel,
  FormSubmissionCallbacks,
  EnquiryFormValues,
  EnquiryAPILearnerProfileModel,
  EnquiryAPIEndpointPayload,
  FormSchemaObj,
  EnquiryAPIEnquiryModel,
  ProfileAPIRegistrationModel,
  ProfileAPIUpdateModel,
  ProfileAPIValues,
  StudyOptionsAPILearnerInventoryItemModel,
  StudyOptionsAPILearnerInventoryItemUpdateModel,
  StudyOptionsAPIValues,
  ProfileAPISuggestionInventoryItemModel,
  DashboardUpdatedItemProps
}
