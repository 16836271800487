import {
  Box,
  BoxProps,
  Divider,
  FormControl,
  HStack,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Text,
  TextProps
} from '@chakra-ui/react'
import { format } from 'date-fns'
import {
  Form as FormikForm,
  FormikValues,
  useField,
  useFormikContext
} from 'formik'
import { WarningRoundedIcon } from 'icons'
import {
  ChangeEventHandler,
  FormEventHandler,
  ReactNode,
  useState
} from 'react'
import breakpoints from 'theme/src/theme/foundations/breakpoints'
import { useMediaQuery } from 'utils/src/helpers'
import { TaxonomyObject } from 'utils/src/helpers/taxonomy'
import { H3, LargeText } from '../../components/typography'
import { DatePickerControl } from '../DatePicker'
import { PhoneNumberInput, StyleInput, StyleInputProps } from '../input'
import SelectControl from '../select/SelectControl'

interface DateProps {
  initialValue?: Date | string
}

const Form = ({ children }: { children: ReactNode }) => {
  const { handleSubmit } = useFormikContext<FormikValues>()
  return <FormikForm onSubmit={handleSubmit}>{children}</FormikForm>
}

Form.LeadText = function FormLeadText({ children, ...props }: TextProps) {
  return (
    <LargeText mb={0} pt={5} {...props}>
      {children}
    </LargeText>
  )
}

Form.Divider = function FormDivider({ mb, mt }: { mb?: number; mt?: number }) {
  return (
    <Box w='full'>
      <Divider mt={mt} mb={mb} />
    </Box>
  )
}

Form.FirstNameField = function FormFirstNameField({
  label,
  ...props
}: Partial<StyleInputProps>) {
  return (
    <StyleInput
      type='text'
      isRequired
      name='firstName'
      label={label || 'First name'}
      {...props}
    />
  )
}

Form.LastNameField = function FormLastNameField({
  label,
  ...props
}: Partial<StyleInputProps>) {
  return (
    <StyleInput
      type='text'
      isRequired
      name='lastName'
      label={label || 'Last name'}
      {...props}
    />
  )
}

Form.EmailAddressField = function FormEmailAddressField({
  label,
  isDisabled
}: Partial<StyleInputProps>) {
  return (
    <StyleInput
      type='email'
      isRequired
      name='email'
      label={label || 'Email address'}
      isDisabled={isDisabled}
    />
  )
}

Form.PhoneNumberField = function FormPhoneNumberField({
  name,
  label,
  isRequired = false,
  countryCodeEditable,
  country = 'nz',
  disableDropdown
}: {
  name?: string
  label?: string
  isRequired?: boolean
  countryCodeEditable?: boolean
  disableDropdown?: boolean
  country?: string
}) {
  return (
    <PhoneNumberInput
      name={name ? name : 'mobilePhone'}
      label={label ? label : 'Mobile number'}
      isRequired={isRequired}
      countryCodeEditable={countryCodeEditable}
      disableDropdown={disableDropdown}
      country={country}
    />
  )
}

Form.DateOfBirthField = function FormDateOfBirthField({
  label,
  initialValue,
  onBlur,
  isRequired
}: Partial<StyleInputProps> & DateProps) {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.sm})`)
  const parseValue =
    initialValue && format(new Date(initialValue), 'yyyy-MM-dd')
  return (
    <>
      {isMobile ? (
        //Uses the StyleInput component for mobile to use the native calendar
        <StyleInput
          onBlur={onBlur}
          name='dateOfBirth'
          type='date'
          label={label || 'Date of birth'}
          hasIcon
          defaultValue={parseValue}
          isDate={true}
          isRequired={isRequired}
        />
      ) : (
        <DatePickerControl
          onBlur={onBlur}
          name='dateOfBirth'
          label={label || 'Date of birth'}
          minDate={false}
          isMobile={isMobile}
          isRequired={isRequired}
        />
      )}
    </>
  )
}

Form.SelectField = function FormSelectField({
  name,
  label,
  required,
  options,
  onInput
}: {
  name: string
  label?: string
  required: boolean
  options: { key: string; label: string }[]
  onInput?: FormEventHandler<HTMLDivElement> &
    ChangeEventHandler<HTMLSelectElement>
}) {
  return (
    <SelectControl
      name={name}
      label={label}
      isRequired={required}
      onInput={onInput}
    >
      <option value=''></option>
      {options.length > 0 &&
        options.map((option, i) => (
          <option key={`${option.key}-${i}`} value={option.key}>
            {option.label}
          </option>
        ))}
    </SelectControl>
  )
}

Form.DeliveryModeField = function FormDeliveryModeField({
  deliveryModeOptions,
  isRequired
}: {
  deliveryModeOptions: TaxonomyObject[]
  isRequired?: boolean
}) {
  return (
    <SelectControl
      name='deliveryMode'
      label='Will you be studying in New Zealand, Online, or via Global Pathways (start your studies at home)?'
      isRequired={isRequired}
    >
      <option value=''></option>
      {deliveryModeOptions.length > 0 &&
        deliveryModeOptions.map((option, i) => (
          <option key={`${option.key}-${i}`} value={option.key}>
            {option.label}
          </option>
        ))}
    </SelectControl>
  )
}

Form.PersonTypeField = function FormPersonTypeField({
  personTypeOptions,
  isRequired,
  label
}: {
  personTypeOptions: TaxonomyObject[]
  isRequired?: boolean
  label?: string
}) {
  return (
    <SelectControl
      name='personType'
      label={label || 'I am a'}
      isRequired={isRequired}
    >
      <option value=''></option>
      {personTypeOptions.length > 0 &&
        personTypeOptions.map((option, i) => (
          <option key={`${option.key}-${i}`} value={option.key}>
            {option.label}
          </option>
        ))}
    </SelectControl>
  )
}

Form.NationalityField = function FormNationalityField({
  label,
  onInput,
  nationalityOptions,
  isRequired
}: {
  label?: string
  onInput?: FormEventHandler<HTMLDivElement> &
    ChangeEventHandler<HTMLSelectElement>
  nationalityOptions: TaxonomyObject[]
  isRequired?: boolean
}) {
  return (
    <SelectControl
      name='nationality'
      label={label ? label : 'Location'}
      onInput={onInput}
      isRequired={isRequired}
    >
      <option value=''></option>
      {nationalityOptions.length > 0 &&
        nationalityOptions.map((option, i) => (
          <option key={`${option.key}-${i}`} value={option.key}>
            {option.label}
          </option>
        ))}
    </SelectControl>
  )
}

Form.LanguageField = function FormLanguageField({
  languageOptions
}: {
  languageOptions: TaxonomyObject[]
}) {
  return (
    <SelectControl name='prefLanguage' label='Language' isRequired>
      <option value=''></option>
      {languageOptions.length > 0 &&
        languageOptions.map((option, i) => (
          <option key={`${option.key}-${i}`} value={option.label}>
            {option.label}
          </option>
        ))}
    </SelectControl>
  )
}

Form.LevelOfStudyField = function FormLevelOfStudyField({
  name,
  label,
  isRequired = false,
  levelOfStudyOptions
}: {
  name?: string
  label?: string
  isRequired?: boolean
  levelOfStudyOptions: TaxonomyObject[]
}) {
  return (
    <SelectControl
      name={name ? name : 'levelOfStudy'}
      label={label ? label : 'Intended level of study'}
      isRequired={isRequired}
    >
      <option value=''></option>
      {levelOfStudyOptions.length > 0 &&
        levelOfStudyOptions.map((option, i) => (
          <option key={`${option.key}-${i}`} value={option.key}>
            {option.label}
          </option>
        ))}
    </SelectControl>
  )
}

Form.AreaOfStudyField = function FormAreaOfStudyField({
  areaOfStudyOptions,
  isRequired,
  label
}: {
  areaOfStudyOptions: TaxonomyObject[]
  isRequired?: boolean
  label?: string
}) {
  return (
    <SelectControl
      name='areaOfStudy'
      label={label || 'Intended area of study'}
      isRequired={isRequired}
    >
      <option value=''></option>
      {areaOfStudyOptions.length > 0 &&
        areaOfStudyOptions.map((option, i) => (
          <option key={`${option.key}-${i}`} value={option.key}>
            {option.label}
          </option>
        ))}
    </SelectControl>
  )
}

Form.StartDateField = function StartDateField({
  name,
  label,
  isRequired,
  datePickerControlStyles
}: {
  name?: string
  label?: string
  isRequired?: boolean
  datePickerControlStyles?: BoxProps
}) {
  const isMobile = useMediaQuery(`(max-width: ${breakpoints.sm})`)
  return (
    <>
      {isMobile ? (
        <StyleInput
          name={name ? name : 'prefStartDate'}
          label={label ? label : 'Approximate start date'}
          type='date'
          isRequired={isRequired}
          hasIcon
          isDate={true}
        />
      ) : (
        <Box sx={{ width: '100%', ...datePickerControlStyles }}>
          <DatePickerControl
            name={name ? name : 'prefStartDate'}
            label={label ? label : 'Approximate start date'}
            isRequired={isRequired}
          />
        </Box>
      )}
    </>
  )
}

Form.StartDateControl = function StartDateControl({
  prefStartDate
}: {
  prefStartDate?: Date | string
}) {
  const [field, meta, { setValue }] = useField('picked')
  const { value } = field

  if (value === 'no' || value === undefined) {
    prefStartDate = undefined
  }

  return (
    <>
      <Text>{'Do you know when you would like to start studying?'}</Text>
      <FormControl>
        <RadioGroup
          name='picked'
          value={value}
          defaultValue={prefStartDate ? 'yes' : ''}
          onChange={(value: string) => {
            setValue(value)
          }}
        >
          <Stack direction='row' spacing={8}>
            <Radio value='yes'>{'Yes'}</Radio>
            <Radio value='no'>{'No'}</Radio>
          </Stack>
        </RadioGroup>
      </FormControl>
      {(value === 'yes' || prefStartDate) && (
        <Form.StartDateField
          name='prefStartDate'
          label='Approximate start date'
          isRequired
          datePickerControlStyles={{
            width: { base: 'full', lg: 'calc(50% - 8px)' }
          }}
        />
      )}
    </>
  )
}

Form.RadioField = function RadioField({
  label,
  options,
  name
}: {
  label: string
  name: string
  options: {
    value: string
    label: string
  }[]
}) {
  const [radioValue, setRadioValue] = useState('')
  const [field, meta] = useField(name)
  const errorTouched = meta.error && meta.touched

  return (
    <>
      <Text>{label}</Text>
      <FormControl id={name} isInvalid={errorTouched ? true : false} {...field}>
        <RadioGroup name={name} value={radioValue} onChange={setRadioValue}>
          <Stack direction='column' spacing={2}>
            {options.map((option, index) => (
              <Radio key={index} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      </FormControl>
    </>
  )
}

Form.AgentUseField = function FormAgentUseField({
  agentUseOptions
}: {
  agentUseOptions: TaxonomyObject[]
}) {
  return (
    <SelectControl name='useOfAgent' label='Agent use' isRequired>
      <option value=''></option>
      {agentUseOptions.length > 0 &&
        agentUseOptions.map((option, i) => (
          <option key={`${option.key}-${i}`} value={option.label}>
            {option.label}
          </option>
        ))}
    </SelectControl>
  )
}

Form.LevelOfEnglishField = function FormLevelOfEnglishField({
  levelOfEnglishOptions
}: {
  levelOfEnglishOptions: TaxonomyObject[]
}) {
  return (
    <SelectControl name='englishProfLevel' label='Level of English'>
      <option value=''></option>
      {levelOfEnglishOptions.length > 0 &&
        levelOfEnglishOptions.map((option, i) => (
          <option key={`${option.key}-${i}`} value={option.label}>
            {option.label}
          </option>
        ))}
    </SelectControl>
  )
}

Form.AreYouInNZField = function AreYouInNZField({
  areYouInNzOptions
}: {
  areYouInNzOptions: TaxonomyObject[]
}) {
  return (
    <SelectControl name='areYouInNZ' label='Are you in New Zealand?'>
      <option value=''></option>
      {areYouInNzOptions.length > 0 &&
        areYouInNzOptions.map((option, i) => (
          <option key={`${option.key}-${i}`} value={option.label}>
            {option.label}
          </option>
        ))}
    </SelectControl>
  )
}

Form.MoreInformationField = function FormMoreInformationField({
  moreInformationOptions
}: {
  moreInformationOptions: TaxonomyObject[]
}) {
  const { values } = useFormikContext<FormikValues>()
  return (
    <>
      <Box w='full'>
        <Text mb={1}>
          {'What would you like to receive more information about?'}
        </Text>
        <SelectControl
          name='sourceInformation'
          label='Information on'
          isRequired
        >
          <option value=''></option>
          {moreInformationOptions.map((option, i) => (
            <option key={`${option.key}-${i}`} value={option.label}>
              {option.label}
            </option>
          ))}
        </SelectControl>
      </Box>
      {values?.sourceInformation === 'Other' && (
        <Box w='full'>
          <StyleInput
            name='sourceInformationOther'
            label='Other questions or comments'
          />
        </Box>
      )}
    </>
  )
}

Form.RegionField = function FormRegionField({
  label,
  name,
  isRequired,
  regionOptions
}: {
  label?: string
  name?: string
  isRequired?: boolean
  regionOptions: TaxonomyObject[]
}) {
  return (
    <SelectControl
      label={label ? label : 'State or region'}
      name={name ? name : 'stateRegion'}
      isRequired={isRequired}
    >
      <option value=''></option>
      {regionOptions.length > 0 &&
        regionOptions.map((option, i) => (
          <option key={`${option.key}-${i}`} value={option.key}>
            {option.label}
          </option>
        ))}
    </SelectControl>
  )
}

Form.ErrorContent = function FormErrorContent() {
  return (
    <>
      <HStack spacing={3} mb={6}>
        <Box boxSize={8} color='red.600'>
          <WarningRoundedIcon />
        </Box>
        <H3 color='red.600'>Error!</H3>
      </HStack>
      <Text maxW='528px'>
        There was an issue with your submission. If you keep experiencing this
        issue - please contact us at:{' '}
        <Link href='mailto:info@studywithnewzealand.govt.nz'>
          info@studywithnewzealand.govt.nz
        </Link>
      </Text>
    </>
  )
}

Form.LineIdField = function FormLineIdField({
  label,
  isRequired
}: {
  label?: string
  isRequired?: boolean
}) {
  return (
    <StyleInput
      name='lineId'
      label={label || 'Line ID'}
      isRequired={isRequired}
    />
  )
}

export default Form
