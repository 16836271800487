import {
  Statistics_Swnz_StatContainerFragment,
  Statistics_Swnz_StatContainerFragmentDoc
} from 'content-service'
import useFragment from 'utils/src/hooks/useFragment'
import { Statistics } from '../../components/content-components'
import { Connector } from '../types'
import { createStatistics } from './helpers/statistics'

export type StatisticsConnectProps = Connector

const StatisticsConnect = ({ id, typename }: StatisticsConnectProps) => {
  const statistics = useFragment<Statistics_Swnz_StatContainerFragment>({
    id,
    typename,
    fragment: Statistics_Swnz_StatContainerFragmentDoc,
    fragmentName: 'statistics_SWNZ_StatContainer'
  })

  if (!statistics) {
    return null
  }

  const statisticsItems = statistics?.statsCollection?.items ?? []
  const stats = createStatistics({ statistics: statisticsItems })

  return <Statistics statItems={stats} />
}

export default StatisticsConnect
