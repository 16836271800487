import { Asset_Swnz_AssetFragment, MediaImage_Swnz_MediaImageFragment } from 'content-service'
import { Box, Image } from '@chakra-ui/react'

interface HomepageHeroVideoProps {
  heroBackgroundVideo: Pick<Asset_Swnz_AssetFragment, 'url' | 'title'> | null | undefined
  heroImage: Pick<MediaImage_Swnz_MediaImageFragment, 'source'> | null | undefined
}

export default function HomepageHeroVideo({
  heroBackgroundVideo,
  heroImage,
}: HomepageHeroVideoProps) {
  const posterUrl = heroImage?.source?.url ?? ''
  const videoSrc = heroBackgroundVideo?.url ?? ''
  const videoTitle = heroBackgroundVideo?.title ?? ''

  if (posterUrl && videoSrc == '')
    return (
      <Image
        src={posterUrl}
        height="100vh"
        width="full"
        objectFit="cover"
        alt="Homepage Hero Image"
      />
    )

  return (
    <Box
      as="video"
      playsInline={true}
      autoPlay={true}
      muted={true}
      loop={true}
      position="relative"
      poster={posterUrl}
      height="100vh"
      width="full"
      objectFit="cover"
    >
      <Box as="source" src={videoSrc} title={videoTitle} />
    </Box>
  )
}
