import {
  AnimatePresence as OrigAAnimatePresence,
  AnimatePresenceProps as OrigAnimatePresenceProps
} from 'framer-motion'
import { FC, ReactNode } from 'react'

interface AnimatePresenceProps
  extends Omit<OrigAnimatePresenceProps, 'children'> {
  children: ReactNode
}

// https://github.com/framer/motion/issues/1509 similar to // https://github.com/chakra-ui/chakra-ui/issues/5896
export const AnimatePresence: FC<AnimatePresenceProps> = OrigAAnimatePresence
