import { GalleryCarouselMediaItem_Swnz_GalleryCarouselMediaItemsItemFragment } from 'content-service'
import { isPresent } from 'ts-is-present'
import { IMAGE_SIZE_CONFIG } from 'utils'
import { GalleryItems } from '../../../components/content-components'
import { OptimisedImage, OptimisedImageProps } from '../../../components/Image'

export interface CreateGalleryItems {
  galleryItems?: Array<GalleryCarouselMediaItem_Swnz_GalleryCarouselMediaItemsItemFragment | null>
}

export const createGalleryItems = ({
  galleryItems
}: CreateGalleryItems): Array<GalleryItems> => {
  if (!galleryItems) {
    return []
  }

  return galleryItems.filter(isPresent).map((item) => {
    const imageComponentProps: OptimisedImageProps = {
      src: '',
      alt: '',
      title: '',
      imageSizeConfig: IMAGE_SIZE_CONFIG.GALLERY_CAROUSEL
    }

    let videoUrl = ''

    if (item.__typename === 'SWNZ_MediaImage') {
      imageComponentProps.src = item.source?.url ?? ''
      imageComponentProps.alt = item?.source?.description ?? ''
      imageComponentProps.title = item?.source?.title ?? ''
    }

    if (item.__typename === 'SWNZ_MediaVideo') {
      imageComponentProps.src = item.thumbnail?.url ?? ''
      videoUrl = item?.sourceUrl ?? ''
    }

    return {
      image: OptimisedImage(imageComponentProps),
      caption: item?.caption ?? '',
      video: videoUrl
    }
  })
}
