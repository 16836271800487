import {
  connectStateResults,
  StateResultsProvided
} from 'react-instantsearch-core'
import { Button } from '../../buttons'

interface ShowResultsButton
  extends Pick<StateResultsProvided, 'searchResults' | 'isSearchStalled'> {
  onClick: () => void
}

export const Component = ({
  onClick,
  searchResults,
  isSearchStalled
}: ShowResultsButton) => (
  <Button
    onClick={onClick}
    isLoading={isSearchStalled}
    loadingText='Loading'
    spinnerPlacement='end'
  >
    Show {searchResults?.nbHits} results
  </Button>
)

export default connectStateResults(Component)
