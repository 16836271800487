import { Box } from '@chakra-ui/react'
import { SearchIcon } from 'icons'
import React from 'react'
import { Button } from '../../buttons'

const SearchButton = () => {
  return (
    <Button
      aria-label='search'
      rightIcon={
        <Box boxSize={4}>
          <SearchIcon />
        </Box>
      }
      width='full'
      data-testid='finder-widget--search-button'
    >
      Search
    </Button>
  )
}

export default SearchButton
