import { useAuth0 } from '@auth0/auth0-react'
import { Box, Flex, Text } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { CloseIcon, TickRoundedIcon } from 'icons'
import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { ReactNode, useEffect, useState } from 'react'
import { Button, H3, H4, SmallText } from 'ui'
import { ZINDEX } from 'utils'

const MotionBox = motion(Box)

interface FormPopupProps {
  children: ({
    handleFormSuccess,
    handleFormError
  }: {
    handleFormSuccess: () => void
    handleFormError: () => void
  }) => ReactNode
  cookieName: string
  id?: string
  heading?: {
    initial: string
    onSuccess: string
  }
  bodyText?: {
    initial: string
    onSuccess: string
  }
  successMessage?: {
    heading: string
    bodyText: string
  }
  errorMessage?: {
    heading: string
    bodyText: string
  }
}

const FormPopup = ({
  children,
  cookieName,
  id,
  heading,
  bodyText,
  successMessage,
  errorMessage
}: FormPopupProps) => {
  const [successfulSubmission, setSuccessfulSubmission] =
    useState<boolean>(false)
  const [submissionError, setSubmissionError] = useState<boolean>(false)
  const [active, setActive] = useState(true)
  const { isAuthenticated, isLoading } = useAuth0()

  const {
    query: { showLeadPopup },
    events: RouterEvents
  } = useRouter()

  const HIDE_POPUP_COOKIE = cookieName
  const FIRST_VISIT_COOKIE = 'POPUP_FIRST_VISIT'
  const INITIAL_DELAY = 60
  const isSubsequentVisit = !!Cookies.get(FIRST_VISIT_COOKIE)
  const hasCookie = !!Cookies.get(HIDE_POPUP_COOKIE)

  /**
   * Ideally we would use Tealium here to trigger when we should load this on subsequent page visits
   * However we can't use tealium_session_event_number for toggling this because subsequent page visits within the SPA routing don't get counted.
   * This value is only updated when a hard refresh is triggered
   */
  useEffect(() => {
    /** Set the cookie if this is the users first visit */
    const setFirstVisitCookie = () => {
      Cookies.set(FIRST_VISIT_COOKIE, 'true')
    }

    // Only run if the first time cookie isn't set
    // console.debug(`👆 Has this user visited the site before: ${isSubsequentVisit ? 'yes' : 'no'}`)
    if (!isSubsequentVisit) {
      // if user navigates away to a completely different site
      // or refreshes the page etc then set cookie
      window.addEventListener('beforeunload', setFirstVisitCookie)

      // if user navigates to another page on the same site
      RouterEvents.on('routeChangeStart', setFirstVisitCookie)

      // Remove our events on unload
      return () => {
        RouterEvents.off('routeChangeStart', setFirstVisitCookie)
        window.removeEventListener('beforeunload', setFirstVisitCookie)
      }
    }

    /* We need this return statement here otherwise typescript will throw an error */
    return
  }, [isSubsequentVisit, RouterEvents])

  // Don't render the component if HIDE_POPUP_COOKIE === true and`showLeadPopup` isn't present in the url params
  if ((hasCookie && !showLeadPopup) || isAuthenticated || isLoading) {
    return null
  }

  // If this is the users first visit, then the popup should take a minute
  // Otherwise any subsequent visits we don't want any delay
  const POPUP_DELAY = isSubsequentVisit ? 0 : INITIAL_DELAY

  const handleSetHasCookie = () => {
    Cookies.set(HIDE_POPUP_COOKIE, 'true')
  }

  const handlePopupClose = () => {
    setActive(!active)
    if (handleSetHasCookie) {
      handleSetHasCookie()
    }
  }

  const handleFormSuccess = () => {
    setSuccessfulSubmission(true)
    handleSetHasCookie()
  }

  const handleFormError = () => {
    setSubmissionError(true)
  }

  const getDelay = () => {
    if (showLeadPopup) {
      return 0
    }

    if (process.env.NEXT_PUBLIC_LEAD_FORM_DELAY) {
      return process.env.NEXT_PUBLIC_LEAD_FORM_DELAY
    }

    return POPUP_DELAY
  }

  return (
    <Box dir='ltr' id={id}>
      {active && (
        <>
          <MotionBox
            bgColor='rgba(0,0,0,0.54)'
            pos='fixed'
            top={0}
            boxSize='100%'
            display={{ sm: 'block', md: 'none' }}
            aria-label='background-overlay'
            zIndex={ZINDEX.FORM_POPUP}
            transition={{ delay: getDelay() }}
            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: 0 }}
            sx={{
              overflowX: 'hidden'
            }}
          />
          <MotionBox
            maxW={{ sm: '420px' }}
            pos='fixed'
            bottom={0}
            right={{ base: 0, md: 4 }}
            maxHeight={{ base: '85vh', md: '80vh' }}
            overflow='scroll'
            borderTopRadius={20}
            boxShadow='0px 5px 20px rgba(7, 25, 55, 0.1)'
            sx={{
              overflowX: 'hidden',
              '.grecaptchaBadge': 'visibility: hidden'
            }}
            background={'white'}
            initial={{ opacity: 0, y: '100%' }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: '100%' }}
            transition={{ delay: getDelay(), type: 'spring', duration: 0.8 }}
            zIndex={ZINDEX.FORM_POPUP + 1}
          >
            <Box
              backgroundColor='deepblue.500'
              color='white'
              p={4}
              position='sticky'
              top='0'
              zIndex={ZINDEX.FORM_POPUP + 2}
            >
              <Flex justifyContent='space-between' alignItems='flex-start'>
                {heading && (
                  <H3 mb={2}>
                    {successfulSubmission && !submissionError
                      ? heading.onSuccess
                      : heading.initial}
                  </H3>
                )}
                <Button
                  variant='unstyled'
                  boxSize={8}
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  aria-label='Close form'
                  icon={<CloseIcon boxSize={8} color={'white'} />}
                  onClick={handlePopupClose}
                />
              </Flex>
              {bodyText && (
                <Text mb={0} fontSize='sm'>
                  {successfulSubmission && !submissionError
                    ? bodyText.onSuccess
                    : bodyText.initial}
                </Text>
              )}
            </Box>
            {!successfulSubmission && !submissionError && (
              <Box padding={5}>
                {children({
                  handleFormSuccess,
                  handleFormError
                })}
              </Box>
            )}
            {successfulSubmission && !submissionError && successMessage && (
              <Box px={4} py={16} backgroundColor='white' textAlign='center'>
                <Box boxSize={16} color='success' marginBottom={8} mx='auto'>
                  <TickRoundedIcon />
                </Box>
                <H4>{successMessage.heading}</H4>
                <SmallText>{successMessage.bodyText}</SmallText>
              </Box>
            )}
            {submissionError && errorMessage && (
              <Box px={4} py={16} backgroundColor='white' textAlign='center'>
                <H4 color='error'>{errorMessage.heading}</H4>
                <SmallText color='error'>{errorMessage.bodyText}</SmallText>
              </Box>
            )}
          </MotionBox>
        </>
      )}
    </Box>
  )
}

export default FormPopup
