import { Box, Divider, Flex, Stack, Text } from '@chakra-ui/react'
import { FormLeadCapture_Swnz_FormLeadCaptureFragment } from 'content-service'
import { Formik } from 'formik'
import { WarningIcon } from 'icons'
import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import type { Utag } from 'tracking'
import { dispatchFormSubmissionUtagLinkEvent } from 'tracking'
import {
  Button,
  CheckboxControl,
  Form,
  H5,
  MultiCheckboxControl,
  postLeadCaptureFormSubmissionToLeadCaptureAPIEndpoint,
  reformatDateForSubmit,
  StackField,
} from 'ui'
import {
  getFormTextColour,
  getRegionOptions,
  regionMap,
  setFormBackgroundColor,
} from 'utils/src/helpers/leadCapture'
import { formTaxonomyData } from 'utils/src/helpers/taxonomy'
import * as Yup from 'yup'
import { LeadCaptureFormProps } from '../../../components/forms'
import { useUser } from '../../../context/user'
import { useTranslation } from '../../../hooks/useTranslation'
import { TransAgreements, TransFormINZMatchingPermissionOptIn } from '../TranslatedAgreements'

declare const utag: Utag

interface EducationFairFormValues {
  email: string
  firstName: string
  lastName: string
  mobile: string
  nationality?: string
  intendedLevelOfStudy: string
  desiredStartDate?: Date | string
  inzMatchingPermissionOptin: boolean
  stateRegion?: string
  dateOfBirth?: Date | string
  hearAboutFair?: string[]
  areaOfStudy?: string
  lineId?: string
  personType?: string
  fairLocation?: string
}

interface EducationFairFormProps extends LeadCaptureFormProps {
  inModal?: boolean
  contentful?: FormLeadCapture_Swnz_FormLeadCaptureFragment
}

const educationFairFormSchema = (
  t?: any,
  lang?: string
): Yup.ObjectSchema<EducationFairFormValues> => {
  return Yup.object()
    .shape(
      {
        firstName: Yup.string().required(t && t('first_name.required')),
        lastName: Yup.string().required(t && t('last_name.required')),
        email: Yup.string()
          .email(t && t('email_address.required'))
          .required(t && t('email_address.required')),
        mobile: Yup.string()
          .min(8, t && t('mobile_number.min'))
          .max(15, t && t('mobile_number.max'))
          .required(t && t('mobile_number.required')),
        nationality:
          lang === 'ko' ? Yup.string() : Yup.string().required(t && t('nationality.required')),
        stateRegion: Yup.string().when('nationality', {
          is: (nationality: string) =>
            nationality == 'china' || nationality == 'new-zealand' || nationality == 'india',
          then: (schema) => schema.required(t && t('region.required')),
        }),
        intendedLevelOfStudy: Yup.string().required(t && t('intended_level_of_study.required')),
        desiredStartDate: Yup.lazy((value) =>
          value === '' || value === undefined || value === null
            ? Yup.string().transform((value: string | null | undefined) => (value = ''))
            : Yup.date()
                .transform((value: Date) => (value ? new Date(value) : value))
                .test(
                  'year',
                  t && t('intended_study_date.error'),
                  (value: Date | undefined) =>
                    value !== undefined &&
                    value.getFullYear() >= 1000 &&
                    value.getFullYear() <= 9999
                )
                .min(new Date(), t && t('intended_study_date.min'))
                .typeError(t && t('intended_study_date.error'))
        ),
        inzMatchingPermissionOptin: Yup.boolean().oneOf([true, false]).defined(),
        fairLocation: Yup.string(),
        dateOfBirth: Yup.lazy((value) =>
          value === '' || value === undefined || value === null
            ? Yup.string().transform((value: string | null | undefined) => (value = ''))
            : Yup.date()
                .transform((value: Date) => (value ? new Date(value) : value))
                .max(new Date(), t && t('date_of_birth.max'))
                .typeError(t && t('date_of_birth.error'))
        ),
        hearAboutFair: Yup.array(),
        areaOfStudy: Yup.string(),
        lineId: t && lang === 'th' ? Yup.string().required(t('line_id.required')) : Yup.string(),
        personType: Yup.string(),
      },

      [
        ['desiredStartDate', 'desiredStartDate'],
        ['dateOfBirth', 'dateOfBirth'],
      ]
    )
    .noUnknown(true)
    .required()
}

const EducationFairForm = ({
  onSuccessCallback,
  onErrorCallback,
  tealium,
  successMessage,
  errorMessage,
  contentful,
  inModal = false,
}: EducationFairFormProps) => {
  const {
    userState: { profile },
  } = useUser()

  // Fetch our translations
  const { t, lang } = useTranslation('forms')

  // Set up contentful constants
  const {
    buttonLabel,
    citiesCollection,
    questionHowDidYouHearAboutTheFair,
    displayDateOfBirth,
    displayIntendedAreaOfStudy,
    displayLineIdField,
    displayPersonTypeIAmA,
    sourceId,
    theme,
  } = contentful || {}

  const bgColor = theme ? setFormBackgroundColor(theme, inModal) : 'white'
  const textColor = theme ? getFormTextColour(theme, inModal) : 'deepblue.500'
  const detailsTextColor = bgColor === 'deepblue.500' ? 'white' : 'gray.500'
  const dividerColor = bgColor === 'deepblue.500' ? 'white' : '#C4C4C4'

  const { items } = citiesCollection || {}

  const fairLocationOptions =
    items &&
    items.map((item) => {
      const { cityLabel, identifier } = item || {}
      return {
        key: identifier,
        label: cityLabel,
      } as { key: string; label: string }
    })

  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const defaultNationality = t('nationality.defaultValue') ?? ''

  const [successfulSubmission, setSuccessfulSubmission] = useState(false)
  const [submissionError, setSubmissionError] = useState(false)
  const [country, setCountry] = useState<string>(defaultNationality)
  const [region, setRegion] = useState<string | null>(regionMap[defaultNationality] ?? null)

  const taxonomy = formTaxonomyData(lang)

  const handleCountrySelect = (e: any) => {
    setCountry(e.target.value)
    setRegion(regionMap[e.target.value])
  }

  const handleFormSubmit = async (values: EducationFairFormValues) => {
    let tealiumCookie = ''
    let tealiumSessionId = ''
    const utagData: { [key: string]: any } | null = window?.utag?.data ?? null

    if (utagData && utagData?.tealium_visitor_id?.length > 0) {
      tealiumCookie = utagData.tealium_visitor_id
    }

    if (utagData && utagData?.tealium_session_id?.length > 0) {
      tealiumSessionId = utagData.tealium_session_id
    }

    const nationality = values.nationality
    const countryItem = taxonomy?.location?.find((country) => country.key == nationality) ?? ''
    const isoCode = countryItem ? countryItem.iso : ''
    const formattedStartDate = values.desiredStartDate
      ? reformatDateForSubmit(values.desiredStartDate)
      : ''

    let formData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      mobile: values.mobile,
      nationality: values.nationality,
      intendedLevelOfStudy: values.intendedLevelOfStudy,
      privacyPolicyAgreed: true,
      inzMatchingPermissionOptin: values.inzMatchingPermissionOptin,
      desiredStartDate: formattedStartDate,
      nationalityISOCode: isoCode,
      tealiumVisitorId: tealiumCookie,
      tealiumSessionId,
      commsOptin: true,
      source: sourceId || '',
      formSchemaName: 'educationFairFormSchema',
      fairLocation: values.fairLocation,
      dateOfBirth: values.dateOfBirth ? reformatDateForSubmit(values.dateOfBirth) : '',
      hearAboutFair: values.hearAboutFair,
      areaOfStudy: values.areaOfStudy,
      lineId: values.lineId,
      personType: values.personType,
    }

    // Check if the region has been added
    // If so we want to push this in to our formData object
    formData = {
      ...formData,
      ...(values.stateRegion && { stateRegion: values.stateRegion }),
    }

    postLeadCaptureFormSubmissionToLeadCaptureAPIEndpoint(formData, {
      success: () => {
        setSuccessfulSubmission(true)
        onSuccessCallback?.()
        utagData &&
          dispatchFormSubmissionUtagLinkEvent(
            tealium.formType,
            tealium.formName,
            window.location.href,
            `${profile?.studyInterests?.levelOfStudy}` || '',
            `${values.nationality}` || `${profile?.demographics?.nationality}` || ''
          )
      },
      error: () => {
        setSubmissionError(true)
        onErrorCallback?.()
      },
    })
  }

  return (
    <>
      <Formik
        initialValues={{
          email: '',
          firstName: '',
          lastName: '',
          mobile: '',
          nationality: defaultNationality,
          intendedLevelOfStudy: '',
          desiredStartDate: '',
          privacyPolicyAgreed: true,
          inzMatchingPermissionOptin: false,
          dateOfBirth: '',
          fairLocation: '',
          hearAboutFair: [],
          areaOfStudy: '',
          lineId: '',
          personType: '',
        }}
        validationSchema={educationFairFormSchema(t, lang)}
        onSubmit={handleFormSubmit}
      >
        {({ isValid, isSubmitting, dirty, values, errors }) => {
          return (
            <>
              <Divider borderColor={dividerColor} />
              <Form>
                <Stack
                  spacing={4}
                  alignItems="flex-start"
                  style={{ color: textColor }}
                  sx={{
                    '.chakra-form__error-message': {
                      textColor: bgColor === 'deepblue.500' ? 'white' : 'red.400',
                    },
                  }}
                >
                  {!isValid && (
                    <Flex
                      border="2px solid"
                      borderRadius="base"
                      borderColor="red.400"
                      backgroundColor="red.50"
                      padding={3}
                      lineHeight="1.125"
                      alignItems="center"
                    >
                      <Box boxSize={6} fill="red.400">
                        <WarningIcon />
                      </Box>
                      <Text fontFamily="enz400" mb="0" paddingLeft={2} color="grey.500">
                        {t('invalid_fields')}
                      </Text>
                    </Flex>
                  )}
                  <H5 mb={0}>{t('your_details')}:</H5>
                  <Stack spacing={6} alignItems="flex-start">
                    <Stack spacing="10px" width="100%">
                      <StackField>
                        <Form.FirstNameField label={t('first_name.label')} />
                        <Form.LastNameField label={t('last_name.label')} />
                      </StackField>
                      {displayDateOfBirth && (
                        <Form.DateOfBirthField label={t('date_of_birth.label')} />
                      )}
                      <Form.EmailAddressField label={t('email_address.label')} />
                      {displayPersonTypeIAmA && (
                        <Form.PersonTypeField
                          label={t('person_type.label')}
                          personTypeOptions={taxonomy.userType.filter(
                            (el) => !['enz', 'education-provider', 'other'].includes(el.key)
                          )}
                        />
                      )}
                      <StackField>
                        <Form.PhoneNumberField
                          name="mobile"
                          label={t('mobile_number.label')}
                          isRequired
                          country={t('mobile_number.country')}
                        />
                        <Form.LevelOfStudyField
                          name="intendedLevelOfStudy"
                          label={t('intended_level_of_study.label')}
                          levelOfStudyOptions={taxonomy.levelOfStudy}
                          isRequired
                        />
                      </StackField>
                      <StackField>
                        <Form.NationalityField
                          label={t('nationality.label')}
                          nationalityOptions={taxonomy.location}
                          onInput={(e: any) => handleCountrySelect(e)}
                          isRequired={lang !== 'ko'}
                        />
                        {country && Object.keys(regionMap).includes(country) && (
                          <Form.RegionField
                            isRequired
                            regionOptions={getRegionOptions(region, taxonomy)}
                            label={t('region.label')}
                          />
                        )}

                        <Form.StartDateField
                          name="desiredStartDate"
                          label={t('intended_study_date.label')}
                        />
                      </StackField>
                      {displayIntendedAreaOfStudy && (
                        <Form.AreaOfStudyField
                          label={t('area_of_study.label')}
                          areaOfStudyOptions={taxonomy.areaOfStudy}
                        />
                      )}
                      {fairLocationOptions && fairLocationOptions.length > 0 && (
                        <Form.SelectField
                          name="fairLocation"
                          label={t('fair_location.label')}
                          options={fairLocationOptions}
                          required
                        />
                      )}
                      {(displayLineIdField || lang === 'th') && (
                        <Form.LineIdField label={t('line_id.label')} isRequired={lang === 'th'} />
                      )}
                      {questionHowDidYouHearAboutTheFair?.dropdownOptionsCollection?.items
                        .length && (
                        <MultiCheckboxControl
                          label={questionHowDidYouHearAboutTheFair.label as string}
                          name="hearAboutFair"
                          checkboxOptions={questionHowDidYouHearAboutTheFair?.dropdownOptionsCollection?.items.map(
                            (item) => {
                              return {
                                key: item?.value as string,
                                label: item?.label as string,
                              }
                            }
                          )}
                          sx={{
                            py: 2,
                          }}
                        />
                      )}
                    </Stack>
                    <Divider borderColor={dividerColor} pt={2} />
                    <CheckboxControl
                      mt={1}
                      mr={2}
                      name="inzMatchingPermissionOptin"
                      extendedText={{
                        showMoreLabel: t('read_more'),
                        showLessLabel: t('read_less'),
                      }}
                    >
                      <TransFormINZMatchingPermissionOptIn color={detailsTextColor} />
                    </CheckboxControl>
                    <Box visibility="hidden">
                      {/* @ts-ignore https://github.com/dozoisch/react-google-recaptcha/issues/277 */}
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY ?? ''}
                        color={detailsTextColor}
                      />
                    </Box>
                  </Stack>
                  <Box>
                    <Button
                      type="submit"
                      isDisabled={!(dirty && isValid) || isSubmitting}
                      mb={3}
                      aria-label="submit"
                    >
                      {buttonLabel ? buttonLabel : 'Submit'}
                    </Button>
                  </Box>
                  <TransAgreements color={detailsTextColor} />
                </Stack>
              </Form>
            </>
          )
        }}
      </Formik>
    </>
  )
}

export default EducationFairForm
export { educationFairFormSchema }
