import { GridItem, useDisclosure } from '@chakra-ui/react'
import { useState } from 'react'
import { SearchState } from 'react-instantsearch-core'
import { PathwayEnquiryForm } from 'swnz/src/components/forms'
import { ALGOLIA_INDICES, ROUTES } from 'swnz/src/constants'
import {
  FinderWidget,
  FormModal,
  FORM_CONSTANTS,
  GridLayout,
  InstantSearchWrapper,
  SearchAutocomplete,
} from 'ui'
import { searchPlugins } from 'ui/src/components/Search/SearchAutocomplete'
import { createConfig } from 'utils/src/helpers/algoliaSearch'
import { formTaxonomyData } from 'utils/src/helpers/taxonomy'
import { uniqBy } from 'utils/src/helpers/uniq'

const { searchClient, indexName } = createConfig({
  indexName: ALGOLIA_INDICES.COURSE_FINDER.MAIN,
})

const countriesFacetPlugin = searchPlugins.pathwaysCountriesFacetPlugin({
  searchClient,
  indexName,
  resultsPagePath: ROUTES.COURSE.RESULTS,
  facet: 'countries.name',
})

const removeDuplicates = uniqBy(({ source, item }) =>
  source.sourceId === 'course' ? item.label : item.label
)

interface PathwaysFinderWidget {
  initialSearchState: SearchState
}

const PathwaysFinderWidget = ({ initialSearchState }: PathwaysFinderWidget) => {
  const taxonomy = formTaxonomyData()

  const [selectedCountry, setSelectedCountry] = useState<string | null>(null)

  const { isOpen, onOpen, onClose } = useDisclosure()

  // @ts-ignore
  const handleObjectSelect = ({ item }) => {
    // If there's a type on our item then we know it's an autocomplete item
    // so we want to handle this as normal and go to the link it has
    // otherwise the item is from our taxonomy and we want to handle it
    // differently
    if (!item.type) {
      setSelectedCountry(item.label as string)
      onOpen()
    }
  }

  const countriesSearchPlugin = searchPlugins.pathwaysCountriesPlugin({
    countries: taxonomy.location,
    onSelect: handleObjectSelect,
  })

  return (
    <>
      <InstantSearchWrapper
        searchClient={searchClient}
        indexName={indexName}
        searchState={initialSearchState}
      >
        <GridLayout>
          <GridItem colSpan={{ base: 12, lg: 8 }}>
            <FinderWidget heading="Find a course provider near you">
              <FinderWidget.Input width={{ base: 'full' }}>
                <SearchAutocomplete
                  title="Search your location"
                  placeholder="Search your location"
                  emptyQueryDropdownTitle="Search your location"
                  facet="name"
                  resultsPath={ROUTES.COURSE.RESULTS}
                  dropdownTitle="Select your location"
                  plugins={[countriesFacetPlugin, countriesSearchPlugin]}
                  // @ts-ignore
                  reshape={({ sourcesBySourceId }) => {
                    const { course, pathwaysCountries, ...rest } = sourcesBySourceId
                    // @ts-ignore
                    return [removeDuplicates(course, pathwaysCountries), Object.values(rest)]
                  }}
                  showLabel={false}
                  shouldRedirectOnSubmit={false}
                />
              </FinderWidget.Input>
            </FinderWidget>
          </GridItem>
        </GridLayout>
      </InstantSearchWrapper>
      <FormModal isOpen={isOpen} onClose={onClose}>
        <PathwayEnquiryForm
          heading={`Sorry, pathway courses aren’t available in ${selectedCountry} just yet.`}
          bodyText={`We’re releasing new courses all the time. Please leave us your details and we’ll keep you notified about courses and accredited study centres near you.`}
          successMessage={{
            heading: FORM_CONSTANTS.ENQUIRY.SUCCESS_MESSAGE.heading,
            bodyText: `We'll be in touch soon with pathway course updates near you.`,
          }}
          errorMessage={FORM_CONSTANTS.ENQUIRY.ERROR_MESSAGE}
        />
      </FormModal>
    </>
  )
}

export default PathwaysFinderWidget
