import LeadCaptureForm, { LeadCaptureFormProps, leadCaptureFormSchema } from './LeadCaptureForm'
import LeadCaptureFormPopup from './LeadCaptureFormPopup'
import LeadCapturePopupForm, { leadCapturePopupFormSchema } from './LeadCapturePopupForm'

export {
  LeadCaptureFormPopup,
  LeadCapturePopupForm,
  LeadCaptureForm,
  leadCaptureFormSchema,
  leadCapturePopupFormSchema,
}
export type { LeadCaptureFormProps }
