import FormAgreements, {
  AccountCommsOptIn,
  AccountPrivacyAndINZConsent,
  EnquiryFormCommsOptIn,
  EnquiryFormINZConsent,
  EnquiryFormPrivacyConsent,
  FormINZMatchingPermissionOptIn
} from './FormAgreements'

export {
  FormAgreements,
  AccountPrivacyAndINZConsent,
  AccountCommsOptIn,
  FormINZMatchingPermissionOptIn,
  EnquiryFormPrivacyConsent,
  EnquiryFormINZConsent,
  EnquiryFormCommsOptIn
}
