import { HeroMediaItem_Swnz_HeroMediaItemFragment } from 'content-service'

export const createHeroVideoProps = (
  mediaItem: HeroMediaItem_Swnz_HeroMediaItemFragment | undefined | null
) => {
  const videoProps = {
    url: '',
    buttonLabel: 'Watch video'
  }

  if (mediaItem && mediaItem.__typename === 'SWNZ_MediaVideo') {
    videoProps.url = mediaItem.sourceUrl || ''
    videoProps.buttonLabel = mediaItem.caption || ''
  }

  return videoProps
}
