import { Box } from '@chakra-ui/react'
import { SearchIcon } from 'icons'
import {
  connectStateResults,
  StateResultsProvided
} from 'react-instantsearch-core'
import { createURL } from 'utils/src/helpers/algoliaSearch'
import { Button } from '../../buttons'

interface FinderSearchButtonProps extends StateResultsProvided {
  resultsPagePath: string
}

const FinderSearchButton = connectStateResults(
  ({ resultsPagePath, searchState = {} }: FinderSearchButtonProps) => {
    const searchStateToURLParams = createURL(searchState, false)
    return (
      <Button
        href={{ pathname: resultsPagePath, query: searchStateToURLParams }}
        rightIcon={
          <Box boxSize={{ base: 7, lg: 5 }}>
            <SearchIcon />
          </Box>
        }
        width='full'
        aria-label='Search button'
        height={'60px'}
      >
        Search
      </Button>
    )
  }
)

export default FinderSearchButton
