import { Box, Flex, Text } from '@chakra-ui/react'
import { ChevronCircle } from 'icons'
import useTranslation from 'next-translate/useTranslation'
import React from 'react'
import { Link } from 'ui'
interface BreadcrumbNavBackButtonProps {
  title: string
  slug: string
}

const BreadcrumbNavBackButton = ({
  title,
  slug
}: BreadcrumbNavBackButtonProps) => {
  const { t } = useTranslation('navigation')

  return (
    <Flex
      height='full'
      position='relative'
      bottom='0'
      display='inline-flex'
      paddingRight={4}
      width='auto'
      sx={{
        'html[lang=ar] &': {
          /* GOTCHA!
           * This is acutally align 'left'
           * stylis RTL plugin changes this to 'left' for us */
          paddingLeft: '4',
          paddingRight: '0'
        }
      }}
    >
      <Link
        href={slug}
        color='white'
        textDecoration='none'
        display='flex'
        aria-label={title}
        _hover={{ backgroundColor: 'none' }}
        _after={{
          content: `''`,
          height: '100%',
          width: { base: '1px', xl: 0 },
          backgroundColor: '#39475F',
          position: 'absolute',
          right: 0,
          'html[lang=ar] &': {
            left: 0,
            right: 'unset'
          }
        }}
      >
        <Box
          boxSize='30px'
          alignSelf='center'
          transform='rotate(180deg)'
          _hover={{
            background: 'white',
            color: 'deepblue.600',
            cursor: 'pointer',
            borderRadius: '50%',
            transitionDuration: 'fast',
            transitionProperty: 'background-color',
            transitionTimingFunction: 'ease-out'
          }}
          sx={{
            '&:hover': {
              path: {
                _first: {
                  fill: 'white'
                },
                _last: {
                  fill: 'deepblue.600'
                }
              }
            },
            'html[lang=ar] &': {
              svg: {
                transform: 'scaleX(-1)'
              }
            }
          }}
        >
          <ChevronCircle />
        </Box>
        <Flex
          alignItems='center'
          marginLeft={4}
          display={{ base: 'none', md: 'inline-flex' }}
          sx={{
            'html[lang=ar] &': {
              /* GOTCHA!
               * This is acutally align 'left'
               * stylis RTL plugin changes this to 'left' for us */
              marginLeft: '0',
              marginRight: '4'
            }
          }}
        >
          <Text
            margin='0'
            padding='0'
            whiteSpace='nowrap'
            display='inline-flex'
            justifyContent='space-around'
            flexDirection='column'
            height={7}
            sx={{
              // Actually right align
              'html[lang=ar] &': {
                textAlign: 'left'
              }
            }}
          >
            <Text
              as='span'
              display='block'
              margin='0'
              lineHeight='0'
              padding='0'
              fontSize='12px'
            >
              {t('breadcrumb_nav_return_action')}
            </Text>
            <Text
              as='span'
              margin='0'
              padding='0'
              lineHeight='0'
              fontFamily='enz500'
              fontSize='16px'
            >
              {title}
            </Text>
          </Text>
        </Flex>
      </Link>
    </Flex>
  )
}

export default BreadcrumbNavBackButton
export type { BreadcrumbNavBackButtonProps }
