const ALGOLIA_INDICES = {
  COURSE_FINDER: {
    MAIN: 'course',
    ASCENDING: 'course_ascending',
    DESCENDING: 'course_descending',
  },
  PROVIDER_FINDER: {
    MAIN: 'provider',
    ASCENDING: 'provider_ascending',
    DESCENDING: 'provider_descending',
  },
  SCHOLARSHIP_FINDER: {
    MAIN: 'scholarship',
    ASCENDING: 'scholarship_ascending',
    DESCENDING: 'scholarship_descending',
  },
}

export default ALGOLIA_INDICES
