import Cookies from 'js-cookie'
import { useRouter } from 'next/router'
import { MouseEvent, useEffect, useState } from 'react'
import {
  connectRefinementList,
  RefinementListProvided
} from 'react-instantsearch-core'
import { COOKIE } from 'swnz/src/constants'
import { StaticItemRefinementList } from '../FacetRefinement/StaticFacetRefinement'
import ResultsFilterModalUI from './ResultsFilterModalUI'
interface ResultsFilterModalProps extends RefinementListProvided {
  totalHits: number
  staticItems: StaticItemRefinementList[] | []
}

const Component = ({
  totalHits,
  staticItems,
  items = [],
  currentRefinement,
  refine
}: ResultsFilterModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isValid, setIsValid] = useState<boolean>(false)
  const itemsReturningResults = items.filter((item) => item.count)
  // let ResultsFilterModalUI: ElementType | null = null
  const router = useRouter()

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    refine([event.currentTarget.value])
    setIsOpen(false)
  }

  const handleOnClose = () => {
    setIsOpen(false)
  }

  useEffect(() => {
    setIsOpen(
      router.query?.loginType || Cookies.get(COOKIE.SET_LOGIN_DATA)
        ? false
        : true
    )
  }, [router.query?.loginType])

  // Only import ResultsFilterModalUI if:
  // nothing currently refined (currentRefinement) &&
  // only 1 item returning search results &&
  // total search result hits less than 300
  useEffect(() => {
    if (
      !currentRefinement.length &&
      itemsReturningResults.length > 1 &&
      totalHits > 300
    ) {
      setIsValid(true)
    }
  }, [currentRefinement, itemsReturningResults, totalHits])

  if (isValid) {
    // We want to display facet fields with no matches but algolia will hide those fields.
    // To show no results we need to merge a static list of items. The approach was taken from
    // https://www.algolia.com/doc/guides/building-search-ui/widgets/customize-an-existing-widget/react/#display-facets-with-no-matches
    const mergedItems = staticItems.map(
      (staticItem: StaticItemRefinementList) => {
        const { label, value } = staticItem
        // Either get the values from the refined algolia facets or if the item doesn't exist set default
        const { isRefined, count } = items.find(
          (item) => item.label == value
        ) || {
          count: 0,
          isRefined: false
        }

        return {
          count: count,
          isRefined: isRefined,
          label,
          value
        }
      }
    )

    return (
      <ResultsFilterModalUI
        totalHits={totalHits}
        items={mergedItems}
        isOpen={isOpen}
        handleButtonClick={handleButtonClick}
        handleOnClose={handleOnClose}
      />
    )
  }

  return null
}

const ResultsFilterModal = connectRefinementList(Component)
export { Component }
export default ResultsFilterModal
