import {
  Box,
  BoxProps,
  ContainerProps,
  GridItem,
  GridItemProps,
  Text
} from '@chakra-ui/react'
import { TickRoundedIcon } from 'icons'
import { ReactNode, useState } from 'react'
import {
  getFormTextColour,
  setFormBackgroundColor
} from 'utils/src/helpers/leadCapture'
import { ContainerWrapper, GridLayout } from '../../components/layout'
import { H3, H4, SmallText } from '../../components/typography'

type FormElementWrapperProps = Omit<BoxProps, 'children'> & {
  children: ({
    handleFormSuccess,
    handleFormError
  }: {
    handleFormSuccess: () => void
    handleFormError: () => void
  }) => ReactNode
  inModal?: boolean
  isEducationFairForm?: boolean
  heading?: {
    initial: string
    onSuccess: string
  }
  bodyText?: {
    initial: string
    onSuccess: string
  }
  successMessage?: {
    heading?: string
    bodyText?: string
  }
  errorMessage?: {
    heading?: string
    bodyText?: string
  }
  theme?: string
  containerStyles?: Pick<ContainerProps, 'sx'>
  gridHeaderStyles?: GridItemProps
  gridItemStyles?: GridItemProps
}

const FormElementWrapper = ({
  children,
  inModal = false,
  heading,
  bodyText,
  successMessage,
  errorMessage,
  isEducationFairForm,
  theme,
  gridHeaderStyles,
  gridItemStyles,
  ...props
}: FormElementWrapperProps) => {
  const bgColor = setFormBackgroundColor(theme, inModal)
  const textColor = getFormTextColour(theme, inModal)

  const [successfulSubmission, setSuccessfulSubmission] =
    useState<boolean>(false)
  const [submissionError, setSubmissionError] = useState<boolean>(false)

  const containerStyles = inModal
    ? { padding: 0, margin: 0, mx: 0, px: 0, py: 0, pt: 0, pb: 0 }
    : isEducationFairForm
      ? { py: { base: '60px', lg: '80px' } }
      : {}

  const gridHeadStyles = inModal
    ? { colSpan: 16, colStart: 1, pt: 5, ...gridHeaderStyles }
    : { colSpan: { base: 4, md: 8, lg: 12 }, ...gridHeaderStyles }
  const gridStyles = inModal
    ? { colSpan: 16, colStart: 1, pt: 5 }
    : {
        colSpan: { base: 4, md: 8 },
        colStart: { base: 1, lg: 3 },
        ...gridItemStyles
      }

  const handleFormSuccess = () => setSuccessfulSubmission(true)
  const handleFormError = () => setSubmissionError(true)

  return (
    <ContainerWrapper sx={containerStyles} backgroundColor={bgColor} {...props}>
      <GridLayout gridTemplateRows='auto'>
        {(heading || bodyText) && (
          <GridItem {...gridHeadStyles} gridRowStart={1} gridRowEnd={2}>
            <Box
              color={textColor}
              backgroundColor={bgColor}
              textAlign={isEducationFairForm ? 'center' : 'left'}
            >
              {heading &&
                (inModal ? (
                  <H3 mb={2}>
                    {successfulSubmission && !submissionError
                      ? heading.onSuccess
                      : heading.initial}
                  </H3>
                ) : (
                  <H4 mb={2}>
                    {successfulSubmission && !submissionError
                      ? heading.onSuccess
                      : heading.initial}
                  </H4>
                ))}
              {bodyText && (
                <Text mb={0} fontSize={inModal ? 'sm' : 'md'}>
                  {successfulSubmission && !submissionError
                    ? bodyText.onSuccess
                    : bodyText.initial}
                </Text>
              )}
            </Box>
          </GridItem>
        )}
        <GridItem {...gridStyles} gridRowStart={2} gridRowEnd={3}>
          {!successfulSubmission &&
            !submissionError &&
            children({
              handleFormSuccess,
              handleFormError
            })}
          {successfulSubmission && !submissionError && successMessage && (
            <Box
              px={4}
              py={16}
              backgroundColor={isEducationFairForm ? bgColor : 'white'}
              textAlign='center'
            >
              <Box boxSize={16} color='success' marginBottom={8} mx='auto'>
                <TickRoundedIcon />
              </Box>
              <H4 color={isEducationFairForm ? textColor : 'deepblue.500'}>
                {successMessage.heading}
              </H4>
              <SmallText
                color={isEducationFairForm ? textColor : 'deepblue.500'}
              >
                {successMessage.bodyText}
              </SmallText>
            </Box>
          )}
          {submissionError && errorMessage && (
            <Box
              px={4}
              py={16}
              backgroundColor={isEducationFairForm ? bgColor : 'white'}
              textAlign='center'
            >
              <H4 color='error'>{errorMessage.heading}</H4>
              <SmallText color='error'>{errorMessage.bodyText}</SmallText>
            </Box>
          )}
        </GridItem>
      </GridLayout>
    </ContainerWrapper>
  )
}

export default FormElementWrapper
