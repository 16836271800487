import { Flex, FlexProps } from '@chakra-ui/react'
import React from 'react'

interface StackFieldProps extends FlexProps {
  spacing?: 'sm' | 'lg'
}

const StackField = ({ sx, spacing = 'sm', ...props }: StackFieldProps) => (
  <Flex
    width='full'
    flexDirection={{ base: 'column', lg: 'row' }}
    sx={{
      '> div': {
        mb: { base: spacing === 'sm' ? 4 : 6, lg: 0 },
        mr: { lg: spacing === 'sm' ? 4 : 6 },
        _last: {
          m: 0
        },
        'html[lang=ar] &': {
          mr: 'unset',
          ml: { lg: spacing === 'sm' ? 4 : 6 },
          _last: {
            m: 0
          }
        }
      },
      ...sx
    }}
    {...props}
  />
)

export default StackField
