const createCanonicalUrl = (
  asPath: string,
  locales: string[],
  defaultLocale: string,
  locale?: string
) => {
  const baseUrl = `${process.env.NEXT_PUBLIC_URL ?? ''}`

  const basePath =
    locales.length < 2
      ? baseUrl
      : !locale || locale === defaultLocale
        ? `${baseUrl}/en`
        : `${baseUrl}/${locale}`

  return asPath === '/' ? basePath : `${basePath}${asPath}`
}

export { createCanonicalUrl }
