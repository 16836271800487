import { Box, Flex, Image } from '@chakra-ui/react'
import { TickRoundedIcon } from 'icons'
import { ReactElement } from 'react'
import { LargeText } from 'ui'

export interface BulletItemsProps {
  bulletItemsToRenderArray?: BulletItem[] | undefined
}

export interface BulletItem {
  bodyText?: ReactElement | null
  iconUrl?: string
  imageName?: string
}

const BulletItems = ({
  bulletItemsToRenderArray
}: BulletItemsProps): ReactElement => {
  return (
    <Box sx={{ columnCount: { base: 1, lg: 2 }, columnGap: '16px' }}>
      {bulletItemsToRenderArray &&
        bulletItemsToRenderArray.map((bulletItem: BulletItem, index) => {
          const { bodyText, iconUrl, imageName } = bulletItem
          return (
            <Box
              key={index}
              mb={9}
              pr={{ lg: 20 }}
              display='inline-block'
              w='full'
              sx={{
                pageBreakInside: 'avoid',
                breakInside: 'avoid',
                wordWrap: 'break-word'
              }}
            >
              <Flex>
                {iconUrl ? (
                  <Box width='48px' minWidth='48px' mr={6}>
                    <Image src={iconUrl} alt={imageName} width='100%' />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      svg: {
                        width: { base: 10, lg: 12 },
                        fill: 'white'
                      }
                    }}
                    color='teal.500'
                    mr={6}
                  >
                    <TickRoundedIcon />
                  </Box>
                )}
                {bodyText && <LargeText mb={0}>{bodyText}</LargeText>}
              </Flex>
            </Box>
          )
        })}
    </Box>
  )
}

export default BulletItems
