export enum Environment {
  TEST = 'test',
  DEV = 'development',
  STAGING = 'staging',
  UAT = 'uat',
  PROD = 'production'
}

export default function environment() {
  const publicEnv = process.env.NEXT_PUBLIC_ENVIRONMENT ?? process.env.NODE_ENV
  return stringToEnvironmentEnum(publicEnv)
}

export function stringToEnvironmentEnum(string?: string) {
  switch (string) {
    case 'test':
      return Environment.TEST
    case 'development':
      return Environment.DEV
    case 'staging':
      return Environment.STAGING
    case 'uat':
      return Environment.UAT
    case 'production':
      return Environment.PROD
    default:
      const envValues: string = JSON.stringify(Object.values(Environment))
      throw new Error(
        `Please set a valid server environment. Set NEXT_PUBLIC_ENVIRONMENT to be one of ${envValues}`
      )
  }
}

export function isEnv(env: Environment): boolean {
  return environment() === env
}
