import { useAuth0 } from '@auth0/auth0-react'
import { Box, Flex } from '@chakra-ui/react'
import { EnquiryIcon, FavouritedIcon, LogoutIcon, RecommendationsIcon } from 'icons'
import { ReactElement } from 'react'
import { NavItem, NavLink, removeLoginDataCookie } from 'ui'
import { ROUTES } from '../../constants'

const ProfileNav = (): ReactElement => {
  const { logout } = useAuth0()

  const handleLogoutClick = () => {
    removeLoginDataCookie()
    logout({
      logoutParams: {
        returnTo: process.env.NEXT_PUBLIC_URL ?? '',
      },
    })
  }

  const profileLinks: Array<NavLink> = [
    {
      id: 'recommendations',
      slug: ROUTES.RECOMMENDATIONS,
      title: 'Recommendations',
      icon: <RecommendationsIcon width={24} />,
    },
    {
      id: 'favourites',
      slug: ROUTES.FAVOURITES,
      title: 'Favourites',
      icon: <FavouritedIcon width={24} />,
    },
    {
      id: 'enquired',
      slug: ROUTES.ENQUIRIES,
      title: 'My enquiries',
      icon: <EnquiryIcon width={24} />,
    },
    {
      id: 'profile',
      slug: ROUTES.PROFILE,
      title: 'Your Profile',
    },
    {
      id: 'logout',
      slug: '/dashboard#logout',
      title: 'Sign out',
      icon: <LogoutIcon width={24} />,
      action: handleLogoutClick,
    },
  ]

  return (
    <Flex as="nav" flexDir="column">
      {profileLinks.map((link) => (
        <Box key={link.id}>
          <Box pl={4} autoFocus={true}>
            <NavItem link={link} />
          </Box>
        </Box>
      ))}
    </Flex>
  )
}

export default ProfileNav
