// Cache constants
const MAX_VERCEL_CACHE_DAYS = 31
const WEEK_CACHE_DAYS = 7
const DAY_TO_SECONDS = 24 * 60 * 60

// 31 days in seconds
const CACHE_EXPIRATION_TIME = {
  MAX: MAX_VERCEL_CACHE_DAYS * DAY_TO_SECONDS,
  WEEK: WEEK_CACHE_DAYS * DAY_TO_SECONDS
}

export default CACHE_EXPIRATION_TIME
