import {
  GalleryCarousel_Swnz_GalleryCarouselFragment,
  GalleryCarousel_Swnz_GalleryCarouselFragmentDoc
} from 'content-service'
import React, { ReactElement } from 'react'
import useFragment from 'utils/src/hooks/useFragment'
import { ContainerWrapper } from '../../components'
import { Gallery } from '../../components/content-components'
import { Connector } from '../types'
import { createGalleryItems } from './helpers/galleryCarousel'

export type GalleryCarouselConnectProps = Connector

const GalleryCarouselConnect: React.FC<GalleryCarouselConnectProps> = ({
  id,
  typename
}): ReactElement | null => {
  const galleryCarousel =
    useFragment<GalleryCarousel_Swnz_GalleryCarouselFragment>({
      id,
      typename,
      fragment: GalleryCarousel_Swnz_GalleryCarouselFragmentDoc,
      fragmentName: 'galleryCarousel_SWNZ_GalleryCarousel'
    })

  if (!galleryCarousel) {
    return null
  }

  const items = createGalleryItems({
    galleryItems: galleryCarousel?.mediaItemsCollection?.items
  })

  return (
    <ContainerWrapper>
      <Gallery galleryItems={items} />
    </ContainerWrapper>
  )
}

export default GalleryCarouselConnect
