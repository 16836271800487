import { Box } from '@chakra-ui/react'
import React from 'react'

interface ThemeBackgroundGraphicProps {
  theme?: string
  alignment?: string
}

const ThemeBackgroundGraphic = ({
  theme,
  alignment
}: ThemeBackgroundGraphicProps) => {
  let bgGraphicWrapperStyles = {}
  let bgGraphicStyles = {}

  if (theme === 'campaign-garb') {
    bgGraphicWrapperStyles = {
      position: 'absolute',
      zIndex: 0,
      inset: 0,
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      opacity: '0.5',
      maxWidth: '1312px',
      my: 0,
      mx: 'auto'
    }

    bgGraphicStyles =
      alignment === 'centered'
        ? {
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            backgroundImage: 'url(/images/poutama.svg)',
            backgroundPosition: 'center',
            maxHeight: '272px',
            boxSizing: 'content-box',
            '@media screen and (min-width: 768px)': {
              maxHeight: '467px'
            }
          }
        : {
            width: '100%',
            height: '100%',
            backgroundRepeat: 'no-repeat',
            boxSizing: 'content-box',
            backgroundImage: 'url(/images/poutama-half.svg)',
            backgroundPosition: 'right', // don't need to change when alignment's left due to line below
            transform: alignment === 'left' ? 'scaleX(-1)' : '', // horizontally flip the div
            maxHeight: '358px',
            '@media screen and (min-width: 768px)': {
              maxHeight: '597px'
            }
          }
  }

  return (
    <Box
      sx={{
        ...bgGraphicWrapperStyles
      }}
    >
      <Box
        sx={{
          ...bgGraphicStyles
        }}
      />
    </Box>
  )
}

export default ThemeBackgroundGraphic
