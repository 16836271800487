import {
  Box,
  Flex,
  FlexProps,
  Image,
  ListItem,
  Stack,
  Text,
  TextProps,
  UnorderedList
} from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { DashboardEnquiryListIcon, DashboardSavedListIcon } from 'icons'
import { ReactNode, useState } from 'react'
import { ImageLoadingPreference, SvgrComponent } from 'utils'
import { useMediaQuery } from 'utils/src/helpers'
import { StudyOptionStatus } from '../../../forms'
import { Button, CtaButton } from '../../buttons'
import { Link } from '../../Link'
import { MediumBoldText } from '../../typography'

interface FinderResultItemCtaButton {
  href?: string
  label?: string
  openInNewWindow?: boolean
}
interface DetailItem {
  label: string
  value: string
}

export enum FinderResultIntroLength {
  SHORT = 220,
  LONG = 440
}
export interface FinderResultProps extends FlexProps {
  children: ReactNode
  rowLayout?: boolean
  hide?: boolean
}

export interface FinderResultItemLeftContentProps {
  matchScore?: string
  itemStatus?: string
}

export interface FinderResultItemLeftContentIconProps {
  Icon: SvgrComponent
  marginTop?: number
}
export interface FinderResultLogoProps {
  provider?: string
  logoUrl: string
}
export interface FinderResultMainContentProps {
  children: ReactNode
}
export interface FinderResultTitleProps {
  title: string
  link: string
  subTitle?: string
  additionalSubTitle?: string
  customFontWeight?: string
  customMarginTop?: { base: number; lg: number }
  customFontSize?: { base: string; lg: string }
  customLineHeight?: { base: string; lg: string }
}
export interface FinderResultSubtitleProps {
  subTitle?: string
  marginTop?: number
}
export interface FinderResultDescriptionProps extends TextProps {
  intro: string
  introLength?: number
  customTitleFontSize?: string
  customTitleLineHeight?: string
  customTitleFontColor?: string
}

export interface FinderResultURLProps {
  label?: string
  url: string
  title: string
}
export interface FinderResultDetailItemsProps {
  detailItems: DetailItem[]
}
export interface FinderResultItemDividerProps {
  color?: string
}
export interface FinderResultButtonsProps {
  children: ReactNode
  wrap?: boolean
}
export interface FinderResultItemDetailsButtonProps {
  link: string
  detailCta: FinderResultItemCtaButton
}
export interface FinderResultItemRemoveButtonProps {
  itemStatus: string
  handleClick: () => void
}
export interface FinderResultItemOptionalButtonProps {
  optionalLink: FinderResultItemCtaButton
  Icon?: SvgrComponent
}

export interface FinderResultLastContactedProps {
  lastContacted: string
}

const FinderResultItem = ({
  children,
  rowLayout = false,
  hide,
  ...props
}: FinderResultProps) => {
  const isDesktop = useMediaQuery('(min-width: 992px)')
  const variants = isDesktop
    ? {
        // Desktop Animation Variant
        still: { transform: 'translateX(0)' },
        slideOut: { transform: 'translateX(100vw)' }
      }
    : {
        // Mobile Animation Variant
        still: { opacity: 1 },
        slideOut: { opacity: 0 }
      }

  return (
    <Flex
      as={motion.div}
      transition={hide ? '.2s linear' : 'none'}
      animate={hide ? 'slideOut' : 'still'}
      variants={variants}
      justifyContent={{ md: 'space-between' }}
      flexDirection={{ base: 'column', md: rowLayout ? 'row' : 'column' }}
      background='white'
      borderRadius='20px'
      px={{ base: 6, lg: 10 }}
      py={{ base: 6, lg: 8 }}
      mb={{ base: 6, lg: 8 }}
      {...props}
    >
      {children}
    </Flex>
  )
}

FinderResultItem.LeftContent = function FinderResultItemLeftContent({
  matchScore = '0%',
  itemStatus
}: FinderResultItemLeftContentProps) {
  return (
    <>
      {itemStatus === StudyOptionStatus.Saved ? (
        <FinderResultItem.LeftContentIcon Icon={DashboardSavedListIcon} />
      ) : itemStatus === StudyOptionStatus.Contacted ? (
        <FinderResultItem.LeftContentIcon
          Icon={DashboardEnquiryListIcon}
          marginTop={7.5}
        />
      ) : (
        <Box
          w={{ base: '60px', lg: '80px' }}
          h={{ base: '60px', lg: '80px' }}
          minWidth={{ base: '60px', lg: '80px' }}
          borderRadius='50%'
          borderWidth={{ base: '3.5px', lg: '5px' }}
          borderColor='surfaceBlue'
          marginRight='22px'
          marginBottom={{ base: '8px', lg: 0 }}
          display='flex'
          textAlign='center'
          justifyContent='center'
          alignItems='center'
        >
          <Text
            color='surfaceBlue'
            fontFamily='enz700'
            fontSize={{ lg: '22px' }}
            margin='0'
          >
            {matchScore}
          </Text>
        </Box>
      )}
    </>
  )
}

FinderResultItem.LeftContentIcon = function FinderResultItemLeftContentIcon({
  Icon,
  marginTop
}: FinderResultItemLeftContentIconProps) {
  return (
    <Box
      marginRight='22px'
      marginTop={marginTop ? `${marginTop}px` : 0}
      marginBottom={{ base: '12px', md: 0 }}
    >
      <Icon />
    </Box>
  )
}

FinderResultItem.Logo = function FinderResultItemLogo({
  provider,
  logoUrl
}: FinderResultLogoProps) {
  return (
    <Box
      position='absolute'
      top={0}
      right={{ base: 0, lg: 4 }}
      background='white'
      overflow={{ base: 'hidden', lg: 'unset' }}
      sx={{
        'html[lang=ar] &': {
          left: 0,
          right: 'unset'
        }
      }}
    >
      <Image
        onError={(event) => {
          event.currentTarget.style.display = 'none'
        }}
        boxSize={{ base: '60px', lg: '80px' }}
        lineHeight='1.2'
        fontSize='0.925rem'
        src={logoUrl}
        alt={`${provider} logo`}
        objectFit='contain'
        loading={ImageLoadingPreference.LAZY}
        sx={{
          'html[lang=ar] &': {
            right: 'unset',
            left: { lg: 16 }
          }
        }}
      />
    </Box>
  )
}

FinderResultItem.MainContent = function FinderResultItemMainContent({
  children
}: FinderResultMainContentProps) {
  return <Stack spacing={2}>{children}</Stack>
}

FinderResultItem.Title = function FinderResultItemTitle({
  title,
  subTitle,
  additionalSubTitle,
  link,
  customFontWeight,
  customMarginTop,
  customFontSize,
  customLineHeight
}: FinderResultTitleProps) {
  const [isActive, setActive] = useState(false)
  const onHover = () => setActive(!isActive)

  return (
    <Stack w='full' maxW='75%' spacing={2}>
      <CtaButton
        href={link}
        variant='link'
        color='surfaceBlue'
        borderRadius='0'
        fontFamily='enz500'
        fontWeight={customFontWeight ? customFontWeight : undefined}
        fontSize={
          customFontSize ? customFontSize : { base: '20px', lg: '22px' }
        }
        lineHeight={customLineHeight ? customLineHeight : { lg: '32px' }}
        letterSpacing='-1px'
        mb={subTitle || customMarginTop ? 0 : 4}
        mt={customMarginTop ? customMarginTop : 1}
        onMouseOver={onHover}
        onMouseOut={onHover}
        display='block'
        whiteSpace='normal'
        aria-label={title}
        textAlign='left'
        height='auto'
        sx={{
          'html:not([lang=en]) &': {
            fontWeight: '500'
          },
          'html[lang=ar] &': {
            // actually left
            textAlign: 'right'
          }
        }}
      >
        {title}
      </CtaButton>
      {subTitle && <FinderResultItem.Subtitle subTitle={subTitle} />}
      {additionalSubTitle && (
        <FinderResultItem.Subtitle
          subTitle={additionalSubTitle}
          marginTop={0}
        />
      )}
    </Stack>
  )
}

FinderResultItem.Subtitle = function FinderResultItemSubtitle({
  subTitle,
  marginTop = 2
}: FinderResultSubtitleProps) {
  return <MediumBoldText marginTop={marginTop}>{subTitle}</MediumBoldText>
}

FinderResultItem.Description = function FinderResultItemDescription({
  intro,
  introLength = FinderResultIntroLength.SHORT,
  customTitleFontSize,
  customTitleLineHeight,
  customTitleFontColor,
  ...props
}: FinderResultDescriptionProps) {
  const formatIntroHtmlString = (
    intro = '',
    introLength: FinderResultIntroLength
  ): string => {
    if (typeof window !== undefined) {
      const container = document.createElement('div')
      container.innerHTML = intro
      const text = container.textContent || container.innerText || ''

      return text.length > introLength
        ? `${text.substring(0, introLength)}...`
        : text
    }

    return ''
  }

  return (
    <Text
      maxW={{ md: '85%', lg: '80%' }}
      fontSize={customTitleFontSize ? customTitleFontSize : undefined}
      lineHeight={customTitleLineHeight ? customTitleLineHeight : undefined}
      {...props}
      textColor={customTitleFontColor ? customTitleFontColor : undefined}
    >
      {formatIntroHtmlString(intro, introLength)}
    </Text>
  )
}

FinderResultItem.URL = function FinderResultItemDescription({
  label,
  url,
  title
}: FinderResultURLProps) {
  return (
    <Link href={url} aria-label={title} maxW={{ md: '85%', lg: '80%' }}>
      {label ? label : url}
    </Link>
  )
}

FinderResultItem.DetailItems = function FinderResultItemDetailItems({
  detailItems
}: FinderResultDetailItemsProps) {
  return (
    <UnorderedList
      w='full'
      maxW='75%'
      styleType='none'
      ml={0}
      mt={3}
      fontFamily='enz500'
      lineHeight='1.5'
      fontSize='sm'
      sx={{
        'html[lang=ar] &': {
          mr: 0
        }
      }}
    >
      {detailItems &&
        detailItems.map((item: DetailItem, index: number) => (
          <ListItem key={index}>
            {item.label}: {item.value}
          </ListItem>
        ))}
    </UnorderedList>
  )
}

FinderResultItem.Divider = function FinderResultItemDivider({
  color = 'deepblue.100'
}: FinderResultItemDividerProps) {
  return <Box borderWidth='1px' borderColor={color} mt={6} mb={6} />
}

FinderResultItem.Buttons = function FinderResultItemButtons({
  children,
  wrap
}: FinderResultButtonsProps) {
  return (
    <Stack
      order={{ md: 2 }}
      direction={{ base: 'column', md: wrap ? 'column' : 'row', lg: 'row' }}
      spacing='10px'
      pt={6}
    >
      {children}
    </Stack>
  )
}

FinderResultItem.DetailsButton = function FinderResultItemDetailsButton({
  link,
  detailCta
}: FinderResultItemDetailsButtonProps) {
  return (
    <CtaButton
      href={link}
      variant='outline'
      openInNewWindow={detailCta.openInNewWindow}
      aria-label={detailCta.label}
      size='sm'
      isExternalLink={true}
      externalIconSize={'20px'}
    >
      {detailCta.label ? detailCta.label : 'See details'}
    </CtaButton>
  )
}

FinderResultItem.RemoveButton = function FinderResultItemRemoveButton({
  itemStatus,
  handleClick
}: FinderResultItemRemoveButtonProps) {
  return (
    <Box
      display='flex'
      justifyContent='center'
      alignItems='center'
      paddingTop='10px'
      paddingBottom={{ base: 0, lg: '10px' }}
      paddingLeft={{ lg: '12px' }}
      marginLeft={{ lg: 0 }}
      maxWidth={{ base: 'none', lg: '192px' }}
    >
      <Button
        variant='link'
        onClick={handleClick}
        fontFamily={'enz400'}
        fontSize={'14px'}
        lineHeight={'24px'}
        color='deepblue.300'
        textDecoration='underline'
        textAlign='center'
      >
        {itemStatus === StudyOptionStatus.Suggested &&
          'Remove from recommended'}
        {itemStatus === StudyOptionStatus.Saved && 'Remove from favourites'}
        {itemStatus === StudyOptionStatus.Contacted && 'Remove from this list'}
      </Button>
    </Box>
  )
}

FinderResultItem.OptionalButton = function FinderResultItemOptionalButton({
  optionalLink,
  Icon
}: FinderResultItemOptionalButtonProps) {
  return (
    <CtaButton
      href={optionalLink.href}
      variant='outline'
      openInNewWindow={optionalLink.openInNewWindow}
      aria-label={optionalLink.label}
      size='sm'
      Icon={Icon && Icon}
      sx={{
        '.chakra-text': {
          whiteSpace: 'normal'
        },
        svg: {
          fill: 'currentColor',
          // Undo flip of chevron icon
          'html[lang=ar] &': {
            transform: 'scaleX(-1) '
          }
        }
      }}
    >
      {optionalLink.label}
    </CtaButton>
  )
}

FinderResultItem.LastContacted = function FinderResultLastContacted({
  lastContacted
}: FinderResultLastContactedProps) {
  return (
    <Box
      py='7px'
      px={4}
      bg='deepblue.300'
      display='inline-flex'
      borderColor='deepblue.300'
      borderRadius='22px'
      mt={6}
      color='white'
      fontFamily='enz500'
      fontSize='sm'
      lineHeight='20px'
      width={{ base: '100%', lg: 'auto' }}
      justifyContent={{ base: 'center', lg: 'unset' }}
    >
      <Text mr='8px' mb={0}>
        &#10003;
      </Text>
      <Text mb={0}>Last contacted {lastContacted}</Text>
    </Box>
  )
}

export default FinderResultItem
export type { DetailItem }
