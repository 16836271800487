import { InventoryScholarship } from 'content-service'
import { createOpenGraphConfig } from './index'

const createScholarshipDetailsPageSeo = ({
  inputData,
  pageUrl
}: {
  inputData: InventoryScholarship
  pageUrl: string
}) => {
  const title = `${inputData.title} | Study with New Zealand` || ''
  const description = `Find more about ${inputData.title} to study in New Zealand and get application tips.`

  return createOpenGraphConfig({
    title,
    url: pageUrl,
    description: description
  })
}

export { createScholarshipDetailsPageSeo }
