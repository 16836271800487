import * as Yup from 'yup'

enum StudyOptionStatus {
  Suggested = 'Suggested',
  Saved = 'Saved',
  Archived = 'Archived',
  Contacted = 'Contacted',
  Applied = 'Applied',
  Offered = 'Offered',
  Confirmed = 'Confirmed'
}

enum StudyOptionItemType {
  Course = 'Course',
  Provider = 'Provider',
  Scholarship = 'Scholarship'
}

type StudyOptionsAPILearnerInventoryItemModel = {
  itemType: string
  itemId: string
  status: string
  matchScore: number
  updated: Date
  tealiumVisitorId?: string
  tealiumSessionId?: string
}

type StudyOptionsAPILearnerInventoryItemUpdateModel = {
  itemType: string
  itemId: string
  status: string
  matchScore?: number
}

type StudyOptionsAPIValues = {
  items: StudyOptionsAPILearnerInventoryItemModel[]
  totalItems: number
}

const learnerInventoryItemUpdateModelEndpointSchema: Yup.ObjectSchema<
  Omit<StudyOptionsAPILearnerInventoryItemUpdateModel, 'matchScore'>
> = Yup.object()
  .shape({
    itemType: Yup.string().required(),
    itemId: Yup.string().required(),
    status: Yup.string().required().oneOf(Object.values(StudyOptionStatus)),
    tealiumVisitorId: Yup.string(),
    tealiumSessionId: Yup.string()
  })
  .noUnknown(true)
  .required()

export {
  learnerInventoryItemUpdateModelEndpointSchema,
  StudyOptionStatus,
  StudyOptionItemType
}
export type {
  StudyOptionsAPILearnerInventoryItemModel,
  StudyOptionsAPILearnerInventoryItemUpdateModel,
  StudyOptionsAPIValues
}
