import { Box, Flex, Stack, Text } from '@chakra-ui/react'
import { Formik } from 'formik'
import { TickRoundedIcon, WarningIcon } from 'icons'
import { useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import type { Utag } from 'tracking'
import { dispatchFormSubmissionUtagLinkEvent } from 'tracking'
import {
  Button,
  CheckboxControl,
  EnquiryFormCommsOptIn,
  EnquiryFormPrivacyConsent,
  Form,
  FormINZMatchingPermissionOptIn,
  H3,
  LargeText,
  postLeadCaptureFormSubmissionToLeadCaptureAPIEndpoint,
  reformatDateForSubmit,
  SmallText,
  StackField,
} from 'ui'
import { getRegionOptions } from 'utils/src/helpers/leadCapture'
import { formTaxonomyData } from 'utils/src/helpers/taxonomy'
import * as Yup from 'yup'

declare const utag: Utag
interface LeadCaptureFormValues {
  firstName: string
  lastName: string
  email: string
  mobile?: string
  areYouInNZ?: string
  dateOfBirth: string | Date
  intendedLevelOfStudy?: string
  nzRegion?: string
  nationality: string
  nationalityISOCode: string
  courseStartDate?: string | Date
  expectedStudyCompletionDate?: string | Date
  privacyPolicyAgreed: boolean
  commsOptin: boolean
  inzMatchingPermissionOptin: boolean
}

export interface LeadCaptureFormProps {
  tealium: {
    source: string
    formType: string
    formName: string
  }
  successMessage?: {
    heading: string
    bodyText: string
  }
  errorMessage?: {
    heading: string
    bodyText: string
  }
  onSuccessCallback?: () => void
  onErrorCallback?: () => void
}

const leadCaptureFormSchema: Yup.ObjectSchema<Omit<LeadCaptureFormValues, 'nationalityISOCode'>> =
  Yup.object()
    .shape(
      {
        firstName: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        email: Yup.string()
          .email('Please enter a valid email')
          .required('Email address is required'),
        mobile: Yup.string()
          .min(8, 'Mobile number is too short')
          .max(15, 'Mobile number is too long'),
        areYouInNZ: Yup.string().oneOf(['Yes', 'I am travelling soon (within two months)', 'No']),
        dateOfBirth: Yup.date()
          .typeError('Please enter a valid date')
          .transform((value) => {
            return value ? new Date(value) : value
          })
          .when('dateOfBirth', {
            is: (val: Date) => val !== undefined,
            then: (rule) => rule.max(new Date(), 'Please enter a date in the past'),
          })
          .required('Date of birth is required'),
        intendedLevelOfStudy: Yup.string(),
        nationality: Yup.string().required('Nationality is required'),
        nzRegion: Yup.string(),
        courseStartDate: Yup.date()
          .typeError('Please enter a valid date')
          .transform((value) => {
            return value ? new Date(value) : value
          })
          .when('courseStartDate', {
            is: (val: Date) => val !== undefined,
            then: (rule) =>
              rule
                .test(
                  'year',
                  'Please enter a valid date',
                  (value: Date | undefined) =>
                    value !== undefined &&
                    value.getFullYear() >= 1000 &&
                    value.getFullYear() <= 9999
                )
                .min(new Date(), 'Please enter a date in the future'),
          }),
        expectedStudyCompletionDate: Yup.date()
          .typeError('Please enter a valid date')
          .transform((value) => {
            return value ? new Date(value) : value
          })
          .when('expectedStudyCompletionDate', {
            is: (val: Date) => val !== undefined,
            then: (rule) => rule.min(new Date(), 'Please enter a date in the future'),
          }),
        privacyPolicyAgreed: Yup.boolean()
          .oneOf([true], '*You must accept the privacy policy.')
          .defined(),
        commsOptin: Yup.boolean().oneOf([true, false]).defined(),
        inzMatchingPermissionOptin: Yup.boolean().oneOf([true, false]).defined(),
      },
      [
        ['expectedStudyCompletionDate', 'expectedStudyCompletionDate'],
        ['courseStartDate', 'courseStartDate'],
        ['dateOfBirth', 'dateOfBirth'],
      ]
    )
    .noUnknown(true)
    .required()

const LeadCaptureForm = ({
  onSuccessCallback,
  onErrorCallback,
  tealium,
  successMessage,
  errorMessage,
}: LeadCaptureFormProps) => {
  const recaptchaRef = useRef<ReCAPTCHA>(null)

  const [successfulSubmission, setSuccessfulSubmission] = useState(false)
  const [submissionError, setSubmissionError] = useState(false)
  const taxonomy = formTaxonomyData()

  const handleFormSubmit = async (values: LeadCaptureFormValues) => {
    let tealiumCookie = ''
    let tealiumSessionId = ''
    const utagData: { [key: string]: any } | null = window?.utag?.data ?? null

    if (utagData && utagData?.tealium_visitor_id?.length > 0) {
      tealiumCookie = utagData.tealium_visitor_id
    }

    if (utagData && utagData?.tealium_session_id?.length > 0) {
      tealiumSessionId = utagData.tealium_session_id
    }

    const countryItem =
      taxonomy?.location?.find((country) => country.key == values.nationality) ?? ''
    const isoCode = countryItem ? countryItem.iso : ''

    const formData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      mobile: values.mobile,
      ...(values?.areYouInNZ ? { areYouInNZ: values.areYouInNZ } : {}),
      dateOfBirth: reformatDateForSubmit(values.dateOfBirth),
      ...(values?.intendedLevelOfStudy
        ? { intendedLevelOfStudy: values.intendedLevelOfStudy }
        : {}),
      ...(values?.nzRegion ? { nzRegion: values.nzRegion } : {}),
      nationality: values.nationality,
      nationalityISOCode: isoCode,
      ...(values?.courseStartDate
        ? { courseStartDate: reformatDateForSubmit(values.courseStartDate) }
        : {}),
      ...(values?.expectedStudyCompletionDate
        ? { expectedStudyCompletionDate: reformatDateForSubmit(values.expectedStudyCompletionDate) }
        : {}),
      privacyPolicyAgreed: values.privacyPolicyAgreed,
      commsOptin: values.commsOptin,
      inzMatchingPermissionOptin: values.inzMatchingPermissionOptin,
      tealiumVisitorId: tealiumCookie,
      tealiumSessionId,
      source: tealium.source,
      formSchemaName: 'leadCaptureFormSchema',
    }

    postLeadCaptureFormSubmissionToLeadCaptureAPIEndpoint(formData, {
      success: () => {
        setSuccessfulSubmission(true)
        onSuccessCallback?.()
        utagData &&
          dispatchFormSubmissionUtagLinkEvent(
            tealium.formType,
            tealium.formName,
            window.location.href
          )
      },
      error: () => {
        setSubmissionError(true)
        onErrorCallback?.()
      },
    })
  }

  return (
    <>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          dateOfBirth: '',
          nationality: '',
          nationalityISOCode: '',
          privacyPolicyAgreed: false,
          commsOptin: false,
          inzMatchingPermissionOptin: false,
        }}
        validationSchema={leadCaptureFormSchema}
        onSubmit={handleFormSubmit}
      >
        {({ isValid, isSubmitting, dirty, initialValues, errors, values }) => {
          return (
            <Form>
              <Stack spacing={6} alignItems="flex-start">
                {dirty && !isValid && (
                  <Flex
                    border="2px solid"
                    borderRadius="base"
                    borderColor="red.400"
                    backgroundColor="red.50"
                    padding={3}
                    lineHeight="1.125"
                    alignItems="center"
                  >
                    <Box boxSize={6} fill="red.400">
                      <WarningIcon />
                    </Box>
                    <Text fontFamily="enz400" mb="0" paddingLeft={2} color="grey.500">
                      {`Please complete the highlighted fields below.`}
                    </Text>
                  </Flex>
                )}
                <Stack spacing={6} alignItems="flex-start">
                  <Stack spacing={6} width="100%">
                    <Form.FirstNameField />
                    <Form.LastNameField />
                    <StackField spacing="lg">
                      <Form.EmailAddressField />
                      <Form.PhoneNumberField
                        name="mobile"
                        country="nz"
                        countryCodeEditable={false}
                        disableDropdown
                      />
                    </StackField>
                    <Form.AreYouInNZField
                      areYouInNzOptions={[
                        { key: 'yes', label: 'Yes' },
                        {
                          key: 'yes-within-two-months',
                          label: 'I am travelling soon (within two months)',
                        },
                        { key: 'no', label: 'No' },
                      ]}
                    />
                    <Stack maxW={{ base: '100%', lg: '49%' }}>
                      <Form.DateOfBirthField initialValue={initialValues.dateOfBirth} isRequired />
                    </Stack>
                    <Form.LevelOfStudyField
                      name="intendedLevelOfStudy"
                      label="Level of study"
                      levelOfStudyOptions={taxonomy.levelOfStudy}
                    />
                    <Form.RegionField
                      label="Region you're studying in"
                      name="nzRegion"
                      regionOptions={getRegionOptions('nz-region', taxonomy)}
                    />
                    <Form.NationalityField
                      label="Nationality"
                      nationalityOptions={taxonomy.location}
                      isRequired={true}
                    />
                    <StackField spacing="lg">
                      <Form.StartDateField name="courseStartDate" label="Course start date" />
                      <Form.StartDateField
                        name="expectedStudyCompletionDate"
                        label="Expected study completion date"
                      />
                    </StackField>
                  </Stack>
                  <CheckboxControl
                    name="inzMatchingPermissionOptin"
                    mt={1}
                    mr={2}
                    extendedText={{ label: 'Read' }}
                  >
                    <FormINZMatchingPermissionOptIn />
                  </CheckboxControl>
                  <CheckboxControl
                    mt={1}
                    mr={2}
                    name="privacyPolicyAgreed"
                    extendedText={{ label: 'Read' }}
                    errorMessage={
                      errors.privacyPolicyAgreed ? errors.privacyPolicyAgreed : undefined
                    }
                  >
                    <EnquiryFormPrivacyConsent />
                  </CheckboxControl>
                  <CheckboxControl mt={1} mr={2} name="commsOptin" extendedText={{ label: 'Read' }}>
                    <EnquiryFormCommsOptIn />
                  </CheckboxControl>
                  <Box visibility="hidden">
                    {/* @ts-ignore https://github.com/dozoisch/react-google-recaptcha/issues/277 */}
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      size="invisible"
                      sitekey={process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY ?? ''}
                    />
                  </Box>
                </Stack>
              </Stack>
              <Box>
                <Button
                  type="submit"
                  isDisabled={!(dirty && isValid) || isSubmitting}
                  mb={3}
                  aria-label="submit"
                >
                  Join NauMai NZ
                </Button>
              </Box>
            </Form>
          )
        }}
      </Formik>
      {successfulSubmission && successMessage && (
        <Box px={4} py={16} textAlign="center">
          <Box boxSize={16} color="success" marginBottom={4} mx="auto">
            <TickRoundedIcon />
          </Box>
          <H3>{successMessage.heading}</H3>
          <LargeText mb={3}>{successMessage.bodyText}</LargeText>
        </Box>
      )}
      {submissionError && errorMessage && (
        <Box px={4} py={16} textAlign="center">
          <H3 color="error">{errorMessage.heading}</H3>
          <SmallText color="error">{errorMessage.bodyText}</SmallText>
        </Box>
      )}
    </>
  )
}

export default LeadCaptureForm
export { leadCaptureFormSchema }
export type { LeadCaptureFormValues }
