import { setContext } from '@apollo/client/link/context'

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-api-key': process.env.CONTENT_API_KEY,
      'x-swnz-opt-in': true
    }
  }
})

export default authLink
