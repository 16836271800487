import { Box, Flex, Image, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import { ZINDEX } from 'utils'
import { Button, CtaButton } from '../buttons'
import { Link } from '../Link'
import { H5 } from '../typography'

/**
  # Cookie consent form
  This form has been built out from HTML provided from SpeakData.

  Here we build out the form and pass the HTML and CSS from CookieConsent.test.tsx.snap to SpeakData.
  Currently it is done through sending a jsFiddle link to SpeakData

  Classes and ID's used by Speakdata
    ID
      * consent_prompt_submit
    Classes

*/
export interface CookieConsentProps {
  zIndex: number
}

interface ToggleButtonProps {
  label: string
  id: string | undefined
}

const ToggleButton = ({ label, id }: ToggleButtonProps) => (
  <CtaButton
    variant='link'
    size='sm'
    fontSize='14px'
    height='48px'
    id={id}
    border='none'
    background='none'
    cursor='pointer'
  >
    {label}
    {/* {'{{confirmation_button}}'} */}
  </CtaButton>
)

const labelStyles = {
  fontSize: '14px',
  lineHeight: '20px',
  cursor: 'pointer'
}

const checkboxStyles = {
  width: '22px',
  height: '22px',
  marginRight: '10px',
  position: 'relative',
  top: '6px',
  accentColor: '#22a2a2'
} as React.CSSProperties

// example
const activeStyles = `
  .toggle-active {
    transform: rotate(270deg);
    background: #EEF4F4;
    border-radius: 18px;
    transition: background 250ms;
  }
  .toggle-active svg {
    fill: #22a2a2;
    transition: background 250ms;
  }
  .toggle_desc:hover {
    background: #EEF4F4;
    border-radius: 18px;
    transition: background 250ms;
  }
  .toggle_desc:hover svg {
    fill: #22a2a2;
  }
  input.toggle {accent-color: #22a2a2}
`

// This js is to be copied into Tealium
function cookieConsentJS() {
  const modal = document.getElementById('__tealiumGDPRcpPrefs')
  if (modal) {
    const toggleBtns = modal.querySelectorAll('.toggle_desc')
    const viewBtn = document.getElementById('show_preferences')
    const hideBtn = document.getElementById('hide_preferences')
    const cookiePrefs = document.getElementById('cookie_preferences')

    const showPreferences = () => {
      if (cookiePrefs) {
        cookiePrefs.style.display = 'block'
      }
      if (viewBtn) {
        viewBtn.style.display = 'none'
      }
      if (hideBtn) {
        hideBtn.style.display = 'block'
      }
    }

    const hidePreferences = () => {
      if (cookiePrefs) {
        cookiePrefs.style.display = 'none'
      }
      if (viewBtn) {
        viewBtn.style.display = 'block'
      }
      if (hideBtn) {
        hideBtn.style.display = 'none'
      }
    }

    toggleBtns.forEach(function (btn) {
      btn.addEventListener('click', () => {
        btn.classList.toggle('toggle-active')
        const desc = document.getElementById(btn.classList[1])
        if (desc) {
          if (btn.classList.contains('toggle-active')) {
            desc.style.display = 'block'
          } else {
            desc.style.display = 'none'
          }
        }
      })
    })
    if (viewBtn) {
      viewBtn.addEventListener('click', showPreferences, false)
    }
    if (hideBtn) {
      hideBtn.addEventListener('click', hidePreferences, false)
    }
  }
}

const CookieConsent = ({
  zIndex = ZINDEX.COOKIE_CONSENT
}: CookieConsentProps) => {
  React.useEffect(() => {
    cookieConsentJS()
  }, [])

  return (
    // We never want this rendered on the page but we do want the class names created
    <Flex justifyContent={'center'}>
      <Box
        position='fixed'
        overflow={'scroll'}
        w={{ base: '325px', md: '320px' }}
        maxHeight={'800px'}
        ml={{ base: '-162.5px', md: 'unset' }}
        left={{ base: '50%', md: '50px' }}
        bottom={{ base: '30px', md: '50px' }}
        maxH={{ base: '100vh', md: '75vh' }}
        backgroundColor='white'
        padding={{ base: '24px 16px', md: '28px 24px 16px 24px' }}
        borderRadius='lg'
        textAlign={'left'}
        boxShadow='0px 10px 30px 0px rgba(0, 0, 0, 0.25)'
        zIndex={zIndex}
        className={'privacy_prompt consent_preferences'}
        maxW={'600px'}
        wordBreak={'break-word'}
        id={'__tealiumGDPRcpPrefs'}
        sx={{
          overflowX: 'hidden'
        }}
      >
        <style>{activeStyles}</style>
        <Box className={'privacy_prompt_content'}>
          <Flex mb={5} direction='column'>
            <Image
              w='255px'
              h='83px'
              src={
                'https://www.studywithnewzealand.govt.nz/nzgovt-logo-compact.png'
              }
              alt={'New Zealand Government logo'}
              objectFit='contain'
              loading='eager'
              className={'logo'}
            />
            <H5
              mb={0}
              mt='22px'
              fontFamily='enz500'
              lineHeight={'28px'}
              letterSpacing={'-1px'}
            >
              Welcome to Study with New Zealand
            </H5>
          </Flex>
          <Text
            mb={0}
            sx={{
              a: {
                color: '#22a2a2',
                textDecoration: 'underline'
              }
            }}
          >
            This is the official government site for information on studying
            with New Zealand <br />
            <br />
            For an optimal experience, we recommend you enable all cookies.
            Alternatively, you can customise which cookies you’re happy for us
            to use. You can update your choices anytime, as per our{' '}
            <Link href={'https://studywithnewzealand.govt.nz/privacy-policy'}>
              Privacy Policy
            </Link>
            {'.'}
          </Text>
          <Box>
            <Box
              bgColor='white'
              w='100%'
              display='block'
              position={'relative'}
              bottom={0}
              left={0}
              pt={5}
              textAlign='left'
              className={'privacy_prompt_footer'}
            >
              <Stack spacing='10px'>
                <Button
                  id='preferences_prompt_submit'
                  className={'button right'}
                  size='sm'
                  fontSize='14px'
                  border='none'
                  cursor={'pointer'}
                >
                  Accept all
                  {/* {'{{confirmation_button}}'} */}
                </Button>
                <ToggleButton id='show_preferences' label='View Preferences' />
              </Stack>
              <Box
                id='cookie_preferences'
                display={'none'}
                mt={5}
                borderTop={'1px solid'}
                borderColor={'deepblue.200'}
              >
                <Box mt={6} fontFamily={'enz500'}>
                  <Box mb={2} position={'relative'}>
                    <Box
                      transform={'rotate(90deg)'}
                      boxSize={'24px'}
                      position={'absolute'}
                      right={'0'}
                      top={1}
                      cursor={'pointer'}
                      className='toggle_desc toggle_desc7'
                    >
                      <svg viewBox='0 0 30 30'>
                        <path d='M10.8 7.4c.5-.5 1.2-.5 1.7 0l6.8 6.8c.5.5.5 1.2 0 1.7l-6.8 6.8c-.5.5-1.2.5-1.7 0s-.5-1.2 0-1.7l5.9-5.9-5.9-5.9c-.5-.6-.5-1.4 0-1.8z'></path>
                      </svg>
                    </Box>
                    <input
                      type='checkbox'
                      className='toggle'
                      id='toggle_cat7'
                      style={checkboxStyles}
                      disabled
                      checked
                    />
                    <label
                      style={{
                        fontSize: '14px',
                        lineHeight: '20px',
                        cursor: 'auto'
                      }}
                      htmlFor='toggle_cat7'
                    >
                      Essential (Required)
                    </label>
                    <Text
                      px={[8, 6]}
                      pt={2}
                      fontSize={'14px'}
                      lineHeight={'20px'}
                      id='toggle_desc7'
                      display={'none'}
                      fontFamily={'enz400'}
                      mt={0}
                    >
                      Modern websites require some cookies to enable essential
                      functionality and monitor performance. Therefore these
                      cookies are always turned on.
                    </Text>
                  </Box>
                  <Box mb={2} position={'relative'}>
                    <Box
                      transform={'rotate(90deg)'}
                      boxSize={'24px'}
                      position={'absolute'}
                      right={'0'}
                      cursor={'pointer'}
                      top={1}
                      className='toggle_desc toggle_desc0'
                    >
                      <svg viewBox='0 0 30 30'>
                        <path d='M10.8 7.4c.5-.5 1.2-.5 1.7 0l6.8 6.8c.5.5.5 1.2 0 1.7l-6.8 6.8c-.5.5-1.2.5-1.7 0s-.5-1.2 0-1.7l5.9-5.9-5.9-5.9c-.5-.6-.5-1.4 0-1.8z'></path>
                      </svg>
                    </Box>
                    <input
                      type='checkbox'
                      className='toggle'
                      id='toggle_cat0'
                      style={checkboxStyles}
                    />
                    <label style={labelStyles} htmlFor='toggle_cat0'>
                      Personalisation
                    </label>
                    <Text
                      px={[8, 6]}
                      pt={2}
                      fontSize={'14px'}
                      lineHeight={'20px'}
                      id='toggle_desc0'
                      display={'none'}
                      fontFamily={'enz400'}
                      mt={0}
                    >
                      These cookies allow us to analyse your activity to show
                      you more relevant content on our website and in the emails
                      we may send you, should you subscribe to them.
                    </Text>
                  </Box>
                  <Box mb={2} position={'relative'}>
                    <Box
                      transform={'rotate(90deg)'}
                      boxSize={'24px'}
                      position={'absolute'}
                      right={'0'}
                      cursor={'pointer'}
                      top={1}
                      className='toggle_desc toggle_desc2'
                    >
                      <svg viewBox='0 0 30 30'>
                        <path d='M10.8 7.4c.5-.5 1.2-.5 1.7 0l6.8 6.8c.5.5.5 1.2 0 1.7l-6.8 6.8c-.5.5-1.2.5-1.7 0s-.5-1.2 0-1.7l5.9-5.9-5.9-5.9c-.5-.6-.5-1.4 0-1.8z'></path>
                      </svg>
                    </Box>
                    <input
                      type='checkbox'
                      className='toggle'
                      id='toggle_cat2'
                      style={checkboxStyles}
                    />
                    <label style={labelStyles} htmlFor='toggle_cat2'>
                      Analytics
                    </label>
                    <Text
                      px={[8, 6]}
                      pt={2}
                      fontSize={'14px'}
                      lineHeight={'20px'}
                      id='toggle_desc2'
                      display={'none'}
                      fontFamily={'enz400'}
                      mt={0}
                    >
                      With these cookies we analyse how you use our website so
                      we can improve it. This information is shared with
                      third-party providers, but no personal data is included.
                    </Text>
                  </Box>
                  <Box mb={2} position={'relative'}>
                    <Box
                      transform={'rotate(90deg)'}
                      boxSize={'24px'}
                      position={'absolute'}
                      right={'0'}
                      cursor={'pointer'}
                      top={1}
                      className='toggle_desc toggle_desc5'
                    >
                      <svg viewBox='0 0 30 30'>
                        <path d='M10.8 7.4c.5-.5 1.2-.5 1.7 0l6.8 6.8c.5.5.5 1.2 0 1.7l-6.8 6.8c-.5.5-1.2.5-1.7 0s-.5-1.2 0-1.7l5.9-5.9-5.9-5.9c-.5-.6-.5-1.4 0-1.8z'></path>
                      </svg>
                    </Box>
                    <input
                      type='checkbox'
                      className='toggle'
                      id='toggle_cat5'
                      style={checkboxStyles}
                    />
                    <label style={labelStyles} htmlFor='toggle_cat5'>
                      Advertising
                    </label>
                    <Text
                      px={[8, 6]}
                      pt={2}
                      fontSize='14px'
                      lineHeight={'20px'}
                      id='toggle_desc5'
                      display={'none'}
                      fontFamily={'enz400'}
                      mt={0}
                    >
                      Advertising cookies enable us to show you more relevant
                      information in our ads. This information is shared with
                      third-party ad platforms, but not other advertisers.
                    </Text>
                  </Box>
                </Box>
                <Flex flexDir={'column'} pt={5}>
                  {/* update ID */}
                  <Button
                    variant='outline'
                    size='sm'
                    fontSize='14px'
                    mb={3}
                    cursor={'pointer'}
                    id='preferences_prompt_submit_selected'
                  >
                    Accept Selected
                  </Button>
                  <ToggleButton
                    id='hide_preferences'
                    label='Hide Preferences'
                  />
                </Flex>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Flex>
  )
}

export default CookieConsent
