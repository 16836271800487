import { useRouter } from "next/router";
import useDeepCompareEffect from "use-deep-compare-effect";
import { createSanitisedPageName } from "../helpers";
import { TEALIUM_EVENT } from "../helpers/constants";
import { useTealium } from "../TealiumTracking";

interface PageViewTaxonomyTag {
  key: string;
  label: string;
}

type TaxonomyTags = Record<string, Array<PageViewTaxonomyTag>>;
interface PageView {
  pageName: string;
  asPath: string;
  taxonomyTags?: TaxonomyTags;
}

interface PageViewResult {
  page_name: string;
  site_section: string;
  content_tags?: string[];
  tealium_event: TEALIUM_EVENT;
}

interface CreatePageViewTaxonomyTags {
  taxonomyTags?: TaxonomyTags;
}

/**
 * Extract taxonomy tag values from the content service into an array
 *
 * e.g.
 * taxonomyTags = {
 *    "topic": [
 *      {
 *        "key": "work",
 *        "label": "Work"
 *      },
 *      {
 *        "key": "money",
 *        "label": "Money"
 *      },
 *    ],
 *    "learner-journey-stage": [
 *      {
 *        "key": "dream",
 *        "label": "Dream"
 *      }
 *    ]
 *  }
 *
 * returns ["work", "money", "dream"]
 */
function createPageViewContentTags({
  taxonomyTags,
}: CreatePageViewTaxonomyTags) {
  return taxonomyTags
    ? Object.entries(taxonomyTags).reduce((previousArray, [_, tags]) => {
        const tagKeys = tags.map((tag) => tag.key);
        return [...previousArray, ...tagKeys];
      }, [] as string[])
    : [];
}

const useTrackPageViews = ({
  pageName,
  pageViewData,
}: {
  pageName: string;
  pageViewData?: TaxonomyTags;
}) => {
  const { setViewData, setIsPageReady } = useTealium();
  const { asPath } = useRouter();
  useDeepCompareEffect(() => {
    setViewData(
      createPageUtagView({
        asPath: asPath ?? "",
        pageName: createSanitisedPageName(pageName),
        taxonomyTags: pageViewData,
      }),
    );
    setIsPageReady(true);
  }, [setViewData, setIsPageReady]);
};

/**
 * createPageUtagView
 * Build the data for a page_view event
 */
function createPageUtagView({
  pageName,
  taxonomyTags,
  asPath,
}: PageView): PageViewResult {
  // Content Category - only add category if there is one tag
  const keys = taxonomyTags ? Object.keys(taxonomyTags) : [];
  const contentCategory =
    keys.length === 1 ? { content_category: keys[0] } : {};

  // Content Tags - pull all the tag values together into a single array
  const tags = createPageViewContentTags({ taxonomyTags });
  const contentTags = tags.length > 0 ? { content_tags: tags } : {};

  return {
    page_name: pageName,
    site_section: asPath === "/" ? "Home" : asPath,
    ...contentTags,
    ...contentCategory,
    tealium_event: TEALIUM_EVENT.PAGE_VIEW,
  };
}

export default createPageUtagView;
export { useTrackPageViews };
export type { PageViewResult, TaxonomyTags };
