import { Spinner } from '@chakra-ui/react'
import { ReactNode } from 'react'
import {
  connectStateResults,
  StateResultsProvided
} from 'react-instantsearch-core'
import { ContainerWrapper } from '../../layout'

interface InstantSearchLoader extends StateResultsProvided {
  children: ReactNode
}

const InstantSearchLoader = ({
  searchResults,
  children
}: InstantSearchLoader) =>
  searchResults ? (
    <>{children}</>
  ) : (
    <ContainerWrapper
      display='flex'
      justifyContent='center'
      alignItems='center'
      height={{
        base: 'calc(100vh - 60px)',
        sm: 'calc(100vh - 76px)',
        xl: 'calc(100vh - 92px)'
      }} // minus header heights
    >
      <Spinner />
    </ContainerWrapper>
  )

export default connectStateResults(InstantSearchLoader)
