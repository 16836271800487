import { Box, Fade, GridItem, useDisclosure } from '@chakra-ui/react'
import AnimatedNumber from 'animated-number-react'
import { Fragment, ReactElement, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { ContainerWrapper, GridLayout } from '../../layout'
import { H3, StatsNumber } from '../../typography'

export interface StatItems {
  heroStat: number
  subtitle: string
  children: ReactElement
}

export interface StatItemsProps {
  statItems?: StatItems[]
}

const Statistics = ({ statItems }: StatItemsProps) => {
  /*
  scroll to reveal transition effect
  */
  const { isOpen, onOpen } = useDisclosure()
  const { ref, inView } = useInView({
    delay: 500,
    threshold: 0.5,
    triggerOnce: true
  })
  useEffect(() => {
    if (inView) {
      onOpen()
    }
  }, [onOpen, inView])
  /*
  number format
  */
  const formatValue = (heroStat: number) => heroStat.toFixed(0)
  return (
    <ContainerWrapper>
      <GridLayout gap={{ base: 6, lg: 14 }}>
        {statItems &&
          statItems.map((item: StatItems, index) => {
            const { heroStat, subtitle, children } = item
            return (
              <GridItem
                colSpan={{ base: 12, md: 4, lg: 4 }}
                key={index}
                borderTop='2px solid'
                borderTopColor='deepblue.200'
              >
                <Fade ref={ref} in={isOpen}>
                  <StatsNumber>
                    {isOpen && (
                      <Fragment>
                        {heroStat < 10 && '0'}
                        <AnimatedNumber
                          value={heroStat}
                          duration={3300}
                          formatValue={formatValue}
                        />
                      </Fragment>
                    )}
                  </StatsNumber>
                  <H3 mb='3'>{subtitle}</H3>
                  <Box>{children}</Box>
                </Fade>
              </GridItem>
            )
          })}
      </GridLayout>
    </ContainerWrapper>
  )
}

export default Statistics
