import { Box, Container, GridItem } from '@chakra-ui/react'
import { GridLayout, H2 } from 'ui'
import { CallToAction, CtaButton } from '../../buttons'
export interface KeepExploringProps {
  title: string
  links?: CallToAction[]
  leadCaptureFormId?: string
}

const KeepExploring = ({
  title = '',
  links = [],
  leadCaptureFormId
}: KeepExploringProps) => {
  return (
    <Box
      background='teal.100'
      width='full'
      paddingY={{ base: '60px', md: '120px' }}
    >
      <Container>
        <GridLayout>
          <GridItem
            colSpan={{ base: 4, md: 3, lg: 6 }}
            gridGap={{ base: 8, md: 'initial' }}
          >
            <H2 mb={{ base: 4, lg: 6 }}>{title}</H2>
          </GridItem>
          <GridItem colSpan={{ base: 4, md: 5, lg: 6 }}>
            {links.map((link: CallToAction, index: number) => {
              const { label, href, openInNewWindow, isExternalLink, modalId } =
                link
              const ariaLabel = `${label}${
                openInNewWindow ? ' (opens in new window)' : ''
              }`

              return (
                <CtaButton
                  key={index}
                  variant='link'
                  chevron={true}
                  href={href}
                  openInNewWindow={openInNewWindow}
                  isExternalLink={isExternalLink}
                  fontFamily='enz500'
                  fill='currentColor'
                  fontSize={{ base: 20, lg: 22 }}
                  height='auto'
                  whiteSpace='normal'
                  display='flex'
                  justifyContent='flex-start'
                  alignItems='center'
                  marginLeft='-4px'
                  aria-label={ariaLabel}
                  _notLast={{
                    mb: { base: 4, lg: 10 }
                  }}
                  modalId={modalId}
                  leadCaptureFormId={leadCaptureFormId}
                >
                  {label}
                </CtaButton>
              )
            })}
          </GridItem>
        </GridLayout>
      </Container>
    </Box>
  )
}

export default KeepExploring
