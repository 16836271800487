import { createConnector } from 'react-instantsearch-dom'
import { buildState } from '../helpers'
import { SearchAutocompleteProps } from '../SearchAutocomplete'

export interface CurrentRefinement {
  currentRefinement: string
}

const connectAutocomplete: any = createConnector<
  CurrentRefinement,
  SearchAutocompleteProps
>({
  displayName: 'AutocompleteConnector',
  getProvidedProps(props, searchState) {
    // Since the `query` searchState entry isn't
    // necessarily defined, we need to default its value.
    const currentRefinement = searchState.query || ''

    // Connect the underlying component with the `currentRefinement`
    return { ...props, searchState, currentRefinement }
  },
  refine(props, searchState, nextRefinement) {
    const { facet } = props

    // When the underlying component calls its `refine` prop,
    // we update the searchState with the provided refinement.
    const nextState = buildState({
      searchState,
      facet,
      facetValue: nextRefinement?.facetValue,
      query: nextRefinement?.query
    })

    return {
      // `searchState` represents the search state of *all* widgets. We need to extend it
      // instead of replacing it, otherwise other widgets will lose their respective state.
      ...searchState,
      ...nextState,
      // Always reset the page on change of search
      page: 1
    }
  },
  getSearchParameters(searchParameters, props, searchState) {
    // When the `query` state entry changes, we update the query
    return searchParameters.setQuery(searchState.query || '')
  },
  cleanUp(props, searchState) {
    // When the widget is unmounted, we omit the entry `query`
    // from the `searchState`, then on the next request the query will
    // be empty
    return searchState
  }
})

export default connectAutocomplete
