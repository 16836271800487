import { Box, Link, Text } from '@chakra-ui/react'
import React, { ReactElement } from 'react'

const MockJumpAheadElements = () => {
  const elements: ReactElement[] = []

  for (let index = 1; index <= 6; index++) {
    elements.push(
      <>
        <Link
          data-name={`element-${index}`}
          data-title={`Element ${index}`}
          data-displayinmenu={true}
          data-type='jump-ahead'
          visibility='hidden'
          id={`element-${index}`}
        ></Link>
        <Box
          height='600px'
          width='full'
          border='1px solid black'
          marginBottom='100px'
        >
          <Text margin={6}>Element {index}</Text>
        </Box>
      </>
    )
  }

  return <>{elements}</>
}

export default MockJumpAheadElements
