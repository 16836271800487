import { Box, BoxProps, Link, Text } from '@chakra-ui/react'
import { SmallText } from '../../components/typography'

interface FormAgreementsProps extends BoxProps {
  includeAgreePolicy?: boolean
}

const FormAgreements = ({
  includeAgreePolicy,
  ...props
}: FormAgreementsProps) => (
  <Box {...props}>
    <SmallText lineHeight={6} color={props.color ?? 'grey.500'}>
      This site is protected by reCAPTCHA and the{' '}
      <Link href='https://policies.google.com/privacy' isExternal>
        {`Google Privacy Policy`}
      </Link>{' '}
      and{' '}
      <Link href='https://policies.google.com/terms' isExternal>
        {`Terms of Service`}
      </Link>{' '}
      apply.
    </SmallText>
    {includeAgreePolicy && (
      <SmallText lineHeight={6} color={props.color ?? 'grey.500'}>
        By submitting this form I agree to{' '}
        <Link href='/privacy-policy'>{`Education New Zealand's Privacy Policy`}</Link>
        , and to receive updates and marketing communications about their
        services, promotions, special offers, new and events.
      </SmallText>
    )}
  </Box>
)

const FormINZMatchingPermissionOptIn = ({ ...props }) => (
  <SmallText lineHeight={6} mb={0} color={props.color ?? 'grey.500'}>
    I give permission for Immigration New Zealand to share my details with
    Education New Zealand. This includes the existence, status and outcome of
    any{' '}
    <Link href='/privacy-policy#immigration-new-zealand-data-agreement'>{`visa`}</Link>{' '}
    application I submit, for use in accordance with, and for the purposes set
    out in,{' '}
    <Link href='/privacy-policy'>{`Education New Zealand’s Privacy Policy`}</Link>{' '}
    which I have read and understood. The results Education New Zealand receives
    may also include visa decisions pre/post the student visa application (such
    as a work visa post study). This will enable us to provide a more
    personalised digital experience and undertake research and analytical
    studies associated with our functions.
  </SmallText>
)

const EnquiryFormPrivacyConsent = () => (
  <SmallText lineHeight={6} mb={0} color='grey.500'>
    I have read, understood and consent to the{' '}
    <Link href='/privacy-policy'>{`Privacy Policy`}</Link> which authorises
    Education New Zealand to collect, use, disclose and store my personal
    information for the purposes set out in that policy. I therefore agree that
    Education New Zealand may disclose my personal information to relevant
    partners for the purposes set out in the{' '}
    <Link href='/privacy-policy'>{`Education New Zealand’s Privacy Policy`}</Link>
    , and acknowledge that the partner will use, store and disclose my personal
    information in accordance with their own privacy policy (available on the
    partner’s website). I also consent to the relevant partner disclosing
    information to Education New Zealand about my application or enrolment
    status (if any) for the purposes set out in the{' '}
    <Link href='/privacy-policy'>{`Education New Zealand’s Privacy Policy`}</Link>
    . I understand that I have the right to remove my consent to this
    information sharing at any time by notice to you or the relevant partner.
  </SmallText>
)

const EnquiryFormINZConsent = () => (
  <SmallText lineHeight={6} mb={0} color='grey.500'>
    I am happy for Immigration New Zealand to share my details with Education
    New Zealand. this includes the existence, status and outcome of any{' '}
    <Link href='/privacy-policy/#about-visa'>{'visa'}</Link> application I
    submit, for use in accordance with, and for the purposes set out in,{' '}
    <Link href='/privacy-policy'>
      {'Education New Zealand’s Privacy Policy'}
    </Link>{' '}
    which I have read and understood. The results Education New Zealand receives
    may also include visa decisions pre/post the student visa application (such
    as a work visa post-study). This will enable us to provide a more
    personalised digital experience and undertake research and analytical
    studies associated with our functions.
  </SmallText>
)

const EnquiryFormCommsOptIn = () => (
  <SmallText lineHeight={6} mb={0} color='grey.500'>
    I consent to receiving information and updates from Education New Zealand,
    its agents, education providers and institutions, about their services,
    promotions, special offers, news and events in accordance with the{' '}
    <Link href='/privacy-policy'>{'Privacy Policy'}</Link>.
  </SmallText>
)

const AccountPrivacyAndINZConsent = () => (
  <Text
    color='deepWaterBlue'
    fontSize='md'
    fontWeight='400'
    lineHeight='1.75'
    mb={0}
  >
    By registering for a Study With New Zealand account, I acknowledge I have
    read and consent to Education New Zealand’s Privacy Policy, which authorises
    Education New Zealand to collect, use, disclose and store my personal
    information for the purposes set out in the{' '}
    <Link href='/privacy-policy'>{'Privacy Policy'}</Link>. This includes the
    existence, status and outcome of any visa application I submit, for use in
    accordance with, and for the purposes set out in the{' '}
    <Link href='/privacy-policy'>{'Privacy Policy'}</Link> which I have read and
    understood. The results Education New Zealand receives may include visa
    decisions pre/post the student visa application (such as a work visa post
    study). This will enable us to provide a more personalised digital
    experience and undertake research and analytical studies associated with our
    functions.
  </Text>
)
const AccountCommsOptIn = () => (
  <Text
    color='deepWaterBlue'
    fontSize='md'
    fontWeight='400'
    lineHeight='1.75'
    mb={0}
  >
    I consent to receive updates and marketing communications about Education
    New Zealand’s international study opportunities, upcoming events, student
    stories and important announcements.
  </Text>
)

export default FormAgreements
export {
  AccountPrivacyAndINZConsent,
  AccountCommsOptIn,
  FormINZMatchingPermissionOptIn,
  EnquiryFormPrivacyConsent,
  EnquiryFormINZConsent,
  EnquiryFormCommsOptIn
}
