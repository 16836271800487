import { HomepageCard_Swnz_HomepageCardFragment } from 'content-service'
import { isPresent } from 'ts-is-present'
import { OptimisedImage, OptimisedImageProps, RichTextRenderer } from 'ui'
import { IMAGE_SIZE_CONFIG } from 'utils'
import { createCtaButton } from 'utils/src/helpers'
import { CardItemProps } from '../../../components/Homepage/CardsPanel/CardItem'

export interface CreateCardItems {
  items?: Array<HomepageCard_Swnz_HomepageCardFragment | null>
}

export const createPanelCards = ({ items }: CreateCardItems): Array<CardItemProps> => {
  if (!items) {
    return []
  }

  const cardsArr = items.filter(isPresent).map((panelCard) => {
    const { label: buttonLabel = null } = panelCard?.primaryCta || {}
    const imageComponentProps: OptimisedImageProps = {
      src: panelCard?.icon?.source?.url || '',
      alt: panelCard?.icon?.caption || '',
      objectFit: 'contain',
      imageSizeConfig: IMAGE_SIZE_CONFIG.HOMEPAGE_CARD,
    }

    return {
      title: panelCard?.title ?? '',
      subtitle: panelCard?.subtitle ?? '',
      bodyText: <RichTextRenderer richText={panelCard?.bodyText} />,
      isNew: Boolean(panelCard?.isNew),
      image: OptimisedImage(imageComponentProps),
      primaryCta: createCtaButton(panelCard.primaryCta),
    }
  })

  return cardsArr
}
