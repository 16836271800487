import { AspectRatio, Box, keyframes, Text } from '@chakra-ui/react'
import { LocationIcon } from 'icons'
import React, { ReactElement } from 'react'
import { createInitStoryModalCustomEvent } from 'utils/src/helpers'
import { VideoButton } from '../../buttons'

interface CarouselGalleryCardProps {
  index: number
  image: ReactElement | null
  name?: string
  areaOfStudy?: string
  location?: string
  modalId?: string | undefined
  modalHasHeroVideo?: boolean
  isMobile?: boolean
  isStoryCard: boolean
  color: string
}

const CarouselGalleryCard = ({
  image,
  name,
  areaOfStudy,
  location,
  index,
  modalId,
  modalHasHeroVideo = false,
  isStoryCard,
  color
}: CarouselGalleryCardProps) => {
  const fadeInAnimation = keyframes`
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  `

  const storyCardCarouselItemPseudoStyleProps = {
    content: `""`,
    position: 'absolute',
    height: 'full',
    width: 'full',
    left: 0,
    bottom: 0,
    background: `linear-gradient(360deg, var(${color}) 5%, rgba(7, 25, 55, 0) 45%)`,
    'html[lang=ar] &': {
      // 5% reverses to 95% so do opposite
      background: `linear-gradient(360deg, var(${color}) 95%, rgba(7, 25, 55, 0) 45%)`
    }
  }

  return (
    <Box
      key={index}
      animation={`${fadeInAnimation} 0.25s ease-in`}
      cursor='pointer'
      _hover={{
        'button > svg': {
          bgColor: 'white',
          borderRadius: '50%',
          color: 'deepblue.500'
        }
      }}
    >
      <Box
        position='relative'
        overflow='hidden'
        marginRight={{ base: 4, lg: 7 }}
        onClick={() =>
          modalId ? createInitStoryModalCustomEvent(modalId, name) : false
        }
        cursor={modalId && 'pointer'}
      >
        {modalHasHeroVideo && <VideoButton compact zIndex={2} />}

        <Box overflow='hidden'>
          <AspectRatio
            ratio={4 / 6}
            _after={{
              ...(isStoryCard && storyCardCarouselItemPseudoStyleProps)
            }}
          >
            {image}
          </AspectRatio>

          {isStoryCard && (
            <Box
              textColor='white'
              position='absolute'
              textAlign='left'
              sx={{
                //actually left
                'html[lang=ar] &': {
                  textAlign: 'right'
                }
              }}
              bottom={{ base: 4, lg: 7 }}
              width='full'
            >
              <Box paddingX={{ base: 4, lg: 7 }}>
                <Text fontFamily='enz700' marginBottom='0'>
                  {name}
                </Text>
                <Text
                  width='full'
                  whiteSpace='nowrap'
                  overflow='hidden'
                  textOverflow='ellipsis'
                  marginBottom='0'
                >
                  {areaOfStudy}
                </Text>
              </Box>

              <Box
                position='relative'
                paddingX={{ base: 4, lg: 7 }}
                sx={{
                  svg: {
                    display: 'revert',
                    paddingBottom: '4px'
                  }
                }}
              >
                <Text fontSize='sm' color='white' fontFamily='enz700'>
                  <LocationIcon height={'24px'} width={'16px'} />
                  <Box as='span' marginLeft={1}>
                    {location}
                  </Box>
                </Text>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default CarouselGalleryCard
