import { Box, BoxProps, Center, Image } from '@chakra-ui/react'
import { Dispatch, SetStateAction } from 'react'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { H5 } from 'ui'
import { CallToAction } from '../../buttons'

export interface EmotionCardResponse {
  heading: string | undefined
  bodyText: any | undefined
  callToAction: CallToAction | undefined
}
export interface EmotionCardProps {
  id: string
  label: string
  icon?: string
  isOpen?: boolean
  onOpen?: () => void
  onClose?: () => void
  setDrawerCard?:
    | Dispatch<SetStateAction<EmotionCardResponse | null>>
    | undefined
  response?: EmotionCardResponse[]
}

const EmotionCard = ({
  label,
  icon,
  onOpen,
  response,
  setDrawerCard,
  ...props
}: EmotionCardProps & BoxProps) => {
  const randomNumber: number = response
    ? Math.floor(Math.random() * response.length)
    : 0

  return (
    <Box px={'5px'}>
      <Box
        _hover={{ cursor: 'pointer' }}
        onClick={() => {
          setDrawerCard &&
            setDrawerCard({
              heading: response?.[randomNumber].heading,
              bodyText: response?.[randomNumber].bodyText,
              callToAction: response?.[randomNumber].callToAction
            })
          onOpen && onOpen()

          createUtagLinkClickEvent({
            linkLabel: label,
            linkCategory: TEALIUM_LINK_CATEGORY.MOOD_SELECTION
          })
        }}
        data-testid='test-emotion-modal'
      >
        <Center>
          <Box
            height={{ base: '150px', lg: '160px' }}
            width={{ base: '150px', lg: '160px' }}
          >
            <Image
              alt={`Emotion icon asking if you are ${label} `}
              _hover={{
                opacity: 0.5
              }}
              src={icon}
            />
          </Box>
        </Center>
        <Box pt={'16px'} textAlign='center'>
          {label && (
            <H5
              fontWeight={'500'}
              fontSize={{ base: '20px', lg: '22px' }}
              lineHeight={{ base: '28px', lg: '32px' }}
              letterSpacing={'-1'}
            >
              {label}
            </H5>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default EmotionCard
