import {
  Box,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  InputGroup,
  InputProps,
  InputRightElement
} from '@chakra-ui/react'
import { isValid } from 'date-fns'
import { enNZ } from 'date-fns/locale'
import { ErrorMessage, useField } from 'formik'
import { CalendarIcon } from 'icons'
import React, { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import { ZINDEX } from '../../../../utils/src'

export type DatePickerControlProps = FormControlProps & InputProps

export interface Props extends DatePickerControlProps {
  name: string
  label?: string
  helpText?: string
  minDate?: boolean
  isMobile?: boolean
}

type CustomInputProps = Exclude<Props, 'isMobile' | 'minDate'>

// eslint-disable-next-line react/display-name
const CustomInput = React.forwardRef((props: CustomInputProps, ref) => {
  return <Input {...props} {...ref} />
})

const DatePickerControl = ({ label, helpText, ...props }: Props) => {
  const [field, { value, touched, error }, { setValue }] = useField(props.name)
  const [date, setDate] = useState<Date | null>(value)
  const { isMobile: _, minDate: __, ...inputProps } = props
  const refCustomInput = useRef(CustomInput)

  const errorTouched = !!error && !!touched
  const addErrorClass = errorTouched ? 'error ' : ''
  const toggleClass = value ? addErrorClass + 'active' : ''

  // If the startDate is set and valid, set the field value
  useEffect(() => {
    if ((date && isValid(date)) || date === null || date === undefined) {
      setValue(date)
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  return (
    <FormControl
      id={props.name}
      lineHeight='none'
      isInvalid={errorTouched ? true : false}
      isRequired={props.isRequired}
      zIndex={ZINDEX.DATE_PICKER}
      sx={{
        '.react-datepicker-wrapper, .react-datepicker__input-container': {
          w: '100%'
        },
        '.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected':
          {
            bgColor: 'primary'
          },
        '.react-datepicker__triangle': {
          left: '-20px !important'
        },
        input: {
          appearance: 'none',
          bgColor: 'teal.100',
          color: 'deepblue.500',
          border: '2px solid',
          borderColor: 'transparent',
          h: '60px',
          w: '100%',
          pl: 4,
          paddingTop: '10px',
          borderRadius: 'base',
          '&:focus, &:hover': {
            borderColor: 'teal.500'
          },
          '&:focus-visible': {
            outline: 0
          },
          '&::placeholder': {
            color: 'deepblue.300'
          }
        }
      }}
      {...field}
    >
      <InputGroup>
        <DatePicker
          selected={date}
          ariaLabelledBy='select date'
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          onChange={(date) => {
            const isValidDate = isValid(date)
            if (isValidDate || date === null || date === undefined) {
              setDate(date)
            }
          }}
          required={props.isRequired}
          dateFormat='dd/MM/yyyy'
          className={toggleClass}
          locale={enNZ}
          sx={{
            '&.active': {
              color: 'deepblue.500',
              '&.error': {
                color: 'red.400'
              }
            }
          }}
          customInput={<CustomInput ref={refCustomInput} {...inputProps} />}
          {...props}
        />
        <InputRightElement
          pointerEvents='none'
          sx={{
            'html[lang=ar] &': {
              /* GOTCHA!
               * This is acutally align 'left'
               * stylis RTL plugin changes this to 'left' for us */
              left: 0,
              right: 'unset'
            }
          }}
          height='full'
          width='3.75rem'
        >
          <Box boxSize={6} fill='deepblue.500'>
            <CalendarIcon />
          </Box>
        </InputRightElement>
      </InputGroup>
      <FormLabel
        color='deepblue.300'
        position='absolute'
        top='22px'
        left='1rem'
        m={0}
        mr={'80px'}
        cursor='text'
        transition='all 0.3s'
        lineHeight='none'
        className={toggleClass}
        sx={{
          pointerEvents: 'none',
          '&.active': {
            top: '10px',
            left: '18px',
            fontSize: '0.75rem',
            color: 'teal.500',
            '&.error': {
              color: 'red.400'
            }
          },
          'html[lang=ar] &': {
            /* GOTCHA!
             * This is acutally align 'left'
             * stylis RTL plugin changes this to 'left' for us */
            right: '1rem',
            left: 'unset',
            '&.active': {
              left: 'unset',
              right: '18px'
            }
          }
        }}
      >
        {label}
      </FormLabel>
      <FormErrorMessage pl={4}>
        <ErrorMessage name={props.name} />
      </FormErrorMessage>
      {!errorTouched && helpText && (
        <FormHelperText
          as='span'
          fontSize='sm'
          mt={1}
          pl={4}
          display='inline-block'
        >
          {helpText}
        </FormHelperText>
      )}
    </FormControl>
  )
}
export default DatePickerControl
