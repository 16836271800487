import {
  ImpactText_Swnz_ImpactTextFragment,
  ImpactText_Swnz_ImpactTextFragmentDoc
} from 'content-service'
import { ReactElement } from 'react'
import { ImpactText } from 'ui'
import { createCtaButton } from 'utils/src/helpers'
import useFragment from 'utils/src/hooks/useFragment'
import { RichTextRenderer } from '../../components/RichTextRenderer'
import { Connector } from '../types'

export interface ImpactTextConnectProps extends Connector {
  inModal?: boolean
  pageTheme?: string
}

const ImpactTextConnect = ({
  id,
  typename,
  inModal = false,
  pageTheme,
  leadCaptureFormId
}: ImpactTextConnectProps): ReactElement | null => {
  const impactText = useFragment<ImpactText_Swnz_ImpactTextFragment>({
    id,
    typename,
    fragment: ImpactText_Swnz_ImpactTextFragmentDoc,
    fragmentName: 'impactText_SWNZ_ImpactText'
  })

  if (!impactText) {
    return null
  }

  const primaryCtaButton = createCtaButton(impactText?.primaryCta)
  const secondaryCtaButton = createCtaButton(impactText?.secondaryCta)
  return (
    <ImpactText
      backgroundColour={impactText?.backgroundColour as string}
      textColour={impactText?.textColour as string}
      textAlignment={impactText?.textAlignment as 'left' | 'center'}
      displayAsQuote={Boolean(impactText?.displayAsQuote)}
      description={impactText?.description as string}
      primaryCta={primaryCtaButton}
      secondaryCta={secondaryCtaButton}
      inModal={inModal}
      pageTheme={pageTheme}
      leadCaptureFormId={leadCaptureFormId}
    >
      <RichTextRenderer richText={impactText?.bodyText} />
    </ImpactText>
  )
}

export default ImpactTextConnect
