import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  ButtonGroup,
  GridItem,
  SimpleGrid,
  Skeleton,
  Text
} from '@chakra-ui/react'
import { createUtagLinkClickEvent, TEALIUM_LINK_CATEGORY } from 'tracking'
import { CallToAction, CtaButton } from '../../buttons'
import { ContainerWrapper, GridLayout } from '../../layout'
import { H3 } from '../../typography'
import AccordionTickBox from './AccordionTickBox'

export interface NextStepsStep {
  title: string
  bodyText: string | undefined
  primaryButton?: CallToAction
  secondaryButton?: CallToAction
}

export interface NextStepsProps {
  title: string
  describe?: string
  steps?: NextStepsStep[]
  isLoaded: boolean
  leadCaptureFormId?: string
}

const NextStepsButton = ({
  label,
  href,
  openInNewWindow,
  isExternalLink,
  modalId,
  leadCaptureFormId
}: CallToAction) => (
  <Box>
    <CtaButton
      variant='link'
      chevron={true}
      href={href}
      openInNewWindow={openInNewWindow}
      isExternalLink={isExternalLink}
      externalIconSize={'22px'}
      aria-label={label}
      whiteSpace='normal'
      modalId={modalId}
      leadCaptureFormId={leadCaptureFormId}
    >
      {label}
    </CtaButton>
  </Box>
)

const NextSteps = ({
  title,
  describe,
  steps,
  isLoaded,
  leadCaptureFormId
}: NextStepsProps) => {
  return (
    <ContainerWrapper>
      <Skeleton isLoaded={isLoaded} mt={14}>
        <GridLayout
          borderTop='1px solid'
          borderColor='deepblue.100'
          pt={{ base: 6, lg: 14 }}
        >
          <GridItem colSpan={{ base: 4, lg: 4 }}>
            <H3 mb={{ base: 4, lg: 6 }}>{title}</H3>
            {describe && <Text>{describe}</Text>}
          </GridItem>
          <GridItem colSpan={{ base: 4, lg: 7 }} colStart={{ lg: 6 }}>
            {steps && (
              <Accordion
                allowToggle
                onChange={(expandedIndex: number) =>
                  expandedIndex >= 0 &&
                  createUtagLinkClickEvent({
                    linkLabel: steps[expandedIndex].title,
                    linkCategory: TEALIUM_LINK_CATEGORY.SELECTION
                  })
                }
              >
                {steps.map((step: NextStepsStep, index) => {
                  const { title, bodyText, primaryButton, secondaryButton } =
                    step

                  return (
                    <AccordionItem
                      key={index}
                      mb={6}
                      border={0}
                      borderRadius='20px'
                      bgColor='teal.100'
                      transition='all 0.3s'
                      _last={{ mb: 0 }}
                    >
                      {({ isExpanded }) => (
                        <Box p={{ base: 4, lg: 6 }}>
                          <AccordionButton
                            textAlign='left'
                            lineHeight={8}
                            p={0}
                            _hover={{
                              bg: 'transparent',
                              '.title': {
                                color: 'teal.700'
                              }
                            }}
                          >
                            <SimpleGrid
                              templateColumns={{
                                base: '46px 1fr',
                                lg: '72px 1fr'
                              }}
                            >
                              <GridItem>
                                <AccordionTickBox isChecked={isExpanded} />
                              </GridItem>

                              <GridItem>
                                <Text
                                  fontFamily='enz500'
                                  fontSize={{ base: '20px', lg: '22px' }}
                                  pt={{ lg: '10px' }}
                                  mb={0}
                                  className='title'
                                  letterSpacing='-1px'
                                  sx={{
                                    'html:lang(vi) &': {
                                      fontWeight: '700'
                                    }
                                  }}
                                >
                                  {title}
                                </Text>
                              </GridItem>
                            </SimpleGrid>
                          </AccordionButton>
                          <AccordionPanel p={0} mt={4}>
                            <SimpleGrid
                              templateColumns={{
                                base: '46px 1fr',
                                lg: '72px 1fr'
                              }}
                            >
                              <GridItem></GridItem>
                              <GridItem>
                                <Text mb={4} lineHeight={7}>
                                  {bodyText}
                                </Text>
                                <ButtonGroup
                                  spacing={{ base: 0, lg: 4 }}
                                  flexDirection={{
                                    base: 'column',
                                    lg: 'row'
                                  }}
                                  flexWrap='wrap'
                                >
                                  {primaryButton && (
                                    <NextStepsButton
                                      {...primaryButton}
                                      leadCaptureFormId={leadCaptureFormId}
                                    />
                                  )}
                                  {secondaryButton && (
                                    <NextStepsButton
                                      {...secondaryButton}
                                      leadCaptureFormId={leadCaptureFormId}
                                    />
                                  )}
                                </ButtonGroup>
                              </GridItem>
                            </SimpleGrid>
                          </AccordionPanel>
                        </Box>
                      )}
                    </AccordionItem>
                  )
                })}
              </Accordion>
            )}
          </GridItem>
        </GridLayout>
      </Skeleton>
    </ContainerWrapper>
  )
}

export default NextSteps
