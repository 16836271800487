import { NavLink } from '../../NavItem'
import { Breadcrumb } from '../SecondaryNav'

export const ACTION_TYPES = Object.freeze({
  SET_BREADCRUMB: 'set'
})

export const stateInitialiser = (links: NavLink[], selected: string) => () => {
  const crumbs = normalizeLinks(links)
  const crumb = crumbs.get(selected)

  return {
    breadcrumbs: crumbs,
    current: crumb
  }
}

function normalizeLinks(links: Array<NavLink>): Map<string, Breadcrumb> {
  const crumbs = new Map()

  links.forEach((link) => {
    function makeCrumb(link: NavLink, parentId: string | null) {
      if (link.children && link.children.length > 0) {
        link.children.forEach((val) => {
          makeCrumb(val, link.id)
        })

        crumbs.set(link.id, {
          ...link,
          parent: parentId,
          childNodes: link.children.map((val) => val.id)
        })
      } else {
        crumbs.set(link.id, { ...link, parent: parentId, childNodes: [] })
      }
    }
    makeCrumb(link, null)
  })

  return crumbs
}
