function baseStyleControl() {
  return {
    border: '1px solid',
    borderRadius: '1.5px',
    borderColor: 'grey'
  }
}

const baseStyleLabel = {
  _disabled: { opacity: 0.38 }
}

const baseStyle = () => ({
  control: baseStyleControl(),
  label: baseStyleLabel
})

const defaultProps = {
  size: 'lg'
}

export default {
  baseStyle,
  defaultProps
}
