import {
  HomepageHero_Swnz_PageHomepageFragment,
  HomepageHero_Swnz_PageHomepageFragmentDoc,
} from 'content-service'
import { CallToAction, Connector } from 'ui'
import useFragment from 'utils/src/hooks/useFragment'
import { HomepageHeroLandingPageConnect } from './HomepageHeroLandingPageConnect'
import { HomepageHeroTabsConnect } from './HomepageHeroTabsConnect'

interface HomepageHeroConnectProps extends Connector {
  pageTheme?: string
  homepageHeroCtas: CallToAction[]
}

const HomepageHeroConnect = ({
  id,
  typename,
  homepageHeroCtas,
  pageTheme,
}: HomepageHeroConnectProps) => {
  const homepageHero = useFragment<HomepageHero_Swnz_PageHomepageFragment>({
    id,
    typename,
    fragment: HomepageHero_Swnz_PageHomepageFragmentDoc,
    fragmentName: 'homepageHero_SWNZ_PageHomepage',
  })

  if (!homepageHero) {
    return null
  }

  return (
    <>
      <HomepageHeroLandingPageConnect
        homepageHero={homepageHero}
        homepageHeroCtas={homepageHeroCtas}
        pageTheme={pageTheme}
      />
      <HomepageHeroTabsConnect homepageHero={homepageHero} />
    </>
  )
}

export default HomepageHeroConnect
