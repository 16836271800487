import { StateResultsProvided } from 'react-instantsearch-core'

interface ShowRegionAndCitiesFacet
  extends Pick<StateResultsProvided, 'searchState'> {
  attribute: string
}

export default function showRegionAndCities({
  attribute,
  searchState
}: ShowRegionAndCitiesFacet) {
  return (
    searchState?.refinementList?.[attribute]?.length !== undefined &&
    searchState.refinementList?.[attribute]?.length > 0
  )
}
