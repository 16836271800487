import { HeroFeaturedFloatingCard_Swnz_HeroFeaturedFloatingCardFragment } from 'content-service'
import { HeroFeaturedFloatingCardProps } from 'ui'

export const createHeroFloatingCardProps = (
  heroFloatingCard: HeroFeaturedFloatingCard_Swnz_HeroFeaturedFloatingCardFragment
): HeroFeaturedFloatingCardProps | null => {
  const { internalLink } = heroFloatingCard
  const heroFloatingCardProps: HeroFeaturedFloatingCardProps = {
    cardHeading: heroFloatingCard?.cardHeading || '',
    title: heroFloatingCard?.title || '',
    video: null,
    pageLink: null,
    onClick: () => {}
  }

  if (internalLink?.__typename === 'SWNZ_MediaVideo') {
    const { sourceUrl, caption, thumbnail } = internalLink
    heroFloatingCardProps.video = {
      url: sourceUrl || '',
      caption: caption || '',
      thumbnail: thumbnail?.url || ''
    }
  } else if (internalLink?.__typename === 'SWNZ_NaumaiPageStandard') {
    const { slug, parentPage, title, hero } = internalLink
    const mediaItem = hero?.['mediaItem']
    heroFloatingCardProps.pageLink = {
      slug: slug || '',
      parentPage: parentPage || undefined,
      title: title || '',
      thumbnail: (mediaItem && mediaItem?.source?.url) || ''
    }
  } else if (internalLink?.__typename === 'SWNZ_Page') {
    const { slug, parentPage, title, content } = internalLink
    const hero = content?.__typename === 'SWNZ_PageStandard' ? content.hero : {}

    const mediaItem = hero?.['mediaItem']
    heroFloatingCardProps.pageLink = {
      slug: slug || '',
      parentPage: parentPage || undefined,
      title: title || '',
      thumbnail: (mediaItem && mediaItem?.source?.url) || ''
    }
  }

  return heroFloatingCardProps
}
