import {
  Box,
  Center,
  Divider,
  FormControl,
  HStack,
  Input,
  InputGroup
} from '@chakra-ui/react'
import { ChangeEvent } from 'react'
import { connectSearchBox, SearchBoxProvided } from 'react-instantsearch-core'
import { SEARCH_BAR } from 'utils'
import SearchInputIcon from '../SearchInputIcon/SearchInputIcon'
import SearchTitleCta, { SearchTitleCtaProps } from '../SearchTitleCta'

interface ResultsSearchBoxProps extends SearchBoxProvided {
  title: string
  placeholder: string
  searchCoursesCtaProps?: SearchTitleCtaProps
  showDivider?: boolean
}
const ResultsSearchBox = ({
  currentRefinement,
  refine,
  title,
  placeholder,
  searchCoursesCtaProps,
  showDivider = true
}: ResultsSearchBoxProps) => {
  const searchButtonSize = '60px'
  return (
    <form
      noValidate
      action=''
      role='search'
      onSubmit={(event) => {
        event.preventDefault()
      }}
    >
      <FormControl id='search'>
        <HStack spacing={4} mb={{ base: 2, lg: 4 }}>
          <Box fontFamily='enz700' fontSize='20px'>
            {title}
          </Box>
          <Center height={6} display={{ base: 'none', lg: 'unset' }}>
            {/* Conditionally show divider as not yet needed on scholarships */}
            {showDivider && (
              <Divider
                orientation='vertical'
                color='deepblue.200'
                opacity={1}
                m='0'
              />
            )}
          </Center>
          {searchCoursesCtaProps && (
            <SearchTitleCta
              display={{ base: 'none', lg: 'inline-block' }}
              {...searchCoursesCtaProps}
            />
          )}
        </HStack>
        <InputGroup alignItems='center'>
          <Input
            title={title}
            type='search'
            placeholder={placeholder}
            aria-label={placeholder}
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              refine(event.target.value)
            }
            value={currentRefinement}
            color={'deepWaterBlue'}
            paddingRight={searchButtonSize}
            h={{ base: '60px', lg: '80px' }}
            lineHeight={{ lg: '80px' }}
            fontSize={{ lg: '22px' }}
            fontFamily='enz400'
            sx={{
              'html[lang=ar] &': {
                paddingRight: 0,
                px: 4,
                paddingLeft: '60px'
              },
              ...SEARCH_BAR.HOVER
            }}
          />
          <SearchInputIcon searchButtonSize={searchButtonSize} />
        </InputGroup>
        {searchCoursesCtaProps && (
          <SearchTitleCta
            {...searchCoursesCtaProps}
            display={{ base: 'block', lg: 'none' }}
            mt={2}
          />
        )}
      </FormControl>
    </form>
  )
}

export default connectSearchBox(ResultsSearchBox)
