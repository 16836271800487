import {
  AutocompleteApi,
  AutocompleteState,
  InternalAutocompleteSource
} from '@algolia/autocomplete-core'
import { LinkProps, ListItemProps, TextProps, useToken } from '@chakra-ui/react'
import { SearchClient } from 'algoliasearch/lite'
import { ReactElement } from 'react'
import { Link } from '../../Link'
import { LI } from '../../lists'

import Highlight from '../Highlight'
import { AutocompleteResults } from './SearchAutocomplete'
import { SearchAutocompleteModalContext } from './SearchAutocompleteModal'
export interface SearchAutocompleteProps {
  title: string
  placeholder: string
  indexName: string
  facet: string
  searchClient: SearchClient
}

interface SearchAutocompleteItem {
  item: AutocompleteResults
  source: InternalAutocompleteSource<AutocompleteResults>
  autocompleteState: AutocompleteState<AutocompleteResults>
  autocomplete: AutocompleteApi<AutocompleteResults>
  itemProps?: ListItemProps
  linkProps?: Omit<LinkProps, 'as' | 'href'>
  highlightProps?: TextProps
}

const SearchAutocompleteItem = ({
  item,
  source,
  autocomplete,
  autocompleteState,
  itemProps,
  linkProps,
  highlightProps
}: SearchAutocompleteItem): ReactElement => {
  const [teal100] = useToken('colors', ['teal.100'])
  const isActiveItem = autocompleteState.activeItemId === item.__autocomplete_id

  return (
    <SearchAutocompleteModalContext.Consumer>
      {(context) => {
        return (
          //@ts-ignore
          <LI
            borderRadius='sm'
            mb={3}
            {...itemProps}
            {...autocomplete.getItemProps({
              item,
              source
            })}
          >
            <Link
              {...(context?.onClose && { onClick: () => context?.onClose?.() })}
              href={source.getItemUrl({ item, state: autocompleteState }) ?? ''}
              display='block'
              borderRadius='1px'
              color='black'
              textDecoration='none'
              background={
                isActiveItem
                  ? 'deepblue.400'
                  : linkProps?.color
                    ? linkProps.color
                    : '#FFF'
              }
              boxShadow={isActiveItem ? `0 0 0px 8px ${teal100}` : 'none'}
              _hover={{
                color: 'black',
                textDecoration: 'none',
                ...(linkProps?._hover ? linkProps._hover : {})
              }}
              {...linkProps}
            >
              <Highlight attribute='label' hit={item} {...highlightProps} />
            </Link>
          </LI>
        )
      }}
    </SearchAutocompleteModalContext.Consumer>
  )
}

export default SearchAutocompleteItem
