// Taken from https://github.com/sandrinodimattia/serverless-jwt/blob/master/examples/nextjs-auth0/lib/auth.js
import {
  claimToArray,
  getTokenFromHeader,
  JwtVerifier,
  JwtVerifierError,
  JwtVerifierOptions,
  removeNamespaces,
} from '@serverless-jwt/jwt-verifier'
import { Middleware } from 'next-api-route-middleware'
import { AUTH } from 'swnz/src/constants'

import type { NextApiRequest, NextApiResponse } from 'next'
import { decryptProfileId, ProfileIds } from 'swnz/src/helpers/cipher'

export interface IdentityContext {
  /**
   * The token that was provided.
   */
  token: string
  /**
   * Claims for the authenticated user.
   */
  claims: Record<string, unknown>
}

export interface NextAuthenticatedApiRequest extends NextApiRequest {
  /**
   * The user identity for the current request.
   */
  identityContext: IdentityContext
}

type IApiRoute<T = any> = (
  req: NextAuthenticatedApiRequest,
  res: NextApiResponse<T>
) => unknown | Promise<unknown>
interface NextJwtVerifier {
  (apiRoute: IApiRoute): IApiRoute
}

export interface NextJwtVerifierOptions extends JwtVerifierOptions {
  /**
   * Customize how errors are handled.
   */
  handleError?(res: NextApiResponse, err: Error | JwtVerifierError): Promise<void>
}

export const validateJWT = (
  verifier: JwtVerifier,
  options: NextJwtVerifierOptions
): Middleware<NextAuthenticatedApiRequest> => {
  return async function (req, res, next) {
    if (!req) {
      throw new Error('Request is not available')
    }

    if (!res) {
      throw new Error('Response is not available')
    }

    let claims
    let accessToken

    try {
      accessToken = getTokenFromHeader(req.headers.authorization as string)
      claims = await verifier.verifyAccessToken(accessToken)
    } catch (err) {
      if (err instanceof JwtVerifierError) {
        if (typeof options.handleError !== 'undefined' && options.handleError !== null) {
          return options.handleError(res, err)
        }

        return res.status(401).json({
          error: err?.code ?? '',
          error_description: err.message,
        })
      }

      return res.status(500).json({
        error: 'unknown-error',
        error_description: 'Unknown error',
      })
    }

    // Expose the identity in the client context.
    const authenticatedRequest = req as NextAuthenticatedApiRequest
    authenticatedRequest.identityContext = {
      token: accessToken as string,
      claims: claims as Record<string, unknown>,
    }

    await next()
  }
}

/**
 * Create a JWT verifier handler.
 * @param options
 */
const NextJwtVerifier = (options: NextJwtVerifierOptions) => {
  const verifier = new JwtVerifier(options)
  return validateJWT(verifier, options)
}

const verifyJwt = NextJwtVerifier({
  issuer: process.env.NEXT_PUBLIC_AUTH0_ISSUER_BASE_URL ?? '',
  audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE ?? '',
  /**
   * Helper function to process the token claims before executing the function.
   */
  mapClaims: async (claims) => {
    // Custom claims added in Auth0 have a prefix, which are removed here.
    const user = removeNamespaces(AUTH.CUSTOM_CLAIM_NAMESPACE, claims)
    // Convert the scope and roles claims to arrays so they are easier to work with.
    user.scope = typeof user.scope === 'string' ? claimToArray(user.scope) : user.scope
    user.roles = typeof user.roles === 'string' ? claimToArray(user.roles) : user.roles

    return user
  },
})

/**
 * Require the token to contain a certain scope.
 * @param {string} scope
 * @param {*} handler
 */
function validateScope(scope: string[]): Middleware<NextAuthenticatedApiRequest> {
  return async function (req, res, next) {
    const claims = req.identityContext?.claims

    if (Array.isArray(claims?.scope)) {
      const validClaim = claims?.scope?.some((scopeItem: string) => scope.includes(scopeItem))
      // Require the token to contain a specific scope.
      if (!validClaim) {
        return res.status(403).json({
          error: 'access_denied',
          error_description: `Token does not contain the required '${scope}' scope`,
        })
      }
    }

    await next()
  }
}

/**
 * Require the authId to match the user in the access token.
 * @param {*} handler
 */
function validateAuthIdToMatchUser(): Middleware<NextAuthenticatedApiRequest> {
  return async function (req, res, next) {
    const authId = getAuthId(req)

    if (!authId) {
      return res.status(403).json({
        error: 'access_denied',
        error_description: 'User id token is not present in request',
      })
    }

    // The context (which includes authId) from the auth token
    const claims = req.identityContext?.claims

    if (claims?.sub !== authId) {
      return res.status(403).json({
        error: 'access_denied',
        error_description: 'AuthID does not match user within token',
      })
    }
    await next()
  }
}

/**
 * Require the authId to match the user in the access token.
 * @param {*} handler
 */
function validateEncodedIdToMatchUser(): Middleware<NextAuthenticatedApiRequest> {
  return async function (req, res, next) {
    const profileId = getProfileId(req)
    const { authId, profileId: decryptedProfileId } = getAuthIdProfileIdFromToken(req)

    if (!profileId || !authId) {
      return res.status(403).json({
        error: 'access_denied',
        error_description: 'User id token is not present in request',
      })
    }

    // The context (which includes authId) from the auth token
    const claims = req.identityContext?.claims

    if (claims?.sub !== authId || profileId !== decryptedProfileId) {
      return res.status(403).json({
        error: 'access_denied',
        error_description: 'User ids do not match tokens',
      })
    }
    await next()
  }
}

// The authId we pass to the url params, body, or headers
export function getAuthId(req: NextAuthenticatedApiRequest | NextApiRequest): string {
  const { query, body, headers } = req

  const authId = query.authId || body.authId || headers['x-auth-id']
  return typeof authId === 'string' ? authId : authId?.[0]
}

// Get the authId and profile id from the studyoptions toeken
export function getAuthIdProfileIdFromToken(
  req: NextAuthenticatedApiRequest | NextApiRequest
): ProfileIds {
  const xAuthId = req.headers['x-auth-id']
  const sessionToken = typeof xAuthId === 'string' ? xAuthId : xAuthId?.[0]
  return decryptProfileId(sessionToken)
}

export function getProfileId(req: NextAuthenticatedApiRequest | NextApiRequest): string {
  const { query, headers } = req

  const profileId = query.profileId || headers['x-profile-id'] || ''
  return typeof profileId === 'string' ? profileId : profileId?.[0]
}

export const validateAuth = verifyJwt
export { validateScope, validateAuthIdToMatchUser, validateEncodedIdToMatchUser }
