import { Box, ButtonGroup, Center, Divider } from '@chakra-ui/react'
import { Fragment, MouseEvent, useState } from 'react'
import { Button } from '../../buttons'
import { TAG_FILTERS } from './helpers/constants'

interface RenderFilterButtonsProps {
  colorVar: string | undefined
  tags?: string[]
  onClickHandler: (e: MouseEvent<HTMLButtonElement>) => void
}

const RenderFilterButtons = ({
  colorVar,
  tags,
  onClickHandler
}: RenderFilterButtonsProps) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const pseudoElementCSS = {
    backgroundColor: 'white',
    color: `var(${colorVar})`
  }

  // Prepend tags array with 'All Areas' button
  tags && tags.unshift(TAG_FILTERS.allAreas)

  return (
    <Center marginBottom={{ base: 8, lg: 10 }} marginX={6}>
      <Box
        data-testid='card_carousel_tag_filters'
        overflowX={{ base: 'scroll', lg: 'hidden' }}
        whiteSpace='nowrap'
        overflowY='hidden'
      >
        <ButtonGroup spacing={4} paddingY={6}>
          {tags &&
            tags.map((key, index) => (
              <Fragment key={index}>
                <Button
                  variant='monochrome'
                  color='white'
                  _active={pseudoElementCSS}
                  _hover={pseudoElementCSS}
                  aria-label={`Filter by ${key}`}
                  title={key}
                  onClick={(e: MouseEvent<HTMLButtonElement>) => {
                    setActiveIndex(index), onClickHandler(e)
                  }}
                  isActive={index === activeIndex ? true : false}
                >
                  {key}
                </Button>
                {index === 0 && (
                  <Divider orientation='vertical' height='auto' marginY='0' />
                )}
              </Fragment>
            ))}
        </ButtonGroup>
      </Box>
    </Center>
  )
}

export default RenderFilterButtons
